import _ from "lodash";
import { isOfflineEnabledAPI } from "@presto-common/OfflineAPIsConfig";
import { Platform } from "react-native";
import OrderManager from "@presto-services/features/orders/OrderManager";
import DBOrderManager from "@presto-db-managers/DBOrderManager";
import PrestoSdk from "../services/global/PrestoSdk";
import Utils from "../services/common/Utils";
import PaymentManager from "@presto-services/features/payment/PaymentManager";
import PosManager from "@presto-services/features/pos/PosManager";
import DBUserManager from "@presto-db-managers/DBUserManager";

function placeAgentOrder(params, successCallback, errorCallback) {
  let newParams = _.cloneDeep(params);
  let enableOffline = false;
  if (params.enableOffline) {
    enableOffline = params.enableOffline;
    newParams = _.omit(params, "enableOffline");
  }

  if (isOfflineEnabledAPI("orderPlace") || enableOffline) {
    newParams.app_id = PrestoSdk.getAppId();
    newParams.order.app_id = PrestoSdk.getAppId();
    newParams.order.ordering_employee_id = newParams.ordering_employee_id;
    /*try {
      newParams.order.user.email = newParams.order.user.email.toLowerCase();
    } catch (e) {
      console.log("Downcase error");
      console.log(e);
    }*/
    const obj = new DBOrderManager();
    obj
      .placeAgentOrder(newParams.order)
      .then((row) => {
        const order = obj.mutateCalculations(row);
        const response = obj.mockAsAPI(order);
        successCallback(response);
      })
      .catch((error) => {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      });
  } else {
    OrderManager.placeAgentOrder(newParams, successCallback, errorCallback);
  }
}

function createAgentCompositePayment(params, successCallback, errorCallback) {
  let newParams = _.cloneDeep(params);
  let enableOffline = false;
  if (params.enableOffline) {
    enableOffline = params.enableOffline;
    newParams = _.omit(params, "enableOffline");
  }

  if (isOfflineEnabledAPI("createAgentCompositePayment") || enableOffline) {
    const obj = new DBOrderManager();
    const id = _.get(newParams, "composite_payment.targets.0.id");
    obj
      .getOrder({ id })
      .then((row) => {
        const response = obj.mockAsAPI(row);
        successCallback(response);
      })
      .catch((error) => {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      });
  } else {
    PaymentManager.createAgentCompositePayment(
      newParams,
      successCallback,
      errorCallback
    );
  }
}

function createPayment(params, successCallback, errorCallback) {
  let newParams = _.cloneDeep(params);
  let enableOffline = false;
  if (params.enableOffline) {
    enableOffline = params.enableOffline;
    newParams = _.omit(params, "enableOffline");
  }
  const orderId = _.get(newParams, "_order_id");
  newParams = _.omit(newParams, "_order_id");

  if (isOfflineEnabledAPI("paymentCreate") || enableOffline) {
    const obj = new DBOrderManager();
    obj
      .createPayment(orderId, newParams)
      .then((row) => {
        const response = obj.mockAsAPI(row);
        successCallback(response);
      })
      .catch((error) => {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      });
  } else {
    PosManager.createPayment(newParams, successCallback, errorCallback);
  }
}

function syncOrders(params, successCallback, errorCallback) {
  const isWeb = Platform.OS == 'web'
  if (isWeb) return successCallback([])
  DBUserManager.syncToRemote().then(() => {
    const obj = new DBOrderManager();
    obj
      .syncOrders(params.order)
      .then((responses) => {
        successCallback(responses);
      })
      .catch((error) => {
        //alert("sync error");
        console.log("Error----Sync");
        console.log(error);
        errorCallback(error);
      });
  });
}

function successPayment(params, successCallback, errorCallback) {
  let newParams = _.cloneDeep(params);
  let enableOffline = false;
  if (params.enableOffline) {
    enableOffline = params.enableOffline;
    newParams = _.omit(params, "enableOffline");
  }

  const orderId = _.get(newParams, "_order_id");
  newParams = _.omit(newParams, "_order_id");
  if (isOfflineEnabledAPI("paymentSuccess") || enableOffline) {
    const obj = new DBOrderManager();
    obj
      .getOrder({ id: orderId })
      .then((row) => {
        const response = obj.mockAsAPI(row);
        successCallback(response);
      })
      .catch((error) => {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      });
  } else {
    PosManager.successPayment(newParams, successCallback, errorCallback);
  }
}

function updateCCOrdersStatus(params, successCallback, errorCallback) {
  let newParams = _.cloneDeep(params);
  let enableOffline = false;
  if (params.enableOffline) {
    enableOffline = params.enableOffline;
    newParams = _.omit(newParams, "enableOffline");
  }

  const orderId = _.get(newParams, "_order_id");
  newParams = _.omit(newParams, "_order_id");
  if (isOfflineEnabledAPI("orderCCUpdateStatus") || enableOffline) {
    const obj = new DBOrderManager();
    obj
      .getOrder({ id: orderId })
      .then((row) => {
        const response = obj.mockAsAPI(row);
        successCallback(response);
      })
      .catch((error) => {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      });
  } else {
    OrderManager.updateCCOrdersStatus(
      newParams,
      successCallback,
      errorCallback
    );
  }
}

export default {
  placeAgentOrder,
  syncOrders,
  createAgentCompositePayment,
  createPayment,
  successPayment,
  updateCCOrdersStatus,
};
