import React, { useContext, useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import TextInputDoubleButton from "@presto-components/TextInputButtons/TextInputDoubleButton";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import _ from "lodash";
import NavigatorContext from "@presto-contexts/NavigatorContext";

export default function ProductDetails({
  selectedProducts,
  onPlusPress,
  setSelectedProductIds,
  setSelectedCategoryIds,
  selectedProductIds,
  customStyles,
  hidePlusIcon,
  placeHolder,
  selectedType = "allow_many",
  searchText = "",
  showOnlyCategories,
}) {
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const [selectedIds, setSelectedIds] = useState([]);

  const bulkServicesUpdate = (ids, cIds) => {
    setSelectedProductIds(ids);
    setSelectedIds(ids);
    setSelectedCategoryIds(cIds);
  };

  useEffect(() => {
    if (selectedProductIds) {
      setSelectedIds(selectedProductIds);
    }
  }, [selectedProductIds]);

  const viewAllProducts = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "VIEW_ALL_PRODUCTS",
        screenParams: {
          selectedServicesIds: selectedIds
            ? selectedIds
            : _.map(selectedProducts, "id"),
          onPressService: bulkServicesUpdate,
          selectedType: selectedType,
          showOnlyCategories: showOnlyCategories,
        },
      },
    });
  };

  return (
    <View>
      <View
        style={{
          backgroundColor: theme.white,
        }}
      >
        <View
          style={
            customStyles ? customStyles : { paddingTop: 10, paddingBottom: 20 }
          }
        >
          <TextInputDoubleButton
            placeholder={
              placeHolder ?? I18n.t("screen_messages.products.select_an_item")
            }
            searchText={searchText}
            hidePlus={hidePlusIcon ? hidePlusIcon : null}
            onSearchText={(text) => {}}
            onPlusPress={(refId) => onPlusPress(refId)}
            onNextPress={(day) => viewAllProducts()}
            autoCapitalize="characters"
          />
        </View>
      </View>
    </View>
  );
}

const getStyles = (theme) => {
  return StyleSheet.create({
    container: {
      flex: 1,
    },
  });
};
