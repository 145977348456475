import React, { useState, useContext, useEffect, useCallback } from "react";
import { View, Dimensions, ScrollView } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import { VerticalSpacing } from "@presto-components/Spacing";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import { alertBox } from "@presto-common/Alert";
import KeyValueList from "@presto-components/KeyValueList/KeyValueList";
import useFocusEffect from "@presto-common/useFocusEffect";
import PurchaseItemCard from "@presto-cards/ItemCards/PurchaseItemCard";
import PurchaseItemModel from "@presto-component-models/PurchaseItemModel";
import PurchaseOrdersManager from "@presto-services/features/purchase_orders/PurchaseOrdersManager";
import PurchaseOrderHelper from "@presto-helpers/PurchaseOrderHelper";
import utils from "../../utils";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import ModalMobile from "@presto-components/Modals/Modal.bupos";
import OrderModel from "@presto-component-models/OrderModel";
import ActionButtons from "@presto-screen-components/ActionButtons/ActionButtons";
import asyncify from "@presto-common/Asyncify";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import DatePickerComponent from "@presto-components/CalendarComponent/DatePickerComponent";
import { AxiosError } from "axios";
import UserContext from "@presto-contexts/UserContext";

const AsyncEmployeeManager = asyncify(EmployeeManager);
const AsyncPurchaseOrdersManager = asyncify(PurchaseOrdersManager);

export default function InitialPurchaseRequestDetail({ route, navigation }) {
  const params = route.params || {};
  const {
    initial,
    setParentLoading,
    onUpdateItem,
    onRemoveItem,
    reloadSideNav,
    resetPurchaseRequest,
    onPressApproveButton,
    onPressRejectButton,
    onPressMarkAsDeliveredButton,
    onPressmarkAsClosedButton,
    supplierOrder,
    getPurchaseItemCardProps,
  } = params || {};
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  const styles = getStyles(theme, windowWidth);
  const [loading, setLoading] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [showRejectioReasonModal, setShowRejectioReasonModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [focusedItem, setFocusedItem] = useState(null);
  const [form, setForm] = useState({
    items: [],
  });
  const enableApproveButton = params.enableApproveButton ?? false;
  const enableRejectButton = params.enableRejectButton ?? false;
  const enableEditDeliveryButton = params.enableEditDeliveryButton ?? false;
  const enableDownloadPO = params.enableDownloadPO ?? false;
  const enableMarkAsDeliveredButton =
    params.enableMarkAsDeliveredButton ?? false;
  const enableMarkAsClosedButton = params.enableMarkAsClosedButton ?? false;
  const isMobile = utils.isMobile();

  useFocusEffect(
    useCallback(() => {
      init();
    }, [initial])
  );

  const init = () => {
    setPurchaseOrder(initial);

    console.log(initial?.items);
    let tempItems = initial?.items
      ? _.compact(
          _.map(initial.items, (item) => {
            return {
              id: item.id,
              batchNumber: _.get(item, "batchNumber", "").toString(),
              expiryDate: _.get(item, "expiryDate", null),
              quantity: item.quantity,
            };
          })
        )
      : [];

    setForm({ ...form, items: tempItems });
  };

  const onPressEditDeliveryButton = () => {};

  const onPressDownloadPo = () => {
    setParentLoading(true);
    PurchaseOrderHelper.printOrDownload(purchaseOrder, { print: false }).then(
      (data) => {
        setParentLoading(false);
        if (data.success) {
          alert(I18n.t("screen_messages.reports.download_successfull"));
        } else {
          alert(I18n.t("screen_messages.download_failed"));
        }
      }
    );
  };

  const onPressBack = () => {
    navigation.goBack();
  };

  const getItems = () => purchaseOrder?.items || [];

  const getTotalDeliveredQty = () => {
    return purchaseOrder ? purchaseOrder.items.length : 0;
  };

  const validateBeforeOnSubmit = () => {
    let isValid = true,
      error = null;

    if (_.isEmpty(purchaseOrder.items)) {
      error = I18n.t("error_messages.purchase_requests.please_add_items");
    }

    if (error) {
      isValid = false;
    }

    return { isValid, error };
  };

  const onSubmit = async () => {
    const { isValid, error } = validateBeforeOnSubmit();

    if (!isValid) {
      return alertBox("", error);
    }

    setParentLoading(true);
    let promises = _.map(getItems(), (item) => {
      let params = {
        item_purchase_request: {
          quantity: item.quantity,
          category_item_id: item.id,
        },
      };

      return AsyncEmployeeManager.itemPurchaseRequestCreate(params)
        .then(([error, response]) => {
          return error || response.data;
        })
        .then((obj) => {
          if (_.isFunction(route.params.onSubmitCallback)) {
            route.params.onSubmitCallback();
          }
          return obj;
        });
    });

    const results = await Promise.all(promises);
    setParentLoading(false);
    let errorCount = _.filter(results, (e) => _.has(e, "error")).length;

    if (errorCount) {
      console.log("InitialPurchaseRequestDetail->onSubmit->error", error);
      alertBox(results[0].message);
    } else {
      setPurchaseOrder(null);
      if (_.isFunction(resetPurchaseRequest)) {
        resetPurchaseRequest();
      }

      alertBox(I18n.t("screen_messages.purchase_requests.items_add_to_pr"));
    }
  };

  const onUpdate = (purchaseItem, quantity) => {
    const purchaseItemObject = new PurchaseItemModel({
      item: purchaseItem,
      itemPurchaseConfiguration: purchaseItem?.itemPurchaseConfiguration,
    });
    if (!purchaseItem) {
      return;
    }

    if (!isPOApproved && hasPurchaseOrderManagementPermission) {
      quantity = purchaseItemObject.convertFromPurchaseUnitQtyToSaleUnitQty(
        quantity
      );
    } else if (isPOApproved) {
      if (quantity > 0) {
        let tempItems = _.map(_.cloneDeep(form.items), (tempItem) => {
          if (purchaseItem.id === tempItem.id) {
            tempItem["quantity"] = quantity;
          }
          return { ...tempItem };
        });
        setForm({ ...form, items: tempItems });
      }
      return;
    }

    setParentLoading(true);
    if (quantity >= 1) {
      onUpdateItem(purchaseItem, quantity).then((obj) => {
        setParentLoading(false);
        if (obj?.error) {
          return;
        }

        setPurchaseOrder(obj);
        if (_.isFunction(reloadSideNav)) {
          reloadSideNav();
        }
      });
    } else {
      onRemoveItem(purchaseItem).then((obj) => {
        setParentLoading(false);
        if (obj?.error) {
          return;
        }

        if (_.isFunction(reloadSideNav)) {
          reloadSideNav();
        }
      });
    }
  };

  const canMarkForApproval = !_.includes(
    ["AWAITING_APPROVAL", "APPROVED", "REJECTED", "DELIVERED", "CLOSED"],
    _.get(purchaseOrder, "status")
  );

  const canApprove = () => {
    let isValid = _.get(purchaseOrder, "status") === "AWAITING_APPROVAL";
    return isValid;
  };

  const canReject = () => {
    let isValid = _.get(purchaseOrder, "status") === "AWAITING_APPROVAL";
    return isValid;
  };

  const hasPermissionToAccess = (key) =>
    _.get(user, `other_data.permissions.${key}`) === true;

  const hasPurchaseOrderManagementPermission = hasPermissionToAccess(
    "purchase_order_management"
  );

  const isPOApproved = _.includes(["APPROVED"], _.get(purchaseOrder, "status"));

  const supplierOrderObject = supplierOrder
    ? new OrderModel({ order: supplierOrder })
    : null;

  const getSupplierOrderStatus = () => {
    return supplierOrderObject ? _.capitalize(supplierOrderObject.status2) : "";
  };

  const getActionButtons = () => {
    let btns = [],
      commonBtnProps = {
        size: "large",
        buttonStyle: "secondary",
        titleColor: theme.white,
        titleFontStyle: "600.semibold",
        titleSize: 24,
        height: 60,
        borderRadius: 12,
      };

    if (canMarkForApproval) {
      btns.push({
        title: route.params?.onSubmitButtonText
          ? route.params.onSubmitButtonText
          : I18n.t("screen_messages.purchase_requests.raise_purchase_request"),
        onPress: _.isFunction(route.params?.onSubmit)
          ? route.params.onSubmit
          : onSubmit,
        bgColor: "#F17B8D",
      });
    }

    if (isPOApproved) {
      btns.push({
        title: I18n.t("screen_messages.common.save_details"),
        onPress: onSaveItemForm,
        bgColor: "#F17B8D",
      });
    }

    if (canApprove && enableApproveButton) {
      btns.push({
        title: I18n.t("screen_messages.purchase_requests.approve"),
        onPress: () => onPressApproveButton(purchaseOrder),
        bgColor: theme.primary,
      });
    }

    if (canReject && enableRejectButton) {
      btns.push({
        title: I18n.t("screen_messages.rejections.reject"),
        onPress: () => setShowRejectioReasonModal(true),
      });
    }

    if (enableEditDeliveryButton) {
      btns.push({
        title: I18n.t("screen_messages.common.edit_text"),
        onPress: () => onPressEditDeliveryButton(purchaseOrder),
        bgColor: theme.primary,
      });
    }

    if (enableMarkAsDeliveredButton) {
      btns.push({
        title: I18n.t("screen_messages.common.deliver"),
        onPress: () => onPressMarkAsDeliveredButton(purchaseOrder),
        bgColor: "#F17B8D",
      });
    }

    if (enableDownloadPO) {
      btns.push({
        title: I18n.t("screen_messages.common.download"),
        onPress: onPressDownloadPo,
        bgColor: "#5F98A4",
      });
    }

    if (enableMarkAsClosedButton) {
      btns.push({
        title: I18n.t("screen_messages.common.close"),
        onPress: () => onPressmarkAsClosedButton(purchaseOrder),
        bgColor: "#77A18B",
      });
    }

    return { btns, commonBtnProps };
  };

  const validateOnSaveItemForm = () => {
    let isValid = true,
      error = null;

    let now = new Date();

    for (let tempItem of form.items) {
      if (_.isEmpty(tempItem.batchNumber)) {
        error = I18n.t("error_messages.products.please_enter_batch_number");
        break;
      }
      if (_.isEmpty(tempItem.expiryDate)) {
        error = I18n.t("error_messages.products.please_enter_expiry_date");
        break;
      } else {
        let tempDate = new Date(tempItem.expiryDate);
        if (isNaN(tempDate.getTime())) {
          error = I18n.t(
            "error_messages.products.please_enter_valid_expiry_date"
          );
          break;
        } else if (tempDate < now) {
          error = I18n.t(
            "error_messages.products.expiry_date_should_be_future_date"
          );
          break;
        }
      }
    }

    if (error) {
      isValid = false;
    }

    return { isValid, error };
  };

  const onSaveItemForm = () => {
    const { isValid, error } = validateOnSaveItemForm();
    if (!isValid) {
      return alertBox("", error);
    }

    setParentLoading(true);
    let promises = _.map(form.items, (tempItem) => {
      let params = {
        id: purchaseOrder.id,
        purchase_order: {
          line_item_id: tempItem.id,
          delivered_quantity: tempItem.quantity,
          batch_number: tempItem.batchNumber,
          expiry_date: tempItem.expiryDate,
        },
      };
      return AsyncPurchaseOrdersManager.updateDeliveredQuantity(params).then(
        ([error, response]) => {
          if (error) {
            return error;
          }
          return response.data;
        }
      );
    });

    Promise.all(promises)
      .then((responses) => {
        let errorCount = _.filter(responses, ["error", true])?.length || 0;
        let message = I18n.t("success_messages.purchase_orders.saved1", {
          count: responses.length,
        });
        if (errorCount) {
          message = I18n.t("error_messages.purchase_orders.failed_to_save1", {
            count: errorCount,
          });
        }

        setParentLoading(false);
        alertBox("", message);
      })
      .catch((error) => {
        let message =
          error instanceof AxiosError
            ? _.get(error, "response.data.message")
            : error.message;
        return alertBox("", message);
      });
  };

  const getItemRequestPurchaseQuantity = (poItem) => {
    return _.get(poItem, "item.itemPurchaseRequest.purchase_quantity");
  };

  const getPoItemQuantity = (poItem) => {
    let qty = poItem.deliveredQuantity;
    if (!purchaseOrder?.id && hasPurchaseOrderManagementPermission) {
      qty = poItem.convertedFromSaleUnitQtyToPurchaseUnitQty;
    } else if (isPOApproved) {
      qty = poItem.quantity;
    }
    return qty;
  };

  const renderItem = (item, index) => {
    const itemPurchaseConfiguration = item?.itemPurchaseConfiguration;
    let itemForm = _.find(form.items, ["id", item.id]) || {
      batchNumber: "",
      expiryDate: null,
      quantity: 1,
    };

    let purchaseItemProps = _.isFunction(getPurchaseItemCardProps)
      ? getPurchaseItemCardProps(item)
      : {};

    const purcahseItemObj = new PurchaseItemModel({
      item,
      itemPurchaseConfiguration,
    });
    return (
      <PurchaseItemCard
        key={item.id}
        purchaseItem={purcahseItemObj}
        text1={
          hasPurchaseOrderManagementPermission &&
          purcahseItemObj.purchaseDisplayUnitName
            ? `${I18n.t("screen_messages.purchase_requests.purchase_unit")} : ${
                purcahseItemObj.purchaseDisplayUnitName
              } ( ${purcahseItemObj.saleToPurchaseRatio} ${
                purcahseItemObj.saleDisplayUnitName
              } ) `
            : " "
        }
        updating={false}
        onUpdate={onUpdate}
        onGetQuantity={getPoItemQuantity}
        onGetTotalPrice={(poItem) => {
          return _.isFunction(route.params?.onGetTotalPrice)
            ? route.params.onGetTotalPrice(poItem)
            : poItem.formatDeliveredPrice;
        }}
        showInputFields={isPOApproved}
        form={itemForm}
        onChange={(fieldName, value) => {
          let tempItems = _.map(_.cloneDeep(form.items), (tempItem) => {
            if (item.id === tempItem.id) {
              tempItem[fieldName] = value;
            }
            return { ...tempItem };
          });
          setForm({ ...form, items: tempItems });
        }}
        renderDatePickerComponent={({ value, onChange, fieldName }) => {
          return (
            <DatePickerComponent
              userDate={value ? new Date(value) : null}
              placeholder={""}
              onDateChange={(value) => {
                onChange(fieldName, value?.toISOString());
              }}
              onFocus={() => {}}
              onBlur={() => {}}
              textInputStyles={{
                borderWidth: 2,
                borderColor: theme.lightText,
                borderRadius: 6,
              }}
              textInputStyle={{ minWidth: "100%" }}
              minimumDate={new Date()}
              showTimeInput={false}
              iconSize={35}
              showYearsDropdown={true}
              defaultToToday={false}
              textInputHeight={48}
            />
          );
        }}
        {...purchaseItemProps}
      />
    );
  };

  let cartInfo = {
    [I18n.t("screen_messages.cart.total_items")]: getTotalDeliveredQty(),
  };

  const RenderBillCard = ({
    leftText,
    rightText,
    rightTextSize,
    fontStyle,
  }) => {
    return (
      <View style={{ flexDirection: "column" }}>
        <KeyValueList dict={cartInfo} />
        <VerticalSpacing size={15} />
        {leftText ? (
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <PrestoText
              size={16}
              fontStyle="500.medium"
              color={theme.paragraph}
            >
              {leftText}
            </PrestoText>
            <PrestoText
              size={rightTextSize ? rightTextSize : 16}
              fontStyle={fontStyle ? fontStyle : "500.medium"}
              color={theme.title}
            >
              {rightText}
            </PrestoText>
          </View>
        ) : null}
      </View>
    );
  };

  function rejectionReasonModal() {
    return (
      <ModalMobile
        visible={showRejectioReasonModal}
        onDismiss={() => setShowRejectioReasonModal(false)}
        onConfirmationButtonPress={() => setShowRejectioReasonModal(false)}
        modalBackgroundColor={theme.white}
        ModelWidth={"40%"}
        ModelHeight={"30%"}
        modalContainerStyles={{
          alignSelf: "center",
          justifyContent: "center",
          borderRadius: 10,
        }}
        content={
          <View
            style={{
              padding: theme.primaryPadding,
              backgroundColor: theme.white,
            }}
          >
            <View
              style={{
                padding: 10,
              }}
            >
              <PrestoText
                fontStyle="600.semibold"
                size={14}
                color={theme.subHeader}
                extraStyle={{ paddingBottom: theme.primaryPadding }}
              >
                {I18n.t("screen_messages.rejections.reason")}
              </PrestoText>
              <PrestoTextInput
                style={{
                  borderWidth: 2,
                  borderColor:
                    focusedItem === "name" ? theme.primary : "#CCCCCC",
                  textInputPaddingHorizontal: 10,
                }}
                theme={theme}
                placeholder={I18n.t(
                  "screen_messages.rejections.please_enter_reason"
                )}
                value={rejectionReason}
                keyboardType={"default"}
                onChange={({ nativeEvent: { text } }) => {
                  setRejectionReason(text);
                }}
                backgroundColor={theme.white}
                borderWidth={2}
                textAlignVertical="center"
              />
              <VerticalSpacing size={30} />
              <PrestoSolidButton
                size="large"
                buttonStyle="primary"
                title={I18n.t("screen_messages.rejections.reject")}
                titleColor={theme.white}
                onPress={() => {
                  onPressRejectButton(purchaseOrder, rejectionReason).then(
                    (...args) => {
                      setRejectionReason("");
                      setShowRejectioReasonModal(false);
                    }
                  );
                }}
                extraStyle={{
                  padding: theme.primaryPadding,
                }}
                disable={rejectionReason?.length == 0}
                opacity={rejectionReason?.length > 0 ? 1 : 0.4}
              />
            </View>
          </View>
        }
      />
    );
  }

  const actionButtons = getActionButtons();

  return (
    <View style={{ backgroundColor: theme.screenBackgroundColor }}>
      <View
        style={{
          backgroundColor: "#737682",
          borderTopLeftRadius: isMobile ? 0 : 18,
          borderTopRightRadius: isMobile ? 0 : 18,
          height: "100%",
        }}
      >
        <ScrollView
          style={{ width: "100%", flex: 1, height: windowHeight }}
          contentContainerStyle={{
            paddingTop: 10,
            paddingBottom: 250,
          }}
        >
          {purchaseOrder ? <View>{_.map(getItems(), renderItem)}</View> : null}
        </ScrollView>

        {purchaseOrder ? (
          <View
            style={{
              backgroundColor: theme.white,
              minHeight: 240,
              borderTopLeftRadius: isMobile ? 0 : 18,
              borderTopRightRadius: isMobile ? 0 : 18,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              width: isMobile ? "100%" : 400,
              position: "absolute",
              bottom: 0,
            }}
          >
            <View style={{ paddingHorizontal: 30, paddingTop: 10 }}>
              <RenderBillCard rightTextSize={24} fontStyle={"600.semibold"} />
            </View>

            <View
              style={{
                paddingHorizontal: theme.primaryPadding,
                paddingTop: theme.primaryPadding,
              }}
            >
              <ActionButtons
                btns={actionButtons.btns}
                commonBtnProps={actionButtons.commonBtnProps}
                spacing={15}
              />
            </View>
          </View>
        ) : null}
      </View>
      {rejectionReasonModal()}
    </View>
  );
}

function getStyles(theme) {
  return {
    footerContainer: {
      paddingHorizontal: 20,
      bottom: 0,
      width: "100%",
    },
    footerInner: {
      flexDirection: "row",
    },
  };
}
