import React, {
  useEffect,
  useContext,
  useReducer,
  useState,
  useCallback,
} from "react";
import {
  ScrollView,
  StyleSheet,
  View,
  StatusBar,
  KeyboardAvoidingView,
  Pressable,
} from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import I18n from "i18n-js";
import { useSafeArea } from "react-native-safe-area-context";
import LoadingContainer from "@presto-components/LoadingContainer";
import { prestoConfirm } from "../../common/Alert";
import {
  PrestoSolidButton,
  PrestoTextButton,
} from "@presto-components/PrestoButton";
import { useFocusEffect } from "@react-navigation/native";
import ProductDetails from "@presto-screen-components/ProductDeatils/ProductDetails";
import AdminManager from "@presto-services/features/admin/AdminManager";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import PrestoIcon from "@presto-components/PrestoIcon";
import { useCategories } from "@presto-stores/CategoryStore";
import { useActionSheet } from "@expo/react-native-action-sheet";
import TextInputWithDateAndButton from "@presto-components/TextInputButtons/TextInputWithDateAndButton";
import moment from "moment";
import PrestoText from "@presto-components/PrestoText";
import { useAssets } from "presto-react-components";
import Utils from "@presto-services/common/Utils";
import TextInputWithIcon from "@presto-components/TextInputs/TextInputWithIcon";
import { VerticalSpacing } from "@presto-components/Spacing";
import utils from "../../utils";

function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_LOADING":
      return { ...state, loading: payload };
    case "SET_CODE":
      return { ...state, code: payload };
    case "SET_DESCRIPTION":
      return { ...state, description: payload };
    case "SET_START_TIME":
      return { ...state, startTime: payload };
    case "SET_END_TIME":
      return { ...state, endTime: payload };
    case "SET_COUPON":
      return { ...state, coupon: payload };
    case "SET_STOCK":
      return { ...state, stock: payload };
    case "SET_VALUE":
      return { ...state, value: payload };
    case "SET_VALUE_TYPE":
      return { ...state, valueType: payload };
    case "SET_MAX_DISCOUNT":
      return { ...state, maxDiscount: payload };
    case "SET_TITLE":
      return { ...state, title: payload };
    case "SET_TARGET":
      return { ...state, target: payload };
    case "SET_SELECTED_DISCOUNT_TYPE":
      return { ...state, selectedDiscountType: payload };
    case "SET_SELECTED_COUPON_TARGET":
      return { ...state, selectedCouponTarget: payload };
    case "SET_FOCUSED_ITEM":
      return { ...state, focusedItem: payload };
    case "SET_SEGMENT_TYPES":
      return { ...state, segmentTypes: payload };
    case "SET_COUPON_TYPE":
      return { ...state, couponType: payload };
    case "SET_SELECTED_COUPON_TYPE":
      return { ...state, selectedCouponType: payload };
    case "SET_COUPON_OBJECT":
      return {
        ...state,
        code: payload.code,
        description: payload.description,
        startTime: payload.startTime,
        endTime: payload.endTime,
        stock: payload.stock,
        value: payload.value,
        valueType: payload.valueType,
        maxDiscount: payload.maxDiscount,
        title: payload.title,
        target: payload.target_type,
      };
    case "SET_AVAILABLE_PRICE_RULES":
      return { ...state, showAllAvailablePriceRules: payload };
    case "SET_SELECTED_PRICE_RULE":
      return { ...state, selectedPriceRule: payload };
    case "SET_SELECTED_PRODUCT_IDS":
      return { ...state, selectedProductIds: payload };
    case "SET_SELECTED_ENTITLED_PRODUCT_IDS":
      return { ...state, selectedEntitledProductIds: payload };
    case "SET_PRODUCTS":
      return { ...state, selectedProducts: payload };
    case "SET_ENTITLED_RODUCTS":
      return { ...state, selectedEntitlementProducts: payload };
    case "SET_CATEGORY_TOGGLE":
      return { ...state, isCategoryToggled: payload };
    case "SET_SELECTED_CATEGORY_IDS":
      return { ...state, selectedCategoryIds: payload };
    case "SET_MINIMUM_BILL_AMOUNT":
      return { ...state, minimumBillAmount: payload };
  }
}

const COUPON_TYPES = {
  simple: "simple",
  price_rule: "price_rule",
  category_discount: "category_discount",
  item_discount: "item_discount",
  first_transaction: "first_transaction",
  minimum_bill_amount: "minimum_bill_amount",
  online_payment: "online_payment",
  referral: "referral",
};

export default function CreateCoupon({ initialCoupon, gotoCoupons }) {
  const { svgs } = useAssets();
  const { showActionSheetWithOptions } = useActionSheet();
  const { theme } = useContext(ThemeContext);
  const productCategories = useCategories((state) => state.productCategories);
  const isMobile = utils.isMobile();
  const styles = getStyles(theme, isMobile);
  const { Navigator } = useContext(NavigatorContext);
  const insets = useSafeArea();
  const categories = useCategories((state) => state.productCategories);
  const [productNames, setProductNames] = useState([]);
  const [segments, setSegments] = useState(null);
  const [entitledProductNames, setEntitledProductNames] = useState([]);
  let date = new Date();

  let discountTypes = [
    {
      label: I18n.t("screen_messages.coupons.fixed_discount"),
      value: 1,
      type: "fixed_discount",
    },
    {
      label: I18n.t("screen_messages.coupons.percentage_discount"),
      value: 2,
      type: "percent_discount",
    },
  ];

  let targetTypes = [{ label: "Order", value: 2, type: "order" }];

  let couponTypes = [{ label: "Simple", type: COUPON_TYPES.simple }];

  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    code: "",
    description: "",
    endTime: new Date(),
    startTime: new Date(),
    coupon: null,
    stock: "",
    value: "",
    couponType,
    valueType: discountTypes[0].type,
    maxDiscount: "",
    title: "",
    segmentTypes: [],
    minimumBillAmount: "",
    isCategoryToggled,
    target: targetTypes[0].type,
    selectedCouponType: couponTypes[0],
    selectedDiscountType: discountTypes[0],
    selectedCouponTarget: targetTypes[0],
    selectedSegmentId,
    focusedItem: null,
    selectedCategoryIds: [],
    showAllAvailablePriceRules: [],
    selectedPriceRule: null,
    selectedProductIds: [],
    selectedEntitledProductIds: [],
    selectedProducts: [],
    selectedEntitlementProducts: [],
  });

  const {
    loading,
    code,
    description,
    startTime,
    endTime,
    coupon,
    stock,
    valueType,
    value,
    maxDiscount,
    title,
    couponType,
    target,
    isCategoryToggled,
    segmentTypes,
    selectedCategoryIds,
    selectedProductIds,
    selectedCouponType,
    selectedDiscountType,
    selectedCouponTarget,
    selectedSegmentId,
    focusedItem,
    minimumBillAmount,
    showAllAvailablePriceRules,
    selectedPriceRule,
  } = state;

  useEffect(() => {
    if (initialCoupon) {
      dispatch({
        type: "SET_COUPON",
        payload: initialCoupon,
      });
      getPriceRuleDetails(initialCoupon?.price_rule_id);
    } else {
      fetchMerchantPriceRules();
    }
  }, [initialCoupon]);

  const fetchMerchantPriceRules = () => {
    let params = {};
    setLoading(true);
    EmployeeManager.getPriceRules(
      params,
      (response) => {
        setAvailablePricesRules(response.data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  useFocusEffect(
    useCallback(() => {
      getSegments();
    }, [])
  );

  useEffect(() => {
    if (coupon) {
      let obj = {
        code: coupon.code,
        description: coupon.description,
        startTime: new Date(coupon.start_time),
        endTime: new Date(coupon.end_time),
        stock: (coupon.max_redemption_count - coupon.redeemed_count).toString(),
        value:
          coupon.value_type === "percent_discount"
            ? coupon.value.toString()
            : (coupon.value / 100).toString(),
        type: coupon.type,
        valueType: coupon.value_type,
        maxDiscount: coupon.max_discount
          ? coupon.value_type === "percent_discount"
            ? coupon.max_discount.toString()
            : coupon.value_type === "fixed_discount"
            ? (coupon.max_discount / 100).toString()
            : 0
          : 0,
        title: coupon.title,
        segment_id: coupon.segment_id,
        target_type: coupon.target_type,
      };
      if (coupon.max_redemption_count) {
        setStock(coupon.max_redemption_count);
      }
      if (coupon.min_bill_amount) {
        setMinBillAmount(coupon.min_bill_amount.toString());
      }
      let editCoupon = _.find(couponTypes, { type: coupon.type });
      dispatch({
        type: "SET_COUPON_TYPE",
        payload: editCoupon.label,
      });
      if (coupon.target_type === "appointment") {
        dispatch({
          type: "SET_SELECTED_COUPON_TARGET",
          payload: targetTypes[0],
        });
      } else {
        dispatch({
          type: "SET_SELECTED_COUPON_TARGET",
          payload: targetTypes[1],
        });
      }

      if (coupon.value_type === "fixed_discount") {
        dispatch({
          type: "SET_SELECTED_DISCOUNT_TYPE",
          payload: discountTypes[0],
        });
      } else {
        dispatch({
          type: "SET_SELECTED_DISCOUNT_TYPE",
          payload: discountTypes[1],
        });
      }
      let existingSegment = _.find(segmentTypes, { id: coupon.segment_id });
      let segmentObj = {
        id: coupon.segment_id,
        label: existingSegment?.name,
      };
      setSegments(segmentObj);

      dispatch({
        type: "SET_COUPON_OBJECT",
        payload: obj,
      });
      if (coupon?.discounted_items && _.isEmpty(selectedProductIds)) {
        setSelectedProductIds(coupon.discounted_items);
      }
    }
  }, [coupon, segmentTypes]);

  const reducerMethodGenerator = Utils.generateReducerMethod(dispatch);
  const setFocusedItem = reducerMethodGenerator("SET_FOCUSED_ITEM");

  const getPriceRuleDetails = (id) => {
    let params = {
      id: id,
    };
    setLoading(true);
    AdminManager.getPriceRuleDetails(
      params,
      (response) => {
        priceRuleSelected(response.data);

        dispatch({
          type: "SET_VALUE_TYPE",
          payload: "price_rule",
        });

        setAvailablePricesRules([response.data]);

        if (response.data.entitlement_type == COUPON_TYPES.item_discount) {
          getEntitledProducs(response.data.entitled_item_ids);
        }
        getProducts(response.data.prerequisite_item_ids);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const setSelectedProductIds = (ids) => {
    dispatch({
      type: "SET_SELECTED_PRODUCT_IDS",
      payload: _.uniq(_.compact(ids)),
    });
  };

  const setSelectedEntitledProductIds = (ids) => {
    dispatch({
      type: "SET_SELECTED_ENTITLED_PRODUCT_IDS",
      payload: _.uniq(_.compact(ids)),
    });
  };

  const setAvailablePricesRules = (val) => {
    dispatch({
      type: "SET_AVAILABLE_PRICE_RULES",
      payload: val,
    });
  };

  const priceRuleSelected = (val) => {
    dispatch({
      type: "SET_SELECTED_PRICE_RULE",
      payload: val,
    });
  };

  const setCode = (val) => {
    dispatch({
      type: "SET_CODE",
      payload: val,
    });
  };

  const setDescription = (val) => {
    dispatch({
      type: "SET_DESCRIPTION",
      payload: val,
    });
  };

  const setMinBillAmount = (val) => {
    dispatch({
      type: "SET_MINIMUM_BILL_AMOUNT",
      payload: val,
    });
  };

  const setStock = (val) => {
    dispatch({
      type: "SET_STOCK",
      payload: val,
    });
  };

  const setValue = (val) => {
    dispatch({
      type: "SET_VALUE",
      payload: val,
    });
  };

  const setValueType = (index) => {
    let val = "";
    let type = null;
    if (index == 0) {
      val = "fixed_discount";
      type = discountTypes[0];
    } else if (index == 1) {
      val = "percent_discount";
      type = discountTypes[1];
    } else if (index == 2) {
      val = "price_rule";
      type = discountTypes[2];
    }
    dispatch({
      type: "SET_VALUE_TYPE",
      payload: val,
    });
    dispatch({
      type: "SET_SELECTED_DISCOUNT_TYPE",
      payload: type,
    });
  };

  const setTarget = (index) => {
    let val = "";
    let type = null;
    if (index == 0) {
      val = "appointment";
      type = targetTypes[0];
    } else {
      val = "order";
      type = targetTypes[1];
    }
    dispatch({
      type: "SET_TARGET",
      payload: val,
    });
    dispatch({
      type: "SET_SELECTED_COUPON_TARGET",
      payload: type,
    });
  };

  const setCoupon = (index) => {
    let type = _.get(couponTypes[index], "type");
    let label = _.get(couponTypes[index], "label");
    dispatch({
      type: "SET_COUPON_TYPE",
      payload: label,
    });
    dispatch({
      type: "SET_SELECTED_COUPON_TYPE",
      payload: type,
    });
    if (
      type === COUPON_TYPES.category_discount ||
      type === COUPON_TYPES.item_discount
    ) {
      dispatch({
        type: "SET_CATEGORY_TOGGLE",
        payload: true,
      });
      dispatch({
        type: "SET_TARGET",
        payload: "order",
      });
    } else {
      dispatch({
        type: "SET_CATEGORY_TOGGLE",
        payload: false,
      });
    }
  };

  const setSegment = (index) => {
    let segmentId = _.get(segmentTypes[index], "id");
    let value = _.get(segmentTypes[index], "name");
    let segmentObj = {
      label: value,
      id: segmentId,
    };
    setSegments(segmentObj);
  };

  const getSegments = () => {
    // AdminManager.listSegments(
    //   {},
    //   (response) => {
    //     dispatch({
    //       type: "SET_SEGMENT_TYPES",
    //       payload: response.data,
    //     });
    //   },
    //   (error) => {
    //     console.log("error", error);
    //   }
    // );
  };

  const setMaxDiscount = (val) => {
    dispatch({
      type: "SET_MAX_DISCOUNT",
      payload: val,
    });
  };

  const setTitle = (val) => {
    dispatch({
      type: "SET_TITLE",
      payload: val,
    });
  };

  const setLoading = (val) => {
    dispatch({
      type: "SET_LOADING",
      payload: val,
    });
  };

  const onBack = () => {};

  const updateCoupon = () => {
    let sTime = startTime;
    sTime.setHours("00", "00", "01");
    let params = {
      coupon: {
        start_time: sTime.toISOString(),
        end_time: endTime.toISOString(),
        value:
          valueType === "percent_discount"
            ? parseInt(value)
            : parseInt(value) * 100,
        value_type: valueType,
        type: coupon.type ?? selectedCouponType,
        target_type: target,
        max_redemption_count: parseInt(stock) || -1,
        active: true,
        title: title,
        description: description,
        max_discount: parseInt(maxDiscount) * 100,
        code: code,
        segment_id: segments?.id,
        discounted_categories:
          selectedCouponType === COUPON_TYPES.category_discount
            ? selectedCategoryIds
            : [],
        discounted_items: selectedProductIds,
        min_bill_amount: _.toString(parseInt(minimumBillAmount) * 100),
      },
    };
    params.id = coupon.id;
    if (valueType == COUPON_TYPES.price_rule) {
      params.coupon.price_rule_id = selectedPriceRule.id;
    }
    EmployeeManager.updateAdminCoupon(
      params,
      (response) => {
        setLoading(false);
        prestoConfirm(
          I18n.t("screen_messages.common.success"),
          I18n.t("screen_messages.coupons.updated"),
          [
            {
              text: "OK",
              onPress: () => {
                Navigator.emit({
                  event: "goBack",
                });
              },
            },
          ]
        );
      },
      (error) => {
        setLoading(false);
        console.log("error", JSON.stringify(error, null, 2));
        alert(error.data.message);
      }
    );
  };

  const createCoupon = () => {
    let sTime = startTime;
    sTime.setHours("00", "00", "01");
    let tempMinimumBillAmount = parseInt(minimumBillAmount) * 100;
    if (isNaN(tempMinimumBillAmount)) {
      tempMinimumBillAmount = 0;
    }

    let params = {
      coupon: {
        start_time: sTime.toISOString(),
        end_time: endTime ? endTime.toISOString() : new Date().toISOString(),
        value:
          valueType === "percent_discount"
            ? parseInt(value)
            : parseInt(value) * 100,
        value_type:
          valueType == "" || valueType == COUPON_TYPES.price_rule
            ? "fixed_discount"
            : valueType,
        type: selectedCouponType.type,
        target_type: target,
        max_redemption_count: stock || -1,
        active: true,
        title: title,
        description: description,
        max_discount: parseInt(maxDiscount) * 100,
        code: code,
        segment_id: segments?.id,
        discounted_categories:
          selectedCouponType === COUPON_TYPES.category_discount
            ? selectedCategoryIds
            : [],
        discounted_items: selectedProductIds,
        min_bill_amount: _.toString(tempMinimumBillAmount),
      },
    };

    EmployeeManager.createCoupon(
      params,
      (response) => {
        setLoading(false);
        prestoConfirm(
          I18n.t("screen_messages.common.success"),
          I18n.t("screen_messages.coupons.created"),
          [
            {
              text: "OK",
              onPress: () => {
                gotoCoupons();
              },
            },
          ]
        );
      },
      (error) => {
        setLoading(false);
        console.log("error", JSON.stringify(error, null, 2));
        alert(error.message);
      }
    );
  };

  const validateSubmitData = () => {
    let isValid = true,
      error = null;

    if (_.isEmpty(title)) {
      isValid = false;
      error = I18n.t("error_messages.coupons.title_required");
    } else if (_.isEmpty(code)) {
      isValid = false;
      error = I18n.t("error_messages.coupons.code_required");
    } else if (_.isEmpty(value)) {
      isValid = false;
      error = I18n.t("error_messages.coupons.discount_value_required");
    } else if (valueType == "price_rule" && !selectedPriceRule) {
      isValid = false;
      error = I18n.t("error_messages.coupons.price_rule_required");
    }

    return { isValid, error };
  };

  const onPressSubmit = () => {
    const { isValid, error } = validateSubmitData();

    if (!isValid) {
      return alert(error);
    }

    let difference = moment(endTime).diff(moment(startTime));
    if (difference <= 0) {
      alert(I18n.t("screen_messages.coupons.select_proper_dates"));
      return;
    }
    setLoading(true);
    if (!coupon) {
      createCoupon();
    } else {
      updateCoupon();
    }
  };

  const showActionSheetForValueType = (item) => {
    showActionSheetWithOptions(
      {
        options: [
          I18n.t("screen_messages.coupons.fixed_discount"),
          I18n.t("screen_messages.coupons.percentage_discount"),
          I18n.t("screen_messages.common.cancel_text"),
        ],
        message: I18n.t("screen_messages.coupons.select_type"),
        cancelButtonIndex: 2,
      },
      (buttonIndex) => {
        setFocusedItem(null);
        switch (buttonIndex) {
          case 0:
            setValueType(buttonIndex);
            break;
          case 1:
            setValueType(buttonIndex);
            break;
        }
      }
    );
  };

  const showActionSheetForTarget = (item) => {
    showActionSheetWithOptions(
      {
        options: ["Order", I18n.t("screen_messages.common.cancel_text")],
        message: I18n.t("screen_messages.coupons.select_type"),
        cancelButtonIndex: 2,
      },
      (buttonIndex) => {
        setFocusedItem(null);
        switch (buttonIndex) {
          case 0:
            setTarget(buttonIndex);
            break;
          case 1:
            setTarget(buttonIndex);
            break;
        }
      }
    );
  };

  const showActionSheetForSegment = (item) => {
    let optionTypes = _.map(segmentTypes, "name");
    optionTypes.push(I18n.t("screen_messages.common.cancel_text"));
    showActionSheetWithOptions(
      {
        options: optionTypes,
        message: I18n.t("screen_messages.coupons.select_type"),
        cancelButtonIndex: segmentTypes.length,
      },
      (buttonIndex) => {
        setFocusedItem(null);
        setSegment(buttonIndex);
      }
    );
  };

  const showActionSheetForCoupon = (item) => {
    let optionTypes = _.map(couponTypes, "label");
    optionTypes.push(I18n.t("screen_messages.common.cancel_text"));
    showActionSheetWithOptions(
      {
        options: optionTypes,
        message: I18n.t("screen_messages.coupons.select_type"),
        cancelButtonIndex: couponTypes.length,
      },
      (buttonIndex) => {
        setFocusedItem(null);
        setCoupon(buttonIndex);
      }
    );
  };

  const searchHeader = () => {
    return (
      <View>
        <HeaderWithTitleAndSearch
          theme={theme}
          showSearch={false}
          showBack={false}
          leftIcon={
            <Pressable
              onPress={() => onBack()}
              style={{
                width: 50,
                height: "100%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: -5,
              }}
            >
              <PrestoIcon icon={<svgs.BackIcon fillColor={theme.white} />} />
            </Pressable>
          }
          title={I18n.t("screen_messages.coupons.title")}
        />
      </View>
    );
  };

  const Heading = ({ title, containerStyle = {} }) => {
    return (
      <View
        style={{
          paddingTop: isMobile ? 15 : 30,
          paddingBottom: theme.primaryPadding,
          ...containerStyle,
        }}
      >
        <PrestoText fontStyle="subHeadingFont" size={14} color={theme.white}>
          {title}
        </PrestoText>
      </View>
    );
  };

  const textInputComponent = ({
    fieldName,
    value,
    placeholder,
    textContentType,
    autoCompleteType,
    keyboardType = "default",
    editable = true,
    autoCapitalize = "none",
    onChangeText,
  }) => {
    return (
      <TextInputWithIcon
        editable={editable}
        textContentType={textContentType}
        autoComplete={autoCompleteType}
        autoCapitalize={autoCapitalize}
        value={value}
        keyboardType={keyboardType}
        placeholder={placeholder}
        theme={{
          ...theme,
          textInputBorderWidth: 2,
          overflow: "hidden",
        }}
        onFocus={() => setFocusedItem(fieldName)}
        onBlur={() => setFocusedItem(null)}
        textInputStyles={{
          justifyContent: "space-between",
          width: "100%",
          borderWidth: isMobile ? 1 : 2,
          borderColor: focusedItem === fieldName ? theme.primary : theme.grey,
          borderRadius: 8,
          fontFamily: theme.primaryMediumFont,
          fontWeight: 500,
          fontSize: 16,
        }}
        textInputHeight={50}
        onChangeText={onChangeText}
      />
    );
  };

  const dropdownInputCommonStyles = (name) => {
    return {
      flex: 1,
      borderRadius: 8,
      borderWidth: 2,
      borderColor: focusedItem === name ? theme.primary : theme.grey,
      alignContent: "center",
      height: 55,
      backgroundColor: theme.white,
    };
  };

  const commonDateFieldProps = (fieldName) => {
    return {
      onShowDate: () => setFocusedItem(fieldName),
      onCancel: () => setFocusedItem(null),
      containerStyle: {
        borderWidth: 2,
        borderColor: focusedItem === fieldName ? theme.primary : theme.grey,
        height: 55,
      },
      textInputContainerStyle: {
        borderColor: focusedItem === fieldName ? theme.primary : theme.grey,
      },
    };
  };

  const viewAllPriceRules = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "ALL_PRICE_RULES",
        screenParams: {
          screenType: "coupon",
          selectedPriceRule: selectedPriceRuleCallBack,
        },
      },
    });
  };

  const selectedPriceRuleCallBack = (value) => {
    priceRuleSelected(value);
    if (value.entitlement_type == "item_discount") {
      setSelectedEntitledProductIds(value.entitled_item_ids);
      setSelectedProductIds(value.prerequisite_item_ids);
      getEntitledProducs(value.prerequisite_item_ids);
    }

    setSelectedProductIds(value.prerequisite_item_ids);
    getProducts(value.prerequisite_item_ids);

    if (value.entitlement_type == "amount_discount") {
      setEntitledProductNames([]);
    }
  };

  const getProducts = (ids) => {
    _.forEach(categories, (section) => {
      let obj = _.find(section.data, (dataItem) => {
        if (_.includes(ids, dataItem.id)) {
          return dataItem;
        }
      });
      if (!_.isEmpty(obj)) {
        productNames.push(obj);
      }
    });
  };

  const getEntitledProducs = (ids) => {
    _.forEach(categories, (section) => {
      let obj = _.find(section.data, (dataItem) => {
        if (_.includes(ids, dataItem.id)) {
          return dataItem;
        }
      });
      if (!_.isEmpty(obj)) {
        entitledProductNames.push(obj);
      }
    });
  };

  const renderProductNames = (productNames) => {
    let name = [];
    _.forEach(productNames, (product) => {
      name.push(product.name);
    });

    return name.join(", ");
  };

  const setSelectedCategoryIds = (ids) => {
    dispatch({
      type: "SET_SELECTED_CATEGORY_IDS",
      payload: _.uniq(_.compact(ids)),
    });
  };

  const getAllSelectedProductIds = () => {
    return selectedProductIds;
  };

  const renderGap = () => {
    return <View style={{ width: theme.primaryPadding }} />;
  };

  return (
    <ScrollView contentContainerStyle={{ paddingBottom: 80 }}>
      <View
        style={{
          margin: theme.primaryPadding,
          paddingHorizontal: theme.primaryPadding,
          paddingBottom: 15,
          backgroundColor: theme.createItemBgColor,
          borderRadius: 20,
        }}
      >
        <View style={styles.row}>
          <View style={[styles.column]}>
            <Heading
              title={I18n.t("screen_messages.coupons.value_type_title")}
            />
            <Pressable
              onPress={() => showActionSheetForValueType()}
              onPressIn={() => setFocusedItem("selectedDiscountType")}
            >
              <View style={dropdownInputCommonStyles("selectedDiscountType")}>
                <View
                  style={{
                    paddingHorizontal: theme.primaryPadding,
                    paddingVertical: 15,
                  }}
                >
                  <PrestoText
                    fontStyle="600.medium"
                    size={14}
                    color={theme.black}
                  >
                    {selectedDiscountType
                      ? selectedDiscountType.label
                      : I18n.t("screen_messages.coupons.value_type")}
                  </PrestoText>
                </View>
              </View>
            </Pressable>
          </View>

          {renderGap()}

          <View style={styles.column}>
            <Heading
              title={Utils.toTitleCase(
                I18n.t("screen_messages.coupons.coupon_title")
              )}
            />
            {textInputComponent({
              placeholder: I18n.t("screen_messages.coupons.enter_coupon_title"),
              value: title,
              fieldName: "title",
              autoCompleteType: "name",
              textContentType: "name",
              onChangeText: setTitle,
            })}
          </View>
        </View>

        <View style={styles.row}>
          <View style={[styles.column]}>
            <Heading title={I18n.t("screen_messages.coupons.code_title")} />
            {textInputComponent({
              placeholder: I18n.t("screen_messages.coupons.code"),
              value: code,
              fieldName: "code",
              autoCompleteType: "name",
              textContentType: "name",
              onChangeText: setCode,
            })}
          </View>
          {renderGap()}

          <View style={[styles.column]}>
            <Heading
              title={I18n.t("screen_messages.coupons.description_title")}
            />
            {textInputComponent({
              placeholder: I18n.t("screen_messages.coupons.description"),
              value: description,
              fieldName: "description",
              autoCompleteType: "name",
              textContentType: "name",
              onChangeText: setDescription,
            })}
          </View>
        </View>

        <View style={styles.row}>
          <View style={[styles.column]}>
            <Heading title={I18n.t("screen_messages.coupons.value_title")} />
            {textInputComponent({
              placeholder: I18n.t("screen_messages.coupons.value"),
              value: value,
              fieldName: "value",
              keyboardType: "numeric",
              onChangeText: setValue,
            })}
          </View>
          {renderGap()}

          <View style={[styles.column]}>
            <Heading title={I18n.t("screen_messages.coupons.max_title")} />
            {textInputComponent({
              placeholder: I18n.t("screen_messages.coupons.max"),
              value: maxDiscount,
              fieldName: "maxDiscount",
              keyboardType: "numeric",
              onChangeText: setMaxDiscount,
            })}
          </View>
        </View>

        <View style={styles.row}>
          <View style={[styles.column]}>
            <Heading
              title={I18n.t("screen_messages.coupons.start_time_title")}
            />
            <TextInputWithDateAndButton
              hideButton={true}
              textPlaceholder={I18n.t("screen_messages.coupons.start_time")}
              datePlaceHolder={I18n.t("screen_messages.coupons.start_time")}
              text=""
              editable={false}
              date={startTime || date}
              onChangeDate={(date) => {
                let newDate = new Date(new Date(date).setHours(23, 59, 59));
                dispatch({ type: "SET_START_TIME", payload: newDate });
                setFocusedItem(null);
              }}
              {...commonDateFieldProps("startTime")}
            />
          </View>
          {renderGap()}

          <View style={[styles.column]}>
            <Heading title={I18n.t("screen_messages.coupons.end_time_title")} />
            <View>
              <TextInputWithDateAndButton
                hideButton={true}
                textPlaceholder={I18n.t("screen_messages.coupons.end_time")}
                datePlaceHolder={I18n.t("screen_messages.coupons.end_time")}
                text=""
                date={endTime || date}
                editable={false}
                onChangeDate={(date) => {
                  let newDate = new Date(new Date(date).setHours(23, 59, 59));
                  dispatch({ type: "SET_END_TIME", payload: newDate });
                  setFocusedItem(null);
                }}
                {...commonDateFieldProps("endTime")}
              />
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <View style={[styles.column, { flex: 0.5 }]}>
            <Heading
              title={I18n.t("screen_messages.coupons.max_redumption_count")}
            />
            {textInputComponent({
              placeholder: I18n.t("screen_messages.stocks.enter_stock"),
              value: stock,
              fieldName: "stock",
              keyboardType: "numeric",
              onChangeText: setStock,
            })}
          </View>
        </View>

        <View
          style={{
            paddingTop: 30,
          }}
        >
          <PrestoSolidButton
            size="extra-large"
            buttonStyle="primary"
            width="50%"
            titleColor={theme.white}
            title={
              coupon
                ? I18n.t("screen_messages.common.update")
                : I18n.t("screen_messages.common.submit")
            }
            titleExtraStyle={{ textTransform: "capitalize" }}
            onPress={() => {
              onPressSubmit();
            }}
          />
        </View>
      </View>
    </ScrollView>
  );
}
const getStyles = (theme, isMobile) => {
  return {
    container: {
      flex: 1,
      backgroundColor: theme.appBackgroundColor,
    },
    row: {
      flexDirection: isMobile ? "column" : "row",
    },
    column: {
      flex: 1,
    },
  };
};
