import _ from "lodash";
import { isOfflineEnabledAPI } from "@presto-common/OfflineAPIsConfig";
import DBUserManager from "@presto-db-managers/DBUserManager";
import PrestoSdk from "../services/global/PrestoSdk";
import Utils from "../services/common/Utils";
import PosManager from "@presto-services/features/pos/PosManager";
import ClientManager from "@presto-services/features/client/ClientManager";

function searchUser(params, successCallback, errorCallback) {
  if (!params.merchant_id) {
    params.merchant_id = PrestoSdk.getMerchantId();
  }

  let newParams = _.cloneDeep(params);
  let enableOffline = false;
  if (params.enableOffline) {
    enableOffline = params.enableOffline;
    newParams = _.omit(params, "enableOffline");
  }
  console.log(params.enableOffline, "isdfaijsdfjklfjlks", isOfflineEnabledAPI("userSearch"), enableOffline)
  if (isOfflineEnabledAPI("userSearch") || enableOffline) {
    const obj = new DBUserManager();
    obj
      .searchUser(newParams)
      .then((rows) => {
        successCallback({
          data: {
            users: rows,
          },
        });
      })
      .catch((error) => {
        errorCallback(error);
      });
  } else {
    PosManager.searchUser(newParams, successCallback, errorCallback);
  }
}

function createClient(params, successCallback, errorCallback) {
  if (!params.merchant_id) {
    params.merchant_id = PrestoSdk.getMerchantId();
  }
  if (isOfflineEnabledAPI("userCreate")) {
    const obj = new DBUserManager();
    let createParams = params.user;
    obj
      .createClient(createParams)
      .then((row) => {
        successCallback(obj.mockAsAPI(row));
      })
      .catch((error) => {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      });
  } else {
    ClientManager.createClient(params, successCallback, errorCallback);
  }
}

function updateClient(params, successCallback, errorCallback) {
  if (!params.merchant_id) {
    params.merchant_id = PrestoSdk.getMerchantId();
  }
  if (isOfflineEnabledAPI("userUpdate")) {
    const obj = new DBUserManager();
    let updateParams = params.user;
    obj
      .updateClient(updateParams)
      .then((row) => {
        successCallback(obj.mockAsAPI(row));
      })
      .catch((error) => {
        errorCallback(error);
      });
  } else {
    ClientManager.updateClient(params, successCallback, errorCallback);
  }
}

function getClient(params, successCallback, errorCallback) {
  let newParams = _.cloneDeep(params);
  let enableOffline = false;
  if (params.enableOffline) {
    enableOffline = params.enableOffline;
    newParams = _.omit(newParams, "enableOffline");
  }

  if (!newParams.merchant_id) {
    newParams.merchant_id = PrestoSdk.getMerchantId();
  }

  if (isOfflineEnabledAPI("userSearch") || enableOffline) {
    const obj = new DBUserManager();
    obj
      .first(params.user_id, "id")
      .then((row) => {
        successCallback(obj.mockAsAPI(row));
      })
      .catch((error) => {
        errorCallback(error);
      });
  } else {
    ClientManager.getClient(params, successCallback, errorCallback);
  }
}

export default {
  searchUser,
  createClient,
  updateClient,
  getClient,
};
