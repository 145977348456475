import Dexie from 'dexie';
import _ from 'lodash';
import { Platform } from "react-native";
import db from '@presto-helpers/DatabaseConnectionHelper'; // Dexie database connection

const chunkSize = 20;

export default class DBBaseManager {
  get databaseConnection() {
    console.log(db, "asjdiofjsoidjfiojsljdfl")
    return db;
  }

  applyDatatypeToObject(item) {
    const fillables = this.fillables;
    const datatypes = this.datatypes;
    for (let index in fillables) {
      const key = fillables[index];
      const datatype = datatypes[key];
      let value = item[key];
      if (datatype === 'JSON') {
        value = _.isString(value) ? JSON.parse(value) : value;
      } else if (datatype === 'BOOL') {
        value = _.includes(['1', 1, true, 'true'], value);
      } else if (datatype === 'INTEGER') {
        value = parseInt(value);
      } else if (datatype === 'NUMBER') {
        value = Number(value);
      }
      item[key] = value;
    }
    return item;
  }

  async all() {
    const items = await this.databaseConnection.items.toArray();
    return items.map(item => this.applyDatatypeToObject(item));
  }

  async find(ids, key = 'id') {
    const isWeb = Platform.OS == 'web'
    if (!_.isArray(ids)) {
      ids = [ids];
    }
    let newIds = _.compact(ids);
    let chunkedItems = _.chunk(newIds, chunkSize);
    let queries = []
    if (!isWeb) {
      queries = chunkedItems.map(checkItems => {
        return this.databaseConnection.items
          .where(key)
          .anyOf(checkItems)
          .toArray();
      });
    }

    const results = await Promise.all(queries);
    let items = _.flatMap(results);
    items = items.map(item => this.applyDatatypeToObject(item));
    return items;
  }

  async first(id, key = 'id') {
    const items = await this.find(id, key);
    return _.first(items);
  }

  async exists(where) {
    const count = 0;
    // count = await this.databaseConnection.items
    //   .where(where)
    //   .count();
    return count > 0;
  }

  async updateItem(item, whereQueryCallback) {
    const fillables = this.fillables;
    const datatypes = this.datatypes;
    let kvPair = _.pick(item, fillables);
    let keys = _.keys(kvPair);

    let updatedItem = {};
    for (let index in keys) {
      const key = keys[index];
      const datatype = datatypes[key];
      let val = kvPair[key];
      if (datatype === 'JSON') {
        val = !_.isString(val) ? JSON.stringify(val) : val;
      } else if (datatype === 'BOOL') {
        val = _.includes([true, 'true', 1, '1'], val) ? 'true' : 'false';
      } else if (datatype === 'INTEGER') {
        val = parseInt(val);
      } else if (datatype === 'NUMBER') {
        val = Number(val);
        if (!isNaN(val)) {
          val = val;
        }
      }
      updatedItem[key] = val;
    }

    const result = whereQueryCallback(kvPair);
    await this.databaseConnection.items
      .where(result.whereQuery)
      .equals(result.values)
      .modify(updatedItem);

    return { rowsAffected: 1, rows: [updatedItem] };
  }

  async createOrUpdateMany(items) {
    const isWeb = Platform.OS == 'web'
    const ids = _.map(items, 'id');
    const existingItems = await this.find(ids);
    let insertBucket = [], updateBucket = [];

    items.forEach(item => {
      let obj = _.find(existingItems, { id: item.id });
      if (!obj) {
        insertBucket.push(item);
      } else {
        updateBucket.push(item);
      }
    });

    let insertCount = 0, updateCount = 0;
    if (!isWeb) {
      if (!_.isEmpty(insertBucket)) {
        await this.databaseConnection.items.bulkAdd(insertBucket);
        insertCount = insertBucket.length;
      }
    }

    if (!_.isEmpty(updateBucket)) {
      for (let item of updateBucket) {
        const whereQueryCallback = kvPair => ({ whereQuery: 'id', values: kvPair.id });
        await this.updateItem(item, whereQueryCallback);
      }
      updateCount = updateBucket.length;
    }

    return { insertCount, updateCount };
  }

  async updateMany(items, whereQueryCallback) {
    const promises = items.map(item =>
      this.updateItem(item, whereQueryCallback)
    );
    const results = await Promise.all(promises);
    const updatedCount = _.sumBy(results, result => result.rowsAffected);
    return { results, updatedCount };
  }

  async insertMany(items) {
    await this.databaseConnection.items.bulkAdd(items);
    return { rowsAffected: items.length };
  }

  mockAsSearchServerAPI(items, count) {
    let response = {
      data: {
        hits: {
          total: {
            value: count,
          },
          hits: _.map(items, (item) => {
            return {
              _source: {
                ...item,
              },
            };
          }),
        },
      },
    };
    return response;
  }

  mockAsAPI(items) {
    return { data: items };
  }

  async remove(whereQuery, args) {
    await this.databaseConnection.items
      .where(whereQuery)
      .equals(args)
      .delete();
    return { rowsAffected: 1 };
  }

  generateId() {
    return Date.now().toString();
  }

  pickOnlyFillables(item, excludeKeys = []) {
    const fillables = this.fillables;
    const datatypes = this.datatypes;
    fillables.forEach(key => {
      const datatype = datatypes[key];
      let value = item[key];
      if (datatype === 'JSON') {
        value = _.isString(value) ? JSON.parse(value) : null;
      } else if (datatype === 'BOOL') {
        value = _.includes(['1', 1, true, 'true'], value) ? 'true' : 'false';
      } else if (datatype === 'INTEGER') {
        value = parseInt(value);
      } else if (datatype === 'NUMBER') {
        value = Number(value);
        if (!isNaN(value)) {
          value = value;
        }
      }
      if (!_.includes(excludeKeys, key)) {
        item[key] = value;
      }
    });
    return item;
  }

  async truncateAll() {
    const isWeb = Platform.OS == 'web'
    if (!isWeb) {
      await this.databaseConnection.items.clear();
    }
    return { rowsAffected: 1 };
  }
}
