import React, { useState, forwardRef, useContext, ref } from "react";
import { View, Pressable, Button, Picker } from "react-native";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import PrestoText from "@presto-components/PrestoText";
import moment from "moment";
import ThemeContext from "@presto-contexts/ThemeContext";

export default function DateTimePicker({
  selectedDate,
  disableMinimumDate = false,
  minimumDate,
  placeholder = null,
  customInputProps = {},
  maximumDate,
  onDateChange,
  showYearsDropdown = true,
  yearStartRange = 1950,
  yearEndRange = null,
}) {
  const { theme } = useContext(ThemeContext);
  const [date, setDate] = useState(selectedDate || new Date());
  const dateFormat = `dd-MM-yyyy`;

  const minimumDateValue = !disableMinimumDate
    ? minimumDate || new Date()
    : null;

  const now = new Date();
  const months = moment.months();
  const years = _.range(
    yearStartRange,
    yearEndRange ? yearEndRange : now.getFullYear() + 1
  );

  const getYear = (date) => {
    return years.indexOf(date.getFullYear());
  };

  const getMonth = (date) => {
    return date.getMonth();
  };

  const handleOnDateChange = (value) => {
    setDate(value);
    onDateChange(value);
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    const { containerProps = {}, component = null } = customInputProps;
    return (
      <Pressable
        ref={ref}
        onPress={() => {
          onClick();
        }}
        {...containerProps}
      >
        {component || (
          <PrestoText fontStyle="400.normal" size={12} color="#979797">
            Select
          </PrestoText>
        )}
      </Pressable>
    );
  });

  return (
    <DatePicker
      portalId="root-portal"
      selected={date}
      onChange={handleOnDateChange}
      minDate={minimumDateValue}
      dateFormat={dateFormat}
      maxDate={maximumDate}
      customInput={<CustomInput />}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => {
        const selectedYearIndex = getYear(date);
        const selectedMonthIndex = getMonth(date) ?? 0;

        return (
          <View
            style={{
              margin: 10,
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              onPress={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              style={{ marginLeft: 10 }}
              title={"<"}
            />

            {showYearsDropdown ? (
              <Picker
                selectedValue={selectedYearIndex !== -1 ? selectedYearIndex : 0}
                onValueChange={(val) => {
                  let year = years[val];
                  changeYear(year);
                }}
              >
                {years.map((label, index) => (
                  <Picker.Item
                    key={index}
                    label={label}
                    value={index.toString()}
                  />
                ))}
              </Picker>
            ) : null}

            <Picker
              selectedValue={selectedMonthIndex !== -1 ? selectedMonthIndex : 0}
              onValueChange={(val) => {
                changeMonth(val);
              }}
            >
              {months.map((label, index) => (
                <Picker.Item
                  key={index}
                  label={label}
                  value={index.toString()}
                />
              ))}
            </Picker>

            <Button
              onPress={increaseMonth}
              disabled={nextMonthButtonDisabled}
              style={{ marginLeft: 10, backgroundColor: theme.lightText }}
              title={">"}
            />
          </View>
        );
      }}
    />
  );
}
