import React, { useState, useContext } from "react";
import { Platform, View } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import MessagesContext from "@presto-contexts/MessagesContext";
import I18n from "i18n-js";
import LoadingContainer from "@presto-components/LoadingContainer";
import CheckboxRowButton from "@presto-components/CheckBoxes/CheckboxRowButton";
import Spring from "@presto-components/Spring/Spring";
import TitleHeaderWithBack from "@presto-components/Headers/TitleHeaderWithBack";
import { useSafeArea, SafeAreaView } from "react-native-safe-area-context";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import _ from "lodash";
import config from "@presto-common/config";
import PrestoText from "@presto-components/PrestoText";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import UserContext from "@presto-contexts/UserContext";

const isZambiaApp = config.applicationName === "Zip Health Zambia";
const LANGUAGE_OPTIONS = [
  {
    key: "en-US",
    label: "screen_messages.language.english",
  },
  {
    key: config.applicationName !== "Zip Health" ? "en-Portugese" : "fr",
    label:
      config.applicationName === "Zip Health Angola"
        ? "screen_messages.language.portugese"
        : "screen_messages.language.french",
  },
];
const LANGUAGE_OPTIONS_ZAMBIA = [
  {
    key: "en-US",
    label: "screen_messages.language.english",
  },
];

export default function SortOptionsScreen({ route, navigation }) {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { locale, setLanguage } = useContext(MessagesContext);
  const [close, setClose] = useState(false);

  const onCheckboxClicked = (key) => {
    setLanguage(key);
    setTimeout(() => {
      navigation.goBack();
    }, 0);
  };

  const insets = useSafeArea();
  const renderActionSheet = ({ margin }) => {
    return (
      <LoadingContainer bgColor={theme.appBackgroundColor} loading={false}>
        <SafeAreaView
          style={{
            backgroundColor: theme.white,
            marginBottom: margin,
            flex: 1,
          }}
        >
          <TitleHeaderWithBackWithGradientBackground
            user={user}
            title={I18n.t("screen_messages.language.choose_a_language")}
          />
          {_.map(
            isZambiaApp ? LANGUAGE_OPTIONS_ZAMBIA : LANGUAGE_OPTIONS,
            (option) => {
              return (
                <>
                  <CheckboxRowButton
                    title={I18n.t(option.label)}
                    showDisclosure={true}
                    selected={option.key === locale}
                    onPress={() => onCheckboxClicked(option.key)}
                  />
                  <LineDivider
                    dividerColor="#DADADA"
                    width="100%"
                    theme={theme}
                  />
                </>
              );
            }
          )}
        </SafeAreaView>
      </LoadingContainer>
    );
  };

  const fromSpring = close ? { margin: 0 } : { margin: -600 };
  const toSpring = close ? { margin: -600 } : { margin: 0 };

  return (
    <Spring from={fromSpring} to={toSpring}>
      {(props) => renderActionSheet(props)}
    </Spring>
  );
}
