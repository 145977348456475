import React, { useState } from "react";
import I18n from "i18n-js";
import OrderReturnItem from "@presto-cards/ItemCards/OrderReturnItem";
import ItemCardModal from "../../component-models/ItemCardModel";
import { prestoAlert } from "../../common/Alert";
import _ from "lodash";

export default function OrderReturnItemCard({
  item,
  variation,
  onUpdatecallback,
  cantEdit,
  returnCount,
  isReturnSuccess,
}) {
  const [updating, setUpdating] = useState(false);
  const count = item?.return_count;

  const onAdd = async (item) => {
    const nextNumber = count + 1;
    if (nextNumber > item.quantity) {
      alert(I18n.t("error_messages.returns.maximum_return_quantity"));
      setUpdating(false);
      return;
    }
    setUpdating(true);
    onUpdatecallback(item, count + 1);
    setUpdating(false);
  };

  const onSubtract = async (item) => {
    setUpdating(true);
    onUpdatecallback(item, count - 1 > 0 ? count - 1 : 0);
    setUpdating(false);
  };

  const onUpdate = async (variation, quantity) => {
    setUpdating(true);

    if (quantity > item.quantity) {
      alert(I18n.t("error_messages.returns.maximum_return_quantity"));
      setUpdating(false);
      return;
    }
    onUpdatecallback(item, quantity);
    setUpdating(false);
  };

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  return (
    <OrderReturnItem
      product={
        new ItemCardModal({
          product: item,
          lineItem: item,
          variation,
          count,
        })
      }
      updating={updating}
      onAdd={onAdd}
      onSubtract={onSubtract}
      onUpdate={onUpdate}
      cantEdit={cantEdit}
      returnCount={returnCount}
      isReturnSuccess={isReturnSuccess}
    />
  );
}
