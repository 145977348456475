import _ from "lodash";
import moment from "moment";
import utils from "../utils/index";
const colors = {
  hair: "#C4421A",
};

export default class UserModel {
  constructor({ user, initials, picUrl, userLoyaltyTierProgress }) {
    this.user = user;
    this.initials = initials;
    this.picUrl = picUrl;
    this.userLoyaltyTierProgress = userLoyaltyTierProgress;
  }

  get name() {
    return this.user?.name == this.user?.phone_number
      ? "Guest"
      : this.user?.name;
  }

  get userInitials() {
    let letter = "AB";
    let userName = this.initials?.match(/("[^"]+"|[^"\s]+)/g);
    let letterOne = userName[0]?.slice(0, 1) || "";
    let letterTwo = userName[1]?.slice(0, 1) || "";
    letter = letterOne + letterTwo;
    return letter;
  }

  get profilePic() {
    return this.picUrl;
  }

  get email() {
    if (!this.user) {
      return "";
    }
    if (_.startsWith(this.user?.email, `${this.user?.phone_number}@`)) {
      return " ";
    } else {
      return this.user?.email;
    }
  }

  get phoneNumber() {
    return this.user?.phone_number;
  }

  get color() {
    return _.values(colors)[Math.floor(Math.random() * 3)];
  }

  get formatBirthday() {
    const dob = this.user?.birthday;
    return dob ? moment(dob).format("Do MMM") : null;
  }

  get loyaltyTierId() {
    const tierId = _.get(this.user, "loyalty_tier_id.$oid");
    return tierId;
  }

  setLoyaltyTiers(tiers) {
    this.loyaltyTiers = tiers;
  }

  get loyaltyTier() {
    const tier = this.loyaltyTierId
      ? _.find(this.loyaltyTiers, ["id", this.loyaltyTierId])
      : null;
    return tier;
  }

  get loyaltyTierGold() {
    const tier = _.find(
      this.loyaltyTiers,
      (e) => _.lowerCase(e.name) === "gold"
    );
    return tier;
  }

  get loyaltyTierBronze() {
    const tier = _.find(
      this.loyaltyTiers,
      (e) => _.lowerCase(e.name) === "bronze"
    );
    return tier;
  }

  get loyaltyTierSilver() {
    const tier = _.find(
      this.loyaltyTiers,
      (e) => _.lowerCase(e.name) === "silver"
    );
    return tier;
  }

  get loyaltyTierBronzeLimitValue() {
    const tier = _.get(this.loyaltyTierBronze, "limit_value", 0);
    return tier;
  }

  get loyaltyTierSilverLimitValue() {
    const tier = _.get(this.loyaltyTierSilver, "limit_value", 0);
    return tier;
  }

  get loyaltyTierGoldLimitValue() {
    const tier = _.get(this.loyaltyTierGold, "limit_value", 0);
    return tier;
  }

  get loyaltyTierLimitValue() {
    const tier = this.loyaltyTierId
      ? _.get(this.loyaltyTier, "limit_value", 0)
      : 0;
    return tier;
  }

  get loyaltyTierName() {
    const name =
      this.loyaltyTierId && !_.isEmpty(this.loyaltyTier)
        ? _.get(this.loyaltyTier, "name")
        : _.get(this.user, "loyalty_tier_name");
    return name;
  }

  get isLoyaltyTierValid() {
    const name = this.loyaltyTierName || "";
    return _.includes(["gold", "silver", "bronze"], _.toLower(name));
  }

  get userSpendings() {
    return Number(this.userLoyaltyTierProgress.loyalty_tier_progress);
  }

  get userSpendings2() {
    return this.userSpendings / 100;
  }

  get currentTierAsperSpendings() {
    const orderedLoyaltyTiers = _.orderBy(
      this.loyaltyTiers,
      "limit_value",
      "asc"
    );
    let currentTierAsperSpendings = {};
    _.forEach(orderedLoyaltyTiers, (item) => {
      let tierLimit = Number(_.get(item, "limit_value", 0));
      if (isNaN(tierLimit)) {
        tierLimit = 0;
      }
      tierLimit = tierLimit / 100;
      if (tierLimit <= 0) {
        currentTierAsperSpendings = _.find(
          orderedLoyaltyTiers,
          (e) => _.lowerCase(e.name) === "bronze"
        );
      } else if (this.userSpendings2 >= tierLimit) {
        currentTierAsperSpendings = { ...item, name: _.toLower(item.name) };
      }
    });

    return currentTierAsperSpendings;
  }

  get orderCount() {
    return _.get(this.user, "order_count");
  }

  get statsOrderCount() {
    return _.get(this.user, "user_order_stat.total_count");
  }

  get lastOrderDate() {
    return _.get(this.user, "last_order_time");
  }

  get statsTotalBillAmount() {
    let val = _.get(this.user, "user_order_stat.total_bill_amount") / 100;
    if (isNaN(val)) {
      val = 0;
    }
    return val;
  }

  get statsTotalBillAmount1() {
    return utils.formatPriceToLocal(this.statsTotalBillAmount);
  }

  static fake() {
    return new UserModel({
      user: {
        name: "name",
        email: "email",
        phoneNumber: "phoneNumber",
      },
    });
  }
}
