import React, { useContext, useState, useEffect, useRef } from "react";
import { View, Keyboard } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import utils from "../../utils/index";
import _ from "lodash";
import LoginHelper from "@presto-helpers/LoginHelper";
import UserContext from "@presto-contexts/UserContext";
import { alertBox } from "@presto-common/Alert";
import MyPurchaseRequests from "@presto-screen-components/PurchaseRequests/MyPurchaseRequests";
import PurchaseOrdersManager from "@presto-services/features/purchase_orders/PurchaseOrdersManager";
import asyncify from "@presto-common/Asyncify";
import I18n from "i18n-js";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import ItemPurchaseRequestTable from "@presto-screen-components/PurchaseRequests/ItemPurchaseRequestTable";
import SearchManager from "@presto-services/features/search/SearchManager";
import { AxiosError } from "axios";
import PurchaseOrderHelper from "@presto-helpers/PurchaseOrderHelper";

const AsyncPurchaseOrdersManager = asyncify(PurchaseOrdersManager);
const AsyncEmployeeManager = asyncify(EmployeeManager);
const AsyncSearchManager = asyncify(SearchManager);

export default function PurchaseOrders({
  sideNavigationRef,
  parentRef,
  tabs,
  setTabs,
  parentLoading,
  setParentLoading,
}) {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const merchantId = LoginHelper.getUserMerchantId(user);
  const extraOptions = useRef({
    selectedItemPurchaseRequests: [],
    draftPo: null,
    approvedItemPurchaseRequests: [],
  });
  const [activeRow, setActiveRow] = useState(null);
  const [
    selectedItemPurchaseRequests,
    setSelectedItemPurchaseRequests,
  ] = useState([]);
  const [itemPurchaseRequests, setItemPurchaseRequests] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [actionHandler, setActionHandler] = useState(null);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (actionHandler) {
      onActionHandle();
    }
  }, [actionHandler, purchaseOrders, purchaseOrder, activeRow]);

  const init = () => {
    parentRef.current.onPressTab = (tab) => {
      setActionHandler({ type: "ON_PRESS_TAB", tab });
    };
    parentRef.current.onChangeOfHeaderTabs = (id) => { };
    parentRef.current.onScreenPressEvent = () => {
      Keyboard.dismiss();
    };
    parentRef.current.onPressNetworkToggle = () => { };

    const tabStyles = {
      tabisActiveTextExtraStyles: {
        color: "#212123",
      },
    };

    setTabs([
      {
        id: "awaiting_approval",
        text: "Pending",
        isActive: true,
        defaultStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
      },
      ...[
        {
          id: "approved",
          text: I18n.t("screen_messages.purchase_requests.status.approved"),
        },
        {
          id: "delivered",
          text: I18n.t("screen_messages.purchase_requests.status.delivered"),
        },
        {
          id: "closed",
          text: I18n.t("screen_messages.purchase_requests.status.closed"),
        },
        {
          id: "rejected",
          text: I18n.t("screen_messages.purchase_requests.status.rejected"),
        },
      ].map((item) => {
        return {
          ...item,
          ...tabStyles,
        };
      }),
    ]);

    fetchItemPurchaseRequest();
    fetchSuppliers();
    getOrCreateAndSetPendingDraftPo();
  };

  const fetchSuppliers = () => {
    const onSuccess = (response) => {
      setSuppliers(response.data);
    };

    const onError = (error) => {
      console.log("PurchaseOrders->fetchSuppliers->error", error);
    };

    const params = {};
    EmployeeManager.suppliers(params, onSuccess, onError);
  };

  const reloadLeftSideSection = () => {
    let tab = _.find(tabs, ["isActive", true]);

    let tabId = tab.id;
    let promiseItem = null;

    if (tabId === "awaiting_approval") {
      fetchItemPurchaseRequest();
      setPurchaseOrders([]);
    }

    if (tabId === "approved") {
      promiseItem = purchaseOrderObject.listApproved();
    }

    if (tabId === "delivered") {
      promiseItem = purchaseOrderObject.listDelivered();
    }

    if (tabId === "closed") {
      promiseItem = purchaseOrderObject.listClosed();
    }

    if (tabId === "rejected") {
      promiseItem = purchaseOrderObject.listRejected();
    }

    setParentLoading(true);
    if (promiseItem) {
      promiseItem
        .then((rows) => {
          setParentLoading(false);
          setPurchaseOrders(rows);
        })
        .catch((error) => {
          setParentLoading(false);
          console.log("PurchaseOrders->reloadLeftSideSection->error", error);
        });
    }
  };

  const onActionHandle = () => {
    if (actionHandler.type === "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL") {
      handleSideNavigation();
      setActionHandler(null);
    }

    if (actionHandler.type === "ON_PRESS_TAB") {
      onPressTab(actionHandler.tab);
      setActionHandler(null);
    }

    if (actionHandler.type === "RELOAD_LEFT_SIDE_SECTION") {
      reloadLeftSideSection();
      setActionHandler(null);
    }

    if (
      actionHandler.type ===
      "RELOAD_LEFT_SIDE_SECTION_AND_RESET_SIDE_NAVIGATION"
    ) {
      setPurchaseOrder(null);
      reloadLeftSideSection();
      setTimeout(() => {
        setActionHandler({
          type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
        });
      }, 200);
    }
  };

  const onPressTab = (tab) => {
    let tempTabs = [];
    setTabs((prev) => {
      tempTabs = _.cloneDeep(prev);
      return prev;
    });

    tempTabs = _.map(tempTabs, (tabItem) => {
      let isActive = tabItem.id === tab.id;
      return {
        ...tabItem,
        isActive,
      };
    });

    setTabs(tempTabs);
    afterPressTab(tab);
  };

  const afterPressTab = (tab) => {
    setTimeout(() => {
      setActionHandler({
        type: "RELOAD_LEFT_SIDE_SECTION_AND_RESET_SIDE_NAVIGATION",
      });
    }, 200);
  };

  const handleSideNavigation = async () => {
    let activeTab = getActiveTab();
    let isActiveTabAwaitingApproval = activeTab?.id == "awaiting_approval";
    let status = isActiveTabAwaitingApproval
      ? null
      : _.get(purchaseOrder, "status");
    const supplierOrder = purchaseOrder
      ? await purchaseOrderObject.getSupplierOrder(purchaseOrder)
      : null;

    let initial = isActiveTabAwaitingApproval
      ? getPurchaseRequestDetailInitial()
      : purchaseOrder;

    const params = {
      initial,
      parentLoading,
      setParentLoading,
      enableApproveButton: status === "AWAITING_APPROVAL",
      enableRejectButton: status === "AWAITING_APPROVAL",
      enableEditDeliveryButton: false,
      enableDownloadPO: _.includes(["APPROVED", "DELIVERED", "CLOSED"], status),
      enableMarkAsDeliveredButton: status === "APPROVED",
      enableMarkAsClosedButton: status === "DELIVERED",
      supplierOrder,
      onPressApproveButton: (po) => {
        setParentLoading(true);
        return purchaseOrderObject
          .markAsApproved(po)
          .then(({ success }) => {
            if (success) {
              callbackAfterPoStatusUpdate();
              alertBox("Purchase order moved to approved state");
            } else {
              alertBox("Failed to move Purchase order to approved state");
            }
            setParentLoading(false);
          })
          .catch((e) => setParentLoading(false));
      },
      onPressRejectButton: (po, rejectionReason) => {
        setParentLoading(true);
        return purchaseOrderObject
          .markAsRejected(po, rejectionReason)
          .then(({ success }) => {
            if (success) {
              callbackAfterPoStatusUpdate();
              alertBox("Purchase order moved to rejected state");
            } else {
              alertBox("Failed to move Purchase order to rejected state");
            }

            setParentLoading(false);
          })
          .catch((e) => setParentLoading(false));
      },
      onUpdateItem: (item, qty) => {
        if (purchaseOrder?.id) {
          return purchaseOrderObject.updateItemDeliveredQty(
            purchaseOrder,
            item,
            qty
          );
        } else {
          return onItemPurchaseRequestUpdate(item, qty);
        }
      },
      onRemoveItem: (item, qty) => {
        if (purchaseOrder?.id) {
          return purchaseOrderObject.updateItemDeliveredQty(
            purchaseOrder,
            item,
            qty
          );
        } else {
          return onItemPurchaseRequestUpdateRemove(item);
        }
      },
      onPressMarkAsDeliveredButton: (po) => {
        return purchaseOrderObject
          .markAsDelivered(po)
          .then(({ success }) => {
            if (success) {
              callbackAfterPoStatusUpdate();
              alertBox("Purchase order moved to delivered state");
            } else {
              alertBox("Failed to move Purchase order to delivered state");
            }

            setParentLoading(false);
          })
          .catch((e) => setParentLoading(false));
      },
      onPressmarkAsClosedButton: (po) => {
        return purchaseOrderObject
          .markAsClosed(po)
          .then((updatedPo) => {
            if (updatedPo.status === "CLOSED") {
              callbackAfterPoStatusUpdate();
              alertBox("Purchase order moved to closed state");
            } else {
              alertBox("Failed to move Purchase order to closed state");
            }
            setParentLoading(false);
          })
          .catch((e) => setParentLoading(false));
      },
      resetPurchaseRequest: () => { },
      reloadSideNav: () => {
        setActionHandler({
          type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
        });
      },
    };

    if (isActiveTabAwaitingApproval) {
      params.onGetTotalPrice = (poItem) => {
        let item = poItem.item;
        let itemPurchaseConfiguration = item.itemPurchaseConfiguration;
        let purchasePrice = Number(
          _.get(item, "supplierPrice.purchase_price", 0)
        );
        let price = purchasePrice * item.quantity;

        if (hasPurchaseOrderManagementPermission && itemPurchaseConfiguration) {
          const ratio = itemPurchaseConfiguration.sale_to_purchase_ratio;
          price = (purchasePrice * item.quantity) / ratio;
        }

        return purchasePrice > 0 ? utils.formattedCurrency(price) : "";
      };

      params.onSubmitButtonText = I18n.t(
        "screen_messages.purchase_orders.create_po"
      );
      params.onSubmit = async () => {
        let proxyPo = getPurchaseRequestDetailInitial();
        if (proxyPo.items.length) {
          const draftPo = new PurchaseOrderHelper.DraftPo(
            extraOptions.current.draftPo
          );
          setParentLoading(true);

          const result =
            extraOptions.current.draftPo || (await draftPo.create());

          if (result instanceof Error) {
            alertBox("", result.message);
          } else {
            extraOptions.current.draftPo = result;

            let responses = await draftPo.approveItemPurchases(proxyPo.items);
            let errors = _.filter(responses, (e) => _.has(e, "error"));
            setParentLoading(false);
            if (errors.length) {
              return alertBox("", _.get(errors, `0.message`));
            }

            let response = await draftPo.markAsConfirmed();

            if (_.has(response, "error")) {
              return alertBox("", response.message);
            }

            getOrCreateAndSetPendingDraftPo();

            extraOptions.current.selectedItemPurchaseRequests = [];
            fetchItemPurchaseRequest().then(() => {
              setActionHandler({
                type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
              });
            });
          }
        }
      };
    }

    sideNavigationRef.navigate("InitialPurchaseRequestDetail", params);
  };

  const hasPermissionToAccess = (key) =>
    _.get(user, `other_data.permissions.${key}`) === true;

  const hasPurchaseOrderManagementPermission = hasPermissionToAccess(
    "purchase_order_management"
  );

  const onItemPurchaseRequestUpdate = (purchaseItem, saleQuantity) => {
    return new Promise((resolve) => {
      let tempItems = extraOptions.current.selectedItemPurchaseRequests;
      extraOptions.current.selectedItemPurchaseRequests = _.map(
        tempItems,
        (selectedItem) => {
          if (purchaseItem.id === selectedItem.product.id) {
            selectedItem.product.quantity = saleQuantity;
            if (
              hasPurchaseOrderManagementPermission &&
              selectedItem.itemPurchaseRequest &&
              selectedItem.itemPurchaseConfiguration
            ) {
              let ratio = _.get(
                selectedItem.itemPurchaseConfiguration,
                "sale_to_purchase_ratio"
              );
              const fromSaleToPurchaseQty = Math.ceil(
                selectedItem.product.quantity / ratio
              );
              selectedItem.itemPurchaseRequest.purchase_quantity = fromSaleToPurchaseQty;
              selectedItem.itemPurchaseRequest.quantity =
                selectedItem.product.quantity;
            }
          }

          return {
            ...selectedItem,
          };
        }
      );
      resolve(getPurchaseRequestDetailInitial());
    });
  };

  const onItemPurchaseRequestUpdateRemove = (purchaseItem) => {
    return new Promise((resolve) => {
      extraOptions.current.selectedItemPurchaseRequests = _.filter(
        extraOptions.current.selectedItemPurchaseRequests,
        (selectedItem) => {
          return selectedItem.product.id !== purchaseItem.id;
        }
      );

      setSelectedItemPurchaseRequests(
        extraOptions.current.selectedItemPurchaseRequests
      );

      resolve(getPurchaseRequestDetailInitial());
    });
  };

  function PurchaseOrderObject() {
    const Manager = PurchaseOrdersManager;
    const AsyncManager = AsyncPurchaseOrdersManager;

    this.list = function (query) {
      const params = {
        purchase_orders: query,
      };
      return AsyncManager.list(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }

          const list = response.data.purchase_orders;
          return list;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->list->error", error);
          alertBox(error.message);
        });
    };

    this.listAwaitingApproval = () => {
      return this.list({ status: "AWAITING_APPROVAL" });
    };

    this.listApproved = () => {
      return this.list({ status: "APPROVED" }).then((rows) => {
        const draftPo = _.filter(rows, ["type", "DRAFT"]);
        let confirmedPo = _.filter(rows, ["type", "CONFIRMED"]);
        confirmedPo = _.map(confirmedPo, (item) => {
          let parentPo = _.find(draftPo, ["id", item.main_order_id]);
          return {
            ...item,
            ordered_by_hash: parentPo?.ordered_by_hash ?? { name: "" },
          };
        });
        return confirmedPo;
      });
    };

    this.listDelivered = () => {
      return this.list({ status: "DELIVERED" });
    };

    this.listClosed = () => {
      return this.list({ status: "CLOSED" });
    };

    this.listRejected = () => {
      return this.list({ status: "REJECTED" });
    };

    this.markAsApproved = (po) => {
      const params = {
        id: po.id,
      };
      return AsyncManager.approve(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const data = {
            success: response.data.success,
          };
          return data;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->markAsApproved->error", error);
          alertBox(error.message);
          return error;
        });
    };

    this.markAsRejected = (po, rejectionReason) => {
      const params = {
        id: po.id,
        purchase_order: {
          reject_reason: rejectionReason,
        },
      };
      return AsyncManager.reject(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const data = {
            success: response.data.success,
          };

          return data;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->markAsRejected->error", error);
          alertBox(error.message);
          return error;
        });
    };

    this.updateItemDeliveredQty = (po, item, qty) => {
      const params = {
        id: po.id,
        purchase_order: {
          line_item_id: item.id,
          delivered_quantity: qty,
          batch_number: item.batch_number,
          expiry_date: item.expiry_date,
        },
      };
      return AsyncManager.updateDeliveredQuantity(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const data = response.data;
          return data;
        })
        .catch((error) => {
          console.log(
            "PurchaseOrderObject->updateItemDeliveredQty->error",
            error
          );
          alertBox(error.message);
          return error;
        });
    };

    this.markAsDelivered = (po) => {
      const params = {
        id: po.id,
      };
      return AsyncManager.markAsDelivered(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const data = response.data;
          return data;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->markAsDelivered->error", error);
          alertBox(error.message);
          return error;
        });
    };

    this.markAsClosed = (po) => {
      const params = {
        id: po.id,
      };
      return AsyncManager.close(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const data = response.data;
          return data;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->markAsClosed->error", error);
          alertBox(error.message);
          return error;
        });
    };

    this.getSupplierOrder = (po) => {
      const params = { id: po.id };
      return AsyncManager.order(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const data = response.data;
          return data;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->getSupplierOrder->error", error);
          return null;
        });
    };

    this.createDraftPo = () => {
      const params = {};
      return AsyncManager.createDraftPo(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const data = response.data;
          return data;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->getSupplierOrder->error", error);
          return error;
        });
    };

    this.approveItemPurchases = (items) => {
      let promises = _.map(items, (item) => {
        console.log(item);
        console.log("00000-----11111");
        let { itemPurchaseRequest, supplierPrice } = item;
        let params = {
          approved_item_purchase: {
            quantity: itemPurchaseRequest.purchase_quantity,
            item_purchase_request_id: itemPurchaseRequest.id,
            draft_purchase_order_id: extraOptions.current.draftPo.id,
            supplier_id: supplierPrice.supplier_id,
          },
        };

        return AsyncEmployeeManager.approveItemPurchaseRequest(params).then(
          ([error, response]) => {
            if (error) {
              if (error instanceof AxiosError) {
                error = _.get(error, "response.data");
              }
              return error;
            }

            return response.data;
          }
        );
      });

      return Promise.all(promises);
    };
  }

  const callbackAfterPoStatusUpdate = () => {
    setActionHandler({ type: "RELOAD_LEFT_SIDE_SECTION" });
    setPurchaseOrder(null);
    setTimeout(() => {
      setActionHandler({
        type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
      });
    }, 200);
  };

  const getOrCreateAndSetPendingDraftPo = () => {
    setParentLoading(true);
    const draftPo = new PurchaseOrderHelper.DraftPo();
    draftPo.initiatedPo().then(async (obj) => {
      if (_.has(obj, "error")) {
        setParentLoading(false);
        let message =
          error instanceof AxiosError
            ? _.get(error, "response.data.message")
            : error.message;
        return alertBox("", message);
      }
      extraOptions.current.draftPo = !_.isEmpty(obj)
        ? obj
        : await draftPo.create();

      setParentLoading(false);
    });
  };

  const fetchItemPurchaseRequest = () => {
    setParentLoading(true);
    return AsyncEmployeeManager.itemPurchaseRequests({})
      .then(([error, response]) => {
        return _.get(response, "data", []);
      })
      .then(async (items) => {
        if (items) {
          let ids = _.map(items, "category_item_id");
          let tempItems = ids.length ? await getItemsByIds(ids) : [];

          if (tempItems) {
            tempItems = _.map(tempItems, (tempItem) => {
              let itemPurchaseRequestItem = _.find(items, [
                "category_item_id",
                tempItem.id,
              ]);
              return {
                itemPurchaseRequest: itemPurchaseRequestItem,
                product: tempItem,
              };
            });
          }
          setItemPurchaseRequests(tempItems);
          setParentLoading(false);
        }
      });
  };

  const getItemsByIds = (ids) => {
    let params = {
      merchant_id: merchantId,
      ids: ids,
      size: ids.length,
    };
    return AsyncSearchManager.fuzzySearchCategoryItem(params).then(
      ([error, response]) => {
        let items = _.get(response, "hits.hits", []);
        return _.map(items, "_source");
      }
    );
  };

  const getActiveTab = () => {
    let tempTab = _.find(tabs, (e) => e.isActive);
    return tempTab;
  };

  const getPurchaseRequestDetailInitial = () => {
    return PurchaseOrderHelper.getPuchaseOrderObjectAsProxy({
      items: extraOptions.current.selectedItemPurchaseRequests,
    });
  };

  const purchaseOrderObject = new PurchaseOrderObject();
  let activeTab = getActiveTab();

  return (
    <View>
      <View style={{ padding: 10 }}>
        {activeTab?.id === "awaiting_approval" ? (
          <ItemPurchaseRequestTable
            activeRow={activeRow}
            setActiveRow={setActiveRow}
            selectedItemIds={[]}
            onPressRow={(product) => {
              let tempItem = null;
              if (product.id !== activeRow?.id) {
                tempItem = product;
              }
              setActiveRow(tempItem);

              setActionHandler({
                type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
              });
            }}
            items={itemPurchaseRequests}
            setParentLoading={setParentLoading}
            selectedItemPurchaseRequests={selectedItemPurchaseRequests}
            setSelectedItemPurchaseRequests={(val) => {
              extraOptions.current.selectedItemPurchaseRequests = val;
              setSelectedItemPurchaseRequests(val);
            }}
            navigateToInitialPurchaseRequestDetail={() => {
              setActionHandler({
                type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
              });
            }}
          />
        ) : (
          <MyPurchaseRequests
            activeRow={activeRow}
            suppliers={suppliers}
            column2Title={
              _.some(tabs, ["id", "awaiting_approval"])
                ? I18n.t("screen_messages.orders.items")
                : I18n.t("screen_messages.suppliers.title2")
            }
            onPressActionButton={() => { }}
            onPressRow={(tempPurchaseOrder) => {
              let tempItem = null;
              if (tempPurchaseOrder.id !== activeRow?.id) {
                tempItem = tempPurchaseOrder;
              }
              setActiveRow(tempItem);
              setPurchaseOrder(tempPurchaseOrder);

              setActionHandler({
                type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
              });
            }}
            items={purchaseOrders}
            onEndReached={() => { }}
          />
        )}
      </View>
    </View>
  );
}
