import React, { useState, useContext, useEffect, useCallback } from "react";
import { View, Dimensions, ScrollView } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import LoadingContainer from "@presto-components/LoadingContainer";
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import OrderManager from "@presto-services/features/orders/OrderManager";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { VerticalSpacing } from "@presto-components/Spacing";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import utils from "../../utils/index";
import OrderReturnItemCard from "@presto-screen-components/OrderReturnItemCard/OrderReturnItemCard";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import { prestoAlert } from "../../common/Alert";
import KeyValueList from "@presto-components/KeyValueList/KeyValueList";
import useFocusEffect from "@presto-common/useFocusEffect";

export default function OrderDetails({ route, navigation }) {
  const params = route.params || {};
  const { order, customer } = params || {};
  const { theme } = useContext(ThemeContext);
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  const styles = getStyles(theme, windowWidth);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState(undefined);

  useFocusEffect(
    useCallback(() => {
      getOrderDetails();
    }, [])
  );

  const getOrderDetails = () => {
    setLoading(true);
    let params = {
      merchant_id: order?.merchant_id,
      order_id: order?.id,
      id_type: order?.id,
    };
    OrderManager.getOrdersByOrderId(
      params,
      (response) => {
        setOrders(response.data);
        setLoading(false);
      },
      (error) => {
        console.log("Product data Error: ", error);
        setLoading(false);
      }
    );
  };

  const alert = (title, message) =>
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );

  const onPressBack = () => {
    navigation.goBack();
  };

  const getTotalReturnPrice = () => {
    let price = 0;
    let tax = getTotalTaxAmount();
    let totalDiscount = getTotalDiscount();
    _.forEach(orders?.items, (item) => {
      if (item.return_count > 0) {
        price += item.rate * item.return_count;
      }
    });
    let val = _.sum([price, parseFloat(tax)]);
    let val2 = _.subtract(val, totalDiscount);

    if (val2 < 0 || isNaN(val2)) {
      val2 = 0;
    }

    return val2.toFixed(2);
  };

  const getTotalTaxAmount = () => {
    let tax = 0;
    _.forEach(orders?.items, (item) => {
      if (item.return_count > 0) {
        let totalAmount = item.return_count * item?.rate;
        tax += (parseInt(item?.tax_percentage) / 100) * totalAmount;
      }
    });
    return tax?.toFixed(2);
  };

  const getTotalReturnItems = () => {
    let count = 0;
    _.forEach(orders?.items, (item) => {
      if (item.return_count > 0) {
        count += item.return_count;
      }
    });
    return count;
  };

  const getTotalDiscount = () => {
    let val = Number(_.get(order, "aggregate_discount"));
    if (isNaN(val)) {
      val = 0;
    }

    val = val.toFixed(2);

    return val;
  };

  const renderItem = (lineItem, index) => {
    let isReturnSuccess =
      lineItem?.returned_quantity === lineItem?.delivered_quantity;
    lineItem.return_count = lineItem.return_count ? lineItem.return_count : 0;
    if (lineItem) {
      return (
        <OrderReturnItemCard
          key={`${lineItem?.reference_id}`}
          item={lineItem}
          variation={null}
          onUpdatecallback={onUpdatecallback}
          isReturnSuccess={isReturnSuccess}
        />
      );
    } else {
      return null;
    }
  };

  const onUpdatecallback = (item, count) => {
    setLoading(true);
    if (parseFloat(item?.returned_quantity)) {
      if (
        parseFloat(item?.returned_quantity) + count >
        parseFloat(item?.delivered_quantity)
      ) {
        alert(I18n.t("screen_messages.returns.cant_return_more_items"));
      } else {
        item.return_count = count;
      }
    } else {
      item.return_count = count;
    }
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const initiateOrderReturn = () => {
    let returnCountPresent = _.some(
      orders?.items,
      (ele) => ele.return_count > 0
    );
    if (!returnCountPresent) {
      alert(I18n.t("screen_messages.returns.please_select_items"));
      return;
    }

    let promises = _.map(orders?.items, async (item) => {
      if (item?.return_count > 0) {
        return new Promise((resolve, reject) => {
          var params = {
            order_return: {
              comment: "",
              order_id: order.id,
              line_item_id: item.id,
              quantity: item.return_count,
              return_reason: "User returned the item",
              return_reason_id: "",
              other_data: {},
            },
          };
          setLoading(true);
          EmployeeManager.createOrderItemReturn(
            params,
            (response) => {
              resolve(response.data);
              setLoading(false);
            },
            (error) => {
              reject(error);
              console.log("return item Error: ", error);
              setLoading(false);
            }
          );
        });
      }
    });

    Promise.all(promises).then(
      (responses) => {
        let compact = _.compact(responses);
        navigation.navigate("OrderReturn", {
          order: orders,
          customer: customer,
          returnId: compact[0]?.id,
        });
      },
      (error) => {
        alert(
          error?.message
            ? error?.message
            : I18n.t("error_messages.return_item_failure")
        );
        console.log("return item promose error: ", error);
        setLoading(false);
      }
    );
  };

  let cartInfo = {
    [I18n.t("screen_messages.returns.return_items")]: getTotalReturnItems(),
    [I18n.t("screen_messages.sub_total")]: getTotalReturnPrice(),
    [I18n.t("screen_messages.discount_text")]: utils.formattedCurrency(
      getTotalDiscount()
    ),
    [I18n.t("screen_messages.total_tax:")]: utils.formattedCurrency(
      getTotalTaxAmount()
    ),
  };

  const RenderBillCard = ({
    leftText,
    rightText,
    rightTextSize,
    fontStyle,
  }) => {
    return (
      <View style={{ flexDirection: "column", width: 350 }}>
        <KeyValueList dict={cartInfo} />
        <VerticalSpacing size={15} />
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <PrestoText size={16} fontStyle="500.medium" color={theme.paragraph}>
            {leftText}
          </PrestoText>
          <PrestoText
            size={rightTextSize ? rightTextSize : 16}
            fontStyle={fontStyle ? fontStyle : "500.medium"}
            color={theme.title}
          >
            {rightText}
          </PrestoText>
        </View>
      </View>
    );
  };

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: theme.screenBackgroundColor,
      }}
    >
      <View
        style={{
          backgroundColor: "#737682",
          height: "100%",
          borderTopLeftRadius: 18,
          borderTopRightRadius: 18,
        }}
      >
        <LoadingContainer loading={loading}>
          <View
            style={{
              backgroundColor: theme.topHeaderInactiveColor,
              height: "100%",
              position: "relative",
            }}
          >
            {/* <ScrollView
              style={{ width: "100%", flex: 1, height: windowHeight }}
              contentContainerStyle={{
                paddingTop: 10,
                paddingBottom: 100,
              }}
            >
            </ScrollView> */}
            {orders ? (
              <View style={{}}>{_.map(orders?.items, renderItem)}</View>
            ) : null}

            <View
              style={{
                backgroundColor: theme.white,
                // minHeight: 340,
                borderTopLeftRadius: 18,
                borderTopRightRadius: 18,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                width: 400,
                position: "absolute",
                bottom: 0,
              }}
            >
              <View style={{ paddingHorizontal: 30, paddingTop: 10 }}>
                <RenderBillCard
                  leftText={I18n.t("screen_messages.cart.invoice_amount")}
                  rightText={utils.formattedCurrency(getTotalReturnPrice())}
                  rightTextSize={24}
                  fontStyle={"600.semibold"}
                />
              </View>

              <View style={styles.footerContainer}>
                <View style={styles.footerInner}>
                  <View style={{ width: "100%", paddingTop: 10, paddingBottom: 10 }}>
                    <PrestoSolidButton
                      size="large"
                      buttonStyle="secondary"
                      title={I18n.t("screen_messages.returns.return")}
                      titleColor={theme.white}
                      titleFontStyle={"600.semibold"}
                      titleSize={24}
                      height={60}
                      borderRadius={12}
                      onPress={() => initiateOrderReturn()}
                      bgColor={theme.secondary}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </LoadingContainer>
      </View>
    </View>
  );
}

function getStyles(theme) {
  return {
    footerContainer: {
      paddingHorizontal: 20,
      bottom: 0,
      width: "100%",
    },
    footerInner: {
      flexDirection: "row",
    },
  };
}
