import React, {
  useState,
  useContext,
  useReducer,
  useCallback,
  useRef,
} from "react";
import { View, ScrollView, Pressable, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import CartContext from "@presto-contexts/CartContext";
import I18n from "i18n-js";
import useFocusEffect from "@presto-common/useFocusEffect";
import _ from "lodash";
import utils from "../../utils/index";
import PrestoText from "@presto-components/PrestoText";
import UserContext from "@presto-contexts/UserContext";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import { useAssets } from "presto-react-components";
import PrestoIcon from "@presto-components/PrestoIcon";
import TextInputDoubleButton from "@presto-components/TextInputButtons/TextInputDoubleButton";
import PaymentComponent from "@presto-screen-components/PosBooking/OrderPayment";
import OrderDataSources from "@presto-datasources/OrderDataSources";
import CategoryDataSources from "@presto-datasources/CategoryDataSources";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import CartCheckoutReview from "@presto-screen-components/CartCheckout/CartCheckoutReview";
import EmployeeDataSource from "@presto-datasources/EmployeeDataSource";
import Asyncify from "@presto-common/Asyncify";
import { useActionSheet } from "@expo/react-native-action-sheet";
import CartDataSources from "@presto-datasources/CartDataSources";
import NetworkContext from "@presto-contexts/NetworkContext";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import { OfflineSync } from "@presto-helpers/OfflineSync";

const AsyncWalletManager = Asyncify(WalletManager);

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_CATEGORY_ITEM":
      return { ...state, items: { ...state.items, [payload.id]: payload } };
  }
  return state;
}

export default function CartCheckout({ route, navigation }) {
  const params = route.params || {};
  const {
    customer,
    onChangeOfHeaderTabs,
    parentLoading,
    setParentLoading,
    onCompleteOrder,
  } = params || {};
  const isWeb = Platform.OS == 'web'
  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme);
  const { cart, getTotalPrice, getTotalTax, clearCart } = useContext(
    CartContext
  );
  const { isOnline } = isWeb ? { isOnline } : useContext(NetworkContext);
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    items: {},
  });
  const { items } = state;
  const { user } = useContext(UserContext);
  const [wallet, setWallet] = useState(null);
  const { svgs } = useAssets();
  const countOfItemInCart = cart?.items?.length > 0 ? cart?.items?.length : 0;
  const [paymentOption, setSelectedPaymentOption] = useState(undefined);
  const [referenceId, setReferenceId] = useState("");
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [employeeChoices, setEmployeeChoices] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [changeReturnForm, setChangeReturnForm] = useState({
    amountPaid: "",
    balance: "",
  });
  const { showActionSheetWithOptions } = useActionSheet();
  const isOfflineCartRef = useRef(false);
  const isMobile = utils.isMobile();

  useFocusEffect(
    useCallback(() => {
      if (cart && user) {
        CartDataSources.existsInOfflineCart(cart.id).then(
          (isOfflineCartValue) => {
            isOfflineCartRef.current = isOfflineCartValue;
            fetchEmployees();
            if (!isOfflineCartValue && isWeb ? true : isOnline()) {
              customerWalletInfo();
            }
            _.forEach(cart?.items, (lineItem) => {
              getCategoryItem(lineItem.item_id);
            });
          }
        );
      }
    }, [cart, user])
  );

  const fetchEmployees = () => {
    const onSuccess = (response) => {
      setParentLoading(false);
      let choices = _.map(response.data.operators, (item) => {
        return {
          label: item.name,
          value: item.id,
          data: item,
        };
      });

      choices = [...choices];
      setEmployeeChoices(choices);

      const currentEmployee = _.find(choices, ["value", user.id]);
      if (currentEmployee) {
        setSelectedEmployee(currentEmployee.data);
      }
    };
    const onError = (error) => {
      setParentLoading(false);
      console.log("CartCheckout->fetchEmployees->error", error);
    };
    setParentLoading(true);
    EmployeeDataSource.nearByOperators(
      {
        enableOffline: isOfflineCartRef.current,
      },
      onSuccess,
      onError
    );
  };

  const customerWalletInfo = () => {
    WalletManager.getEmployeeWalletInfo(
      { id: customer.id },
      (response) => {
        setWallet(response.data);
      },
      (error) => {
        console.log("customerWalletInfo error", error);
      }
    );
  };

  const doWalletDebit = async (order) => {
    let params = {
      id: customer.id,
      debit: {
        transaction_amount: order.total_amount * 100,
        transaction_id: order.id,
        transaction_type: "Order",
        comment: referenceId,
      },
    };
    const [error, response] = await AsyncWalletManager.makeUserPayment(params);
    return [error, response];
  };

  const getCategoryItem = (id) => {
    CategoryDataSources.searchCategoryItem(
      { id, enableOffline: isOfflineCartRef.current },
      (response) => {
        if (response.hits.hits.length > 0) {
          dispatch({
            type: "SET_CATEGORY_ITEM",
            payload: response.hits.hits[0]._source,
          });
        }
      },
      () => { }
    );
  };

  const getTotalPayable = () => {
    const val = Number(cart?.total_price) / 100;
    return val.toFixed(2);
  };

  const selectedPaymentOption = (option) => {
    setSelectedPaymentOption(option);
  };

  const paymentReferenceId = (id) => {
    setReferenceId(id);
  };

  const viewAllCoupon = () => {
    navigation.navigate("CouponApplyScreen", {
      merchant_id: cart?.merchant_id,
      cartType: "cart",
      customer: customer,
      offlineEnabledAPIForCouponListing: isOfflineCartRef.current,
    });
  };

  const getLineItems = () => {
    let array = [];
    _.forEach(cart?.items, (item) => {
      const variation = item.variation;
      let rate = item.rate / 100;
      let totalPrice = item.total_price;
      if (!_.isEmpty(variation)) {
        totalPrice = _.get(variation, "price.total_price");
        rate = _.get(variation, "price.total_price");
      }

      if (isNaN(totalPrice)) {
        throw new Error("Item total price cannot be zero");
      }

      array.push({
        item_id: item.item_id,
        quantity: item.quantity,
        total_price: rate,
        variation_id: item.variation ? item.variation.id : "",
      });
    });
    return array;
  };

  const syncOrders = () => {
    OrderDataSources.syncOrders(
      {},
      (response) => { },
      (error) => {
        console.log("CartCheckout.onPlaceOrder.error", error);
      }
    );
    setTimeout(function () {
      const offlineSync = new OfflineSync(user);
      const force = true;
      offlineSync.syncCatalog(force);
      console.log("Sync in progress");
    }, 10000);
  };

  const removeHeaderTabs = () => {
    const id = cart.id;
    onChangeOfHeaderTabs(id);
  };

  const completeOrder = () => {
    onPlaceOrder((order) => {
      if (order) {
        if ("ONLINE" === order.payment_type || paymentOption === "WALLET") {
          let walletPromise = null;
          if (paymentOption === "WALLET") {
            walletPromise = doWalletDebit(order).then((...args) => {
              createCompositePayment(order);
            });
          } else {
            createCompositePayment(order);
          }
        } else {
          updateOrderStatus({}, order);
        }
      }
    });
  };

  const onPlaceOrder = (successCallback) => {
    if (!paymentOption) {
      alert(I18n.t("error_messages.payments.choose_payment_method"));
      return;
    }
    setParentLoading(true);
    var orderParams = {
      merchant_id: customer?.merchant_id,
      order: {
        outlet_id: "",
        merchant_id: customer?.merchant_id,
        line_items: getLineItems(),
        instructions: "",
        payment_type: paymentOption == "CASH" ? "COD" : "ONLINE",
        source: "",
        tax_amount: getTotalTax(),
        total_amount: getTotalPrice(),
        address: { line1: "No address available" },
        user: customer,
        coupon_code: cart?.coupon_code,
        aggregate_discount: cart.total_discounts,
      },
    };

    if (selectedEmployee) {
      orderParams.ordering_employee_id = selectedEmployee.id;
    }

    OrderDataSources.placeAgentOrder(
      orderParams,
      (response) => {
        successCallback(response.data);
        setParentLoading(false);
        clearCart().then((...args) => {
          removeHeaderTabs();
        });
      },
      (error) => {
        setParentLoading(false);
        console.log("place order error :>> ", error);
        alert(error.message);
      }
    );
  };

  const createCompositePayment = (order) => {
    setParentLoading(true);
    let params = {
      composite_payment: {
        user_id: order.user_id,
        payment_type: order.payment_type,
        merchant_id: order.merchant_id,
        source: "MOBILE",
        targets: [
          {
            id: order?.id,
            type: "order",
          },
        ],
      },
    };

    OrderDataSources.createAgentCompositePayment(
      params,
      (response) => {
        if (response.data) {
          createPayment(response.data, order);
        }
      },
      (error) => {
        console.log("createCompositePayment error", error);
        setParentLoading(false);
      }
    );
  };

  const createPayment = (compositePayment, order) => {
    let createParams = {
      _order_id: order.id,
      payment: {
        type: paymentOption,
        reference_id: referenceId,
        provider: "razorpay",
      },
      payable_type: "CompositePayment",
      payable_id: compositePayment.id,
    };
    setParentLoading(true);
    OrderDataSources.createPayment(
      createParams,
      (response) => {
        paymentSuccess(response.data, order);
      },
      (error) => {
        console.log("create payment error", error);
        removeHeaderTabs();
        paymentFailure(error);
        setParentLoading(false);
      }
    );
  };

  const paymentSuccess = (data, order) => {
    let successParams = {
      _order_id: order.id,
      payment_id: data.id,
    };
    setParentLoading(true);
    OrderDataSources.successPayment(
      successParams,
      (response) => {
        if (response?.data) {
          updateOrderStatus(data, order);
        }
        setParentLoading(false);
      },
      (error) => {
        paymentFailure(error);
        setParentLoading(false);
      }
    );
  };

  const updateOrderStatus = (data, order) => {
    var params = {
      merchant_id: order.merchant_id,
      order_id: order.id,
      status: "DELIVERED",
    };
    setParentLoading(true);
    OrderDataSources.updateCCOrdersStatus(
      params,
      (response) => {
        setParentLoading(false);
        if (isWeb) {
          navigation.navigate("OrderSuccess", { order, cart, paymentOption });
        }
        if (_.isFunction(onCompleteOrder)) {
          onCompleteOrder(order);
        }
        syncOrders();
        removeHeaderTabs();
        navigation.navigate("OrderSuccess", { order, cart, paymentOption });
      },
      (error) => {
        paymentFailure(error);
        console.log("updateCCOrdersStatus error", error);
        setParentLoading(false);
      }
    );
  };

  const paymentFailure = (error) => {
    setPaymentFailed(true);
    alert(error.message);
    setParentLoading(false);
  };

  const onPressBack = () => {
    navigation.navigate("CustomerSearch");
  };

  const showOptions = (fieldName, callBack) => {
    let options = [],
      choices = [],
      keyName = "label";
    if (fieldName === "employeeChoices") {
      choices = employeeChoices;
    }

    options = _.map(choices, keyName);
    if (_.isArray(options)) {
      options.push(I18n.t("screen_messages.common.cancel_text"));
    }

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex: options.length,
      },
      (buttonIndex) => {
        let item = _.get(choices, buttonIndex, null);
        callBack(item);
      }
    );
  };

  const getChangeToBeReturned = () => {
    let amountPaid = Number(changeReturnForm.amountPaid);
    if (isNaN(amountPaid)) {
      amountPaid = 0;
    }
    let balance = amountPaid - getTotalPayable();

    if (isNaN(balance) || balance < 0) {
      balance = 0;
    }
    return balance;
  };

  const getChangeToBeReturnedFormatted = () => {
    return utils.formattedCurrencyToRoundOff(getChangeToBeReturned() * 100, "");
  };

  const Separator = () => (
    <View style={{ paddingVertical: 3 }}>
      <LineDivider lineHeight={1} width="100%" dividerColor="#D9DBE2" />
    </View>
  );

  const renderTextBox = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <View style={{ flex: 1 }}>
          <PrestoText fontStyle="500.medium" size={16} color="#848484">
            {I18n.t("screen_messages.common.cash_received")}
          </PrestoText>
        </View>

        <View style={{ width: 120 }}>
          <PrestoTextInput
            style={{
              textInputHeight: 32,
              textInputWidth: 120,
              borderRadius: 6,
            }}
            theme={theme}
            value={changeReturnForm.amountPaid}
            keyboardType={"numeric"}
            onChange={({ nativeEvent: { text } }) => {
              setChangeReturnForm({ ...changeReturnForm, amountPaid: text });
            }}
            backgroundColor={theme.white}
            textAlign="right"
          />
        </View>
      </View>
    );
  };

  const renderChangeToReturnForm = () => {
    return (
      <View
        style={{
          borderTopWidth: 1,
          borderTopColor: "#727581",
          paddingTop: 10,
        }}
      >
        {renderTextBox()}

        <View style={{ paddingTop: 10 }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flex: 1 }}>
              <PrestoText fontStyle="500.medium" size={16} color="#848484">
                {I18n.t("screen_messages.common.change")}
              </PrestoText>
            </View>

            <View style={{ flex: 1, alignItems: "flex-end" }}>
              <PrestoText fontStyle="500.medium" size={16} color="#51BC72">
                {getChangeToBeReturnedFormatted()}
              </PrestoText>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const renderActionButtonsForPaymentOptionAsCash = () => {
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              height: 63,
              flex: 1,
              paddingRight: 10,
            }}
          >
            <Pressable
              style={{
                backgroundColor: "#737682",
                borderRadius: 12,
                height: "100%",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              onPress={() => setSelectedPaymentOption(undefined)}
            >
              <View>
                <svgs.BackLeftArrow color={theme.white} />
              </View>
            </Pressable>
          </View>

          <View style={{ height: 63, flex: 4 }}>
            <Pressable
              style={{
                paddingHorizontal: theme.primaryPadding,
                backgroundColor: "#F17B8D",
                borderRadius: 12,
                height: "100%",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              onPress={() => completeOrder()}
            >
              <View>
                <PrestoText
                  fontStyle="600.semibold"
                  size={20}
                  color={theme.white}
                >
                  {I18n.t("screen_messages.common.done")}
                </PrestoText>
              </View>
            </Pressable>
          </View>
        </View>
      </View>
    );
  };

  return (
    <View style={{ backgroundColor: theme.screenBackgroundColor }}>
      <View
        style={{
          backgroundColor: "#737682",
          borderTopLeftRadius: isMobile ? 0 : 18,
          borderTopRightRadius: isMobile ? 0 : 18,
          height: "100%",
        }}
      >
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "flex-end",
          }}
        >
          <View style={styles.container}>
            <View style={styles.contentContainer}>
              <View
                style={{
                  flexDirection: "row",
                  paddingHorizontal: theme.containerPadding,
                  paddingTop: 20,
                }}
              >
                <View style={styles.customerCard}>
                  <View style={{ padding: theme.containerPadding }}>
                    <PrestoText
                      size={16}
                      fontStyle="500.medium"
                      color={theme.title}
                    >
                      {customer?.name}
                    </PrestoText>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        paddingTop: 10,
                      }}
                    >
                      {customer?.phone_number ? (
                        <>
                          <PrestoIcon
                            icon={
                              <svgs.CallIcon
                                color={theme.subHeader}
                                height={20}
                                width={20}
                              />
                            }
                          />
                          <PrestoText
                            extraStyle={{ paddingLeft: 5 }}
                            fontStyle="600.semibold"
                            size={12}
                            color={theme.header}
                          >
                            {customer?.phone_number}
                          </PrestoText>
                        </>
                      ) : null}
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  paddingHorizontal: theme.containerPadding,
                  paddingTop: 10,
                }}
              >
                <View style={{ height: 40 }}>
                  <TextInputDoubleButton
                    placeholder={I18n.t(
                      "screen_messages.coupons.enter_coupon_code"
                    )}
                    searchText={cart?.coupon_code}
                    onNextPress={() => viewAllCoupon()}
                    hidePlus={true}
                    isEditable={false}
                    onSearchText={(text) =>
                      dispatch({ type: "SET_COUPON_TEXT", payload: text })
                    }
                    iconBgColor={theme.primary}
                    extraStyle={{
                      borderWidth: 0,
                      borderRadius: 12,
                    }}
                    arrowButtonContainerStyles={{
                      borderTopRightRadius: 12,
                      borderBottomRightRadius: 12,
                    }}
                    arrowButtonProps={{
                      borderTopRightRadius: 12,
                      borderBottomRightRadius: 12,
                    }}
                    textInputStyle={{
                      backgroundColor: "#D8D8D8",
                      borderTopLeftRadius: 12,
                      borderBottomLeftRadius: 12,
                      fontSize: 14,
                      color: "#999999",
                    }}
                  />
                </View>
              </View>

              <View
                style={{
                  paddingTop: 10,
                  paddingHorizontal: theme.containerPadding,
                  flex: 1,
                }}
              >
                <View style={{ height: 40 }}>
                  <TextInputDoubleButton
                    placeholder={I18n.t(
                      "screen_messages.employee.choose_employee"
                    )}
                    isEditable={false}
                    searchText={selectedEmployee ? selectedEmployee.name : ""}
                    onSearchText={(text) => { }}
                    onNextPress={() => {
                      showOptions("employeeChoices", (item) => {
                        if (item && item.data) {
                          setSelectedEmployee(item.data);
                        } else {
                          setSelectedEmployee(null);
                        }
                      });
                    }}
                    hidePlus={true}
                    hideArrow={false}
                    extraStyle={{
                      borderWidth: 0,
                      borderRadius: 12,
                    }}
                    arrowButtonContainerStyles={{
                      borderTopRightRadius: 12,
                      borderBottomRightRadius: 12,
                    }}
                    arrowButtonProps={{
                      borderTopRightRadius: 12,
                      borderBottomRightRadius: 12,
                    }}
                    textInputStyle={{
                      backgroundColor: "#D8D8D8",
                      borderTopLeftRadius: 12,
                      borderBottomLeftRadius: 12,
                      fontSize: 14,
                      color: "#999999",
                    }}
                    iconBgColor={theme.primary}
                  />
                </View>
              </View>
            </View>
            <View style={styles.footerContainer}>
              <View style={{ marginTop: 10 }}>
                {cart != null && !_.isEmpty(cart.items) ? (
                  <CartCheckoutReview cart={cart} />
                ) : null}
              </View>

              {paymentOption == "CASH" ? renderChangeToReturnForm() : null}

              <View style={{ paddingTop: 10, paddingBottom: 20 }}>
                {paymentOption === "CASH" ? (
                  renderActionButtonsForPaymentOptionAsCash()
                ) : (
                  <PaymentComponent
                    setLoading={setParentLoading}
                    cart={cart}
                    walletInfo={wallet}
                    customer={customer}
                    selectedPaymentOption={selectedPaymentOption}
                    paymentReferenceId={paymentReferenceId}
                    paymentOption={paymentOption}
                    referenceId={referenceId}
                    completeOrder={completeOrder}
                    isOfflineCart={isOfflineCartRef.current}
                  />
                )}
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
}

function getStyles(theme) {
  return {
    customerCard: {
      backgroundColor: theme.white,
      ...theme.darkShadow,
      width: "100%",
      borderRadius: 12,
    },
    container: {},
    contentContainer: {},
    footerContainer: {
      ...theme.darkShadow,
      paddingHorizontal: 20,
      width: "100%",
      marginTop: 10,
      backgroundColor: theme.white,
      borderTopRightRadius: 18,
      borderTopLeftRadius: 18,
    },
  };
}
