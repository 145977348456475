import React, { useContext } from "react";
import { View, StyleSheet } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import { PrestoSolidButton } from "@presto-components/PrestoButton";

export default function ActionButtons({
  btns,
  commonBtnProps,
  chunks = 2,
  spacing = 10,
}) {
  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme);
  const btnsChunks = _.chunk(btns, chunks);

  const Btn = ({ btn, commonBtnProps }) => {
    return <PrestoSolidButton {...commonBtnProps} {...btn} />;
  };

  const BtnColumn = ({ btn, commonBtnProps }) => {
    return (
      <View style={styles.column}>
        <Btn btn={btn} commonBtnProps={commonBtnProps} />
      </View>
    );
  };

  const Gap = () => <View style={{ width: 10 }} />;

  const Spacing = ({ spacing }) => <View style={{ height: spacing }} />;

  const BtnRow = ({ btnItems, commonBtnProps }) => {
    return (
      <View style={styles.row}>
        {_.map(btnItems, (btn, index) => {
          return (
            <React.Fragment key={index}>
              <BtnColumn btn={btn} commonBtnProps={commonBtnProps} />
              {index != btnItems.length - 1 ? <Gap /> : null}
            </React.Fragment>
          );
        })}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {_.map(btnsChunks, (btnItems, index) => {
        return (
          <View>
            <BtnRow
              key={index}
              btnItems={btnItems}
              commonBtnProps={commonBtnProps}
            />
            <Spacing spacing={spacing} />
          </View>
        );
      })}
    </View>
  );
}

function getStyles(theme) {
  return StyleSheet.create({
    container: {},
    row: {
      flexDirection: "row",
      alignItems: "center",
    },
    column: {
      flex: 1,
    },
  });
}
