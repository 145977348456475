import React, { useContext, useState, useEffect } from "react";
import { View, Pressable, ScrollView } from "react-native";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import PrestoText from "@presto-components/PrestoText";
import ThemeContext from "@presto-contexts/ThemeContext";
import ClientManager from "@presto-services/features/client/ClientManager";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import _ from "lodash";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { alertBox } from "@presto-common/Alert";
import Utils from "@presto-services/common/Utils";
import config from "@presto-common/config";
import ClientDataSource from "@presto-datasources/ClientDataSource";
import DBUserManager from "@presto-db-managers/DBUserManager";
import DatePickerComponent from "@presto-components/CalendarComponent/DatePickerComponent";
import { useActionSheet } from "@expo/react-native-action-sheet";
import utils from "../../utils";

export default function AddCustomer({ route, navigation }) {
  const { title } = route?.params;
  const {
    initialForm,
    setParentLoading,
    onSaveCustomerInfoCallback,
  } = route?.params;
  var textType = Utils.getSearchType(title || "");
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { showActionSheetWithOptions } = useActionSheet();
  const [focusedItem, setFocusedItem] = useState(null);
  const [form, setForm] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    gender: null,
    birthday: null,
  });
  const isMobile = utils.isMobile();

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    let keyName = null,
      value = title,
      tempForm = {
        name: _.get(initialForm, "name"),
        phoneNumber: _.get(initialForm, "phone_number"),
        email: _.get(initialForm, "email")
          ? _.toLower(_.get(initialForm, "email"))
          : "",
        gender: _.get(initialForm, "gender", null),
        birthday: _.get(initialForm, "birthday", null),
      };
    if (value) {
      if (textType === "name") {
        keyName = "name";
      } else if (textType === "email") {
        keyName = "email";
      } else if (textType === "phone_number") {
        keyName = "phoneNumber";
      }
    }
    if (keyName) {
      tempForm[keyName] = value;
    }
    setForm(tempForm);
  };

  const userInfo = {
    user: {
      name: form.name,
      phone_number: form.phoneNumber,
      email: form.email,
      gender: form.gender,
      birthday: form.birthday,
    },
  };

  const validateSubmitData = () => {
    let isValid = true;
    let error = {};
    let phoneNumber = _.get(userInfo, "user.phone_number", "");

    if (_.isEmpty(userInfo?.user.name)) {
      isValid = false;
      error.title = "";
      error.message = I18n.t("error_messages.common.name_required");
    } else if (_.isEmpty(userInfo?.user.phone_number)) {
      isValid = false;
      error.title = I18n.t(
        "error_messages.login_messages.phone_number_missing"
      );
      error.message = I18n.t(
        "error_messages.common.please_enter_phone_no_to_proceed"
      );
    } else if (phoneNumber?.length < 9 || phoneNumber?.length > 13) {
      isValid = false;
      error.title = I18n.t("error_messages.login_messages.verify_phone_no");
      error.message = I18n.t(
        "error_messages.login_messages.please_enter_valid_phone_no_to_proceed"
      );
    }

    return { isValid, error };
  };

  const onAddCustomer = () => {
    const { isValid, error } = validateSubmitData();
    if (!isValid) {
      return alertBox(error.title, error.message);
    }
    let params = { user: userInfo?.user };
    if (params.user.phone_number.length != 10) {
      alertBox(
        I18n.t("screen_messages.valid_phone_number_title"),
        I18n.t("screen_messages.valid_phone_number_text")
      );
      return;
    }

    if (_.isEmpty(_.get(params, "user.email"))) {
      const applicationName = config.applicationName
        .replace(/[ ]/g, "")
        .toLowerCase();
      params.user.email = `${params.user.phone_number}@${applicationName}.com`;
    }

    setParentLoading(true);
    ClientDataSource.createClient(
      params,
      (response) => {
        update(response?.data);
        setParentLoading(false);
      },
      (error) => {
        setParentLoading(false);
        console.log("createClient Error: ", error);
        alertBox(error.message);
      }
    );
  };

  const update = (client) => {
    var params = {
      user: { ...client, birthday: userInfo?.user.birthday },
    };
    ClientDataSource.updateClient(
      params,
      (response) => {
        alertBox(I18n.t("success_messages.customers.created_message"));
        DBUserManager.syncToRemote();
        if (_.isFunction(onSaveCustomerInfoCallback)) {
          const user = params.user;
          onSaveCustomerInfoCallback(user);
        } else {
          onPressBack();
        }
      },
      (error) => {
        console.log("UpdateClients data Error: ", error);
        alertBox(error.message);
      }
    );
  };

  const onUpdateCustomer = (userInfo) => {
    const { isValid, error } = validateSubmitData();
    if (!isValid) {
      return alertBox(error.title, error.message);
    }

    const tempUser = _.omit(userInfo?.user, ["_error", "_sync_status"]);
    var params = {
      user: {
        ...tempUser,
        birthday: userInfo?.user?.birthday,
        id: initialForm.id,
      },
      address: { line1: "No address available" },
      merchant_id: initialForm.merchant_id,
    };

    setParentLoading(true);
    ClientDataSource.updateClient(
      params,
      (response) => {
        setParentLoading(false);
        DBUserManager.syncToRemote();
        alertBox(I18n.t("success_messages.customers.updated_message"));
        if (_.isFunction(onSaveCustomerInfoCallback)) {
          onSaveCustomerInfoCallback();
        }
        onPressBack();
      },
      (error) => {
        setParentLoading(false);
        console.log("onUpdateCustomer->error", JSON.stringify(error));
        const msg = _.get(error, "message");
        alertBox("Error", msg);
      }
    );
  };

  const onPressBack = () => {
    navigation.goBack();
  };

  const chooseGender = () => {
    showActionSheetWithOptions(
      {
        options: [
          I18n.t("screen_messages.genders.male"),
          I18n.t("screen_messages.genders.female"),
          I18n.t("screen_messages.common.cancel_text"),
        ],
        cancelButtonIndex: 3,
      },
      (buttonIndex) => {
        if (_.includes([0, 1], buttonIndex)) {
          const gender = buttonIndex === 0 ? "male" : "female";
          setForm({ ...form, gender });
        } else {
          setForm({ ...form, gender: null });
        }
      }
    );
  };

  return (
    <View style={{ backgroundColor: theme.screenBackgroundColor }}>
      <ScrollView
        style={{
          backgroundColor: "#737682",
          borderTopLeftRadius: isMobile ? 0 : 18,
          borderTopRightRadius: isMobile ? 0 : 18,
          height: "100%",
        }}
      >
        <View style={{ padding: theme.containerPadding }}>
          {!initialForm ? (
            <PrestoText
              fontStyle="600.semibold"
              size={18}
              color={theme.white}
              extraStyle={{ marginBottom: theme.primaryPadding }}
            >
              {I18n.t("screen_messages.customers.add_new_customer")}
            </PrestoText>
          ) : null}

          <PrestoText
            fontStyle="600.semibold"
            extraStyle={{ marginTop: 10 }}
            color={theme.white}
          >
            {I18n.t("screen_messages.common.name_text")}*
          </PrestoText>
          <VerticalSpacing size={10} />
          <PrestoTextInput
            style={{
              borderWidth: 2,
              borderColor: focusedItem === "name" ? theme.primary : "#CCCCCC",
              textInputPaddingHorizontal: 10,
            }}
            theme={theme}
            placeholder=""
            value={form.name}
            keyboardType={"default"}
            onChange={({ nativeEvent: { text } }) =>
              setForm({ ...form, name: text })
            }
            backgroundColor={theme.white}
            borderWidth={2}
            textContentType={"name"}
            autoCompleteType={"name"}
            importantForAutofill={"yes"}
            onFocus={() => setFocusedItem("name")}
            onBlur={() => setFocusedItem(null)}
          />
          <VerticalSpacing size={10} />
          <PrestoText fontStyle="600.semibold" color={theme.white}>
            {I18n.t("screen_messages.common.phone_number")}*
          </PrestoText>
          <VerticalSpacing size={10} />
          <PrestoTextInput
            style={{
              borderWidth: 2,
              borderColor: focusedItem === "phone" ? theme.primary : "#CCCCCC",
              textInputPaddingHorizontal: 10,
            }}
            theme={theme}
            placeholder=""
            value={form.phoneNumber}
            keyboardType={"phone-pad"}
            onChange={({ nativeEvent: { text } }) =>
              setForm({ ...form, phoneNumber: text })
            }
            backgroundColor={theme.white}
            borderWidth={2}
            textContentType={"telephoneNumber"}
            autoCompleteType={"tel"}
            importantForAutofill={"yes"}
            onFocus={() => setFocusedItem("phoneNumber")}
            onBlur={() => setFocusedItem(null)}
          />
          <VerticalSpacing size={10} />
          <PrestoText fontStyle="600.semibold" color={theme.white}>
            {I18n.t("screen_messages.common.email")}
          </PrestoText>
          <VerticalSpacing size={10} />
          <PrestoTextInput
            style={{
              borderWidth: 2,
              borderColor: focusedItem === "email" ? theme.primary : "#CCCCCC",
              textInputPaddingHorizontal: 10,
            }}
            theme={theme}
            placeholder=""
            value={form.email}
            keyboardType={"email-address"}
            onChange={({ nativeEvent: { text } }) =>
              setForm({ ...form, email: text })
            }
            backgroundColor={theme.white}
            borderWidth={2}
            textContentType={"emailAddress"}
            autoCompleteType={"email"}
            importantForAutofill={"yes"}
            onFocus={() => setFocusedItem("email")}
            onBlur={() => setFocusedItem(null)}
          />
          <VerticalSpacing size={theme.primaryPadding} />
          <View style={{}}>
            <PrestoText fontStyle="600.semibold" color={theme.white}>
              {I18n.t("screen_messages.common.dob")}
            </PrestoText>
            <VerticalSpacing size={10} />
            <DatePickerComponent
              userDate={form.birthday ? new Date(form.birthday) : null}
              placeholder={I18n.t("error_messages.common.dob_required")}
              onDateChange={(value) => {
                if (_.isDate(value)) {
                  setForm({ ...form, birthday: value.toISOString() });
                } else {
                  setForm({ ...form, birthday: null });
                }
              }}
              onFocus={() => setFocusedItem("birthday")}
              onBlur={() => setFocusedItem(null)}
              textInputStyles={{
                borderWidth: 2,
                borderColor:
                  focusedItem === "birthday" ? theme.primary : theme.lightText,
                borderRadius: 8,
              }}
              textInputStyle={{
                borderRadius: 6,
              }}
              minimumDate={new Date()}
              showTimeInput={false}
              iconSize={35}
              showYearsDropdown={true}
              defaultToToday={false}
              textInputHeight={48}
            />
          </View>
          <VerticalSpacing size={theme.primaryPadding} />
          <View style={{}}>
            <PrestoText fontStyle="600.semibold" color={theme.white}>
              {I18n.t("screen_messages.common.gender")}
            </PrestoText>
            <VerticalSpacing size={10} />

            <Pressable onPress={chooseGender}>
              <View pointerEvents="none">
                <PrestoTextInput
                  style={{
                    borderWidth: 2,
                    borderColor:
                      focusedItem === "gender" ? theme.primary : "#CCCCCC",
                    textInputPaddingHorizontal: 10,
                  }}
                  theme={theme}
                  placeholder={I18n.t("error_messages.genders.required")}
                  value={form.gender ? _.capitalize(form.gender) : null}
                  editable={false}
                  onChange={({ nativeEvent: { text } }) => {}}
                  backgroundColor={theme.white}
                  borderWidth={2}
                  onFocus={() => {
                    setFocusedItem("gender");
                  }}
                  onBlur={() => {
                    setFocusedItem(null);
                  }}
                />
              </View>
            </Pressable>
          </View>
          <VerticalSpacing size={theme.primaryPadding} />

          <PrestoSolidButton
            size="large"
            buttonStyle="primary"
            title={
              !initialForm
                ? I18n.t("screen_messages.common.add_proceed")
                : I18n.t("screen_messages.common.update")
            }
            titleColor={theme.white}
            titleFontStyle={"600.semibold"}
            titleSize={24}
            height={60}
            borderRadius={12}
            onPress={() =>
              !initialForm ? onAddCustomer() : onUpdateCustomer(userInfo)
            }
          />
        </View>
      </ScrollView>
    </View>
  );
}
