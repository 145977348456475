import DBBaseManager from "@presto-db-managers/DBBaseManager.js";
import _ from "lodash";

export default class DBCategoryManager extends DBBaseManager {
  constructor() {
    super();
    this.tableName = "categories";
    this.fillables = [
      "id",
      "name",
      "description",
      "other_data",
      "pic_url",
      "reference_id",
      "listing_order",
      "hidden",
      "catalog_id",
      "merchant_id",
      "category_id",
      "updated_at",
      "created_at",
      "pictures",
    ];
    this.datatypes = {
      other_data: "JSON",
      pictures: "JSON",
      hidden: "BOOL",
    };
  }
}
