import _ from "lodash";
import { isOfflineEnabledAPI } from "@presto-common/OfflineAPIsConfig";
import DBEmployeeManager from "@presto-db-managers/DBEmployeeManager";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import PrestoSdk from "../services/global/PrestoSdk";
import Utils from "../services/common/Utils";

function nearByOperators(params, successCallback, errorCallback) {
  let newParams = _.cloneDeep(params);
  let enableOffline = false;
  if (params.enableOffline) {
    enableOffline = params.enableOffline;
    newParams = _.omit(newParams, "enableOffline");
  }

  if (isOfflineEnabledAPI("employeeFetch") || enableOffline) {
    const obj = new DBEmployeeManager();
    obj
      .all(newParams)
      .then((rows) => {
        successCallback({
          data: {
            operators: rows,
          },
        });
      })
      .catch((error) => {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      });
  } else {
    EmployeeManager.nearByOperators(newParams, successCallback, errorCallback);
  }
}

export default {
  nearByOperators,
};
