import PrestoText from "@presto-components/PrestoText";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import React, { useContext } from "react";
import { Image, View, Pressable } from "react-native";
import moment from "moment";
import _ from "lodash";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import { useAssets } from "presto-react-components";
import utils from "../../utils/index";

export default function QPItemCard(props) {
  const { theme } = useContext(ThemeContext);
  const { images: Images, svgs } = useAssets();
  const {
    item,
    onPress,
    selected,
    onPressButton,
    showRemove,
    onLongPress,
    tabText,
  } = props || null;

  const defaultPicUrl =
    "https://images.unsplash.com/photo-1584308666744-24d5c474f2ae?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1660&q=80";

  const getPicUrl = () => (item.pic_url ? item.pic_url : defaultPicUrl);
  const isZipSupplied = function (purchaseConfig) {
    let zipFound = false;
    purchaseConfig?.supplier_prices?.forEach((sp) => {
      if (
        sp.supplier_id ||
        sp?.name?.indexOf("Zip") >= 0 ||
        sp.supplier_id == "64c8ff28179fb06bfbe3b6f9" ||
        sp.supplier_id == "65845fdd179fb0a4e0de327e" ||
        sp.supplier_id == "65cc70fa179fb01ce6ad8745" ||
        sp.supplier_id == "65cdd88d179fb03655ed2eea" ||
        sp.supplier_id == "65cddd19179fb03655ed4c75" ||
        sp.supplier_id == "65cd9e96179fb02ff0a255ef"
      ) {
        zipFound = true;
      }
    });
    return zipFound;
  };

  const getMargin = function () {
    if (item.other_data.avg_purchase_price) {
      var percentageDiff =
        ((item.price.base_price +
          item.price.tax -
          item.other_data.avg_purchase_price) /
          item.other_data.avg_purchase_price) *
        100.0;
      return percentageDiff.toFixed(2);
    } else {
      return "";
    }
  };

  const getNewMargin = function () {
    if (item.other_data.avg_purchase_price && item.markedUpPrice) {
      console.log(
        "DIFF Between - " +
          (item.markedUpPrice + item.markedUpTax) +
          "," +
          item.other_data.avg_purchase_price
      );
      var percentageDiff =
        ((item.markedUpPrice +
          item.markedUpTax -
          item.other_data.avg_purchase_price) /
          item.other_data.avg_purchase_price) *
        100.0;
      return percentageDiff.toFixed(2);
    } else {
      return "";
    }
  };
  return (
    <Pressable
      onPress={() => onPress && onPress(item)}
      style={{
        width: "100%",
        maxHeight: 80,
        backgroundColor: selected ? theme.primary : theme.white,
        borderRadius: 15,
        padding: 5,
        flexDirection: "row",
        marginBottom: 5,
      }}
    >
      <View
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          flexDirection: "column",
          width: "20%",
          justifyContent: "center",
          alignItems: "flex-start",
          borderRightColor: theme.lightText,
          borderRightWidth: 1,
        }}
      >
        <PrestoText
          size={12}
          numberOfLines={2}
          extraStyle={{ paddingLeft: 10 }}
          color={selected ? theme.white : theme.dark}
          fontStyle={"400.bold"}
        >
          {item?.name}
        </PrestoText>
        {item?.other_data?.molecule ? (
          <PrestoText
            extraStyle={{ paddingLeft: 10 }}
            size={12}
            color={selected ? theme.white : theme.dark}
          >
            {item.other_data.molecule}
          </PrestoText>
        ) : null}
      </View>
      <View
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          flexDirection: "column",
          width: "10%",
          justifyContent: "center",
          alignItems: "center",
          borderRightColor: theme.lightText,
          borderRightWidth: 1,
          paddingHorizontal: 10,
        }}
      >
        <PrestoText
          fontStyle={"400.semibold"}
          size={16}
          color={selected ? theme.white : theme.dark}
          extraStyle={{ textAlign: "right" }}
        >
          {item?.stock_count}
        </PrestoText>
        <PrestoText
          extraStyle={{ paddingLeft: 0 }}
          size={12}
          color={selected ? theme.white : theme.dark}
        >
          {item.other_data["sale unit"]}
        </PrestoText>
      </View>
      <View
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          flexDirection: "column",
          width: "10%",
          justifyContent: "center",
          alignItems: "center",
          borderRightColor: theme.lightText,
          borderRightWidth: 1,
          paddingHorizontal: 10,
        }}
      >
        <PrestoText
          fontStyle={"400.semibold"}
          size={16}
          color={selected ? theme.white : theme.dark}
          extraStyle={{ textAlign: "right" }}
        >
          {item?.other_data?.nearest_expiry}
        </PrestoText>
      </View>
      <View
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          flexDirection: "column",
          width: "10%",
          justifyContent: "center",
          alignItems: "center",
          borderRightColor: theme.lightText,
          borderRightWidth: 1,
          paddingHorizontal: 10,
        }}
      >
        <PrestoText
          fontStyle={"400.semibold"}
          size={16}
          color={selected ? theme.white : theme.dark}
          extraStyle={{ textAlign: "right" }}
        >
          {item?.other_data?.avg_purchase_price
            ? utils.formattedPriceWithoutCurrency(
                item?.other_data?.avg_purchase_price
              )
            : ""}
        </PrestoText>
      </View>
      <View
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          flexDirection: "column",
          width: "10%",
          justifyContent: "center",
          alignItems: "center",
          borderRightColor: theme.lightText,
          borderRightWidth: 1,
          paddingHorizontal: 10,
        }}
      >
        <PrestoText
          fontStyle={"400.semibold"}
          size={14}
          color={selected ? theme.white : theme.dark}
          extraStyle={{ textAlign: "right" }}
        >
          {item?.other_data?.last_purchase_price
            ? utils.formattedPriceWithoutCurrency(
                item?.other_data?.last_purchase_price
              )
            : ""}
        </PrestoText>
      </View>
      <View
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          flexDirection: "column",
          width: "10%",
          justifyContent: "center",
          alignItems: "center",
          borderRightColor: theme.lightText,
          borderRightWidth: 1,
          paddingHorizontal: 10,
        }}
      >
        <PrestoText
          fontStyle={"400.semibold"}
          size={16}
          color={selected ? theme.white : theme.dark}
          extraStyle={{ textAlign: "right" }}
        >
          {utils.formattedPriceWithoutCurrency(
            (item.price.base_price + item.price.tax).toFixed(2)
          )}
        </PrestoText>
      </View>
      <View
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          flexDirection: "column",
          width: "10%",
          justifyContent: "center",
          alignItems: "center",
          borderRightColor: theme.lightText,
          borderRightWidth: 1,
          paddingHorizontal: 10,
        }}
      >
        <PrestoText
          fontStyle={"400.semibold"}
          size={16}
          color={
            selected
              ? theme.white
              : getMargin() && getMargin() < 0
              ? theme.secondary
              : theme.primary
          }
          extraStyle={{ textAlign: "right" }}
        >
          {getMargin()}%
        </PrestoText>
      </View>
      <View
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          flexDirection: "column",
          width: "10%",
          justifyContent: "center",
          alignItems: "center",
          borderRightColor: theme.lightText,
          borderRightWidth: 1,
          paddingHorizontal: 10,
        }}
      >
        <PrestoText
          fontStyle={"400.semibold"}
          size={16}
          color={selected ? theme.white : theme.dark}
          extraStyle={{ textAlign: "right" }}
        >
          {item.markedUpPrice
            ? (item.markedUpPrice + item.markedUpTax).toFixed(2)
            : ""}
        </PrestoText>
      </View>
      <View
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          flexDirection: "column",
          width: "10%",
          justifyContent: "center",
          alignItems: "center",
          borderRightColor: theme.lightText,
          borderRightWidth: 1,
          paddingHorizontal: 10,
        }}
      >
        <PrestoText
          fontStyle={"400.semibold"}
          size={16}
          color={
            selected
              ? theme.white
              : getNewMargin() && getNewMargin() < 0
              ? theme.secondary
              : theme.primary
          }
          extraStyle={{ textAlign: "right" }}
        >
          {getNewMargin()}
        </PrestoText>
      </View>
    </Pressable>
  );
}
