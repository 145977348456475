import CartIcon from "./svgs/CartIcon";
import StarIcon from "./svgs/StarIcon";
import StoreIcon from "./svgs/StoreIcon";
import UserIcon from "./svgs/UserIcon";
import WhtsappIcon from "./svgs/WhtsappIcon";

export default {
  CartIcon,
  StarIcon,
  StoreIcon,
  UserIcon,
  WhtsappIcon
};
