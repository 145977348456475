import React, { useContext } from "react";
import { Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import StylesContext from "@presto-contexts/StylesContext";
import UserContext from "@presto-contexts/UserContext";
import TopHeaderWithTitle from "@presto-components/Headers/TopHeaderWithTitle";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";

export default function TopHeaderWithTitleComponent({
  scene,
  previous,
  navigation,
  title,
  goBack = true,
  onPressLeftButton,
}) {
  const { Navigator } = useContext(NavigatorContext);
  const { WebNavigator } = useContext(WebNavigatorContext);

  const onSearch = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "SEARCH",
      },
    });
  };
  const onGoBack = () => {
    if (onPressLeftButton) {
      onPressLeftButton();
      return;
    }
    if (Platform.OS == "web") {
      WebNavigator.emit({
        event: "goBack",
      });
    }
    else {
      Navigator.emit({
        event: "goBack",
      });
    }
  };

  const { theme } = useContext(ThemeContext);
  const { appStyles } = useContext(StylesContext);
  const { user, authState, currentAddress } = useContext(UserContext);

  return (
    <TopHeaderWithTitle
      scene={scene}
      previous={previous}
      navigation={navigation}
      theme={theme}
      appStyles={appStyles}
      user={user}
      authState={authState}
      title={title}
      CurrentAddress={currentAddress || null}
      onSearch={() => onSearch()}
      onPressLeftButton={() => onGoBack()}
      shouldGoBack={goBack}
    />
  );
}
