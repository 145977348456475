import React from "react";
import I18n from "i18n-js";
import LineItemModel from "@presto-component-models/LineItemModel";
import { LineItemCards } from "presto-react-components";
import _ from "lodash";

export default function LineItemCard({ item }) {
  return (
    <LineItemCards.LineItemCard1
      lineItem={
        new LineItemModel({
          lineItem: item,
        })
      }
    />
  );
}
