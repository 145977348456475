import React, { useContext } from "react";
import { View } from "react-native";
import TouchableOpacity from "@presto-components/TouchableOpacity/TouchableOpacity";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import PrestoText from "@presto-components/PrestoText";
import I18n from "i18n-js";

function LeftRightRow(props) {
  if (props.children.length !== 2) {
    throw new Error(I18n.t("screen_messages.common.total_number_of_children"));
  }
  if (props.leftWidth && props.rightWidth) {
    throw new Error(I18n.t("screen_messages.common.dont_specify_left_right"));
  }

  const leftStyle = props.rightWidth
    ? { width: props.leftWidth, flex: 1 }
    : { width: props.leftWidth };
  const rightStyle = props.leftWidth
    ? { width: props.rightWidth, flex: 1 }
    : { width: props.rightWidth };

  return (
    <View
      style={{
        flexDirection: "row",
        backgroundColor: "transparent",
        justifyContent: "space-between",
        alignItems: "center",
        flexShrink: 1,
        flexWrap: "wrap",
        height: props.height,
      }}
    >
      <View style={leftStyle}>{props.children[0]}</View>
      <View style={rightStyle}>{props.children[1]}</View>
    </View>
  );
}

export default function KeyValue({
  dict,
  onRowClick,
  leftTextColor,
  leftTextProps = {},
  rightTextColor = {},
  rightTextProps,
  showDivider = true,
  strikeThrough,
  lineDividerProps = {},
  isWidth = true,
}) {
  const { theme } = useContext(ThemeContext);
  const components = _.map(_.keys(dict), (key, index) => {
    return (
      <React.Fragment key={`${index}`}>
        <TouchableOpacity
          onPress={() => {
            onRowClick && onRowClick(key, dict[key]);
          }}
          style={{
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <LeftRightRow style={{ flexShrink: 1 }}>
            <PrestoText
              color={leftTextColor || theme.paragraph}
              size={13}
              wieght="600"
              extraStyle={{ marginTop: 10, ...(isWidth && { width: 130 }) }}
              {...leftTextProps}
            >
              {key}
            </PrestoText>
            <PrestoText
              color={rightTextColor || theme.paragraph}
              size={13}
              fontStyle="400.bold"
              flexWrap="wrap"
              extraStyle={{
                marginTop: 2,
                paddingTop: 5,
                paddingLeft: 10,
                paddingBottom: 5,
                flexWrap: "wrap",
                textAlign: "right",
                flex: 1,
                // width: 220,
                flexShrink: 1,
                textDecorationLine:
                  strikeThrough && strikeThrough(key) ? "line-through" : "",
              }}
              {...rightTextProps}
            >
              {dict[key]}
            </PrestoText>
          </LeftRightRow>
        </TouchableOpacity>

        {showDivider ? (
          <LineDivider
            lineHeight={1}
            width="100%"
            dividerColor="#DADADA"
            {...lineDividerProps}
          />
        ) : null}
      </React.Fragment>
    );
  });
  return <View>{components}</View>;
}
