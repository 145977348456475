import React from 'react';
import { View, Text, Platform } from "react-native";
import TopHeaderWithTitle from "@presto-screen-components/Headers/TopHeaderWithTitle";
import { WebView } from "react-native-webview";
import I18n from "i18n-js";

const TermsAndConditions = (props) => {
  const { url } = props.route.params;
  return (
    <View style={{ height: "100%", width: "100%", zIndex: -1 }}>
      <TopHeaderWithTitle
        title={I18n.t("screen_messages.privacy_policy_t&c")}
      />
      <View
        style={{ height: "100%", width: "100%" }}>
        {
          Platform.OS == "web"
            ? <iframe src={url} height={"100%"} width={"100%"} />
            : <WebView
              source={{ uri: url }}
              style={{ flex: 1 }}
            />
        }
      </View>
    </View>
  )
}

export default TermsAndConditions
