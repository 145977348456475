import I18n from "i18n-js";
import moment from "moment";
import utils from "../../src/utils";
import _ from "lodash";

export default class OrderModel {
  constructor({ order, merchant, isCustomisable }) {
    this.orderObj = order;
    this.merchantObj = merchant;
    this.isCustomisable = isCustomisable;
  }

  get titleWithFriendlyID() {
    return this.isCustomisable
      ? I18n.t("screen_messages.payments.order_payment_text") +
          " #" +
          this.orderObj.friendly_id
      : this.orderObj.other_data.delivery_type === "delivery"
      ? I18n.t("screen_messages.orders.order_delivery_text") +
        " #" +
        this.orderObj.friendly_id
      : !this.isCustomisable &&
        this.orderObj.other_data.delivery_type !== "delivery"
      ? I18n.t("screen_messages.orders.order_pickup_text") +
        " #" +
        this.orderObj.friendly_id
      : I18n.t("screen_messages.orders.order_with_id", {
          query: this.orderObj.friendly_id,
        });
  }
  get createdAt() {
    return (
      `${moment(this.orderObj?.created_at).format("D MMM yyy")}` ||
      moment(new Date()).format("dddd MMM yyy")
    );
  }

  get timeSlot() {
    return this.orderObj?.time_slot
      ? `Between ${this.orderObj.time_slot}`
      : null;
  }

  get heading() {
    return this.merchantObj?.name || null;
  }

  get status() {
    return this.orderObj?.status == "PAYMENT_COMPLETE"
      ? I18n.t("screen_messages.common.completed")
      : this.orderObj.delivered_at
      ? I18n.t("screen_messages.orders.order_delivered")
      : this.orderObj?.status;
  }

  get status2() {
    return this.orderObj.status;
  }

  get appointmentStatus() {
    return this.orderObj?.status == "PAYMENT_COMPLETE"
      ? `${moment(this.orderObj?.delivered_at).format("MMM D")}, ${moment(
          this.orderObj?.delivered_at
        ).format("hh:mm a")}`
      : this.orderObj.status == "REJECTED"
      ? this.orderObj.reject_reason ||
        I18n.t("error_messages.sorry_unable_to_serve_you_at_moment")
      : `${moment(this.orderObj?.created_at).format("MMM D")}, ${moment(
          this.orderObj?.created_at
        ).format("hh:mm a")}`;
  }

  get orderDelivery() {
    if (this.orderObj.status === "RECEIVED") {
      return I18n.t("screen_messages.tracking_screen.order_received");
    } else if (this.orderObj.status === "ACCEPTED") {
      return I18n.t("screen_messages.tracking_screen.order_accepted");
    } else if (this.orderObj.status === "DISPATCHED") {
      return I18n.t("screen_messages.tracking_screen.order_dispatched");
    } else if (this.orderObj.status === "DELIVERED") {
      return I18n.t("screen_messages.tracking_screen.order_delivered");
    } else {
      return I18n.t("screen_messages.tracking_screen.order_rejected");
    }
  }

  get orderPickup() {
    if (this.orderObj.status === "RECEIVED") {
      return I18n.t("screen_messages.tracking_screen.order_received");
    } else if (this.orderObj.status === "ACCEPTED") {
      return I18n.t("screen_messages.tracking_screen.order_accepted");
    } else if (this.orderObj.status === "DISPATCHED") {
      return I18n.t("screen_messages.common.ready_for_pickup");
    } else if (this.orderObj.status === "DELIVERED") {
      return I18n.t("screen_messages.tracking_screen.order_picked");
    } else {
      return I18n.t("screen_messages.tracking_screen.order_rejected");
    }
  }

  get orderDelivered() {
    if (this.orderObj?.delivered_at) {
      return (
        I18n.t("screen_messages.tracking_screen.order_delivered_track_title") +
        moment(this.orderObj?.delivered_at).format("MMM D")
      );
    } else if (this.orderObj.status === "REJECTED") {
      return I18n.t("screen_messages.tracking_screen.order_rejected");
    } else {
      return I18n.t(
        "screen_messages.tracking_screen.order_delivered_track_title2"
      );
    }
  }

  get orderPicked() {
    return (
      I18n.t("screen_messages.tracking_screen.order_picked_up") +
      moment(this.orderObj?.delivered_at).format("MMM D")
    );
  }

  get description() {
    return this.isCustomisable
      ? I18n.t("screen_messages.payments.paid_at_store")
      : this.orderObj.other_data.delivery_type === "delivery"
      ? this.orderObj.address.full_address
      : `Pickup at ${this.orderObj.merchant_hash?.name || "Store"}`;
  }

  get totalItems() {
    return `${this.orderObj?.quantity} Items`;
  }

  get totalItems2() {
    return this.orderObj.quantity;
  }

  get fullAddress() {
    return this.orderObj?.address?.full_address;
  }

  get cardIcon() {
    return this.merchantObj?.pic_url || null;
  }

  get trackButtonTitle() {
    return this.orderObj.status === "DISPATCHED"
      ? I18n.t("screen_messages.orders.track")
      : I18n.t("screen_messages.orders.track");
  }

  get detailButtonTitle() {
    return this.orderObj?.status === "DELIVERED"
      ? I18n.t("screen_messages.common.details_uppercase")
      : I18n.t("screen_messages.common.details");
  }
  get rateButtonTitle() {
    return this.orderObj?.status === "DELIVERED"
      ? I18n.t("screen_messages.orders.rate")
      : I18n.t("screen_messages.orders.rate");
  }

  get reebookOrTrackButtonTitle() {
    return this.appointmentObj?.status === "PAYMENT_COMPLETE"
      ? I18n.t("screen_messages.bookings.rebook")
      : I18n.t("screen_messages.orders.track");
  }

  get reorderButtonTitle() {
    return this.orderObj?.other_data.delivery_type === "delivery"
      ? I18n.t("screen_messages.reorder_text")
      : this.orderObj?.status !== "PAYMENT_COMPLETE"
      ? I18n.t("screen_messages.bookings.rebook")
      : "";
  }

  get orderAmount() {
    if (this.orderObj?.bill_amount) {
      return utils.formattedPrice(this.orderObj?.bill_amount);
    }
  }

  get orderAmount2() {
    return _.get(this.orderObj, "bill_amount", 0);
  }

  get grossAmount() {
    return utils.formattedPrice(this.orderObj?.gross_amount ?? 0);
  }

  get grossAmount2() {
    return this.orderObj?.gross_amount;
  }

  get originalGrossAmount() {
    return utils.formattedPrice(this.orderObj?.original_gross_amount ?? 0);
  }
  get grossAmount() {
    return utils.formattedPrice(this.orderObj?.gross_amount ?? 0);
  }

  get orderTax() {
    if (this.orderObj?.tax_amount) {
      return utils.formattedPrice(this.orderObj?.tax_amount / 100);
    } else {
      return utils.formattedPrice(0);
    }
  }

  get orderTax2() {
    return this.orderObj?.tax_amount;
  }

  get orderTaxActual() {
    if (this.orderObj?.tax_amount) {
      return utils.formattedPrice(this.orderObj?.tax_amount);
    }
    return utils.formattedPrice(0);
  }

  get orderTotal() {
    if (this.orderObj?.total_amount) {
      return this.orderObj?.total_amount;
    } else {
      return 0;
    }
  }

  get discount() {
    if (this.orderObj?.aggregate_discount > 0) {
      return this.orderObj.aggregate_discount ?? 0;
    } else return this.orderObj?.discount ?? 0;
  }

  get ingredientCost() {
    if (this.orderObj?.total_amount) {
      return utils.formattedPrice(this.orderObj?.gross_amount);
    } else {
      return 0;
    }
  }

  get ingredientTax() {
    if (this.orderObj?.tax_amount) {
      return utils.formattedPrice(this.orderObj?.tax_amount);
    } else {
      return utils.formattedPrice(0);
    }
  }

  get items() {
    return _.get(this.orderObj, "items", []);
  }

  set items(items) {
    this.orderObj.items = items;
  }

  get deliveryCharge() {
    if (this.orderObj?.delivery_charge) {
      return utils.formattedPrice(this.orderObj?.delivery_charge);
    }
  }

  get totalAmount() {
    if (this.orderObj?.total_amount) {
      return utils.formattedPrice(this.orderObj?.total_amount);
    }
  }

  get isInStoreOrder() {
    return this.orderObj?.address?.full_address?.includes(
      I18n.t("screen_messages.location.no_address_available")
    );
  }

  get balanceAmount() {
    let isPaymentPending = _.isEmpty(this.orderObj?.payments_list);
    return isPaymentPending
      ? utils.formattedPrice(this.orderObj?.total_amount, true)
      : 0;
  }

  get orderId() {
    return this.orderObj.friendly_id;
  }

  get id() {
    return this.orderObj.id;
  }

  get totalItemsInOrder() {
    return this.orderObj.items.length;
  }

  get user() {
    return this.orderObj.user_hash;
  }

  get createdAt() {
    return this.orderObj.created_at;
  }

  get createdAt2() {
    return moment(this.orderObj.created_at).format("DD-MM-YYYY");
  }

  static fake() {
    return new OrderModel({
      title: "Title",
      description: "Description",
      timeSlot: "timeSlot",
      cardIcon: "cardIcon",
      trackButtonTitle: "trackButtonTitle",
      detailButtonTitle: "detailButtonTitle",
      reorderButtonTitle: "reorderButtonTitle",
      rateButtonTitle: "rateButtonTitle",
    });
  }
}
