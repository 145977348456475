import React, {
  useContext,
  useReducer,
  useCallback,
  useRef,
  useState,
} from "react";
import {
  View,
  ScrollView,
  Dimensions,
  Pressable,
  FlatList,
  Platform,
} from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import CartContext from "@presto-contexts/CartContext";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import useFocusEffect from "@presto-common/useFocusEffect";
import _ from "lodash";
import CartItemCard from "@presto-screen-components/CartItemCard/CartItemCard";
import utils from "../../utils/index";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import DBCartManager from "@presto-db-managers/DBCartManager";
import CategoryDataSources from "@presto-datasources/CategoryDataSources";
import CartDataSources from "@presto-datasources/CartDataSources";
import { alertBox, prestoConfirm } from "@presto-common/Alert";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import ClientDataSource from "@presto-datasources/ClientDataSource";
import { useAssets } from "presto-react-components";

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_CATEGORY_ITEM":
      return { ...state, items: { ...state.items, [payload.id]: payload } };
  }
  return state;
}

const windowHeight = Dimensions.get("window").height;

export default function Cart({ navigation, route }) {
  const { svgs } = useAssets();
  const isWeb = Platform.OS == 'web'
  const isDesktop = utils.isDesktop();
  const isMobile = utils.isMobile();
  const routeParams = route?.params || {};
  const {
    setParentLoading,
    onPressRemoveCart,
    visible = isDesktop || isMobile,
  } = routeParams;
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const styles = getStyles(theme);
  const { cart, checkoutCart, getCurrentCartId } = useContext(CartContext);
  const [state, dispatch] = useReducer(reducer, {
    items: {},
  });
  const { items } = state;
  const countOfItemInCart = cart?.items?.length > 0 ? cart?.items?.length : 0;
  const isOfflineCartRef = useRef(false);
  const scrollViewRef = useRef(null);
  const extraOptions = useRef({
    enableScrollToEnd: true,
  });
  const [customer, setCustomer] = useState(null);
  const isCheckoutToCustomerEnabled = false;
  console.log(cart, "oidsjfioajdsfwerjewqrfijoijdsifj")
  useFocusEffect(
    useCallback(() => {
      if (cart) {
        fetchCustomer();
        CartDataSources.existsInOfflineCart(cart.id).then(
          (isOfflineCartValue) => {
            isOfflineCartRef.current = isOfflineCartValue;
            _.forEach(cart?.items, (lineItem) => {
              getCategoryItem(lineItem.item_id);
            });
          }
        );
      }
    }, [cart])
  );

  useFocusEffect(
    useCallback(() => {
      if (!_.isEmpty(items) && extraOptions.current.enableScrollToEnd) {
        setTimeout(() => {
          scrollViewRef?.current?.scrollToEnd();
        }, 200);
      }
    }, [items, extraOptions])
  );

  const fetchCustomer = () => {
    if (cart.user_id) {
      let params = {
        merchant_id: cart.merchant_id,
        user_id: cart.user_id,
      };

      ClientDataSource.getClient(
        params,
        (response) => {
          setCustomer(response.data);
        },
        (error) => {
          console.log("fetchCustomer->error", error);
        }
      );
    }
  };

  const renderCartItem = (lineItem) => {
    const item = items[lineItem.item_id];
    const variation = lineItem.variation;
    if (item) {
      return (
        <CartItemCard
          key={lineItem.id}
          item={item}
          variation={variation}
          showWhite={true}
          onUpdateCallBack={() => { }}
        />
      );
    } else {
      return null;
    }
  };

  const getCategoryItem = (id) => {
    CategoryDataSources.searchCategoryItem(
      { id, enableOffline: isOfflineCartRef.current },
      (response) => {
        if (response.hits.hits.length > 0) {
          let lineItem = response.hits.hits[0]._source;
          let cartItem = lineItem;

          dispatch({
            type: "SET_CATEGORY_ITEM",
            payload: cartItem,
          });
        }
      },
      () => { }
    );
  };

  const getCouponDiscount = () => {
    let val = 0;
    if (cart?.discounts?.length) {
      val = Number(cart?.total_discounts);
    }
    return utils.formattedCurrencyToRoundOff(val);
  };

  const getCartCount = () => {
    let val = cart?.items
      ? _.sumBy(cart?.items, (item) => {
        return item.quantity;
      })
      : 0;

    return val;
  };

  const renderCartReview = () => {
    let savings = 0;
    let savingOnOnefreeItem = 0;
    let freeItemAmount = 0;
    if (cart && cart.items) {
      _.forEach(cart.items, (product) => {
        if (product?.other_data?.variation_free_quantity) {
          savingOnOnefreeItem +=
            (product?.quantity *
              parseInt(product?.other_data?.variation_free_quantity) *
              (product?.other_data?.old_price - product?.rate / 100)) /
            (parseInt(product?.other_data?.variation_quantity) +
              parseInt(product?.other_data?.variation_free_quantity));
          freeItemAmount +=
            (product?.quantity *
              parseInt(product?.other_data?.variation_free_quantity) *
              (product?.rate / 100)) /
            (parseInt(product?.other_data?.variation_quantity) +
              parseInt(product?.other_data?.variation_free_quantity));
        }
        const parsedOldPrice = parseFloat(product.other_data?.old_price);
        const oldPrice = isNaN(parsedOldPrice) ? 0 : parsedOldPrice;
        let oldTotal = 0;
        if (oldPrice) {
          oldTotal = oldPrice * product.quantity * 100;
          savings +=
            oldTotal - (product.rate * product.quantity - product.total_tax);
        }
      });
    }

    return (
      <ScrollView style={{ ...(isWeb && { height: isWeb ? 275 : 300 }) }}>
        <View>
          {_.map(cart.items, (item) => {
            return renderCartItem(item);
          })}
          <VerticalSpacing size={30} />
        </View>
      </ScrollView>
    );
  };

  const doCheckoutWithCustomer = (customer) => {
    let params = {
      ...cart,
      user_id: customer.id,
      cart_id: getCurrentCartId(),
    };

    return checkoutCart(params).then(([error, response]) => {
      if (error) {
        throw new error();
      }
      return response.data;
    });
  };

  const navigateToCheckout = (customer) => {
    setParentLoading(true);
    doCheckoutWithCustomer(customer)
      .then((...args) => {
        setParentLoading(false);
        navigation.navigate("CartCheckout", {
          customer: customer,
        });
      })
      .catch((error) => {
        setParentLoading(false);
        alertBox("", error.message);
        return error;
      });
  };

  const onPressRemoveCartMobile = () => {
    prestoConfirm(
      I18n.t("screen_messages.common.btn_confirm"),
      I18n.t("screen_messages.cart.confirm_to_delete"),
      [
        {
          text: I18n.t("screen_messages.common.yes_text"),
          onPress: () => {
            const cartId = cart.id;
            removeCart(cartId);
          },
        },
        {
          text: I18n.t("screen_messages.common.no_text"),
        },
      ]
    );
  };

  const removeCart = (cartId) => {
    const obj = new DBCartManager();
    obj.exists(`id='${cartId}'`).then((exists) => {
      if (exists) {
        CartDataSources.clearCart(
          { cart_id: cartId },
          (response) => {
            alertBox("", I18n.t("screen_messages.cart.cart_deleted"));
            Navigator.emit({
              event: "replace",
              params: {
                screenName: "HOME",
                screenParams: {
                  selectedFeature: {
                    state: "pos_management",
                  },
                },
              },
            });
          },
          (error) => {
            alertBox("", I18n.t("error_messages.cart.failed_to_delete_cart"));
          }
        );
      } else {
        alertBox("", I18n.t("error_messages.cart.cannot_delete_online_cart"));
      }
    });
  };

  const RenderBillCard = ({
    leftText,
    rightText,
    rightTextSize,
    fontStyle,
  }) => {
    return (
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View style={{ flexDirection: "row" }}>
          <PrestoText size={16} fontStyle="500.medium" color={theme.paragraph}>
            {leftText}
          </PrestoText>
        </View>

        <View>
          <PrestoText
            size={rightTextSize ? rightTextSize : 16}
            fontStyle={fontStyle ? fontStyle : "500.medium"}
            color="#473C3C"
          >
            {rightText}
          </PrestoText>
        </View>
      </View>
    );
  };

  const Separator = () => (
    <View style={{ paddingVertical: 3 }}>
      <LineDivider lineHeight={1} width="100%" dividerColor="#D9DBE2" />
    </View>
  );

  if (!visible) {
    return <View style={{ width: "100%", height: 100 }} />;
  }

  return (
    <View style={{ backgroundColor: theme.screenBackgroundColor }}>
      <View
        style={{
          backgroundColor: "#737682",
          height: "100%",
          borderTopLeftRadius: isMobile ? 0 : 18,
          borderTopRightRadius: isMobile ? 0 : 18,
        }}
      >
        <ScrollView
          nestedScrollEnabled
          ref={scrollViewRef}
          style={{ width: "100%" }}
          contentContainerStyle={{
            paddingTop: 10,
            paddingBottom: 0,
          }}
        >
          <View style={{}}>
            {cart != null && !_.isEmpty(cart.items) ? (
              renderCartReview()
            ) : (
              <View style={{ padding: 30 }}>
                <PrestoText
                  fontStyle={"400.normal"}
                  color={theme.white}
                  fontSize={12}
                >
                  {I18n.t("screen_messages.cart.please_add_items_to_cart")}
                </PrestoText>
              </View>
            )}
          </View>
          <ScrollView style={{ ...(isWeb && { height: "95%" }) }}>
            <View
              style={{
                backgroundColor: theme.white,
                borderTopLeftRadius: 18,
                borderTopRightRadius: 18,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                width: isMobile ? "100%" : 400,
                position: "relative",
              }}
            >
              <View
                style={{
                  paddingHorizontal: theme.primaryPadding,
                  paddingTop: 30,
                }}
              >
                <Separator />

                <RenderBillCard
                  leftText={I18n.t("screen_messages.cart.total_items")}
                  rightText={getCartCount()}
                />
                <Separator />

                <RenderBillCard leftText={" "} rightText={" "} />

                <Separator />

                <RenderBillCard
                  leftText={I18n.t("screen_messages.cart.cart_total")}
                  rightText={utils.formattedCurrencyToRoundOff(
                    cart?.original_gross_amount || cart?.sub_total_price || 0
                  )}
                />

                <Separator />

                <RenderBillCard
                  leftText={I18n.t("screen_messages.cart.discounts_text")}
                  rightText={getCouponDiscount()}
                />

                <Separator />

                <RenderBillCard
                  leftText={I18n.t("screen_messages.billing.taxes")}
                  rightText={utils.formattedCurrencyToRoundOff(
                    cart?.total_taxes || 0
                  )}
                />
                <Separator />

                <View
                  style={{
                    paddingTop: 15,
                  }}
                >
                  <RenderBillCard
                    leftText={I18n.t("screen_messages.cart.to_pay")}
                    rightText={utils.formattedCurrencyToRoundOff(
                      cart?.total_price || 0
                    )}
                    rightTextSize={24}
                    fontStyle={"600.semibold"}
                  />
                </View>
              </View>

              <View style={styles.footerContainer}>
                <View style={styles.footerInner}>
                  <View
                    style={{
                      width: "100%",
                      paddingVertical: 30,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={{ flex: 5 }}>
                        <PrestoSolidButton
                          size="large"
                          buttonStyle="secondary"
                          title={I18n.t("screen_messages.checkout")}
                          titleColor={theme.white}
                          titleFontStyle={"600.semibold"}
                          titleSize={24}
                          height={60}
                          borderRadius={12}
                          onPress={() => {
                            if (customer && isCheckoutToCustomerEnabled) {
                              navigateToCheckout(customer);
                            } else {
                              navigation.navigate("CustomerSearch", {
                                allowToProceedAsGuestUser: true,
                                showAddCustomerButton: true,
                                offlineEnabledAPIForCustomerSearch: true,
                                onPressItemCallback: (customer) => {
                                  navigateToCheckout(customer);
                                },
                                onSaveCustomerInfoCallback: (newCustomer) => {
                                  navigateToCheckout(newCustomer);
                                },
                              });
                            }
                          }}
                          bgColor={
                            countOfItemInCart > 0
                              ? theme.secondary
                              : theme.tertiaryButtonColor
                          }
                          disable={countOfItemInCart > 0 ? false : true}
                        />
                      </View>
                      <View style={{ flex: 1, paddingLeft: 10 }}>
                        <Pressable
                          style={{
                            backgroundColor: cart?.id
                              ? theme.secondary
                              : theme.tertiaryButtonColor,
                            padding: 10,
                            borderRadius: 12,
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            ...(isWeb && { paddingVertical: 20 })
                          }}
                          onPress={() => {
                            if (cart?.id && _.isFunction(onPressRemoveCart)) {
                              onPressRemoveCart(cart);
                            } else if (cart?.id && isMobile) {
                              onPressRemoveCartMobile();
                            }
                          }}
                        >
                          <View>
                            <svgs.TrashIcon2 color={theme.white} />
                          </View>
                        </Pressable>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        </ScrollView>
      </View>
    </View>
  );
}

function getStyles(theme) {
  return {
    footerContainer: {
      paddingHorizontal: theme.primaryPadding,
      width: "100%",
    },
    footerInner: {
      flexDirection: "row",
      flex: 1,
    },
  };
}
