import React, { useContext, useState, useEffect, useCallback } from "react";
import { View, Pressable, SectionList, Dimensions } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import LoadingContainer from "@presto-components/LoadingContainer";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import { prestoAlert } from "../../common/Alert";
import utils from "../../utils/index";
import { useAssets } from "presto-react-components";
import _ from "lodash";
import PrestoIcon from "@presto-components/PrestoIcon";
import { VerticalSpacing } from "@presto-components/Spacing";
import { useSafeArea } from "react-native-safe-area-context";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PickerMobile from "@presto-components/PrestoPickers/PickerMobile.buadmin";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import moment from "moment";
import DocumentPicker from "@presto-common/DocumentPicker";;
import { useCatalog } from "@presto-stores/CatalogStore";
import ModalMobile from "@presto-components/Modals/Modal.bupos";
import DeleteIcon from "../../screen-components/ImageUpload/DeleteIcon";
import useFocusEffect from "@presto-common/useFocusEffect";
import * as Linking from "expo-linking";

export default function BulkUpdate({ navigation }) {
  const [loading, setLoading] = useState(false);
  const windowHeight = Dimensions.get("window").height;
  const { theme } = useContext(ThemeContext);
  const { svgs } = useAssets();
  const [selectedItem, setSelectedItem] = useState(undefined);
  const insets = useSafeArea();
  const [sideNavigation, setSideNavigation] = useState(false);
  const [bulkUpdates, setBulkUpdates] = useState();
  const [taskType, setTaskType] = useState("");
  const [email, setEmail] = useState("");
  const [focused, setFocused] = useState("");
  const [file, setFile] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const catalog = useCatalog((state) => state.catalog);
  const { Navigator } = useContext(NavigatorContext);

  useFocusEffect(
    useCallback(() => {
      getBulkUpdates();
    }, [])
  );

  const getBulkUpdates = () => {
    setLoading(true);
    EmployeeManager.getAllBulkUpdateTasks(
      {},
      (res) => {
        let obj = {
          title: "Bulk Updates",
          data: res.data,
        };
        setLoading(false);
        setBulkUpdates([obj]);
      },
      (err) => {
        setLoading(false);
        console.log("err :>> ", JSON.stringify(err));
      }
    );
  };

  const createNewBulkUpdateTask = () => {
    setSideNavigation(false);
    setLoading(true);
    const data = new FormData();
    data.append("catalog_bulk_update_task[catalog_csv]", {
      uri: file?.uri,
      name: "sample",
      type: file?.type,
    });
    data.append("catalog_bulk_update_task[task_type]", taskType.value);
    data.append("catalog_bulk_update_task[notification_email_id]", email);
    data.append("catalog_bulk_update_task[catalog_id]", catalog?.id);
    console.log("params :>> ", data);
    EmployeeManager.createBulkUpdateTask(
      data,
      (res) => {
        alert(I18n.t("screen_messages.bulk_update.bulk_update_success"));
        console.log("create res :>> ", JSON.stringify(res));
        setSideNavigation(false);
        getBulkUpdates();
      },
      (err) => {
        setLoading(false);
        alert(err.message);
        setSideNavigation(false);
        console.log("create err :>> ", err);
      }
    );
  };

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const onPressTask = (item) => {
    setModalVisible(true);
    setSelectedItem(item);
  };

  const SectionListComponent = () => {
    return (
      <View style={{ padding: 20 }}>
        <SectionList
          style={{
            backgroundColor: theme.whiteBackgroundCardColor,
          }}
          contentContainerStyle={{ paddingBottom: 100 }}
          sections={bulkUpdates}
          renderItem={({ item, index }) => {
            return renderItem({ item, index });
          }}
          renderSectionHeader={() => {
            return (
              <View
                style={{
                  width: "80%",
                  borderWidth: 1,
                  borderColor: theme.subHeader,
                  height: 40,
                  borderTopRightRadius: 10,
                  borderTopLeftRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  backgroundColor: theme.appBackgroundColor,
                }}
              >
                <View
                  style={{
                    width: "10%",
                    alignItems: "center",
                    borderRightWidth: 1,
                    justifyContent: "center",
                    height: "100%",
                    borderColor: theme.subHeader,
                  }}
                >
                  <PrestoText size={16} fontStyle={"600.semibold"}>
                    {I18n.t("screen_messages.bulk_update.serial_no")}
                  </PrestoText>
                </View>
                <View
                  style={{
                    width: "20%",
                    alignItems: "center",
                    borderRightWidth: 1,
                    justifyContent: "center",
                    height: "100%",
                    borderColor: theme.subHeader,
                  }}
                >
                  <PrestoText size={16} fontStyle={"600.semibold"}>
                    {I18n.t("screen_messages.bulk_update.task_type")}
                  </PrestoText>
                </View>
                <View
                  style={{
                    width: "40%",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRightWidth: 1,
                    height: "100%",
                    borderColor: theme.subHeader,
                  }}
                >
                  <PrestoText size={16} fontStyle={"600.semibold"}>
                    {I18n.t("screen_messages.bulk_update.date")}
                  </PrestoText>
                </View>
                <View style={{ width: "30%", alignItems: "center" }}>
                  <PrestoText size={16} fontStyle={"600.semibold"}>
                    {I18n.t("screen_messages.bulk_update.status")}
                  </PrestoText>
                </View>
              </View>
            );
          }}
          ListFooterComponent={() => {
            return <View style={{ height: 200 }}></View>;
          }}
        />
      </View>
    );
  };

  const renderItem = ({ item, index }) => {
    return (
      <View
        style={{
          width: "80%",
          borderWidth: 1,
          borderTopWidth: 0,
          borderColor: theme.subHeader,
          height: 40,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Pressable
          onPress={() => onPressTask(item)}
          style={{
            width: "10%",
            borderRightWidth: 1,
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            borderColor: theme.subHeader,
          }}
        >
          <PrestoText>{index + 1}</PrestoText>
        </Pressable>
        <View
          style={{
            width: "20%",
            alignItems: "center",
            borderRightWidth: 1,
            justifyContent: "center",
            height: "100%",
            borderColor: theme.subHeader,
          }}
        >
          <PrestoText>
            {item?.file_storage_id
              ? I18n.t("screen_messages.bulk_update.import")
              : I18n.t("screen_messages.bulk_update.export")}
          </PrestoText>
        </View>
        <View
          style={{
            width: "40%",
            alignItems: "center",
            justifyContent: "center",
            borderRightWidth: 1,
            height: "100%",
            borderColor: theme.subHeader,
          }}
        >
          <PrestoText>
            {moment(item?.created_at).format("MMMM Do YYYY, h:mm a")}
          </PrestoText>
        </View>
        <View style={{ width: "30%", alignItems: "center" }}>
          <PrestoText>
            {item?.error_message
              ? I18n.t("screen_messages.bulk_update.failed")
              : I18n.t("screen_messages.bulk_update.success")}
          </PrestoText>
        </View>
      </View>
    );
  };

  const BulkUpdateDetails = () => {
    return (
      <ModalMobile
        visible={modalVisible}
        ModelWidth={"40%"}
        ModelHeight={windowHeight - insets.top}
        onDismiss={() => setModalVisible(false)}
        modalContainerStyles={{ alignSelf: "flex-end" }}
        content={
          <>
            <View
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: theme.white,
                padding: 20,
              }}
            >
              <PrestoText
                size={24}
                fontStyle="400.semibold"
                color={theme.primary}
              >
                {I18n.t("screen_messages.bulk_update.task_details")}
              </PrestoText>
              <VerticalSpacing size={20} />
              <View
                style={{
                  width: "100%",
                  height: 50,
                  borderWidth: 1,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  borderColor: theme.subHeader,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <View
                  style={{
                    borderRightWidth: 1,
                    width: "40%",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "center",
                    borderRightColor: theme.subHeader,
                  }}
                >
                  <PrestoText size={16} fontStyle="400.regular">
                    {I18n.t("screen_messages.bulk_update.task_type")}
                  </PrestoText>
                </View>
                <View
                  style={{
                    alignItems: "center",
                    width: "60%",
                  }}
                >
                  <PrestoText size={16} fontStyle="600.regualar">
                    {selectedItem?.file_storage_id
                      ? I18n.t("screen_messages.bulk_update.import")
                      : I18n.t("screen_messages.bulk_update.export")}
                  </PrestoText>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  height: 60,
                  borderWidth: 1,
                  borderTopWidth: 0,
                  borderColor: theme.subHeader,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <View
                  style={{
                    borderRightWidth: 1,
                    width: "40%",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "center",
                    borderRightColor: theme.subHeader,
                  }}
                >
                  <PrestoText size={16} fontStyle="400.regular">
                    {I18n.t("screen_messages.bulk_update.email")}
                  </PrestoText>
                </View>
                <View
                  style={{
                    alignItems: "center",
                    width: "60%",
                  }}
                >
                  <PrestoText size={16} fontStyle="600.regualar">
                    {selectedItem?.notification_email_id}
                  </PrestoText>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  height: 50,
                  borderWidth: 1,
                  borderTopWidth: 0,
                  borderColor: theme.subHeader,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <View
                  style={{
                    borderRightWidth: 1,
                    width: "40%",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "center",
                    borderRightColor: theme.subHeader,
                  }}
                >
                  <PrestoText size={16} fontStyle="400.regular">
                    {I18n.t("screen_messages.bulk_update.created_at")}
                  </PrestoText>
                </View>
                <View
                  style={{
                    alignItems: "center",
                    width: "60%",
                  }}
                >
                  <PrestoText size={16} fontStyle="600.regualar">
                    {moment(selectedItem?.created_at).format(
                      "MMMM Do YYYY, h:mm a"
                    )}
                  </PrestoText>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  height: 40,
                  borderWidth: 1,
                  borderTopWidth: 0,
                  borderColor: theme.subHeader,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <View
                  style={{
                    borderRightWidth: 1,
                    width: "40%",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "center",
                    borderRightColor: theme.subHeader,
                  }}
                >
                  <PrestoText size={16} fontStyle="400.regular">
                    {I18n.t("screen_messages.bulk_update.status")}
                  </PrestoText>
                </View>
                <View
                  style={{
                    alignItems: "center",
                    width: "60%",
                  }}
                >
                  <PrestoText size={16} fontStyle="600.regualar">
                    {selectedItem?.error_message
                      ? I18n.t("screen_messages.bulk_update.failed")
                      : I18n.t("screen_messages.bulk_update.success")}
                  </PrestoText>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  height: 120,
                  borderWidth: 1,
                  borderTopWidth: 0,
                  borderColor: theme.subHeader,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <View
                  style={{
                    borderRightWidth: 1,
                    width: "40%",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "center",
                    borderRightColor: theme.subHeader,
                  }}
                >
                  <PrestoText size={16} fontStyle="400.regular">
                    {I18n.t("screen_messages.bulk_update.error")}
                  </PrestoText>
                </View>
                <View
                  style={{
                    alignItems: "center",
                    width: "60%",
                    padding: 10,
                  }}
                >
                  <PrestoText size={16} fontStyle="600.regualar">
                    {selectedItem?.error_message || "null"}
                  </PrestoText>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  height: 50,
                  borderWidth: 1,
                  borderTopWidth: 0,
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                  borderColor: theme.subHeader,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingRight: 30,
                }}
              >
                <Pressable onPress={onPressDownloadTask}>
                  <PrestoText
                    size={18}
                    fontStyle="400.regular"
                    color={theme.primary}
                  >
                    {I18n.t("screen_messages.bulk_update.download")}
                  </PrestoText>
                </Pressable>
              </View>
            </View>
          </>
        }
      />
    );
  };

  const NewBulkUpdate = () => {
    return (
      <ModalMobile
        visible={sideNavigation}
        ModelWidth={"40%"}
        ModelHeight={windowHeight - insets.top}
        onDismiss={() => setSideNavigation(false)}
        modalContainerStyles={{ alignSelf: "flex-end" }}
        content={
          <View style={{ padding: 10, position: "relative", height: "100%" }}>
            <PrestoText
              size={24}
              fontStyle="400.semibold"
              color={theme.primary}
            >
              {I18n.t("screen_messages.bulk_update.create_msg")}
            </PrestoText>
            <VerticalSpacing size={20} />
            <PrestoText size={16} fontStyle="400.semibold">
              {I18n.t("screen_messages.bulk_update.task_type")}
            </PrestoText>
            <VerticalSpacing size={10} />
            <PickerMobile
              items={TaskTypes}
              labelFieldKey={"label"}
              valueFieldKey={"value"}
              selectedItem={taskType}
              onSelect={(val) => {
                setFocused("picker");
                setTaskType(val);
              }}
              textContainerStyles={{
                color: theme.primary,
                borderColor:
                  focused == "picker" ? theme.primary : theme.subHeader,
              }}
            />
            <VerticalSpacing size={20} />
            <PrestoText size={16} fontStyle="400.semibold">
              {I18n.t("screen_messages.bulk_update.email")}
            </PrestoText>
            <PrestoTextInput
              style={{
                borderWidth: 2,
                borderColor:
                  focused == "email" ? theme.primary : theme.subHeader,
              }}
              textInputStyle={{ borderRadius: 5 }}
              theme={theme}
              placeholder={"Enter notification email"}
              value={email}
              keyboardType={"default"}
              onChange={(val) => onChangeEmail(val)}
              backgroundColor={theme.white}
              borderWidth={1}
              textInputColor={"red"}
              textContentType={"email"}
              autoCompleteType={"email"}
              importantForAutofill={"yes"}
              onFocus={() => setFocused("email")}
              onBlur={() => { }}
              textAlignVertical="center"
            />
            {!_.isEmpty(file) ? (
              <>
                <VerticalSpacing size={20} />
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    height: 50,
                    justifyContent: "space-between",
                  }}
                >
                  <PrestoText size={18} extraStyle={{ paddingLeft: 10 }}>
                    {file?.name}
                  </PrestoText>
                  <Pressable onPress={() => setFile(null)}>
                    <DeleteIcon height={30} width={30} />
                  </Pressable>
                </View>
              </>
            ) : null}
            <VerticalSpacing size={file ? 0 : 20} />
            <PrestoSolidButton
              size="large"
              titleColor={theme.white}
              buttonStyle="primary"
              title={I18n.t("screen_messages.bulk_update.pick_a_file")}
              onPress={onPressPickFile}
              extraStyle={{}}
            />
            <View
              style={{
                height: 80,
                width: "100%",
                padding: 10,
                position: "absolute",
                bottom: insets.bottom,
                marginLeft: 10,
              }}
            >
              <PrestoSolidButton
                size="large"
                titleColor={theme.white}
                buttonStyle="primary"
                title={I18n.t("screen_messages.bulk_update.create_task")}
                onPress={createNewBulkUpdateTask}
                extraStyle={{}}
              />
            </View>
          </View>
        }
      />
    );
  };

  const onPressHome = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "FEATURE_SELECTION",
      },
    });
  };

  const renderSearchComponent = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          height: 60,
          backgroundColor: theme.primary,
        }}
      >
        <Pressable onPress={onPressHome}>
          <View
            style={{
              backgroundColor: theme.primary,
              padding: 10,
              height: 60,
            }}
          >
            <PrestoIcon
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={
                <svgs.StoreIcon2 width="32" height="32" color={theme.white} />
              }
            />
          </View>
        </Pressable>
        <PrestoText
          size={20}
          color={theme.white}
          fontStyle="600.semibold"
          extraStyle={{ paddingTop: 15, paddingRight: 10 }}
        >
          {I18n.t("screen_messages.bulk_update.bulk_update_title")}
        </PrestoText>
      </View>
    );
  };

  const TaskTypes = [
    {
      label: "Export",
      value: "export",
    },
    {
      label: "Import",
      value: "import",
    },
  ];

  const onChangeEmail = ({ nativeEvent: { text } }) => {
    setEmail(text);
  };

  const onPressPickFile = async () => {
    let result = await DocumentPicker.pick({
      allowMultiSelection: false,
      copyTo: "cachesDirectory",
    });
    setFile(result[0]);
  };

  const onPressNew = () => {
    setSideNavigation(true);
  };

  const onPressDownloadTask = () => {
    setModalVisible(false);
    setLoading(true);
    let params = { id: selectedItem?.id };
    EmployeeManager.downloadBulkUpdateTask(
      params,
      (res) => {
        Linking.openURL(res.data?.secure_download_link);
        console.log("res :>> ", res.data?.secure_download_link);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        alert(err?.message);
        console.log("err :>> ", JSON.stringify(err));
      }
    );
  };

  return (
    <LoadingContainer
      loading={loading}
      bgColor={theme.appBackgroundColor}
      style={{ top: insets.top, position: "relative" }}
    >
      <View
        style={{
          backgroundColor: theme.appBackgroundColor,
        }}
      >
        <View style={{ zIndex: 999999 }}>{renderSearchComponent()}</View>
        {!_.isEmpty(bulkUpdates) ? <SectionListComponent /> : null}

        {sideNavigation ? <NewBulkUpdate /> : null}
        {modalVisible ? <BulkUpdateDetails /> : null}
      </View>
      <View
        style={{
          height: 80,
          width: 120,
          right: insets.right + 10,
          top: insets.top + 50,
          position: "absolute",
        }}
      >
        <PrestoSolidButton
          size="large"
          titleColor={theme.white}
          buttonStyle="primary"
          title={I18n.t("screen_messages.bulk_update.new")}
          onPress={onPressNew}
          extraStyle={{}}
        />
      </View>
    </LoadingContainer>
  );
}

function getStyles(theme) {
  return {
    container: {},
  };
}
