import DBBaseManager from "@presto-db-managers/DBBaseManager";
import config from "@presto-common/config";
import _ from "lodash";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";

export default class DBEmployeeManager extends DBBaseManager {
  constructor() {
    super();
    this.tableName = "employees";
    this.fillables = ["id", "name", "email", "role", "phone_number"];
    this.datatypes = {};
  }

  syncLocally() {
    return new Promise((resolve, reject) => {
      const onSuccess = (response) => {
        let list = response.data.operators;
        this.createOrUpdateMany(list).then(({ insertCount, updateCount }) => {
          resolve({ insertCount, updateCount });
        });
      };
      const onError = (error) => {
        console.log("DBEmployeeManager->syncLocally->error", error);
        reject(error);
      };
      EmployeeManager.nearByOperators({}, onSuccess, onError);
    });
  }
}
