import React, { useContext } from "react";
import svgs from "@presto-assets/svgs";
import { DefaultSvgs } from "presto-react-components";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
const iconStyles = {
  height: 45,
  width: 58,
};

export const getPosFeatureData = () => {
  const { theme } = useContext(ThemeContext);
  return [
    {
      title: I18n.t("screen_messages.common.pos"),
      state: "pos_management",
      icon: <DefaultSvgs.PosIcon {...iconStyles} />,
      bgColor: "#E7D13D",
    },
    {
      title: I18n.t("screen_messages.purchase_requests.title"),
      state: "purchase_request_management",
      icon: <DefaultSvgs.PurchaseRequestIcon {...iconStyles} />,
      bgColor: "#CBB72E",
    },
    {
      title: I18n.t("screen_messages.stocks.stocks"),
      state: "all_stock",
      icon: <DefaultSvgs.StockIcon2 {...iconStyles} />,
      bgColor: "#A9971F",
    },
    {
      title: I18n.t("screen_messages.customers.customers"),
      state: "customer_management",
      icon: <DefaultSvgs.CustomerIcon {...iconStyles} />,
      bgColor: "#3DE7D3",
    },
    {
      title: I18n.t("screen_messages.reports&insights"),
      state: "sales_db_reports_management",
      icon: <DefaultSvgs.SetupIcon {...iconStyles} />,
      bgColor: "#229385",
    },
    {
      title: I18n.t("screen_messages.offers.offers_and_coupons"),
      state: "offer_management",
      icon: <DefaultSvgs.OfferIcon3 {...iconStyles} />,
      bgColor: "#229385",
    },
    {
      title: I18n.t("screen_messages.common.admin"),
      state: "admin_management",
      icon: <DefaultSvgs.SettingIcon1 fill={theme.primary} {...iconStyles} />,
      bgColor: "#3D93E7",
    },
  ];
};
