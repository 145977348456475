import create from "zustand";

let globalStateStore = (set, get) => ({
  getKey: (key) => {
    return get()[key];
  },
  setKey: (key, value) => {
    set({ [key]: value });
  },
  clear: (key) => {
    set({ [key]: null });
  },
});

export const useGlobalStateStore = create(globalStateStore);
export const GlobalStoreKeys = {
  currentHomeDate: "currentHomeDate",
  googleDriveObject: "googleDriveObject",
};
