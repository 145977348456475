import React, { useState, useCallback, forwardRef } from "react";
import { View, SafeAreaView, Text, TouchableOpacity } from "react-native";
import moment from "moment";
import I18n from "i18n-js";
import DatePicker, { CalendarContainer } from "react-datepicker";

export default function YearMonthPicker(props) {
  const [date, setDate] = useState(props.date ? new Date(props.date) : new Date());
  const [show, setShow] = useState(props.show || false);
  const [isSet, setIsSet] = useState(props.isSet ? props.isSet : false);
  const showPicker = useCallback((value) => setShow(value), []);

  const onValueChange = useCallback(
    (event, newDate) => {
      if (event !== "dismissedAction") {
        const selectedDate = newDate || date;

        showPicker(false);
        setDate(selectedDate);
        props.onDateChange(selectedDate);
        setIsSet(true);
      } else {
        setIsSet(false);
        showPicker(false);
      }
    },
    [date, showPicker]
  );

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Text className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </Text>
  ));

  const MyContainer = ({ className, children }) => {
    return (
      <View style={{
      }}>
        <CalendarContainer>
          <View style={{}}>{children}</View>
        </CalendarContainer>
      </View>
    );
  };

  return (
    <View>
      <DatePicker
        selected={date}
        onChange={(date) => { setDate(date); props.onDateChange(date) }}
        showMonthYearPicker
        dateFormat="MM/yyyy"
        customInput={<ExampleCustomInput />}
      // calendarContainer={MyContainer}
      />
    </View>
  );
}
