import DBBaseManager from "@presto-db-managers/DBBaseManager.js";
import _ from "lodash";
import moment from "moment";
import config from "@presto-common/config";

export default class DBMetaDataManager extends DBBaseManager {
  constructor() {
    super();
    this.tableName = "meta_data";
    this.fillables = ["key", "value"];
    this.keyOptions = [
      "dbVersion",
      "migration",
      "lastCategoriesFetchDate",
      "lastCategoryItemsFetchDate",
      "lastUserFetchDate",
      "lastCouponFetchDate",
      "currentLoggedUserInfo",
      "lastEmployeesFetchDate",
      "userDumpId",
    ];
    this.datatypes = {};
  }

  setLastCategoriesAndCategoryItemsFetchDate(dt) {
    let tableName = this.tableName;
    return new Promise((resolve, reject) => {
      let catExistsPromise = this.exists("key='lastCategoriesFetchDate'");
      let catItemExistsPromise = this.exists(
        "key='lastCategoryItemsFetchDate'"
      );

      Promise.all([catExistsPromise, catItemExistsPromise])
        .then(([catExists, catItemExists]) => {
          let queries = [];
          if (catExists) {
            queries.push({
              sql: `UPDATE ${tableName} set value=? WHERE key='lastCategoriesFetchDate';`,
              args: [dt],
            });
          } else {
            queries.push({
              sql: `INSERT INTO ${tableName} (key,value) VALUES (?, ?);`,
              args: ["lastCategoriesFetchDate", dt],
            });
          }

          if (catItemExists) {
            queries.push({
              sql: `UPDATE ${tableName} set value=? WHERE key='lastCategoryItemsFetchDate';`,
              args: [dt],
            });
          } else {
            queries.push({
              sql: `INSERT INTO ${tableName} (key,value) VALUES (?, ?);`,
              args: ["lastCategoryItemsFetchDate", dt],
            });
          }

          this.asyncExec(queries)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              console.error(
                "DBMetaDataManager.setLastCategoriesAndCategoryItemsFetchDate.asyncExec error",
                error
              );
              reject(error);
            });
        })
        .catch((e) => reject(e));
    });
  }

  getLastUserFetchDate() {
    let tableName = this.tableName;
    let sql = `SELECT * FROM ${tableName} WHERE key='lastUserFetchDate';`;
    let args = [];
    return this.asyncExec({ sql, args }).then(({ rows }) => {
      return _.first(rows);
    });
  }

  setLastUserFetchDate(dt) {
    let tableName = this.tableName;
    return this.getLastUserFetchDate().then((row) => {
      let sql = null,
        args = [];
      if (_.isEmpty(row)) {
        sql = `INSERT INTO ${tableName} (key,value) VALUES (?, ?);`;
        args = ["lastUserFetchDate", dt];
      } else {
        sql = `UPDATE ${tableName} SET value=? WHERE key=?;`;
        args = [dt, "lastUserFetchDate"];
      }
      return this.asyncExec({ sql, args });
    });
  }

  markAsMigrationDone() {
    return this.getMigration.then(async ({ item }) => {
      if (item) {
        const ok = await this.updateMigration("done");
        if (ok) {
          return true;
        }
      } else {
        const ok = await this.createMigration("done");
        if (ok) {
          return true;
        }
      }
      return false;
    });
  }

  getMigration() {
    let tableName = this.tableName;
    let query = `SELECT * FROM ${tableName} WHERE key='migration';`;
    return this.asyncExec({ sql: query }).then(({ rows }) => {
      return _.first(rows);
    });
  }

  isMigrationDone() {
    return getMigration().then(({ item }) => {
      return _.get(item, "value") === "done";
    });
  }

  createMigration(value) {
    query = `INSERT INTO ${this.tableName} (key,value) VALUES (?,?);`;
    return this.asyncExec({
      sql: query,
      args: ["migration", value],
    }).then(({ insertId }) => {
      if (insertId > 0) {
        return true;
      }
      return false;
    });
  }

  updateMigration(value) {
    query = `UPDATE ${this.tableName} SET value=? WHERE key=?;`;
    return this.asyncExec({
      sql: query,
      args: [value, "migration"],
    }).then(({ insertId }) => {
      if (insertId > 0) {
        return true;
      }
      return false;
    });
  }

  async canResyncCategoriesAndCategoryItems() {
    const [category, categoryItem] = await this.find(
      ["lastCategoriesFetchDate", "lastCategoryItemsFetchDate"],
      "key"
    );
    let can =
      this.canSyncCategories(category) ||
      this.canSyncCategoryItems(categoryItem);

    return can;
  }

  canSyncCategories(obj) {
    let can = true;
    let now = moment();
    const { syncDurationForCategory } = _.get(config, "offlineSyncOptions");
    let dt1 = _.get(obj, "value");
    if (dt1) {
      let catDate = new moment(dt1);
      if (catDate.isValid()) {
        let diff = now.diff(catDate, "minutes");
        if (diff < syncDurationForCategory) {
          can = false;
        }
      }
    }
    return can;
  }

  canSyncCategoryItems(obj) {
    let can = true;
    let now = moment();
    const { syncDurationForCategoryItem } = _.get(config, "offlineSyncOptions");
    let dt1 = _.get(obj, "value");
    if (dt1) {
      let catDate = new moment(dt1);
      if (catDate.isValid()) {
        let diff = now.diff(catDate, "minutes");
        if (diff < syncDurationForCategoryItem) {
          can = false;
        }
      }
    }
    return can;
  }

  canResyncUsers() {
    return this.getLastUserFetchDate().then((obj) => {
      let can = true;
      let now = moment();
      const { syncDurationForUsers } = _.get(config, "offlineSyncOptions");
      let dt1 = _.get(obj, "value");
      if (dt1) {
        let catDate = new moment(dt1);
        if (catDate.isValid()) {
          let diff = now.diff(catDate, "minutes");
          if (diff < syncDurationForUsers) {
            can = false;
          }
        }
      }
      return can;
    });
  }

  getLastCouponFetchDate() {
    return this.find("lastCouponFetchDate", "key");
  }

  setLastCouponFetchDate(dt) {
    let tableName = this.tableName;
    return this.getLastCouponFetchDate().then((row) => {
      let sql = null,
        args = [];
      if (_.isEmpty(row)) {
        sql = `INSERT INTO ${tableName} (key,value) VALUES (?, ?);`;
        args = ["lastCouponFetchDate", dt];
      } else {
        sql = `UPDATE ${tableName} SET value=? WHERE key=?;`;
        args = [dt, "lastCouponFetchDate"];
      }
      return this.asyncExec({ sql, args });
    });
  }

  canResyncCoupons() {
    return this.getLastCouponFetchDate().then((obj) => {
      let can = true;
      let now = moment();
      const { syncDurationForCoupons } = _.get(config, "offlineSyncOptions");
      let dt1 = _.get(obj, "value");
      if (dt1) {
        let catDate = new moment(dt1);
        if (catDate.isValid()) {
          let diff = now.diff(catDate, "minutes");
          if (diff < syncDurationForCoupons) {
            can = false;
          }
        }
      }
      return can;
    });
  }

  getCurrentLoggedUserInfo() {
    return this.first("currentLoggedUserInfo", "key").then((obj) => {
      let user = null;
      try {
        user = !_.isEmpty(obj.value) ? JSON.parse(obj.value) : null;
      } catch (error) { }
      return user;
    });
  }

  setCurrentLoggedUserInfo(data) {
    let tableName = this.tableName;
    return this.first("currentLoggedUserInfo", "key").then((row) => {
      let sql = null,
        args = [];

      let val = !_.isNull(data) ? JSON.stringify(data) : null;

      if (_.isEmpty(row)) {
        sql = `INSERT INTO ${tableName} (key,value) VALUES (?, ?);`;
        args = ["currentLoggedUserInfo", val];
      } else {
        sql = `UPDATE ${tableName} SET value=? WHERE key=?;`;
        args = [val, "currentLoggedUserInfo"];
      }
      return this.asyncExec({ sql, args });
    });
  }

  getlastEmployeesFetchDate() {
    return this.first("lastEmployeesFetchDate", "key");
  }

  setlastEmployeesFetchDate(dt) {
    let tableName = this.tableName;
    return this.getlastEmployeesFetchDate().then((row) => {
      let sql = null,
        args = [];

      let val = dt;
      if (_.isEmpty(row)) {
        sql = `INSERT INTO ${tableName} (key,value) VALUES (?, ?);`;
        args = ["lastEmployeesFetchDate", val];
      } else {
        sql = `UPDATE ${tableName} SET value=? WHERE key=?;`;
        args = [val, "lastEmployeesFetchDate"];
      }
      return this.asyncExec({ sql, args });
    });
  }

  canResyncEmployees() {
    return this.getlastEmployeesFetchDate().then((obj) => {
      let can = true;
      let now = moment();
      const { syncDurationForEmployees } = _.get(config, "offlineSyncOptions");
      let dt1 = _.get(obj, "value");

      if (dt1) {
        let catDate = new moment(dt1);
        if (catDate.isValid()) {
          let diff = now.diff(catDate, "minutes");
          if (diff < syncDurationForEmployees) {
            can = false;
          }
        }
      }
      return can;
    });
  }

  setEmployeeDumpId(data) {
    let tableName = this.tableName;
    return this.getUserDumpId().then((row) => {
      let sql = null,
        args = [];

      let val = data;
      if (_.isEmpty(row)) {
        sql = `INSERT INTO ${tableName} (key,value) VALUES (?, ?);`;
        args = ["userDumpId", val];
      } else {
        sql = `UPDATE ${tableName} SET value=? WHERE key=?;`;
        args = [val, "userDumpId"];
      }
      return this.asyncExec({ sql, args });
    });
  }

  getUserDumpId() {
    return this.first("userDumpId", "key");
  }
}
