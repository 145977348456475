import create from "zustand";
import { devtools } from "zustand/middleware";
import _ from "lodash";
import SearchManager from "@presto-services/features/search/SearchManager";
import CategoryObject from "@presto-services/data_models/CategoryObject";
import CatalogManager from "@presto-services/features/catalog/CatalogManager";
import moment from "moment";
import LoginHelper from "@presto-helpers/LoginHelper";
import CategoryManager from "@presto-services/features/categories/CategoryManager";

const createcategories = (rawcategories) => {
  return _.map(rawcategories, (rawCategory) => {
    let a = new CategoryObject();
    a.buildObject(rawCategory._source);
    return a;
  });
};

const createServiceItemObjects = (rawServiceItems) => {
  return _.map(rawServiceItems, (rawServiceItem) => {
    return rawServiceItem._source;
  });
};

const createProductItemObjects = (products) => {
  return _.map(products, (product) => {
    return product._source;
  });
};

let createCategories = (set, get) => {
  const loadProducts = (categories, merchantId) => {
    if (categories.length != 0) {
      let categoryIds = _.map(categories, "id");
      let promise = new Promise((resolve, reject) => {
        // TODO (Engineering) we are using 1000 has size this has to be revisited
        const size = 1000;
        SearchManager.fuzzySearchCategoryItem(
          {
            category_ids: categoryIds,
            merchant_id: merchantId,
            size,
          },
          (response) => {
            resolve(createProductItemObjects(response.hits.hits));
          },
          (error) => {
            console.log(error);
            resolve([]);
          }
        );
      });

      promise
        .then((results) => {
          set({
            allProducts: results,
          });
          const productsGroupedByCategory = _.groupBy(results, "category_id");

          const sections = _.map(categories, (category) => {
            return {
              title: category.name,
              category: category,
              data: productsGroupedByCategory[category.id],
              type: "CATEGORY_PRODUCT_SECTION",
            };
          });

          set({
            productCategories: sections,
            isProductCategoriesLoading: false,
            lastProductCategoriesLoadTime: new Date(),
          });
        })
        .catch((error) => {
          console.log("loadProducts promise error = > ", error);
        });
    }
  };
  const loadServiceProducts = (categories, merchantId) => {
    if (categories.length != 0) {
      let categoryIds = _.map(categories, "id");
      let promise = new Promise((resolve, reject) => {
        // TODO (Engineering) we are using 1000 has size this has to be revisited
        const size = 1000;
        SearchManager.fuzzySearchCategoryItem(
          {
            category_ids: categoryIds,
            merchant_id: merchantId,
            size,
          },
          (response) => {
            resolve(createProductItemObjects(response.hits.hits));
          },
          (error) => {
            console.log(error);
            resolve([]);
          }
        );
      });

      promise
        .then((results) => {
          set({
            allProducts: results,
          });
          const productsGroupedByCategory = _.groupBy(results, "category_id");

          const sections = _.map(categories, (category) => {
            return {
              title: category.name,
              category: category,
              data: productsGroupedByCategory[category.id],
              type: "CATEGORY_PRODUCT_SECTION",
            };
          });

          set({
            serviceProductCategories: sections,
            isProductCategoriesLoading: false,
            lastProductCategoriesLoadTime: new Date(),
          });
        })
        .catch((error) => {
          console.log("loadProducts promise error = > ", error);
        });
    }
  };
  const setTopLevelCategories = (categories) => {
    console.log(categories);
    let topLevelCategories = _.filter(
      categories,
      (category) => category.category_id === undefined
    );
    console.log("topLevelCategories", topLevelCategories);
    console.log("topLevelCategories", topLevelCategories.length);
  };

  const loadServices = (categories) => {
    //console.log("loadServices categories-->", categories);
    if (categories.length != 0) {
      let promises = _.map(categories, (category) => {
        return [
          new Promise((resolve, reject) => {
            let params = {
              category_id: category.id,
            };

            if (
              LoginHelper.isUserRoleOperator() ||
              LoginHelper.isUserRoleEmployee()
            ) {
              params.is_available = true;
            }

            SearchManager.getAllServices(
              params,
              (response) => {
                if (response?.hits?.hits?.length > 0) {
                  resolve(createServiceItemObjects(response.hits.hits));
                } else {
                  resolve();
                }
              },
              (error) => {
                console.log(error);
                resolve([]);
              }
            );
          }),
        ];
      });

      const newPromises = _.map(promises, (promise) => {
        return Promise.all(promise);
      });
      Promise.all(newPromises)
        .then((results) => {
          updateServiceCatalog(results);
          const categorySets = _.map(results, (result) => {
            return _.concat(result[0], result[1]);
          });

          const sections = _.map(categorySets, (categoryServices, index) => {
            return {
              title: categories[index].name,
              category: categories[index],
              data: categoryServices,
              type: "CATEGORY_SERVICE_SECTION",
            };
          });

          set({
            categories: sections,
            lastCategoriesLoadTime: new Date(),
            isCategoriesLoading: false,
            isServicesLoading: false,
          });
        })
        .catch((error) => {
          console.log("promise error = > ");
          console.log(error);
        });
    } else {
      set({
        categories: [],
        lastCategoriesLoadTime: new Date(),
        isCategoriesLoading: false,
        isServicesLoading: false,
      });
    }
  };

  const updateServiceCatalog = (services) => {
    let servicesArr = [];
    _.forEach(services, (service) => {
      if (service && service[0] && service[0].length > 0) {
        servicesArr = servicesArr.concat(service[0]);
      }
    });
    let serviceCatalog = get().sevicesCatalog;

    serviceCatalog.data = _.map(serviceCatalog.categories, (category) => {
      if (category.categories?.length > 0) {
        return updateCategories(category, servicesArr);
      } else {
        return category;
      }
    });
    set({ serviceCatalog: serviceCatalog });
  };

  const updateCategories = (category, servicesArr) => {
    category.data = _.map(category.categories, (cat) => {
      if (cat?.categories?.length > 0) {
        return updateCategories(cat, servicesArr);
      } else {
        let services = _.filter(
          servicesArr,
          (service) => service.category_id === cat.id
        );
        cat.data = services;
        return cat;
      }
    });
    return category;
  };
  const getServiceCategory = (id, merchantId) => {
    let params = {
      merchant_id: merchantId,
      catalog_id: id,
    };
    SearchManager.searchCategory(
      params,
      (categories) => {
        loadServices(createcategories(categories.hits.hits));
      },
      (error) => {
        set({
          isCategoriesLoading: false,
          isServicesLoading: false,
        });
        console.log(error);
      }
    );
  };

  return {
    ready: false,
    categories: [],
    isCategoriesLoading: false,
    lastCategoriesLoadTime: moment().subtract(1, "day"),

    allServicesHash: {},
    isServicesLoading: false,
    lastServicesLoadTime: moment().subtract(1, "day"),

    productCategories: [],
    isProductCategoriesLoading: false,
    lastProductCategoriesLoadTime: moment().subtract(1, "day"),

    merchantId: null,

    init: (merchantId) => {
      set({
        merchantId: merchantId,
        ready: true,
        lastCategoriesLoadTime: moment().subtract(1, "day"),
        lastServicesLoadTime: moment().subtract(1, "day"),
        lastProductCategoriesLoadTime: moment().subtract(1, "day"),
      });
    },

    fetch: (merchantId) => {
      get().fetchProductCategory(get().merchantId || merchantId);
      get().fetchServiceProducts(get().merchantId || merchantId);
      get().fetchServiceCategory(get().merchantId || merchantId);
      get().fetchAllServices(get().merchantId || merchantId);
    },

    refreshServiceCategory: (merchantId) => {
      set({ categories: [] });
      get().fetchServiceCategory(merchantId, true);
    },

    fetchServiceCategory: (merchantId, force = false, topLevel = false) => {
      const { isCategoriesLoading, lastCategoriesLoadTime } = get();
      if (isCategoriesLoading) {
        return;
      }
      if (!force && moment() - moment(lastCategoriesLoadTime) < 60000) {
        return;
      }
      set({ isCategoriesLoading: true, lastCategoriesLoadTime: new Date() });
      let params = {
        filter: "services",
        merchant_id: merchantId,
        top_level_categories: topLevel,
        hide: false,
      };

      CatalogManager.getFilterBasedCatalog(
        params,
        (response) => {
          if (response.data.id) {
            set({ sevicesCatalog: response.data });
            getServiceCategory(response.data.id, merchantId);
          } else {
            set({
              isCategoriesLoading: false,
              isServicesLoading: false,
            });
          }
        },
        (error) => {
          set({
            isCategoriesLoading: false,
            isServicesLoading: false,
          });
          console.log("Error", error);
        }
      );
    },

    refreshProductCategory: (merchantId) => {
      get().fetchProductCategory(merchantId, true);
    },

    fetchProductCategory: (merchantId, force = false) => {
      const {
        isProductCategoriesLoading,
        lastProductCategoriesLoadTime,
      } = get();
      if (isProductCategoriesLoading) {
        return;
      }
      if (!force && moment() - moment(lastProductCategoriesLoadTime) < 60000) {
        return;
      }
      set({
        isProductCategoriesLoading: true,
        lastProductCategoriesLoadTime: new Date(),
      });
      let params = {
        filter: "products",
        merchant_id: merchantId,
        top_level_categories: false,
      };
      CatalogManager.getFilterBasedCatalog(
        params,
        (response) => {
          set({
            productsCatalog: response.data,
            isProductCategoriesLoading: false,
          });
          let list = response?.data?.categories || [];
          loadProducts(list, merchantId);
        },
        (error) => {
          set({ isProductCategoriesLoading: false });
          console.log("Error", error);
          set({
            isCategoriesLoading: false,
            isProductCategoriesLoading: false,
          });
        }
      );
    },

    refreshServiceProducts: (merchantId) => {
      get().fetchServiceProducts(merchantId, true);
    },

    fetchServiceProducts: (merchantId) => {
      set({
        isProductCategoriesLoading: true,
      });
      let params = {
        filter: "service_products",
        merchant_id: merchantId,
        top_level_categories: false,
      };
      CatalogManager.getFilterBasedCatalog(
        params,
        (response) => {
          set({
            productsCatalog: response.data,
            isProductCategoriesLoading: false,
          });
          let list = response?.data?.categories || [];
          loadServiceProducts(list, merchantId);
        },
        (error) => {
          set({ isProductCategoriesLoading: false });
          console.log("Error", error);
          set({
            isCategoriesLoading: false,
            isProductCategoriesLoading: false,
          });
        }
      );
    },

    refreshAllServices: () => {
      get().fetchAllServices(true);
    },

    fetchAllServices: (force = false) => {
      const { isServicesLoading, lastServicesLoadTime } = get();
      if (isServicesLoading) {
        return;
      }
      if (!force && moment() - moment(lastServicesLoadTime) < 60000) {
        return;
      }

      set({ isServicesLoading: true, lastServicesLoadTime: new Date() });
      // TODO (Engineering) we are using 1000 has size this has to be revisited
      let params = { size: 3000 };
      SearchManager.getAllServices(
        params,
        (response) => {
          let hashList = {};
          let serviceItemObjects = response.hits.hits
            ? createServiceItemObjects(response.hits.hits)
            : [];

          _.forEach(serviceItemObjects, (item) => {
            hashList[item.id] = item;
          });
          set({
            allServicesHash: hashList,
            lastServicesLoadTime: new Date(),
            isServicesLoading: false,
          });
        },
        (error) => {
          console.log("Category Store : getAllServices error : ", error);
        }
      );
    },
    resetCategories: () => {
      set({
        categories: [],
        productCategories: [],
        serviceProductCategories: [],
        ready: false,
        allServicesHash: {},
        sevicesCatalog: {},
        productsCatalog: {},
        allProducts: [],
        lastCategoriesLoadTime: null,
        lastProductCategoriesLoadTime: null,
        lastServicesLoadTime: null,
        isServicesLoading: false,
        isCategoriesLoading: false,
        isProductCategoriesLoading: false,
      });
    },
    getServiceHash: (id) => {
      return get().allServicesHash[id] || null;
    },
    removeCategory: (categoryId, onSuccess, onError) => {
      CategoryManager.removeCategory(
        { categoryId: categoryId },
        (response) => {
          get().refreshServiceCategory(get().merchantId);
          if (onSuccess) {
            onSuccess(response.data);
          }
        },
        (error) => {
          console.log(`removeCategory error`, error);
          if (onError) {
            onError(error);
          }
        }
      );
    },
    modifyVariationOfService: ({ item, variation, operation = null }) => {
      let categories = _.cloneDeep(get().categories);

      categories = _.map(categories, (category) => {
        let obj = _.cloneDeep(category);
        let itemIndex = _.findIndex(category.data, ["id", item.id]);

        if (itemIndex >= 0) {
          let variations = _.get(obj, `data.${itemIndex}.variations`, []);
          const variationIndex = _.findIndex(variations, ["id", variation.id]);
          let mode = !_.isEmpty(operation)
            ? operation
            : variationIndex >= 0
            ? "update"
            : "new";

          if (mode === "new") {
            obj["data"][itemIndex]["variations"] = [...variations, variation];
          } else if (mode === "update") {
            let oldVariation =
              obj["data"][itemIndex]["variations"][variationIndex];

            obj["data"][itemIndex]["variations"][variationIndex] = {
              ...oldVariation,
              ...variation,
            };
          } else if (operation === "delete") {
            obj["data"][itemIndex]["variations"] = _.filter(
              variations,
              (variationItem) => variationItem.id !== variation.id
            );
          }
        }

        return obj;
      });

      set({
        categories: categories,
      });
    },
    modifyService: ({ service, operation = null }) => {
      let categories = _.cloneDeep(get().categories);

      categories = _.map(categories, (category) => {
        let obj = _.cloneDeep(category);
        let services = _.get(obj, "data", []);
        services = _.compact(services);
        let serviceIndex = _.findIndex(services, ["id", service.id]);
        let mode = !_.isEmpty(operation)
          ? operation
          : serviceIndex >= 0
          ? "update"
          : "new";

        if (mode === "new") {
          obj["data"] = [...services, service];
        } else if (mode === "update") {
          let oldService = services[serviceIndex];
          obj["data"][serviceIndex] = {
            ...oldService,
            ...service,
          };
        } else if (operation === "delete") {
          obj["data"] = _.filter(
            services,
            (serviceItem) => serviceItem.id !== service.id
          );
        }

        return obj;
      });

      set({
        categories: categories,
      });
    },
    getProduct: (id) => {
      let product = id ? get().getProducts([id]) : null;
      return !_.isEmpty(product) ? product[0] : null;
    },
    getProducts: (ids = []) => {
      let productCategories = _.cloneDeep(get().productCategories);
      let products = [];
      _.forEach(productCategories, (productCategory) => {
        const categoryProducts = _.compact(_.get(productCategory, "data", []));
        _.forEach(categoryProducts, (productItem) => {
          if (_.includes(ids, productItem.id)) {
            products.push(productItem);
          }
        });
      });
      return products;
    },
    getAllProducts: () => {
      let productCategories = _.cloneDeep(get().productCategories);
      console.log("productCategories", productCategories);
      let products = [];
      _.forEach(productCategories, (productCategory) => {
        const categoryProducts = _.compact(_.get(productCategory, "data", []));
        _.forEach(categoryProducts, (productItem) => {
          products.push(productItem);
        });
      });
      return _.compact(products);
    },
    getService: (id) => {
      let services = id ? get().getServices([id]) : null;
      return !_.isEmpty(services) ? services[0] : null;
    },
    getServices: (ids = []) => {
      let serviceCategories = _.cloneDeep(get().categories);
      let services = [];
      _.forEach(serviceCategories, (serviceCategory) => {
        const categoryServices = _.compact(_.get(serviceCategory, "data", []));
        _.forEach(categoryServices, (serviceItem) => {
          if (_.includes(ids, serviceItem.id)) {
            services.push(serviceItem);
          }
        });
      });

      return services;
    },
  };
};

export const useCategories = create(devtools(createCategories));
