import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import EmployeeConstants from "./EmployeeConstants";
import $ from "../../global/util";
import _ from "lodash";

const EmployeeResource = function ($http, PrestoSdk, EmployeeConstants) {
  function getAllEmployees(params) {
    var url =
      PrestoSdk.getHostName() + EmployeeConstants.GET_ALL_EMPLOYEES.PATH;
    return $http.get(url);
  }

  function getAdminEmployees() {
    var url = PrestoSdk.getHostName() + "/admin/v0/employees.json";
    return $http.get(url);
  }

  function createAdminManager(params) {
    var url = PrestoSdk.getHostName() + "/employees.json";
    return $http.post(url, params);
  }

  function updateAdminManager(params) {
    var url = PrestoSdk.getHostName() + "/employees/" + params.id + ".json";
    return $http.put(url, params);
  }

  function createEmployee(params) {
    var url =
      PrestoSdk.getHostName() + EmployeeConstants.GET_ALL_EMPLOYEES.PATH;
    return $http.post(url, params);
  }

  function createEmployeeAddress(params) {
    let employee_id = params.employee_id;
    var url =
      PrestoSdk.getHostName() + `/employee/v0/employees/${employee_id}/address`;

    return $http.post(url, params);
  }

  function updateEmployee(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/employees/${params.employee_id}.json`;

    return $http.put(url, params);
  }

  function showEmployee(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/employees/${params.employee_id}.json`;

    return $http.put(url, params);
  }

  function showEmployeeAddress(params) {
    let employee_id = params.employee_id;
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/employees/${employee_id}/address.json`;

    return $http.get(url, params);
  }

  function updateEmployeeAddress(params) {
    let employee_id = params.employee_id;
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/employees/${employee_id}/address.json`;

    // console.log("updateEmployeeAddress url, params:", url, params);
    return $http.put(url, params);
  }

  function removeEmployee(params) {
    let employee_id = params.employee_id;
    var url =
      PrestoSdk.getHostName() + `/employee/v0/employees/${employee_id}.json`;

    // console.log("removeEmployee url, params:", url, params);
    return $http.delete(url, params);
  }

  function updateEmployeeAvailabilityStatus(params) {
    let employee_id = params.employee_id;
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/employees/${employee_id}/available.json`;

    return $http.post(url, params);
  }

  function updateEmployeeCitizenUserId(params) {
    // let employee_id = params.employee_id;
    // var url =
    //   PrestoSdk.getHostName() +
    //   `/employee/v0/employees/update_citizen_user_id.json`;
    // console.log("updateEmployeeCitizenUserId url, params:", url, params);
    // return $http.post(url, params);
  }

  function updateEmployeeLocation(params) {
    // where should I add employee id
    let employee_id = params.employee_id;
    var url =
      PrestoSdk.getHostName() + `/employee/v0/employees/update_location.json`;

    console.log("updateEmployeeCitizenUserId url, params:", url, params);
    return $http.put(url, params);
  }

  function operatorsOccupancyByDate(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/bookings/occupancy.json?start_time=${params.start_time}&end_time=${params.end_time}&operator_ids=${params.operator_ids}`;

    return $http.get(url);
  }

  function showOperationalTiming(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/operational_timing.json?entity_name=${params.entity_name}&entity_id=${params.entity_id}`;
    return $http.get(url, params);
  }

  function createOperationalTiming(params) {
    var url = PrestoSdk.getHostName() + `/employee/v0/operational_timing.json`;
    return $http.post(url, params);
  }

  function updateOperationalTiming(params) {
    var url = PrestoSdk.getHostName() + `/employee/v0/operational_timing.json`;
    return $http.put(url, params);
  }

  function operatorLeaves({
    entity_id,
    start_time,
    page = 1,
    records_per_page = 10,
  }) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/non_working_slots/leave_list.json?entity_id=${entity_id}&start_time=${start_time}&page=${page}&records_per_page=${records_per_page}`;
    return $http.get(url, {});
  }

  function showNonWorkingSlots({
    start_time,
    page = 1,
    records_per_page = 10,
  }) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/non_working_slots/holiday_list.json?start_time=${start_time}&page=${page}&records_per_page=${records_per_page}`;

    return $http.get(url, {});
  }

  function createNonWorkingSlots(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/non_working_slots.json`;
    return $http.post(url, params);
  }

  function updateNonWorkingSlots(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/non_working_slots/${params.id}.json`;
    return $http.put(url, params);
  }

  function removeNonWorkingSlots(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/non_working_slots/${params.id}.json`;
    return $http.delete(url, params);
  }

  function getOrders(params) {
    var url = PrestoSdk.getHostName() + `/employee/v0/orders.json`;

    let attributes = [];
    if (params.oldest_time) {
      attributes.push(`oldest_time=${params.oldest_time}`);
    }

    if (params.latest_time) {
      attributes.push(`latest_time=${params.latest_time}`);
    }
    if (params.friendly_id) {
      attributes.push(`friendly_id=${params.friendly_id}`);
    }

    if (params.user_phone) {
      attributes.push(`user_phone=${params.user_phone}`);
    }
    let query = "";
    if (attributes.length) {
      query = `?${attributes.join("&")}`;
    }
    url += query;

    console.log("here getOrders url", url);
    return $http.get(url);
  }

  function getV1Orders(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/orders/within_time_range.json`;
    let attributes = [];
    if (params.start_time) {
      attributes.push(`start_time=${params.start_time}`);
    }

    if (params.end_time) {
      attributes.push(`end_time=${params.end_time}`);
    }
    if (params.friendly_id) {
      attributes.push(`friendly_id=${params.friendly_id}`);
    }

    if (params.user_phone) {
      attributes.push(`user_phone=${params.user_phone}`);
    }
    if (params.records_per_page) {
      attributes.push(`records_per_page=${params.records_per_page}`);
    }
    let query = "";
    if (attributes.length) {
      query = `?${attributes.join("&")}`;
    }
    url += query;

    console.log("here getOrders url", url);
    return $http.get(url);
  }

  function applyCoupon(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/orders/${params.orderId}/apply_coupon.json`;
    return $http.put(url, params);
  }

  function removeCoupon(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/orders/${params.orderId}/remove_coupon.json`;
    return $http.put(url, params);
  }

  function generatorLoginTokenForOperator(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/operators/" +
      params.id +
      "/generate_login_token.json";
    return $http.post(url, params);
  }

  function loginEmployeeWithToken(params) {
    var url = PrestoSdk.getHostName() + "/employee/v1/login_with_token.json";
    console.log(`loginEmployeeWithToken url`, url);
    return $http.post(url, params);
  }

  function getOrderReturnReasons(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/order_return_reasons.json?category_id=" +
      params.categoryId;
    return $http.get(url);
  }

  function createOrderItemReturn(params) {
    var url = PrestoSdk.getHostName() + "/employee/v0/order_returns.json";
    return $http.post(url, params);
  }

  function getOrderItemReturn(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/order_returns/" +
      params.order_return_id +
      ".json";
    return $http.get(url, params);
  }

  function associateDocumentToLineItem(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/order_returns/${params.return_id}/add_document`;
    return $http.post(url, params);
  }

  function getCoupons(params) {
    var url = PrestoSdk.getHostName() + "/employee/v1/coupons.json";
    return $http.get(url, params);
  }

  function couponRemove(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/coupons/${params.id}.json`;
    return $http.delete(url);
  }

  function deleteEmployee(params) {
    var url = PrestoSdk.getHostName() + `/employees/${params.manager_id}.json`;
    return $http.delete(url);
  }

  function getItemPromotions(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/item_promotions.json`;
    return $http.get(url, params);
  }

  function getCCAppointments(params) {
    let newDate = new Date();
    var url =
      PrestoSdk.getHostName() +
      `/cc/employees/${params.employee_id}/appointments.json?status=${params.orderStatus
      }&_prid=${newDate.toISOString()}`;
    return $http.get(url);
  }

  function getAppointmentDetails(params) {
    var url =
      PrestoSdk.getHostName() +
      `/cc/${params.merchant_id}/appointments/${params.appointment_id}.json`;
    return $http.get(url);
  }

  function updateDeliveredQuantity(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/orders/${params.order_id}/update_delivered_quantity.json`;
    return $http.post(url, params);
  }

  function getPriceRules(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/price_rules.json`;
    return $http.get(url);
  }

  function getPriceRule(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/price_rules/${params.id}.json`;
    return $http.get(url);
  }

  function getTransactionHistory(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/wallets/${params.userId}/transactions.json`;
    return $http.get(url);
  }

  function userWalletInfo(params) {
    var url = PrestoSdk.getHostName() + `/employee/v0/wallets.json`;
    if (Array.isArray(params.userIds)) {
      url += `?user_ids=${params.userIds.join(",")}`;
    }
    return $http.get(url);
  }

  function getAllBulkUpdateTasks(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/catalog_bulk_update_tasks.json`;
    return $http.get(url);
  }

  function getBulkUpdateTask(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/catalog_bulk_update_tasks/${params.id}.json`;
    return $http.get(url);
  }

  function createBulkUpdateTask(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/catalog_bulk_update_tasks.json`;
    const header = {
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
    };
    return $http.post(url, params, header, { body: params });
  }

  function downloadBulkUpdateTask(params) {
    let body = {
      params: {
        response_type: "json",
      },
    };
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/catalog_bulk_update_tasks/${params.id}/download_csv.json`;
    return $http.get(url, body);
  }

  function createReport(params) {
    var url = PrestoSdk.getHostName() + `/employee/v0/reports_tasks.json`;
    return $http.post(url, params);
  }

  function getReport(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/reports_tasks.json" +
      "?page=" +
      params.page;
    return $http.get(url, params);
  }

  function getReportV1(params) {
    var url = PrestoSdk.getHostName() + "/employee/v1/reports_tasks.json";
    let queryItems = [];

    if (params.report_type) {
      queryItems.push(`report_type=${params.report_type}`);
    }

    if (params.state) {
      queryItems.push(`state=${params.state}`);
    }

    if (params.page) {
      queryItems.push(`page=${params.page}`);
    }

    if (!_.isEmpty(queryItems)) {
      const join = _.join(queryItems, "&");
      url += `?${join}`;
    }

    return $http.get(url);
  }

  function updateCategoryItem(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/category_items/${params.id}.json`;
    return $http.put(url, params.body);
  }

  function createCategoryItem(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/category_items.json`;
    return $http.post(url, params);
  }

  function deleteCategoryItem(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/category_items/${params.id}.json`;
    return $http.delete(url);
  }

  function completeOrderReturn(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/order_returns/${params.id}/complete.json`;
    return $http.put(url, params);
  }

  function acceptOrderReturn(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/order_returns/${params.id}/accept.json`;
    return $http.put(url, params.body);
  }

  function creditUserWallet(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/wallets/${params.user_id}/credit.json`;
    return $http.post(url, params.body);
  }

  function createPriceRule(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/price_rules.json`;
    return $http.post(url, params);
  }

  function getAllPriceRules(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/price_rules.json`;
    return $http.get(url, params);
  }

  function showPriceRule(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/price_rules/${params.id}.json`;
    return $http.get(url);
  }

  function updatePriceRule(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/price_rules/${params.id}.json`;
    return $http.put(url, params);
  }

  function deletePriceRule(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/price_rules/${params.id}.json`;
    return $http.delete(url, params);
  }

  function createItemPromotion(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/item_promotions.json`;
    return $http.post(url, params);
  }

  function getAllItemPromotions(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/item_promotions.json`;
    return $http.get(url, params);
  }

  function showItemPromotion(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/item_promotions/${params.id}.json`;
    return $http.get(url);
  }

  function updateItemPromotion(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/item_promotions/${params.id}.json`;
    return $http.put(url, params);
  }

  function deleteItemPromotion(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/item_promotions/${params.id}.json`;
    return $http.delete(url);
  }

  function nearByOperators(params) {
    var url = PrestoSdk.getHostName() + `/nearby_operators.json`;
    let queryString = "",
      queryStringItems = [`location=1,2`, `merchant_id=${params.merchant_id}`];

    queryString = _.join(queryStringItems, "&");
    url += `?${queryString}`;

    return $http.get(url);
  }

  function suppliers(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/suppliers.json`;
    return $http.get(url);
  }

  function supplierPrices(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/supplier_prices.json`;
    return $http.get(url);
  }

  function supplierPricesCreate(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/supplier_prices.json`;
    return $http.post(url, params);
  }

  function supplierPricesShow(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/supplier_prices/${params.id}.json`;

    let queries = [];
    if (params.purchase_configuration_id) {
      queries.push(
        `purchase_configuration_id=${params.purchase_configuration_id}`
      );
    }

    if (queries.length) {
      let join = _.join(queries, "&");
      url += `?${join}`;
    }

    return $http.get(url);
  }

  function supplierPricesUpdate(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/supplier_prices/${params.id}.json`;
    return $http.put(url, params);
  }

  function supplierPricesDelete(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/supplier_prices/${params.id}.json?purchase_configuration_id=${params.purchase_configuration_id}`;
    return $http.delete(url);
  }

  function setItemPurchasePrice(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/category_items/${params.id}/set_purchase_price.json`;
    return $http.put(url, params);
  }

  function createEmployee(params) {
    var url = PrestoSdk.getHostName() + `/employee/v0/employees.json`;
    return $http.post(url, params);
  }

  function createCoupon(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/coupons.json`;
    return $http.post(url, params);
  }

  function updateCoupon(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/coupons/${params.id}.json`;
    return $http.put(url, params);
  }

  function removeCoupon(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/coupons/${params.id}.json`;
    return $http.delete(url);
  }

  function embeddedDashboardList(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/metabase_embedded_dashboards.json`;
    if (!_.isEmpty(params)) {
      const tempUrl = new URLSearchParams(params);
      url += `?${tempUrl.toString()}`;
    }
    return $http.get(url);
  }

  function embeddedDashboardUrl(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/metabase_embedded_dashboards/${params.dashboard_id}/embed_url.json`;
    if (!_.isEmpty(params)) {
      const tempUrl = new URLSearchParams(params);
      url += `?${tempUrl.toString()}`;
    }
    return $http.get(url);
  }

  function inventoryBatchList(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/inventory_batches.json`;
    if (!_.isEmpty(params)) {
      const tempUrl = new URLSearchParams(params);
      url += `?${tempUrl.toString()}`;
    }
    return $http.get(url);
  }

  function inventoryBatchCreate(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/inventory_batches.json`;
    return $http.post(url, params);
  }

  function inventoryBatchShow(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/inventory_batches/${params.id}.json`;
    return $http.get(url);
  }

  function inventoryBatchUpdate(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/inventory_batches/${params.id}.json`;
    return $http.put(url, params.data);
  }

  function inventoryBatchDelete(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/inventory_batches/${params.id}.json`;
    return $http.delete(url);
  }

  function purchaseConfigurationCreate(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/purchase_configurations.json`;
    return $http.post(url, params);
  }

  function purchaseConfigurationShow(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/purchase_configurations/${params.id}.json`;
    return $http.get(url);
  }

  function purchaseConfigurationUpdate(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/purchase_configurations/${params.id}.json`;
    return $http.put(url, params);
  }

  function purchaseConfigurationDelete(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/purchase_configurations/${params.id}.json`;
    return $http.delete(url);
  }

  function itemPurchaseConfigurationShow(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/category_items/${params.id}/purchase_configuration.json`;

    return $http.get(url);
  }

  function itemPurchaseConfigurationList(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/purchase_configurations/for_item_ids.json?item_ids=${params.item_ids}`;

    return $http.get(url);
  }

  function itemPurchaseRequests(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/item_purchase_requests.json`;

    let queries = [];

    if (params.category_item_id) {
      queries.push(`category_item_id=${params.category_item_id}`);
    }

    if (queries.length) {
      let join = _.join(queries, "&");
      url += `?${join}`;
    }

    return $http.get(url);
  }

  function itemPurchaseRequestList(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/item_purchase_requests${params.forItems ? "" : ".json"}${params.forItems ? "/for_item_ids.json" : ""}?item_ids=${params.item_ids}`;
    console.log(url);

    return $http.get(url);
  }

  function itemPurchaseRequestCreate(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/item_purchase_requests.json`;
    return $http.post(url, params);
  }

  function itemPurchaseRequestUpdate(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/item_purchase_requests/${params.id}.json`;
    return $http.put(url, { quantity: params.quantity });
  }

  function itemPurchaseRequestDelete(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/item_purchase_requests/${params.id}.json`;
    return $http.delete(url);
  }

  function approveItemPurchaseRequest(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/approved_item_purchases.json`;
    return $http.post(url, params);
  }

  function getApprovedItemPurchaseRequest(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/approved_item_purchases.json?`;
    if (params.category_item_id) {
      url += `category_item_id=${params.category_item_id}`
    }
    if (params.category_item_id && params.draft_purchase_order_id) {
      url += `&`
    }
    if (params.draft_purchase_order_id) {
      url += `draft_purchase_order_id=${params.draft_purchase_order_id}`
    }
    return $http.get(url);
  }

  function updateApprovedItemPurchaseRequest(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/approved_item_purchases/${params.id}.json`;
    return $http.put(url, params);
  }

  function deleteApprovedItemPurchaseRequest(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/approved_item_purchases/${params.id}.json`;
    return $http.delete(url)
  }

  function fetchPromotions(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/item_promotions/for_item_reference_ids.json`;
    return $http.post(url, params);
  }

  return {
    deleteCategoryItem: deleteCategoryItem,
    createPriceRule: createPriceRule,
    getAllPriceRules: getAllPriceRules,
    showPriceRule: showPriceRule,
    updatePriceRule: updatePriceRule,
    deletePriceRule: deletePriceRule,
    createItemPromotion: createItemPromotion,
    getAllItemPromotions: getAllItemPromotions,
    showItemPromotion: showItemPromotion,
    updateItemPromotion: updateItemPromotion,
    deleteItemPromotion: deleteItemPromotion,
    createCategoryItem: createCategoryItem,
    acceptOrderReturn: acceptOrderReturn,
    creditUserWallet: creditUserWallet,
    completeOrderReturn: completeOrderReturn,
    updateCategoryItem: updateCategoryItem,
    downloadBulkUpdateTask: downloadBulkUpdateTask,
    createBulkUpdateTask: createBulkUpdateTask,
    getBulkUpdateTask: getBulkUpdateTask,
    getAllBulkUpdateTasks: getAllBulkUpdateTasks,
    getAllEmployees: getAllEmployees,
    createEmployee: createEmployee,
    createEmployeeAddress: createEmployeeAddress,
    updateEmployee: updateEmployee,
    showEmployee: showEmployee,
    showEmployeeAddress: showEmployeeAddress,
    updateEmployeeAddress: updateEmployeeAddress,
    removeEmployee: removeEmployee,
    updateEmployeeAvailabilityStatus: updateEmployeeAvailabilityStatus,
    operatorsOccupancyByDate: operatorsOccupancyByDate,
    showOperationalTiming: showOperationalTiming,
    createOperationalTiming: createOperationalTiming,
    updateOperationalTiming: updateOperationalTiming,
    showNonWorkingSlots: showNonWorkingSlots,
    createNonWorkingSlots: createNonWorkingSlots,
    updateNonWorkingSlots: updateNonWorkingSlots,
    removeNonWorkingSlots: removeNonWorkingSlots,
    getAdminEmployees: getAdminEmployees,
    createAdminManager: createAdminManager,
    updateAdminManager: updateAdminManager,
    applyCoupon: applyCoupon,
    removeCoupon: removeCoupon,
    getCoupons: getCoupons,
    generatorLoginTokenForOperator: generatorLoginTokenForOperator,
    loginEmployeeWithToken: loginEmployeeWithToken,
    operatorLeaves: operatorLeaves,
    getOrders: getOrders,
    getV1Orders: getV1Orders,
    deleteEmployee: deleteEmployee,
    getOrderReturnReasons: getOrderReturnReasons,
    createOrderItemReturn: createOrderItemReturn,
    associateDocumentToLineItem: associateDocumentToLineItem,
    getOrderItemReturn: getOrderItemReturn,
    getItemPromotions: getItemPromotions,
    getCCAppointments: getCCAppointments,
    getAppointmentDetails: getAppointmentDetails,
    updateDeliveredQuantity: updateDeliveredQuantity,
    getPriceRule: getPriceRule,
    getTransactionHistory: getTransactionHistory,
    userWalletInfo: userWalletInfo,
    createReport: createReport,
    getReport: getReport,
    nearByOperators,
    suppliers,
    supplierPrices,
    supplierPricesCreate,
    supplierPricesShow,
    supplierPricesUpdate,
    supplierPricesDelete,
    setItemPurchasePrice,
    createEmployee,
    createCoupon,
    updateCoupon,
    removeCoupon,
    getPriceRules,
    getReportV1,
    couponRemove,
    inventoryBatchList,
    inventoryBatchCreate,
    inventoryBatchShow,
    inventoryBatchUpdate,
    inventoryBatchDelete,
    purchaseConfigurationCreate,
    purchaseConfigurationShow,
    purchaseConfigurationUpdate,
    purchaseConfigurationDelete,
    itemPurchaseConfigurationShow,
    itemPurchaseConfigurationList,
    itemPurchaseRequests,
    itemPurchaseRequestList,
    itemPurchaseRequestCreate,
    itemPurchaseRequestUpdate,
    itemPurchaseRequestDelete,
    approveItemPurchaseRequest,
    embeddedDashboardList,
    embeddedDashboardUrl,
    getApprovedItemPurchaseRequest,
    updateApprovedItemPurchaseRequest,
    deleteApprovedItemPurchaseRequest,
    fetchPromotions,
  };
};

export default EmployeeResource($http, PrestoSdk, EmployeeConstants);
