import React from "react";
import _ from "lodash";
import I18n from "i18n-js";
import { CouponCards } from "presto-react-components";

export default function BaseCouponCard(props) {
  let forwardProps = {
    ...props,
    paragraph1: I18n.t("screen_messages.register.terms_conditions"),
  };

  return <CouponCards.BaseCouponCard1 {...forwardProps} />;
}
