import React, { createContext, useState, useContext, useEffect } from "react";
import CatalogManager from "@presto-services/features/catalog/CatalogManager";
import asyncify from "../common/Asyncify";
import UserContext from "@presto-contexts/UserContext";
import Config from "@presto-common/config";
const AsyncCatalogManager = asyncify(CatalogManager);
const CatalogContext = createContext();

export function CatalogContextProvider(props) {
  const [catalog, setCatalog] = useState(null);
  const [isSync,setIsSync] = useState(false);
  const { user } = useContext(UserContext);

  const refreshCatalog = async () => {
    const [error, response] = await AsyncCatalogManager.getFilterBasedCatalog({
      filter: user?.catalog_type || Config.constants.default_catalog_name,
    });
    if (!error) {
      setCatalog(response?.data);
    }
  };
  useEffect(() => {
    /*  console.log("Refreshing Catalog : ", user); */
    refreshCatalog();
  }, [user]);

  return (
    <CatalogContext.Provider
      value={{
        catalog,
        refreshCatalog,
        isSync,
        setIsSync,
      }}
    >
      {props.children}
    </CatalogContext.Provider>
  );
}

export default CatalogContext;
