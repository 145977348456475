import React, { useContext, useState, useEffect } from "react";
import { View, FlatList, Pressable, Platform } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import SearchInput from "@presto-components/TextInputs/SearchInput";
import CategoryDataSources from "@presto-datasources/CategoryDataSources";
import { useCatalog } from "@presto-stores/CatalogStore";
import { RowButton } from "@presto-components/PrestoButton";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import PrestoIcon from "../../components/PrestoIcon";
import utils from "../../utils";

export default function SearchBar(props) {
  const {
    placeholder = I18n.t("screen_messages.common.search_by_product_name"),
    isOfflineEnabledSearch,
    onSetSearchQuery,
    onPressItem,
    hideAutoComplete,
    onSearchComplete,
    onSearchQryChange,
    onSearchResults,
    doQueryReset,
    sortIcon,
    onPressSortIcon,
  } = props;
  const { theme } = useContext(ThemeContext);
  const isWeb = Platform.OS == 'web'
  const catalog = useCatalog((state) => state.catalog);
  const [searchQuery, setSearchQuery] = useState("");
  const [autoCompleteResults, setAutoCompleteResults] = useState([]);
  const isMobile = utils.isMobile();
  const styles = getStyle(theme, isMobile);

  useEffect(() => {
    if (_.isFunction(doQueryReset)) {
      doQueryReset(() => {
        setSearchQuery("");
      });
    }
  }, [props]);

  const loadAutoComplete = (text) => {
    const params = {
      search_string: text,
      or_reference_id: text,
      or_therapy_area_simple: text,
      or_molecule: text,
      or_company_name: text,
      page: 1,
      fuzziness: "AUTO",
      enableOffline: isWeb ? false : isOfflineEnabledSearch,
      size: 100,
    };

    CategoryDataSources.fuzzySearchCategoryItem(
      params,
      (response) => {
        if (onSearchComplete) {
          let filtered = _.map(response.hits.hits, (item) => item?._source);
          onSearchComplete(filtered);
        }
        const items = response.hits.hits;
        setAutoCompleteResults(items);
        onSearchResults({ searchQuery, autoCompleteItems: items });
      },
      () => {
        onSearchResults({ searchQuery, autoCompleteItems: [] });
      }
    );
  };

  function onSearchQueryChange(text) {
    setSearchQuery(text);
    if (_.isFunction(onSetSearchQuery)) {
      onSetSearchQuery(text);
    }
    if (text?.length) {
      onSearchQryChange && onSearchQryChange(text);
      loadAutoComplete(text);
    } else {
      setAutoCompleteResults([]);
      onSearchResults({ searchQuery: text, autoCompleteItems: [] });
    }
  }

  function onSearchSubmit(...args) {
    const items = _.map(autoCompleteResults, (e) => e._source);
    onPressItem(items);
    setAutoCompleteResults([]);
  }

  const renderAutoCompleteItem = ({ item, index }) => {
    return (
      <View key={`index-${index}-query`}>
        <RowButton
          title={item._source.name}
          onPress={() => {
            setAutoCompleteResults([]);
            onPressItem(item._source);
          }}
          showDisclosure={false}
          fontStyle="600.semibold"
          fontSize={isMobile ? 14 : 16}
          titleColor={theme.title}
        />
        <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
      </View>
    );
  };

  const renderAutoComplete = () => {
    return (
      <View style={styles.autoComplete}>
        <View style={styles.autoCompleteContainer}>
          <FlatList
            keyExtractor={(_, index) => "index-" + index}
            data={autoCompleteResults}
            renderItem={renderAutoCompleteItem}
          />
        </View>
      </View>
    );
  };

  return (
    <View>
      <View
        style={{
          width: "100%",
          flexDirection: sortIcon ? "row" : "column",
        }}
      >
        <View
          style={{
            width: sortIcon ? "90%" : "100%",
          }}
        >
          <SearchInput
            onSearch={onSearchSubmit}
            placeholder={placeholder}
            value={searchQuery}
            onChange={onSearchQueryChange}
            containerStyle={{
              backgroundColor: theme.white,
              borderRadius: 6,
              height: 36,
            }}
            showSearchIcon={true}
            textInputStyle={{
              paddingLeft: theme.containerPadding,
              paddingVertical: 5,
              fontFamily: theme.primaryFont,
              fontSize: 16,
              color: "#A9A9A9",
              height: 36,
            }}
          />
        </View>
        {sortIcon ? (
          <Pressable
            style={{ width: "10%", height: 36, paddingLeft: 5 }}
            onPress={onPressSortIcon}
          >
            <PrestoIcon icon={sortIcon} />
          </Pressable>
        ) : null}
      </View>

      {autoCompleteResults.length && !hideAutoComplete ? (
        <View style={{ position: "relative" }}>{renderAutoComplete()}</View>
      ) : null}
    </View>
  );
}

function getStyle(theme, isMobile) {
  return {
    autoComplete: {
      backgroundColor: "transparent",
      position: "relative",
      width: "100%",
      zIndex: 12,
    },
    autoCompleteContainer: {
      marginLeft: isMobile ? 0 : 12,
      marginTop: 1,
      backgroundColor: theme.white,
      height: isMobile ? 250 : 300,
      width: isMobile ? "100%" : "50%",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
      borderColor: "lightgray",
    },
  };
}
