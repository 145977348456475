import React, { useState, useContext, useCallback } from "react";
import { View, Dimensions, ScrollView, PermissionsAndroid, Platform } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import OrderManager from "@presto-services/features/orders/OrderManager";
import { VerticalSpacing } from "@presto-components/Spacing";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import utils from "../../utils/index";
import LineItemCard from "@presto-cards/LineItemCard/LineItemCard";
import KeyValueList from "@presto-components/KeyValueList/KeyValueList";
import useFocusEffect from "@presto-common/useFocusEffect";
import OrderModel from "@presto-component-models/OrderModel";
import Share from "@presto-common/Share"
import PrestoSdk from "../../services/global/PrestoSdk";
import { alertBox } from "@presto-common/Alert";
import OrdersHelper from "@presto-helpers/OrdersHelper";
import Link from "next/link";
import SessionManager from "@presto-services/features/session/SessionManager";

export default function OrderDetailsForReports({ route, navigation }) {
  const params = route.params || {};
  const isWeb = Platform.OS == 'web'
  const { initialOrder, setParentLoading, summaryInfo, generateReport } =
    params || {};
  const { theme } = useContext(ThemeContext);
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  const styles = getStyles(theme, windowWidth);
  const [order, setOrder] = useState(null);
  const [analyticsFormat, setAnalyticsFormat] = useState("summary");

  useFocusEffect(
    useCallback(() => {
      if (initialOrder) {
        getOrderDetails();
      } else {
        setAnalyticsFormat("summary");
        setOrder(null);
      }
    }, [initialOrder])
  );

  const getOrderDetails = () => {
    setParentLoading(true);
    let params = {
      merchant_id: initialOrder.merchant_id,
      order_id: initialOrder.id,
      id_type: initialOrder.id,
    };
    OrderManager.getOrdersByOrderId(
      params,
      (response) => {
        const orderInstance = new OrderModel({ order: response.data });
        setOrder(orderInstance);
        setAnalyticsFormat("order");
        setParentLoading(false);
      },
      (error) => {
        console.log("OrderDetailsForReports->getOrderDetails->error: ", error);
        setParentLoading(false);
      }
    );
  };

  const onPressBack = () => {
    navigation.goBack();
  };

  const getTotalReturnPrice = () => {
    let price = 0;
    let tax = getTotalTaxAmount();
    if (order) {
      _.forEach(order.items, (item) => {
        if (item.return_count > 0) {
          price += item.rate * item.return_count;
        }
      });
    }
    return utils.formattedPrice(price + parseFloat(tax));
  };

  const getTotalTaxAmount = () => {
    let tax = 0;
    if (order) {
      _.forEach(order.items, (item) => {
        if (item.return_count > 0) {
          let totalAmount = item.return_count * item?.rate;
          tax += (parseInt(item?.tax_percentage) / 100) * totalAmount;
        }
      });
    }
    return tax.toFixed(2);
  };

  const askFileWritePermissions = async () => {
    const writePermissionKey =
      PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE;
    const readPermissionKey =
      PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE;
    const result = await PermissionsAndroid.requestMultiple([
      writePermissionKey,
      readPermissionKey,
    ]);

    if (
      result[readPermissionKey] === "denied" ||
      result[writePermissionKey] === "denied"
    ) {
      alertBox(I18n.t("screen_messages.permissions.enabled_write"));
      return null;
    } else {
      return result;
    }
  };

  const onDownloadInvoice = (printInvoice) => {
    setParentLoading(true);
    OrdersHelper.downloadInvoiceByEmployee(order, { print: printInvoice }).then(
      (data) => {
        setParentLoading(false);
        if (data.success) {
          alert(I18n.t("screen_messages.reports.download_successfull"));
        } else {
          alert(I18n.t("screen_messages.download_failed"));
        }
      }
    );
  };

  const fetchPrestoSession = async () => {
    setParentLoading(true);
    return await SessionManager.getEmployee((sessionHeader) => {
      const url =
        PrestoSdk.getHostName() +
        `/employee/v0/orders/${order.id}/download_invoice.json`;
      window.open(url, "_self");
      setParentLoading(false);
      return url;
    })
  };

  const handleOnPressDownloadReportPdf = () => {
    if (analyticsFormat === "order") {
      if (isWeb) {
        fetchPrestoSession()
      } else {
        onDownloadInvoice(false);
      }
    }
  };

  const handleOnPressPrintSummary = () => {
    if (analyticsFormat === "order") {
      if (isWeb) {
        fetchPrestoSession()
      } else {
        onDownloadInvoice(true);
      }
    }
  };

  const renderItem = (lineItem, index) => {
    return <LineItemCard key={`${lineItem.id}-${index}`} item={lineItem} />;
  };

  let cartInfo =
    analyticsFormat === "summary"
      ? {}
      : analyticsFormat === "order"
        ? {
          [""]: "",
          [I18n.t("screen_messages.cart.total_items")]: order.totalItems2,
          [" "]: "",
          [I18n.t("screen_messages.cart.cart_total")]: utils.formattedCurrency(
            utils.formatPriceToLocal(order.orderAmount2)
          ),
          [I18n.t(
            "screen_messages.billing.discounts"
          )]: utils.formattedCurrency(utils.formatPriceToLocal(order.discount)),
          [I18n.t("screen_messages.billing.taxes")]: utils.formattedCurrency(
            utils.formatPriceToLocal(order.orderTax2)
          ),
        }
        : {};

  const RenderBillCard = ({
    leftText,
    rightText,
    rightTextSize,
    fontStyle,
  }) => {
    return (
      <View style={{ flexDirection: "column" }}>
        <KeyValueList
          dict={cartInfo}
          lineDividerProps={{ dividerColor: "#D9DBE2" }}
          leftTextColor="#848484"
          rightTextColor="#473C3C"
          leftTextProps={{
            size: 16,
            fontStyle: "500.medium",
          }}
          rightTextProps={{
            size: 16,
            fontStyle: "500.medium",
          }}
        />
        <VerticalSpacing size={20} />
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <PrestoText size={16} fontStyle="500.medium" color="#848484">
            {leftText}
          </PrestoText>
          <PrestoText
            size={rightTextSize ? rightTextSize : 16}
            fontStyle={fontStyle ? fontStyle : "500.medium"}
            color={theme.title}
          >
            {rightText}
          </PrestoText>
        </View>
      </View>
    );
  };

  return (
    <View style={{ backgroundColor: theme.screenBackgroundColor }}>
      <View
        style={{
          backgroundColor: "#737682",
          borderTopLeftRadius: 18,
          borderTopRightRadius: 18,
          height: windowHeight,
          paddingTop: 20
        }}
      >
        <ScrollView
          style={{ width: "100%", flex: 1, height: windowHeight }}
          contentContainerStyle={{
            paddingTop: 10,
            paddingBottom: 100,
          }}
        >
          <View>
            {order ? (
              _.map(order.items, renderItem)
            ) : (
              <PrestoText
                extraStyle={{ paddingHorizontal: 30, paddingTop: 30 }}
                color={theme.white}
                fontStyle={"400.normal"}
                size={12}
              >
                {I18n.t("screen_messages.orders.please_select_an_order")}
              </PrestoText>
            )}
          </View>
        </ScrollView>

        <View
          style={{
            backgroundColor: theme.white,
            minHeight: 500,
            borderTopLeftRadius: 18,
            borderTopRightRadius: 18,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: "100%",
            position: "relative",
            bottom: 0,
          }}
        >
          <View style={{ paddingHorizontal: theme.primaryPadding }}>
            <RenderBillCard
              leftText={
                analyticsFormat === "summary"
                  ? ""
                  : I18n.t("screen_messages.cart.invoice_amount")
              }
              rightText={
                analyticsFormat === "summary"
                  ? ""
                  : utils.formattedCurrency(
                    utils.formatPriceToLocal(order.orderTotal)
                  )
              }
              rightTextSize={24}
              fontStyle={"600.semibold"}
            />
          </View>

          <View style={styles.footerContainer}>
            <View style={styles.footerInner}>
              <View
                style={{ width: "100%", paddingTop: 10, flexDirection: "row" }}
              >
                {analyticsFormat === "order" ? (
                  <View style={{ marginRight: 5, flex: 1 }}>

                    <PrestoSolidButton
                      size="large"
                      buttonStyle="secondary"
                      title={
                        analyticsFormat === "summary"
                          ? ""
                          : analyticsFormat === "order"
                            ? I18n.t("screen_messages.reports.download_order_pdf")
                            : ""
                      }
                      titleColor={theme.white}
                      titleFontStyle={"600.semibold"}
                      titleSize={isWeb ? 15 : 20}
                      height={60}
                      borderRadius={12}
                      onPress={handleOnPressDownloadReportPdf}
                      bgColor="#F17B8D"
                      numberOfLines={2}
                      extraStyle={{
                        paddingLeft: theme.primaryPadding,
                        justifyContent: "flex-start",
                        width: "auto"
                      }}
                      titleExtraStyle={{
                        textAlign: "left",
                        ...(isWeb && { width: "80%" })
                      }}
                    />
                  </View>
                ) : null}

                {analyticsFormat === "order" ? (
                  <View style={{ marginLeft: 5, flex: 1 }}>
                    <PrestoSolidButton
                      size="large"
                      buttonStyle="secondary"
                      title={
                        analyticsFormat === "summary"
                          ? I18n.t("screen_messages.reports.print_summary")
                          : analyticsFormat === "order"
                            ? I18n.t("screen_messages.reports.print_order")
                            : ""
                      }
                      titleColor={theme.white}
                      titleFontStyle={"600.semibold"}
                      titleSize={isWeb ? 15 : 20}
                      height={60}
                      borderRadius={12}
                      onPress={handleOnPressPrintSummary}
                      bgColor="#5F98A4"
                      numberOfLines={2}
                      extraStyle={{
                        paddingHorizontal: theme.primaryPadding,
                        justifyContent: "flex-start",
                      }}
                      titleExtraStyle={{
                        textAlign: "left",
                        ...(isWeb && { width: "80%" })
                      }}
                    />
                  </View>
                ) : null}
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

function getStyles(theme) {
  return {
    footerContainer: {
      paddingHorizontal: theme.primaryPadding,
      bottom: 0,
      width: "100%",
    },
    footerInner: {
      flexDirection: "row",
    },
  };
}
