import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const OrderResource = function ($http, PrestoSdk) {
  function placeOrder(params) {
    var url = PrestoSdk.getHostName() + "/orders.json";
    if (PrestoSdk.getAppConfig().merchant_config.features.user_order) {
      url = PrestoSdk.getHostName() + "/user/v0/orders.json";
    }
    if (PrestoSdk.getAppConfig().merchant_config.features.market_place) {
      url = PrestoSdk.getHostName() + "/user/v0/marketplace_orders.json";
    }
    return $http.post(url, params);
  }

  function getMyOrders(params) {
    var url = PrestoSdk.getHostName() + "/user/v0/orders.json";
    if (params.page) {
      url += "?page_no=" + params.page;
    }
    if (params.latest_time) {
      url += "?latest_time=" + params.latest_time;
    }
    if (params.oldest_time) {
      url += "?oldest_time=" + params.oldest_time;
    }
    console.log("getMyOrders URL==>", url);
    return $http.get(url);
  }

  function cancelOrder(params) {
    var url =
      PrestoSdk.getHostName() +
      "/user/v0/orders/" +
      params.order.id +
      "/cancel.json";
    return $http.post(url, params);
  }

  function getReturnConfig(params) {
    var url =
      PrestoSdk.getHostName() +
      "/user/v0/order_return_configs/return_config.json";
    return $http.get(url);
  }

  function getReturnReason(params) {
    var url =
      PrestoSdk.getHostName() +
      "/user/v0/order_return_reasons.json?category_id=" +
      params.category_id;
    return $http.get(url);
  }

  function createReturn(params) {
    var url = PrestoSdk.getHostName() + "/user/v0/order_returns.json";
    return $http.post(url, params);
  }

  function getOrderReturns(params) {
    var url =
      PrestoSdk.getHostName() + "/user/v0/order_returns/" + params.id + ".json";
    return $http.get(url);
  }

  function getOrder(params) {
    var url =
      PrestoSdk.getHostName() + "/user/v0/orders/" + params.id + ".json";
    return $http.get(url);
  }

  function placeAgentOrder(params) {
    var url =
      PrestoSdk.getHostName() + "/cc/" + params.merchant_id + "/orders.json";
    return $http.post(url, params);
  }

  function getAgentOrders(params) {
    var url =
      PrestoSdk.getHostName() + "/cc/" + params.merchant_id + "/orders.json";
    if (params.outlet_id) {
      url += "?outlet_id=" + params.outlet_id;
    }
    if (params.oldest_time) {
      url += "?oldest_time=" + params.oldest_time;
    }
    return $http.get(url);
  }

  function getOrdersByPhone(params) {
    var url =
      PrestoSdk.getHostName() +
      "/cc/" +
      params.merchant_id +
      "/orders.json?outlet_id=" +
      params.outlet_id +
      "&user_phone=" +
      params.phone_number;
    return $http.get(url);
  }

  function getOrdersByOrderId(params) {
    var url =
      PrestoSdk.getHostName() +
      "/cc/" +
      params.merchant_id +
      "/orders/" +
      params.order_id +
      ".json";
    if (params.id_type) {
      url += "?id_type=" + params.id_type;
    } else {
      url += "?id_type=external";
    }
    return $http.get(url);
  }

  function getOrderByFriendlyId(params) {
    var url =
      PrestoSdk.getHostName() +
      "/cc/" +
      params.merchant_id +
      "/orders/" +
      params.friendly_id +
      ".json?id_type=friendly";
    return $http.get(url);
  }

  function updateCCOrdersStatus(params) {
    console.log("Updating order");
    console.log(params);
    var url =
      PrestoSdk.getHostName() +
      "/cc/" +
      "MID" +
      "/orders/" +
      params.order_id +
      ".json";
    console.log(url);
    return $http.put(url, params);
  }

  function updateCCOrderItem(params) {
    var url =
      PrestoSdk.getHostName() +
      "/cc/" +
      params.merchant_id +
      "/orders/" +
      params.order_id +
      "/update_line_item.json";
    return $http.post(url, params);
  }

  function cancelCCOrder(params) {
    var url =
      PrestoSdk.getHostName() +
      "/cc/" +
      PrestoSdk.getMerchantId() +
      "/orders/" +
      params.order.order_id +
      "/cancel.json";
    return $http.put(url, params);
  }

  function rejectCCOrder(params) {
    var url =
      PrestoSdk.getHostName() +
      "/cc/" +
      PrestoSdk.getMerchantId() +
      "/orders/" +
      params.order.order_id +
      "/reject.json";
    return $http.put(url, params);
  }

  function getConfigFile() {
    var url = PrestoSdk.getPrestoPaymentFileUrl();
    return $http.get(url);
  }

  function calculateLoyaltyAmount(params) {
    var url =
      PrestoSdk.getHostName() + "/user/v0/orders/calculate_loyalty.json";
    return $http.post(url, params);
  }

  function claimOrder(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/orders/" +
      params.order_id +
      "/claim_order.json";
    return $http.put(url, params);
  }

  function removeItem(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/orders/" +
      params.order_id +
      "/remove_item.json";
    return $http.put(url, params);
  }

  function addItem(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/orders/" +
      params.order_id +
      "/add_item.json";
    return $http.put(url, params);
  }
  function updateItem(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/orders/" +
      params.order_id +
      "/update_item.json";

    return $http.put(url, params);
  }

  function updateEmployeeOtherData(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/orders/" +
      params.order_id +
      "/update_employee_other_data.json";

    return $http.put(url, params);
  }

  return {
    placeOrder: placeOrder,
    getMyOrders: getMyOrders,
    cancelOrder: cancelOrder,
    getReturnConfig: getReturnConfig,
    getReturnReason: getReturnReason,
    createReturn: createReturn,
    getOrderReturns: getOrderReturns,
    getOrder: getOrder,
    placeAgentOrder: placeAgentOrder,
    getAgentOrders: getAgentOrders,
    getOrdersByPhone: getOrdersByPhone,
    getOrdersByOrderId: getOrdersByOrderId,
    updateCCOrdersStatus: updateCCOrdersStatus,
    cancelCCOrder: cancelCCOrder,
    rejectCCOrder: rejectCCOrder,
    getConfigFile: getConfigFile,
    getOrderByFriendlyId: getOrderByFriendlyId,
    calculateLoyaltyAmount: calculateLoyaltyAmount,
    claimOrder: claimOrder,
    updateCCOrderItem: updateCCOrderItem,
    removeItem: removeItem,
    addItem: addItem,
    updateItem: updateItem,
    updateEmployeeOtherData: updateEmployeeOtherData,
  };
};

export default OrderResource($http, PrestoSdk);
