import React from "react";
import {
  StackActions,
  TabActions,
  DrawerActions,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import _ from "lodash";
import navigationConfig from "@presto-app/navigationConfig";
import SessionManager from "@presto-services/features/session/SessionManager";
import { WebNavigator } from "@presto-contexts/WebNavigatorContext";
import {
  NavigationContainer,
  NavigationIndependentTree,
} from "@react-navigation/native";
import config from "@presto-common/config";
import AnalyticsManager from "./AnalyticsManager";
import qs from "qs";

const forFade = ({ current }) => ({
  cardStyle: {
    opacity: current.progress,
  },
});

class WebNavigationManager {
  initialize = () => {
    this.reactNavigationRef = React.createRef();
    this.navigationConfig = navigationConfig["desktop"];
    this.isDesktop = false;
    WebNavigator.addObserver(this);
  };

  handleNavigationEvent = (event, params = {}) => {
    const { tabName, tabRootName, screenName, screenParams = {}, pop } = params;
    console.log("Handling web Event : ", event, {
      tabName,
      tabRootName,
      screenName,
      screenParams,
      pop,
    });
    console.log("this.isDesktop", this.isDesktop);
    if (this.isDesktop && event === "push") {
      event = "modal";
    }
    AnalyticsManager.reportScreenOpened(screenName || tabName);
    switch (event) {
      case "openDrawer":
        this.handleOpenDrawer();
        break;
      case "closeDrawer":
        this.handleCloseDrawer();
        break;
      case "toggleDrawer":
        this.handleToggleDrawer();
        break;
      case "switchDrawerItem":
        this.handleSwitchDrawerItem(tabName, pop);
        break;
      case "jumpDrawer":
        this.handleJumpDrawer(tabName, screenName, screenParams);
        break;
      case "jumpTab":
        this.handleJumpTab(tabName, screenName, screenParams);
        break;
      case "goBack":
        this.goBack();
        break;
      case "navigate":
        this.handleNavigate(screenName, screenParams);
        break;
      case "replace":
        this.handleReplace(screenName + "_MODAL", screenParams);
        break;
      case "push":
        this.handleNavigate(screenName, screenParams);
        break;
      case "modal":
        this.handleNavigate(screenName + "_MODAL", screenParams);
        break;
      case "switchTab":
        this.switchTab(tabName, pop);
        break;
      case "popToTop":
        this.popToTop(tabName);
        break;
    }
  };

  handleToggleDrawer = () => {
    this.reactNavigationRef.current?.dispatch(DrawerActions.toggleDrawer());
  };

  handleCloseDrawer = () => {
    this.reactNavigationRef.current?.dispatch(DrawerActions.closeDrawer());
  };

  handleOpenDrawer = () => {
    this.reactNavigationRef.current?.dispatch(DrawerActions.openDrawer());
  };

  handleSwitchDrawerItem = (tabName, pop = false) => {
    console.log("Ref : ", this.reactNavigationRef.current);
    this.reactNavigationRef.current?.dispatch(DrawerActions.jumpTo(tabName));
    if (pop) {
      this.reactNavigationRef.current?.dispatch(
        StackActions.popToTop({ animationEnabled: false })
      );
    }
  };

  handleJumpTab = (tabName, tabRootName, screenName, params = {}) => {
    this.jumpTab(tabName, tabRootName, screenName, params);
  };

  handleJumpDrawer = (tabName, tabRootName, screenName, params = {}) => {
    this.jumpDrawer(tabName, tabRootName, screenName, params);
  };

  jumpDrawer = (tabName, screenName, params = {}) => {
    const tabRootName = _.find(
      this.navigationConfig.screens["DRAWER"].tabScreens,
      (ts) => {
        return ts.name === tabName;
      }
    );

    this.reactNavigationRef.current?.dispatch(DrawerActions.jumpTo(tabName));
    const timeout = 0;

    if (screenName) {
      setTimeout(() => {
        if (
          this.reactNavigationRef.current?.getCurrentRoute().name !==
          tabRootName
        ) {
          this.reactNavigationRef.current?.dispatch(
            StackActions.popToTop({ animationEnabled: false })
          );
          setTimeout(() => {
            this.reactNavigationRef.current?.dispatch(
              StackActions.push(screenName, params)
            );
          }, timeout);
        } else {
          setTimeout(() => {
            this.reactNavigationRef.current?.dispatch(
              StackActions.push(screenName, params)
            );
          }, timeout);
        }
      }, timeout);
    } else {
      setTimeout(() => {
        if (
          this.reactNavigationRef.current?.getCurrentRoute().name !==
          tabRootName
        ) {
          this.reactNavigationRef.current?.dispatch(
            StackActions.popToTop({ animationEnabled: false })
          );
        }
      }, timeout);
    }
  };

  handleNavigate = (screen, params) => {
    this.reactNavigate(screen, params);
  };
  handleReplace = (screen, params) => {
    this.reactReplace(screen, params);
  };

  handlePush = (screen, params) => {
    this.push(screen, params);
  };

  goBack = () => {
    this.reactNavigationRef.current?.goBack();
  };

  handleEmit = ({ event, params }) => {
    console.log("Event : ", event, this.reactNavigationRef.current);
    if (event.display == "push") {
      this.handlePush({ screen: event.screen, params: params });
    } else if (event.display == "modal") {
      this.handleNavigate({ screen: event.screen + "_MODAL", params: params });
    } else {
      this.handleReplace({ screen: event.screen + "_MODAL", params: params });
    }
  };

  switchTab = (tabName, pop = false) => {
    console.log("Ref : ", this.reactNavigationRef.current);
    this.reactNavigationRef.current?.dispatch(TabActions.jumpTo(tabName));
    if (pop) {
      if (this.reactNavigationRef.current?.canGoBack()) {
        this.reactNavigationRef.current?.dispatch(
          StackActions.popToTop({ animationEnabled: false })
        );
      }
    }
  };

  popToTop = (tabName) => {
    console.log("Ref : ", this.reactNavigationRef.current);
    this.reactNavigationRef.current?.dispatch(TabActions.jumpTo(tabName));
    if (this.reactNavigationRef.current?.canGoBack()) {
      this.reactNavigationRef.current?.dispatch(
        StackActions.popToTop({ animationEnabled: false })
      );
    }
  };

  renderTree = (loggedIn, desktop) => {
    this.isDesktop = true;
    return this.createNavigationTree(loggedIn);
  };

  findModalScreens(navigationConfig, exclude) {
    return _.filter(
      _.map(_.keys(navigationConfig.screens), (key) => {
        return {
          name: key,
          screen: navigationConfig.screens[key],
        };
      }),
      (a) => {
        return a.name + "_MODAL" !== exclude;
      }
    );
  }

  findPushScreens(screen, navigationConfig, exclude) {
    return _.filter(
      _.map(_.keys(navigationConfig.screens), (key) => {
        return {
          name: key,
          screen: navigationConfig.screens[key],
        };
      }),
      (a) => {
        return a.name !== exclude;
      }
    );
  }

  createTabRootScreensNavigationTree = (root) => {
    const tabScreens = root.tabScreens;

    const screens = _.map(tabScreens, (tabScreen) => {
      const screensOfTab = this.findPushScreens(
        this.navigationConfig.screens[tabScreen.screen],
        this.navigationConfig,
        tabScreen.screen
      );

      let Stack = createStackNavigator();
      const tabScreenStack = _.map(screensOfTab, (tab) => {
        const initialParams = _.omit(tab.screen, [
          "component",
          "header",
          "headerParams",
          "path",
        ]);
        const Header = tab.screen.header;
        return (
          <Stack.Screen
            key={tab.name}
            component={tab.screen.component}
            name={tab.name}
            initialParams={initialParams}
            options={{
              header: ({ scene, previous, navigation }) => {
                if (tab.screen.header) {
                  const { options } = scene.descriptor;
                  return (
                    <Header
                      scene={scene}
                      previous={previous}
                      options={options}
                      title={options.title || tab.screen.title}
                      {...tab.screen.headerParams}
                      navigation={navigation}
                    />
                  );
                } else {
                  return undefined;
                }
              },
            }}
          ></Stack.Screen>
        );
      });
      console.log("tabScreen : ", tabScreen, tabScreenStack);

      const Header = this.navigationConfig.screens[tabScreen.screen].header;
      return (
        <Stack.Navigator
          initialRouteName={tabScreen.screen}
          headerMode="screen"
        >
          <Stack.Screen
            component={
              this.navigationConfig.screens[tabScreen.screen].component
            }
            options={{
              header: ({ scene, previous, navigation }) => {
                if (Header) {
                  const { options } = scene.descriptor;
                  return (
                    <Header
                      scene={scene}
                      previous={previous}
                      options={options}
                      title={
                        options.title ||
                        this.navigationConfig.screens[tabScreen.screen]
                          .component
                      }
                      {...this.navigationConfig.screens[tabScreen.screen]
                        .headerParams}
                      navigation={navigation}
                    />
                  );
                } else {
                  return undefined;
                }
              },
            }}
            name={tabScreen.screen}
          ></Stack.Screen>
          {tabScreenStack}
        </Stack.Navigator>
      );
    });

    return screens;
  };

  findAllModalScreens = (root) => {
    return this.findModalScreens(this.navigationConfig);
  };

  checkAndNavigateToRightScreen = () => {
    if (window && window.localStorage) {
      console.log("OPATH Init Home Page = " + window.localStorage.o_path);
      var storedHref = window.localStorage.o_path;

      //delete window.localStorage.o_path;
      if (storedHref && storedHref.indexOf("?o_path=") >= 0) {
        let params = {};
        var urlPath = storedHref
          .split("?o_path=")[1]
          .split("#")[0]
          .replace("checkout", "")
          .replace("order_details", "my_orders")
          .replace("subscription_details", "my_subscriptions");

        if (urlPath != "") {
          if (urlPath[0] == "/") {
            urlPath = urlPath.substr(1);
          }
          if (_.includes(urlPath, "?")) {
            const splits = urlPath.split("?");
            urlPath = splits[0];
            params = qs.parse(splits[1]);
          }
          var currentPath = decodeURIComponent(urlPath);
          return { path: currentPath, params };
        }
      }
    }
    return {};
  };

  findMain = (loggedIn) => {
    if (this.navigationConfig["main"]) {
      return this.navigationConfig.main;
    } else {
      return this.navigationConfig.logged_out && !loggedIn
        ? this.navigationConfig.logged_out
        : this.navigationConfig.logged_in;
    }
  };

  generateLinkingForScreen = (modal, all, toplevel) => {
    if (modal.screen.type === "TABS") {
      return {};
    } else {
      if (toplevel) {
        return {
          [modal.name + "_MODAL"]: {
            path: modal.screen.path,
          },
        };
      }
    }
  };

  generateLinking = (loggedIn) => {
    const modals = this.findAllModalScreens();
    let config = {
      screens: {},
    };
    _.forEach(modals, (modal) => {
      const c = this.generateLinkingForScreen(modal, modals, true);
      config = {
        ...config,
        screens: {
          ...config.screens,
          ...c,
        },
      };
    });
    return {
      config,
    };
  };

  findScreenFromPath = (path, linking) => {
    let m = undefined;
    _.forEach(_.keys(linking.config), (k) => {
      const screen = linking.config[k];

      if (screen.path === path) {
        m = k;
      }
    });
    if (m) return m;
  };

  createNavigationTree = (loggedIn) => {
    const linking = this.generateLinking(loggedIn);
    let Main = this.findMain(loggedIn);
    const root = this.navigationConfig.screens[Main];
    const RootStack = createStackNavigator();
    const modalScreens = this.findAllModalScreens(root);

    const {
      path: initialPath,
      params: initialParams,
    } = this.checkAndNavigateToRightScreen();

    const initialRoute = initialPath
      ? this.findScreenFromPath(initialPath, linking)
      : undefined;

    if (root?.type === "TABS") {
      const Component = root.component;
      const screens = this.createTabRootScreensNavigationTree(root);
      console.log("Screens for Tabs ; ", screens);
      const component = (
        <NavigationContainer
          documentTitle={{
            formatter: () => config.applicationName,
          }}
          linking={linking}
          ref={this.ref}
          key={`user-${loggedIn}`}
        >
          <RootStack.Navigator
            mode={"modal"}
            initialRouteName={initialRoute ? initialRoute : Main}
            headerMode="none"
            screenOptions={{
              headerShown: false,
              cardStyle: { backgroundColor: "transparent" },
              cardOverlayEnabled: true,
            }}
          >
            <RootStack.Screen
              component={Component}
              name={Main}
              initialParams={{ screens: screens, tabConfigs: root.tabScreens }}
            />
            {_.map(modalScreens, (msc) => {
              const Header = msc.screen.header;
              return (
                <RootStack.Screen
                  key={`${msc.name + "_MODAL"}`}
                  component={msc.screen.component}
                  name={`${msc.name + "_MODAL"}`}
                  initialParams={
                    `${msc.name + "_MODAL"}` === initialRoute
                      ? initialParams
                      : undefined
                  }
                  options={{
                    headerShown: !!Header,
                    header: ({ scene, previous, navigation }) => {
                      if (Header) {
                        const { options } = scene.descriptor;
                        return (
                          <Header
                            scene={scene}
                            previous={previous}
                            options={options}
                            title={options.title || msc.screen.title}
                            {...msc.screen.headerParams}
                            navigation={navigation}
                          />
                        );
                      } else {
                        return undefined;
                      }
                    },
                    cardStyleInterpolator: msc.screen?.fadeIn
                      ? forFade
                      : undefined,
                  }}
                />
              );
            })}
          </RootStack.Navigator>
        </NavigationContainer>
      );
      return component;
    } else if (root?.type === "DRAWER") {
      const Component = root.component;
      const screens = this.createTabRootScreensNavigationTree(root);
      console.log("Screens for Tabs ; ", screens);
      const component = (
        <NavigationIndependentTree>
          <NavigationContainer
            documentTitle={{
              formatter: () => config.applicationName,
            }}
            linking={linking}
            ref={this.ref}
            key={`user-${loggedIn}`}
          >
            <RootStack.Navigator
              mode="modal"
              documentTitle={{
                formatter: () => config.applicationName,
              }}
              initialRouteName={initialRoute ? initialRoute : Main}
              screenOptions={{
                headerShown: false,
                cardStyle: { backgroundColor: "transparent" },
                cardOverlayEnabled: true,
              }}
            >
              <RootStack.Screen
                component={Component}
                name={Main}
                initialParams={{
                  screens: screens,
                  tabConfigs: root.tabScreens,
                }}
              />
              {_.map(modalScreens, (msc) => {
                const Header = msc.screen.header;
                return (
                  <RootStack.Screen
                    key={`${msc.name + "_MODAL"}`}
                    initialParams={
                      `${msc.name + "_MODAL"}` === initialRoute
                        ? initialParams
                        : undefined
                    }
                    component={msc.screen.component}
                    name={`${msc.name + "_MODAL"}`}
                    options={{
                      headerShown: !!Header,
                      header: ({ scene, previous, navigation }) => {
                        if (Header) {
                          const { options } = scene.descriptor;
                          return (
                            <Header
                              scene={scene}
                              previous={previous}
                              options={options}
                              title={options.title || msc.screen.title}
                              {...msc.screen.headerParams}
                              navigation={navigation}
                            />
                          );
                        } else {
                          return undefined;
                        }
                      },
                      cardStyleInterpolator: msc.screen?.fadeIn
                        ? forFade
                        : undefined,
                    }}
                  />
                );
              })}
            </RootStack.Navigator>
          </NavigationContainer>
        </NavigationIndependentTree>
      );
      return component;
    } else {
      const Component = root.component;
      const component = (
        <NavigationIndependentTree>
          <NavigationContainer
            documentTitle={{
              formatter: () => config.applicationName,
            }}
            theme={{
              colors: {
                background: "transparent",
              },
            }}
            linking={linking}
            key={`user-${loggedIn}`}
            ref={this.ref}
          >
            <RootStack.Navigator
              mode="modal"
              initialRouteName={initialRoute ? initialRoute : Main + "_MODAL"}
              screenOptions={{
                headerShown: false,
                cardStyle: { backgroundColor: "transparent" },
                cardOverlayEnabled: true,
              }}
            >
              <RootStack.Screen
                component={Component}
                name={Main}
                options={{ headerShown: false }}
              />
              {_.map(modalScreens, (msc) => {
                return (
                  <RootStack.Screen
                    key={`${msc.name + "_MODAL"}`}
                    component={msc.screen.component}
                    name={`${msc.name + "_MODAL"}`}
                    initialParams={
                      `${msc.name + "_MODAL"}` === initialRoute
                        ? initialParams
                        : undefined
                    }
                    options={
                      msc.screen?.fadeIn
                        ? { cardStyleInterpolator: forFade }
                        : {}
                    }
                  />
                );
              })}
            </RootStack.Navigator>
          </NavigationContainer>
        </NavigationIndependentTree>
      );
      return component;
    }
  };

  reactNavigate = (...args) => {
    this.reactNavigationRef.current?.navigate(...args);
  };

  reactReplace = (name, params) => {
    this.reactNavigationRef.current?.dispatch(
      StackActions.replace(name, params)
    );
    //this.reactNavigationRef.current?.replace(...args);
  };

  push = (...args) => {
    console.log("this.ref : ", this.ref);
    this.reactNavigationRef.current?.dispatch(StackActions.push(...args));
    //this.reactNavigationRef.current.push(...args);
  };

  jumpTab = (tabName, screenName, params = {}) => {
    const tabRootName = _.find(
      this.navigationConfig.screens["TABS"].tabScreens,
      (ts) => {
        return ts.name === tabName;
      }
    );
    console.log(
      "Hello Hello ",
      this.navigationConfig.screens["TABS"],
      tabRootName,
      screenName
    );
    this.reactNavigationRef.current?.dispatch(TabActions.jumpTo(tabName));
    const timeout = 600;

    if (screenName) {
      setTimeout(() => {
        if (
          this.reactNavigationRef.current?.getCurrentRoute().name !==
          tabRootName
        ) {
          this.reactNavigationRef.current?.dispatch(
            StackActions.popToTop({ animationEnabled: false })
          );
          setTimeout(() => {
            this.reactNavigationRef.current?.dispatch(
              StackActions.push(screenName, params)
            );
          }, timeout);
        } else {
          setTimeout(() => {
            this.reactNavigationRef.current?.dispatch(
              StackActions.push(screenName, params)
            );
          }, timeout);
        }
      }, timeout);
    } else {
      setTimeout(() => {
        if (
          this.reactNavigationRef.current?.getCurrentRoute().name !==
          tabRootName
        ) {
          this.reactNavigationRef.current?.dispatch(
            StackActions.popToTop({ animationEnabled: false })
          );
        }
      }, timeout);
    }
  };

  // TODO(amal) : ubu might not work
  navigateTo = (targetUrl) => {
    if (targetUrl === "app/vouchers") {
      SessionManager.isLoggedInUser(
        () => {
          this.jumpTab("WALLET", "WALLET_PAGE", "VOUCHERS_LIST_PAGE");
        },
        () => { }
      );
    } else if (targetUrl === "app/discover") {
      this.jumpTab("DISCOVER", "DISCOVER_PAGE");
    } else if (_.startsWith(targetUrl, "app/browse")) {
      const categoryId = _.split(targetUrl, "/")[2];
      this.jumpTab("DISCOVER", "VENDOR_PAGE", {
        vendorId: categoryId,
      });
    } else if (_.startsWith(targetUrl, "app/item")) {
      console.log(targetUrl);
      const itemId = _.split(targetUrl, "/")[2];
      this.jumpTab("DISCOVER", "VENDOR_PAGE", {
        itemId: itemId,
      });
    } else if (_.startsWith(targetUrl, "app/categoryItem")) {
      console.log(targetUrl);
      const merchantId = _.split(targetUrl, "/")[2];
      const categoryId = _.split(targetUrl, "/")[3];
      const itemId = _.split(targetUrl, "/")[4];
      this.jumpTab("DISCOVER", "VENDOR_PAGE", {
        vendorId: merchantId,
        itemId: itemId,
        categoryId: categoryId,
      });
    } else if (_.startsWith(targetUrl, "app/searchCatalog")) {
      const categoryId = _.split(targetUrl, "/")[2];
      this.jumpTab("DISCOVER", "DISCOVER_PAGE", "VENDOR_PAGE", {
        vendorId: categoryId,
      });
    } else if (targetUrl === "app/wallet") {
      SessionManager.isLoggedInUser(
        () => {
          this.jumpTab("WALLET");
        },
        () => { }
      );
    } else if (targetUrl === "app/home") {
      this.jumpTab("HOME");
    } else if (targetUrl === "app/profile") {
      SessionManager.isLoggedInUser(
        () => {
          this.jumpTab("PROFILE");
        },
        () => { }
      );
    }
  };

  jumpNewTab = (tabName, tabRootName, screenName, params = {}) => {
    this.reactNavigationRef.current?.dispatch(TabActions.jumpTo(tabName));
    const timeout = 600;

    if (screenName) {
      setTimeout(() => {
        if (
          this.reactNavigationRef.current?.getCurrentRoute().name !==
          tabRootName
        ) {
          this.reactNavigationRef.current?.dispatch(
            StackActions.popToTop({ animationEnabled: false })
          );
          setTimeout(() => {
            this.reactNavigationRef.current?.dispatch(
              StackActions.push(screenName, params)
            );
          }, timeout);
        } else {
          setTimeout(() => {
            this.reactNavigationRef.current?.dispatch(
              StackActions.push(screenName, params)
            );
          }, timeout);
        }
      }, timeout);
    } else {
      setTimeout(() => {
        if (
          this.reactNavigationRef.current?.getCurrentRoute().name !==
          tabRootName
        ) {
          this.reactNavigationRef.current?.dispatch(
            StackActions.popToTop({ animationEnabled: false })
          );
        }
      }, timeout);
    }
  };

  get ref() {
    return this.reactNavigationRef;
  }
}

export default new WebNavigationManager();
