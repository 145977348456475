import _ from "lodash";
import { isOfflineEnabledAPI } from "@presto-common/OfflineAPIsConfig";
import DBCouponManager from "@presto-db-managers/DBCouponManager";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import PrestoSdk from "../services/global/PrestoSdk";
import Utils from "../services/common/Utils";

function getCoupons(params, successCallback, errorCallback) {
  let newParams = _.cloneDeep(params);
  let enableOffline = false;
  if (params.enableOffline) {
    enableOffline = params.enableOffline;
    newParams = _.omit(newParams, "enableOffline");
  }

  if (isOfflineEnabledAPI("couponFetch") || enableOffline) {
    const obj = new DBCouponManager();
    obj
      .getCoupons(newParams)
      .then((rows) => {
        successCallback(obj.mockAsAPI(rows));
      })
      .catch((error) => {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      });
  } else {
    EmployeeManager.getCoupons(newParams, successCallback, errorCallback);
  }
}

export default {
  getCoupons,
};
