import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";

const AdminResource = function ($http, PrestoSdk) {
  function galleryMediaItems(params) {
    var url =
      PrestoSdk.getHostName() +
      "/admin/v0/galleries/" +
      params.galleryId +
      "/media_items.json?galleryId=" +
      params.galleryId;

    return $http.get(url, params);
  }

  function fetchGallaryDetails(params) {
    var url = PrestoSdk.getHostName() + "/admin/v0/galleries.json";
    return $http.get(url, params);
  }

  function uploadStoreImage(params) {
    var url =
      PrestoSdk.getHostName() +
      "/admin/v0/galleries/" +
      params.galleryId +
      "/media_items.json";

    return $http.post(url, params);
  }

  function deleteStoreImage(params) {
    var url =
      PrestoSdk.getHostName() +
      "/admin/v0/galleries/" +
      params.galleryId +
      "/media_items/" +
      params.id +
      ".json";

    return $http.delete(url, params);
  }

  function createCategory(params) {
    let url = PrestoSdk.getHostName() + "/categories.json";
    for (key in params) {
      if (params.hasOwnProperty(key)) {
        if (-1 == url.indexOf("?")) {
          url = url + "?" + key + "=" + params[key];
        } else {
          url = url + "&" + key + "=" + params[key];
        }
      }
    }
    return $http.post(url, params);
  }

  function updateCategory(params) {
    let url = PrestoSdk.getHostName() + "/categories/" + params.id + ".json";
    return $http.put(url, params);
  }

  function createServiceVariation(params) {
    let url = PrestoSdk.getHostName() + "/admin/v1/variations.json";
    return $http.post(url, params);
  }

  function updateServiceVariation(params) {
    let url =
      PrestoSdk.getHostName() + "/admin/v1/variations/" + params.id + ".json";
    return $http.put(url, params);
  }

  function deleteServiceVariation(params) {
    let url =
      PrestoSdk.getHostName() +
      "/admin/v1/variations/" +
      params.id +
      ".json?service_id=" +
      params.service_id;
    return $http.delete(url);
  }

  function getAllStylists() {
    var url = PrestoSdk.getHostName() + "/admin/v1/operators.json";
    return $http.get(url);
  }

  function getAdminCoupons(params) {
    var url = PrestoSdk.getHostName() + "/admin/v0/coupons.json";
    return $http.get(url);
  }

  function createAdminCoupon(params) {
    var url = PrestoSdk.getHostName() + "/admin/v0/coupons.json";
    return $http.post(url, params);
  }

  function showAdminCoupon(params) {
    var url =
      PrestoSdk.getHostName() + "/admin/v0/coupons/" + params.id + ".json";
    return $http.get(url);
  }

  function deleteAdminCoupon(params) {
    var url =
      PrestoSdk.getHostName() + "/admin/v0/coupons/" + params.id + ".json";
    return $http.delete(url);
  }

  function updateAdminCoupon(params) {
    var url =
      PrestoSdk.getHostName() + "/admin/v0/coupons/" + params.id + ".json";
    return $http.put(url, params);
  }

  function getAllBookings(params) {
    let str = Object.entries(params)
      .map(([key, val]) => `[${key}]=${val}`)
      .join("&");
    var url = PrestoSdk.getHostName() + "/admin/v1/bookings.json?" + str;
    return $http.get(url, params);
  }

  function showOperationalTiming(params) {
    let entity_name = params.entity_name ?? "Merchant";
    var url =
      PrestoSdk.getHostName() +
      `/admin/v0/operational_timing.json?entity_name=${entity_name}&entity_id=${params.entity_id}`;
    return $http.get(url, params);
  }

  function createOperationalTiming(params) {
    var url = PrestoSdk.getHostName() + `/admin/v0/operational_timing.json`;
    return $http.post(url, params);
  }

  function updateOperationalTiming(params) {
    var url = PrestoSdk.getHostName() + `/admin/v0/operational_timing.json`;
    return $http.put(url, params);
  }

  function createGallery(params) {
    var url = PrestoSdk.getHostName() + "/admin/v0/galleries.json";
    return $http.post(url, params);
  }

  function showNonWorkingSlots(params) {
    var url =
      PrestoSdk.getHostName() +
      `/admin/v1/non_working_slots.json?entity_id=${params.entity_id}&entity_name=${params.entity_name}`;
    return $http.get(url, params);
  }

  function createNonWorkingSlots(params) {
    var url = PrestoSdk.getHostName() + `/admin/v1/non_working_slots.json`;
    return $http.post(url, params);
  }

  function updateNonWorkingSlots(params) {
    var url =
      PrestoSdk.getHostName() + `/admin/v1/non_working_slots/${params.id}.json`;
    return $http.put(url, params);
  }

  function removeNonWorkingSlots(params) {
    var url =
      PrestoSdk.getHostName() + `/admin/v1/non_working_slots/${params.id}.json`;
    console.log("removeNonWorkingSlots url, params", url, params);
    return $http.delete(url, params);
  }

  function operatorsOccupancyByDate(params) {
    var url =
      PrestoSdk.getHostName() +
      `/admin/v1/bookings/occupancy.json?start_time=${params.start_time}&end_time=${params.end_time}&operator_ids=${params.operator_ids}`;

    return $http.get(url);
  }

  function getNewUserStats(params) {
    var url =
      PrestoSdk.getHostName() +
      `/reports/users/new_users.json?start_time=${params.start_time}&end_time=${params.end_time}&aggregate_by=${params.aggregate_by}`;
    return $http.get(url);
  }

  function getRecurringUserStats(params) {
    var url =
      PrestoSdk.getHostName() +
      `/reports/users/recurring_users.json?start_time=${params.start_time}&end_time=${params.end_time}&aggregate_by=${params.aggregate_by}`;
    console.log("url = = ", url);
    return $http.get(url);
  }

  function getDailyRevenueStats(params) {
    var url =
      PrestoSdk.getHostName() +
      `/reports/revenues/daily_revenue_growth.json?start_time=${params.start_time}&end_time=${params.end_time}&aggregate_by=${params.aggregate_by}`;
    return $http.get(url);
  }

  function initiateBankDetailsCreation(params) {
    console.log("params.", params);
    var url =
      PrestoSdk.getHostName() +
      "/admin/v0/account_detail/initiate_account_detail_creation.json";
    console.log("url", url);
    return $http.post(url, params);
  }

  function generatorLoginTokenForOperator(params) {
    var url =
      PrestoSdk.getHostName() +
      "/admin/v1/operators/" +
      params.id +
      "/generate_login_token.json";
    return $http.post(url, params);
  }

  function generatorLoginTokenForEmployee(params) {
    var url =
      PrestoSdk.getHostName() +
      "/admin/v0/employees/" +
      params.id +
      "/generate_login_token.json";
    return $http.post(url, params);
  }

  function getOrders(params) {
    var url = PrestoSdk.getHostName() + `/admin/v0/orders.json`;
    if (params.oldest_time) {
      url += "?oldest_time=" + params.oldest_time;
    }
    return $http.get(url);
  }

  function getOrdersByOrderId(params) {
    var url = PrestoSdk.getHostName() + "/orders/" + params.order_id + ".json";
    return $http.get(url);
  }

  function updateOrderStatus(params) {
    var url = PrestoSdk.getHostName() + "/orders/" + params.order_id + ".json";
    return $http.put(url, params);
  }

  function getAllAppointments(params) {
    var url =
      PrestoSdk.getHostName() +
      "/admin/v1/appointments.json?start_time=" +
      params.start_time +
      "&end_time=" +
      params.end_time +
      "&time_type=" +
      params.time_type;
    return $http.get(url, params);
  }

  function getStylistStats(params) {
    var url =
      PrestoSdk.getHostName() +
      `/admin/v0/metrics/time_series_metric.json?type=${params.type}&start_time=${params.start_time}&end_time=${params.end_time}&interval=${params.interval}&employee_id=${params.operator_id}`; // here the employee_id refers to operator.id
    return $http.get(url, params);
  }

  function getAppointmentCountStats(params) {
    var url =
      PrestoSdk.getHostName() +
      `/admin/v0/metrics/time_series_metric.json?type=appointment_created&start_time=${params.start_time}&end_time=${params.end_time}&interval=${params.interval}`;
    return $http.get(url, params);
  }

  function getLoyaltyConfigInfo(params) {
    var url = PrestoSdk.getHostName() + "/admin/v0/loyalty_configs.json";
    return $http.get(url, params);
  }

  function createLoyaltyConfig(params) {
    var url = PrestoSdk.getHostName() + "/admin/v0/loyalty_configs.json";
    return $http.post(url, params);
  }

  function createLoyaltyFormula(params) {
    var url =
      PrestoSdk.getHostName() +
      "/admin/v0/loyalty_configs/loyalty_formulas.json";
    return $http.post(url, params);
  }

  function getLoyaltyFormula(params) {
    var url =
      PrestoSdk.getHostName() +
      `/admin/v0/loyalty_configs/loyalty_formulas.json?target_type=${params.target_type}&target_id=${params.target_id}`;
    return $http.get(url);
  }

  function deleteLoyaltyFormula(params) {
    var url =
      PrestoSdk.getHostName() +
      "/admin/v0/loyalty_configs/loyalty_formulas/" +
      params.id +
      ".json" +
      `?target_type=${params.target_type}&target_id=${params.target_id}`;
    return $http.delete(url, params);
  }

  function updateLoyaltyFormula(params) {
    var url =
      PrestoSdk.getHostName() +
      "/admin/v0/loyalty_configs/loyalty_formulas/" +
      params.id;

    return $http.put(url, params);
  }

  function createSegment(params) {
    var url = PrestoSdk.getHostName() + "/admin/v0/segments.json";
    return $http.post(url, params);
  }

  function listSegments(params) {
    var url = PrestoSdk.getHostName() + "/admin/v0/segments.json";
    return $http.get(url);
  }

  function updateSegment(params) {
    var url =
      PrestoSdk.getHostName() + "/admin/v0/segments/" + params.id + ".json";

    return $http.put(url, params);
  }

  function removeSegment(params) {
    var url =
      PrestoSdk.getHostName() + "/admin/v0/segments/" + params.id + ".json";
    return $http.delete(url);
  }

  function createSegmentRule(params) {
    var url = PrestoSdk.getHostName() + "/admin/v0/segment_rules.json";
    return $http.post(url, params);
  }

  function listSegmentRules(params) {
    var url = PrestoSdk.getHostName() + "/admin/v0/segment_rules.json";
    return $http.get(url);
  }

  function updateSegmentRule(params) {
    var url =
      PrestoSdk.getHostName() +
      "/admin/v0/segment_rules/" +
      params.id +
      ".json";

    return $http.put(url, params);
  }

  function removeSegmentRule(params) {
    var url =
      PrestoSdk.getHostName() +
      "/admin/v0/segment_rules/" +
      params.id +
      ".json";
    return $http.delete(url);
  }

  function getPriceRules(params) {
    var url = PrestoSdk.getHostName() + "/admin/v0/price_rules.json";
    return $http.get(url);
  }

  function getPriceRule(params) {
    var url =
      PrestoSdk.getHostName() + "/admin/v0/price_rules/" + params.id + ".json";
    return $http.get(url);
  }

  function createPriceRule(params) {
    var url = PrestoSdk.getHostName() + "/admin/v0/price_rules.json";
    return $http.post(url, params);
  }

  function deletePriceRule(params) {
    var url =
      PrestoSdk.getHostName() + "/admin/v0/price_rules/" + params.id + ".json";
    return $http.delete(url, params);
  }

  function updatePriceRule(params) {
    var url =
      PrestoSdk.getHostName() + "/admin/v0/price_rules/" + params.id + ".json";
    return $http.put(url, params);
  }

  function createItemPromotion(params) {
    var url = PrestoSdk.getHostName() + "/admin/v1/item_promotions.json";
    return $http.post(url, params);
  }

  function listItemPromotions(params) {
    var url = PrestoSdk.getHostName() + "/admin/v1/item_promotions.json";
    return $http.get(url);
  }

  function showItemPromotion(params) {
    var url =
      PrestoSdk.getHostName() +
      "/admin/v1/item_promotions/" +
      params.id +
      ".json";
    return $http.get(url);
  }

  function updateItemPromotion(params) {
    var url =
      PrestoSdk.getHostName() +
      "/admin/v1/item_promotions/" +
      params.id +
      ".json";
    return $http.put(url, {
      item_promotion: params.item_promotion,
    });
  }

  function removeItemPromotion(params) {
    var url =
      PrestoSdk.getHostName() +
      "/admin/v1/item_promotions/" +
      params.id +
      ".json";
    return $http.delete(url);
  }

  function getPriceRuleDetails(params) {
    var url =
      PrestoSdk.getHostName() + "/admin/v0/price_rules/" + params.id + ".json";
    return $http.get(url);
  }

  return {
    fetchGallaryDetails: fetchGallaryDetails,
    createGallery: createGallery,
    uploadStoreImage: uploadStoreImage,
    galleryMediaItems: galleryMediaItems,
    deleteStoreImage: deleteStoreImage,
    createCategory: createCategory,
    updateCategory: updateCategory,
    createServiceVariation: createServiceVariation,
    updateServiceVariation: updateServiceVariation,
    deleteServiceVariation: deleteServiceVariation,
    getAllStylists: getAllStylists,
    getAllBookings: getAllBookings,
    getAdminCoupons: getAdminCoupons,
    createAdminCoupon: createAdminCoupon,
    showAdminCoupon: showAdminCoupon,
    deleteAdminCoupon: deleteAdminCoupon,
    updateAdminCoupon: updateAdminCoupon,
    showOperationalTiming: showOperationalTiming,
    createOperationalTiming: createOperationalTiming,
    updateOperationalTiming: updateOperationalTiming,
    showNonWorkingSlots: showNonWorkingSlots,
    createNonWorkingSlots: createNonWorkingSlots,
    updateNonWorkingSlots: updateNonWorkingSlots,
    removeNonWorkingSlots: removeNonWorkingSlots,
    initiateBankDetailsCreation: initiateBankDetailsCreation,
    operatorsOccupancyByDate: operatorsOccupancyByDate,
    getNewUserStats: getNewUserStats,
    getRecurringUserStats: getRecurringUserStats,
    getDailyRevenueStats: getDailyRevenueStats,
    generatorLoginTokenForOperator: generatorLoginTokenForOperator,
    generatorLoginTokenForEmployee: generatorLoginTokenForEmployee,
    getOrders: getOrders,
    getOrdersByOrderId: getOrdersByOrderId,
    updateOrderStatus: updateOrderStatus,
    getAllAppointments: getAllAppointments,
    getStylistStats: getStylistStats,
    getAppointmentCountStats: getAppointmentCountStats,
    getLoyaltyConfigInfo: getLoyaltyConfigInfo,
    createLoyaltyConfig: createLoyaltyConfig,
    createLoyaltyFormula: createLoyaltyFormula,
    getLoyaltyFormula: getLoyaltyFormula,
    deleteLoyaltyFormula: deleteLoyaltyFormula,
    updateLoyaltyFormula: updateLoyaltyFormula,
    createSegment,
    listSegments,
    updateSegment,
    removeSegment,
    createSegmentRule,
    listSegmentRules,
    updateSegmentRule,
    removeSegmentRule,
    getPriceRule: getPriceRule,
    getPriceRules: getPriceRules,
    createPriceRule: createPriceRule,
    deletePriceRule: deletePriceRule,
    updatePriceRule: updatePriceRule,
    getPriceRuleDetails: getPriceRuleDetails,
    createItemPromotion,
    listItemPromotions,
    showItemPromotion,
    updateItemPromotion,
    removeItemPromotion,
  };
};

export default AdminResource($http, PrestoSdk);
