import React from "react";
import _ from "lodash";
import SideNavigation from "@presto-screen-components/SideNavigation/SideNavigation";

export default function SideNavigationScreen(props) {
  let { forwardProps, closeModal } = props?.route?.params;
  console.log(
    "forwardProps, closeModal",
    JSON.stringify(forwardProps),
    closeModal
  );
  return (
    <SideNavigation
      {...forwardProps}
      closeModal={_.isFunction(closeModal) && closeModal}
    />
  );
}
