import I18n from "i18n-js";
import _ from "lodash";
import moment from "moment";
import utils from "../utils/index";

export default class PurchaseItemModel {
  constructor({ item, itemPurchaseConfiguration }) {
    this.item = item;
    this.itemPurchaseConfiguration = itemPurchaseConfiguration;
  }

  get name() {
    return this.item.name;
  }

  get companyName() {
    return this.item.other_data.company_name;
  }

  get molecule() {
    return this.item.other_data.molecule;
  }

  get formatTotalPrice() {
    return utils.formattedCurrency(this.item.total_price / 100);
  }

  get formatDeliveredPrice() {
    let qty = this.deliveredQuantity;
    let rate = this.rate;

    qty = Number(qty);
    if (isNaN(qty)) {
      qty = 0;
    }
    let val = _.multiply(qty, rate);

    return utils.formattedCurrency(val);
  }

  get formatDeliveredPrice2() {
    let qty = this.deliveredQuantity;
    let rate = this.rate;

    qty = Number(qty);
    if (isNaN(qty)) {
      qty = 0;
    }
    let val = _.multiply(qty, rate);

    return val / 100;
  }

  get quantity() {
    return this.item.quantity;
  }

  get deliveredQuantity() {
    return this.item.delivered_quantity;
  }

  get rate() {
    let val = Number(this.item.rate);
    if (isNaN(val)) {
      val = 0;
    }
    return val;
  }

  get purchaseDisplayUnitName() {
    let val = _.get(
      this.itemPurchaseConfiguration,
      "purchase_unit_display_name"
    );
    return val;
  }

  get saleDisplayUnitName() {
    let val = _.get(this.itemPurchaseConfiguration, "sale_unit_display_name");
    return val;
  }

  get saleToPurchaseRatio() {
    let val = Number(
      _.get(this.itemPurchaseConfiguration, "sale_to_purchase_ratio", 0)
    );

    if (isNaN(val)) {
      val = 0;
    }

    return val;
  }

  get convertedFromSaleUnitQtyToPurchaseUnitQty() {
    let ratio = this.saleToPurchaseRatio;
    let quantity = this.quantity;
    let val = ratio > 0 ? Math.ceil(quantity / ratio) : 0;

    if (isNaN(val)) {
      val = 0;
    }

    return val;
  }

  convertFromPurchaseUnitQtyToSaleUnitQty(qty) {
    let ratio = this.saleToPurchaseRatio;
    let val = _.multiply(qty, ratio);
    if (isNaN(val)) {
      val = 0;
    }
    return val;
  }

  static fake() {
    return new PurchaseItemModel({ item: {} });
  }
}
