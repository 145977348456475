import React, { useContext, useEffect, useState, useRef } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import StylesContext from "@presto-contexts/StylesContext";
import { Picker } from "@react-native-picker/picker";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import PrestoText from "@presto-components/PrestoText";
import { useAssets } from "presto-react-components";
import { Modal, Portal } from "react-native-paper";
import { useActionSheet } from "@expo/react-native-action-sheet";
import I18n from "i18n-js";
import _ from "lodash";

export default function PickerMobile(props) {
  const {
    data = props.items,
    selectedItem,
    disabled = false,
    labelFieldKey = "label",
    maxheight = 200,
    maxWidth = 200,
    onSelect,
    title,
    defaultText = I18n.t("screen_messages.common.choose_one"),
    textFontStyle = "600.regular",
    textContainerStyles = {},
    textColor,
  } = props || {};

  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const viewRef = useRef(null);
  const [selected, setSelected] = useState(false);

  const pickerItems = data.map((item) => _.get(item, labelFieldKey));
  const { showActionSheetWithOptions } = useActionSheet();

  const showActionSheet = () => {
    showActionSheetWithOptions(
      {
        options: [...pickerItems, I18n.t("screen_messages.common.cancel_text")],
        message: title,
        cancelButtonIndex: data.length,
      },
      (buttonIndex) => {
        onSelect(data[buttonIndex] ?? selectedItem);
        setSelected(false);
      }
    );
  };

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      ref={viewRef}
      style={{
        borderRadius: 6,
        borderWidth: 2,
        borderColor: !selected ? theme.grey : theme.primary,
        width: "100%",
        height: 50,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingHorizontal: 10,
        backgroundColor: theme.textContainerStyles,
        ...textContainerStyles,
      }}
      onPress={() => {
        !disabled ? showActionSheet() : null;
        setSelected(true);
      }}
    >
      <PrestoText
        color={
          textColor ||
          (selectedItem ? theme.textInputColor : theme.placeHolderColor)
        }
        fontFamily={theme.primaryMediumFont}
        size={16}
        fontStyle={"600.medium"}
        extraStyle={{}}
        numberOfLines={3}
      >
        {selectedItem ? selectedItem[labelFieldKey] : defaultText}
      </PrestoText>
      <svgs.DownIcon
        color={props?.dropdownIconStyles?.color || theme.darkGrey}
        width={props?.dropdownIconStyles?.width || 20}
        height={props?.dropdownIconStyles?.height || 20}
      />
    </TouchableOpacity>
  );
}

const getStyles = (theme, styles) => {
  return StyleSheet.create({
    container: {
      // ...styles.pickerButtonStyles.container,
    },
    picker: {
      height: styles.pickerButtonStyles.container.height || 30,
      borderColor: theme.black,
      backgroundColor: theme.light,
      flex: 1,
      alignItems: "center",
      width: "100%",
      zIndex: 1,
      opacity: 0,
      position: "absolute",
    },
  });
};
