import databaseConnection from "@presto-helpers/DatabaseConnectionHelper";
import _ from "lodash";
import { Platform } from "react-native";


const chuckSize = 20;

export default class DBBaseManager {
  get databaseConnection() {
    return databaseConnection;
  }

  applyDatatypeToObject(item) {
    const fillables = this.fillables;
    const datatypes = this.datatypes;
    for (let index in fillables) {
      const key = fillables[index];
      const datatype = datatypes[key];
      let value = item[key];
      if (datatype === "JSON") {
        value = _.isString(value) ? JSON.parse(value) : value;
      } else if (datatype === "BOOL") {
        if (_.includes(["1", 1, true, "true"])) {
          value = true;
        } else if (_.includes(["0", 0, false, "false"])) {
          value = false;
        }
      } else if (datatype === "INTEGER") {
        let val = parseInt(value);
        value = val;
      } else if (datatype === "NUMBER") {
        let val = Number(value);
        if (!isNaN(val)) {
          value = val;
        }
      }
      item[key] = value;
    }
    return item;
  }

  all() {
    let sql = `SELECT * FROM ${this.tableName};`,
      args = [];
    return this.asyncExec({ sql, args }).then(({ rows, ...props }) => {
      return rows;
    });
  }

  find(ids, key = "id") {
    const isWeb = Platform.OS == 'web'
    if (!_.isArray(ids)) {
      ids = [ids];
    }
    let newIds = _.compact(ids);
    let chunkedItems = _.chunk(newIds, chuckSize);

    let queries = _.map(chunkedItems, (checkItems) => {
      let args = checkItems;
      let whereQueryPlaceholders = _.join(
        _.fill(Array(args.length), "? "),
        ", "
      );
      let sqlQuery = `SELECT * FROM ${this.tableName} WHERE ${key} IN (${whereQueryPlaceholders})`;

      return {
        sql: sqlQuery,
        args: args,
      };
    });

    return new Promise((resolve, reject) => {
      isWeb ? resolve([]) :
        this.databaseConnection.exec(queries, false, (error, results) => {
          if (error) {
            reject(error);
          }
          let items = _.map(
            _.flatMap(results, (e) => e.rows),
            (e) => this.applyDatatypeToObject(e)
          );
          resolve(items);
        });
    });
  }

  first(id, key = "id") {
    return this.find(id, key).then((rows) => _.first(rows));
  }

  exists(where) {
    let sql = `select COUNT(_id) as count from ${this.tableName} where ${where}`;
    return this.asyncExec({ sql, args: [] }).then(({ rows }) => {
      let count = _.sum(_.map(rows, "count"));
      return count > 0;
    });
  }

  updateItem(item, whereQueryCallback) {
    const fillables = this.fillables;
    const datatypes = this.datatypes;
    let kvPair = _.pick(item, fillables);
    let keys = _.keys(kvPair);
    let bucket = [],
      query = `UPDATE ${this.tableName} SET`,
      whereValue = [];
    if (keys.length) {
      for (let index in keys) {
        const key = keys[index];
        const datatype = datatypes[key];
        let val = kvPair[key];

        if (datatype === "JSON") {
          val = !_.isString(val) ? JSON.stringify(val) : val;
        } else if (datatype === "BOOL") {
          val = _.includes([true, "true", 1, "1"], val) ? "true" : "false";
        } else if (datatype === "INTEGER") {
          let val = parseInt(value);
          value = val;
        } else if (datatype === "NUMBER") {
          let val = Number(value);
          if (!isNaN(val)) {
            value = val;
          }
        } else {
          let val = _.escape(val);
        }
        whereValue.push(val);
        let query = `${key}=?`;
        bucket.push(query);
      }

      let joined = _.join(bucket, ", ");
      const result = whereQueryCallback(kvPair);
      if (!_.isArray(result.values)) {
        whereValue = [...whereValue, result.values];
      } else {
        whereValue = [...whereValue, ...result.values];
      }
      query = `${query} ${joined} ${result.whereQuery};`;
    }
    console.log(
      "DBBaseManager->updateItem->query",
      _.truncate(JSON.stringify(query), {
        length: 150,
        separator: " ",
      })
    );
    return this.asyncExec({ sql: query, args: whereValue }).then(
      ({ rowsAffected, rows }) => {
        return {
          rows: _.first(rows),
          rowsAffected,
        };
      }
    );
  }

  async createOrUpdateMany(items) {
    let insertBucket = [],
      updateBucket = [];
    const ids = _.map(items, "id");
    const existingItems = await this.find(ids);
    if (!_.isEmpty(items)) {
      for (let index in items) {
        let item = items[index];
        let id = item.id;
        let obj = _.find(existingItems, ["id", id]);
        if (_.isEmpty(obj)) {
          insertBucket.push(item);
        } else {
          updateBucket.push(item);
        }
      }
    }

    let insertCount = 0,
      updateCount = 0;

    if (!_.isEmpty(insertBucket)) {
      let result = await this.insertMany(insertBucket);
      insertCount = result.rowsAffected;
    }

    if (!_.isEmpty(updateBucket)) {
      const whereQueryCallback = (item) => {
        let whereQuery = "WHERE id=?",
          values = item.id;
        return { whereQuery, values };
      };
      let results = await this.updateMany(updateBucket, whereQueryCallback);
      updateCount = results.updatedCount;
    }
    return { insertCount, updateCount };
  }

  updateMany(items, whereQueryCallback) {
    let promises = [];
    if (!_.isEmpty(items)) {
      for (let index in items) {
        let item = items[index];
        promises.push(this.updateItem(item, whereQueryCallback));
      }
    }
    return Promise.all(promises).then((results) => {
      return {
        results,
        updatedCount: _.sumBy(results, (result) => {
          let len = Number(_.get(result, "rowsAffected", 0));
          if (isNaN(len)) {
            len = 0;
          }
          return len;
        }),
      };
    });
  }

  insertMany(items) {
    const isWeb = Platform.OS == 'web'
    const fillables = this.fillables;
    const datatypes = this.datatypes;
    let columnstring = _.join(fillables, ", ");

    let chunkedItems = _.chunk(items, chuckSize);
    let queries = [];

    if (chunkedItems) {
      for (let chunkIndex in chunkedItems) {
        let chunkItems = chunkedItems[chunkIndex];
        let ctx = `INSERT INTO ${this.tableName} (${columnstring}) VALUES`;
        let values = [];

        if (!_.isEmpty(chunkItems)) {
          for (let index in chunkItems) {
            let item = chunkItems[index];
            let pickedColumns = [];
            for (let index in fillables) {
              const key = fillables[index];
              const datatype =
                datatypes && datatypes[key] ? datatypes[key] : null;
              let value = item[key];
              if (datatype === "JSON") {
                value = JSON.stringify(value);
              } else if (datatype === "BOOL") {
                value = _.includes([true, "true", 1, "1"], value)
                  ? "true"
                  : "false";
              } else if (datatype === "INTEGER") {
                let val = parseInt(value);
                value = val;
              } else if (datatype === "NUMBER") {
                let val = Number(value);
                if (!isNaN(val)) {
                  value = val;
                }
              }
              pickedColumns.push(value);
            }
            values = [...values, ...pickedColumns];
          }

          let columnPlaceHolder = `(${_.join(
            _.fill(Array(fillables.length), "?")
          )})`;
          let columnPlaceHolders = _.join(
            _.fill(Array(chunkItems.length), columnPlaceHolder),
            ", "
          );
          let query = `${ctx} ${columnPlaceHolders}`;
          queries.push({
            sql: query,
            args: values,
          });
        }
      }
    }

    return new Promise((resolve, reject) => {
      console.log(
        "DBBaseManager->insertMany->queries",
        _.truncate(JSON.stringify(queries), {
          length: 150,
          separator: " ",
        })
      );
      console.log("DBBaseManager->insertMany->queries length", [
        queries?.length,
      ]);
      isWeb ? resolve([])
        : this.databaseConnection.exec(queries, false, (error, results) => {
          if (error) {
            error.results = results;
            error.msg = `Failed to insert data: \n ${JSON.stringify({
              results,
              query,
            })}`;
            reject(error);
          }
          const lastResult = _.last(results);
          const rowsAffected = _.get(lastResult, "insertId", 0);
          resolve({
            rowsAffected,
          });
        });
    });
  }

  mockAsSearchServerAPI(items, count) {
    let response = {
      data: {
        hits: {
          total: {
            value: count,
          },
          hits: _.map(items, (item) => {
            return {
              _source: {
                ...item,
              },
            };
          }),
        },
      },
    };

    console.log("MOCKED");
    console.log(response?.data?.hits?.total?.value);
    return response;
  }

  mockAsAPI(items) {
    let response = {
      data: items,
    };
    return response;
  }

  asyncExec(queries) {
    const isWeb = Platform.OS == 'web'
    if (!Array.isArray(queries)) {
      queries = [queries];
    }

    return new Promise((resolve, reject) => {
      isWeb ?
        resolve({})
        : this.databaseConnection.exec(queries, false, (error, results) => {
          console.log("queries results\n", [
            queries,
            error,
            _.first(_.keys(results)),
          ]);
          let errorObject = error;

          if (!errorObject) {
            errorObject = _.find(results, (e) => {
              if (!_.isEmpty(e?.error)) {
                return true;
              }
            });
          }

          if (errorObject) {
            console.error("asyncExec.exec error", errorObject);
            reject(errorObject);
          }
          const rowsAffected = _.get(_.last(results), "rowsAffected");
          const insertId = _.get(_.last(results), "insertId");
          let rows = _.flatMap(results, (e) => e.rows);
          rows = _.compact(rows);
          rows = _.map(rows, (e) => this.applyDatatypeToObject(e));
          resolve({ insertId, rowsAffected, rows });
        });
    });
  }

  remove(whereQuery, args) {
    let sql = `DELETE FROM ${this.tableName} WHERE ${whereQuery}`;
    return this.asyncExec({ sql, args });
  }

  generateId() {
    let d = Math.floor(Date.now());
    return d.toString();
  }

  pickOnlyFillables(item, excludeKeys = []) {
    const fillables = this.fillables;
    const datatypes = this.datatypes;
    for (let index in fillables) {
      const key = fillables[index];
      const datatype = datatypes[key];
      let value = item[key];
      if (datatype === "JSON") {
        value = _.isString(value) ? JSON.parse(value) : null;
      } else if (datatype === "BOOL") {
        if (_.includes(["1", 1, true, "true"])) {
          value = "true";
        } else if (_.includes(["0", 0, false, "false"])) {
          value = "false";
        }
      } else if (datatype === "INTEGER") {
        let val = parseInt(value);
        value = val;
      } else if (datatype === "NUMBER") {
        let val = Number(value);
        if (!isNaN(val)) {
          value = val;
        }
      }
      if (!_.includes(excludeKeys, key)) {
        item[key] = value;
      }
    }
    return item;
  }

  truncateAll() {
    let sql = `DELETE FROM ${this.tableName};`,
      args = [];
    return this.asyncExec({ sql, args });
  }
}
