import utils from "../utils";
import moment from "moment";
import _ from "lodash";

export default class LineItemModel {
  constructor({ lineItem }) {
    this.lineItem = lineItem;
  }

  get id() {
    return this.lineItem.id;
  }

  get picURL() {
    return this.lineItem.pic_url;
  }

  get name() {
    return this.lineItem.name;
  }

  get quantity() {
    return this.lineItem.quantity;
  }

  get price() {
    return this.lineItem.total_price;
  }

  get rate() {
    return Number(this.lineItem.rate);
  }

  get price1() {
    return _.multiply(Number(this.quantity), this.rate);
  }

  get formattedPrice() {
    return utils.formattedPrice(this.price ?? 0);
  }

  get formattedPrice1() {
    return utils.formattedPrice(this.price1 ?? 0);
  }

  get formattedDeliveryTime() {
    let time = this.lineItem?.delivery_time;
    return time ? moment(time).format("Do MMM YYYY hh:mm A") : "";
  }

  get offerText() {
    return this.lineItem.other_data.offer_text;
  }
  get promotionDiscount() {
    return this.lineItem.promotion_discount_amount;
  }
  get priceRuleId() {
    return _.get(this.lineItem, "promotion_hash.price_rule_id");
  }
  get operatorId() {
    return _.get(this.lineItem, "other_data.user_data.operator_id", null);
  }

  get companyName() {
    return _.get(this.lineItem, "other_data.company_name", null);
  }

  get molecule() {
    return _.get(this.lineItem, "other_data.molecule", null);
  }
}
