import React, { useContext, useState, useEffect } from "react";
import { View, Dimensions, Pressable } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import svgs from "@presto-assets/svgs";
import { DefaultSvgs } from "presto-react-components";
import PrestoIcon from "@presto-components/PrestoIcon";
import { alertBox } from "@presto-common/Alert";
import Utils from "@presto-services/common/Utils";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";

export default function UserForm({
  initialUser,
  setCurrentSection,
  setParentLoading,
  permissionChoices,
}) {
  const { theme } = useContext(ThemeContext);
  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;

  const [form, setForm] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    permissions: [],
  });

  useEffect(() => {
    if (!_.isEmpty(initialUser)) {
      let query = {
        name: initialUser.name,
        phoneNumber: initialUser.phone_number,
        email: initialUser.email,
        permissions: _.get(initialUser, "other_data.permissions", {}),
      };
      setForm(query);
    }
  }, [initialUser]);

  const onPressPermissionChoice = (permissionChoice) => {
    const exists = isPermissionChoiceSelected(permissionChoice);
    let perms = _.cloneDeep(form.permissions);

    if (_.isEmpty(perms)) {
      perms = {};
    }

    if (exists) {
      perms[permissionChoice.id] = false;
    } else {
      perms[permissionChoice.id] = true;
    }
    setForm({ ...form, permissions: perms });
  };

  const isPermissionChoiceSelected = (permissionChoice) =>
    _.get(form, `permissions.${permissionChoice.id}`) === true;

  const validateSubmit = () => {
    let isValid = true,
      error = null;

    if (_.isEmpty(form.name)) {
      error = I18n.t("error_messages.common.please_enter_name");
    } else if (_.isEmpty(form.phoneNumber)) {
      error = I18n.t("error_messages.common.please_enter_phone_no");
    } else if (!Utils.isValidPhoneNumber(form.phoneNumber)) {
      error = I18n.t("error_messages.common.please_enter_valid_phone_number");
    } else if (_.isEmpty(form.email)) {
      error = I18n.t("error_messages.common.please_enter_email");
    } else if (!Utils.isValidEmail(form.email)) {
      error = I18n.t("error_messages.common.please_enter_valid_email");
    } else if (_.isEmpty(initialUser) && _.isEmpty(form.password)) {
      error = I18n.t("error_messages.common.please_enter_password");
    }

    if (error) {
      isValid = false;
    }

    return { isValid, error };
  };

  const onSubmit = () => {
    const { isValid, error } = validateSubmit();
    if (!isValid) {
      return alertBox("", error);
    }

    let method = _.isEmpty(initialUser)
      ? EmployeeManager.createEmployee
      : EmployeeManager.updateEmployee;

    let params = {
      employee: {
        name: form.name,
        email: form.email,
        password: form.password,
        role: "CC_AGENT",
        Reference_id: "",
        phone_number: form.phoneNumber,
        pic_url: null,
        about: null,
        other_data: {
          ...initialUser?.other_data,
          permissions: form.permissions,
        },
      },
    };

    if (!_.isEmpty(initialUser)) {
      params.employee_id = initialUser.id;
      params.employee.address = initialUser.address;
      delete params.employee.password;
    }

    const onSuccess = (response) => {
      setParentLoading(false);
      setCurrentSection("LISTING");
    };
    const onError = (error) => {
      setParentLoading(false);
      alertBox("", error.message);
      console.log("RoleManagement->userform->onSubmit->error", error);
    };

    setParentLoading(true);
    method(params, onSuccess, onError);
  };

  return (
    <View style={{ backgroundColor: theme.screenBackgroundColor }}>
      <View
        style={{
          backgroundColor: theme.createItemBgColor,
          borderRadius: 12,
          margin: theme.primaryPadding,
          padding: 10,
        }}
      >
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <View style={{ width: "50%" }}>
            <VerticalSpacing size={20} />
            <PrestoText
              color={theme.white}
              fontStyle={"400.semibold"}
              size={16}
            >
              {I18n.t("screen_messages.stock.name")}
            </PrestoText>
            <PrestoTextInput
              style={{}}
              width={"100%"}
              theme={theme}
              placeholder={I18n.t("screen_messages.add_name")}
              value={form.name}
              keyboardType={"default"}
              onChange={({ nativeEvent: { text } }) =>
                setForm({ ...form, name: text })
              }
              backgroundColor={theme.white}
              borderWidth={2}
            />
          </View>
          <View style={{ width: "50%", paddingLeft: 20 }}>
            <VerticalSpacing size={20} />
            <View>
              <PrestoText
                color={theme.white}
                fontStyle={"400.semibold"}
                size={16}
              >
                {I18n.t("screen_messages.common.phone_number")}
              </PrestoText>
              <PrestoTextInput
                width={300}
                theme={theme}
                placeholder={I18n.t(
                  "error_messages.common.please_enter_phone_no"
                )}
                value={form.phoneNumber}
                keyboardType={"numeric"}
                onChange={({ nativeEvent: { text } }) =>
                  setForm({ ...form, phoneNumber: text })
                }
                backgroundColor={theme.white}
                borderWidth={2}
              />
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
          }}
        >
          <View style={{ width: "50%" }}>
            <VerticalSpacing size={20} />
            <PrestoText
              color={theme.white}
              fontStyle={"400.semibold"}
              size={16}
            >
              {I18n.t("screen_messages.login.email")}
            </PrestoText>
            <PrestoTextInput
              style={{}}
              width={"100%"}
              theme={theme}
              placeholder={I18n.t("error_messages.common.please_enter_email")}
              value={form.email}
              keyboardType={"default"}
              onChange={({ nativeEvent: { text } }) =>
                setForm({ ...form, email: text })
              }
              backgroundColor={theme.white}
              borderWidth={2}
            />
          </View>

          {_.isEmpty(initialUser) ? (
            <View style={{ width: "50%", paddingLeft: 20 }}>
              <VerticalSpacing size={20} />
              <View>
                <PrestoText
                  color={theme.white}
                  fontStyle={"400.semibold"}
                  size={16}
                >
                  {I18n.t("screen_messages.login.password")}
                </PrestoText>
                <PrestoTextInput
                  width={300}
                  theme={theme}
                  placeholder={I18n.t(
                    "error_messages.common.please_enter_password"
                  )}
                  value={form.password}
                  keyboardType={"numeric"}
                  onChange={({ nativeEvent: { text } }) =>
                    setForm({ ...form, password: text })
                  }
                  backgroundColor={theme.white}
                  borderWidth={2}
                />
              </View>
            </View>
          ) : null}
        </View>

        <View style={{ paddingTop: theme.primaryPadding }}>
          <PrestoText color={theme.white} fontStyle={"400.semibold"} size={16}>
            {I18n.t("screen_messages.policy_permissions.title2")}
          </PrestoText>
        </View>

        <View
          style={{
            paddingTop: theme.primaryPadding,
            height: 70,
            width: "100%",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            {_.map(permissionChoices, (permissionChoice) => {
              return (
                <Pressable
                  onPress={() => onPressPermissionChoice(permissionChoice)}
                  key={permissionChoice.id}
                  style={{
                    paddingRight: theme.primaryPadding,
                    ...(isPermissionChoiceSelected(permissionChoice) && {}),
                  }}
                >
                  <PrestoIcon
                    icon={permissionChoice.renderIcon({
                      color: isPermissionChoiceSelected(permissionChoice)
                        ? theme.primary
                        : theme.white,
                    })}
                  />
                </Pressable>
              );
            })}
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
          }}
        >
          <PrestoSolidButton
            bgColor={theme.primary}
            title={
              initialUser
                ? I18n.t("screen_messages.common.update")
                : I18n.t("screen_messages.create_str")
            }
            height={50}
            titleSize={16}
            width={100}
            extraStyle={{
              marginTop: theme.primaryPadding,
              alignSelf: "flex-start",
            }}
            titleColor={theme.white}
            borderColor={theme.primary}
            borderWidth={1}
            borderRadius={7}
            onPress={onSubmit}
          />
        </View>
      </View>
    </View>
  );
}
