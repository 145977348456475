import DBBaseManager from "@presto-db-managers/DBBaseManager";
import config from "@presto-common/config";
import _ from "lodash";
import PosManager from "@presto-services/features/pos/PosManager";
import ClientManager from "@presto-services/features/client/ClientManager";
import asyncify from "@presto-common/Asyncify";
const AsyncPosManager = asyncify(PosManager);
const AsyncClientManager = asyncify(ClientManager);
import { Platform } from "react-native";
export default class DBUserManager extends DBBaseManager {
  constructor() {
    super();
    this.tableName = "users";
    this.fillables = [
      "id",
      "name",
      "email",
      "phone_number",
      "created_at",
      "birthday",
      "gender",
      "address",
      "merchant_id",
      "_sync_status", // choices are: pending, synced
      "_error",
    ];
    this.datatypes = {
      _error: "JSON",
    };
  }

  searchUser(params) {
    const isWeb = Platform.OS == 'web'
    let parameter = params.parameter;
    let value = params.value;
    let sql = `SELECT * FROM ${this.tableName}`,
      whereBucket = [],
      args = [];

    if (parameter === "name") {
      whereBucket.push(`name LIKE ?`);
      args.push(`%${params.value}%`);
    }

    if (parameter === "email") {
      whereBucket.push(`email LIKE ?`);
      args.push(`%${params.value}%`);
    }

    if (parameter === "phone_number") {
      whereBucket.push(`phone_number LIKE ?`);
      args.push(`%${params.value}%`);
    }

    if (parameter === "_sync_status") {
      whereBucket.push(`_sync_status=?`);
      args.push(params.value);
    }

    if (!_.isEmpty(whereBucket)) {
      let joinedWhere = _.join(whereBucket, " AND ");
      sql = `${sql} WHERE ${joinedWhere};`;
    }

    if (isWeb) return {}
    return this.asyncExec({ sql, args }).then(({ rows }) => {
      return rows;
    });
  }

  createClient(params) {
    const isWeb = Platform.OS == 'web'
    params = { _sync_status: "pending", id: this.generateId(), ...params };

    if (!params?.merchant_id) {
      params.merchant_id = config.server_config.merchant_id;
    }

    let data = this.pickOnlyFillables(params);
    //@TODO for Debugging
    console.log("DBUserManager->createClient->data", JSON.stringify(data));
    return this.find(data.phone_number, "phone_number").then((rows) => {
      console.log("DBUserManager->createClient->rows", [rows?.length]);
      if (_.isEmpty(rows)) {
        let fields = _.join(_.keys(data), ",");
        let args = _.values(data);
        let values = _.join(_.fill(Array(args.length), "?"), ", ");
        let sql = `INSERT INTO ${this.tableName} (${fields}) VALUES (${values});`;
        console.log("DBUserManager->createClient->insert query", sql);
        console.log(
          "DBUserManager->createClient->insert args",
          JSON.stringify(args)
        );
        if (isWeb) return {}
        return this.asyncExec({ sql, args }).then(({ insertId }) => {
          return this.find(insertId, "_id").then((rows) => _.first(rows));
        });
      } else {
        const message = `User with phone number ${data.phone_number} already exists`;
        console.log("DBUserManager->createClient->error", [message]);
        const error = new Error(message);
        error.data = {
          message: message,
        };
        throw error;
      }
    });
  }

  updateClient(params) {
    let id = params.id;
    let data = this.pickOnlyFillables(params, ["id"]);
    return this.find(data.id).then((rows) => {
      let row = _.first(rows);
      if (_.isEmpty(row)) {
        const error = new Error("Trying to update invalid user");
        throw error;
      }
      const whereQueryCallback = (item) => {
        let whereQuery = "WHERE id=?",
          values = item.id;
        return { whereQuery, values };
      };
      return this.updateItem(params, whereQueryCallback).then(
        ({ rowsAffected }) => {
          return this.find(id);
        }
      );
    });
  }

  static searchRemoteClient({ parameter, value }) {
    return AsyncPosManager.searchUser({ parameter, value }).then(
      ([error, response]) => {
        if (error) {
          return [error, null];
        }
        const users = response?.data?.users;
        return _.first(users);
      }
    );
  }

  static getRemoteClient({ merchant_id, user_id }) {
    return AsyncClientManager.getClient({ merchant_id, user_id }).then(
      ([error, response]) => {
        if (error) {
          return null;
        }
        return response.data;
      }
    );
  }

  getPendingUsers() {
    const instance = new DBUserManager();
    return instance.searchUser({
      parameter: "_sync_status",
      value: "pending",
    });
  }

  static syncToRemote() {
    const isWeb = Platform.OS == 'web'
    const instance = new DBUserManager();
    if (isWeb) return []
    return instance.getPendingUsers().then(async (rows) => {
      if (rows) {
        for (let index in rows) {
          const row = rows[index];
          const params = {
            parameter: "phone_number",
            value: row.phone_number,
          };
          const customer = await DBUserManager.searchRemoteClient(params);
          if (_.isEmpty(customer)) {
            let param1 = {
              user: {
                name: row.name,
                phone_number: row.phone_number,
                email: row.email,
                gender: row.gender,
              },
            };

            const [error1, response1] = await AsyncClientManager.createClient(
              param1
            );

            if (!_.isEmpty(response1)) {
              const newId = response1.data.id;
              let param2 = {
                user: { ...param1.user, birthday: row.birthday, id: newId },
              };
              const [error2, response2] = await AsyncClientManager.updateClient(
                param2
              );
              if (!_.isEmpty(response2) && newId) {
                await instance.syncUserById(row._id, newId);
              }
            }
          } else {
            const newId = customer.id;
            if (newId) {
              await instance.syncUserById(row._id, newId);
            }
          }
        }
      }
    });
  }

  syncUserById(_id, newId) {
    const isWeb = Platform.OS == 'web'
    let sql = `UPDATE ${this.tableName} SET _sync_status=?, id=?  WHERE _id=?`;
    let args = ["synced", newId, _id];
    if (isWeb) return {}
    return this.asyncExec({ sql, args }).then(
      ({ rowsAffected }) => rowsAffected
    );
  }
}
