import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { Platform } from "react-native";
import _ from "lodash";

import ServerCartManager from "@presto-services/features/server_cart/ServerCartManager";
import asyncify from "../common/Asyncify";
import UserContext from "./UserContext";
import { AsyncAlert } from "../common/Alert";
import I18n from "i18n-js";
import SearchManager from "@presto-services/features/search/SearchManager";
import { useCatalog } from "@presto-stores/CatalogStore";
import CartDataSources from "@presto-datasources/CartDataSources";

const AsyncServerCartManager = asyncify(CartDataSources);
const AsyncSearchManager = asyncify(SearchManager);

const CartContext = createContext();

export function CartContextProvider(props) {
  const isWeb = Platform.OS == 'web';
  const [cart, setCart] = useState(null);
  const [multiCarts, setMultiCarts] = useState([]);
  const currentCartData = useRef({
    id: null,
  });
  const { user } = useContext(UserContext);
  const { catalog, refresh: refreshCatalog } = useCatalog();

  const setCurrentCartData = ({ id }) => {
    currentCartData.current.id = id;
  };

  const getCurrentCartId = () => {
    return currentCartData.current.id;
  };

  const addItemToCart = async (
    item_id,
    variation_id,
    quantity,
    merchant_id
  ) => {
    const [error, response] = await AsyncServerCartManager.addItem({
      item_id,
      variation_id,
      quantity,
      merchant_id,
      ...(isWeb && { id: getCurrentCartId() }),
      ...(!isWeb && { cart_id: getCurrentCartId() })
    });
    if (!error) {
      if (isWeb) {
        setMultiCarts((ps) => [...ps]?.map((ele) => ele.id == getCurrentCartId() ? response?.data : ele))
      } else {
        console.log(getCurrentCartId(), "oweqiriwekdkfadjsfjidsfnjk")
        console.log(response.data, "qowejdsjnijsdfijisdfjo")
        setCart(response.data);
      }
    }
    return [error, response];
  };

  const applyCouponToCart = async (coupon_code) => {
    const [error, response] = await AsyncServerCartManager.applyCoupon({
      coupon_code,
      cart_id: getCurrentCartId(),
    });
    if (!error) {
      console.log("Coupon apply response : ", JSON.stringify(response.data));
      setCart(response.data);
    }
    return [error, response];
  };

  const removeCouponFromCart = async (coupon_code) => {
    const [error, response] = await AsyncServerCartManager.removeCoupon({
      coupon_code,
      cart_id: getCurrentCartId(),
    });
    if (!error) {
      setCart(response.data);
    }
    return [error, response];
  };

  const checkoutCart = async (params) => {
    const [error, response] = await AsyncServerCartManager.checkout(params);
    if (!error) {
      setCart(response.data);
    }
    return [error, response];
  };

  const placeOrder = async (params) => {
    const [error, response] = await AsyncServerCartManager.placeOrder(
      params || {}
    );
    return [error, response];
  };

  const updateItemFromCart = async (line_item_id, quantity) => {
    const [error, response] = await AsyncServerCartManager.updateItem({
      line_item_id,
      quantity,
      cart_id: getCurrentCartId(),
    });
    if (!error) {
      setCart(response.data);
    }
    return [error, response];
  };

  const removeItemFromCart = async (line_item_id) => {
    const [error, response] = await AsyncServerCartManager.removeItem({
      line_item_id,
      cart_id: getCurrentCartId(),
    });
    if (!error) {
      if (isWeb) {
        setMultiCarts((ps) => [...ps]?.map((ele) => ele.id == getCurrentCartId() ? response?.data : ele))
      } else {
        setCart(response.data);
      }
    }
    return [error, response];
  };

  const clearCart = async () => {
    const [error, response] = await AsyncServerCartManager.clearCart({
      cart_id: getCurrentCartId(),
    });
    if (!error) {
      if (isWeb) {
        setMultiCarts((ps) => [...ps.filter((ele) => ele.id !== cart?.id)])
      }
      else {
        setCart(response.data);
      }
      setCurrentCartData({ id: response.data._id });
    } else {
      setCurrentCartData({ id: null });
    }
    return [error, response];
  };

  const fetchCart = async () => {
    const cart_id = getCurrentCartId();
    console.log(getCurrentCartId(), "isafiojdiofjisdjiojfio")
    if (multiCarts?.length) {
      console.log(multiCarts?.find((ele) => ele.id == getCurrentCartId()), "dasjflkajsdklfjl")
      return ["", multiCarts?.find((ele) => ele.id == getCurrentCartId())]
    }
    // need to set this condition for web
    const [error, response] = await AsyncServerCartManager.fetchCart({
      cart_id: getCurrentCartId(),
    });

    const newCartId = _.get(response, "data.id");

    console.log("Fetching cart : ", error, response);
    console.log("getCurrentCartId() id", [getCurrentCartId()]);
    return [error, response?.data];
  };

  const createCart = async (mid, oid, extraParams = {}) => {
    const [error, response] = await AsyncServerCartManager.createCart({
      merchant_id: mid,
      outlet_id: oid,
      cart_id: getCurrentCartId(),
      ...extraParams,
    });
    if (!error && isWeb) {
      setMultiCarts((ps) => [...ps, response.data])
    }

    // console.log("Creating cart : ", error, response);
    return [error, response?.data];
  };

  const refreshCart = async () => {
    if (isWeb) {
      // const [error, cart] = await fetchCart();
      const error = false;
      const cart = multiCarts?.find((ele) => ele.id == getCurrentCartId())
      if (error) {
        setCart(null);
      } else {
        setCart(cart);
      }
    } else {
      const [error, cart] = await fetchCart();
      if (error) {
        setCart(null);
      } else {
        setCart(cart);
      }
    }
  };

  const getItemCount = () => {
    if (!cart) return 0;
    return cart.items?.length ?? 0;
  };

  const findItemInCart = (cart, itemId, variationId) => {
    if (variationId) {
      return _.first(
        _.filter(
          cart.items,
          (li) => li.item_id === itemId && li.variation?.id == variationId
        )
      );
    } else {
      return _.first(_.filter(cart.items, (li) => li.item_id === itemId));
    }
  };

  const getCountOfItemInCart = (itemId, variationId) => {
    if (!cart) return 0;
    const itemInCart = findItemInCart(cart, itemId, variationId);
    return itemInCart?.quantity ?? 0;
  };

  const reOrder = async (order, merchantId = null) => {
    let cartCopy = cart;
    if (!user) {
      return ["noUser", null];
    }
    if (user && user.catalog_type) {
      if (!catalog) {
        refreshCatalog();
        return ["refresh_catalog", null];
      }
      if (user.catalog_type !== catalog.filter) {
        refreshCatalog();
        console.log(user.catalog_type, catalog);
        return ["refresh_catalog", null];
      }
    }
    const [error, cartResponse] = await fetchCart();
    if (!cartResponse) {
      const [error, cartResponse] = await createCart();
      if (error) {
        return ["cart_error", error];
      } else {
        cartCopy = cartResponse;
      }
    } else {
      const [error, _] = await clearCart();
      if (error) {
        return ["cart_clear_error", error];
      }
      cartCopy = cartResponse;
    }
    let errorInReorder = false;
    for (const lineItem of order.items) {
      const [
        error,
        searchResponse,
      ] = await AsyncSearchManager.getReferenceIdBasedProducts({
        reference_id: lineItem.reference_id,
      });
      const products = searchResponse.hits?.hits;
      const searchProduct = _.find(products, (product) => {
        return product._source?.id === lineItem.item_id;
      })?._source;
      if (error || !searchProduct) {
        errorInReorder = true;
        continue;
      }

      if (user.catalog_type && searchProduct.catalog_id !== catalog.id) {
        errorInReorder = true;
        continue;
      }

      const [err, response] = await updateItemInCart(
        lineItem.item_id,
        lineItem.variation?.id,
        lineItem.quantity,
        merchantId
      );
      if (err) {
        errorInReorder = true;
      }
    }
    if (errorInReorder) {
      return ["error_adding_items", errorInReorder];
    } else {
      return ["success", null];
    }
  };

  const clearAndUpdateCart = async (
    itemId,
    variationId,
    count,
    merchantId = null,
    outeltId = null
  ) => {
    let cartCopy = cart;
    const [error, cartResponse] = await createCart(merchantId, outeltId);
    if (error) {
      return [error, false];
    } else {
      cartCopy = cartResponse;
    }

    const itemInCart = findItemInCart(cartCopy, itemId, variationId);
    if (itemInCart) {
      if (count == 0) {
        const [error, response] = await removeItemFromCart(itemInCart.id);
        console.log("updateItemFromCart response : ", error, response);
        if (error) {
          return [error, false];
        } else {
          return [null, true];
        }
      } else {
        const [error, response] = await updateItemFromCart(
          itemInCart.id,
          count
        );
        console.log("updateItemFromCart response : ", error, response);
        if (error) {
          return [error, false];
        } else {
          return [null, true];
        }
      }
    } else {
      console.log("addItemToCart request : ", itemId, variationId, count);
      const [error, response] = await addItemToCart(
        itemId,
        variationId,
        count,
        merchantId
      );
      console.log("addItemToCart response : ", error, response);
      if (error) {
        return [error, false];
      } else {
        return [null, true];
      }
    }
  };

  const updateItemInCart = async (
    itemId,
    variationId,
    count,
    merchantId = null,
    outeltId = null
  ) => {
    // console.log("Update Item in cart : ", itemId, variationId, count);
    let cartCopy = cart;
    const [error, cartResponse] = await fetchCart();
    let isDifferentVendor =
      cartResponse && cartResponse.merchant_id != merchantId ? true : false;
    if (merchantId && isDifferentVendor && getItemCount() > 0) {
      const userResp = await AsyncAlert(
        I18n.t("screen_messages.common.confirm_text"),
        I18n.t("screen_messages.cart.clear_cart"),
        I18n.t("screen_messages.common.confirm_text"),
        I18n.t("screen_messages.common.cancel_text"),
        false
      );
      console.log("userResps", userResp);
      if (userResp === "yes") {
        return clearAndUpdateCart(
          itemId,
          variationId,
          count,
          merchantId,
          outeltId
        );
      } else {
        return [null, true];
      }
    } else if (isDifferentVendor && getItemCount() == 0) {
      return clearAndUpdateCart(
        itemId,
        variationId,
        count,
        merchantId,
        outeltId
      );
    } else {
      if (
        !cartResponse ||
        (cartResponse && cartResponse.merchant_id != merchantId)
      ) {
        const [error, cartResponse] = await createCart(merchantId, outeltId);
        if (error) {
          return [error, false];
        } else {
          cartCopy = cartResponse;
        }
      } else {
        cartCopy = cartResponse;
      }

      const itemInCart = findItemInCart(cartCopy, itemId, variationId);
      if (itemInCart) {
        if (count == 0) {
          const [error, response] = await removeItemFromCart(itemInCart.id);
          console.log("removeItemFromCart response : ", error, response);
          if (error) {
            return [error, false];
          } else {
            return [null, true];
          }
        } else {
          const [error, response] = await updateItemFromCart(
            itemInCart.id,
            count
          );
          console.log("updateItemFromCart response : ", error, response);
          if (error) {
            return [error, false];
          } else {
            return [null, true];
          }
        }
      } else {
        console.log("addItemToCart request : ", itemId, variationId, count);
        const [error, response] = await addItemToCart(
          itemId,
          variationId,
          count
        );

        console.log("addItemToCart response : ", error, response);
        if (error) {
          return [error, false];
        } else {
          return [null, true];
        }
      }
    }
  };

  const getTotalPrice = () => {
    if (!cart) return 0;
    // console.log("Cart : ", cart);
    return cart.total_price;
  };

  const getSubTotalPrice = () => {
    if (!cart) return 0;
    return cart.original_gross_amount || cart.sub_total_price;
  };

  const getTotalTax = () => {
    if (!cart) return 0;
    // console.log("Cart : ", cart);
    return cart.total_taxes;
  };

  const getTotalDiscountPrice = () => {
    if (!cart) return 0;
    // console.log("Cart : ", cart);
    return cart.total_discounts;
  };

  const validateCart = async () => {
    const [error, response] = await AsyncServerCartManager.validateCart({});
    if (!error) {
      setCart(response.data);
    }
    return [error, response];
  };

  const fetchCarts = async () => {
    const [error, response] = await AsyncServerCartManager.fetchCarts({});

    if (error) {
      throw error;
    }

    return response.data;
  };

  useEffect(() => {
    if (user) {
      console.log("refreshing cart");
      refreshCart();
    }
  }, [user]);

  return (
    <CartContext.Provider
      value={{
        cart,
        clearCart,
        updateItemInCart,
        placeOrder,
        checkoutCart,
        removeItemFromCart,
        applyCouponToCart,
        removeCouponFromCart,
        getItemCount,
        getCountOfItemInCart,
        reOrder,
        refreshCart,
        getTotalPrice,
        getTotalTax,
        getSubTotalPrice,
        getTotalDiscountPrice,
        validateCart,
        fetchCart,
        addItemToCart,
        updateItemFromCart,
        currentCartData,
        setCurrentCartData,
        createCart,
        fetchCarts,
        getCurrentCartId,
        multiCarts,
        setMultiCarts,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}

export default CartContext;