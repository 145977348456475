import Utils from "../common/Utils";
import AddressObject from "./AddressObject";
import ErrorConstants from "../common/ErrorConstants";
import PrestoSdk from "../global/PrestoSdk";
import UserObject from "./UserObject";
import ServiceItemObject from "./ServiceItemObject";
import $ from "../global/util";

const EmployeeObject = function (
  Utils,
  AddressObject,
  ErrorConstants,
  PrestoSdk,
  UserObject,
  ServiceItemObject
) {
  function EmployeeObject() {
    this.merchant_id = PrestoSdk.getMerchantId();

    this.id = null;
    this.name = "";
    this.email = "";
    this.password = "";
    this.role = "";
    this.Reference_id = "";
    this.phone_number = "";
    this.pic_url = "";
    this.about = "";
    this.rating = "";
    this.score = "";
    this.Current_cycle_count = "";
    this.service_ids = "";
    this.other_data = {};
    this.address = new AddressObject();
  }

  EmployeeObject.prototype = {
    validateObject: function () {
      var error = Utils.ErrorObject(ErrorConstants.ErrorCodes.SUCCESS, true);
      // if (Utils.isEmpty(this.address.toString())) {
      //   error = Utils.ErrorObject(
      //     ErrorConstants.ErrorCodes.ADDRESS_EMPTY,
      //     false
      //   );
      // }
      return error;
    },
    buildObject: function (rawEmployeeObj) {
      this.merchant_id = rawEmployeeObj.merchant_id;
      this.id = rawEmployeeObj.id;
      this.name = rawEmployeeObj.name;
      this.email = rawEmployeeObj.email;
      this.password = rawEmployeeObj.password;
      this.role = rawEmployeeObj.role;
      this.Reference_id = rawEmployeeObj.Reference_id;
      this.phone_number = rawEmployeeObj.phone_number;
      this.pic_url = rawEmployeeObj.pic_url;
      this.about = rawEmployeeObj.about;
      this.rating = rawEmployeeObj.rating;
      this.score = rawEmployeeObj.score;
      this.Current_cycle_count = rawEmployeeObj.Current_cycle_count;
      this.service_ids = rawEmployeeObj.service_ids;
      this.other_data = rawEmployeeObj.other_data;
      this.address = rawEmployeeObj.address;
      if (rawEmployeeObj.address) {
        let addressObj = new AddressObject();
        addressObj.buildObject(rawEmployeeObj.address);
        this.address = addressObj;
      }
    },
  };

  return EmployeeObject;
};

export default EmployeeObject(
  Utils,
  AddressObject,
  ErrorConstants,
  PrestoSdk,
  UserObject,
  ServiceItemObject
);
