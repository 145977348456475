import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import EmployeeResource from "./EmployeeResource";
import PrestoSdk from "../../global/PrestoSdk";
import EmployeeObject from "../../data_models/EmployeeObject";
import AddressObject from "../../data_models/AddressObject";

const EmployeeManager = function (
  Utils,
  ErrorConstants,
  EmployeeResource,
  PrestoSdk,
  EmployeeObject
) {
  function getAllEmployees(params, successCallback, errorCallback) {
    EmployeeResource.getAllEmployees(params).then(
      function (response) {
        // console.log("allEmployeesResponse json: ", response);
        let list = [];
        if (response.data.length) {
          for (let index in response.data) {
            // var employeeObj = new EmployeeObject();
            // employeeObj.buildObject(response.data[index]);
            list.push(response.data[index]);
          }
          response.data = list;
        }
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAdminEmployees(params, successCallback, errorCallback) {
    EmployeeResource.getAdminEmployees(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createAdminManager(params, successCallback, errorCallback) {
    EmployeeResource.createAdminManager(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateAdminManager(params, successCallback, errorCallback) {
    EmployeeResource.updateAdminManager(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createEmployee(employee, successCallback, errorCallback) {
    if (!(employee instanceof EmployeeObject)) {
      var employeeObj = new EmployeeObject();
      employeeObj.buildObject(employee);
      employee = employeeObj;
    }
    var validationResponse = employee.validateObject();
    // console.log("employee: ", employee);
    if (validationResponse.success) {
      var params = {};
      params.merchant_id = PrestoSdk.getMerchantId();
      params.app_id = PrestoSdk.getAppId();
      params.employee = employee;
      EmployeeResource.createEmployee(params).then(
        function (response) {
          response.message = Utils.getSuccessMessage("employee_created");
          successCallback(response);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    } else {
      validationResponse = Utils.updateErrorObject(validationResponse);
      errorCallback(validationResponse);
    }
  }

  function createEmployeeAddress(params, successCallback, errorCallback) {
    let address = null;
    if (!(params?.address instanceof AddressObject)) {
      var addressObj = new AddressObject();
      addressObj.buildObject(params.address);
      address = addressObj;
    }

    params.merchant_id = PrestoSdk.getMerchantId();
    params.app_id = PrestoSdk.getAppId();
    params.address = address;
    EmployeeResource.createEmployeeAddress(params).then(
      function (response) {
        // response.message = Utils.getSuccessMessage("employee_created");
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateEmployee(params, successCallback, errorCallback) {
    let employee = null;
    if (!(params.employee instanceof EmployeeObject)) {
      var employeeObj = new EmployeeObject();
      employeeObj.buildObject(params.employee);
      employee = employeeObj;
    }
    var validationResponse = employee.validateObject();
    if (validationResponse.success) {
      params.merchant_id = PrestoSdk.getMerchantId();
      params.app_id = PrestoSdk.getAppId();
      params.employee = employee;
      EmployeeResource.updateEmployee(params).then(
        function (response) {
          response.message = Utils.getSuccessMessage("employee_updated");
          successCallback(response);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    } else {
      validationResponse = Utils.updateErrorObject(validationResponse);
      errorCallback(validationResponse);
    }
  }

  function showEmployee(params, successCallback, errorCallback) {
    params.merchant_id = PrestoSdk.getMerchantId();
    params.app_id = PrestoSdk.getAppId();
    EmployeeResource.showEmployee(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function showEmployeeAddress(params, successCallback, errorCallback) {
    params.merchant_id = PrestoSdk.getMerchantId();
    params.app_id = PrestoSdk.getAppId();
    EmployeeResource.showEmployeeAddress(params).then(
      function (response) {
        // response.message = Utils.getSuccessMessage("employee_created");
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateEmployeeAddress(params, successCallback, errorCallback) {
    let address = null;
    if (!(params?.address instanceof AddressObject)) {
      var addressObj = new AddressObject();
      addressObj.buildObject(params.address);
      address = addressObj;
    }

    params.merchant_id = PrestoSdk.getMerchantId();
    params.app_id = PrestoSdk.getAppId();
    params.address = address;
    EmployeeResource.updateEmployeeAddress(params).then(
      function (response) {
        response.message = Utils.getSuccessMessage("employee_address_updated");
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeEmployee(params, successCallback, errorCallback) {
    params.merchant_id = PrestoSdk.getMerchantId();
    params.app_id = PrestoSdk.getAppId();
    EmployeeResource.removeEmployee(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateEmployeeAvailabilityStatus(
    params,
    successCallback,
    errorCallback
  ) {
    let query = {
      merchant_id: PrestoSdk.getMerchantId(),
      app_id: PrestoSdk.getAppId(),
      employee_id: params.employee_id, // values : true or false only
      active: params.active, // values : true or false only
    };

    EmployeeResource.updateEmployeeAvailabilityStatus(query).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function operatorsOccupancyByDate(params, successCallback, errorCallback) {
    EmployeeResource.operatorsOccupancyByDate(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function showOperationalTiming(params, successCallback, errorCallback) {
    EmployeeResource.showOperationalTiming(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createOperationalTiming(params, successCallback, errorCallback) {
    EmployeeResource.createOperationalTiming(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateOperationalTiming(params, successCallback, errorCallback) {
    EmployeeResource.updateOperationalTiming(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function showNonWorkingSlots(params, successCallback, errorCallback) {
    EmployeeResource.showNonWorkingSlots(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createNonWorkingSlots(params, successCallback, errorCallback) {
    EmployeeResource.createNonWorkingSlots(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateNonWorkingSlots(params, successCallback, errorCallback) {
    EmployeeResource.updateNonWorkingSlots(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeNonWorkingSlots(params, successCallback, errorCallback) {
    EmployeeResource.removeNonWorkingSlots(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function applyCoupon(params, successCallback, errorCallback) {
    EmployeeResource.applyCoupon(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeCoupon(params, successCallback, errorCallback) {
    EmployeeResource.removeCoupon(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function generatorLoginTokenForOperator(
    params,
    successCallback,
    errorCallback
  ) {
    EmployeeResource.generatorLoginTokenForOperator(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function loginEmployeeWithToken(params, successCallback, errorCallback) {
    EmployeeResource.loginEmployeeWithToken(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function operatorLeaves(params, successCallback, errorCallback) {
    EmployeeResource.operatorLeaves(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOrders(params, successCallback, errorCallback) {
    EmployeeResource.getOrders(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getV1Orders(params, successCallback, errorCallback) {
    EmployeeResource.getV1Orders(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOrderReturnReasons(params, successCallback, errorCallback) {
    const reasonMockData = [
      {
        id: "638873e85fc4e76be33e0e73",
        reason: "Not satisfied with quality",
      },
      {
        id: "638873fa5fc4e76be33e0e74",
        reason: "No demand for this",
      },
    ];

    EmployeeResource.getOrderReturnReasons(params).then(
      function (response) {
        if (response.data.length === 0) {
          response.data = reasonMockData;
        }
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
        successCallback({
          data: reasonMockData,
        });
      }
    );
  }

  function createOrderItemReturn(params, successCallback, errorCallback) {
    EmployeeResource.createOrderItemReturn(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOrderItemReturn(params, successCallback, errorCallback) {
    EmployeeResource.getOrderItemReturn(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function associateDocumentToLineItem(params, successCallback, errorCallback) {
    EmployeeResource.associateDocumentToLineItem(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getCoupons(params, successCallback, errorCallback) {
    EmployeeResource.getCoupons(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function deleteEmployee(params, successCallback, errorCallback) {
    EmployeeResource.deleteEmployee(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getItemPromotions(params, successCallback, errorCallback) {
    EmployeeResource.getItemPromotions(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getCCAppointments(params, successCallback, errorCallback) {
    EmployeeResource.getCCAppointments(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAppointmentDetails(params, successCallback, errorCallback) {
    EmployeeResource.getAppointmentDetails(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateDeliveredQuantity(params, successCallback, errorCallback) {
    EmployeeResource.updateDeliveredQuantity(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getPriceRule(params, successCallback, errorCallback) {
    EmployeeResource.getPriceRule(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getTransactionHistory(params, successCallback, errorCallback) {
    EmployeeResource.getTransactionHistory(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function userWalletInfo(params, successCallback, errorCallback) {
    EmployeeResource.userWalletInfo(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAllBulkUpdateTasks(params, successCallback, errorCallback) {
    EmployeeResource.getAllBulkUpdateTasks(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getBulkUpdateTask(params, successCallback, errorCallback) {
    EmployeeResource.getBulkUpdateTask(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createBulkUpdateTask(params, successCallback, errorCallback) {
    EmployeeResource.createBulkUpdateTask(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function downloadBulkUpdateTask(params, successCallback, errorCallback) {
    EmployeeResource.downloadBulkUpdateTask(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createReport(params, successCallback, errorCallback) {
    EmployeeResource.createReport(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getReport(params, successCallback, errorCallback) {
    EmployeeResource.getReport(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateCategoryItem(params, successCallback, errorCallback) {
    EmployeeResource.updateCategoryItem(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createCategoryItem(params, successCallback, errorCallback) {
    EmployeeResource.createCategoryItem(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function completeOrderReturn(params, successCallback, errorCallback) {
    EmployeeResource.completeOrderReturn(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function acceptOrderReturn(params, successCallback, errorCallback) {
    EmployeeResource.acceptOrderReturn(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function creditUserWallet(params, successCallback, errorCallback) {
    EmployeeResource.creditUserWallet(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createPriceRule(params, successCallback, errorCallback) {
    EmployeeResource.createPriceRule(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAllPriceRules(params, successCallback, errorCallback) {
    EmployeeResource.getAllPriceRules(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function showPriceRule(params, successCallback, errorCallback) {
    EmployeeResource.showPriceRule(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updatePriceRule(params, successCallback, errorCallback) {
    EmployeeResource.updatePriceRule(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function deletePriceRule(params, successCallback, errorCallback) {
    EmployeeResource.deletePriceRule(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createItemPromotion(params, successCallback, errorCallback) {
    EmployeeResource.createItemPromotion(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAllItemPromotions(params, successCallback, errorCallback) {
    EmployeeResource.getAllItemPromotions(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function showItemPromotion(params, successCallback, errorCallback) {
    EmployeeResource.showItemPromotion(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateItemPromotion(params, successCallback, errorCallback) {
    EmployeeResource.updateItemPromotion(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function deleteItemPromotion(params, successCallback, errorCallback) {
    EmployeeResource.deleteItemPromotion(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function deleteCategoryItem(params, successCallback, errorCallback) {
    EmployeeResource.deleteCategoryItem(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function nearByOperators(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }

    EmployeeResource.nearByOperators(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function suppliers(params, successCallback, errorCallback) {
    EmployeeResource.suppliers(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function supplierPrices(params, successCallback, errorCallback) {
    EmployeeResource.supplierPrices(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function supplierPricesCreate(params, successCallback, errorCallback) {
    EmployeeResource.supplierPricesCreate(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function supplierPricesShow(params, successCallback, errorCallback) {
    EmployeeResource.supplierPricesShow(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function supplierPricesUpdate(params, successCallback, errorCallback) {
    EmployeeResource.supplierPricesUpdate(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function supplierPricesDelete(params, successCallback, errorCallback) {
    EmployeeResource.supplierPricesDelete(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function setItemPurchasePrice(params, successCallback, errorCallback) {
    EmployeeResource.setItemPurchasePrice(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createEmployee(params, successCallback, errorCallback) {
    EmployeeResource.createEmployee(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createCoupon(params, successCallback, errorCallback) {
    EmployeeResource.createCoupon(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateCoupon(params, successCallback, errorCallback) {
    EmployeeResource.updateCoupon(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeCoupon(params, successCallback, errorCallback) {
    EmployeeResource.removeCoupon(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getPriceRules(params, successCallback, errorCallback) {
    EmployeeResource.getPriceRules(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getReportV1(params, successCallback, errorCallback) {
    EmployeeResource.getReportV1(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function couponRemove(params, successCallback, errorCallback) {
    EmployeeResource.couponRemove(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function embeddedDashboardList(params, successCallback, errorCallback) {
    EmployeeResource.embeddedDashboardList(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function embeddedDashboardUrl(params, successCallback, errorCallback) {
    EmployeeResource.embeddedDashboardUrl(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function inventoryBatchList(params, successCallback, errorCallback) {
    EmployeeResource.inventoryBatchList(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function inventoryBatchCreate(params, successCallback, errorCallback) {
    EmployeeResource.inventoryBatchCreate(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function inventoryBatchShow(params, successCallback, errorCallback) {
    EmployeeResource.inventoryBatchShow(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function inventoryBatchUpdate(params, successCallback, errorCallback) {
    EmployeeResource.inventoryBatchUpdate(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function inventoryBatchDelete(params, successCallback, errorCallback) {
    EmployeeResource.inventoryBatchDelete(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function purchaseConfigurationCreate(params, successCallback, errorCallback) {
    EmployeeResource.purchaseConfigurationCreate(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function purchaseConfigurationShow(params, successCallback, errorCallback) {
    EmployeeResource.purchaseConfigurationShow(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function purchaseConfigurationUpdate(params, successCallback, errorCallback) {
    EmployeeResource.purchaseConfigurationUpdate(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function purchaseConfigurationDelete(params, successCallback, errorCallback) {
    EmployeeResource.purchaseConfigurationDelete(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function itemPurchaseConfigurationShow(
    params,
    successCallback,
    errorCallback
  ) {
    EmployeeResource.itemPurchaseConfigurationShow(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getApprovedItemPurchaseRequest(
    params,
    successCallback,
    errorCallback
  ) {
    console.log(params, "getApprovedItemPurchaseRequestD!!!!!@#$%^&*")
    EmployeeResource.getApprovedItemPurchaseRequest(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function itemPurchaseConfigurationList(
    params,
    successCallback,
    errorCallback
  ) {
    EmployeeResource.itemPurchaseConfigurationList(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function itemPurchaseRequests(params, successCallback, errorCallback) {
    EmployeeResource.itemPurchaseRequests(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function itemPurchaseRequestList(params, successCallback, errorCallback) {
    EmployeeResource.itemPurchaseRequestList(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function itemPurchaseRequestCreate(params, successCallback, errorCallback) {
    EmployeeResource.itemPurchaseRequestCreate(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function itemPurchaseRequestUpdate(params, successCallback, errorCallback) {
    EmployeeResource.itemPurchaseRequestUpdate(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateApprovedItemPurchaseRequest(params, successCallback, errorCallback) {
    EmployeeResource.updateApprovedItemPurchaseRequest(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function deleteApprovedItemPurchaseRequest(params, successCallback, errorCallback) {
    EmployeeResource.deleteApprovedItemPurchaseRequest(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function itemPurchaseRequestDelete(params, successCallback, errorCallback) {
    EmployeeResource.itemPurchaseRequestDelete(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function approveItemPurchaseRequest(params, successCallback, errorCallback) {
    EmployeeResource.approveItemPurchaseRequest(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function fetchPromotions(params, successCallback, errorCallback) {
    EmployeeResource.fetchPromotions(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    )
  }

  return {
    deleteCategoryItem: deleteCategoryItem,
    createPriceRule: createPriceRule,
    getAllPriceRules: getAllPriceRules,
    showPriceRule: showPriceRule,
    updatePriceRule: updatePriceRule,
    deletePriceRule: deletePriceRule,
    createItemPromotion: createItemPromotion,
    getAllItemPromotions: getAllItemPromotions,
    showItemPromotion: showItemPromotion,
    updateItemPromotion: updateItemPromotion,
    deleteItemPromotion: deleteItemPromotion,
    createCategoryItem: createCategoryItem,
    acceptOrderReturn: acceptOrderReturn,
    creditUserWallet: creditUserWallet,
    completeOrderReturn: completeOrderReturn,
    updateCategoryItem: updateCategoryItem,
    downloadBulkUpdateTask: downloadBulkUpdateTask,
    createBulkUpdateTask: createBulkUpdateTask,
    getBulkUpdateTask: getBulkUpdateTask,
    getAllBulkUpdateTasks: getAllBulkUpdateTasks,
    getAllEmployees: getAllEmployees,
    createEmployee: createEmployee,
    createEmployeeAddress: createEmployeeAddress,
    updateEmployee: updateEmployee,
    showEmployee: showEmployee,
    showEmployeeAddress: showEmployeeAddress,
    updateEmployeeAddress: updateEmployeeAddress,
    removeEmployee: removeEmployee,
    updateEmployeeAvailabilityStatus: updateEmployeeAvailabilityStatus,
    operatorsOccupancyByDate: operatorsOccupancyByDate,
    showOperationalTiming: showOperationalTiming,
    createOperationalTiming: createOperationalTiming,
    updateOperationalTiming: updateOperationalTiming,
    showNonWorkingSlots: showNonWorkingSlots,
    createNonWorkingSlots: createNonWorkingSlots,
    updateNonWorkingSlots: updateNonWorkingSlots,
    removeNonWorkingSlots: removeNonWorkingSlots,
    getAdminEmployees: getAdminEmployees,
    createAdminManager: createAdminManager,
    updateAdminManager: updateAdminManager,
    applyCoupon: applyCoupon,
    removeCoupon: removeCoupon,
    getCoupons: getCoupons,
    generatorLoginTokenForOperator: generatorLoginTokenForOperator,
    loginEmployeeWithToken: loginEmployeeWithToken,
    operatorLeaves: operatorLeaves,
    getOrders: getOrders,
    getV1Orders: getV1Orders,
    getOrderReturnReasons: getOrderReturnReasons,
    createOrderItemReturn: createOrderItemReturn,
    getOrderItemReturn: getOrderItemReturn,
    associateDocumentToLineItem: associateDocumentToLineItem,
    deleteEmployee: deleteEmployee,
    getItemPromotions: getItemPromotions,
    getCCAppointments: getCCAppointments,
    getAppointmentDetails: getAppointmentDetails,
    updateDeliveredQuantity: updateDeliveredQuantity,
    getPriceRule: getPriceRule,
    getTransactionHistory: getTransactionHistory,
    userWalletInfo: userWalletInfo,
    createReport: createReport,
    getReport: getReport,
    nearByOperators,
    suppliers,
    supplierPrices,
    supplierPricesCreate,
    supplierPricesShow,
    supplierPricesUpdate,
    supplierPricesDelete,
    setItemPurchasePrice,
    createEmployee,
    createCoupon,
    updateCoupon,
    removeCoupon,
    getPriceRules,
    getReportV1,
    couponRemove,
    inventoryBatchList,
    inventoryBatchCreate,
    inventoryBatchShow,
    inventoryBatchUpdate,
    inventoryBatchDelete,
    purchaseConfigurationCreate,
    purchaseConfigurationShow,
    purchaseConfigurationUpdate,
    purchaseConfigurationDelete,
    itemPurchaseConfigurationShow,
    itemPurchaseConfigurationList,
    itemPurchaseRequests,
    itemPurchaseRequestList,
    itemPurchaseRequestCreate,
    itemPurchaseRequestUpdate,
    itemPurchaseRequestDelete,
    approveItemPurchaseRequest,
    embeddedDashboardList,
    embeddedDashboardUrl,
    getApprovedItemPurchaseRequest,
    deleteApprovedItemPurchaseRequest,
    updateApprovedItemPurchaseRequest,
    fetchPromotions,
  };
};

export default EmployeeManager(
  Utils,
  ErrorConstants,
  EmployeeResource,
  PrestoSdk,
  EmployeeObject
);
