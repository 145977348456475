import React, { useContext, useReducer } from "react";
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import I18n from "i18n-js";
import CartContext from "@presto-contexts/CartContext";
import { createStackNavigator } from "@react-navigation/stack";
import {
  NavigationContainer,
  NavigationIndependentTree,
} from "@react-navigation/native";
import CustomerSearch from "@presto-screens/CustomerSearch/CustomerSearch";
import CartScreen from "@presto-screens/Cart/Cart";
import CartCheckout from "@presto-screens/CartCheckout/CartCheckout";
import OrderSuccessScreen from "@presto-screens/OrderSuccess/OrderSuccess";
import AddCustomer from "@presto-screen-components/AddCustomer/AddCustomer";
import CouponApplyScreen from "@presto-screens/CouponApply/CouponApply";
import CreateReport from "@presto-screens/Reports/CreateReport";
import OrdersList from "@presto-screens/OrdersList/OrdersList";
import OrderDetails from "@presto-screens/OrderDetails/OrderDetails";
import OrderDetailsForReports from "@presto-screens/OrderDetails/OrderDetailsForReports";
import OrderReturn from "@presto-screens/OrderReturn/OrderReturn";
import ItemDetailScreen from "@presto-screens/ItemDetailScreen/ItemDetailScreen";
import ItemPromotion from "@presto-screens/ItemPromotion/ItemPromotion";
import PriceRule from "@presto-screens/ItemPromotion/PriceRule";
import ReportDetails from "@presto-screen-components/AllReports/ReportDetails";
import CustomerDetails from "@presto-screen-components/CustomerDetails/CustomerDetails";
import InitialPurchaseRequestDetail from "@presto-screen-components/PurchaseRequests/InitialPurchaseRequestDetail";
import UserInfo from "@presto-screen-components/RoleManagement/UserInfo";
import CouponDetails from "@presto-screen-components/CouponDetails/CouponDetails";
import utils from "../../utils";
import TopHeaderWithTitle from "@presto-screen-components/Headers/TopHeaderWithTitle";

const Features = {
  pos_management: "pos_management",
  reports_management: "reports_management",
  sales_reports_management: "sales_reports_management",
  return_management: "return_management",
  all_stock: "all_stock",
  offer_management: "offer_management",
  customer_management: "customer_management",
  purchase_request_management: "purchase_request_management",
  purchase_order_management: "purchase_order_management",
  admin_management_user_form: "admin_management_user_form",
  admin_management: "admin_management",
};

export function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_CATEGORY_ITEM":
      return { ...state, items: { ...state.items, [payload.id]: payload } };
  }
  return state;
}

export default function SideNavigation({
  selectedFeature,
  forwardProps,
  navRef,
  onChangeOfHeaderTabs,
  parentLoading,
  setParentLoading,
  onCompleteOrder,
  goBack,
  closeModal,
}) {
  const { theme } = useContext(ThemeContext);
  const [state, dispatch] = useReducer(reducer, {
    items: {},
  });
  const { items } = state;
  const { cart } = useContext(CartContext);
  const Stack = createStackNavigator();
  const isMobile = utils.isMobile();
  const headerShown = isMobile ? true : false;

  const renderHeader = (title, goHome, feature) => {
    return (
      <TopHeaderWithTitle
        title={title}
        onPressLeftButton={() =>
          _.isFunction(closeModal) ? closeModal() : goBack(goHome, feature)
        }
      />
    );
  };

  const stackScreensForPos = () => {
    return (
      <>
        <Stack.Screen
          name="CartScreen"
          component={CartScreen}
          options={{
            headerShown: headerShown,
            header: () =>
              renderHeader(
                I18n.t("screen_messages.cart_tab"),
                true,
                Features.pos_management
              ),
          }}
          initialParams={{ setParentLoading }}
        />
        <Stack.Screen
          name="CustomerSearch"
          component={CustomerSearch}
          options={{
            headerShown: headerShown,
            gestureEnabled: false,
            header: () => renderHeader("Customer Search"),
          }}
          initialParams={{
            filter: "",
            offlineEnabledAPIForCustomerSearch: true,
            showAddCustomerButton: false,
          }}
        />
        <Stack.Screen
          name="AddCustomer"
          component={AddCustomer}
          options={{
            headerShown: headerShown,
            gestureEnabled: false,
            header: () => renderHeader("Add Customer"),
          }}
          initialParams={{ parentLoading, setParentLoading }}
        />
        <Stack.Screen
          name="CartCheckout"
          component={CartCheckout}
          options={{
            headerShown: headerShown,
            gestureEnabled: false,
            header: () => renderHeader("Cart Checkout"),
          }}
          initialParams={{
            onCompleteOrder,
            onChangeOfHeaderTabs,
            parentLoading,
            setParentLoading,
          }}
        />
        <Stack.Screen
          name="CouponApplyScreen"
          component={CouponApplyScreen}
          options={{
            headerShown: headerShown,
            gestureEnabled: false,
            header: () => renderHeader("Coupon Apply"),
          }}
        />
        <Stack.Screen
          name="OrderSuccess"
          component={OrderSuccessScreen}
          options={{
            headerShown: headerShown,
            gestureEnabled: false,
            header: () => renderHeader("Order Success"),
          }}
        />
        <Stack.Screen
          name="ItemDetailScreen"
          component={ItemDetailScreen}
          options={{
            headerShown: headerShown,
            header: () => renderHeader("Item Details"),
          }}
        />
      </>
    );
  };

  const stackScreensForReports = () => {
    return (
      <>
        <Stack.Screen
          name="ReportDetails"
          component={ReportDetails}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CreateReport"
          component={CreateReport}
          options={{ headerShown: false }}
        />
      </>
    );
  };

  const stackScreensForSalesReports = () => {
    return (
      <Stack.Screen
        name="OrderDetailsForReports"
        component={OrderDetailsForReports}
        options={{ headerShown: false, gestureEnabled: false }}
      />
    );
  };

  const stackScreensForCustomer = () => {
    return (
      <>
        <Stack.Screen
          name="CustomerDetails"
          component={CustomerDetails}
          options={{
            headerShown: headerShown,
            gestureEnabled: false,
            header: () =>
              renderHeader(
                I18n.t("screen_messages.customers.customer_details_text"),
                true,
                Features.customer_management
              ),
          }}
        />
        <Stack.Screen
          name="AddCustomer"
          component={AddCustomer}
          options={{
            headerShown: headerShown,
            gestureEnabled: false,
            header: () =>
              renderHeader(I18n.t("screen_messages.customers.add_customer")),
          }}
        />
      </>
    );
  };

  const stackScreensForPurchaseRequest = () => {
    return (
      <>
        <Stack.Screen
          name="InitialPurchaseRequestDetail"
          component={InitialPurchaseRequestDetail}
          options={{
            headerShown: headerShown,
            header: () =>
              renderHeader(
                "Initial PurchaseRequest",
                true,
                Features.purchase_request_management
              ),
          }}
        />
        <Stack.Screen
          name="ItemDetailScreen"
          component={ItemDetailScreen}
          options={{
            headerShown: headerShown,
            header: () =>
              renderHeader(
                "Item Details",
                true,
                Features.purchase_request_management
              ),
          }}
        />
      </>
    );
  };

  const stackScreensForPurchaseOrder = () => {
    return (
      <>
        <Stack.Screen
          name="InitialPurchaseRequestDetail"
          component={InitialPurchaseRequestDetail}
          options={{ headerShown: false }}
        />
      </>
    );
  };

  const stackScreensForAdmin = () => {
    return (
      <>
        <Stack.Screen
          name="UserInfo"
          component={UserInfo}
          options={{ headerShown: false }}
        />
      </>
    );
  };

  const stackScreensForReturns = () => {
    return (
      <>
        <Stack.Screen
          name="CustomerSearch"
          component={CustomerSearch}
          options={{
            headerShown: headerShown,
            header: () =>
              renderHeader("Customer Search", true, Features.pos_management),
          }}
          initialParams={{ filter: "Returns" }}
        />
        <Stack.Screen
          name="OrdersList"
          component={OrdersList}
          options={{
            headerShown: headerShown,
            header: () => renderHeader("Order List"),
          }}
        />
        <Stack.Screen
          name="OrderDetails"
          component={OrderDetails}
          options={{
            headerShown: headerShown,
            header: () =>
              renderHeader(I18n.t("screen_messages.order_details_title")),
          }}
        />
        <Stack.Screen
          name="OrderReturn"
          component={OrderReturn}
          options={{
            headerShown: headerShown,
            header: () => renderHeader("Order Return"),
          }}
        />
      </>
    );
  };
  const stackScreensForInventory = () => {
    return (
      <>
        <Stack.Screen
          name="ItemDetailScreen"
          component={ItemDetailScreen}
          options={{ headerShown: false, gestureEnabled: false }}
          initialParams={{
            filter: "ItemDetail",
            ...forwardProps,
          }}
        />
        <Stack.Screen
          name="InitialPurchaseRequestDetail"
          component={InitialPurchaseRequestDetail}
          options={{ headerShown: false }}
        />
      </>
    );
  };

  const stackScreensForPromotions = () => {
    return (
      <>
        <Stack.Screen
          name="ItemPromotion"
          component={ItemPromotion}
          options={{ headerShown: false, gestureEnabled: false }}
          initialParams={{
            filter: "ItemPromotion",
            ...forwardProps,
          }}
        />
        <Stack.Screen
          name="PriceRule"
          component={PriceRule}
          options={{ headerShown: false, gestureEnabled: false }}
          initialParams={{
            filter: "PriceRule",
            ...forwardProps,
          }}
        />
        <Stack.Screen
          name="CouponDetails"
          component={CouponDetails}
          options={{
            headerShown: headerShown,
            gestureEnabled: false,
            header: () =>
              renderHeader(I18n.t("screen_messages.coupon_details")),
          }}
        />
      </>
    );
  };

  const displayStackScreens = () => {
    console.log("Selected Feature -- " + selectedFeature?.state);
    if (selectedFeature?.state === Features.pos_management) {
      return stackScreensForPos();
    } else if (selectedFeature?.state === Features.reports_management) {
      return stackScreensForReports();
    } else if (selectedFeature?.state === Features.sales_reports_management) {
      return stackScreensForSalesReports();
    } else if (selectedFeature?.state === Features.return_management) {
      return stackScreensForReturns();
    } else if (selectedFeature?.state === Features.all_stock) {
      return stackScreensForInventory();
    } else if (selectedFeature?.state === Features.offer_management) {
      return stackScreensForPromotions();
    } else if (selectedFeature?.state === Features.customer_management) {
      return stackScreensForCustomer();
    } else if (
      selectedFeature?.state === Features.purchase_request_management
    ) {
      return stackScreensForPurchaseRequest();
    } else if (selectedFeature?.state === Features.purchase_order_management) {
      return stackScreensForPurchaseOrder();
    } else if (
      _.includes(
        [Features.admin_management_user_form, Features.admin_management],
        selectedFeature?.state
      )
    ) {
      return stackScreensForAdmin();
    }
  };

  return (
    <NavigationIndependentTree>
      <NavigationContainer ref={navRef}>
        <Stack.Navigator>{displayStackScreens()}</Stack.Navigator>
      </NavigationContainer>
    </NavigationIndependentTree>
  );
}
