import React, { useContext } from "react";
import { View, ScrollView } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import _ from "lodash";
import PrestoText from "@presto-components/PrestoText";
import OrderReturnItemCard from "@presto-screen-components/OrderReturnItemCard/OrderReturnItemCard";

export default function PriceRule({ route }) {
  const { state, prerequisiteIds, entitlementIds } = route?.params;
  const { theme } = useContext(ThemeContext);

  const renderItem = (lineItem, index) => {
    if (lineItem) {
      return (
        <OrderReturnItemCard
          key={`${lineItem.id}${index}`}
          cantEdit={true}
          item={lineItem}
          variation=""
        />
      );
    } else {
      return null;
    }
  };

  const DetailComponent = ({
    label,
    children,
    extraStyle,
    labelExtraStyle,
  }) => {
    return (
      <View
        style={{ paddingHorizontal: 20, flexDirection: "row", ...extraStyle }}
      >
        <PrestoText
          fontStyle={"400.semibold"}
          color={theme.white}
          size={16}
          extraStyle={labelExtraStyle}
        >
          {label}
        </PrestoText>
        <View>{children}</View>
      </View>
    );
  };

  return (
    <View
      style={{
        backgroundColor: theme.topHeaderInactiveColor,
        height: "100%",
        position: "relative",
      }}
    >
      <PrestoText
        size={22}
        fontStyle={"600.semibold"}
        color={theme.white}
        extraStyle={{ paddingHorizontal: 20, paddingVertical: 20 }}
      >
        {I18n.t("screen_messages.price_rules.price_rule_details")}
      </PrestoText>
      <ScrollView contentContainerStyle={{ paddingBottom: 50 }}>
        {state?.itemName && (
          <DetailComponent
            label={I18n.t("screen_messages.stock.name")}
            extraStyle={{ justifyContent: "space-between" }}
          >
            <PrestoText fontStyle={"400.normal"} color={theme.white} size={18}>
              {state?.itemName}
            </PrestoText>
          </DetailComponent>
        )}
        {state?.value ? (
          <DetailComponent
            label={I18n.t("screen_messages.price_rules.value_str")}
            extraStyle={{ paddingTop: 20, justifyContent: "space-between" }}
          >
            <PrestoText fontStyle={"400.normal"} color={theme.white} size={18}>
              {state?.value}
            </PrestoText>
          </DetailComponent>
        ) : null}
        {state?.entitlementType ? (
          <DetailComponent
            label={I18n.t("screen_messages.price_rules.entitlement_type")}
            extraStyle={{ paddingTop: 20, justifyContent: "space-between" }}
          >
            <PrestoText fontStyle={"400.normal"} color={theme.white} size={18}>
              {state?.entitlementType?.label}
            </PrestoText>
          </DetailComponent>
        ) : null}
        {state?.valueType ? (
          <DetailComponent
            label={I18n.t("screen_messages.price_rules.value_type")}
            extraStyle={{ paddingTop: 20, justifyContent: "space-between" }}
          >
            <PrestoText fontStyle={"400.normal"} color={theme.white} size={18}>
              {state?.valueType?.label}
            </PrestoText>
          </DetailComponent>
        ) : null}
        {state?.prerequisiteQty ? (
          <DetailComponent
            label={I18n.t("screen_messages.price_rules.prerequisite_qty")}
            extraStyle={{ paddingTop: 20, justifyContent: "space-between" }}
          >
            <PrestoText fontStyle={"400.normal"} color={theme.white} size={18}>
              {state?.prerequisiteQty}
            </PrestoText>
          </DetailComponent>
        ) : null}
        {state?.minPurchaseQty ? (
          <DetailComponent
            label={I18n.t("screen_messages.price_rules.minimum_quantity")}
            extraStyle={{ paddingTop: 20, justifyContent: "space-between" }}
          >
            <PrestoText fontStyle={"400.normal"} color={theme.white} size={18}>
              {state?.minPurchaseQty}
            </PrestoText>
          </DetailComponent>
        ) : null}
        {state?.minPurchaseValue ? (
          <DetailComponent
            label={I18n.t("screen_messages.price_rules.min_purchase_value")}
            extraStyle={{ paddingTop: 20, justifyContent: "space-between" }}
          >
            <PrestoText fontStyle={"400.normal"} color={theme.white} size={18}>
              {state?.minPurchaseValue}
            </PrestoText>
          </DetailComponent>
        ) : null}
        {state?.entitlementQty ? (
          <DetailComponent
            label={I18n.t("screen_messages.price_rules.entitled_item_qty")}
            extraStyle={{ paddingTop: 20, justifyContent: "space-between" }}
          >
            <PrestoText fontStyle={"400.normal"} color={theme.white} size={18}>
              {state?.entitlementQty}
            </PrestoText>
          </DetailComponent>
        ) : null}
        {state?.allocationLimit ? (
          <DetailComponent
            label={I18n.t("screen_messages.price_rules.allocation_limit")}
            extraStyle={{ paddingTop: 20, justifyContent: "space-between" }}
          >
            <PrestoText fontStyle={"400.normal"} color={theme.white} size={18}>
              {state?.allocationLimit}
            </PrestoText>
          </DetailComponent>
        ) : null}
        {prerequisiteIds?.length ? (
          <DetailComponent
            label={I18n.t("screen_messages.price_rules.prerequisite_items")}
            labelExtraStyle={{ paddingBottom: 10, paddingHorizontal: 20 }}
            extraStyle={{
              paddingTop: 20,
              flexDirection: "column",
              paddingHorizontal: 0,
            }}
          >
            {_.map(prerequisiteIds, renderItem)}
          </DetailComponent>
        ) : null}
        {entitlementIds?.length ? (
          <DetailComponent
            label={I18n.t("screen_messages.price_rules.emtitled_items")}
            labelExtraStyle={{ paddingBottom: 10, paddingHorizontal: 20 }}
            extraStyle={{
              paddingTop: 20,
              flexDirection: "column",
              paddingHorizontal: 0,
            }}
          >
            {_.map(entitlementIds, renderItem)}
          </DetailComponent>
        ) : null}
      </ScrollView>
    </View>
  );
}
