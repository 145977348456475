import React, { useContext, useState } from "react";
import { View, Pressable, ScrollView, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import _ from "lodash";
import CustomerNameWithPhone from "@presto-cards/CustomerInfo/CustomerNameWithPhone";
import LoadingContainer from "@presto-components/LoadingContainer";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoText from "@presto-components/PrestoText";
import Config from "@presto-common/config";
import { useAssets } from "presto-react-components";
import { prestoAlert } from "../../common/Alert";
import UserModel from "@presto-component-models/UserModel";
import CartCheckoutReview from "@presto-screen-components/CartCheckout/CartCheckoutReview";
import NetworkContext from "@presto-contexts/NetworkContext";
import OrdersHelper from "@presto-helpers/OrdersHelper";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import PrestoIcon from "@presto-components/PrestoIcon";
import utils from "../../utils/index";
import SessionManager from "@presto-services/features/session/SessionManager";
import PrestoSdk from "@presto-services/global/PrestoSdk";

export default function OrderSucccessScreen({ route, navigation }) {
  const { svgs } = useAssets();
  const isWeb = Platform.OS == 'web'
  const params = route.params || {};
  const { order, cart, paymentOption } = params;
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { userNetworkState } = isWeb ? { userNetworkState: true } : useContext(NetworkContext);
  const [loading, setLoading] = useState(false);
  const isChangeFormEnabled = false;
  const [changeReturnForm, setChangeReturnForm] = useState({
    amountPaid: "",
    balance: "",
  });
  const isMobile = utils.isMobile();

  const fetchPrestoSession = async () => {
    setLoading(true);
    return await SessionManager.getEmployee((sessionHeader) => {
      const url =
        PrestoSdk.getHostName() +
        `/employee/v0/orders/${order.id}/download_invoice.json&x-presto-st=RURpL1hjcEdYbFB5VENwR0NVdWpYZGp1SisyNWJWa29hRTJ0akd6TW1iV3ZjODNPR2NGT01VbzBCejJxQnNsT2lCVS9jSkt1Q3ZqSkd5T0x4b1BPQ3JzaVNPNkJ6dGFuRm9PcXEyaE5aQ0NoaHVBK3ZBaHpEQ3ZabzhZeWlJekc2aVJOdUVtQWNsdlRteXR6ZU1YaXo4UE9pTnlhcUpVUVR2Rzd6Q0hSZ3JSN2wzM05LVFV1ZzI0bDU5cjBUQ0lFZkNnYnQ1QnluVzZ6QXZ3SzBXZUFpZUZqWTlsbWRkcUJIUEI5S3ZZSG5Kc04ySForb0xlMms1REZzaVhnQ29wR2xqdGlnYVNYS2dFQVM1N3pVY0hUTHh1bkJvTkhCK2RPUE9XSkMwdGpsSEJjcmRGTTU1Z0JVTGliQm9ZWHJUSWFPTE1uUk5uTStxeGlsLzdQOFlGV3BVeEV4UCsvam4zZ2QyOTN3TjJYcEFDK0JYenVYcWFvWmk0VFNBZXZaMVRiUjREMTNXZTYxdkM4NnB4cnNZcTlaTTl1elZHdFY2c0I1WUQ3am5TK3NsS1pvMXNrSjkrMmxqTDhibzkvZllOQi0tUzlNNWY4RERYeTBoSHVGV2pSVUdoQT09--7be0e34667e4e772aceb0e5ad003a9f92b593f5a`;
      window.open(url, "_self");
      setLoading(false);
      return url;
    })
  };

  const onDownloadInvoice = (printInvoice) => {
    if (isWeb) {
      fetchPrestoSession()
    } else {
      setLoading(true);
      OrdersHelper.downloadInvoiceByEmployee(order, { print: printInvoice }).then(
        (data) => {
          setLoading(false);

          if (data.success) {
            alert(I18n.t("screen_messages.reports.download_successfull"));
          } else {
            alert(I18n.t("screen_messages.download_failed"));
          }
        }
      );
    }
  };

  const onPressRightIcon = () => {
    if (isMobile) {
      navigation.popToTop();
      Navigator.emit({
        event: "replace",
        params: {
          screenName: "HOME",
          screenParams: {
            selectedFeature: {
              state: "pos_management",
            },
          },
        },
      });
      return;
    }
    navigation.navigate("CartScreen");
  };

  const getTotalPayable = () => {
    const val = Number(cart?.total_price) / 100;
    return val.toFixed(2);
  };

  const getChangeToBeReturned = () => {
    let amountPaid = Number(changeReturnForm.amountPaid);
    if (isNaN(amountPaid)) {
      amountPaid = 0;
    }
    let balance = amountPaid - getTotalPayable();

    if (isNaN(balance) || balance < 0) {
      balance = 0;
    }
    return balance;
  };

  const getChangeToBeReturnedFormatted = () => {
    return utils.formattedCurrencyToRoundOff(getChangeToBeReturned() * 100, "");
  };

  const BoxItem = ({ title, bgColor, containerStyle = {}, onPress }) => {
    return (
      <View style={{ height: 63, width: "50%", ...containerStyle }}>
        <Pressable
          style={{
            paddingTop: 7,
            paddingHorizontal: theme.primaryPadding,
            backgroundColor: bgColor,
            borderRadius: 12,
            height: "100%",
          }}
          onPress={onPress}
        >
          <View>
            <PrestoText fontStyle="600.semibold" size={20} color={theme.white}>
              {title}
            </PrestoText>
          </View>
        </Pressable>
      </View>
    );
  };

  const renderTextBox = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <View style={{ flex: 1 }}>
          <PrestoText fontStyle="500.medium" size={16} color="#848484">
            {I18n.t("screen_messages.common.cash_received")}
          </PrestoText>
        </View>

        <View style={{ width: 120 }}>
          <PrestoTextInput
            style={{
              textInputHeight: 32,
              textInputWidth: 120,
              borderRadius: 6,
            }}
            theme={theme}
            value={changeReturnForm.amountPaid}
            keyboardType={"numeric"}
            onChange={({ nativeEvent: { text } }) => {
              setChangeReturnForm({ ...changeReturnForm, amountPaid: text });
            }}
            backgroundColor={theme.white}
            textAlign="right"
          />
        </View>
      </View>
    );
  };

  const renderChangeToReturnForm = () => {
    return (
      <View
        style={{
          borderTopWidth: 1,
          borderTopColor: "#727581",
          paddingVertical: 30,
        }}
      >
        {renderTextBox()}

        <View style={{ paddingTop: 20 }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flex: 1 }}>
              <PrestoText fontStyle="500.medium" size={16} color="#848484">
                {I18n.t("screen_messages.common.change")}
              </PrestoText>
            </View>

            <View style={{ flex: 1, alignItems: "flex-end" }}>
              <PrestoText fontStyle="500.medium" size={16} color="#51BC72">
                {getChangeToBeReturnedFormatted()}
              </PrestoText>
            </View>
          </View>
        </View>
      </View>
    );
  };

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: theme.screenBackgroundColor,
      }}
    >
      <ScrollView
        contentContainerStyle={{
          backgroundColor: "#737682",
          height: "100%",
          borderTopLeftRadius: isMobile ? 0 : 18,
          borderTopRightRadius: isMobile ? 0 : 18,
          paddingBottom: 100,
        }}
      >
        <LoadingContainer bgColor="transparent" loading={loading}>
          <View
            style={{
              paddingVertical: 20,
              paddingHorizontal: theme.primaryPadding,
            }}
          >
            <CustomerNameWithPhone
              customer={new UserModel({ user: order.user_hash })}
              onPress={() => { }}
              containerStyles={{
                borderRadius: 12,
                borderBottomWidth: 0,
              }}
            />
          </View>
          <View
            style={{
              paddingHorizontal: theme.primaryPadding + 12,
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <PrestoIcon
              icon={
                <svgs.TickIconFilled
                  fillColor={theme.primary}
                  color={theme.white}
                />
              }
            />
            <PrestoText
              fontStyle="600.semibold"
              color={theme.white}
              size={36}
              extraStyle={{ paddingLeft: 10, marginTop: -10 }}
            >
              {I18n.t("screen_messages.orders.this_order_is_complete")}
            </PrestoText>
          </View>
        </LoadingContainer>
      </ScrollView>
      <View
        style={{
          ...theme.darkShadow,
          paddingHorizontal: 20,
          position: "absolute",
          bottom: 0,
          width: "100%",
          backgroundColor: theme.white,
          paddingBottom: theme.containerPadding + 10,
          borderTopRightRadius: 18,
          borderTopLeftRadius: 18,
          // height: 200,
        }}
      >
        <View style={{ marginTop: 40, marginBottom: theme.containerPadding }}>
          <CartCheckoutReview cart={cart} />
        </View>

        {paymentOption == "CASH" && isChangeFormEnabled
          ? renderChangeToReturnForm()
          : null}

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            marginBottom: 30,
          }}
        >
          <BoxItem
            title={I18n.t("screen_messages.common.print")}
            bgColor="#F17B8D"
            containerStyle={{
              paddingRight: 10,
              opacity: userNetworkState ? 1 : 0.4,
            }}
            onPress={() =>
              userNetworkState ? onDownloadInvoice((false)) : null
            }
          />
          <BoxItem
            title={I18n.t("screen_messages.common.done")}
            bgColor="#737682"
            onPress={onPressRightIcon}
          />
        </View>
      </View>
    </View>
  );
}
