import React from "react";
import { ScrollView, View } from "react-native";
import HomeScreen from "@presto-screens/Home/Home";
import LoginScreen from "@presto-screens/LoginWithPassword/LoginWithPassword";
import SearchScreen from "@presto-screens/SearchResults/SearchResults";
import ReportAProblem from "@presto-screens/Feedback/ReportAProblem";
import ChooseLanguage from "@presto-screens/ChooseLanguage/ChooseLanguage";
import FeatureSelection from "@presto-screens/FeatureSelection/FeatureSelection";
import AllStock from "@presto-screens/AllStock/AllStock";
import InsightsReports from "@presto-screens/Reports/InsightsReports";
import SalesDbReports from "@presto-screens/Reports/SalesDbReports";
import BulkUpdate from "@presto-screens/BulkUpdate/BulkUpdate";
import ReportsHomeScreen from "@presto-screens/Home/Home";
import Catalog from "@presto-screens/CreateCatalog/Catalog";
import AllSales from "@presto-screens/Shop/AllSales";
import OffersScreen from "@presto-screens/OffersScreen/OffersScreen";
import SideNavigation from "@presto-screens/SideNavigation/SideNavigation";
import NonZipPurchases from "@presto-screens/PurchaseOrders/NonZipPurchases";
import SortOptionsScreen from "@presto-screens/SortOptions/SortOptions";
import OrderDetailsForReports from "@presto-screens/OrderDetails/OrderDetailsForReports"
import TopHeaderWithTitle from "@presto-screen-components/Headers/TopHeaderWithTitle";
import ItemDetailScreen from "@presto-screens/ItemDetailScreen/ItemDetailScreen.ziphealthPos.js"
import TermsAndConditions from "@presto-screens/TermsAndConditions/TermsAndConditions.ziphealthPos";

function EmptyScreen() {
  return (
    <ScrollView style={{}}>
      <View
        style={{
          width: "100%",
          height: 1000,
          justifyContent: "center",
          alignItems: "center",
        }}
      ></View>
    </ScrollView>
  );
}

export default {
  mobile: {
    logged_out: "LOGIN",
    logged_in: "FEATURE_SELECTION",
    screens: {
      LOGGED_OUT: {
        path: "login_out",
        component: LoginScreen,
        header: false,
      },
      LOGIN: {
        path: "login",
        component: LoginScreen,
        header: false,
      },
      FEATURE_SELECTION: {
        path: "feature_selection",
        component: FeatureSelection,
        header: false,
      },
      HOME: {
        path: "home",
        component: HomeScreen,
        header: false,
      },
      REPORTS_HOME: {
        path: "REPORTS_home",
        component: ReportsHomeScreen,
        header: false,
      },
      EMPTY_TAB_SCREEN: {
        component: EmptyScreen,
        header: false,
      },
      SEARCH_RESULTS_SCREEN: {
        path: "search",
        component: SearchScreen,
        header: false,
      },
      REPORT_A_PROBLEM: {
        path: "report",
        component: ReportAProblem,
        header: false,
      },
      CHOOSE_LANGUAGE: {
        path: "chooselanguage",
        component: ChooseLanguage,
        header: false,
      },
      ALL_STOCK: {
        path: "all_stock",
        component: AllStock,
      },
      INSIGHTS: {
        path: "insights",
        component: InsightsReports,
      },
      SALESDB: {
        path: "salesdb",
        component: SalesDbReports,
      },
      BULK_UPDATE: {
        path: "bulk_update",
        component: BulkUpdate,
      },
      CATALOG: {
        path: "catalog",
        component: Catalog,
      },
      ALL_SALES: {
        path: "all_sales",
        component: AllSales,
      },
      OFFERS_SCREEN: {
        path: "offers_screen",
        component: OffersScreen,
      },
      SIDE_NAVIGATION: {
        path: "side_navigation",
        component: SideNavigation,
      },
      NON_ZIP_PURCHASES: {
        path: "non_zip_purchases",
        component: NonZipPurchases,
        fadeIn: true,
      },
      SORT_OPTIONS: {
        path: "sort_options",
        component: SortOptionsScreen,
        fadeIn: true,
      },
      ORDER_DETAILS_FOR_REPORTS: {
        path: "order_details_for_reports",
        component: OrderDetailsForReports,
        header: TopHeaderWithTitle,
      },
      ITEM_DETAILS_SCREEN: {
        path: "item_detail_screen",
        component: ItemDetailScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Item Details Screen",
        },
      },
      TERMS_AND_CONDITION: {
        path: "terms&condition",
        component: TermsAndConditions,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Terms And Condition",
        },
      },
    },
  },
  desktop: {
    logged_out: "LOGIN",
    logged_in: "FEATURE_SELECTION",
    screens: {
      LOGGED_OUT: {
        path: "login_out",
        component: LoginScreen,
        header: false,
      },
      LOGIN: {
        path: "login",
        component: LoginScreen,
        header: false,
      },
      FEATURE_SELECTION: {
        path: "feature_selection",
        component: FeatureSelection,
        header: false,
      },
      HOME: {
        path: "home",
        component: HomeScreen,
        header: false,
      },
      REPORTS_HOME: {
        path: "REPORTS_home",
        component: ReportsHomeScreen,
        header: false,
      },
      EMPTY_TAB_SCREEN: {
        component: EmptyScreen,
        header: false,
      },
      SEARCH_RESULTS_SCREEN: {
        path: "search",
        component: SearchScreen,
        header: false,
      },
      REPORT_A_PROBLEM: {
        path: "report",
        component: ReportAProblem,
        header: false,
      },
      CHOOSE_LANGUAGE: {
        path: "chooselanguage",
        component: ChooseLanguage,
        header: false,
      },
      ALL_STOCK: {
        path: "all_stock",
        component: AllStock,
      },
      INSIGHTS: {
        path: "insights",
        component: InsightsReports,
      },
      SALESDB: {
        path: "salesdb",
        component: SalesDbReports,
      },
      BULK_UPDATE: {
        path: "bulk_update",
        component: BulkUpdate,
      },
      CATALOG: {
        path: "catalog",
        component: Catalog,
      },
      ALL_SALES: {
        path: "all_sales",
        component: AllSales,
      },
      OFFERS_SCREEN: {
        path: "offers_screen",
        component: OffersScreen,
      },
      SIDE_NAVIGATION: {
        path: "side_navigation",
        component: SideNavigation,
      },
      NON_ZIP_PURCHASES: {
        path: "non_zip_purchases",
        component: NonZipPurchases,
        fadeIn: true,
      },
      SORT_OPTIONS: {
        path: "sort_options",
        component: SortOptionsScreen,
        fadeIn: true,
      },
      ORDER_DETAILS_FOR_REPORTS: {
        path: "order_details_for_reports",
        component: OrderDetailsForReports,
        header: TopHeaderWithTitle,
      },
      ITEM_DETAILS_SCREEN: {
        path: "item_detail_screen",
        component: ItemDetailScreen,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Item Details Screen",
        },
      },
      TERMS_AND_CONDITION: {
        path: "terms&condition",
        component: TermsAndConditions,
        header: TopHeaderWithTitle,
        headerParams: {
          title: "Terms And Condition",
        },
      },
    },
  }
};
