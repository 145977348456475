import React from "react";
import { CartItemCards } from "presto-react-components";
import utils from "../../utils";

export default function CartItemCard(props) {
  return (
    <CartItemCards.CartItemCard2
      {...props}
      formattedPrice={utils.formattedPrice}
    />
  );
}
