import _ from "lodash";
import { isOfflineEnabledAPI } from "@presto-common/OfflineAPIsConfig";
import DBCartManager from "@presto-db-managers/DBCartManager";
import ServerCartObject from "../services/data_models/ServerCartObject";
import ServerCartManager from "../services/features/server_cart/ServerCartManager";
import DBCartItemManager from "@presto-db-managers/DBCartItemManager";
import DBCouponManager from "@presto-db-managers/DBCouponManager";
import PrestoSdk from "../services/global/PrestoSdk";
import Utils from "../services/common/Utils";
import { Platform } from "react-native";

function isWebFunctions(webFunction, nativeFunction) {
  const isWeb = Platform.OS == 'web';
  if (isWeb) {
    return webFunction;
  } else {
    return nativeFunction;
  }
}
async function createCart(params, successCallback, errorCallback) {
  if (!params.merchant_id) {
    params.merchant_id = PrestoSdk.getMerchantId();
  }

  let isOfflineCart = _.get(params, "isOfflineCart");
  if (!isOfflineCart) {
    isOfflineCart = await existsInOfflineCart(params.cart_id);
  }
  console.log(isOfflineEnabledAPI("cartCreate") || isOfflineCart, 'sdfioajsidjflkjsdfjioj')
  if (isOfflineEnabledAPI("cartCreate") || isOfflineCart) {
    const obj = new DBCartManager();
    obj
      .createCart(params)
      .then((row) => {
        obj.fetchCart(params).then((row) => {
          var cartObject = new ServerCartObject();
          cartObject.buildObject(row);
          const response = { data: cartObject };
          successCallback(response);
        });
      })
      .catch((error) => {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      });
  } else {
    isWebFunctions(ServerCartManager.employeeCart, ServerCartManager.createCart)(params, successCallback, errorCallback);
  }
}

async function updateItem(params, successCallback, errorCallback) {
  if (!params.merchant_id) {
    params.merchant_id = PrestoSdk.getMerchantId();
  }

  const isOfflineCart = await existsInOfflineCart(params.cart_id);

  if (isOfflineEnabledAPI("cartUpdateItem") || isOfflineCart) {
    const obj = new DBCartItemManager();

    let updateParams = {
      _cart_id: params.cart_id,
      line_item_id: params.line_item_id,
      merchant_id: params.merchant_id,
      quantity: params.quantity,
    };
    obj
      .updateItem(updateParams)
      .then((itemRow) => {
        const dbCartManager = new DBCartManager();
        let fetchParams = {
          id: params.cart_id,
        };
        dbCartManager.invalidateAppliedCouponCode(fetchParams).then(() => {
          dbCartManager.fetchCart(fetchParams).then((row) => {
            var cartObject = new ServerCartObject();
            cartObject.buildObject(row);
            const response = { data: cartObject };
            successCallback(response);
          });
        });
      })
      .catch((error) => {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      });
  } else {
    isWebFunctions(ServerCartManager.employeeUpdateItem, ServerCartManager.updateItem)(params, successCallback, errorCallback);
  }
}

async function addItem(params, successCallback, errorCallback) {
  if (!params.merchant_id) {
    params.merchant_id = PrestoSdk.getMerchantId();
  }

  const isOfflineCart = await existsInOfflineCart(params.cart_id);
  console.log(successCallback, "jsadfoisdjfoijdsljlk")
  if (isOfflineEnabledAPI("cartAddItem") || isOfflineCart) {
    const cartManager = new DBCartManager();
    const obj = new DBCartItemManager();

    let newParams = {
      id: params.cart_id,
    };

    cartManager
      .fetchCart(newParams)
      .then(async (cart) => {
        if (_.isEmpty(cart)) {
          cart = await cartManager.createCart({
            merchant_id: params.merchant_id,
            outlet_id: params.outlet_id,
            cart_id: params.cart_id,
          });
          newParams = {
            id: cart.id,
          };
        }
        params._cart_id = cart.id;
        let result = await obj.addItem(params);
        const dbCartManager = new DBCartManager();

        await dbCartManager.invalidateAppliedCouponCode({ id: cart.id });

        dbCartManager.fetchCart(newParams).then((row) => {
          var cartObject = new ServerCartObject();
          cartObject.buildObject(row);
          const response = { data: cartObject };
          successCallback(response);
        });
      })
      .catch((e) => {
        errorCallback(e);
      });
  } else {
    ServerCartManager.addMultiItem(params, successCallback, errorCallback);
  }
}

async function removeItem(params, successCallback, errorCallback) {
  if (!params.merchant_id) {
    params.merchant_id = PrestoSdk.getMerchantId();
  }
  const isOfflineCart = await existsInOfflineCart(params.cart_id);

  if (isOfflineEnabledAPI("cartRemoveItem") || isOfflineCart) {
    const dbCartManager = new DBCartManager();
    const obj = new DBCartItemManager();
    const removeParams = {
      _cart_id: params.cart_id,
      line_item_id: params.line_item_id,
    };
    obj
      .removeItem(removeParams)
      .then((rowItem) => {
        const fetchParams = {
          _cart_id: params.cart_id,
        };
        dbCartManager
          .invalidateAppliedCouponCode({ id: params.cart_id })
          .then(() => {
            dbCartManager.fetchCart(fetchParams).then((row) => {
              var cartObject = new ServerCartObject();
              cartObject.buildObject(row);
              const response = { data: cartObject };
              successCallback(response);
            });
          });
      })
      .catch((error) => {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      });
  } else {
    isWebFunctions(ServerCartManager.employeeRemoveItem, ServerCartManager.removeItem)(params, successCallback, errorCallback);
  }
}

function existsInOfflineCart(cartId) {
  const obj = new DBCartManager();
  return new Promise((resolve) => {
    if (cartId) {
      return obj.exists(`id='${cartId}'`).then((exists) => resolve(exists));
    } else {
      resolve(false);
    }
  });
}

async function fetchCart(params, successCallback, errorCallback, multiCarts) {
  if (!params.merchant_id) {
    params.merchant_id = PrestoSdk.getMerchantId();
  }
  const isOfflineCart = await existsInOfflineCart(params.cart_id);

  if (isOfflineEnabledAPI("cartFetchCart") || isOfflineCart) {
    const obj = new DBCartManager();
    const fetchParams = {
      id: params.cart_id,
    };
    obj
      .fetchCart(fetchParams)
      .then((row) => {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(row);
        let response = { data: cartObject };
        successCallback(response);
      })
      .catch((error) => {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      });
  } else {
    isWebFunctions(ServerCartManager.fetchMultiItem, ServerCartManager.fetchCart)(params, successCallback, errorCallback);
  }
}

async function clearCart(params, successCallback, errorCallback) {
  if (!params.merchant_id) {
    params.merchant_id = PrestoSdk.getMerchantId();
  }

  const isOfflineCart = await existsInOfflineCart(params.cart_id);

  if (isOfflineEnabledAPI("cartClearCart") || isOfflineCart) {
    const obj = new DBCartManager();
    const clearCartParams = {
      id: params.cart_id,
    };
    obj
      .clearCart(clearCartParams)
      .then((row) => {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(row);
        let response = { data: cartObject };
        successCallback(response);
      })
      .catch((error) => {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      });
  } else {
    isWebFunctions(ServerCartManager.employeeClearcart, ServerCartManager.clearCart)(params, successCallback, errorCallback);
  }
}

async function applyCoupon(params, successCallback, errorCallback) {
  if (!params.merchant_id) {
    params.merchant_id = PrestoSdk.getMerchantId();
  }

  const isOfflineCart = await existsInOfflineCart(params.cart_id);

  if (isOfflineEnabledAPI("couponApply") || isOfflineCart) {
    const bBCartManager = new DBCartManager();
    const obj = new DBCouponManager();
    const newParams = {
      id: params.cart_id,
      coupon_code: params.coupon_code,
      merchant_id: params.merchant_id,
    };
    bBCartManager
      .applyCoupon(newParams)
      .then((cart) => {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(cart);
        let response = { data: cartObject };
        successCallback(response);
      })
      .catch((error) => {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      });
  } else {
    isWebFunctions(ServerCartManager.employeeApplyCoupon, ServerCartManager.applyCoupon)(params, successCallback, errorCallback);
  }
}

async function removeCoupon(params, successCallback, errorCallback) {
  if (!params.merchant_id) {
    params.merchant_id = PrestoSdk.getMerchantId();
  }
  const isOfflineCart = await existsInOfflineCart(params.cart_id);
  if (isOfflineEnabledAPI("couponRemove") || isOfflineCart) {
    const bBCartManager = new DBCartManager();
    const newParams = {
      id: params.cart_id,
      coupon_code: params.coupon_code,
      merchant_id: params.merchant_id,
    };
    bBCartManager
      .removeCoupon(newParams)
      .then((cart) => {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(cart);
        let response = { data: cartObject };
        successCallback(response);
      })
      .catch((error) => {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      });
  } else {
    ServerCartManager.removeCoupon(params, successCallback, errorCallback);
  }
}

function checkout(params, successCallback, errorCallback) {
  existsInOfflineCart(params.cart_id).then((isOfflineCart) => {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }

    const cartId = params.cart_id;
    params = _.omit(params, "cart_id");

    if (isOfflineEnabledAPI("cartCheckout") || isOfflineCart) {
      const bBCartManager = new DBCartManager();
      const newParams = {
        id: cartId,
        ...params,
      };
      bBCartManager
        .checkout(newParams)
        .then(async (rowsAffected) => {
          const cart = await bBCartManager.fetchCart({
            id: cartId,
          });
          var cartObject = new ServerCartObject();
          cartObject.buildObject(cart);
          let response = { data: cartObject };
          successCallback(response);
        })
        .catch((error) => {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        });
    } else {
      isWebFunctions(ServerCartManager.multiCheckout, ServerCartManager.checkout)(params, successCallback, errorCallback);
    }
  });
}

function fetchCarts(params, successCallback, errorCallback) {
  const bBCartManager = new DBCartManager();
  bBCartManager
    .fetchCarts({})
    .then((carts) => {
      let data = _.map(carts, (cart) => {
        let cartObject = new ServerCartObject();
        cartObject.buildObject(cart);
        cartObject._user = cart._user;
        return cartObject;
      });

      let response = { data };
      successCallback(response);
    })
    .catch((error) => {
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    });
}

function addUserToCart(params, successCallback, errorCallback) {
  const bBCartManager = new DBCartManager();
  bBCartManager
    .addUserToCart({ user: params.user, id: params.cart_id })
    .then((rowsAffected) => {
      return bBCartManager.fetchCart({ id: params.cart_id });
    })
    .then((cart) => {
      let cartObject = new ServerCartObject();
      cartObject.buildObject(cart);
      let response = { data: cartObject };
      successCallback(response);
    })
    .catch((error) => {
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    });
}

export default {
  createCart,
  updateItem,
  addItem,
  removeItem,
  fetchCart,
  clearCart,
  applyCoupon,
  removeCoupon,
  fetchCarts,
  addUserToCart,
  existsInOfflineCart,
  checkout,
};