import DBBaseManager from "@presto-db-managers/DBBaseManager";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import Utils from "@presto-services/common/Utils";
import _ from "lodash";

export default class DBCouponManager extends DBBaseManager {
  constructor() {
    super();
    this.tableName = "coupons";
    this.fillables = [
      "id",
      "active",
      "code",
      "description",
      "end_time",
      "max_discount",
      "max_redemption_count",
      "merchant_id",
      "min_bill_amount",
      "other_data",
      "start_time",
      "target_type",
      "title",
      "type",
      "value",
      "value_type", //fixed, percent
    ];
    this.datatypes = {
      other_data: "JSON",
      active: "BOOL",
      max_discount: "NUMBER",
      max_redemption_count: "NUMBER",
      min_bill_amount: "NUMBER",
      value: "NUMBER",
    };
  }

  syncLocally() {
    let params = {
      only_active: true,
      exclude_expired_coupon: true,
      exclude_referral_coupons: true,
    };
    EmployeeManager.getCoupons(
      params,
      (response) => {
        let items = _.get(response, "data");
        this.createOrUpdateMany(items);
      },
      (error) => {
        console.log("DBCouponManager.syncLocally.error", error);
      }
    );
  }

  getCoupons(params) {
    let sql = `SELECT * FROM ${this.tableName}`,
      whereBucket = [];
    let args = [];

    if (params.only_active) {
      whereBucket.push(`active=?`);
      args.push("true");
    }

    if (params.code) {
      whereBucket.push(`code=?`);
      args.push(params.code);
    }

    if (!_.isEmpty(whereBucket)) {
      let join = _.join(whereBucket, " AND ");
      sql = `${sql} WHERE ${join}`;
    }

    return this.asyncExec({ sql, args }).then(({ rows }) => rows);
  }

  apply({ merchant_id, code }) {
    let sql = `SELECT * FROM ${this.tableName} WHERE merchant_id=? AND code=?`;
    let args = [merchant_id, code];
    return this.asyncExec({ sql, args }).then(({ rows }) => {
      let coupon = _.first(rows);

      if (!this.isOfflineSupported(coupon)) {
        const error = new Error(`Coupon ${code} is not supported offline`);
        throw new error();
      }

      return coupon;
    });
  }

  isOfflineSupported(item) {
    return _.includes(["simple"], item.type);
  }

  computeDiscountOnValue(coupon, value) {
    let valueType = _.get(coupon, "value_type"),
      couponValue = Number(_.get(coupon, "value"));
    let discountValue = 0;
    if (isNaN(couponValue)) {
      couponValue = 0;
    }
    if (valueType === "fixed_discount") {
      discountValue = couponValue;
    } else if (valueType === "percent_discount") {
      discountValue = Utils.getPercentage(value, couponValue);
    }
    return discountValue;
  }

  getCouponByCode(code) {
    return this.getCoupons({
      only_active: true,
      code,
    }).then((rows) => _.first(rows));
  }
}
