import DBBaseManager from "@presto-db-managers/DBBaseManager";
import OrderManager from "@presto-services/features/orders/OrderManager";
import PaymentManager from "@presto-services/features/payment/PaymentManager";
import PosManager from "@presto-services/features/pos/PosManager";
import DBUserManager from "@presto-db-managers/DBUserManager";
import AsyncStorage from "@react-native-community/async-storage";
import _ from "lodash";

export default class DBOrderManager extends DBBaseManager {
  constructor() {
    super();
    this.tableName = "orders";
    this.fillables = [
      "id",
      "outlet_id",
      "merchant_id",
      "items",
      "instructions",
      "payment_type",
      "source",
      "tax_amount",
      "total_amount",
      "aggregate_discount",
      "address",
      "user_hash",
      "coupon_code",
      "app_id",
      "ordering_employee_id",
      "_composite_payment",
      "_create_payment_params",
      "_sync_status", // choices are: pending, synced
      "_error",
    ];
    this.datatypes = {
      items: "JSON",
      address: "JSON",
      user_hash: "JSON",
      _error: "JSON",
      _composite_payment: "JSON",
      _create_payment_params: "JSON",
    };
  }

  getOrder(params) {
    let id = params.id;
    return this.first(id);
  }

  mutateCalculations(order) {
    order.total_amount = _.divide(order.total_amount, 100);
    order.tax_amount = _.divide(order.tax_amount, 100);
    order.aggregate_discount = _.divide(order.aggregate_discount, 100);
    return order;
  }

  placeAgentOrder = async (params) => {
    let merchant_id = await AsyncStorage.getItem("MERCHANT_ID");
    let data = {
      id: this.generateId(),
      outlet_id: params.outlet_id,
      merchant_id: merchant_id,
      items: JSON.stringify(params.line_items),
      instructions: params.instructions,
      payment_type: params.payment_type,
      source: params.source,
      tax_amount: params.tax_amount,
      total_amount: params.total_amount,
      aggregate_discount: params.aggregate_discount,
      address: JSON.stringify(params.address),
      user_hash: JSON.stringify(params.user),
      coupon_code: params.coupon_code,
      app_id: params.app_id,
      ordering_employee_id: params.ordering_employee_id || "",
      _sync_status: "pending",
    };
    let columns = _.keys(data);
    let len = columns.length;
    let columnString = _.join(columns, ", ");
    let values = _.values(data);
    let valueString = _.join(_.fill(Array(len), "?"), ", ");
    let query = `INSERT INTO ${this.tableName} (${columnString}) VALUES (${valueString});`;
    return this.asyncExec({ sql: query, args: values }).then(
      async ({ insertId }) => {
        return await this.getOrder({ id: data.id });
      }
    );
  };

  pendingOrders() {
    let query = `SELECT * FROM ${this.tableName} WHERE _sync_status='pending';`;
    return this.asyncExec({ sql: query, args: [] }).then(({ rows }) => {
      let newRows = _.map(rows, (row) => this.applyDatatypeToObject(row));
      return newRows;
    });
  }

  syncOrders() {
    return this.pendingOrders().then((orders) => {
      let promises = _.map(orders, (order) => this.syncOrder(order));
      return Promise.all(promises);
    });
  }

  syncOrder = async (order) => {
    let merchant_id = await AsyncStorage.getItem("MERCHANT_ID");
    try {
      order.user_hash.email = order.user_hash.email.toLowerCase();
    } catch (e) {
      console.log("Error Downcase");
    }
    console.log("Syncing order");
    return new Promise(async (resolve, reject) => {
      let params = {
        merchant_id: merchant_id,
        order: {
          outlet_id: order.outlet_id,
          merchant_id: merchant_id,
          line_items: order.items,
          instructions: order.instructions,
          payment_type: order.payment_type,
          source: order.source,
          tax_amount: order.tax_amount,
          total_amount: order.total_amount,
          address: order.address,
          user: order.user_hash,
          coupon_code: order.coupon_code,
        },
      };
      console.log("Syncing order");
      console.log(params);

      if (order.ordering_employee_id) {
        params.ordering_employee_id = order.ordering_employee_id;
      }

      const customer = await DBUserManager.getRemoteClient({
        merchant_id: merchant_id,
        user_id: params.order.user.id,
      });

      if (customer) {
        params.order.user = customer;
      }

      const onSuccess = async (response) => {
        const remoteOrder = response.data;

        console.log("Syncing order done");
        console.log(response);
        const removeLocalOrder = () => {
          let query = "id=?",
            args = [order.id];
          this.remove(query, args).then(({ rowsAffected }) => {
            resolve(rowsAffected > 0);
          });
        };

        if (remoteOrder.payment_type === "ONLINE") {
          try {
            const compositePaymentResponse = await this.pushAgentCompositePaymentToRemote(
              remoteOrder,
              order
            );

            if (compositePaymentResponse instanceof Error) {
              throw compositePaymentResponse;
            }

            const createPaymentResponse = await this.pushCreatePaymentToRemote(
              compositePaymentResponse,
              remoteOrder,
              order
            );

            if (createPaymentResponse instanceof Error) {
              throw createPaymentResponse;
            }

            const successPaymentResponse = await this.pushSuccessPaymentToRemote(
              createPaymentResponse,
              remoteOrder
            );

            if (successPaymentResponse instanceof Error) {
              throw successPaymentResponse;
            }

            const updateOrderStatusResponse = await this.pushUpdateOrderStatus(
              successPaymentResponse,
              remoteOrder
            );

            if (updateOrderStatusResponse instanceof Error) {
              throw updateOrderStatusResponse;
            }

            removeLocalOrder();
          } catch (error) {
            console.log("Error ---- ");
            console.log(error);
            onError(error);
          }
        } else {
          await this.pushUpdateOrderStatus({}, remoteOrder);
          removeLocalOrder();
        }
      };

      const onError = (error) => {
        console.log("Error ---- DB ORDER");
        console.log(error);
        let sql = `UPDATE ${this.tableName} SET _error=? WHERE id=?`;
        let args = [JSON.stringify(error), order.id];
        this.asyncExec({ sql, args }).then(({ rowsAffected }) => {
          resolve(rowsAffected > 0);
        });
      };
      console.log("Order Params -- ");
      console.log(JSON.stringify(params));
      OrderManager.placeAgentOrder(params, onSuccess, onError);
    });
  };

  pushAgentCompositePaymentToRemote = async (remoteOrder, localOrder) => {
    let merchant_id = await AsyncStorage.getItem("MERCHANT_ID");
    return new Promise((resolve, reject) => {
      const onSuccess = (response) => {
        resolve(response.data);
      };

      const onError = (error) => {
        reject(error);
      };

      let params = {
        composite_payment: {
          user_id: remoteOrder.user_id,
          payment_type: remoteOrder.payment_type,
          merchant_id: merchant_id,
          source: "MOBILE",
          targets: [
            {
              id: remoteOrder.id,
              type: "order",
            },
          ],
        },
      };
      PaymentManager.createAgentCompositePayment(params, onSuccess, onError);
    });
  };

  pushCreatePaymentToRemote(compositePaymentResponse, remoteOrder, localOrder) {
    return new Promise((resolve, reject) => {
      const create_payment_params = _.get(localOrder, "_create_payment_params");
      let params = {
        payment: {
          type: _.get(create_payment_params, "payment.type"),
          reference_id: _.get(create_payment_params, "payment.reference_id"),
          provider: "razorpay",
        },
        payable_type: "CompositePayment",
        payable_id: compositePaymentResponse.id,
      };

      const onSuccess = (response) => {
        resolve(response.data);
      };

      const onError = (error) => {
        reject(error);
      };

      PosManager.createPayment(params, onSuccess, onError);
    });
  }

  pushSuccessPaymentToRemote(createPaymentResponse, remoteOrder) {
    return new Promise((resolve, reject) => {
      const onSuccess = (response) => {
        resolve(response.data);
      };

      const onError = (error) => {
        reject(error);
      };

      let params = {
        payment_id: createPaymentResponse.id,
      };
      PosManager.successPayment(params, onSuccess, onError);
    });
  }

  pushUpdateOrderStatus = async (successPaymentResponse, remoteOrder) => {
    let merchant_id = await AsyncStorage.getItem("MERCHANT_ID");
    return new Promise((resolve, reject) => {
      const onSuccess = (response) => {
        console.log("Response");
        console.log(response);
        resolve(response.data);
      };

      const onError = (error) => {
        console.log("Error");
        console.log(error);
        reject(error);
      };

      let params = {
        merchant_id: merchant_id,
        order_id: remoteOrder.id,
        status: "DELIVERED",
      };
      console.log("Params");
      console.log(params);
      OrderManager.updateCCOrdersStatus(params, onSuccess, onError);
    });
  };

  createAgentCompositePayment(params) {
    const orderId = _.get(params, "composite_payment.targets.0.id");
    const query = {
      _composite_payment: params,
    };
    return this.updateItem(query, (item) => {
      return {
        values: [orderId],
        whereQuery: "WHERE id=?",
      };
    }).then((...args) => {
      return this.getOrder({ id: orderId });
    });
  }

  createPayment(orderId, params) {
    const query = {
      _create_payment_params: params,
    };
    return this.updateItem(query, (item) => {
      return {
        values: [orderId],
        whereQuery: "WHERE id=?",
      };
    }).then((...args) => {
      return this.getOrder({ id: orderId });
    });
  }
}
