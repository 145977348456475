import _ from "lodash";
import React, { useEffect, useState } from "react";
import { StyleSheet, TextInput } from "react-native";
import * as RNLocalize from "react-native-localize";

function count(str, ch) {
  return _.countBy(str)[ch] || 0;
}

export default function PrestoAmountInput(props) {
  const settings = RNLocalize.getNumberFormatSettings();
  const separator = settings.decimalSeparator || ".";
  let [amount, setAmount] = useState(props.value || "");

  useEffect(() => {
    setAmount(props.value);
  }, [props.value]);

  const setAmountValue = (val) => {
    setAmount(val);
    props.onAmountChange(val);
  };

  const onTextChange = (text) => {
    text = _.replace(text, separator, ".");
    console.log("Text: ", text, count(text, "."), _.findLastIndex(text, "."));
    if (_.isNaN(+text)) {
      setAmountValue(text.substring(0, text.length - 1));
      return;
    }
    if (text[text.length - 1] === "." && count(text, ".") > 1) {
      setAmountValue(text.substring(0, text.length - 1));
      return;
    }

    if (
      _.lastIndexOf(text, ".") !== -1 &&
      text.length - _.lastIndexOf(text, ".") > 3
    ) {
      setAmountValue(text.substring(0, text.length - 1));
      return;
    }
    setAmountValue(text);
  };
  return (
    <TextInput
      underlineColorAndroid="transparent"
      autoCapitalize={props.autoCapitalize || "none"}
      autoCompleteType={props.autoCompleteType || "off"}
      spellCheck={false}
      autoCorrect={props.autoCorrect || false}
      keyboardType={props.keyboardType || "default"}
      placeholder={props.placeholder}
      maxLength={props.maxLength}
      multiline={props.multiline}
      placeholderTextColor={"#7B7B7B"}
      {...props}
      value={_.replace(amount, ".", separator)}
      onChangeText={onTextChange}
      style={
        props.textInputStyle || {
          ...defaultStyles.textInputStyle,
        }
      }
    ></TextInput>
  );
}

const defaultStyles = {
  textInputStyle: {
    width: 60,
    borderWidth: 1,
    borderRadius: 4,
    paddingLeft: 10,
    paddingVertical: 5,
    borderColor: "#aaaaaa",
    backgroundColor: "#ffffff",
  },
};
