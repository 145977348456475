import AdminResource from "./AdminResource";
import Utils from "../../common/Utils";

const AdminManager = function (Utils, AdminResource) {
  function galleryMediaItems(params, successCallback, errorCallback) {
    AdminResource.galleryMediaItems(params).then(
      function (galleryMediaItemsResponse) {
        successCallback(galleryMediaItemsResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function fetchGallaryDetails(params, successCallback, errorCallback) {
    AdminResource.fetchGallaryDetails(params).then(
      function (fetchGallaryDetailsResponse) {
        successCallback(fetchGallaryDetailsResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function uploadStoreImage(params, successCallback, errorCallback) {
    AdminResource.uploadStoreImage(params).then(
      function (uploadStoreImageResponse) {
        successCallback(uploadStoreImageResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function deleteStoreImage(params, successCallback, errorCallback) {
    AdminResource.deleteStoreImage(params).then(
      function (deleteStoreImageResponse) {
        successCallback(deleteStoreImageResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createCategory(params, successCallback, errorCallback) {
    AdminResource.createCategory(params).then(
      function (categoryDetails) {
        if (successCallback) {
          successCallback(categoryDetails);
        }
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function updateCategory(params, successCallback, errorCallback) {
    AdminResource.updateCategory(params).then(
      function (categoryDetails) {
        if (successCallback) {
          successCallback(categoryDetails);
        }
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function createServiceVariation(params, successCallback, errorCallback) {
    AdminResource.createServiceVariation(params).then(
      function (response) {
        if (successCallback) {
          successCallback(response);
        }
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function getAllStylists(params, successCallback, errorCallback) {
    AdminResource.getAllStylists(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function updateServiceVariation(params, successCallback, errorCallback) {
    AdminResource.updateServiceVariation(params).then(
      function (response) {
        if (successCallback) {
          successCallback(response);
        }
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function deleteServiceVariation(params, successCallback, errorCallback) {
    AdminResource.deleteServiceVariation(params).then(
      function (response) {
        if (successCallback) {
          successCallback(response);
        }
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function getAdminCoupons(params, successCallback, errorCallback) {
    AdminResource.getAdminCoupons(params).then(
      function (response) {
        if (successCallback) {
          successCallback(response);
        }
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function createAdminCoupon(params, successCallback, errorCallback) {
    AdminResource.createAdminCoupon(params).then(
      function (response) {
        if (successCallback) {
          successCallback(response);
        }
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function showAdminCoupon(params, successCallback, errorCallback) {
    AdminResource.showAdminCoupon(params).then(
      function (response) {
        if (successCallback) {
          successCallback(response);
        }
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function deleteAdminCoupon(params, successCallback, errorCallback) {
    AdminResource.deleteAdminCoupon(params).then(
      function (response) {
        if (successCallback) {
          successCallback(response);
        }
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function updateAdminCoupon(params, successCallback, errorCallback) {
    AdminResource.updateAdminCoupon(params).then(
      function (response) {
        if (successCallback) {
          successCallback(response);
        }
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function getAllBookings(params, successCallback, errorCallback) {
    AdminResource.getAllBookings(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function showOperationalTiming(params, successCallback, errorCallback) {
    AdminResource.showOperationalTiming(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createOperationalTiming(params, successCallback, errorCallback) {
    AdminResource.createOperationalTiming(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateOperationalTiming(params, successCallback, errorCallback) {
    AdminResource.updateOperationalTiming(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createGallery(params, successCallback, errorCallback) {
    AdminResource.createGallery(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function showNonWorkingSlots(params, successCallback, errorCallback) {
    AdminResource.showNonWorkingSlots(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createNonWorkingSlots(params, successCallback, errorCallback) {
    AdminResource.createNonWorkingSlots(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateNonWorkingSlots(params, successCallback, errorCallback) {
    AdminResource.updateNonWorkingSlots(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeNonWorkingSlots(params, successCallback, errorCallback) {
    AdminResource.removeNonWorkingSlots(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function operatorsOccupancyByDate(params, successCallback, errorCallback) {
    AdminResource.operatorsOccupancyByDate(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getDailyRevenueStats(params, successCallback, errorCallback) {
    AdminResource.getDailyRevenueStats(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getNewUserStats(params, successCallback, errorCallback) {
    AdminResource.getNewUserStats(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getRecurringUserStats(params, successCallback, errorCallback) {
    AdminResource.getRecurringUserStats(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function initiateBankDetailsCreation(params, successCallback, errorCallback) {
    AdminResource.initiateBankDetailsCreation(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        console.log("error", error);
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function generatorLoginTokenForOperator(
    params,
    successCallback,
    errorCallback
  ) {
    AdminResource.generatorLoginTokenForOperator(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function generatorLoginTokenForEmployee(
    params,
    successCallback,
    errorCallback
  ) {
    AdminResource.generatorLoginTokenForEmployee(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOrders(params, successCallback, errorCallback) {
    AdminResource.getOrders(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOrdersByOrderId(params, successCallback, errorCallback) {
    AdminResource.getOrdersByOrderId(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateOrderStatus(params, successCallback, errorCallback) {
    AdminResource.updateOrderStatus(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAllAppointments(params, successCallback, errorCallback) {
    AdminResource.getAllAppointments(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getStylistStats(params, successCallback, errorCallback) {
    AdminResource.getStylistStats(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAppointmentCountStats(params, successCallback, errorCallback) {
    AdminResource.getAppointmentCountStats(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getLoyaltyConfigInfo(params, successCallback, errorCallback) {
    AdminResource.getLoyaltyConfigInfo(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createLoyaltyConfig(params, successCallback, errorCallback) {
    AdminResource.createLoyaltyConfig(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createLoyaltyFormula(params, successCallback, errorCallback) {
    AdminResource.createLoyaltyFormula(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getLoyaltyFormula(params, successCallback, errorCallback) {
    AdminResource.getLoyaltyFormula(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function deleteLoyaltyFormula(params, successCallback, errorCallback) {
    AdminResource.deleteLoyaltyFormula(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function updateLoyaltyFormula(params, successCallback, errorCallback) {
    AdminResource.updateLoyaltyFormula(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createSegment(params, successCallback, errorCallback) {
    AdminResource.createSegment(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function listSegments(params, successCallback, errorCallback) {
    AdminResource.listSegments(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateSegment(params, successCallback, errorCallback) {
    AdminResource.updateSegment(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeSegment(params, successCallback, errorCallback) {
    AdminResource.removeSegment(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createSegmentRule(params, successCallback, errorCallback) {
    AdminResource.createSegmentRule(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function listSegmentRules(params, successCallback, errorCallback) {
    AdminResource.listSegmentRules(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateSegmentRule(params, successCallback, errorCallback) {
    AdminResource.updateSegmentRule(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeSegmentRule(params, successCallback, errorCallback) {
    AdminResource.removeSegmentRule(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getPriceRule(params, successCallback, errorCallback) {
    AdminResource.getPriceRule(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getPriceRules(params, successCallback, errorCallback) {
    AdminResource.getPriceRules(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createPriceRule(params, successCallback, errorCallback) {
    AdminResource.createPriceRule(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function deletePriceRule(params, successCallback, errorCallback) {
    AdminResource.deletePriceRule(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updatePriceRule(params, successCallback, errorCallback) {
    AdminResource.updatePriceRule(params).then(
      function (categoryDetails) {
        if (successCallback) {
          successCallback(categoryDetails);
        }
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function getPriceRuleDetails(params, successCallback, errorCallback) {
    AdminResource.getPriceRuleDetails(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createItemPromotion(params, successCallback, errorCallback) {
    AdminResource.createItemPromotion(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function listItemPromotions(params, successCallback, errorCallback) {
    AdminResource.listItemPromotions(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function showItemPromotion(params, successCallback, errorCallback) {
    AdminResource.showItemPromotion(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateItemPromotion(params, successCallback, errorCallback) {
    AdminResource.updateItemPromotion(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeItemPromotion(params, successCallback, errorCallback) {
    AdminResource.removeItemPromotion(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    galleryMediaItems: galleryMediaItems,
    createGallery: createGallery,
    fetchGallaryDetails: fetchGallaryDetails,
    uploadStoreImage: uploadStoreImage,
    deleteStoreImage: deleteStoreImage,
    createCategory: createCategory,
    updateCategory: updateCategory,
    createServiceVariation: createServiceVariation,
    updateServiceVariation: updateServiceVariation,
    deleteServiceVariation: deleteServiceVariation,
    getAllStylists: getAllStylists,
    getAllBookings: getAllBookings,
    getAdminCoupons: getAdminCoupons,
    createAdminCoupon: createAdminCoupon,
    showAdminCoupon: showAdminCoupon,
    deleteAdminCoupon: deleteAdminCoupon,
    updateAdminCoupon: updateAdminCoupon,
    showOperationalTiming: showOperationalTiming,
    createOperationalTiming: createOperationalTiming,
    updateOperationalTiming: updateOperationalTiming,
    showNonWorkingSlots: showNonWorkingSlots,
    createNonWorkingSlots: createNonWorkingSlots,
    updateNonWorkingSlots: updateNonWorkingSlots,
    removeNonWorkingSlots: removeNonWorkingSlots,
    initiateBankDetailsCreation: initiateBankDetailsCreation,
    operatorsOccupancyByDate: operatorsOccupancyByDate,
    getNewUserStats: getNewUserStats,
    getRecurringUserStats: getRecurringUserStats,
    getDailyRevenueStats: getDailyRevenueStats,
    generatorLoginTokenForOperator: generatorLoginTokenForOperator,
    generatorLoginTokenForEmployee: generatorLoginTokenForEmployee,
    getOrders: getOrders,
    getOrdersByOrderId: getOrdersByOrderId,
    updateOrderStatus: updateOrderStatus,
    getAllAppointments: getAllAppointments,
    getStylistStats: getStylistStats,
    getAppointmentCountStats: getAppointmentCountStats,
    getLoyaltyConfigInfo: getLoyaltyConfigInfo,
    createLoyaltyConfig: createLoyaltyConfig,
    createLoyaltyFormula: createLoyaltyFormula,
    getLoyaltyFormula: getLoyaltyFormula,
    deleteLoyaltyFormula: deleteLoyaltyFormula,
    updateLoyaltyFormula: updateLoyaltyFormula,
    createSegment,
    listSegments,
    updateSegment,
    removeSegment,
    createSegmentRule,
    listSegmentRules,
    updateSegmentRule,
    removeSegmentRule,
    getPriceRule: getPriceRule,
    getPriceRules: getPriceRules,
    createPriceRule: createPriceRule,
    deletePriceRule: deletePriceRule,
    updatePriceRule: updatePriceRule,
    getPriceRuleDetails: getPriceRuleDetails,
    createItemPromotion,
    listItemPromotions,
    showItemPromotion,
    updateItemPromotion,
    removeItemPromotion,
  };
};

export default AdminManager(Utils, AdminResource);
