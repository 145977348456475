import DBBaseManager from "@presto-db-managers/DBBaseManager";
import DBCategoryItemManager from "@presto-db-managers/DBCategoryItemManager";
import _ from "lodash";
import moment from "moment";
import config from "@presto-common/config";
import { Platform } from "react-native";

export default class DBCartItemManager extends DBBaseManager {
  constructor() {
    super();
    this.tableName = "cart_items";
    this.fillables = [
      "_cart_id",
      "_variation_id",
      "id",
      "reference_id",
      "name",
      "quantity",
      "pic_url",
      "other_data",
      "item_id",
      "rate",
      "total_price",
      "total_amount",
      "_sync_status", // choices are: pending, synced
    ];
    this.datatypes = {
      other_data: "JSON",
    };
  }

  async addItem({ _cart_id, item_id, merchant_id, quantity, variation_id }) {
    const obj = new DBCategoryItemManager();
    let item = null;
    if (!_.isEmpty(item_id)) {
      const items = await obj.find(item_id);
      item = _.first(items);
    }

    if (!_.isEmpty(item_id) && !_.isEmpty(variation_id)) {
      item_id = item.id;
      item = _.find(_.get(item, "variations"), ["id", variation_id]);
    }

    if (_.isEmpty(item)) {
      const error = new Error(
        `Item does not exists in category_items\n${JSON.stringify({
          _cart_id,
          item_id,
          merchant_id,
          quantity,
          variation_id,
        })}`
      );
      throw error;
    }

    if (item.stock_count < quantity) {
      const error = new Error(
        `Specified quantities of ${item.name} is not available.`
      );
      throw error;
    }

    let id = this.generateId();
    let rate = _.multiply(obj.getBasePrice(item), 100);
    let qty = Number(quantity);
    let totalPrice = _.multiply(rate, qty);
    let totalAmount = totalPrice;

    if (isNaN(qty) || qty <= 0) {
      const error = new Error("Invalid qty");
      throw error;
    }
    const isWeb = Platform.OS == 'web'
    let columns = [
      "id",
      "_cart_id",
      "_variation_id",
      "item_id",
      "quantity",
      "total_price",
      "total_amount",
      "rate",
    ];
    let joinedColumns = _.join(columns, ", ");
    let placeHolders = _.join(_.fill(Array(columns.length), "?"), ", ");

    let query = `INSERT INTO ${this.tableName} (${joinedColumns}) VALUES (${placeHolders})`;
    let args = [
      id,
      _cart_id,
      variation_id,
      item_id,
      quantity,
      totalPrice,
      totalAmount,
      rate,
    ];
    if (isWeb) return {}
    return this.asyncExec([{ sql: query, args: args }])
      .then(({ rows }) => {
        return _.first(rows);
      })
      .catch((e) => {
        console.error("DBCartItemManager.addItem.asyncExec.error", e);
        throw e;
      });
  }

  updateItem({ _cart_id, line_item_id, merchant_id, quantity }) {
    let query = `UPDATE ${this.tableName} SET quantity=? WHERE _cart_id=? AND id=?;`;
    let args = [quantity, _cart_id, line_item_id];
    const isWeb = Platform.OS == 'web'
    if (isWeb) return {}
    return this.asyncExec({ sql: query, args: args })
      .then(({ rowsAffected, rows }) => {
        if (rowsAffected === 0) {
          const error = new Error(
            `DBCartItemmanager.updateItem failed as not records exists\n${query}\n${args}`
          );
          throw error;
        }
        return _.first(rows);
      })
      .catch((e) => {
        throw e;
      });
  }

  removeItem({ _cart_id, line_item_id }) {
    let query = `DELETE FROM ${this.tableName} WHERE _cart_id=? AND id=? ;`;
    let args = [_cart_id, line_item_id];
    const isWeb = Platform.OS == 'web'
    if (isWeb) return {}
    return this.asyncExec({ sql: query, args: args })
      .then(({ rowsAffected, rows }) => {
        if (rowsAffected === 0) {
          const error = new Error(
            `DBCartItemmanager.removeItem failed as not records exists\n${query}\n${args}`
          );
          throw error;
        }
        return _.first(rows);
      })
      .catch((e) => {
        throw e;
      });
  }

  cartItems({ cart_id }) {
    let query = `SELECT * FROM ${this.tableName} WHERE _cart_id=?;`,
      args = [cart_id];
    const isWeb = Platform.OS == 'web'
    if (isWeb) return {}
    return this.asyncExec({
      sql: query,
      args: args,
    }).then(({ rows }) => {
      let items = _.map(rows, (e) => this.applyDatatypeToObject(e));
      return items;
    });
  }

  clearCartItems(cart_id) {
    let query = `DELETE FROM ${this.tableName} WHERE _cart_id=?;`;
    let args = [cart_id];
    const isWeb = Platform.OS == 'web'
    if (isWeb) return {}
    return this.asyncExec({
      sql: query,
      args: args,
    });
  }

  itemDiscount(item) {
    let val = this.getOldPrice(item) - this.getBasePrice(item);
    if (val <= 0) {
      val = 0;
    }

    return val;
  }

  getOldPrice(item) {
    let val = Number(_.get(item, "other_data.old_price"));
    if (isNaN(val)) {
      val = 0;
    }
    return val;
  }

  getTotalPrice(item) {
    let val = Number(_.get(item, "price.total_price"));
    if (isNaN(val)) {
      val = 0;
    }
    return val;
  }

  getBasePrice(item) {
    let val = Number(_.get(item, "price.base_price"));
    if (isNaN(val)) {
      val = 0;
    }
    return val;
  }
}
