import React, { useContext } from "react";
import { View, StyleSheet } from "react-native";
import PrestoIcon from "@presto-components/PrestoIcon";
import FlexRowSpaceAligned from "../FlexRowSpaceAligned";
import ThemeContext from "@presto-contexts/ThemeContext";
import Pressable from "@presto-components/Pressable/Pressable";
import { useAssets, Components } from "presto-react-components";
const AmountInput = Components.AmountInput;

export default function PlusMinusButton({
  disable,
  onPressMinus,
  onPressPlus,
  count,
  onUpdate,
  variation,
  extraStyle,
  btnExtraStyle,
}) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const styles = getStyle(theme);
  return (
    <FlexRowSpaceAligned
      styleSet={{
        ...styles.plusMinusButtonStyle,
        ...extraStyle,
      }}
    >
      <Pressable disabled={disable} onPress={onPressMinus}>
        <View
          style={{
            width: 60,
            height: 60,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 6,
            ...btnExtraStyle,
          }}
        >
          <PrestoIcon icon={<svgs.Minus primaryColor={theme.primary} />} />
        </View>
      </Pressable>

      <View
        style={{
          width: 45,
          height: 34,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
        }}
      >
        <AmountInput
          size={40}
          amount={count}
          textSize={16}
          onUpdate={(amount) => onUpdate(amount)}
          textColor={theme.primary}
          borderColor={theme.primary}
          backgroundColor={"white"}
          onBlur={(amount) => onUpdate(amount)}
        />
      </View>
      <Pressable disabled={disable} onPress={onPressPlus}>
        <View
          style={{
            width: 60,
            height: 60,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 6,
            ...btnExtraStyle,
          }}
        >
          <PrestoIcon icon={<svgs.Plus primaryColor={theme.primary} />} />
        </View>
      </Pressable>
    </FlexRowSpaceAligned>
  );
}

function getStyle(theme) {
  return {
    plusMinusButtonStyle: {
      paddingVertical: 5,
      justifyContent: "space-around",
      alignItems: "center",
      height: 60,
      width: 200,
      borderColor: theme.primary,
      borderWidth: 1,
      borderRadius: 20,
    },
  };
}
