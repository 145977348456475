import React, { useContext } from "react";
import { View, FlatList, Pressable, Image } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import _ from "lodash";
import moment from "moment";

export default function UserTable({
  permissionChoices,
  items,
  onEndReached,
  activeRow,
  onPressRow,
}) {
  const { theme } = useContext(ThemeContext);
  const tableColumns = [
    {
      title: "",
      width: "10%",
      thType: "highlighted",
    },
    {
      title: I18n.t("screen_messages.common.name_text"),
      width: "25%",
    },
    {
      title: I18n.t("screen_messages.policy_permissions.title2"),
      width: "65%",
    },
  ];

  const RenderItemHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          paddingVertical: 5,
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: theme.screenBackgroundColor,
        }}
      >
        {_.map(tableColumns, (column, index) => {
          let viewStyles = {
            height: 50,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          };

          let textProps = {
            extraStyle: { paddingLeft: 20 },
            color: theme.paragraph,
            fontStyle: "400.bold",
            size: 13,
          };

          if (column.thType === "highlighted") {
            viewStyles = {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            };
            textProps = {
              extraStyle: { paddingLeft: 20 },
              color: theme.black,
              fontStyle: "400.bold",
              size: 16,
            };
          }

          if (column.title == "Amount") {
            viewStyles = {
              ...viewStyles,
              alignItems: "center",
            };
          }

          return (
            <View
              key={index}
              style={{
                ...viewStyles,
                width: column.width,
              }}
            >
              <PrestoText {...textProps}>{column.title}</PrestoText>
            </View>
          );
        })}
      </View>
    );
  };

  const renderPermission = (item, isActive) => {
    let permissions = _.get(item, "other_data.permissions");

    if (_.isEmpty(permissions)) {
      return null;
    }

    let components = [];

    _.forEach(permissions, (value, key) => {
      if (value === false) {
        return;
      }

      let permission = _.find(permissionChoices, ["id", key]);

      if (permission) {
        components.push(
          <View key={key} style={{ paddingRight: 10 }}>
            {permission.renderIcon({
              color: isActive ? theme.white : theme.primary,
            })}
          </View>
        );
      }
    });

    return components;
  };

  const renderItem = ({ item, index }) => {
    const picUrl = item.pic_url;
    const name = item.name;
    const email = item.email;
    const phoneNumber = item.phone_number;

    const isActive = _.get(activeRow, "id") === item.id;
    const bgColor = isActive ? theme.primary : theme.white;
    const textColor1 = isActive ? theme.white : theme.primary;
    const textColor2 = isActive ? theme.white : theme.darkText;

    return (
      <Pressable
        key={`${item.id}-${index}`}
        onPress={() => onPressRow(item)}
        style={{
          width: "100%",
          backgroundColor: bgColor,
          borderRadius: 15,
          paddingHorizontal: theme.primaryPadding,
          paddingVertical: 10,
          flexDirection: "row",
          marginBottom: 5,
        }}
      >
        <View
          style={{
            width: "10%",
            flexDirection: "column",
          }}
        >
          <View style={{ width: 40, height: 40 }}>
            {picUrl ? (
              <Image
                source={{ uri: picUrl }}
                style={{ width: "100%", height: "100%" }}
                resizeMode="contain"
              />
            ) : null}
          </View>
        </View>

        <View
          style={{
            width: `25%`,
            paddingRight: 15,
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
          }}
        >
          <View>
            <PrestoText
              extraStyle={{}}
              color={textColor1}
              fontStyle="600.semibold"
            >
              {name}
            </PrestoText>
          </View>
          <View>
            <PrestoText color={textColor2} fontStyle="400.normal">
              {email}
            </PrestoText>
          </View>
        </View>

        <View
          style={{
            width: `70%`,
            flexDirection: "row",
            paddingLeft: 15,
          }}
        >
          {renderPermission(item, isActive)}
        </View>
      </Pressable>
    );
  };

  return (
    <View>
      <FlatList
        stickyHeaderIndices={[0]}
        keyExtractor={(item, index) => `${item.id}-${index}`}
        data={items}
        renderItem={renderItem}
        ListHeaderComponent={() => {
          return <RenderItemHeader />;
        }}
        onEndReached={onEndReached}
      />
    </View>
  );
}
