import React, { useEffect, useMemo, useState } from "react";
import { View, TextInput } from "react-native";
import _ from "lodash";

export default function PrestoTextInput(props) {
  const { theme, backgroundColor, style, editable } = props;
  const styles = useMemo(() => {
    return getStyles(theme, backgroundColor, style, props);
  }, [theme, style]);

  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onFocus = () => {
    if (props.onFocus) {
      props.onFocus();
    }
  };

  const onBlur = () => {
    if (props.onBlur) {
      props.onBlur(value);
    }
  };

  const onChange = ({ nativeEvent: { text } }) => {
    setValue(text);
  };

  return (
    <View style={styles.container}>
      <View style={{ ...styles.textinputContainer }}>
        <TextInput
          editable={editable ? editable : true}
          underlineColorAndroid="transparent"
          autoCapitalize={props.autoCapitalize || "none"}
          spellCheck={false}
          autoCorrect={props.autoCorrect || false}
          keyboardType={props.keyboardType || "default"}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          multiline={props.multiline}
          placeholderTextColor={
            props.textInputPlaceholderColor || styles.placeHolder.textColor
          }
          onChange={onChange}
          {...props}
          onBlur={onBlur}
          onFocus={onFocus}
          textContentType={props.textContentType || "none"}
          returnKeyType={props.returnKeyType || "done"}
          style={{ ...styles.textInputStyle }}
          importantForAutofill={props.importantForAutofill || "yes"}
        />
      </View>
    </View>
  );
}

const getStyles = (theme, backgroundColor, style, props) => {
  return {
    container: {
      width: "100%",
    },
    textInputStyle: {
      width: style?.textInputWidth ? style.textInputWidth : "100%",
      height: style?.textInputHeight ? style.textInputHeight : 50,
      borderWidth: style?.borderWidth || 1,
      borderRadius: style?.borderRadius
        ? style?.borderRadius
        : theme.textInputBorderRadius
        ? theme.textInputBorderRadius
        : 25,
      fontSize: style?.textInputSize || 16,
      fontFamily: style?.fontFamily || theme.primaryMediumFont,
      paddingHorizontal:
        style?.textInputPaddingHorizontal || theme.textInputBorderRadius || 25,
      color: style?.textInputColor || theme.textInputColor,
      backgroundColor: backgroundColor || theme.textInputBgColor,
      borderColor: style?.borderColor || theme.textInputBgColor,
      textAlignVertical: props.textAlignVertical || "center",
      ...style?.textInputStyle,
    },
    textinputContainer: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      height: style?.textInputHeight ?? theme.textInputHeight,
      borderRadius: theme.textInputBorderRadius || 25,
      borderColor: theme.textInputBorderColor || "#CCCCCC",
    },
    placeHolder: {
      textColor: theme.placeHolderColor,
    },
  };
};
