import React, { useState, useEffect, useContext } from "react";
import { View, StyleSheet, TextInput, Pressable } from "react-native";
import _ from "lodash";
import * as RNLocalize from "react-native-localize";
import PrestoText from "@presto-components/PrestoText";
import PrestoIcon from "@presto-components/PrestoIcon";
import { useAssets } from "presto-react-components";
import ThemeContext from "@presto-contexts/ThemeContext";

function count(str, ch) {
  return _.countBy(str)[ch] || 0;
}

export default function PrestoAmountInputWithPlusMinus(props) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const settings = RNLocalize.getNumberFormatSettings();
  const separator = settings.decimalSeparator || ".";
  let [amount, setAmount] = useState(props.value || "");

  useEffect(() => {
    setAmount(props.value);
  }, [props.value]);

  const setAmountValue = (val) => {
    if (val >= 0) {
      setAmount(val);
      props.onAmountChange(val);
    }
  };

  const onTextChange = (text) => {
    text = _.replace(text, separator, ".");
    console.log("Text: ", text, count(text, "."), _.findLastIndex(text, "."));
    if (_.isNaN(+text)) {
      setAmountValue(text.substring(0, text.length - 1));
      return;
    }
    if (text[text.length - 1] === "." && count(text, ".") > 1) {
      setAmountValue(text.substring(0, text.length - 1));
      return;
    }

    if (
      _.lastIndexOf(text, ".") !== -1 &&
      text.length - _.lastIndexOf(text, ".") > 3
    ) {
      setAmountValue(text.substring(0, text.length - 1));
      return;
    }
    setAmountValue(text);
  };

  return (
    <View style={defaultStyles.container}>
      <View style={{ flexDirection: "row" }}>
        <Pressable
          onPress={() => {
            setAmountValue(amount - 1);
          }}
        >
          <View
            style={{
              width: 40,
              height: 40,
            }}
          >
            <PrestoIcon
              icon={
                <svgs.MinusIcon
                  color={props.selected ? theme.white : theme.primary}
                />
              }
            />
          </View>
        </Pressable>

        <TextInput
          underlineColorAndroid="transparent"
          autoCapitalize={props.autoCapitalize || "none"}
          autoCompleteType={props.autoCompleteType || "off"}
          spellCheck={false}
          autoCorrect={props.autoCorrect || false}
          keyboardType={props.keyboardType || "default"}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          multiline={props.multiline}
          placeholderTextColor={"#7B7B7B"}
          {...props}
          value={_.replace(amount, ".", separator)}
          onChangeText={onTextChange}
          textAlign="center"
          fontSize={10}
          style={
            props.textInputStyle || {
              ...defaultStyles.textInputStyle,
            }
          }
        ></TextInput>
        <Pressable
          onPress={() => {
            if (!amount) {
              setAmountValue(1);
            } else {
              setAmountValue(parseInt(amount) + 1);
            }
          }}
        >
          <View
            style={{
              width: 40,
              height: 40,
            }}
          >
            <PrestoIcon
              icon={
                <svgs.PlusIcon
                  primaryColor={props.selected ? theme.white : theme.primary}
                />
              }
            />
          </View>
        </Pressable>
      </View>
    </View>
  );
}
const defaultStyles = {
  textInputStyle: {
    width: 40,
    borderWidth: 1,
    borderRadius: 4,
    paddingLeft: 5,
    paddingRight: 5,
    paddingVertical: 0,
    borderColor: "#aaaaaa",
    backgroundColor: "#ffffff",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    textInputSize: 10,
  },
};
