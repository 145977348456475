import React, { useContext } from "react";
import {
  View,
  Dimensions,
  TouchableOpacity,
  Platform,
  Pressable,
} from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import CartContext from "@presto-contexts/CartContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useSafeArea } from "react-native-safe-area-context";
import _ from "lodash";
import utils from "../../utils/index";
import UserContext from "@presto-contexts/UserContext";
import I18n from "i18n-js";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import PrestoText from "@presto-components/PrestoText";
import Draggable from "react-native-draggable";

const windowWidth = Dimensions.get("window").width;
let isWebMobileScreen = windowWidth < 600 ? true : false;

export default function FloatingCart({
  route,
  containerBottom,
  componentProps = {},
  floatingCartProps,
}) {
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const { theme } = useContext(ThemeContext);
  const { cart, getItemCount, getTotalPrice, clearCart } = useContext(
    CartContext
  );
  const { Navigator } = useContext(NavigatorContext);
  const { user } = useContext(UserContext);
  const insets = useSafeArea();
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  const isIOS = Platform.OS === "ios";
  const isWebMobileScreen = Platform.OS === "web" && windowWidth < 940;
  let doesCartExists = cart != null && !_.isEmpty(cart.items);

  const onOrder = () => {
    if (!user) {
      showLogin();
      return;
    } else {
      setPaneState(true);
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "SIDE_NAVIGATION",
          screenParams: {
            forwardProps: componentProps,
          },
        },
      });
    }
  };

  const showLogin = () => {
    setPaneState(true);
    Navigator.emit({
      event: "modal",
      params: { screenName: "LOGIN" },
    });
  };

  const getItemsCount = () => {
    if (doesCartExists) {
      return getItemCount();
    } else {
      if (_.isEmpty(floatingCartProps)) return "";
      return floatingCartProps?.count;
    }
  };

  const getTotalItemsPrice = () => {
    if (doesCartExists) {
      return getTotalPrice();
    } else {
      if (_.isEmpty(floatingCartProps)) return "";
      return floatingCartProps?.price * 100;
    }
  };

  return (
    <View
      style={{
        backgroundColor: theme.primary,
        borderRadius: 15,
        width: 190,
        position: utils.isMobile()
          ? "absolute"
          : isWebMobileScreen
          ? "fixed"
          : "absolute",
        right: 20,
        bottom: containerBottom || insets.bottom + 10,
        flexDirection: "column",
        padding: 10,
        zIndex: 100,
      }}
    >
      <PrestoText color={theme.white} size={14} fontStyle="500.normal">
        {I18n.t("screen_messages.cart.floating_cart_count", {
          count: getItemsCount(),
        })}
      </PrestoText>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PrestoText fontStyle="400.normal" color={theme.white} size={14}>
          {utils.formattedCurrencyCents(getTotalItemsPrice())}
        </PrestoText>
        <Pressable onPress={() => onOrder()}>
          <View
            style={{
              borderWidth: 1,
              borderColor: theme.white,
              borderRadius: 10,
              height: 32,
              minWidth: 20,
              paddingHorizontal: 5,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoText
              fontStyle="400.normal"
              color={theme.white}
              size={14}
              extraStyle={{ textTransform: "uppercase" }}
            >
              {I18n.t("screen_messages.orders.title_singular_space")}
            </PrestoText>
          </View>
        </Pressable>
      </View>
    </View>
  );
}
