import React from "react";
import I18n from "i18n-js";
import { ItemCards } from "presto-react-components";

export default function ItemCardPos(props) {
  let forwardProps = {
    ...props,
    offersCount: I18n.t("screen_messages.count_offers", {
      count: props?.product?.offersCount,
    }),
  };
  if (props.forPurchases) {
    return <ItemCards.ItemCard11 {...forwardProps} />;
  } else if (props.forMasterCatalog) {
    return <ItemCards.ItemCard12 {...forwardProps} />;
  } else {
    return <ItemCards.ItemCard13 {...forwardProps} />;
  }
}
