import Utils from "@presto-services/common/Utils";
import SearchManager from "@presto-services/features/search/SearchManager";
import _ from "lodash";
import { isOfflineEnabledAPI } from "@presto-common/OfflineAPIsConfig";
import DBCategoryItemManager from "@presto-db-managers/DBCategoryItemManager";

function fuzzySearchCategoryItem(params, successCallback, errorCallback) {
  let newParams = _.cloneDeep(params);
  let enableOffline = false;
  if (params.enableOffline) {
    enableOffline = params.enableOffline;
    newParams = _.omit(newParams, "enableOffline");
  }

  if (
    isOfflineEnabledAPI("categoryItems", params.excludeOfflineEnabledAPI) ||
    enableOffline
  ) {
    const obj = new DBCategoryItemManager();
    obj.filter(newParams).then((items) => {
      const response = obj.mockAsSearchServerAPI(items.items, items.totalCount);
      successCallback(response.data);
    });
  } else {
    SearchManager.fuzzySearchCategoryItem(
      newParams,
      successCallback,
      errorCallback
    );
  }
}

function searchCategoryItem(params, successCallback, errorCallback) {
  let newParams = _.cloneDeep(params);
  let enableOffline = false;
  if (params.enableOffline) {
    enableOffline = params.enableOffline;
    newParams = _.omit(newParams, "enableOffline");
  }

  if (
    isOfflineEnabledAPI("categoryItems", params.excludeOfflineEnabledAPI) ||
    enableOffline
  ) {
    const obj = new DBCategoryItemManager();
    obj.filter(newParams).then((items, totalCount) => {
      const response = obj.mockAsSearchServerAPI(items.items, totalCount);
      successCallback(response.data);
    });
  } else {
    SearchManager.searchCategoryItem(newParams, successCallback, errorCallback);
  }
}

export default {
  fuzzySearchCategoryItem,
  searchCategoryItem,
};
