import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { Dimensions, TouchableWithoutFeedback, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { useMediaQuery } from "react-responsive";
import DropShadow from "@presto-components/DropShadow/DropShadow";

export default function DefaultModal({ children, goBack, height, bgColor }) {
  const { theme } = useContext(ThemeContext);
  const [close, setClose] = useState(close);
  const isDesktop = useMediaQuery({ minWidth: 941 });
  const fromSpring = { margin: 0 };
  const toSpring = { margin: isDesktop ? 1000 : 600 };
  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;

  const getTopOffsetSpacing = () => {
    let val = 0;
    if (height) {
      if (_.isString(height)) {
        val = `${100 - parseInt(height)}%`;
      } else {
        val = windowHeight - parseInt(height);
      }
    }
    return val;
  };

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <TouchableWithoutFeedback onPress={goBack}>
        <View
          style={{
            height: getTopOffsetSpacing(),
          }}
        ></View>
      </TouchableWithoutFeedback>

      <DropShadow
        style={{
          ...theme.primaryTopShadow,
          flex: 1,
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
        }}
      >
        <View
          style={{
            backgroundColor: bgColor ? bgColor : theme.appBackgroundColor,
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            flex: 1,
            width: windowWidth,
            ...theme.lightShadow,
          }}
        >
          <ScrollView
            contentContainerStyle={{
              height: "100%",
            }}
          >
            {children}
          </ScrollView>
        </View>
      </DropShadow>
    </View>
  );
}
