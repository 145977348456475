const fonts = {
  primaryFont: "Montserrat-Regular",
  primaryMediumFont: "Montserrat-Medium",
  primarySemiBoldFont: "Montserrat-SemiBold",
  primaryBoldFont: "Montserrat-Bold",
};

const colors = {
  primary: "#53C175",
  secondary: "#FF3353",
  tertiary: "#FFFFFF",
  black: "#000",
  white: "#fff",
  light: "#fefefe",
  dark: "#333333",
  success: "green",
  error: "red",
  warning: "orange",
  marketing: "blue",
  gray: "#A9A9A9",
};

const textColor = {
  title: "#000000",
  header: "#707070",
  subHeader: "#A9A9A9",
  paragraph: "#848484",
  lightText: "#CCCCCC",
  darkText: "#333333",
  lightdark: "#E3E3E3",
  // buttons Color
  primaryButtonText: "#FFFFFF",
  secondaryButtonText: "#FFFFFF",
  tertiaryButtonText: "#FFFFFF",
  tertiaryButtonColor: "#D5D5D5",
  topHeaderInactiveColor: "#737682",
};

const textInputStyles = {
  textInputHeight: 64,
  textInputBorderRadius: 8,
};

const constantStyles = {
  cardRadius: 12,
};

const backgroundColor = {
  //only for model background
  modalBackgroundColor: "#FFFFFF",
  //only for APP background
  appBackgroundColor: "#FFFFFF",
  //only for card background
  cardBackgroundColor: "#FFFFFF",
  screenBackgroundColor: "#D8D8D8",
  createItemBgColor: "#3e4152",
  extraGrey: "#aaaaaa",
};

const shadowStyles = {
  lightShadow: {
    shadowColor: "rgba(0, 0, 0, 0.5)",
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.1,
    shadowRadius: 20,
    elevation: 5,
  },
  darkShadow: {
    shadowColor: "#000000",
    shadowOffset: {
      width: 3,
      height: 2,
    },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 10,
  },
  primaryTopShadow: {
    shadowColor: "rgba(0,0,0,0.5)",
    shadowOffset: {
      width: 4,
      height: -4,
    },
    shadowOpacity: 0.35,
    shadowRadius: 10,
    elevation: 5,
  },
};

const buttonStyles = {
  "solid-button-styles": {
    primary: {
      color: colors.primaryButtonText,
      backgroundColor: colors.primary,
      fontStyle: "600.semibold",
    },
    secondary: {
      color: colors.secondaryButtonText,
      backgroundColor: colors.secondary,
      fontStyle: "600.semibold",
    },
    tertiary: {
      color: colors.tertiaryButtonText,
      backgroundColor: textColor.tertiaryButtonColor,
      fontStyle: "600.semibold",
    },
  },
};

const buttonVariations = {
  "extra-large": {
    height: 50,
    fontSize: 18,
    borderRadius: 8,
    borderWidth: 1,
  },
  large: {
    height: 50,
    fontSize: 18,
    borderRadius: 8,
    borderWidth: 1,
  },
};

const themes = (svgs, images) => {
  return {
    ...fonts,
    ...colors,
    ...backgroundColor,
    ...textColor,
    ...textInputStyles,
    ...buttonStyles,
    ...buttonVariations,
    ...shadowStyles,
    ...constantStyles,
    name: "light",

    get secondaryTextFitButtonVerticalPadding() {
      return 20;
    },
    get notifyMeButtonFontSize() {
      return 10;
    },
  };
};
export default themes;
