import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { View, ScrollView, Platform } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import moment from "moment";
import _ from "lodash";
import { usePagination } from "@presto-hooks/appointmentPagination";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import SalesTable from "@presto-screen-components/SalesReports/SalesTable";
import AdvanceSearchBar from "@presto-screen-components/SalesReports/AdvanceSearchBar";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoText from "@presto-components/PrestoText";
import StylistManager from "@presto-services/features/pos_stylist/StylistManager";
import asyncify from "@presto-common/Asyncify";
import UserContext from "@presto-contexts/UserContext";
import { alertBox } from "@presto-common/Alert";
import LoadingContainer from "@presto-components/LoadingContainer";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import HeaderWithTabs from "@presto-screen-components/Headers/HeaderWithTabs";
import { WebView } from "react-native-webview";
import PrestoSdk from "../../services/global/PrestoSdk";
const AsyncStylistManager = asyncify(StylistManager);

export default function InsightsReports({ navigation, route }) {
  useEffect(() => {
    init();
  }, []);
  const isWeb = Platform.OS == 'web'

  const init = () => { };
  const [loading, setLoading] = useState(false);
  const { theme } = useContext(ThemeContext);

  const insets = useSafeAreaInsets();

  const [tabs, setTabs] = useState(
    _.compact([
      {
        id: "insights",
        text: "Insights",
        defaultStyles: {
          paddingLeft: 25,
          paddingRight: 25,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
        isActive: true,
      },
    ])
  );

  return (
    <LoadingContainer
      bgColor={"transparent"}
      loading={loading}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        marginTop: insets.top,
      }}
    >
      <HeaderWithTabs
        isActiveStyles={{
          backgroundColor: "#D8D8D8",
        }}
        tabs={tabs}
      />
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          height: "100%",
        }}
      >
        <View
          style={{
            backgroundColor: theme.screenBackgroundColor,
            flex: 1,
          }}
        >
          {
            isWeb
              ? <iframe
                src={`${"https://delivery.presto-apps.com/qs_embedded/index.html?merchant_id=" + PrestoSdk.getMerchantId()}`}
              />
              : <WebView
                source={{
                  uri:
                    "https://delivery.presto-apps.com/qs_embedded/index.html?merchant_id=" +
                    PrestoSdk.getMerchantId(),
                }}
                style={{ flex: 1 }}
              ></WebView>
          }
        </View>
      </View>
    </LoadingContainer>
  );
}
