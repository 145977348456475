import React, { useContext, useState, useEffect } from "react";
import { View, StyleSheet, TextInput, Pressable, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import { Icon, IconButton } from "@presto-components/PrestoButton";
import DateTimePicker from "@presto-components/CalendarComponent/DateTimePicker";
import moment from "moment";
import { useAssets } from "presto-react-components";

export default function TextInputWithDateAndButton(props) {
  const isWeb = Platform.OS == 'web'
  const { svgs } = useAssets();
  const {
    textPlaceholder,
    datePlaceHolder,
    text,
    date,
    onPress,
    onChangeText,
    onChangeDate,
    hideButton = false,
    editable = true,
    disablePlusButton = false,
    textInputContainerStyle = {},
  } = props;
  const [textInputText, setTextInputText] = useState("");
  const [showDate, setShowDate] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme, props);

  useEffect(() => {
    setTextInputText(text);
  }, [text]);

  useEffect(() => {
    setDateValue(date);
  }, [date]);

  const dateChanged = (date_object) => {
    setDateValue(date_object);
    setShowDate(false);
    if (onChangeDate) {
      onChangeDate(date_object);
    }
  };

  const onShowDate = () => {
    setShowDate(!showDate);
    if (props.onShowDate) {
      props.onShowDate();
    }
  };

  const onCancel = () => {
    setShowDate(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const DateComponent = () => {
    return (
      <View>
        <DateTimePicker
          selectedDate={date}
          onDateChange={dateChanged}
          modal={showDate}
          mode="date"
          onCancel={onCancel}
          customInputProps={{
            containerProps: {
              style: {
                width: "100%",
                paddingLeft: 10,
                height: "100%",
                borderRadius: 8,
                backgroundColor: "white",
                borderWidth: 1,
                borderColor: "black"
              },
            },
            component: (
              <View
                style={{
                  height: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: 50,
                  paddingRight: 10
                }}
              >
                <PrestoText fontStyle="400.normal" size={12} color="#979797">
                  {textPlaceholder}
                </PrestoText>
                <PrestoText fontStyle="400.normal" size={12} color="#979797">
                  {moment(date).format("DD MMM YYYY")}
                </PrestoText>
              </View>
            ),
          }}
        />
      </View>
    );
  };

  const formatDate = () => {
    return dateValue instanceof Date
      ? moment(dateValue.toISOString()).format("D MMM yyy")
      : datePlaceHolder;
  };

  return (
    <>
      {
        isWeb ? DateComponent()
          : <View style={styles.container}>
            <View style={styles.inner}>
              <View style={[styles.textInputContainer, { width: "45%" }]}>
                <TextInput
                  editable={editable}
                  underlineColorAndroid="transparent"
                  autoCorrect={true}
                  keyboardType={"default"}
                  value={textInputText}
                  placeholder={textPlaceholder}
                  placeholderTextColor="#979797"
                  onChangeText={(text) => {
                    setTextInputText(text);
                    if (onChangeText) {
                      onChangeText(text);
                    }
                  }}
                  style={styles.textInputStyle}
                />
              </View>
              <View
                style={[
                  styles.textInputContainer,
                  {
                    width: "30%",
                    borderLeftWidth: 1,
                    borderColor: "#979797",
                    ...textInputContainerStyle,
                  },
                ]}
              >
                <Pressable
                  onPress={() => {
                    onShowDate();
                  }}
                  style={{
                    width: "100%",
                    paddingLeft: 10,
                    height: "100%",
                  }}
                >
                  <View
                    style={{
                      height: "100%",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <PrestoText fontStyle="400.normal" size={12} color="#979797">
                      {formatDate()}
                    </PrestoText>
                  </View>
                </Pressable>
              </View>
              {!hideButton ? (
                <View
                  style={{
                    width: "25%",
                    height: "100%",
                    backgroundColor: theme.primary,
                    borderTopRightRadius: 6,
                    borderBottomRightRadius: 6,
                    opacity: disablePlusButton ? 0.4 : 1,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <IconButton
                      borderColor="transparent"
                      icon={<svgs.PlusIconBuposOperator color={theme.white} />}
                      onPress={() =>
                        !disablePlusButton
                          ? onPress(textInputText, dateValue)
                          : null
                      }
                    />
                  </View>
                </View>
              ) : null}
            </View>
          </View>
      }
    </>
  );
}

function getStyles(theme, props) {
  const { containerStyle = {} } = props;
  return {
    container: {
      flex: 1,
      width: "100%",
      borderColor: theme.tertiary,
      height: 53,
      backgroundColor: theme.white,
      borderRadius: 8,
      borderWidth: 1,
      ...containerStyle,
    },
    inner: {
      flex: 1,
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    textInputContainer: {},
    textInputStyle: {
      height: "100%",
      width: "100%",
      paddingLeft: 10,
      fontSize: 12,
      color: "#979797",
      fontWeight: "400",
      fontFamily: theme.primaryFont,
    },
  };
}
