import config from "@presto-common/config";
import SearchManager from "@presto-services/features/search/SearchManager";
import _ from "lodash";
import DBCategoryManager from "@presto-db-managers/DBCategoryManager";
import DBCategoryItemManager from "@presto-db-managers/DBCategoryItemManager";
import DBMetaDataManager from "@presto-db-managers/DBMetaDataManager";
import DBCouponManager from "@presto-db-managers/DBCouponManager";
import UserHelper from "@presto-helpers/UserHelper";
import DBEmployeeManager from "@presto-db-managers/DBEmployeeManager";
import DBUserManager from "@presto-db-managers/DBUserManager";
import DBOrderManager from "@presto-db-managers/DBOrderManager";
import LoginHelper from "@presto-helpers/LoginHelper";
import Utils from "@presto-services/common/Utils";
import moment from "moment";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import AsyncStorage from "@react-native-community/async-storage";

export function OfflineSync(user) {
  this.user = user;

  this.canSyncCatalog = async () => {
    const metaData = new DBMetaDataManager();
    const can = await metaData.canResyncCategoriesAndCategoryItems();
    return can;
  };

  this.syncCatalog = async (force = false) => {
    const canSync = await this.canSyncCatalog();
    if (!canSync && !force) {
      return;
    }

    const onSuccess = async (response) => {
      const categories = _.map(_.get(response, "hits.hits"), (e) => e._source);
      const obj = new DBCategoryManager();
      await obj.truncateAll();
      await obj.createOrUpdateMany(categories);
      this.syncCategoryItems(categories);
    };
    const onError = (error) => {
      console.log("OfflineSync->fetchCatalog error", error);
    };
    const merchantId = LoginHelper.getUserMerchantId(this.user);
    SearchManager.searchCategory(
      {
        merchant_id: merchantId,
        isExcludeMerchantId: false,
      },
      onSuccess,
      onError
    );
  };

  this.syncCategoryItems = (categories) => {
    console.log("Category items sycn");
    const onSuccess = async (response) => {
      const data = _.map(_.get(response, "hits.hits"), (e) => e._source);
      for (let item in data) {
        try {
          data[item].zip_supplied = parseInt(
            data[item]?.other_data?.zip_supplied
          );
          if (!data[item].zip_supplied || data[item].zip_supplied === NaN) {
            data[item].zip_supplied = 0;
          }
        } catch (e) {
          data[item].zip_supplied = 0;
        }
      }
      console.log("I will update all now");
      const obj = new DBCategoryItemManager();
      await obj.truncateAll();
      await obj.createOrUpdateMany(data);
      const now = new Date();
      const nowISO = now.toISOString();
      const metaData = new DBMetaDataManager();
      metaData.setLastCategoriesAndCategoryItemsFetchDate(nowISO);
    };
    const onError = (error) => {
      console.log("OfflineSync->fetchCategoryItems error", error);
    };
    const merchantId = LoginHelper.getUserMerchantId(this.user);

    // TODO: Recusively fetch all the records in chunks for 5000
    const size = 5000;
    const categoryIds = _.map(categories, "id");
    SearchManager.fuzzySearchCategoryItem(
      {
        //category_ids: categoryIds,
        merchant_id: merchantId,
        size,
        isExcludeMerchantId: false,
      },
      onSuccess,
      onError
    );
  };

  this.syncUserDumpReportId = async (reportId) => {
    const metaData = new DBMetaDataManager();
    const userDumpObject = new UserHelper.UserDumpObject();
    userDumpObject.sync(reportId).then(async () => {
      const now = new Date();
      const nowISO = now.toISOString();
      await metaData.setLastUserFetchDate(nowISO);
      await metaData.setEmployeeDumpId(null);
    });
  };

  this.syncUserDump = async (force = false) => {
    const metaData = new DBMetaDataManager();
    const can = await metaData.canResyncUsers();
    if (!can && !force) {
      return false;
    }
    await DBUserManager.syncToRemote();
    const userDumpObject = new UserHelper.UserDumpObject();
    const dumpData = await metaData.getUserDumpId();
    if (dumpData?.value) {
      const reportId = dumpData?.value;
      const [error1, response1] = await userDumpObject.listDumps();
      if (response1) {
        const userReport = _.find(response1.data, ["id", reportId]);
        const isCompleted = _.get(userReport, "state") === "completed";
        if (isCompleted) {
          await this.syncUserDumpReportId(reportId);
        }
      } else {
        console.log("OfflineSync->syncUserDump->error1", error1);
      }
    } else {
      userDumpObject.createNewDump().then((report) => {
        if (report?.id) {
          setTimeout(() => {
            this.syncUserDumpReportId(report.id);
          }, 10000);
        }
      });
    }
  };

  this.initialLoadUsers = async () => {
    const userDumpObject = new UserHelper.UserDumpObject();
    const dBUserManager = new DBUserManager();
    const all = await dBUserManager.all();
    if (_.isEmpty(all)) {
      const [error1, response1] = await userDumpObject.listDumps();
      if (response1) {
        const MIN_YEAR_DIFFERENCE = 2;
        let filteredReports = _.filter(response1.data, ["report_type", "User"]);
        filteredReports = Utils.sortItemsByDate(filteredReports, "DESC");

        const userReport = _.find(filteredReports, (e) => {
          const startDate = moment(e.start_date);
          const endDate = moment(e.end_date);
          return endDate.diff(startDate, "years") >= MIN_YEAR_DIFFERENCE;
        });
        const isCompleted = _.get(userReport, "state") === "completed";
        if (isCompleted) {
          const reportId = userReport.id;
          await this.syncUserDumpReportId(reportId);
        } else {
          userDumpObject.createNewDump().then((report) => {
            if (report?.id) {
              setTimeout(() => {
                this.syncUserDumpReportId(report.id);
              }, 10000);
            }
          });
        }
      }
    }
  };

  this.syncCoupons = async (force = false) => {
    const metaData = new DBMetaDataManager();
    const can = await metaData.canResyncCoupons();
    if (!can && !force) {
      return false;
    }
    const bBCouponManager = new DBCouponManager();
    await bBCouponManager.truncateAll();
    await bBCouponManager.syncLocally();
    const now = new Date();
    const nowISO = now.toISOString();
    await metaData.setLastCouponFetchDate(nowISO);
  };

  this.syncEmployees = async (force = false) => {
    const metaData = new DBMetaDataManager();
    const can = await metaData.canResyncEmployees();
    if (!can && !force) {
      return false;
    }
    const bBEmployeeManager = new DBEmployeeManager();
    await bBEmployeeManager.truncateAll();
    bBEmployeeManager.syncLocally().then(async (...args) => {
      const now = new Date();
      const nowISO = now.toISOString();
      await metaData.setlastEmployeesFetchDate(nowISO);
    });
  };

  this.syncFrequentSales = async (force = false) => {
    const metaData = new DBMetaDataManager();
    const can = await metaData.canResyncEmployees();
    if (!can && !force) {
      return false;
    }

    const params = {
      records_per_page: 300,
      start_time: moment().subtract(29, "days").toISOString(),
      end_time: moment().toISOString(),
    };
    const onSuccess = async (response) => {
      console.log("Success -- V1 Orders");
      console.log(response.orders);
      let itemMap = {};
      _.forEach(response.data.orders, (order) => {
        _.forEach(order.items, (item) => {
          if (item.reference_id && itemMap[item.reference_id]) {
            itemMap[item.reference_id] =
              itemMap[item.reference_id] + item.quantity;
          } else if (item.reference_id) {
            itemMap[item.reference_id] = item.quantity;
          }
        });
      });
      console.log(itemMap);
      const sortedKeys = Object.keys(itemMap).sort(
        (a, b) => itemMap[b] - itemMap[a]
      );
      console.log(sortedKeys);
      await AsyncStorage.setItem("FREQUENT_IDS", JSON.stringify(sortedKeys));
      console.log("---- ITEM MAP ------");
    };
    const onError = (error) => {
      console.log("Error ---- V1 Orders");
      console.log(error);
    };
    EmployeeManager.getV1Orders(params, onSuccess, onError);
  };

  this.syncOrders = async (force = false) => {
    if (force) {
      let bBOrderManager = new DBOrderManager();
      bBOrderManager.syncOrders();
    }
  };

  this.syncAll = async (force = false) => {
    let migration_needed = await AsyncStorage.getItem("migration_needed");
    if (migration_needed != "no" && force) {
      AsyncStorage.setItem("migration_needed", "yes");
    }
    await this.syncCatalog(force);
    await this.syncUserDump(force);
    await this.syncCoupons(force);
    await this.syncEmployees(force);
    await this.syncFrequentSales(force);
    await this.syncOrders(force);
  };
}
