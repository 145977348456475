import React, { useContext } from "react";
import {
  View,
  StyleSheet,
  Platform,
  Pressable,
  ScrollView,
} from "react-native";
import { Modal, Portal } from "react-native-paper";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import {
  PrestoSolidButton,
  PrestoFramedButton,
} from "@presto-components/PrestoButton";

export default function ModalMobile({
  visible,
  onDismiss,
  modalBackgroundColor,
  ModelWidth,
  ModelHeight,
  modalContainerStyles,
  content,
  toBottom,
  alertModel,
  alertLeftIcon,
  alertHeading,
  alertMessage,
  alertContent,
  alertRightIcon,
  alertButtomTitle,
  alertButtonOnPress,
  alertHeaderTitleStyles,
  confirmationModal,
  confirmationHeading,
  ConfirmationMessage,
  confirmationButtonTitle,
  buttonOneTitle,
  buttonTwoTitle,
  onButtonOnePressed,
  titleWithRightLink,
  noBorder,
  closeButtonTitle,
  CoupleButton,
  onConfirmationButtonPress,
  cancelbuttonTitle,
  onCancelButtonPressed,
  footerContent,
  ...props
}) {
  const { theme } = useContext(ThemeContext);

  return (
    <View style={{}}>
      <Portal>
        <Modal
          animationType="slide"
          visible={visible}
          onDismiss={onDismiss}
          contentContainerStyle={[
            toBottom
              ? {
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  left: 0,
                  borderTopRightRadius: noBorder ? 0 : 20,
                  borderTopLeftRadius: noBorder ? 0 : 20,
                }
              : null,
            {
              overflow: "hidden",
              backgroundColor: modalBackgroundColor || theme.white,
              width: ModelWidth || "100%",
              height: ModelHeight || "70%",
              padding: 0,
              margin: 0,
              ...modalContainerStyles,
            },
          ]}
        >
          {/* Alert Modal */}
          <View style={{ flex: alertModel ? 1 : 0 }}>
            <ScrollView
              style={{
                flex: 1,
                //padding: theme.containerPadding,
              }}
              contentContainerStyle={{
                paddingVertical: theme.containerPadding,
              }}
            >
              {alertModel ? (
                <>
                  <View style={{ padding: theme.containerPadding }}>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {alertLeftIcon ? (
                        <View
                          style={{
                            marginRight: theme.primaryPadding,
                          }}
                        >
                          {alertLeftIcon}
                        </View>
                      ) : null}
                      {alertHeading ? (
                        <PrestoText
                          fontStyle="600.semibold"
                          size={16}
                          color={theme.rowButtonLeftColor}
                          extraStyle={alertHeaderTitleStyles}
                        >
                          {alertHeading}
                        </PrestoText>
                      ) : null}
                      {alertRightIcon ? (
                        <View
                          style={{
                            marginLeft: theme.primaryPadding,
                          }}
                        >
                          {alertRightIcon}
                        </View>
                      ) : null}
                    </View>
                    {alertMessage ? (
                      <View
                        style={{
                          marginTop: theme.containerPadding + 10,
                          marginHorizontal: 5,
                        }}
                      >
                        <PrestoText
                          fontStyle="500.medium"
                          size={16}
                          color={theme.secondaryTextColor}
                        >
                          {alertMessage}
                        </PrestoText>
                      </View>
                    ) : null}
                    {alertContent ? (
                      <View
                        style={{
                          marginTop: theme.containerPadding + 10,
                        }}
                      >
                        {alertContent}
                      </View>
                    ) : null}
                  </View>
                </>
              ) : null}
              {confirmationModal ? (
                <>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      paddingHorizontal: theme.containerPadding,
                    }}
                  >
                    {alertLeftIcon ? (
                      <View
                        style={{
                          marginRight: theme.primaryPadding,
                        }}
                      >
                        {alertLeftIcon}
                      </View>
                    ) : null}
                    {confirmationHeading ? (
                      <PrestoText
                        fontStyle="600.semibold"
                        size={16}
                        color={theme.rowButtonLeftColor}
                        extraStyle={alertHeaderTitleStyles}
                      >
                        {confirmationHeading}
                      </PrestoText>
                    ) : null}
                    {alertRightIcon ? (
                      <View
                        style={{
                          marginLeft: theme.primaryPadding,
                        }}
                      >
                        {alertRightIcon}
                      </View>
                    ) : null}
                  </View>
                  {titleWithRightLink ? (
                    <View
                      style={{
                        paddingHorizontal: theme.containerPadding,
                        ...props?.titleWithRightLinkStyles,
                      }}
                    >
                      <PrestoText fontStyle="medium">
                        {titleWithRightLink}
                      </PrestoText>
                    </View>
                  ) : null}
                  {ConfirmationMessage ? (
                    <View
                      style={{
                        marginTop: theme.containerPadding + 10,
                      }}
                    >
                      <PrestoText
                        fontStyle="500.medium"
                        size={16}
                        color={theme.secondaryTextColor}
                      >
                        {ConfirmationMessage}
                      </PrestoText>
                    </View>
                  ) : null}
                  {alertContent ? (
                    <View
                      style={{
                        marginTop: theme.containerPadding + 10,
                        paddingHorizontal: theme.containerPadding,
                      }}
                    >
                      {alertContent}
                    </View>
                  ) : null}
                </>
              ) : null}
            </ScrollView>
            {alertModel ? (
              <PrestoSolidButton
                size={"extra-large"}
                buttonStyle="primary"
                title={alertButtomTitle || "Close"}
                onPress={() => alertButtonOnPress() || onDismiss()}
                extraStyle={{
                  padding: theme.primaryPadding,
                  zIndex: 10,
                  ...theme.customeShadow,
                }}
              />
            ) : null}
            {confirmationModal ? (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: theme.primaryPadding,
                  paddingVertical: 15,
                  // marginBottom: theme.primaryPadding,
                  shadowColor: theme.black,
                  shadowOffset: {
                    width: -2,
                    height: 0,
                  },
                  shadowOpacity: 0.2,
                  shadowRadius: 10,
                }}
              >
                {buttonOneTitle ? (
                  <View style={{ width: "50%" }}>
                    <Pressable style={{ marginRight: 10 }}>
                      <PrestoFramedButton
                        buttonStyle="primary"
                        size={"extra-large"}
                        title={buttonOneTitle}
                        titleFontStyle="700.semibold"
                        onPress={() => onButtonOnePressed()}
                      />
                    </Pressable>
                  </View>
                ) : null}

                <View
                  style={{
                    width: buttonOneTitle ? "50%" : "100%",
                  }}
                >
                  <PrestoSolidButton
                    buttonStyle="primary"
                    size={"extra-large"}
                    title={buttonTwoTitle || confirmationButtonTitle}
                    onPress={() =>
                      onConfirmationButtonPress
                        ? onConfirmationButtonPress()
                        : null
                    }
                  />
                </View>
              </View>
            ) : null}
            {/* Content Model */}
          </View>
          {content ? (
            <ScrollView
              style={{ flex: 1 }}
              contentContainerStyle={{
                minHeight: "100%",
                minWidth: "100%",
                backgroundColor: theme.white,
              }}
              showsVerticalScrollIndicator={false}
            >
              {content}
            </ScrollView>
          ) : null}
          {content && closeButtonTitle ? (
            <>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  paddingHorizontal: theme.primaryPadding,
                  paddingVertical: theme.primaryPadding,
                  backgroundColor: "#fff",
                  ...theme.primaryTopShadow,
                }}
              >
                {cancelbuttonTitle ? (
                  <View style={{ width: "50%" }}>
                    <Pressable style={{ marginRight: 10 }}>
                      <PrestoFramedButton
                        buttonStyle="primary"
                        size={"extra-large"}
                        title={cancelbuttonTitle}
                        titleFontStyle="700.semibold"
                        onPress={() => onCancelButtonPressed()}
                      />
                    </Pressable>
                  </View>
                ) : null}
                <View
                  style={{
                    width: !cancelbuttonTitle ? "100%" : "50%",
                    ...theme.primaryShadow,
                  }}
                >
                  <PrestoSolidButton
                    buttonStyle="primary"
                    size={"extra-large"}
                    title={closeButtonTitle}
                    onPress={() =>
                      props.closeButtonPressed
                        ? props.closeButtonPressed()
                        : onDismiss()
                    }
                  />
                </View>
              </View>
            </>
          ) : null}
          {CoupleButton ? (
            <View
              style={{
                flexDirection: "row",
                padding: theme.primaryPadding,
                ...theme.customeShadow,
              }}
            >
              {buttonOneTitle ? (
                <View style={{ width: "50%" }}>
                  <PrestoFramedButton
                    buttonStyle="primary"
                    size={"extra-large"}
                    title={buttonOneTitle}
                    titleFontStyle="700.semibold"
                    onPress={() => onButtonOnePressed()}
                    extraStyle={{ marginRight: 10 }}
                  />
                </View>
              ) : null}
              {CoupleButton && buttonOneTitle ? (
                <View
                  style={{
                    width: buttonOneTitle ? "50%" : "100%",
                  }}
                >
                  <PrestoSolidButton
                    buttonStyle="primary"
                    size={"extra-large"}
                    title={buttonTwoTitle || confirmationButtonTitle}
                    onPress={() => onConfirmationButtonPress()}
                  />
                </View>
              ) : null}
            </View>
          ) : null}
          {footerContent ? <View>{footerContent}</View> : null}
        </Modal>
      </Portal>
    </View>
  );
}

const styles = {
  container: {
    flex: 1,
    zIndex: 11000,
    paddingTop: Platform.OS === "web" ? 15 : 0,
  },
};
