import React, { useState, useContext, useCallback } from "react";
import { View, ScrollView } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import KeyValueList from "@presto-components/KeyValueList/KeyValueList";
import useFocusEffect from "@presto-common/useFocusEffect";
import NavigatorContext from "@presto-contexts/NavigatorContext";

export default function UserInfo({ route, navigation }) {
  const params = route.params || {};
  const { initial, setParentLoading, sideNavigationRef } = params;
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const styles = getStyles(theme);
  const [user, setUser] = useState(null);

  useFocusEffect(
    useCallback(() => {
      init();
    }, [initial])
  );

  const init = () => {
    setUser(initial);
  };

  const onPressBack = () => {
    navigation.goBack();
  };

  const navigateToEditForm = () => {
    Navigator.emit({
      event: "replace",
      params: {
        screenName: "HOME",
        screenParams: {
          selectedFeature: {
            state: "admin_management_user_form",
          },
          initialUser: user,
        },
      },
    });
  };

  let cartInfo = {
    [I18n.t("screen_messages.cart.total_items")]: "",
  };

  return (
    <View style={{ backgroundColor: theme.screenBackgroundColor }}>
      <View
        style={{
          backgroundColor: "#737682",
          borderTopLeftRadius: 18,
          borderTopRightRadius: 18,
          height: "100%",
        }}
      >
        <ScrollView
          style={{ width: "100%", flex: 1 }}
          contentContainerStyle={{
            paddingTop: 10,
          }}
        >
          {user ? (
            <View style={{ paddingHorizontal: theme.primaryPadding }}>
              {user.pic_url ? (
                <View style={{ paddingTop: theme.primaryPadding }}>
                  <Image
                    source={{ uri: user.pic_url }}
                    style={{ width: 120, height: 120, borderRadius: "100%" }}
                    resizeMode="contain"
                  />
                </View>
              ) : null}

              <PrestoText
                size={18}
                color={theme.white}
                fontStyle="600.semibold"
                extraStyle={{ paddingTop: theme.containerPadding }}
              >
                {user.name}
              </PrestoText>

              <PrestoText
                size={18}
                color={theme.white}
                fontStyle="600.semibold"
                extraStyle={{ paddingTop: theme.containerPadding }}
              >
                {user.email}
              </PrestoText>

              <PrestoText
                size={18}
                color={theme.white}
                fontStyle="600.semibold"
                extraStyle={{ paddingTop: theme.containerPadding }}
              >
                {user.phone_number}
              </PrestoText>

              <View
                style={{
                  flexDirection: "column",
                  paddingTop: theme.containerPadding,
                }}
              >
                <KeyValueList
                  leftTextColor={theme.white}
                  leftTextProps={{ size: 16 }}
                  rightTextColor={theme.white}
                  rightTextProps={{ size: 16 }}
                  dict={{}}
                />
              </View>
            </View>
          ) : null}
        </ScrollView>

        {user ? (
          <View
            style={{
              paddingBottom: 40,
              paddingHorizontal: theme.primaryPadding,
            }}
          >
            <PrestoSolidButton
              size="large"
              buttonStyle="primary"
              title={I18n.t("screen_messages.common.edit_text")}
              titleColor={theme.white}
              titleFontStyle={"600.semibold"}
              titleSize={24}
              height={60}
              borderRadius={12}
              onPress={navigateToEditForm}
              bgColor="#D3D3D3"
            />
          </View>
        ) : null}
      </View>
    </View>
  );
}

function getStyles(theme) {
  return {
    footerContainer: {
      paddingHorizontal: 20,
      bottom: 0,
      width: "100%",
    },
    footerInner: {
      flexDirection: "row",
    },
  };
}
