import React, { useContext, useState, useEffect } from "react";
import { View, FlatList, Pressable, Image, Platform } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import PrestoAmountInputWithPlusMinus from "@presto-components/AmountInputs/PrestoAmountInputWithPlusMinus";
import _ from "lodash";
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import ItemCardModel from "@presto-component-models/ItemCardModel";
import utils from "../../utils";
import { useAssets } from "presto-react-components";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard.ziphealthPos";
import AsyncStorage from "@react-native-community/async-storage";

export default function ProductsTable({
  items,
  onEndReached,
  activeRow,
  onPressRow,
  selectedItemIds = [],
  onPressActionButton,
  onAmountChange,
  addItemToPurchaseRequest,
  updateItemToPurchaseRequest,
  updateSortDirection,
  currentSortDirection,
  sortField,
  updateSortField,
}) {
  const [myListVisibile, setMyListVisible] = useState(false);
  const [zipItemIds, setZipItemIds] = useState([]);
  const isWeb = Platform.OS == 'web'
  const getZipItemIds = async (callback) => {
    let zitemIds = await AsyncStorage.getItem("ZIP_ITEM_IDS");
    if (!zitemIds) {
      zitemIds = "[]";
    }
    setZipItemIds(JSON.parse(zitemIds));
    console.log("ZIP IDS ==== " + JSON.stringify(zipItemIds));
    callback();
  };

  useEffect(() => {
    getZipItemIds(() => {
      setMyListVisible(true);
      console.log("Got ZIP IDS");
    });
  }, []);

  const { theme } = useContext(ThemeContext);
  const isMobile = utils.isMobile();
  const { images: Images, svgs } = useAssets();
  const tableColumns = [
    {
      title: I18n.t("screen_messages.products.sku_1"),
      width: "23%",
      textAlign: "left",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",

        paddingLeft: theme.primaryPadding,
      },
      columnName: "Name",
      sortable: true,
    },
    {
      title: I18n.t("screen_messages.orders.in_stock"),
      width: isMobile ? "20%" : "10%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
      columnName: "Stock",
      sortable: true,
    },
    {
      title: I18n.t("screen_messages.purchase_configurations.last_month_sale"),
      width: "11%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.purchase_configurations.supply_unit"),
      width: "11%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t(
        "screen_messages.purchase_configurations.purchase_supply_unit"
      ),
      width: "12%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.purchase_configurations.available_stock"),
      width: "10%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
    {
      title: I18n.t("screen_messages.purchase_configurations.sold_by"),
      width: "11%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
      columnName: "Zip",
      sortable: true,
    },
    {
      title: I18n.t("screen_messages.purchase_configurations.status_text"),
      width: "11%",
      textAlign: "center",
      thType: "highlighted",
      viewStyles: {
        alignItems: "center",
        paddingLeft: theme.primaryPadding,
      },
    },
  ];

  const RenderItemHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          paddingVertical: 5,
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: theme.screenBackgroundColor,
        }}
      >
        {_.map(tableColumns, (column, index) => {
          let viewStyles = {
            height: 50,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            ...column?.viewStyles,
          };

          let textProps = {
            extraStyle: { paddingLeft: 20 },
            color: theme.paragraph,
            fontStyle: "400.bold",
            size: 10,
          };

          if (column.thType === "highlighted") {
            viewStyles = {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            };
            textProps = {
              extraStyle: {
                paddingLeft: theme.primaryPadding,
                textAlgin: column.textAlign,
                width: "100%",
              },
              color: theme.black,
              fontStyle: "400.bold",
              size: 10,
            };
          }

          if (column.sortable) {
            return (
              <Pressable
                onPress={() => {
                  updateSortDirection(column.columnName);
                }}
                style={{
                  ...viewStyles,
                  padding: 0,
                  margin: 0,
                  width: column.width,
                }}
              >
                <View
                  key={index}
                  style={{
                    ...viewStyles,
                    width: "100%",
                  }}
                >
                  <PrestoText
                    {...textProps}
                    extraStyle={{
                      textAlign: textProps.extraStyle.textAlgin,
                      width: "100%",
                    }}
                  >
                    {column.title}{" "}
                    {sortField == column.columnName
                      ? currentSortDirection == "DESC"
                        ? "^"
                        : "v"
                      : ""}
                  </PrestoText>
                </View>
              </Pressable>
            );
          } else {
            return (
              <View
                key={index}
                style={{
                  ...viewStyles,
                  width: column.width,
                }}
              >
                <PrestoText
                  {...textProps}
                  extraStyle={{
                    textAlign: textProps.extraStyle.textAlgin,
                    width: "100%",
                  }}
                >
                  {column.title}
                </PrestoText>
              </View>
            );
          }
        })}
      </View>
    );
  };

  const defaultPicUrl =
    "https://images.unsplash.com/photo-1584308666744-24d5c474f2ae?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1660&q=80";

  const getPicUrl = (item) => (item.pic_url ? item.pic_url : defaultPicUrl);

  const isZipSupplied = function (purchaseConfig) {
    let zipFound = false;
    purchaseConfig?.supplier_prices?.forEach((sp) => {
      if (
        sp.supplier_id ||
        sp?.name?.indexOf("Zip") >= 0 ||
        sp.supplier_id == "64c8ff28179fb06bfbe3b6f9" ||
        sp.supplier_id == "65845fdd179fb0a4e0de327e" ||
        sp.supplier_id == "65cc70fa179fb01ce6ad8745" ||
        sp.supplier_id == "65cdd88d179fb03655ed2eea" ||
        sp.supplier_id == "65cddd19179fb03655ed4c75" ||
        sp.supplier_id == "65cd9e96179fb02ff0a255ef"
      ) {
        zipFound = true;
      }
    });
    return zipFound;
  };

  const renderItem = ({ item, index }) => {
    // console.log(JSON.stringify(item),"hellooooooo=========================");
    const product = new ItemCardModel({ product: item });
    const sentToZip = zipItemIds?.indexOf(item.id) >= 0 ? true : false;
    let picUrl = getPicUrl(item);
    let id = product.productId;
    let name = product.name;
    let inStock = product.stockCount;
    let molecules = _.get(item, "other_data.molecule");
    const isActive = item?.itemPurchaseRequest?.purchase_quantity >= 0;
    const isAddedToBucket =
      item?.itemPurchaseRequest?.purchase_quantity >= 0 ||
      _.includes(selectedItemIds, id);
    let bgColor = isActive ? "#6D707B" : theme.white;
    if (isAddedToBucket || sentToZip) {
      bgColor = theme.primary;
    }
    const textColor2 =
      isActive || isAddedToBucket || sentToZip ? theme.white : theme.darkText;
    const textColor4 =
      isActive || isAddedToBucket || sentToZip ? theme.white : "#666666";
    isActive || isAddedToBucket || sentToZip ? theme.white : theme.darkText;
    const textColor3 =
      isActive || isAddedToBucket || sentToZip ? theme.white : theme.black;

    const onPressAdd = () => {
      if (!isAddedToBucket) {
        let purchase_quantity = item.purchase_quantity;
        addItemToPurchaseRequest({ item, purchase_quantity }, false);
      } else {
        let purchase_quantity = item.purchase_quantity;
        updateItemToPurchaseRequest({ item, purchase_quantity }, false);
      }
    };

    const onAmountChange = (quantity) => {
      item.purchase_quantity = quantity;
      if (quantity > 0) {
        bgColor = theme.primary;
        // setBgColor(theme.primary)
      }
      if (!isAddedToBucket) {
        addItemToPurchaseRequest({ item, quantity }, false);
      } else {
        updateItemToPurchaseRequest({ item, quantity }, false);
      }
    };

    let value =
      item?.itemPurchaseRequest?.purchase_quantity || item.purchase_quantity
        ? parseInt(
          item?.itemPurchaseRequest?.purchase_quantity ||
          item.purchase_quantity
        )
        : "";

    if (isMobile) {
      return (
        <ItemCard
          key={id}
          item={item}
          forPurchases={true}
          onPress={() => onPressRow(item)}
          onAmountChange={onAmountChange}
          onSubtract={onAmountChange}
          onPressAdd={() => addItemToPurchaseRequest({ item }, false)}
          value={value}
          zipImage={Images.ZipIcon}
          selected={isActive}
        ></ItemCard>
      );
    } else {
      return (
        <Pressable
          key={`${item.id}-${index}`}
          onPress={() => onPressAdd(item)}
          style={{
            width: "100%",
            backgroundColor: bgColor,
            borderRadius: 0,
            paddingHorizontal: theme.primaryPadding,
            paddingVertical: 10,
            flexDirection: "row",
            marginBottom: 0,
            borderBottomWidth: 0.25,
            borderBottomColor: "#CCCCCC",
          }}
        >
          <View
            style={{
              width: "23%",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
              paddingLeft: isMobile ? 20 : 10,
              paddingRight: 10,
            }}
          >
            <PrestoText color={textColor2} fontStyle="400.normal">
              {name}
            </PrestoText>

            <PrestoText size={10} color={textColor4} fontStyle="400.normal">
              {molecules}
            </PrestoText>
          </View>

          <View
            style={{
              width: "11%",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
              marginRight: 0,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              extraStyle={{ textAlign: "center" }}
              color={textColor4}
              fontStyle="600.semibold"
            >
              {inStock}
            </PrestoText>
            <PrestoText size={10} color={textColor4} fontStyle="400.normal">
              {item?.itemPurchaseConfiguration?.sale_unit_display_name}
            </PrestoText>
          </View>
          <View
            style={{
              width: "11%",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
              marginRight: 0,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              extraStyle={{ textAlign: "center" }}
              color={textColor4}
              fontStyle="600.semibold"
            ></PrestoText>
          </View>
          <View
            style={{
              width: "11%",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
              marginRight: 0,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              extraStyle={{ textAlign: "center" }}
              color={textColor4}
              fontStyle="600.semibold"
            >
              {item?.itemPurchaseConfiguration?.purchase_unit_display_name}
            </PrestoText>
            <PrestoText size={10} color={textColor4} fontStyle="400.normal">
              {I18n.t("screen_messages.common.contains")}{" "}
              {item?.itemPurchaseConfiguration?.sale_to_purchase_ratio}{" "}
              {item?.itemPurchaseConfiguration?.sale_unit_display_name}
            </PrestoText>
          </View>
          <View
            style={{
              width: "13%",
              flexDirection: "row",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
              marginRight: 0,
              alignItems: "center",
              justifyContent: "center",
              gap: 0,
            }}
          >
            <PrestoAmountInputWithPlusMinus
              value={value}
              selected={isAddedToBucket || sentToZip}
              clearTextOnFocus={false}
              onAmountChange={onAmountChange}
              placeholder=""
              keyboardType="numeric"
            ></PrestoAmountInputWithPlusMinus>
          </View>
          <View
            style={{
              width: "10%",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
              marginRight: 0,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              extraStyle={{ textAlign: "center" }}
              color={textColor4}
              fontStyle="600.semibold"
            >
              {parseInt(inStock) +
                (item.purchase_quantity ||
                  item?.itemPurchaseRequest?.purchase_quantity ||
                  0) *
                item?.itemPurchaseConfiguration?.sale_to_purchase_ratio}
            </PrestoText>
            <PrestoText size={10} color={textColor4} fontStyle="400.normal">
              {item?.itemPurchaseConfiguration?.sale_unit_display_name}
            </PrestoText>
          </View>
          <View
            style={{
              width: "11%",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightColor: "#DBDBDB",
              marginRight: 0,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isZipSupplied(item?.itemPurchaseConfiguration) ? (
              <Image
                source={Images.ZipIcon}
                style={{ width: 30, height: 30, alignSelf: "center" }}
              />
            ) : (
              ""
            )}
          </View>

          <View
            style={{
              width: "12%",
              flexDirection: "column",
              justifyContent: "space-around",
              alignContent: "flex-end",
              alignItems: "center",
              marginLeft: 0,
            }}
          >
            <View style={{ paddingRight: isMobile ? 10 : 25 }}>
              <PrestoFramedButton
                size="small"
                buttonStyle="primary"
                title={
                  isAddedToBucket
                    ? I18n.t("screen_messages.common.update")
                    : I18n.t("screen_messages.common.add")
                }
                titleColor={
                  isAddedToBucket || sentToZip ? theme.white : theme.primary
                }
                titleFontStyle={"400.normal"}
                titleSize={11}
                height={29}
                width={isMobile ? 50 : 60}
                borderRadius={6}
                borderColor={
                  isAddedToBucket || sentToZip ? theme.white : theme.primary
                }
                onPress={onPressAdd}
              />
            </View>
            {sentToZip ? (
              <View style={{ paddingRight: isMobile ? 10 : 25 }}>
                <PrestoText size={10} color={textColor4} fontStyle="400.bold">
                  {I18n.t("screen_messages.purchases.sent_to_zip")};
                </PrestoText>
              </View>
            ) : (
              <></>
            )}
          </View>
        </Pressable>
      );
    }
  };

  return (
    <View>
      {myListVisibile ? (
        <View style={{ ...(isWeb) && { height: "80vh" } }}>
          <FlatList
            stickyHeaderIndices={[0]}
            keyExtractor={(item, index) => `${item.id}-${index}`}
            data={items}
            renderItem={renderItem}
            ListHeaderComponent={() => {
              if (isMobile) return;
              return <RenderItemHeader />;
            }}
            onEndReached={onEndReached}
          />
        </View>
      ) : null}
    </View>
  );
}
