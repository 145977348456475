import React, { useContext, useState, useEffect, useCallback } from "react";
import { View, Pressable, Dimensions, ScrollView, Image } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import LoadingContainer from "@presto-components/LoadingContainer";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import { prestoAlert } from "../../common/Alert";
import utils from "../../utils/index";
import { useAssets } from "presto-react-components";
import _ from "lodash";
import PrestoIcon from "@presto-components/PrestoIcon";
import { VerticalSpacing } from "@presto-components/Spacing";
import { useSafeArea } from "react-native-safe-area-context";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import moment from "moment";
import { useCatalog } from "@presto-stores/CatalogStore";
import ModalMobile from "@presto-components/Modals/Modal.bupos";
import useFocusEffect from "@presto-common/useFocusEffect";
import CatalogDetailsComponent from "@presto-screen-components/CatalogDetails/CatalogDetails";

export default function BulkUpdate({ navigation }) {
  const [loading, setLoading] = useState(false);
  const windowHeight = Dimensions.get("window").height;
  const { theme } = useContext(ThemeContext);
  const { svgs } = useAssets();
  const insets = useSafeArea();
  const [currentlyActive, setCurrentlyActive] = useState("Stocks");
  const catalog = useCatalog((state) => state.catalog);
  const { Navigator } = useContext(NavigatorContext);

  useFocusEffect(useCallback(() => {}, []));

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const Types = {
    all_sku: "All SKU",
    sold_today: "Sold Today",
    returns: "Returns",
    recently_added: "Recently Added",
    low_stock: "Low Stock",
    stocks: "Stocks",
  };

  const onPressHome = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "FEATURE_SELECTION",
      },
    });
  };

  const renderHeaderComponent = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          height: 60,
          backgroundColor: theme.dark,
          alignItems: "flex-end",
        }}
      >
        {/* Need to have a shadow on the right 
        Currently has margin
        Need to remove that */}
        <Pressable
          onPress={onPressHome}
          style={{
            width: "6%",
            backgroundColor: theme.primary,
            borderTopLeftRadius: 10,
            alignItems: "center",
            height: 55,
            borderTopRightRadius: 10,
          }}
        >
          <View
            style={{
              backgroundColor: theme.primary,
              padding: 10,
              height: 55,
            }}
          >
            <PrestoIcon
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={
                <svgs.StoreIcon2 width="36" height="36" color={theme.white} />
              }
            />
          </View>
        </Pressable>
        <Pressable
          onPress={() => {
            setCurrentlyActive("Stocks");
          }}
          style={{
            width: "16%",
            backgroundColor:
              currentlyActive == Types.stocks
                ? theme.tertiaryButtonColor
                : theme.topHeaderInactiveColor,
            borderTopLeftRadius: 10,
            alignItems: "center",
            height: currentlyActive == Types.stocks ? 55 : 40,
            marginLeft: 1,
            borderTopRightRadius: 10,
          }}
        >
          <View
            style={{
              backgroundColor:
                currentlyActive == Types.stocks
                  ? theme.tertiaryButtonColor
                  : theme.topHeaderInactiveColor,
              padding: 10,
              height: currentlyActive == Types.stocks ? 55 : 40,

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              size={20}
              fontStyle={"600.semibold"}
              color={
                currentlyActive == Types.stocks ? theme.black : theme.white
              }
            >
              {I18n.t("screen_messages.all_sales.stocks")}
            </PrestoText>
          </View>
        </Pressable>
        <Pressable
          onPress={() => {
            setCurrentlyActive(I18n.t("screen_messages.all_sales.low_stock"));
          }}
          style={{
            width: "14%",
            backgroundColor:
              currentlyActive == Types.low_stock
                ? theme.tertiaryButtonColor
                : theme.topHeaderInactiveColor,
            alignItems: "center",
            height: currentlyActive == Types.low_stock ? 55 : 40,
            marginLeft: 1,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          <View
            style={{
              backgroundColor:
                currentlyActive == Types.low_stock
                  ? theme.tertiaryButtonColor
                  : theme.topHeaderInactiveColor,
              padding: 10,
              height: currentlyActive == Types.low_stock ? 55 : 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              size={18}
              fontStyle={"600.semibold"}
              color={
                currentlyActive == Types.low_stock ? theme.black : theme.white
              }
            >
              {I18n.t("screen_messages.all_sales.low_stocks")}
            </PrestoText>
          </View>
        </Pressable>
        <Pressable
          onPress={() => {
            setCurrentlyActive(
              I18n.t("screen_messages.all_sales.recently_added")
            );
          }}
          style={{
            width: "17%",
            backgroundColor:
              currentlyActive == Types.recently_added
                ? theme.tertiaryButtonColor
                : theme.topHeaderInactiveColor,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            alignItems: "center",
            height: currentlyActive == Types.recently_added ? 55 : 40,
            marginLeft: 1,
          }}
        >
          <View
            style={{
              backgroundColor:
                currentlyActive == Types.recently_added
                  ? theme.tertiaryButtonColor
                  : theme.topHeaderInactiveColor,
              padding: 10,
              height: currentlyActive == Types.recently_added ? 55 : 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              size={18}
              fontStyle={"600.semibold"}
              color={
                currentlyActive == Types.recently_added
                  ? theme.black
                  : theme.white
              }
            >
              {I18n.t("screen_messages.all_sales.recently_added")}
            </PrestoText>
          </View>
        </Pressable>
        <Pressable
          onPress={() => {
            setCurrentlyActive(I18n.t("screen_messages.all_sales.sold_today"));
          }}
          style={{
            width: "14%",
            backgroundColor:
              currentlyActive == Types.sold_today
                ? theme.tertiaryButtonColor
                : theme.topHeaderInactiveColor,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            alignItems: "center",
            height: currentlyActive == Types.sold_today ? 55 : 40,
            marginLeft: 1,
          }}
        >
          <View
            style={{
              backgroundColor:
                currentlyActive == Types.sold_today
                  ? theme.tertiaryButtonColor
                  : theme.topHeaderInactiveColor,
              padding: 10,
              height: currentlyActive == Types.sold_today ? 55 : 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              size={18}
              fontStyle={"600.semibold"}
              color={
                currentlyActive == Types.sold_today ? theme.black : theme.white
              }
            >
              {I18n.t("screen_messages.all_sales.sold_today")}
            </PrestoText>
          </View>
        </Pressable>
        <Pressable
          onPress={() => {
            setCurrentlyActive(I18n.t("screen_messages.all_sales.returns"));
          }}
          style={{
            width: "12%",
            backgroundColor:
              currentlyActive == Types.returns
                ? theme.tertiaryButtonColor
                : theme.topHeaderInactiveColor,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            alignItems: "center",
            height: currentlyActive == Types.returns ? 55 : 40,
            marginLeft: 1,
          }}
        >
          <View
            style={{
              backgroundColor:
                currentlyActive == Types.returns
                  ? theme.tertiaryButtonColor
                  : theme.topHeaderInactiveColor,
              padding: 10,
              height: currentlyActive == Types.returns ? 55 : 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              size={18}
              fontStyle={"600.semibold"}
              color={
                currentlyActive == Types.returns ? theme.black : theme.white
              }
            >
              {I18n.t("screen_messages.all_sales.returns")}
            </PrestoText>
          </View>
        </Pressable>
        <Pressable
          onPress={() => {
            setCurrentlyActive(I18n.t("screen_messages.all_sales.all_sku"));
          }}
          style={{
            width: "12%",
            backgroundColor:
              currentlyActive == Types.all_sku
                ? theme.tertiaryButtonColor
                : theme.topHeaderInactiveColor,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            alignItems: "center",
            height: currentlyActive == Types.all_sku ? 55 : 40,
            marginLeft: 1,
          }}
        >
          <View
            style={{
              backgroundColor:
                currentlyActive == Types.all_sku
                  ? theme.tertiaryButtonColor
                  : theme.topHeaderInactiveColor,
              padding: 10,
              height: currentlyActive == Types.all_sku ? 55 : 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              size={18}
              fontStyle={"600.semibold"}
              color={
                currentlyActive == Types.all_sku ? theme.black : theme.white
              }
            >
              {I18n.t("screen_messages.all_sales.all_sku")}
            </PrestoText>
          </View>
        </Pressable>
      </View>
    );
  };

  const renderItemHeader = () => {
    return (
      <View
        style={{
          width: "70%",
          height: 60,
          padding: 5,
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "10%",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <PrestoText
            extraStyle={{ paddingLeft: 20 }}
            color={theme.black}
            fontStyle={"400.bold"}
            size={16}
          >
            {I18n.t("screen_messages.all_sales.skus")}
          </PrestoText>
        </View>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "25%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <PrestoText
            extraStyle={{ paddingLeft: 20 }}
            color={theme.paragraph}
            fontStyle={"400.bold"}
            size={14}
          >
            {I18n.t("screen_messages.all_sales.sku_and_name")}
          </PrestoText>
        </View>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "15%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={14}>
            {I18n.t("screen_messages.all_sales.last_ordered")}
          </PrestoText>
        </View>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "13%",
            justifyContent: "center",
            alignItems: "flex-start",
            paddingHorizontal: 10,
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={14}>
            {I18n.t("screen_messages.all_sales.sold_today")}
          </PrestoText>
        </View>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "13%",
            justifyContent: "center",
            alignItems: "flex-start",
            paddingHorizontal: 10,
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={14}>
            {I18n.t("screen_messages.all_sales.in_stock")}
          </PrestoText>
        </View>
        <View
          style={{
            height: 50,
            width: "24%",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            paddingHorizontal: 10,
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={14}>
            {I18n.t("screen_messages.all_sales.unit_price")}
          </PrestoText>
        </View>
      </View>
    );
  };

  const renderItem = () => {
    return (
      <View
        style={{
          width: "70%",
          height: 60,
          backgroundColor: theme.white,
          borderRadius: 15,
          padding: 5,
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "10%",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Image
            source={{ uri: "http://unsplash.it/60/35" }}
            alt={"image"}
            style={{
              height: 45,
              width: 60,
              borderRadius: 5,
              marginLeft: 20,
              borderWidth: 1,
              borderColor: "#EAE9ED",
            }}
          />
        </View>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "25%",
            justifyContent: "center",
            alignItems: "flex-start",
            borderRightColor: theme.lightText,
            borderRightWidth: 1,
          }}
        >
          <PrestoText
            extraStyle={{ paddingLeft: 20 }}
            color={theme.primary}
            fontStyle={"400.bold"}
          >
            {"ABCDEFG-23643726"}
          </PrestoText>
          <PrestoText extraStyle={{ paddingLeft: 20 }}>{"Dolo-650"}</PrestoText>
        </View>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "15%",
            justifyContent: "center",
            alignItems: "center",
            borderRightColor: theme.lightText,
            borderRightWidth: 1,
          }}
        >
          <PrestoText>{"25-05-2023"}</PrestoText>
        </View>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "13%",
            justifyContent: "center",
            alignItems: "flex-end",
            borderRightColor: theme.lightText,
            borderRightWidth: 1,
            paddingHorizontal: 10,
          }}
        >
          <PrestoText>{"890"}</PrestoText>
        </View>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "13%",
            justifyContent: "center",
            alignItems: "flex-end",
            borderRightColor: theme.lightText,
            borderRightWidth: 1,
            paddingHorizontal: 10,
          }}
        >
          <PrestoText fontStyle={"400.semibold"}>{"890"}</PrestoText>
        </View>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "24%",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            paddingHorizontal: 10,
          }}
        >
          <PrestoText fontStyle={"400.semibold"}>{"24.00"}</PrestoText>
          <PrestoSolidButton
            title={I18n.t("screen_messages.add")}
            height={40}
            titleSize={16}
            width={60}
            titleColor={theme.primary}
            borderColor={theme.primary}
            borderWidth={1}
            borderRadius={7}
            onPress={() => {}}
          />
        </View>
      </View>
    );
  };

  return (
    <LoadingContainer
      loading={loading}
      bgColor={theme.appBackgroundColor}
      style={{ top: insets.top, position: "relative" }}
    >
      <View style={{ zIndex: 999999 }}>{renderHeaderComponent()}</View>
      <View
        style={{
          backgroundColor: theme.tertiaryButtonColor,
        }}
      >
        <View
          style={{
            appBackgroundColor: theme.tertiaryButtonColor,
            height: "100%",
            padding: 15,
          }}
        >
          {renderItemHeader()}
          <VerticalSpacing size={10} />
          {renderItem()}
        </View>
      </View>
    </LoadingContainer>
  );
}

function getStyles(theme) {
  return {
    container: {},
  };
}
