import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { View } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import AdvanceSearchBar from "@presto-screen-components/SalesReports/AdvanceSearchBar";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoText from "@presto-components/PrestoText";
import asyncify from "@presto-common/Asyncify";
import UserContext from "@presto-contexts/UserContext";
import { alertBox } from "@presto-common/Alert";
import UserTable from "@presto-screen-components/RoleManagement/UserTable";
import UserForm from "@presto-screen-components/RoleManagement/UserForm";
import svgs from "@presto-assets/svgs";
import { DefaultSvgs } from "presto-react-components";
import BrandLogoSection from "@presto-screen-components/BrandLogo/BrandLogoSection";

export default function RoleManagement({
  sideNavigationRef,
  parentRef,
  tabs,
  setTabs,
  parentLoading,
  setParentLoading,
  selectedFeature,
  initialUser,
  ...props
}) {
  const { Navigator } = useContext(NavigatorContext);
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [activeRow, setActiveRow] = useState(null);
  const [currentSection, setCurrentSection] = useState("LISTING");

  const [filterOptions, setFilterOptions] = useState({
    searchValue: "",
    ...props.filterOptions,
  });

  const permissionChoices = [
    {
      title: "PoS",
      id: "pos_management",
      renderIcon: (props) => (
        <DefaultSvgs.Pos
          height={40}
          width={40}
          color={props.color}
          stroke={props.color}
        />
      ),
    },
    {
      title: "All Stock",
      id: "all_stock",
      renderIcon: (props) => (
        <DefaultSvgs.StockIcon height={40} width={40} color={props.color} />
      ),
    },

    {
      title: "Purchase Request",
      id: "purchase_request_management",
      renderIcon: (props) => (
        <DefaultSvgs.PurchaseRequests
          height={40}
          width={40}
          color={props.color}
        />
      ),
    },
    {
      title: "Purchase Order",
      id: "purchase_order_management",
      renderIcon: (props) => (
        <DefaultSvgs.PurchaseOrders
          height={40}
          width={40}
          color={props.color}
        />
      ),
    },
    {
      title: "Reports",
      id: "sales_reports_management",
      renderIcon: (props) => (
        <DefaultSvgs.SalesReports height={40} width={40} color={props.color} />
      ),
    },
    {
      title: "Offers & Discounts",
      id: "offer_management",
      renderIcon: (props) => (
        <svgs.StoreIcon
          height={40}
          width={40}
          color={"#FFF"}
          stroke={"#FFF"}
          {...props}
        />
      ),
    },
    {
      title: "Customers",
      id: "customer_management",
      renderIcon: (props) => (
        <svgs.UserIcon
          height={40}
          width={40}
          color={props.color}
          stroke={props.color}
        />
      ),
    },
    {
      title: "Admin",
      id: "admin_management",
      renderIcon: (props) => (
        <DefaultSvgs.HomeWithSmile
          height={40}
          width={40}
          color={"#FFF"}
          {...props}
        />
      ),
    },
  ];

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (user) {
      initPage();
    }
  }, [user]);

  useEffect(() => {
    if (
      selectedFeature.state === "admin_management_user_form" &&
      !_.isEmpty(initialUser)
    ) {
      setActiveRow(initialUser);
      setCurrentSection("ADD_MEMBER");
    }
  }, [selectedFeature]);

  useEffect(() => {
    updateFunctionReferences();
  }, [activeRow, filterOptions, tabs]);

  const init = () => {
    const tabStyles = {
      tabisActiveTextExtraStyles: {
        color: "#212123",
      },
    };

    const tempTabs = [
      {
        id: "members",
        text: I18n.t("screen_messages.members.members"),
        isActive: true,
        defaultStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
      },
      ...[
        {
          id: "add_member",
          text: I18n.t("screen_messages.members.add_member"),
        },
      ].map((item) => {
        return {
          ...item,
          ...tabStyles,
        };
      }),
    ];

    setTabs(tempTabs);
  };

  const updateFunctionReferences = () => {
    parentRef.current.onPressTab = onPressTab;
  };

  const onPressTab = (tab) => {
    let tempTabs = tabs;
    tempTabs = _.map(tempTabs, (tabItem) => {
      let isActive = tabItem.id === tab.id;
      return {
        ...tabItem,
        isActive,
      };
    });

    setTabs(tempTabs);
    afterPressTab(tab);
  };

  const afterPressTab = (tab) => {
    const id = tab.id;
    setFilterOptions({
      ...filterOptions,
      searchValue: "",
    });

    if (tab.id === "add_member") {
      setActiveRow(null);
      setCurrentSection("ADD_MEMBER");
    }
    if (tab.id === "members") {
      setCurrentSection("LISTING");
    }
  };

  const initPage = () => {
    let params = {};
    EmployeeManager.nearByOperators(
      params,
      (response) => {
        const data = _.filter(
          _.get(response, "data.operators"),
          (e) => e.merchant_id === user.merchant_id
        );
        setUsers(data);
      },
      (error) => {
        setUsers([]);
      }
    );
  };

  const doAdvanceSearch = () => {
    initPage();
  };
  
  return (
    <View
      style={{
        backgroundColor: theme.screenBackgroundColor,
        flex: 1,
      }}
    >
      {currentSection === "LISTING" ? (
        <>
          <View style={{ padding: 10 }}>
            <AdvanceSearchBar
              searchValue={filterOptions.searchValue}
              onChange={(text) => {
                setFilterOptions({
                  ...filterOptions,
                  searchValue: text,
                });
              }}
              onPressIcon={doAdvanceSearch}
            />
          </View>

          <View
            style={{
              paddingHorizontal: 10,
              paddingBottom: 170,
              height: "100%",
            }}
          >
            <View
              style={{
                minHeight: 410,
              }}
            >
              <UserTable
                permissionChoices={permissionChoices}
                activeRow={activeRow}
                onPressRow={(newRow) => {
                  let tempItem = null;
                  if (newRow.id !== activeRow?.id) {
                    tempItem = newRow;
                  }
                  setActiveRow(tempItem);
                  sideNavigationRef.navigate("UserInfo", {
                    initial: tempItem,
                    parentLoading,
                    setParentLoading,
                    sideNavigationRef,
                    setCurrentSection,
                  });
                }}
                items={users}
              />
            </View>
            <View
              style={{
                position: "absolute",
                bottom: 120,
                left: "50%",
                height: 60,
              }}
            >
              <BrandLogoSection />
            </View>
          </View>
        </>
      ) : null}

      {currentSection === "ADD_MEMBER" ? (
        <View>
          <UserForm
            permissionChoices={permissionChoices}
            setParentLoading={setParentLoading}
            initialUser={activeRow}
            setCurrentSection={(val) => {
              setCurrentSection(val);
              initPage();
            }}
          />
        </View>
      ) : null}
      <View>
        <BrandLogoSection />
      </View>
    </View>
  );
}
