import PrestoSdk from "../../global/PrestoSdk";
import $http from "../../global/http";

const StylistResource = function (PrestoSdk, $http) {
  function getAllStylists(params) {
    var url = PrestoSdk.getHostName() + "/employee/v1/operators.json";
    return $http.get(url);
  }

  function createStylist(params) {
    var url = PrestoSdk.getHostName() + "/employee/v1/operators.json";
    return $http.post(url, params);
  }

  function removeStylist(params) {
    let operator_id = params.operator_id;
    var url =
      PrestoSdk.getHostName() + `/employee/v1/operators/${operator_id}.json`;
    return $http.delete(url, params);
  }

  function addOperatorServices(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/operators/${params.operatorId}/add_services.json`;
    return $http.put(url, params);
  }

  function removeOperatorServices(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/operators/${params.operatorId}/remove_services.json`;
    return $http.put(url, params);
  }

  function showOperator(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/operators/${params.employee_id}.json`;
    return $http.get(url);
  }

  function updateOperator(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/operators/${params.operatorId}.json`;
    return $http.put(url, params);
  }

  //   isLoggedInUser: function () {
  //   var url = PrestoSdk.getHostName() + SessionConstants.LOGIN_STATUS.PATH;
  //   let resp = $http.get(url);
  //   console.log("resp", resp);
  //   return resp;
  // },

  function isOperatorLoggedIn(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/operators/${params.operatorId}.json`;
    return $http.put(url, params);
  }

  function startBooking(params) {
    var url =
      PrestoSdk.getHostName() +
      "/operator/v1/bookings/" +
      params.id +
      "/start.json";
    return $http.put(url, params);
  }

  function endBooking(params) {
    var url =
      PrestoSdk.getHostName() +
      "/operator/v1/bookings/" +
      params.id +
      "/complete.json";
    return $http.put(url, params);
  }

  function getStylistStats(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/metrics/time_series_metric.json?type=${params.type}&start_time=${params.start_time}&end_time=${params.end_time}&interval=${params.interval}&employee_id=${params.operator_id}`; // here the employee_id refers to operator.id
    return $http.get(url, params);
  }

  function createOrder(params) {
    var url = PrestoSdk.getHostName() + `/operator/v1/orders.json`;
    return $http.post(url, params);
  }

  function addItem(params) {
    var url =
      PrestoSdk.getHostName() +
      "/operator/v1/orders/" +
      params.order_id +
      "/add_item.json";
    return $http.put(url, params);
  }

  function removeItem(params) {
    var url =
      PrestoSdk.getHostName() +
      "/operator/v1/orders/" +
      params.order_id +
      "/remove_item.json";
    return $http.put(url, params);
  }

  function updateItem(params) {
    var url =
      PrestoSdk.getHostName() +
      "/operator/v1/orders/" +
      params.order_id +
      "/update_item.json";
    return $http.put(url, params);
  }

  function showOrder(params) {
    var url =
      PrestoSdk.getHostName() + `/operator/v1/orders/${params.order_id}.json`;
    return $http.get(url);
  }

  function getStatsForStylist(params) {
    var url =
      PrestoSdk.getHostName() +
      `/operator/v1/metrics/time_series_metric.json?type=${params.type}&start_time=${params.start_time}&end_time=${params.end_time}&interval=${params.interval}&employee_id=${params.operator_id}`; // here the employee_id refers to operator.id
    return $http.get(url, params);
  }

  function getStoreStats(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/metrics/time_series_metric.json?type=${params.type}&start_time=${params.start_time}&end_time=${params.end_time}&interval=${params.interval}`;
    return $http.get(url, params);
  }

  return {
    getAllStylists: getAllStylists,
    createStylist: createStylist,
    removeStylist: removeStylist,
    addOperatorServices: addOperatorServices,
    showOperator: showOperator,
    updateOperator: updateOperator,
    removeOperatorServices: removeOperatorServices,
    startBooking: startBooking,
    endBooking: endBooking,
    getStylistStats: getStylistStats,
    getStatsForStylist: getStatsForStylist,
    createOrder: createOrder,
    addItem: addItem,
    removeItem: removeItem,
    updateItem: updateItem,
    showOrder: showOrder,
    getStoreStats: getStoreStats,
  };
};

export default StylistResource(PrestoSdk, $http);
