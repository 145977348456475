import OutletConstants from "./OutletConstants";
import PrestoSdk from "../../global/PrestoSdk";
import $http from "../../global/http";
import $ from "../../global/util";

const OutletResource = function (OutletConstants, PrestoSdk, $http) {
  function getOutlets() {
    var url =
      PrestoSdk.getHostName() +
      OutletConstants.GET_OUTLETS.PATH +
      "?merchant_id=" +
      PrestoSdk.getMerchantId();
    return $http.get(url);
  }

  function getOutlet(params) {
    var url =
      PrestoSdk.getHostName() +
      "/outlets/" +
      params.outletId +
      ".json" +
      "?merchant_id=" +
      PrestoSdk.getMerchantId();
    return $http.get(url);
  }

  function getOutletConfig(params) {
    var url =
      PrestoSdk.getHostName() +
      "/outlets/" +
      params.outletId +
      "/configuration.json";
    return $http.get(url);
  }

  function getServingOutlets(area) {
    var url =
      PrestoSdk.getHostName() +
      "/user/v0/outlets/serving_in_area.json" +
      "?merchant_id=" +
      PrestoSdk.getMerchantId() +
      "&area=" +
      area;
    return $http.get(url);
  }

  function getOutletDetails(params) {
    var url = PrestoSdk.getHostName() + "/outlets/" + params.outletId + ".json";
    return $http.get(url);
  }

  return {
    getOutlets: getOutlets,
    getOutletConfig: getOutletConfig,
    getServingOutlets: getServingOutlets,
    getOutletDetails: getOutletDetails,
    getOutlet: getOutlet,
  };
};

export default OutletResource(OutletConstants, PrestoSdk, $http);
