import _ from "lodash";
import React, { createContext, useState, useCallback } from "react";

const ModalContext = createContext();

export function ModalContextProvider(props) {
  const [LeftModal, setLeftModal] = useState(null);
  const [RightModals, setRightModals] = useState([]);
  const [BottomModal, setBottomModal] = useState(null);
  const [CenterModal, setCenterModal] = useState(null);

  const showRightModal = (Component) => {
    setRightModals([Component]);
  };

  const pushRightModal = (Component) => {
    setRightModals([...RightModals, Component]);
  };

  const popRightModal = (n = 1) => {
    console.log("Right Modals : ", RightModals);
    setRightModals(_.dropRight(RightModals, n));
  };

  const showLeftModal = (Component) => {
    setLeftModal(Component);
  };

  const showBottomModal = (Component) => {
    setBottomModal(Component);
  };

  const showCenterModal = (Component) => {
    setCenterModal(Component);
  };

  const hideRightModal = () => {
    setRightModals([]);
  };

  const hideLeftModal = () => {
    setLeftModal(null);
  };

  const hideBottomModal = () => {
    setBottomModal(null);
  };

  const hideCenterModal = () => {
    setCenterModal(null);
  };

  return (
    <ModalContext.Provider
      value={{
        LeftModal,
        RightModals,
        BottomModal,
        CenterModal,
        showLeftModal,
        showRightModal,
        showBottomModal,
        hideLeftModal,
        hideBottomModal,
        hideRightModal,
        showCenterModal,
        hideCenterModal,
        pushRightModal,
        popRightModal,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
}

export default ModalContext;
