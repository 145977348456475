import React, { useContext, useState, useEffect, useCallback } from "react";
import { View, Pressable, Dimensions, ScrollView } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import LoadingContainer from "@presto-components/LoadingContainer";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import { prestoAlert } from "../../common/Alert";
import utils from "../../utils/index";
import { useAssets } from "presto-react-components";
import _ from "lodash";
import PrestoIcon from "@presto-components/PrestoIcon";
import { VerticalSpacing } from "@presto-components/Spacing";
import { useSafeArea } from "react-native-safe-area-context";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import moment from "moment";
import { useCatalog } from "@presto-stores/CatalogStore";
import ModalMobile from "@presto-components/Modals/Modal.bupos";
import useFocusEffect from "@presto-common/useFocusEffect";
import CatalogDetailsComponent from "@presto-screen-components/CatalogDetails/CatalogDetails";

export default function BulkUpdate({ navigation }) {
  const [loading, setLoading] = useState(false);
  const windowHeight = Dimensions.get("window").height;
  const { theme } = useContext(ThemeContext);
  const { svgs } = useAssets();
  const insets = useSafeArea();
  const catalog = useCatalog((state) => state.catalog);
  const { Navigator } = useContext(NavigatorContext);

  useFocusEffect(useCallback(() => {}, []));

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const onPressHome = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "FEATURE_SELECTION",
      },
    });
  };

  const renderSearchComponent = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          height: 60,
          backgroundColor: theme.dark,
          alignItems: "flex-end",
        }}
      >
        <Pressable
          onPress={onPressHome}
          style={{
            width: "6%",
            backgroundColor: theme.primary,
            borderTopLeftRadius: 10,
            alignItems: "center",
            height: 55,
            borderTopRightRadius: 10,
          }}
        >
          <View
            style={{
              backgroundColor: theme.primary,
              padding: 10,
              height: 55,
            }}
          >
            <PrestoIcon
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={
                <svgs.StoreIcon2 width="36" height="36" color={theme.white} />
              }
            />
          </View>
        </Pressable>
        {/* Need to have a shadow on the right 
        Currently has margin
        Need to remove that */}
        <Pressable
          onPress={onPressHome}
          style={{
            width: "18%",
            backgroundColor: theme.primary,
            borderTopLeftRadius: 10,
            alignItems: "center",
            height: 55,
            marginLeft: 1,
            borderTopRightRadius: 10,
          }}
        >
          <View
            style={{
              backgroundColor: theme.primary,
              padding: 10,
              height: 55,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText
              size={18}
              fontStyle={"600.semibold"}
              color={theme.white}
            >
              {"Catalog"}
            </PrestoText>
          </View>
        </Pressable>
      </View>
    );
  };

  return (
    <LoadingContainer
      loading={loading}
      bgColor={theme.appBackgroundColor}
      style={{ top: insets.top, position: "relative" }}
    >
      <View style={{ zIndex: 999999 }}>{renderSearchComponent()}</View>
      <View
        style={{
          backgroundColor: theme.lightText,
          height: "100%",
        }}
      >
        <ScrollView
          contentContainerStyle={{
            padding: 10,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/* Here pass all the values as strings */}
          <CatalogDetailsComponent
            storeName={"ganesh"}
            address={"hello"}
            timings={"10 - 19"}
            outOfStock={"20"}
            lowStock={"192"}
            inStock={"19382"}
            sales={"2983.00"}
            theme={theme}
          />
        </ScrollView>
      </View>
    </LoadingContainer>
  );
}

function getStyles(theme) {
  return {
    container: {},
  };
}
