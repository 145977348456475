import React from "react";
import create from "zustand";
import { devtools } from "zustand/middleware";
import MerchantManager from "../services/features/merchant/MerchantManager";
import OutletManager from "../services/features/outlets/OutletManager";
import config from "@presto-common/config";

let MerchantStore = (set, get) => ({
  merchant: null,
  merchantId: null,
  loading: false,
  init: (id) => {
    set({ merchantId: id });
  },
  getMerchant: () => {
    return get().merchant;
  },
  setMerchant: (merchant) => {
    set({ merchant: merchant });
  },
  setMerchantId: (id) => {
    set({ merchantId: id });
  },
  reset: () => {
    set({ merchantId: null, merchant: null });
  },
  refreshMerchant: () => {
    if (config.features.load_merchant_for_user) {
      OutletManager.getOutlets(
        (resp) => {
          if (resp.data) {
            set({ merchant: resp.data[0], loading: false });
          }
        },
        (error) => {
          set({ loading: false });
          console.log("error", error);
        }
      );
    } else {
      set({ loading: true });
      let params = {
        id: get().merchantId,
      };
      MerchantManager.getMerchant(
        params,
        (resp) => {
          if (resp.data) {
            set({ merchant: resp.data, loading: false });
          }
        },
        (error) => {
          set({ loading: false });
          console.log("error", error);
        }
      );
    }
  },

  updateMerchant: (params, callBack) => {
    set({ loading: true });
    MerchantManager.updateAdmin(
      params,
      (resp) => {
        if (resp.data) {
          set({ merchant: resp.data, loading: false });
          if (callBack) {
            callBack("success");
          }
        }
        set({ loading: false });
      },
      (error) => {
        set({ loading: false });
        if (callBack) {
          callBack(error);
        }
        console.log("error", error);
      }
    );
  },
});

export const useMerchantStore = create(devtools(MerchantStore));
