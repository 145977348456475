import React from "react";
import I18n from "i18n-js";
import { View } from "react-native";
import PrestoText from "@presto-components/PrestoText";

export default function CatalogDetailsComponent({
  storeName,
  address,
  timings,
  outOfStock,
  lowStock,
  inStock,
  sales,
  theme,
}) {
  return (
    <View
      style={{
        height: 350,
        width: "35%",
        borderRadius: 20,
        backgroundColor: theme.white,
      }}
    >
      <View
        style={{
          width: "100%",
          height: "15%",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          borderBottomColor: theme.lightText,
          borderBottomWidth: 1,
          justifyContent: "center",
        }}
      >
        <PrestoText
          size={24}
          fontStyle={"600.bold"}
          color={theme.primary}
          extraStyle={{ paddingLeft: 20 }}
        >
          {storeName}
        </PrestoText>
      </View>
      <View
        style={{
          width: "100%",
          height: "25%",
          borderBottomColor: theme.lightText,
          borderBottomWidth: 1,
          paddingVertical: 10,
          paddingHorizontal: 20,
        }}
      >
        <PrestoText fontStyle={"400.regular"} size={17}>
          {address}
        </PrestoText>
        <PrestoText fontStyle={"600.bold"} size={16}>
          {timings}
        </PrestoText>
      </View>
      <View
        style={{
          width: "100%",
          height: "15%",
          borderBottomColor: theme.lightText,
          borderBottomWidth: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingHorizontal: 20,
        }}
      >
        <PrestoText size={18} fontStyle={"600.bold"} color={theme.secondary}>
          {I18n.t("screen_messages.catalog.out_of_stock")}
        </PrestoText>
        <PrestoText size={18} fontStyle={"600.bold"} color={theme.secondary}>
          {outOfStock}
        </PrestoText>
      </View>
      <View
        style={{
          width: "100%",
          height: "15%",
          borderBottomColor: theme.lightText,
          borderBottomWidth: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingHorizontal: 20,
        }}
      >
        <PrestoText size={18} fontStyle={"600.bold"} color={"orange"}>
          {I18n.t("screen_messages.catalog.low_stock")}
        </PrestoText>
        <PrestoText size={18} fontStyle={"600.bold"} color={"orange"}>
          {lowStock}
        </PrestoText>
      </View>
      <View
        style={{
          width: "100%",
          height: "15%",
          borderBottomColor: theme.lightText,
          borderBottomWidth: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingHorizontal: 20,
        }}
      >
        <PrestoText size={18} fontStyle={"600.bold"} color={theme.black}>
          {I18n.t("screen_messages.catalog.in_stock")}
        </PrestoText>
        <PrestoText size={18} fontStyle={"600.bold"} color={theme.black}>
          {inStock}
        </PrestoText>
      </View>
      <View
        style={{
          width: "100%",
          height: "15%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingHorizontal: 20,
        }}
      >
        <PrestoText size={18} fontStyle={"600.bold"} color={theme.black}>
          {I18n.t("screen_messages.catalog.sales_average")}
        </PrestoText>
        <PrestoText size={18} fontStyle={"600.bold"} color={theme.black}>
          {sales}
        </PrestoText>
      </View>
    </View>
  );
}
