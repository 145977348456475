import React, { useContext, useState, forwardRef } from "react";
import Pressable from "@presto-components/Pressable/Pressable";
import ThemeContext from "@presto-contexts/ThemeContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import TextInputWithIcon from "@presto-components/TextInputs/TextInputWithIcon";
import _ from "lodash";
import { useAssets } from "presto-react-components";

export default function DatePickerComponent(props) {
  const { svgs } = useAssets();
  const { currentDate, onDateChange, textInputStyles, iconSize } = props;

  let userDate = props.userDate;

  const [selected, setSelected] = useState(userDate ?? new Date());
  const { theme } = useContext(ThemeContext);
  const onChangeDate = (value) => {
    setSelected(value);
    onDateChange(value);
  };

  const minDate = props?.minDate || props?.minDate == null ? props?.minDate : new Date();
  const maximumDate = props?.maximumDate || null;
  const showTimeInput = props.showTimeInput == false ? false : true;
  const dateFormat = showTimeInput ? `MMMM d, yyyy h:mm aa` : `MMMM d, yyyy`;
  const showYearsDropdown = props?.showYearsDropdown || false;

  const years = _.range(1950, 2050);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const CustomInputComponent = forwardRef(({ value, onClick }, ref) => {
    return (
      <TextInputWithIcon
        ref={ref}
        icon={
          <Pressable onPress={onClick}>
            <svgs.CalendarIcon width={iconSize || 25} height={iconSize || 25} />
          </Pressable>
        }
        showSoftInputOnFocus={true}
        value={value}
        keyboardType="date"
        placeholder="Sun, 26 Dec"
        maxLength={2}
        multiline={false}
        secureTextEntry={false}
        theme={{
          textInputBorderWidth: 1,
          // textInputBorderColor: theme.textInputBorderColor,
          overflow: "hidden",
        }}
        onFocus={props.onFocus || null}
        onBlur={props.onBlur || null}
        textInputStyles={textInputStyles || null}
        textInputHeight={40}
      />
    );
  });

  const getYear = (date) => {
    return years.indexOf(date.getYear());
  };

  const getMonth = (date) => {
    return date.getMonth();
  };

  return (
    <DatePicker
      portalId="root-portal"
      selected={selected}
      onChange={(date) => onChangeDate(date)}
      timeInputLabel={showTimeInput ? "Select Time: " : ""}
      showTimeInput={showTimeInput}
      minDate={minDate}
      timeFormat="HH:mm"
      dateFormat={dateFormat}
      maxDate={maximumDate}
      customInput={<CustomInputComponent />}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            style={{ marginRight: 10 }}
          >
            {"<"}
          </button>

          {showYearsDropdown ? (
            <select
              value={years[getYear(date)]}
              onChange={({ target: { value } }) => changeYear(value)}
              style={{ marginRight: 5 }}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : null}

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
            style={{ marginLeft: 5 }}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            style={{ marginLeft: 10 }}
          >
            {">"}
          </button>
        </div>
      )}
    />
  );
}
