export default {
  "Icon" : require("./images/Icon.png"),
  "SplashBackground" : require("./images/SplashBackground.png"),
  "ZipIcon" : require("./images/ZipIcon.png"),
  "ZipPlusTruck" : require("./images/ZipPlusTruck.png"),
  "logo_transparent copy 2" : require("./images/logo_transparent copy 2.png"),
  "logo_transparent copy" : require("./images/logo_transparent copy.png"),
  "logo_transparent" : require("./images/logo_transparent.png"),
  "logo_transparent_old" : require("./images/logo_transparent_old.png"),
  "textIcon" : require("./images/textIcon.png"),
  "track_truck" : require("./images/track_truck.png")
};
