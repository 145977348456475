import React, {
  useContext,
  useReducer,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  View,
  FlatList,
  SectionList,
  KeyboardAvoidingView,
  Dimensions,
} from "react-native";
import SearchManager from "@presto-services/features/search/SearchManager";
import ThemeContext from "@presto-contexts/ThemeContext";
import SearchableScreen from "@presto-screens/SearchableScreen/SearchableScreen";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import { usePagination } from "../../hooks/pagination";
import { VerticalSpacing } from "@presto-components/Spacing";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import I18n from "i18n-js";
import _ from "lodash";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { RowButton } from "@presto-components/PrestoButton";
import AsyncStorage from "@react-native-community/async-storage";
import { useSafeArea } from "react-native-safe-area-context";
import PrestoText from "@presto-components/PrestoText";
import AnalyticsManager from "../../common/AnalyticsManager";

const windowHeight = Dimensions.get("window").height;

export function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_SEARCH_QUERY":
      return {
        ...state,
        ...{ query: payload, auto_complete_results: [] },
      };
    case "SET_SEARCH_FILTER":
      return {
        ...state,
        ...payload,
      };
    case "SET_SORT_TYPE":
      return {
        ...state,
        sort_type: payload,
      };
    case "SET_SEARCH_RESULTS":
      return { ...state, search_results: payload, auto_complete_results: [] };
    case "SET_RECENT_SEARCHES":
      return { ...state, recent_searches: payload };

    case "SET_AUTO_COMPLETE_RESULTS":
      return { ...state, auto_complete_results: _.slice(payload, 0, 7) };
  }
  return state;
}

export default function SearchScreen({ navigation, route }) {
  const searchParams = route.params.searchParams;
  const catalogId = route.params.catalogId;
  const showSimilar = route.params?.showSimilar;
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const ref = useRef();
  const [state, dispatch] = useReducer(reducer, {
    query: searchParams.query,
    category_id: searchParams.category?.id,
    company_name: searchParams.company_name,
    brand_name: searchParams.brand_name,
    molecule: searchParams.molecule,
    therapy_area_simple: searchParams.therapy_area_simple,
    sort_type: "listing",
    on_offer: undefined,
    auto_complete_results: [],
    is_new: searchParams.is_new || false,
    bestsellers: searchParams.bestsellers || false,
    price_range: searchParams.price_range || null,
    region: searchParams.region || null,
  });
  ref.current = { ...state, molecule: searchParams?.molecule };

  const insets = useSafeArea();

  const isSearchEmpty = () => {
    return (
      _.isEmpty(state.query) &&
      _.isEmpty(state.category_id) &&
      _.isEmpty(state.company_name) &&
      _.isEmpty(state.region) &&
      _.isEmpty(state.brand_name) &&
      _.isEmpty(state.molecule) &&
      _.isEmpty(state.therapy_area_simple) &&
      !state.is_new &&
      !state.bestsellers
    );
  };

  const searchPage = useCallback((pageNumber, success, failure) => {
    const params = {
      category_id: ref.current.category_id,
      search_string: ref.current.query,
      company_name: ref.current.company_name,
      brand_name: ref.current.brand_name,
      region: ref.current.region,
      molecule: ref.current.molecule,
      therapy_area_simple: ref.current.therapy_area_simple,
      on_variation_offer: ref.current.on_offer,
      [ref.current.sort_type]: true,
      page: pageNumber,
      is_new: ref.current.is_new,
      bestsellers: ref.current.bestsellers,
      price_range: ref.current.price_range,
      name_asc: true,
      catalog_id: catalogId,
    };
    SearchManager.fuzzySearchCategoryItem(
      params,
      (response) => {
        success(response.hits.hits);
      },
      (error) => {
        failure(error);
      }
    );
  }, []);
  const [
    results,
    loadNextPage,
    loadFirstPage,
    loading,
    pageLoaded,
  ] = usePagination(searchPage);

  useEffect(() => {
    loadFirstPage();
  }, [
    state.query,
    state.sort_type,
    state.company_name,
    state.region,
    state.on_offer,
    state.price_range,
    state.molecule,
    searchParams,
  ]);

  const onBack = () => {
    navigation.goBack();
  };

  const renderItem = ({ item, index }) => {
    return <ItemCard key={item._source.id} item={item._source}></ItemCard>;
  };
  const onSortChanged = (key) => {
    dispatch({
      type: "SET_SORT_TYPE",
      payload: key,
    });
  };

  const onFilterChanged = (params) => {
    dispatch({
      type: "SET_SEARCH_FILTER",
      payload: params,
    });
  };

  const onSort = () => {
    AnalyticsManager.send("sort_items");
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "SORT_OPTIONS_SCREEN",
        screenParams: {
          onSortChanged: onSortChanged,
          selectedSort: state.sort_type,
        },
      },
    });
  };

  const onFilter = () => {
    AnalyticsManager.send("filter_items");
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "FILTER_OPTIONS_SCREEN",
        screenParams: {
          onFilterChange: onFilterChanged,
          selectedManufacturer: state.company_name,
          onOffer: state.on_offer,
          selectedPrice: state.price_range,
        },
      },
    });
  };

  const searchInputRef = useRef();

  useEffect(() => {
    if (route.params.focus) {
      setTimeout(() => searchInputRef.current?.focus(), 100);
    }
  }, []);

  const renderSectionHeader = ({ section }) => {
    if (!section.title || section.title === "" || section.data?.length === 0) {
      return null;
    }
    return (
      <View
        style={{
          paddingHorizontal: theme.primaryPadding,
          marginTop: 15,
          height: 40,
          flexDirection: "row",
          alignItems: "flex-end",
        }}
      >
        <PrestoText fontStyle="medium" color={theme.paragraph}>
          {section.title || ""}
        </PrestoText>
      </View>
    );
  };

  const onSearchItemPress = (query) => {
    onSearch(_.toLower(query));
  };

  const onSearch = async (text) => {
    if (!_.isEmpty(text)) {
      dispatch({ type: "SET_SEARCH_QUERY", payload: text });
      const recentSearchesJson = await AsyncStorage.getItem("RECENT_SEARCHES");
      const recentSearches = recentSearchesJson
        ? JSON.parse(recentSearchesJson)
        : [];
      const filteredSearches = _.filter(recentSearches, (s) => s !== text);
      const newRecentSearches = [text, ...filteredSearches];
      const splicedSearches = _.slice(newRecentSearches, 0, 6);
      await AsyncStorage.setItem(
        "RECENT_SEARCHES",
        JSON.stringify(splicedSearches)
      );
    }
  };

  useEffect(() => {
    async function fet() {
      const recentSearchesJson = await AsyncStorage.getItem("RECENT_SEARCHES");
      dispatch({
        type: "SET_RECENT_SEARCHES",
        payload: recentSearchesJson ? JSON.parse(recentSearchesJson) : [],
      });
    }
    fet();
  }, []);

  const renderSearchItem = ({ item: query, index }) => {
    return (
      <View key={`index-${index}-query`}>
        <RowButton
          title={query}
          rightTitle={""}
          onPress={() => {
            onSearchItemPress(query);
          }}
          showDisclosure={true}
        />
        <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
      </View>
    );
  };

  const renderEmptySearchPage = () => {
    const sections = [
      {
        title: I18n.t("screen_messages.common.recent_searches"),
        key: "recent_searches",
        data: state.recent_searches || [],
      },
      {
        title: I18n.t("screen_messages.common.popular_searches"),
        key: "popular_searches",
        data: ["Paracetamol", "Ibucap", "Headache"],
      },
    ];
    return (
      <SectionList
        keyExtractor={(_, index) => "index-" + index}
        stickySectionHeadersEnabled={false}
        renderSectionHeader={renderSectionHeader}
        sections={sections}
        renderItem={renderSearchItem}
        layout={"default"}
        showsHorizontalScrollIndicator={false}
      />
    );
  };

  const loadAutoComplete = useCallback((text) => {
    const params = {
      category_id: ref.current.category_id,
      search_string: text,
      company_name: ref.current.company_name,
      region: ref.current.region,
      brand_name: ref.current.brand_name,
      molecule: ref.current.molecule,
      therapy_area_simple: ref.current.therapy_area_simple,
      on_variation_offer: ref.current.on_offer,
      [ref.current.sort_type]: true,
      page: 1,
      fuzziness: "AUTO",
      is_new: ref.current.is_new,
      catalog_id: catalogId,
    };

    SearchManager.fuzzySearchCategoryItem(
      params,
      (response) => {
        dispatch({
          payload: response.hits.hits,
          type: "SET_AUTO_COMPLETE_RESULTS",
        });
      },
      () => {}
    );
  }, []);

  const onSearchChange = (text) => {
    if (text && text.length >= 3) {
      loadAutoComplete(text);
    }
  };

  const onSearchFocus = () => {
    AnalyticsManager.send("category_search");
  };

  const renderAutoCompleteItem = ({ item, index }) => {
    return (
      <View key={`index-${index}-query`}>
        <RowButton
          title={item._source.name}
          rightTitle={""}
          onPress={() => {
            onSearchItemPress(item._source.name);
          }}
          showDisclosure={false}
        />
        <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
      </View>
    );
  };

  const renderAutoComplete = () => {
    return (
      <View
        style={{
          backgroundColor: "transparent",
          position: "absolute",
          height: 300,
          top: 10,
          left: 0,
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 100,
        }}
      >
        <View
          style={{
            backgroundColor: "white",
            position: "absolute",
            height: "100%",
            width: "95%",
            borderRadius: 10,
            borderWidth: 1,
            borderColor: "lightgray",
          }}
        >
          <FlatList
            keyExtractor={(_, index) => "index-" + index}
            data={state.auto_complete_results}
            renderItem={renderAutoCompleteItem}
            layout={"default"}
            showsHorizontalScrollIndicator={false}
          />
        </View>
      </View>
    );
  };
  let searchResultsTitle =
    state.query ||
    state.company_name ||
    state.region ||
    state.brand_name ||
    state.molecule ||
    state.therapy_area_simple ||
    "";

  if (state.is_new) {
    searchResultsTitle = "New on Zip";
  } else if (state.bestsellers) {
    searchResultsTitle = "Best sellers";
  }

  return (
    <SearchableScreen
      onFocus={onSearchFocus}
      onSearchChange={onSearchChange}
      showOptions={!isSearchEmpty()}
      canGoback={true}
      onBack={onBack}
      onSearch={onSearch}
      onSort={onSort}
      sortEnabled={false}
      onFilter={onFilter}
      inputref={searchInputRef}
      placeholder={I18n.t("screen_messages.common.search_with_query", {
        query: searchParams.category?.name || searchResultsTitle,
      })}
    >
      {state.auto_complete_results?.length > 0 ? renderAutoComplete() : null}
      {isSearchEmpty() ? (
        renderEmptySearchPage()
      ) : (
        <View style={{ flex: 1, width: "100%" }}>
          <KeyboardAvoidingView behavior="height" enabled>
            <FlatList
              data={results}
              style={{ flex: 1 }}
              renderItem={renderItem}
              keyExtractor={(item) => item._source.id}
              style={{ paddingTop: 5, height: windowHeight - 150 }}
              ListFooterComponent={() => {
                return <VerticalSpacing size={100} />;
              }}
              ListHeaderComponent={() => {
                return (
                  <View>
                    {/* <DealsComponent /> */}
                    {results.length > 0 &&
                    ((state.query && state.query.length > 0) ||
                      state.is_new ||
                      state.bestsellers) ? (
                      <View
                        style={{ paddingHorizontal: theme.containerPadding }}
                      >
                        <PrestoText
                          fontStyle="semibold"
                          size={14}
                          color={theme.primary}
                        >
                          {searchResultsTitle
                            ? I18n.t(
                                "screen_messages.common.search_results_for",
                                {
                                  query: searchResultsTitle,
                                }
                              )
                            : I18n.t(
                                "screen_messages.common.search_results_default_prefix"
                              )}
                        </PrestoText>
                      </View>
                    ) : null}
                    {results.length == 0 && !loading && pageLoaded > 0 ? (
                      <View
                        style={{ paddingHorizontal: theme.containerPadding }}
                      >
                        <PrestoText
                          fontStyle="semibold"
                          size={14}
                          color={theme.primary}
                        >
                          {searchResultsTitle
                            ? I18n.t(
                                "screen_messages.common.no_search_results_for",
                                {
                                  query: searchResultsTitle,
                                }
                              )
                            : I18n.t(
                                "screen_messages.common.no_search_results_default_prefix"
                              )}
                        </PrestoText>
                      </View>
                    ) : null}
                  </View>
                );
              }}
              onEndReached={
                results.length > 0
                  ? () => {
                      console.log("onEndREached");
                      loadNextPage();
                    }
                  : undefined
              }
              onEndReachedThreshold={0.25}
            />
          </KeyboardAvoidingView>
        </View>
      )}
    </SearchableScreen>
  );
}
