import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { View, ScrollView, Alert, Platform, Text, TouchableOpacity } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import moment from "moment";
import _ from "lodash";
import { usePagination } from "@presto-hooks/appointmentPagination";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import SalesTable from "@presto-screen-components/SalesReports/SalesTable";
import AdvanceSearchBar from "@presto-screen-components/SalesReports/AdvanceSearchBar";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoText from "@presto-components/PrestoText";
import StylistManager from "@presto-services/features/pos_stylist/StylistManager";
import asyncify from "@presto-common/Asyncify";
import UserContext from "@presto-contexts/UserContext";
import { alertBox } from "@presto-common/Alert";
import LoadingContainer from "@presto-components/LoadingContainer";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import HeaderWithTabs from "@presto-screen-components/Headers/HeaderWithTabs";
import { WebView } from "react-native-webview";
import * as Linking from "expo-linking";
import NetInfo from "@react-native-community/netinfo";

const AsyncStylistManager = asyncify(StylistManager);

export default function SalesDbReports({ navigation, route }) {
  useEffect(() => {
    init();
  }, [loading, embedUrl]);
  const isWeb = Platform.OS == 'web'
  const init = () => { };
  const [loading, setLoading] = useState(false);
  const [embedUrl, setEmbedUrl] = useState("");
  const { theme } = useContext(ThemeContext);
  let finalUrl = "";
  const insets = useSafeAreaInsets();

  const onPressTab = (tab) => {
    let tempTabs = [];
    setTabs((prev) => {
      tempTabs = _.cloneDeep(prev);
      return prev;
    });

    tempTabs = _.map(tempTabs, (tabItem) => {
      let isActive = _.includes([tab.id], tabItem.id);
      return {
        ...tabItem,
        isActive,
      };
    });

    setTabs(tempTabs);
    afterPressTab(tab);
  };

  const afterPressTab = (tab) => {
    let dIndex = 0;
    if (tab.id == "dashboard") {
      dIndex = 0;
    } else if (tab.id == "sales") {
      dIndex = 1;
    } else if (tab.id == "products") {
      dIndex = 2;
    } else if (tab.id == "purchases") {
      dIndex = 3;
    } else if (tab.id == "insights") {
      dIndex = 4;
    } else if (tab.id == "expiry") {
      dIndex = 5;
    }
    setLoading(true);
    EmployeeManager.embeddedDashboardList(
      {},
      (response) => {
        EmployeeManager.embeddedDashboardUrl(
          { dashboard_id: response?.data[dIndex]?.id },
          (response) => {
            setLoading(false);
            setEmbedUrl(response.data.embed_url);
          },
          (error) => {
            setLoading(false);
            console.log("Error here", error);
            alertBox(error.title, error.message);
          }
        );
      },
      (error) => {
        setLoading(false);
        console.log("Error here", error);
        alertBox(error.title, error.message);
      }
    );
  };

  const [tabs, setTabs] = useState(
    _.compact([
      {
        id: "dashboard",
        text: I18n.t("screen_messages.reports.dashboard"),
        defaultStyles: {
          paddingLeft: 25,
          paddingRight: 25,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
        isActive: true,
      },
      {
        id: "sales",
        text: I18n.t("screen_messages.reports.sales"),
        defaultStyles: {
          paddingLeft: 25,
          paddingRight: 25,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
        isActive: false,
      },
      {
        id: "products",
        text: I18n.t("screen_messages.reports.products"),
        defaultStyles: {
          paddingLeft: 25,
          paddingRight: 25,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
        isActive: false,
      },
      {
        id: "purchases",
        text: I18n.t("screen_messages.reports.purchases"),
        defaultStyles: {
          paddingLeft: 25,
          paddingRight: 25,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
        isActive: false,
      },
      {
        id: "expiry",
        text: I18n.t("screen_messages.reports.expiring"),
        defaultStyles: {
          paddingLeft: 25,
          paddingRight: 25,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
        isActive: false,
      },
      {
        id: "insights",
        text: I18n.t("screen_messages.reports.insights"),
        defaultStyles: {
          paddingLeft: 25,
          paddingRight: 25,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
        isActive: false,
      },
    ])
  );

  if (!embedUrl) {
    EmployeeManager.embeddedDashboardList(
      {},
      (response) => {
        console.log(response);
        EmployeeManager.embeddedDashboardUrl(
          { dashboard_id: response.data[0].id },
          (response) => {
            setEmbedUrl(response.data.embed_url);
          },
          (error) => {
            console.log("Error here", error);
            alertBox(error.title, error.message);
          }
        );
      },
      (error) => {
        console.log("Error here", error);
        alertBox(error.title, error.message);
      }
    );
  }

  return (
    <>
      <LoadingContainer
        bgColor={"transparent"}
        loading={loading}
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          marginTop: insets.top,
        }}
      >
        <HeaderWithTabs
          isActiveStyles={{
            backgroundColor: "#D8D8D8",
          }}
          tabs={tabs}
          onPressTab={onPressTab}
        />
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            height: "100%",
            backgroundColor: isWeb ? theme.white : theme.screenBackgroundColor,
            flex: 1,
          }}
        >
          <View
            style={{
              backgroundColor: theme.screenBackgroundColor,
              flex: 1,
            }}
          >
            {embedUrl ? (
              isWeb
                ? <iframe
                  style={{ height: "100%" }}
                  src={embedUrl}
                />
                :
                <>
                  <WebView
                    originWhitelist={["*"]}
                    javaScriptEnabled={true}
                    startInLoadingState={true}
                    scalesPageToFit={true}
                    domStorageEnabled={true}
                    onError={(err) => console.log(err)}
                    onHttpError={(err) => console.log(err)}
                    allowFileAccess={true}
                    allowFileAccessFromFileURLs={true}
                    allowUniversalAccessFromFileURLs={true}
                    allowingReadAccessToURL={true}
                    mixedContentMode="always"
                    source={{
                      uri: embedUrl,
                    }}
                    style={{ flex: 1 }}
                  ></WebView>
                </>

            ) : null}
          </View>
        </View>
      </LoadingContainer>
    </>
  );
}
