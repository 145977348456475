import DBBaseManager from "@presto-db-managers/DBBaseManager";
import DBCartItemManager from "@presto-db-managers/DBCartItemManager";
import DBCategoryItemManager from "@presto-db-managers/DBCategoryItemManager";
import DBCouponManager from "@presto-db-managers/DBCouponManager";
import _ from "lodash";
import { Platform } from "react-native";

export default class DBCartManager extends DBBaseManager {
  constructor() {
    super();
    this.tableName = "carts";
    this.fillables = [
      "id",
      "user_id",
      "merchant_id",
      "outlet_id",
      "total_price",
      "original_gross_amount",
      "sub_total_price",
      "total_taxes",
      "discounts",
      "total_discounts",
      "coupon_code",
      "discount_response",
      "aggregate_discounts",
      "_sync_status", // choices are: pending, synced
      "_user",
    ];
    this.datatypes = {
      discounts: "JSON",
      discount_response: "JSON",
      _user: "JSON",
    };
  }

  fetchCart(params) {
    return this.fetchCarts(params)
      .then((results) => {
        let item = _.first(results);
        return !_.isEmpty(item) ? item : {};
      })
      .catch((e) => {
        return e;
      });
  }

  fetchCarts(params) {
    const isWeb = Platform.OS == 'web'
    let query = `SELECT * FROM ${this.tableName}`,
      whereBucket = [],
      args = [];

    if (params?.id) {
      whereBucket.push(["id=?"]);
      args.push(params.id);
    }

    if (!_.isEmpty(whereBucket)) {
      let whereJoined = _.join(whereBucket, " AND ");
      query = `${query} WHERE ${whereJoined};`;
    }
    if (isWeb) return new Promise((res, rej) => res({}));
    return this.asyncExec({
      sql: query,
      args: args,
    }).then(async ({ rows }) => {
      let cartItemManager = new DBCartItemManager();
      let categoryItemManager = new DBCategoryItemManager();
      let bBCouponManager = new DBCouponManager();

      if (rows) {
        for (let index in rows) {
          let originalGrossAmount = 0,
            totalDiscounts = 0,
            totalTaxes = 0,
            aggregateDiscounts = 0,
            coupon = null;

          let cart = this.applyDatatypeToObject(rows[index]);
          cart.items = await cartItemManager.cartItems({ cart_id: cart.id });

          if (cart.coupon_code) {
            coupon = await bBCouponManager.getCouponByCode(cart.coupon_code);
          }

          if (cart.items) {
            for (let jIndex in cart.items) {
              let cartItem = _.cloneDeep(cart.items[jIndex]);
              let lineItem = await categoryItemManager.first(cartItem.item_id);
              let quantity = Number(cartItem.quantity);
              let totalPrice = categoryItemManager.getTotalPrice(lineItem);

              if (cartItem._variation_id) {
                const variation = _.find(_.get(lineItem, "variations"), [
                  "id",
                  cartItem._variation_id,
                ]);

                if (variation) {
                  cartItem.variation = variation;
                  totalPrice = categoryItemManager.getTotalPrice(variation);
                }
              }

              cartItem.other_data = {
                variation_free_quantity: _.get(
                  lineItem,
                  "other_data.variation_free_quantity"
                ),
                old_price: _.get(lineItem, "other_data.old_price"),
                variation_quantity: _.get(
                  lineItem,
                  "other_data.variation_quantity"
                ),
              };

              let totalItemPrice = _.multiply(totalPrice, quantity);
              totalItemPrice = _.multiply(totalItemPrice, 100);

              let totalItemTax = _.multiply(
                categoryItemManager.getTax(lineItem),
                quantity
              );
              totalItemTax = _.multiply(totalItemTax, 100);

              originalGrossAmount += totalItemPrice;
              totalTaxes += totalItemTax;
              aggregateDiscounts +=
                cartItemManager.itemDiscount(lineItem) * quantity;

              cart.items[jIndex] = cartItem;
            }
          }

          if (_.isObject(cart.discount_response)) {
            totalDiscounts = Number(
              _.get(cart, "discount_response.discount_value", 0)
            );
            if (isNaN(totalDiscounts)) {
              totalDiscounts = 0;
            }

            if (
              coupon &&
              coupon.max_discount > 0 &&
              totalDiscounts >= coupon.max_discount * 100
            ) {
              totalDiscounts = coupon.max_discount * 100;
            }

            cart.discount_response = {
              ...cart.discount_response,
              discount_value: totalDiscounts,
            };
          }

          cart.original_gross_amount = originalGrossAmount;
          cart.total_discounts = totalDiscounts;
          aggregateDiscounts = aggregateDiscounts * 100;
          cart.aggregate_discounts = aggregateDiscounts + totalDiscounts;
          cart.item_promotion_discounts = aggregateDiscounts;
          cart.total_taxes = totalTaxes;
          let tempAddTotalPrice = _.sum([originalGrossAmount, totalTaxes]);
          let tempSubTotalPrice = cart.total_discounts;
          cart.total_price = _.subtract(tempAddTotalPrice, tempSubTotalPrice);

          if (cart.total_price < 0) {
            cart.total_price = 0;
          }
          rows[index] = cart;
        }
      }
      return rows;
    });
  }

  createCart({ cart_id, merchant_id, outlet_id }) {
    let id = cart_id ? cart_id : this.generateId();
    const isWeb = Platform.OS == 'web'
    let columns = {
      id: id,
      user_id: null,
      merchant_id: merchant_id,
      outlet_id: outlet_id,
      total_price: 0,
      original_gross_amount: 0,
      sub_total_price: 0,
      total_taxes: 0,
      discounts: JSON.stringify([]),
      total_discounts: 0,
      discount_response: JSON.stringify({}),
    };
    let keys = _.keys(columns);
    let args = _.values(columns);
    let joinedColumns = _.join(keys, ", ");
    let placeHolders = _.join(_.fill(Array(keys.length), "?"), ", ");
    let query = `INSERT INTO ${this.tableName} (${joinedColumns}) VALUES (${placeHolders})`;
    return this.first(cart_id).then(async (cart) => {
      if (!_.isEmpty(cart)) {
        return await this.fetchCart({
          id: id,
        });
      }

      if (isWeb) return {}
      return this.asyncExec({ sql: query, args: args })
        .then(async ({ insertId, rows }) => {
          let param = {
            id: id,
          };
          return await this.fetchCart(param);
        })
        .catch((e) => {
          throw e;
        });
    });
  }

  async clearCart({ id }) {
    const isWeb = Platform.OS == 'web'
    if (_.isEmpty(id)) {
      return this.fetchCart({ id });
    }
    let query = `DELETE FROM ${this.tableName} WHERE id=?;`;
    let args = [id];
    if (isWeb) return []
    return this.asyncExec({ sql: query, args: args })
      .then(async ({ insertId, rows }) => {
        const obj = new DBCartItemManager();
        await obj.clearCartItems(id);
        return await this.fetchCart({ id });
      })
      .catch((e) => {
        throw e;
      });
  }

  clearAllCarts() {
    this.fetchCarts({}).then((rows) => {
      _.forEach(rows, (row) => {
        this.clearCart({ id: row.id });
      });
    });
  }

  applyCoupon({ merchant_id, coupon_code, id }) {
    const bBCouponManager = new DBCouponManager();
    return bBCouponManager
      .first(coupon_code, "code")
      .then((coupon) => {
        if (_.isEmpty(coupon)) {
          const error = new Error(`Invalid Coupon ${code} does not exists.`);
          throw new error();
        }
        if (!bBCouponManager.isOfflineSupported(coupon)) {
          const error = new Error(`Coupon ${code} is not supported offline`);
          throw new error();
        }
        return coupon;
      })
      .then(async (coupon) => {
        const cart = await this.fetchCart({ id });
        if (_.isEmpty(cart)) {
          const error = new Error(`Invalid cart`);
          throw new error();
        }

        let totalPrice = this.cartTotalPrice(cart);
        if (!_.isObject(cart.discount_response)) {
          cart.discount_response = {};
        }

        let discounts = [],
          discountValue = bBCouponManager.computeDiscountOnValue(
            coupon,
            totalPrice
          );

        if (discountValue && discountValue > totalPrice) {
          throw new Error(`Coupon Not Applicable`);
        }

        if (_.isArray(cart.discounts)) {
          discounts = _.cloneDeep(cart.discounts);
        }

        console.log("app debug discounts", [cart, discounts]);

        if (discountValue > 0) {
          discounts.push({
            name: coupon_code,
            value: discountValue,
          });
        }

        cart.discount_response = {
          ...cart.discount_response,
          discount_value: discountValue,
        };

        let query = {
          coupon_code: coupon_code,
          discount_response: cart.discount_response,
          discounts: discounts,
        };
        await this.updateItem(query, (item) => {
          let values = [id],
            whereQuery = "WHERE id=?";
          return { values, whereQuery };
        });
        return await this.fetchCart({ id });
      });
  }

  invalidateAppliedCouponCode({ id }) {
    const bBCouponManager = new DBCouponManager();
    return this.fetchCart({ id }).then(async (cart) => {
      let coupon = null,
        updateQuery = null;
      if (cart?.discount_response && cart?.coupon_code) {
        coupon = await bBCouponManager.getCouponByCode(cart?.coupon_code);
      }

      if (coupon) {
        let totalPrice = this.cartTotalPrice(cart);
        if (!_.isObject(cart.discount_response)) {
          cart.discount_response = {};
        }

        let discounts = [],
          discountValue = bBCouponManager.computeDiscountOnValue(
            coupon,
            totalPrice
          );
        if (_.isArray(cart.discounts)) {
          discounts = _.cloneDeep(cart.discounts);
        }

        if (discountValue > 0) {
          discounts.push({
            name: cart.coupon_code,
            value: discountValue,
          });
        }

        cart.discount_response = {
          ...cart.discount_response,
          discount_value: discountValue,
        };

        updateQuery = {
          coupon_code: cart.coupon_code,
          discount_response: cart.discount_response,
          discounts: discounts,
        };
      } else {
        updateQuery = {
          coupon_code: null,
          discount_response: {},
          discounts: [],
        };
      }
      await this.updateItem(updateQuery, (item) => {
        let values = [id],
          whereQuery = "WHERE id=?";
        return { values, whereQuery };
      });
    });
  }

  removeCoupon({ merchant_id, coupon_code, id }) {
    let query = {
      coupon_code: null,
      discount_response: {},
      discounts: [],
    };
    return this.updateItem(query, (item) => {
      let values = [id],
        whereQuery = "WHERE id=?";
      return { values, whereQuery };
    }).then(({ rows }) => {
      return this.fetchCart({ id });
    });
  }

  cartTotalPrice(item) {
    let val = _.get(item, "total_price");

    if (isNaN(val)) {
      val = 0;
    }
    return val;
  }

  addUserToCart({ user, id }) {
    let query = {
      user_id: user.id,
      _user: user,
    };
    return this.updateItem(query, (item) => {
      return {
        values: [id],
        whereQuery: `WHERE id=?`,
      };
    }).then(({ rowsAffected }) => {
      return rowsAffected;
    });
  }

  checkout({ user_id, id }) {
    let query = {
      user_id: user_id,
    };
    return this.updateItem(query, (item) => {
      return {
        values: [id],
        whereQuery: `WHERE id=?`,
      };
    }).then(({ rowsAffected }) => {
      return rowsAffected;
    });
  }
}
