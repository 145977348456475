import React, { useState, useContext, useEffect, useRef } from "react";
import { FlatList, View, Pressable, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import CartContext from "@presto-contexts/CartContext";
import ServiceCartContext from "@presto-contexts/ServiceCartContext";
import LoadingContainer from "@presto-components/LoadingContainer";
import _ from "lodash";
import CouponManager from "@presto-services/features/coupons/CouponManager";
import CouponComponent from "@presto-components/Coupons/Coupon";
import utils from "../../utils";
import I18n from "i18n-js";
import TextInputWithButtonComponent from "@presto-components/TextInputs/TextInputWithButtonComponent";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoText from "@presto-components/PrestoText";
import OffersCardModel from "@presto-component-models/OffersCardModel";
import ModalMobile from "@presto-components/Modals/Modal.bupos";
import { VerticalSpacing } from "@presto-components/Spacing";
import PrestoIcon from "@presto-components/PrestoIcon";
import ModalContext from "@presto-contexts/ModalContext";
import Config from "@presto-common/config";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import HeaderWithBackAndLogoAndRightTitle from "@presto-components/Headers/HeaderWithBackAndLogoAndRightTitle";
import CouponDataSource from "@presto-datasources/CouponDataSource";
import { useAssets } from "presto-react-components";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";

export default function Coupons({ route, navigation }) {
  const isMobileWeb = utils.isMobileWeb();
  const isNextJsApp = utils.isNextJsApp();
  const isMobile = utils.isMobile();
  const isWeb = Platform.OS == 'web'
  const { theme } = useContext(ThemeContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const { popRightModal } = useContext(ModalContext);
  const { svgs } = useAssets();
  console.log(modalVisible, "siodfaoisdjfijalij")
  const context =
    route.params.cartType == "cart"
      ? useContext(CartContext)
      : useContext(ServiceCartContext);

  const { serviceCart, cart } = context;
  const { applyCouponToCart, removeCouponFromCart, refreshCart } = context;
  const checkoutCart = route.params.cartType == "cart" ? cart : serviceCart;
  const offlineEnabledAPIForCouponListing =
    route.params.offlineEnabledAPIForCouponListing ?? false;
  const countOfItemInCart = cart?.items?.length > 0 ? cart?.items?.length : 0;

  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [textCouponCode, setTextCouponCode] = useState("");
  const { Navigator } = useContext(NavigatorContext);
  const modalRef = useRef();
  const goBack = () => {
    modalRef.current?.close();
    setTimeout(() => {
      Navigator.emit({ event: "goBack" });
    }, 100);
  };
  useEffect(() => {
    if (Config.features.app_user == "cc_agent") {
      getEmployeeCoupons();
    } else if (Config.features.app_user == "user") {
      getUserCoupons();
    }
  }, []);

  const getEmployeeCoupons = () => {
    setLoading(true);

    let params = {
      only_active: true,
      exclude_expired_coupon: true,
      exclude_referral_coupons: true,
      enableOffline: offlineEnabledAPIForCouponListing,
    };
    CouponDataSource.getCoupons(
      params,
      (response) => {
        setLoading(false);
        setCoupons(response.data);
      },
      (error) => {
        setLoading(false);
        alert("Unable to Fetch Coupons");
      }
    );
  };

  const getUserCoupons = () => {
    setLoading(true);
    let params = {};
    if (route?.params?.merchant_id) {
      params.merchant_id = route.params.merchant_id;
    }
    CouponManager.getMyCoupons(
      (response) => {
        setLoading(false);
        setCoupons(response.data);
      },
      (error) => {
        setLoading(false);
        console.log("Coupons error : ", error);
      },
      params
    );
  };

  const applyCoupon = async (couponCode) => {
    setLoading(true);
    const [error, response] = await applyCouponToCart(couponCode);
    setLoading(false);
    if (error) {
      setErrorMessage(utils.errorMessage(error));
      removeCouponFromCart(couponCode);
    } else {
      refreshCart();
      if (response.data && _.isEmpty(response.data.discount_response)) {
        setErrorMessage(
          I18n.t("error_messages.sorry_coupon_cannot_applied_for_code", {
            code: couponCode,
          })
        );
        removeCouponFromCart(couponCode);
      } else {
        setDiscountAmount(
          response.data.discount_response?.discount_value / 100
        );
        setErrorMessage("");
        setModalVisible(true);
      }
      if (!isWeb) onPressBack();
    }
  };

  const onTextCouponApply = () => {
    if (!_.isEmpty(textCouponCode)) {
      applyCoupon(textCouponCode);
    }
  };

  const onCouponChange = ({ nativeEvent: { text } }) => {
    setTextCouponCode(text);
  };

  const onApplyCoupon = async (coupon) => {
    applyCoupon(coupon.code);
  };

  const onRemoveCoupon = async (coupon) => {
    setLoading(true);
    const [error, response] = await removeCouponFromCart(coupon.title);
    setLoading(false);
    refreshCart();
    if (error) {
      setErrorMessage(utils.errorMessage(error));
    } else {
      setErrorMessage("");
    }
  };

  const renderCoupon = ({ item, index }) => {
    return (
      <View key={index} style={{ backgroundColor: theme.white }}>
        <CouponComponent
          coupon={
            new OffersCardModel({
              coupon: item,
              cart: checkoutCart,
            })
          }
          onApplyRemoveCoupon={() => {
            checkoutCart.coupon_code == item.code
              ? onRemoveCoupon(item)
              : onApplyCoupon(item);
          }}
        />
      </View>
    );
  };

  function showCouponAppliedModal() {
    return modalVisible ? (
      <ModalMobile
        ModelHeight={"30%"}
        ModelWidth={isMobile ? "90%" : "30%"}
        visible={modalVisible}
        modalContainerStyles={{
          position: isNextJsApp ? "fixed" : null,
          alignSelf: "center",
          borderRadius: 20,
        }}
        onDismiss={() => {
          setModalVisible(false);
          console.log(modalVisible, "sadiofjaoisjdfkljaslkdfjlk")
          if (isMobileWeb && isNextJsApp) {
            popRightModal();
          } else {
            Navigator.emit({ event: "goBack" });
          }
        }}
        content={
          <View
            style={{
              flex: 1,
              paddingHorizontal: theme.containerPadding,
              backgroundColor: theme.white,
            }}
          >
            <VerticalSpacing size={23} />
            <PrestoText
              fontStyle="headingFont"
              color={theme.black}
              extraStyle={{
                textAlign: "center",
              }}
            >
              {I18n.t("screen_messages.coupons.coupon_applied")}
            </PrestoText>
            <VerticalSpacing size={20} />
            <PrestoIcon
              icon={<svgs.ActionCompletedIcon2 width={50} height={50} />}
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            />

            <VerticalSpacing size={18} />
            <PrestoText
              fontStyle="body1Font"
              color={theme.header}
              extraStyle={{ textAlign: "center" }}
            >
              {I18n.t("screen_messages.coupons.discount_recieved", {
                total: discountAmount,
              })}
            </PrestoText>
          </View>
        }
      />
    ) : <></>;
  }

  const onPressBack = () => {
    navigation.goBack();
  };

  return (
    <View style={{ backgroundColor: theme.screenBackgroundColor }}>
      <View
        style={{
          paddingTop: 15,
          backgroundColor: "#737682",
          borderTopLeftRadius: 18,
          borderTopRightRadius: 18,
          height: "100%",
          paddingBottom: 20,
        }}
      >
        <LoadingContainer bgColor="transparent" loading={loading}>
          <View style={{ marginHorizontal: theme.primaryPadding }}>
            <View
              style={{
                paddingTop: 20,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <PrestoText
                color={theme.white}
                fontStyle="600.semibold"
                size={14}
              >
                {I18n.t("screen_messages.coupons.code_title")}
              </PrestoText>

              <Pressable onPress={onPressBack}>
                <PrestoText
                  color={theme.white}
                  fontStyle="600.semibold"
                  size={14}
                >
                  {I18n.t("screen_messages.common.back")}
                </PrestoText>
              </Pressable>
            </View>

            <TextInputWithButtonComponent
              labelText=""
              theme={theme}
              value={textCouponCode}
              onChange={onCouponChange}
              placeholder={I18n.t("screen_messages.coupons.code")}
              autoCapitalize="characters"
              buttonText={I18n.t("screen_messages.apply_title")}
              onPressButton={() => onTextCouponApply()}
              autoCorrect={false}
              placeholderSize={18}
              textInputStyle={{
                borderWidth: 2,
                backgroundColor: theme.white,
                color: theme.darkGrey,
                height: 50,
                borderRadius: 6,
                borderColor: theme.primary,
              }}
              primaryContainer={{
                backgroundColor: theme.primary,
                borderRadius: 6,
                height: 35,
              }}
            />

            <View style={{ paddingVertical: 15 }}>
              {errorMessage ? (
                <PrestoText
                  color={theme.failure}
                  fontStyle="600.semibold"
                  size={14}
                >
                  {errorMessage}
                </PrestoText>
              ) : null}
            </View>
          </View>
          <View
            style={{ marginHorizontal: theme.primaryPadding - 10, flex: 1 }}
          >
            <View style={{ paddingBottom: 10, paddingHorizontal: 15 }}>
              <PrestoText
                color={theme.white}
                fontStyle="600.semibold"
                size={14}
              >
                {I18n.t("screen_messages.coupons.choose_these_offers")}
              </PrestoText>
            </View>
            <FlatList
              data={coupons}
              renderItem={renderCoupon}
              scrollEnabled={true}
            />
          </View>
          {showCouponAppliedModal()}
        </LoadingContainer>
      </View>
    </View>
  );
}
