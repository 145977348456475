import React from "react";
import { Image, StyleSheet, Pressable } from "react-native";
import * as Linking from "expo-linking";
import Config from "@presto-common/config";
import { useAssets } from "presto-react-components";

export default function BrandLogoSection() {
  const { images } = useAssets();

  const GotoPresto = () => {
    Platform.OS === "web"
      ? window.open(Config.constants.presto_website, "_blank")
      : Linking.openURL(Config.constants.presto_website);
  };

  return (
    <Pressable
      style={{
        paddingVertical: 10,
        flexDirection: "row",
        justifyContent: "center",
      }}
      onPress={() => GotoPresto()}
    >
      <Image
        source={images.PoweredByPrestoIcon}
        style={{
          height: 18,
          width: 135,
        }}
        resizeMode="cover"
      />
    </Pressable>
  );
}
