import firebase from "firebase";
import AnalyticsConfig from "@presto-app/analytics";
import CommonAnalytics from "../data/analytics";
import config from "@presto-common/config";

class AnalyticsManager {
  initialize = () => {
    this.trackedEvents = _.merge(
      CommonAnalytics.tracked_events,
      AnalyticsConfig.tracked_events
    );
    if (config?.analytics?.firebase) {
      firebase.initializeApp(config.firebaseConfig);
      firebase.analytics();
    }
  };

  send = (eventName, eventAttr) => {
    if (!this.trackedEvents) {
      return;
    }
    const event = this.trackedEvents[eventName];
    if (event) {
      const query = { ...event, ...eventAttr };
      if (config?.analytics?.firebase) {
        analytics().logEvent(eventName, query);
      }
      if (this.isFBEventsEnabled()) {
        this.sendFBEvents(eventName, query);
      }
    }
  };

  sendFBEvents = (eventName, eventAttr) => {
    window.fbq("trackCustom", eventName, eventAttr);
  };

  isFBEventsEnabled = () => {
    return !_.isEmpty(_.get(config, "analytics.facebook.pixel"));
  };

  reportScreenOpened = (screenName) => {
    console.log("Reporting Screen Opened : ", screenName);
  };
}

export default new AnalyticsManager();
