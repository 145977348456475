import React, { useState, useContext, useEffect } from "react";
import { View, FlatList, Dimensions, ScrollView } from "react-native";
import _ from "lodash";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import LoadingContainer from "@presto-components/LoadingContainer";
import PrestoIcon from "@presto-components/PrestoIcon";
import { useAssets } from "presto-react-components";
import PrestoText from "@presto-components/PrestoText";
import OrderManager from "@presto-services/features/orders/OrderManager";
import HeaderWithBackAndLogoAndRightTitle from "@presto-components/Headers/HeaderWithBackAndLogoAndRightTitle";
import OrderCard from "@presto-cards/OrderCard/OrderCard";
import OrderModel from "@presto-component-models/OrderModel";
import UserModel from "@presto-component-models/UserModel";
import CustomerNameWithPhone from "@presto-cards/CustomerInfo/CustomerNameWithPhone";

export default function OrdersList({ route, navigation }) {
  const params = route.params || {};
  const { customer } = params || {};
  const { theme } = useContext(ThemeContext);
  const { svgs } = useAssets();
  const windowWidth = Dimensions.get("window").width;
  const styles = getStyles(theme, windowWidth);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState(undefined);

  useEffect(() => {
    if (customer.phone_number) {
      getOrdersByPhoneNumber();
    }
  }, []);

  const getOrdersByPhoneNumber = () => {
    setLoading(true);
    let params = {
      merchant_id: customer?.merchant_id,
      phone_number: customer?.phone_number,
    };
    OrderManager.getOrdersByPhone(
      params,
      (response) => {
        setOrders(response.data);
        setLoading(false);
      },
      (error) => {
        console.log("Product data Error: ", error);
        setLoading(false);
      }
    );
  };

  const onViewDetails = (item) => {
    navigation.navigate("OrderDetails", { order: item, customer: customer });
  };

  const renderOrder = ({ item, index }) => {
    return (
      <View style={{ width: "100%" }} key={index}>
        <View
          style={{
            paddingVertical: 10,
          }}
        >
          <OrderCard
            order={
              new OrderModel({
                order: item,
              })
            }
            onPress={() => onViewDetails(item)}
          />
        </View>
      </View>
    );
  };

  const onPressBack = () => {
    navigation.goBack();
  };

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: theme.screenBackgroundColor,
      }}
    >
      <View
        style={{
          backgroundColor: "#737682",
          height: "100%",
          borderTopLeftRadius: 18,
          borderTopRightRadius: 18,
        }}
      >
        <LoadingContainer loading={loading}>
          <ScrollView
            style={{
              flex: 1,
              backgroundColor: theme.topHeaderInactiveColor,
            }}
            contentContainerStyle={{ paddingBottom: 100 }}
            keyboardShouldPersistTaps={"always"}
          >
            <View
              style={{
                alignSelf: "center",
                width: "90%",
                paddingTop: 30,
              }}
            >
              <CustomerNameWithPhone
                key={customer?.name}
                customer={new UserModel({ user: customer })}
                onPress={() => {}}
              />
            </View>
            <View style={{ padding: theme.containerPadding }}>
              <View style={{ paddingBottom: 20 }}>
                <PrestoText
                  size={12}
                  color={theme.white}
                  fontStyle={"500.medium"}
                  extraStyle={{ paddingBottom: 10 }}
                >
                  {I18n.t("screen_messages.returns.recent_orders_of")}
                  <PrestoText
                    size={12}
                    fontStyle={"600.semibold"}
                    color={theme.white}
                  >
                    {""} {customer?.name} {""}
                  </PrestoText>
                  {I18n.t("screen_messages.returns.that_can_be_returned")}
                </PrestoText>

                <FlatList
                  data={orders}
                  renderItem={renderOrder}
                  style={{
                    width: "100%",
                    backgroundColor: theme.topHeaderInactiveColor,
                  }}
                  keyExtractor={(item, index) => `${index}`}
                  ListFooterComponent={() => (
                    <View
                      style={{ height: 20, backgroundColor: "transparent" }}
                    ></View>
                  )}
                />
              </View>
            </View>
          </ScrollView>
        </LoadingContainer>
      </View>
    </View>
  );
}

function getStyles(theme) {
  return {
    customerCard: {
      backgroundColor: theme.white,
      ...theme.darkShadow,
      width: "100%",
    },
  };
}
