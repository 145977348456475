import React, { useContext, useState } from "react";
import { View, Pressable, StyleSheet } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import DatePickerComponent from "@presto-components/CalendarComponent/DatePickerComponent";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import { useActionSheet } from "@expo/react-native-action-sheet";
import PrestoIcon from "@presto-components/PrestoIcon";
import { useAssets } from "presto-react-components";
import TextInputDoubleButton from "@presto-components/TextInputButtons/TextInputDoubleButton";
import { alertBox } from "@presto-common/Alert";

export default function AdvanceSearchFields({
  filterOptions,
  setFilterOptions,
  parentLoading,
  setParentLoading,
  callBackAfterSubmit,
}) {
  const { theme } = useContext(ThemeContext);
  const { svgs } = useAssets();
  const styles = getStyles(theme);
  const { showActionSheetWithOptions } = useActionSheet();

  const [statusTypeValue, setStatusTypeValue] = useState(null);
  const [dateTypeValue, setDateTypeValue] = useState(null);
  const [email, setEmail] = useState("");
  const [focusedItem, setFocusedItem] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [statusType, setStatusType] = useState([
    { label: "ALL", value: "ALL" },
    { label: "RECEIVED", value: "RECEIVED" },
    { label: "ACCEPTED", value: "ACCEPTED" },
    { label: "DISPATCHED", value: "DISPATCHED" },
    { label: "DELIVERED", value: "DELIVERED" },
    { label: "REJECTED", value: "REJECTED" },
  ]);

  const [dateType, setDateType] = useState([
    { label: "Created At", value: "created_at" },
    { label: "Received At", value: "received_at" },
    { label: "Accepted At", value: "accepted_at" },
    { label: "Dispatched At", value: "dispatched_at" },
    { label: "Delivered At", value: "delivered_at" },
    { label: "Rejected At", value: "rejected_at" },
    { label: "Cancelled At", value: "cancelled_at" },
    { label: "Failed At", value: "failed_at" },
  ]);

  let maxDate = new Date();
  maxDate.setHours("23", "59", "59");

  const getChoiceValue = (fieldName) => {
    let val = null;
    if (fieldName === "statusTypeValue") {
      val = _.find(statusType, ["label", statusTypeValue]).value;
    }
    if (fieldName === "dateTypeValue") {
      val = _.find(dateType, ["label", dateTypeValue]).value;
    }

    return val;
  };

  const clearUserInputs = () => {
    setStatusTypeValue("");
    setDateTypeValue("");
    setEmail("");
    setStartDate(null);
    setEndDate(null);
  };

  const onSubmit = () => {
    let isValid = true,
      error = null;
    let allFieldsFilled = null;

    allFieldsFilled = _.some(
      [statusTypeValue, dateTypeValue, startDate, endDate, email],
      (field) => _.isEmpty(field)
    );

    if (allFieldsFilled) {
      isValid = false;
      error = {
        title: I18n.t("error_messages.common.missing_information_title"),
        message: I18n.t("error_messages.common.missing_information_desc"),
      };
    }

    if (!isValid) {
      return alertBox(error.title, error.message);
    }

    setParentLoading(true);
    let params = {
      reports_task: {
        report_type: "Order",
        status:
          statusTypeValue == "ALL" ? "" : getChoiceValue("statusTypeValue"),
        date_type: getChoiceValue("dateTypeValue"),
        start_date: startDate,
        end_date: endDate,
        notification_email_id: email,
      },
    };
    EmployeeManager.createReport(
      params,
      (response) => {
        alertBox(
          "Information",
          I18n.t("screen_messages.reports.report_created_success")
        );
        clearUserInputs();
        setParentLoading(false);
        callBackAfterSubmit();
      },
      (error) => {
        console.log("Error here", error);
        alertBox(error.title, error.message);
        setParentLoading(false);
      }
    );
  };

  const showOptions = (fieldName, callBack) => {
    let options = [],
      choices = [],
      keyName = "label";

    if (fieldName === "statusType") {
      choices = statusType;
    }

    if (fieldName === "dateType") {
      choices = dateType;
    }
    options = _.map(choices, keyName);
    if (_.isArray(options)) {
      options.push(I18n.t("screen_messages.common.cancel_text"));
    }

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex: options.length,
      },
      (buttonIndex) => {
        let item = _.get(choices, buttonIndex, null);
        callBack(item);
      }
    );
  };

  const renderLabel = ({ title }) => {
    return (
      <PrestoText fontStyle={"600.semibold"} color={theme.white} size={12}>
        {title}
      </PrestoText>
    );
  };

  const renderTextInput = ({
    editable = true,
    placeholder = "",
    labelTitle = "",
    hideArrow = false,
    onPressIcon,
    onChange,
    value,
  }) => {
    return (
      <View>
        {renderLabel({ title: labelTitle })}
        <VerticalSpacing size={10} />
        <View style={{ height: 50 }}>
          <TextInputDoubleButton
            placeholder={placeholder}
            isEditable={editable}
            searchText={value}
            onSearchText={onChange}
            onNextPress={onPressIcon}
            hidePlus={true}
            hideArrow={hideArrow}
            iconBgColor={theme.primary}
            extraStyle={{
              borderWidth: 0,
            }}
          />
        </View>
      </View>
    );
  };

  const renderDatePicker = ({
    userDate,
    maxDate,
    fieldName = "",
    placeholder = "",
    labelTitle = "",
    onDateChange,
  }) => {
    return (
      <View>
        {labelTitle ? renderLabel({ title: labelTitle }) : null}
        <VerticalSpacing size={10} />
        <DatePickerComponent
          userDate={userDate}
          placeholder={placeholder}
          onDateChange={onDateChange}
          onFocus={() => setFocusedItem(fieldName)}
          onBlur={() => setFocusedItem(null)}
          textInputStyles={{
            borderWidth: 2,
            borderColor:
              focusedItem === fieldName ? theme.primary : theme.lightText,
            borderRadius: 6,
          }}
          minimumDate={null}
          maximumDate={maxDate}
          showTimeInput={false}
          iconSize={35}
          showYearsDropdown={true}
          defaultToToday={false}
          textInputStyle={{ minWidth: "100%", borderRadius: 2 }}
          textInputHeight={50}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        <View style={{ width: "33%", paddingRight: 20 }}>
          {renderTextInput({
            editable: false,
            labelTitle: I18n.t("screen_messages.reports.status"),
            placeholder: I18n.t("screen_messages.reports.select_status"),
            onPressIcon: (text) => {
              showOptions("statusType", (item) => {
                let label = _.get(item, "label", null);
                setStatusTypeValue(label);
              });
            },
            onChange: (text) => {},
            value: statusTypeValue,
          })}
        </View>

        <View style={{ width: "33%" }}>
          {renderTextInput({
            editable: false,
            labelTitle: I18n.t("screen_messages.reports.date_type"),
            placeholder: I18n.t("screen_messages.reports.select_date_type"),
            onPressIcon: (text) => {
              showOptions("dateType", (item) => {
                let label = _.get(item, "label", null);
                setDateTypeValue(label);
              });
            },
            onChange: (text) => {},
            value: dateTypeValue,
          })}
        </View>

        <View style={{ width: "33%" }}></View>
      </View>
      <View style={styles.inner}>
        <View style={{ width: "33%", paddingRight: 20 }}>
          {renderDatePicker({
            maxDate,
            userDate: !_.isEmpty(startDate) ? new Date(startDate) : null,
            fieldName: "startDate",
            placeholder: I18n.t("screen_messages.reports.start_date"),
            labelTitle: I18n.t("screen_messages.reports.date_range"),
            onDateChange: (value) => {
              setStartDate(value.toISOString());
            },
          })}
        </View>

        <View style={{ width: "33%", paddingRight: 20 }}>
          <View style={{ paddingTop: theme.primaryPadding }}>
            {renderDatePicker({
              maxDate,
              userDate: !_.isEmpty(endDate) ? new Date(endDate) : null,
              fieldName: "startDate",
              placeholder: I18n.t("screen_messages.reports.end_date"),
              labelTitle: null,
              onDateChange: (value) => {
                setEndDate(value.toISOString());
              },
            })}
          </View>
        </View>
        <View style={{ width: "33%" }}>
          {renderTextInput({
            editable: true,
            placeholder: I18n.t("screen_messages.reports.enter_email"),
            labelTitle: I18n.t("screen_messages.reports.notify_email"),
            onPressIcon: (text) => {
              onSubmit();
            },
            onChange: setEmail,
            value: email,
            hideArrow: false,
          })}
        </View>
      </View>
    </View>
  );
}

function getStyles(theme) {
  return StyleSheet.create({
    container: {
      backgroundColor: "#3D4152",
      borderRadius: 12,
    },
    inner: {
      paddingVertical: theme.primaryPadding,
      paddingHorizontal: 10,
      flexDirection: "row",
      justifyContent: "space-between",
      alignContent: "center",
    },
  });
}
