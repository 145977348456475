import React, { useContext, useEffect } from "react";
import { Text, TextInput, Pressable } from "react-native";
import _ from "lodash";
import * as SplashScreen from "expo-splash-screen";
import { SafeAreaProvider } from "react-native-safe-area-context";
import NavigationManager from "./common/NavigationManager";
import PrestoSdk from "./services/global/PrestoSdk";
import LoadingScreen from "@presto-screens/Loading/Loading";
import { useFonts } from "@use-expo/font";
import ThemeContext, {
  ThemeContextProvider,
} from "@presto-contexts/ThemeContext";
import { ModalContextProvider } from "@presto-contexts/ModalContext";
import { UserContextProvider } from "@presto-contexts/UserContext";
import { MessagesContextProvider } from "@presto-contexts/MessagesContext";
import { StylesContextProvider } from "@presto-contexts/StylesContext";
import { CartContextProvider } from "@presto-contexts/CartContext";
import { ServiceCartContextProvider } from "@presto-contexts/ServiceCartContext";
import { NavigatorContextProvider } from "@presto-contexts/NavigatorContext";
import { WebNavigatorContextProvider } from "@presto-contexts/WebNavigatorContext";
import { RightPaneContextProvider } from "@presto-contexts/RightPaneContext";
import { CatalogContextProvider } from "@presto-contexts/CatalogContext";
import AppDataLoader from "./loaders/AppDataLoader";
import UserDataLoader from "./loaders/UserDataLoader";
import { Provider as PrestoProvider } from "presto-react-components";
import fonts from "@presto-assets/fonts";
import { NativeBaseProvider } from "native-base";
import WebNavigationManager from "./common/WebNavigationManager";
import { Provider } from "react-native-paper";
import Messages from "@presto-app/messages";
import AppImages from "@presto-assets/images";
import AppSvgs from "@presto-assets/svgs";
import config from "@presto-common/config";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";

import { PosCartContextProvider } from "@presto-contexts/PosCartContext";

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.allowFontScaling = false;

TextInput.defaultProps = Text.defaultProps || {};
TextInput.defaultProps.allowFontScaling = false;

Pressable.defaultProps = Pressable.defaultProps || {};
Pressable.defaultProps.style = { cursor: "pointer" };

PrestoSdk.initialize();
NavigationManager.initialize();
WebNavigationManager.initialize();

function ProviderWrapper({ children }) {
  const { theme } = useContext(ThemeContext);
  return (
    <PrestoProvider
      theme={theme}
      svgs={AppSvgs}
      images={AppImages}
      messages={Messages}
    >
      {children}
    </PrestoProvider>
  );
}

function addFbPixel() {
  if (!_.isEmpty(_.get(config, "analytics.facebook.pixel"))) {
    window.fbq("init", _.get(config, "analytics.facebook.pixel"));
    window.fbq("trackCustom", "APP_OPENED_WEB");
  }
}
function addGoogleMaps() {
  //<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCi6C91dILQ7JSlGoqle9_3FQVPpjk89uE&libraries=places&callback=initMap"></script>
  var googleScript = document.createElement("script");
  const mapsApiKey = config.web?.mapsApiKey;
  if (!mapsApiKey) {
    return;
  }
  const src = `https://maps.googleapis.com/maps/api/js?key=${mapsApiKey}&libraries=places&callback=initMap`;
  googleScript.setAttribute("src", src);
  document.head.appendChild(googleScript);
}

function addScripts() {
  addFbPixel();
  addGoogleMaps();
}

SplashScreen.preventAutoHideAsync().catch((a) => {
  console.log("Warning in app", a);
});

function App() {
  console.log("NativeBaseProvider : NativeBaseProvider", NativeBaseProvider);

  useEffect(() => {
    addScripts();
  }, []);

  let [fontsLoaded] = useFonts(fonts);
  if (!fontsLoaded) {
    return null;
  }

  return (
    <NativeBaseProvider>
      <SafeAreaProvider>
        <WebNavigatorContextProvider>
          <NavigatorContextProvider>
            <ThemeContextProvider>
              <ProviderWrapper>
                <MessagesContextProvider>
                  <StylesContextProvider>
                    <AppDataLoader>
                      <UserContextProvider>
                        <UserDataLoader>
                          <CatalogContextProvider>
                            <CartContextProvider>
                              <PosCartContextProvider>
                                <ServiceCartContextProvider>
                                  <RightPaneContextProvider>
                                    <ModalContextProvider>
                                      <ActionSheetProvider>
                                        <Provider>
                                          <LoadingScreen />
                                        </Provider>
                                      </ActionSheetProvider>
                                    </ModalContextProvider>
                                  </RightPaneContextProvider>
                                </ServiceCartContextProvider>
                              </PosCartContextProvider>
                            </CartContextProvider>
                          </CatalogContextProvider>
                        </UserDataLoader>
                      </UserContextProvider>
                    </AppDataLoader>
                  </StylesContextProvider>
                </MessagesContextProvider>
              </ProviderWrapper>
            </ThemeContextProvider>
          </NavigatorContextProvider>
        </WebNavigatorContextProvider>
      </SafeAreaProvider>
    </NativeBaseProvider>
  );
}

export default App;
