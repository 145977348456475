import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import ClientResource from "./ClientResource";
import PrestoSdk from "../../global/PrestoSdk";
import AppointmentObject from "../../data_models/AppointmentObject";
import ClientObject from "../../data_models/ClientObject";
import AddressObject from "../../data_models/AddressObject";
import $ from "../../global/util";

const ClientManager = function (
  Utils,
  ErrorConstants,
  ClientResource,
  PrestoSdk,
  ClientObject
) {
  function getAllClients(params, successCallback, errorCallback) {
    ClientResource.getAllClients(params).then(
      function (response) {
        let list = [];
        if (response.data.length) {
          for (let index in response.data) {
            list.push(response.data[index]);
          }
          response.data = list;
        }
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createClient(params, successCallback, errorCallback) {
    ClientResource.createClient(params).then(
      function (response) {
        response.message = Utils.getSuccessMessage("Client_created");
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getClient(params, successCallback, errorCallback) {
    ClientResource.getClient(params).then(
      function (response) {
        response.message = Utils.getSuccessMessage("Client_Fetched");
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateClient(params, successCallback, errorCallback) {
    ClientResource.updateClient(params).then(
      function (response) {
        response.message = Utils.getSuccessMessage("Client_updated");
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    getAllClients: getAllClients,
    updateClient: updateClient,
    createClient: createClient,
    getClient: getClient,
  };
};

export default ClientManager(
  Utils,
  ErrorConstants,
  ClientResource,
  PrestoSdk,
  ClientObject
);
