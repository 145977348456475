import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import config from "@presto-common/config";

const ServerCartResource = function ($http, PrestoSdk) {
  function createCart(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart.json";
    return $http.post(url, { cart: params });
  }

  function employeeCart(params) {
    let url = PrestoSdk.getHostName() + "/employee/v1/carts.json";
    return $http.post(url, { cart: params })
  }

  function addMultiItem(params) {
    let url = PrestoSdk.getHostName() + `/employee/v1/carts/${params?.id}/add_item.json`;
    console.log("url ", url);
    return $http.put(url, { cart: params });
  }

  function fetchMultiItem(params) {
    let url = PrestoSdk.getHostName() + `/employee/v1/carts/${params?.id}.json`;
    console.log("url ", url);
    return $http.get(url);
  }

  function multiCheckout(params) {
    let url = PrestoSdk.getHostName() + `/employee/v1/carts/${params?.id}/checkout.json`;
    return $http.put(url, { cart: params });
  }

  function employeeClearcart(params) {
    let url = PrestoSdk.getHostName() + `/employee/v1/carts/${params?.cart_id}/clear_cart.json`;
    return $http.put(url, { cart: params });
  }

  function employeeUpdateItem(params) {
    let url = PrestoSdk.getHostName() + `/employee/v1/carts/${params?.cart_id}/update_item.json`;
    return $http.put(url, { cart: params });
  }

  function employeeRemoveItem(params) {
    let url = PrestoSdk.getHostName() + `/employee/v1/carts/${params?.cart_id}/remove_item.json`;
    return $http.put(url, { cart: params });
  }

  function employeeApplyCoupon(params) {
    let url = PrestoSdk.getHostName() + "/employee/v1/carts/apply_coupon.json";
    return $http.put(url, { cart: params });
  }

  function fetchCart() {
    let url = PrestoSdk.getHostName() + "/user/v0/cart.json";
    return $http.get(url);
  }

  function addItem(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/add_item.json";
    console.log("url ", url);
    return $http.put(url, { cart: params });
  }

  function removeItem(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/remove_item.json";
    return $http.put(url, { cart: params });
  }

  function updateItem(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/update_item.json";
    return $http.put(url, { cart: params });
  }

  function applyCoupon(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/apply_coupon.json";
    return $http.put(url, { cart: params });
  }

  function removeCoupon(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/remove_coupon.json";
    return $http.put(url, { cart: params });
  }

  function clearCart(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/clear_cart.json";
    return $http.put(url, { cart: params });
  }

  function checkout(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/checkout.json";
    return $http.put(url, { cart: params });
  }

  function placeOrder(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/place_order.json";
    return $http.post(url, { cart: params });
  }

  function validateCart(params) {
    let url = PrestoSdk.getHostName() + "/user/v0/cart/validate.json";
    return $http.post(url, { cart: params });
  }

  function fetchBooks(params) {
    let url = config.constants.booksmap_json;
    return $http.get(url);
  }

  function getProbableItemPromotions(params) {
    let url =
      PrestoSdk.getHostName() + "/user/v0/cart/probable_item_promotions.json";
    return $http.get(url);
  }

  return {
    createCart: createCart,
    fetchCart: fetchCart,
    addItem: addItem,
    removeItem: removeItem,
    updateItem: updateItem,
    applyCoupon: applyCoupon,
    removeCoupon: removeCoupon,
    clearCart: clearCart,
    checkout: checkout,
    placeOrder: placeOrder,
    validateCart: validateCart,
    fetchBooks: fetchBooks,
    getProbableItemPromotions: getProbableItemPromotions,
    employeeCart: employeeCart,
    addMultiItem: addMultiItem,
    fetchMultiItem: fetchMultiItem,
    multiCheckout: multiCheckout,
    employeeClearcart: employeeClearcart,
    employeeApplyCoupon: employeeApplyCoupon,
    employeeUpdateItem: employeeUpdateItem,
    employeeRemoveItem: employeeRemoveItem
  };
};

export default ServerCartResource($http, PrestoSdk);
