import PrestoSdk from "../../global/PrestoSdk";
import $http from "../../global/http";
import $ from "../../global/util";

const PosResource = function (PrestoSdk, $http) {
  function searchUser(params) {
    if (params.parameter === "name") {
      let names = params.value.split("");
      for (var index in names) {
        names[index] = `[${names[index]}${names[index].toUpperCase()}]`;
      }
      let newNames = names.join("");
      params.value = newNames;
    }
    var url =
      PrestoSdk.getHostName() +
      "/cc/users/search.json?parameter=" +
      params.parameter +
      "&value=.*" +
      params.value;
    if (params.sortField && params.sortDirection) {
      url = url + `&sort_field=${params.sortField.toLowerCase()}&sort_order=${params.sortDirection.toLowerCase()}`
    }
    return $http.get(url, params);
  }
  function getUser(params) {
    var url =
      PrestoSdk.getHostName() +
      "/cc/" +
      params.merchant_id +
      "/users/" +
      params.user_id +
      ".json";
    return $http.get(url);
  }

  function bookAppointment(params) {
    var url = PrestoSdk.getHostName() + "/employee/v1/appointments.json";
    return $http.post(url, params);
  }

  function getAppointment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment_id +
      ".json";
    return $http.get(url, params);
  }

  function rescheduleAppointment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment_id +
      "/reschedule.json";
    return $http.put(url, params);
  }

  function updateAppointment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment_id +
      ".json";
    return $http.put(url, params);
  }

  function getBooking(params) {
    var url =
      PrestoSdk.getHostName() + "/employee/v1/bookings/for_service_items.json";
    return $http.get(url, params);
  }

  function addService(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment_id +
      "/add_service.json";
    return $http.put(url, params);
  }

  function updateService(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment_id +
      "/update_service.json";
    return $http.put(url, params);
  }

  function removeService(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment_id +
      "/remove_service.json";
    return $http.put(url, params);
  }

  function applyCoupon(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment_id +
      "/apply_coupon.json";
    return $http.put(url, params);
  }

  function removeCoupon(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment_id +
      "/remove_coupon.json";
    return $http.put(url, params);
  }

  function confirmAppointment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment_id +
      "/confirm.json";
    return $http.put(url, params);
  }

  function startAppointment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment_id +
      "/start.json";
    return $http.put(url, params);
  }

  function completeAppointment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment_id +
      "/complete.json";
    return $http.put(url, params);
  }

  function cancelAppointment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment_id +
      "/cancel.json";
    return $http.put(url, params);
  }

  function rejectAppointment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment_id +
      "/reject.json";
    return $http.put(url, params);
  }

  function downloadInvoice(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment_id +
      "/download_invoice.json";
    return $http.get(url, params);
  }

  function sendInvoice(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment_id +
      "/send_invoice_to_user.json";
    return $http.put(url, params);
  }

  function getAllAppointments(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments.json?start_time=" +
      params.start_time +
      "&end_time=" +
      params.end_time +
      "&time_type=" +
      params.time_type;
    return $http.get(url, params);
  }

  function startBookingService(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/bookings/" +
      params.booking_id +
      "/start.json";
    return $http.put(url, params);
  }

  function completeBookingService(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/bookings/" +
      params.booking_id +
      "/complete.json";
    return $http.put(url, params);
  }
  function searchAppointment(params) {
    let str = Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join("&");
    var url = PrestoSdk.getHostName() + "/employee/v1/appointments.json?" + str;
    return $http.get(url);
  }

  function createPayment(params) {
    var url = PrestoSdk.getHostName() + "/employee/v1/payments.json";
    return $http.post(url, params);
  }

  function cancelPayment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/payments/" +
      params.payment_id +
      "/cancel.json";
    return $http.put(url, params);
  }

  function successPayment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/payments/" +
      params.payment_id +
      "/succeed.json";
    return $http.put(url, params);
  }

  function getUserAppointments(params) {
    var url = PrestoSdk.getHostName() + "/employee/v1/appointments.json";
    if (params.user_id) {
      url += "?user_id=" + params.user_id;
    }
    if (params.status) {
      url += "&status=" + params.status;
    }
    return $http.get(url, params);
  }
  function createService(params) {
    var url = PrestoSdk.getHostName() + "/admin/v1/services.json";
    return $http.post(url, params);
  }

  function updateServiceByAdmin(params) {
    var url =
      PrestoSdk.getHostName() + "/admin/v1/services/" + params.id + ".json";
    return $http.put(url, params);
  }
  function deleteServiceByAdmin(params) {
    var url =
      PrestoSdk.getHostName() + "/admin/v1/services/" + params.id + ".json";
    return $http.delete(url);
  }

  return {
    searchUser: searchUser,
    bookAppointment: bookAppointment,
    addService: addService,
    updateService: updateService,
    removeService: removeService,
    applyCoupon: applyCoupon,
    removeCoupon: removeCoupon,
    confirmAppointment: confirmAppointment,
    startAppointment: startAppointment,
    completeAppointment: completeAppointment,
    cancelAppointment: cancelAppointment,
    rejectAppointment: rejectAppointment,
    downloadInvoice: downloadInvoice,
    sendInvoice: sendInvoice,
    getAppointment: getAppointment,
    getBooking: getBooking,
    getAllAppointments: getAllAppointments,
    getUser: getUser,
    startBookingService: startBookingService,
    completeBookingService: completeBookingService,
    searchAppointment: searchAppointment,
    createPayment: createPayment,
    successPayment: successPayment,
    cancelPayment: cancelPayment,
    getUserAppointments: getUserAppointments,
    rescheduleAppointment: rescheduleAppointment,
    updateAppointment: updateAppointment,
    createService: createService,
    updateServiceByAdmin: updateServiceByAdmin,
    deleteServiceByAdmin: deleteServiceByAdmin,
  };
};

export default PosResource(PrestoSdk, $http);
