import _ from "lodash";
import SessionManager from "@presto-services/features/session/SessionManager";
import PosManager from "@presto-services/features/pos/PosManager";
import Utils from "@presto-services/common/Utils";
import RNFetchBlob from "@presto-common/RNFetchBlob";
import ClientDataSource from "@presto-datasources/ClientDataSource";
import moment from "moment";
import DBUserManager from "@presto-db-managers/DBUserManager";
import DBMetaDataManager from "@presto-db-managers/DBMetaDataManager";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import asyncify from "@presto-common/Asyncify";
import PrestoSdk from "@presto-services/global/PrestoSdk";

const AsyncEmployeeManager = asyncify(EmployeeManager);
const AsyncClientDataSource = asyncify(ClientDataSource);

function getUsersByUserIds(userIds) {
  return new Promise((resolve) => {
    let params = {
      user_ids: userIds,
    };
    SessionManager.getUsersByIds(
      params,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        console.log(" error : ", error);
        resolve(null);
      }
    );
  });
}

function getUser(user_id, merchant_id) {
  return new Promise((resolve) => {
    let params = {
      user_id: user_id,
      merchant_id: merchant_id,
    };
    PosManager.getUser(
      params,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        console.log(" error : ", error);
        resolve(null);
      }
    );
  });
}

function getAppointmentUserIds(appointments) {
  let userIds = [];
  _.forEach(appointments, (appointment) => {
    let index = _.findIndex(userIds, (i) => i === appointment.user_id);
    if (index < 0) {
      userIds.push(appointment.user_id);
    }
  });
  return userIds;
}

function UserDumpObject() {
  this.listDumps = () => {
    const params = {
      report_type: "User",
      state: "completed",
    };
    return AsyncEmployeeManager.getReportV1(params);
  };

  this.createNewDump = () => {
    const now = moment(),
      oldDate = moment();
    now.add(2, "days").endOf("day");
    oldDate.startOf("day");
    oldDate.subtract(2, "years");
    let query = {
      reports_task: {
        report_type: "User",
        notification_email_id: "pavan.k@presto-apps.com",
        end_date: now.format("YYYY-MM-DD"),
        start_date: oldDate.format("YYYY-MM-DD"),
        date_type: "created_at",
      },
    };

    return AsyncEmployeeManager.createReport(query)
      .then(([error, response]) => {
        if (error) {
          throw error;
        }
        const obj = response.data;
        let dBMetaDataManager = new DBMetaDataManager();
        dBMetaDataManager.setEmployeeDumpId(obj.id);
        return obj;
      })
      .catch((e) => {
        console.log("this.createNewDump error", error);
        return null;
      });
  };

  this.load = async function (reportId) {
    let users = [];
    try {
      const sessionUrl = PrestoSdk.getHostName() + "/employee.json";
      const sessionResponse = await fetch(sessionUrl);
      const prestoToken = sessionResponse.headers.map["x-presto-st"];
      const reportUrl =
        PrestoSdk.getHostName() +
        `/employee/v0/reports_tasks/${reportId}/download_report`;
      const response = await RNFetchBlob.fetch("GET", reportUrl, {
        "x-presto-st": prestoToken,
      });
      const data = await response.blob();
      const csv = await RNFetchBlob.fs.readFile(data._ref, "utf8");
      users = Utils.csvToJSON(csv);
    } catch (error) {
      console.log("UserDumpObject.load error", error);
    }
    return users;
  };

  this.sanitizedUsers = function (reportId) {
    return this.load(reportId).then((rows) => {
      let users = _.map(rows, (e) => {
        let query = {
          birthday: e["Birthday"],
          address: e["Address"],
          created_at: e["Created At"],
          email: e["Email"],
          id: e["ID"],
          name: e["Name"],
          phone_number: e["Phone Number"],
        };

        const isValid = _.some(
          [query.email, query.phone_number, query.name],
          (e) => !_.isEmpty(e)
        );
        if (!isValid) {
          return null;
        }

        return query;
      });
      users = _.compact(users);

      return users;
    });
  };

  this.sync = function (reportId) {
    return this.sanitizedUsers(reportId).then(async (users) => {
      const bBUserManager = new DBUserManager();
      await bBUserManager.truncateAll();

      if (users) {
        for (let index in users) {
          const user = users[index];
          const query = {
            user: { ...user, _sync_status: "synced" },
          };
          await AsyncClientDataSource.createClient(query);
        }
      }
    });
  };
}

export default {
  getUsersByUserIds: getUsersByUserIds,
  getAppointmentUserIds: getAppointmentUserIds,
  getUser: getUser,
  UserDumpObject,
};
