import DatabaseHelper from "@presto-helpers/DatabaseHelper";
// import { Platform } from 'react-native';

// const isWeb = Platform.OS == 'web'
const databaseHelper = new DatabaseHelper();
const databaseConnection = databaseHelper.connect();
// if (Platform.OS === 'web') {
//   databaseConnection = { exec: databaseHelper.connect }
// }
console.log(databaseConnection, "ljdsafjiosdfjiojsld")
export default databaseConnection;
