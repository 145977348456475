import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={44}
      height={44}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.85 12.53c0-4.212 3.454-7.642 7.7-7.642 4.243 0 7.696 3.429 7.696 7.642v8.437c0 4.215-3.451 7.646-7.695 7.646-4.246 0-7.701-3.43-7.701-7.646V12.53zm7.7 17.668c5.124 0 9.292-4.142 9.292-9.232v-8.437c0-5.09-4.168-9.229-9.293-9.229-5.127 0-9.299 4.138-9.299 9.229v8.437c0 5.092 4.172 9.232 9.3 9.232z"
        fill={props.color || "#000"}
      />
      <Path
        d="M10.841 29.31l-2.675 2.252c-.767.646-.97 1.803-.293 2.644 1.735 2.154 6.634 7.169 14.676 7.169s12.942-5.016 14.678-7.17c.678-.842.473-2-.295-2.646l-2.679-2.25c-.895-.753-2.2-.583-2.952.215-1.55 1.645-4.803 4.463-8.752 4.463-3.948 0-7.203-2.818-8.755-4.463-.753-.798-2.058-.967-2.953-.213z"
        stroke={props.color || "#000"}
        strokeWidth={1.35}
      />
      <Path
        d="M20.17 16.186c0 .365-.261.668-.605.73l-.133.013h-1.18a.74.74 0 01-.736-.743c0-.364.26-.667.604-.73l.133-.012h1.18a.74.74 0 01.736.742zM27.595 16.186c0 .365-.261.668-.605.73l-.133.013h-1.179a.74.74 0 01-.737-.743c0-.364.26-.667.605-.73l.132-.012h1.18a.74.74 0 01.736.742zM26.424 21.494c.41 0 .743.33.743.737 0 4.023-5.07 5.723-8.12 2.546a.733.733 0 01.026-1.043.746.746 0 011.05.026c2.16 2.25 5.559 1.11 5.559-1.53a.74.74 0 01.742-.736z"
        fill={props.color || "#000"}
      />
    </Svg>
  );
}

export default SvgComponent;
