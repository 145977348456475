import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";

import PrestoSdk from "../../global/PrestoSdk";

import ServerCartObject from "../../data_models/ServerCartObject";
import ServerCartResource from "./ServerCartResource";

const ServerCartManager = function (
  Utils,
  ErrorConstants,
  ServerCartResource,
  ServerCartObject,
  PrestoSdk
) {
  function createCart(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.createCart(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function employeeCart(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.employeeCart(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function fetchCart(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.fetchCart(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function addItem(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    console.log("params -->", params);
    ServerCartResource.addItem(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function addMultiItem(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    console.log("params -->", params);
    ServerCartResource.addMultiItem(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function fetchMultiItem(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    console.log("params -->", params);
    ServerCartResource.fetchMultiItem(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function employeeClearcart(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.employeeClearcart(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function employeeUpdateItem(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.employeeUpdateItem(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function employeeRemoveItem(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.employeeRemoveItem(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function employeeApplyCoupon(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.employeeApplyCoupon(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeItem(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.removeItem(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateItem(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.updateItem(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function applyCoupon(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.applyCoupon(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeCoupon(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.removeCoupon(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function clearCart(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.clearCart(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function checkout(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.checkout(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function multiCheckout(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.multiCheckout(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function placeOrder(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.placeOrder(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function validateCart(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    ServerCartResource.validateCart(params).then(
      function (response) {
        var cartObject = new ServerCartObject();
        cartObject.buildObject(response.data);
        response.data = cartObject;
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function fetchBooks(params, successCallback, errorCallback) {
    ServerCartResource.fetchBooks(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getProbableItemPromotions(params, successCallback, errorCallback) {
    ServerCartResource.getProbableItemPromotions(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    createCart: createCart,
    fetchCart: fetchCart,
    addItem: addItem,
    removeItem: removeItem,
    updateItem: updateItem,
    applyCoupon: applyCoupon,
    removeCoupon: removeCoupon,
    clearCart: clearCart,
    checkout: checkout,
    placeOrder: placeOrder,
    validateCart: validateCart,
    fetchBooks: fetchBooks,
    getProbableItemPromotions: getProbableItemPromotions,
    employeeCart,
    addMultiItem,
    fetchMultiItem,
    multiCheckout,
    employeeClearcart,
    employeeApplyCoupon,
    employeeUpdateItem,
    employeeRemoveItem
  };
};

export default ServerCartManager(
  Utils,
  ErrorConstants,
  ServerCartResource,
  ServerCartObject,
  PrestoSdk
);
