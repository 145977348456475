import _ from "lodash";
import config from "@presto-common/config";
import { Platform } from "react-native";
import {
  NetworkState,
  UserNetworkState,
} from "@presto-contexts/NetworkContext";

let offlineDefaultAPIS = _.cloneDeep(_.get(config, "offlineDefaultAPIs", {}));

export function toggleOfflineAPIs(status) {
  _.forEach(offlineDefaultAPIS, (value, key) => {
    offlineDefaultAPIS = {
      ...offlineDefaultAPIS,
      [key]: status,
    };
  });
}

export function isOfflineEnabledAPI(key) {
  if (Platform.OS == "web" || (NetworkState.connected && UserNetworkState.online)) {
    return false;
  } else {
    return offlineDefaultAPIS[key];
  }
}

export default offlineDefaultAPIS;
