import React, { useContext, useEffect, useRef } from "react";
import { Platform } from "react-native";
import * as SplashScreen from "expo-splash-screen";
import MainScreens from "@presto-screens/Main/Main";
import asyncify from "../../common/Asyncify";
import UserContext, { AuthState } from "@presto-contexts/UserContext";
import SessionHelper from "@presto-helpers/SessionHelper";
import config from "@presto-common/config";
import { useMerchantStore } from "@presto-stores/MerchantStore";
const AsyncSessionHelper = asyncify(SessionHelper);
import AsyncStorage from "@react-native-community/async-storage";
import CatalogManager from "@presto-services/features/catalog/CatalogManager";
import SearchManager from "@presto-services/features/search/SearchManager";
import _ from "lodash";
import DBCategoryManager from "@presto-db-managers/DBCategoryManager";
import DBCategoryItemManager from "@presto-db-managers/DBCategoryItemManager";
import DBMetaDataManager from "@presto-db-managers/DBMetaDataManager";
import DBCouponManager from "@presto-db-managers/DBCouponManager";
import UserHelper from "@presto-helpers/UserHelper";
import DBEmployeeManager from "@presto-db-managers/DBEmployeeManager";
import DBUserManager from "@presto-db-managers/DBUserManager";
import NetworkContext from "@presto-contexts/NetworkContext";
import { OfflineSync } from "@presto-helpers/OfflineSync";

export default function LoadingScreen() {
  const isWeb = Platform.OS == 'web'
  const { authState, setUser, user } = useContext(UserContext);
  const { networkInfo } = isWeb ? { networkInfo: undefined } : useContext(NetworkContext);
  const merchantStore = useMerchantStore();
  const extraOptions = useRef({
    syncing: false,
  });

  const canSyncData = async () => {
    const metaData = new DBMetaDataManager();
    const can = await metaData.canResyncCategoriesAndCategoryItems();
    return can;
  };

  const fetchCatalog = async () => {
    const canSync = await canSyncData();
    if (!canSync) {
      return;
    }

    const onSuccess = async (response) => {
      const categories = _.map(_.get(response, "hits.hits"), (e) => e._source);
      const obj = new DBCategoryManager();
      await obj.truncateAll();
      await obj.createOrUpdateMany(categories);
      fetchCategoryItems(categories);
    };
    const onError = (error) => {
      console.log("LoadingScreen->fetchCatalog error", error);
    };
    const merchantId = config.server_config.merchant_id;
    SearchManager.searchCategory(
      {
        merchant_id: merchantId,
        excludeOfflineEnabledAPI: true,
      },
      onSuccess,
      onError
    );
  };

  const fetchCategoryItems = (categories) => {
    const onSuccess = async (response) => {
      const data = _.map(_.get(response, "hits.hits"), (e) => e._source);
      const obj = new DBCategoryItemManager();
      await obj.truncateAll();
      await obj.createOrUpdateMany(data).then(() => {
        const now = new Date();
        const nowISO = now.toISOString();
        const metaData = new DBMetaDataManager();
        metaData.setLastCategoriesAndCategoryItemsFetchDate(nowISO);
      });
    };
    const onError = (error) => {
      console.log("LoadingScreen->fetchCategoryItems error", error);
    };
    const merchantId = config.server_config.merchant_id;

    // TODO: Recusively fetch all the records in chunks for 5000
    const size = 5000;
    const categoryIds = _.map(categories, "id");
    SearchManager.fuzzySearchCategoryItem(
      {
        category_ids: categoryIds,
        merchant_id: merchantId,
        excludeOfflineEnabledAPI: true,
        size,
      },
      onSuccess,
      onError
    );
  };

  const syncUserDumpReportId = async (reportId) => {
    const metaData = new DBMetaDataManager();
    const userDumpObject = new UserHelper.UserDumpObject();
    userDumpObject.sync(reportId).then(async () => {
      const now = new Date();
      const nowISO = now.toISOString();
      await metaData.setLastUserFetchDate(nowISO);
      await metaData.setEmployeeDumpId(null);
    });
  };

  const syncUserDump = async () => {
    const metaData = new DBMetaDataManager();
    const can = await metaData.canResyncUsers();
    if (!can) {
      return false;
    }
    await DBUserManager.syncToRemote();
    const userDumpObject = new UserHelper.UserDumpObject();
    const dumpData = await metaData.getUserDumpId();
    if (dumpData?.value) {
      const reportId = dumpData?.value;
      const [error1, response1] = await userDumpObject.listDumps();
      if (response1) {
        const userReport = _.find(response1.data, ["id", reportId]);
        const isCompleted = _.get(userReport, "state") === "completed";
        if (isCompleted) {
          await syncUserDumpReportId(reportId);
        } else {
          await userDumpObject.createNewDump();
        }
      } else {
        console.log("LoadingScreen->syncUserDump->error1", error1);
      }
    } else {
      const bBUserManager = new DBUserManager();
      const all = await bBUserManager.all();
      if (_.isEmpty(all)) {
        const [error1, response1] = await userDumpObject.listDumps();
        if (response1) {
          const userReport = _.first(response1.data);
          const isCompleted = _.get(userReport, "state") === "completed";
          if (isCompleted) {
            const reportId = userReport.id;
            await syncUserDumpReportId(reportId);
          }
        }
      }
    }
  };

  const syncCoupons = async () => {
    const metaData = new DBMetaDataManager();
    const can = await metaData.canResyncCoupons();
    if (!can) {
      return false;
    }
    const bBCouponManager = new DBCouponManager();
    await bBCouponManager.truncateAll();
    await bBCouponManager.syncLocally();
    const now = new Date();
    const nowISO = now.toISOString();
    await metaData.setLastCouponFetchDate(nowISO);
  };

  const syncEmployees = async () => {
    const metaData = new DBMetaDataManager();
    const can = await metaData.canResyncEmployees();
    if (!can) {
      return false;
    }
    const bBEmployeeManager = new DBEmployeeManager();
    await bBEmployeeManager.truncateAll();
    bBEmployeeManager.syncLocally().then(async (...args) => {
      const now = new Date();
      const nowISO = now.toISOString();
      await metaData.setlastEmployeesFetchDate(nowISO);
    });
  };

  const hideSplashScreen = async () => {
    const metaData = new DBMetaDataManager();
    const [error, response] = await AsyncSessionHelper.isLoggedInUser();
    await SplashScreen.hideAsync().catch(console.warn);
    if (error) {
      let user = await metaData.getCurrentLoggedUserInfo();
      if (error.message === "Network Error" && !_.isEmpty(user)) {
        setUser(user);
      } else {
        setUser(undefined);
      }
    } else {
      await metaData.setCurrentLoggedUserInfo(response.data);
      setUser(response.data);
      merchantStore.setMerchantId(response.data.merchant_id);
      merchantStore.refreshMerchant();
    }
  };

  const checkAndHideSplashScreen = async () => {
    const previouslyOpened = await AsyncStorage.getItem("PREVIOUSLY_OPENED");
    if (!config.splash_screen_delay || previouslyOpened === "true") {
      hideSplashScreen();
    } else {
      AsyncStorage.setItem("PREVIOUSLY_OPENED", "true");
      setTimeout(() => {
        hideSplashScreen();
      }, config.splash_screen_delay);
    }
  };

  useEffect(() => {
    checkAndHideSplashScreen();
  }, [authState]);

  useEffect(() => {
    if (
      !_.isEmpty(user) &&
      (networkInfo && networkInfo.isConnected) &&
      !extraOptions.current.syncing
    ) {
      extraOptions.current.syncing = true;
      const offlineSync = new OfflineSync(user);
      // offlineSync.syncAll();
    }
  }, [networkInfo, user]);

  if (authState === AuthState.unknown) {
    return null;
  } else {
    return <MainScreens />;
  }
}
