import React, { useContext } from "react";
import { View, Image } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoIcon from "@presto-components/PrestoIcon";
import { useAssets } from "presto-react-components";
import PrestoText from "@presto-components/PrestoText";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";

export default function HeaderWithBackAndLogoAndRightTitle({
  rightTitle,
  navigation,
  enableBack,
  onPressBack,
  headerStyle,
  rightIcon,
  onPressRightIcon,
}) {
  const { svgs, images } = useAssets();
  const { theme } = useContext(ThemeContext);

  const onPress = () => {
    onPressBack();
  };

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: headerStyle?.backgroundColor
          ? headerStyle?.backgroundColor
          : theme.white,
        borderBottomWidth: 1,
        borderBottomColor: headerStyle?.borderBottomColor
          ? headerStyle?.borderBottomColor
          : theme.primary,
        paddingRight: theme.containerPadding,
        height: 60,
        alignItems: "center",
      }}
    >
      <View style={{ flexDirection: "row" }}>
        {enableBack ? (
          <TouchableWithoutFeedback onPress={onPress}>
            <View
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <PrestoIcon
                icon={
                  <svgs.LeftArrow
                    primaryColor={theme.title}
                    width={45}
                    height={45}
                  />
                }
              />
            </View>
          </TouchableWithoutFeedback>
        ) : null}

        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: enableBack ? 0 : 20,
          }}
        >
          <Image
            source={headerStyle ? images.Icon : images.textIcon}
            style={{ width: 50, height: 30, alignItems: "center" }}
          />
        </View>
      </View>

      <View>
        {rightTitle ? (
          <PrestoText
            size={36}
            fontStyle="600.semibold"
            color={headerStyle?.color ? headerStyle?.color : theme.primary}
          >
            {rightTitle}
          </PrestoText>
        ) : null}
        {rightIcon ? (
          <TouchableWithoutFeedback onPress={onPressRightIcon}>
            <View>
              <PrestoIcon icon={rightIcon} />
            </View>
          </TouchableWithoutFeedback>
        ) : null}
      </View>
    </View>
  );
}
