import React, { useContext, useState, useRef, useEffect } from "react";
import {
  View,
  Button,
  ScrollView,
  Platform,
  Alert,
  Text,
  TouchableOpacity,
} from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import CartContext from "@presto-contexts/CartContext";
import LoadingContainer from "@presto-components/LoadingContainer";
import SideNavigation from "@presto-screen-components/SideNavigation/SideNavigation";
import _ from "lodash";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import HeaderWithTabs from "@presto-screen-components/Headers/HeaderWithTabs";
import AllReports from "@presto-screens/Reports/AllReports";
import SalesReports from "@presto-screens/Reports/SalesReports";
import InsightsReports from "@presto-screens/Reports/InsightsReports";
import SalesDbReports from "@presto-screens/Reports/SalesDbReports";
import PosManagement from "@presto-screens/PosManagement/PosManagement";
import CustomerList from "@presto-screens/CustomerList/CustomerList";
import PurchaseRequests from "@presto-screens/PurchaseRequests/PurchaseRequests";
import PurchaseOrders from "@presto-screens/PurchaseOrders/PurchaseOrders";
import RoleManagement from "@presto-screens/RoleManagement/RoleManagement";
import { createNavigationContainerRef } from "@react-navigation/native";
import I18n from "i18n-js";
import { TabBlockItem } from "@presto-screen-components/Headers/HeaderWithTabs";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import utils from "../../utils/index";
import FloatingCartComponent from "@presto-screen-components/FloatingCartComponent/FloatingCartComponent";
import { updateConfig } from "../../common/config";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import NetInfo from "@react-native-community/netinfo";

export default function Home({ navigation, route }) {
  const isDesktop = utils.isDesktop();
  const isWeb = Platform.OS == 'web'
  const { WebNavigator } = useContext(WebNavigatorContext);
  const routeParams = route?.params || {};
  const { selectedFeature } = routeParams;
  const { theme } = useContext(ThemeContext);
  const { cart } = useContext(CartContext);
  const { Navigator } = useContext(NavigatorContext);
  const navigationRef = createNavigationContainerRef();
  const [loading, setLoading] = useState(false);
  const [floatingCartProps, setFloatingCartProps] = useState({});
  const parentRef = useRef({
    onPressTab: null,
    onChangeOfHeaderTabs: null,
    onPressNetworkToggle: null,
  });
  const [tabs, setTabs] = useState([]);
  const insets = useSafeAreaInsets();
  const isMobile = utils.isMobile();
  const isIOS = Platform.OS === "ios";
  const isReturnManagement = selectedFeature.state === "return_management";

  useEffect(() => {
    init();
  }, [selectedFeature]);

  const init = () => {
    if (isReturnManagement) {
      setTabs([
        {
          id: "start_returns",
          text: I18n.t("screen_messages.returns.start_returns"),
          isActive: true,
          defaultStyles: {
            backgroundColor: theme.primary,
          },
          tabisActiveStyles: {
            backgroundColor: theme.primary,
          },
          tabisActiveTextExtraStyles: {
            color: theme.white,
          },
        },
      ]);
      goToReturnScreens();

      parentRef.current.renderRightSideComponent = () => {
        return (
          <View>
            <TabBlockItem
              isActive={true}
              text={I18n.t("screen_messages.common.start_pos")}
              onPress={() => {
                if (isWeb) {
                  WebNavigator.emit({
                    event: "replace",
                    params: {
                      screenName: "HOME",
                      screenParams: {
                        selectedFeature: { state: "pos_management" },
                      },
                    },
                  });
                } else {
                  Navigator.emit({
                    event: "replace",
                    params: {
                      screenName: "HOME",
                      screenParams: {
                        selectedFeature: { state: "pos_management" },
                      },
                    },
                  });
                }
              }}
              onLongPress={(...args) => { }}
              tabisActiveStyles={{
                backgroundColor: theme.secondary,
              }}
              tabisActiveTextExtraStyles={{
                color: theme.white,
              }}
              defaultStyles={{
                backgroundColor: theme.primary,
              }}
            />
          </View>
        );
      };
    } else if (selectedFeature?.state == "pos_management") {
      setFloatingCartProps(null);
    }
  };

  const handleOnScreenPress = () => {
    if (parentRef.current.onScreenPressEvent) {
      parentRef.current.onScreenPressEvent();
    }
  };

  const scrollViewHeight = () => {
    let height = "100%";
    if (isReturnManagement) {
      return { height };
    } else if (selectedFeature?.state == "purchase_request_management") {
      return { height };
    } else if (selectedFeature?.state == "purchase_request_management") {
      return { height };
    } else if (selectedFeature?.state == "sales_reports_management") {
      return { height };
    } else if (selectedFeature?.state == "insights_reports_management") {
      return { height };
    } else {
      return { height };
    }
  };

  const commonProps = {
    sideNavigationRef: navigationRef,
    parentRef: parentRef,
    tabs: tabs,
    setTabs: setTabs,
    parentLoading: loading,
    setParentLoading: setLoading,
    getPropsForFloatingCart: getPropsForFloatingCart,
  };

  const goToReturnScreens = () => {
    if (!isMobile) return;
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "SIDE_NAVIGATION",
        screenParams: {
          forwardProps: componentProps,
        },
      },
    });
  };

  function getPropsForFloatingCart({ count, price, event }) {
    setFloatingCartProps({ count, price, event });
  }

  const goBack = (goHome, feature) => {
    if (goHome) {
      selectedFeature.state = feature;
      Navigator.emit({
        event: "replace",
        params: {
          screenName: "HOME",
          screenParams: {
            selectedFeature: selectedFeature,
          },
        },
      });
      return;
    }
    navigationRef.goBack();
  };

  const renderSideNavigation = (component) => {
    return isDesktop ? (
      selectedFeature?.state === "purchase_request_management" ? null : (
        <View
          style={{
            width: 400,
            height: "100%",
            // paddingBottom: 35,
            backgroundColor: theme.screenBackgroundColor,
          }}
        >
          {component}
        </View>
      )
    ) : selectedFeature?.state === "purchase_request_management" ? null : (
      component
    );
  };

  let componentProps = {
    navRef: navigationRef,
    selectedFeature: selectedFeature,
    onChangeOfHeaderTabs: (id) => {
      if (_.isFunction(parentRef.current.onChangeOfHeaderTabs)) {
        parentRef.current.onChangeOfHeaderTabs(id);
      }
    },
    parentLoading: loading,
    setParentLoading: setLoading,
    onCompleteOrder: (order) => {
      if (_.isFunction(parentRef.current.onCompleteOrder)) {
        parentRef.current.onCompleteOrder(order);
      }
    },
    goBack: goBack,
  };

  const SideNavComponent = React.memo(() => {
    return <SideNavigation {...componentProps} />;
  });

  const showFloatingComponent = () => {
    let can = false;
    // if (selectedFeature?.state === "purchase_request_management") {
    //   can = true;
    // } else
    if (
      selectedFeature?.state === "pos_management" &&
      cart != null &&
      !_.isEmpty(cart?.items)
    ) {
      can = true;
    }
    if (!isMobile) return false;
    return can;
  };

  return (
    <>
      <LoadingContainer
        loading={loading}
        bgColor={theme.screenBackgroundColor}
        style={{
          backgroundColor: theme.screenBackgroundColor,
          marginTop: insets.top,
        }}
      >
        {/* <ScrollView
        nestedScrollEnabled
        contentContainerStyle={scrollViewHeight()} */}
        <ScrollView
          nestedScrollEnabled
          contentContainerStyle={scrollViewHeight()}
          keyboardShouldPersistTaps="handled"
          style={{
            marginTop: isIOS ? 0 : insets.top,
            backgroundColor: theme.screenBackgroundColor,
          }}
        >
          {/* <LoadingContainer
        loading={loading}
        bgColor={theme.screenBackgroundColor}
        style={{
          backgroundColor: theme.screenBackgroundColor,
        }}
      > */}

          <HeaderWithTabs
            isActiveStyles={{
              backgroundColor: "#D8D8D8",
            }}
            tabs={tabs}
            onPressTab={(...args) => {
              if (_.isFunction(parentRef.current.onPressTab)) {
                parentRef.current.onPressTab(...args);
              }
            }}
            onLongPress={(...args) => {
              if (_.isFunction(parentRef.current?.onLongPressTab)) {
                parentRef.current.onLongPressTab(...args);
              }
            }}
            renderRightSideComponent={
              parentRef.current.renderRightSideComponent
            }
          />
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              height: selectedFeature?.state === "purchase_request_management" ? "70%" : "100%",
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              {selectedFeature?.state === "pos_management" ? (
                <PosManagement
                  {...commonProps}
                  componentProps={componentProps}
                />
              ) : null}

              {selectedFeature?.state === "sales_reports_management" ? (
                <SalesReports
                  {...commonProps}
                  filterOptions={routeParams.filterOptions}
                />
              ) : null}
              {selectedFeature?.state === "sales_db_reports_management" ? (
                <SalesDbReports
                  {...commonProps}
                  filterOptions={routeParams.filterOptions}
                />
              ) : null}

              {selectedFeature?.state === "insights_reports_management" ? (
                <InsightsReports
                  {...commonProps}
                  filterOptions={routeParams.filterOptions}
                />
              ) : null}

              {selectedFeature?.state === "reports_management" ? (
                <AllReports {...commonProps} />
              ) : null}

              {selectedFeature?.state === "customer_management" ? (
                <CustomerList
                  {...commonProps}
                  componentProps={componentProps}
                />
              ) : null}

              {selectedFeature?.state === "purchase_request_management" ? (
                <PurchaseRequests
                  {...commonProps}
                  componentProps={componentProps}
                />
              ) : null}

              {selectedFeature?.state === "purchase_order_management" ? (
                <PurchaseOrders {...commonProps} />
              ) : null}

              {_.includes(
                ["admin_management_user_form", "admin_management"],
                selectedFeature?.state
              ) ? (
                <RoleManagement
                  {...commonProps}
                  selectedFeature={selectedFeature}
                  initialUser={routeParams.initialUser}
                />
              ) : null}
            </View>

            {/* {isMobile ? null : renderSideNavigation(<SideNavComponent />)} */}
            {isMobile
              ? null
              : renderSideNavigation(<SideNavigation {...componentProps} />)}
          </View>

        </ScrollView>
        {showFloatingComponent() ? (
          <>
            <FloatingCartComponent
              componentProps={componentProps}
              floatingCartProps={floatingCartProps}
            />
          </>
        ) : null}
      </LoadingContainer>
    </>
  );
}
