import React, { useContext, useState, useCallback } from "react";
import { View, FlatList, Pressable, RefreshControl, Platform } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import _ from "lodash";
import moment from "moment";
import OrderModel from "@presto-component-models/OrderModel";

export default function AllReportsTable({
  items,
  onEndReached,
  activeRow,
  onPressRow,
  onDownloadReport,
  refresh,
}) {
  const [refreshing, setRefreshing] = useState(false);
  const { theme } = useContext(ThemeContext);
  const isWeb = Platform.OS == 'web'

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    refresh();
    setTimeout(() => {
      setRefreshing(false);
    }, 500);
  });

  const tableColumns = [
    {
      title: I18n.t("screen_messages.reports.report_type"),
      width: "20%",
      thType: "highlighted",
    },
    {
      title: _.capitalize(I18n.t("screen_messages.reports.status")),
      width: "25%",
      viewStyles: {
        alignItems: "center",
      },
    },
    {
      title: I18n.t("screen_messages.reports.date_range"),
      width: "25%",
      viewStyles: {
        alignItems: "center",
      },
    },
    {
      title: I18n.t("screen_messages.reports.requester"),
      width: "30%",
      viewStyles: {
        alignItems: "center",
        paddingRight: 10,
      },
    },
  ];

  const RenderItemHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          paddingVertical: 5,
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: theme.screenBackgroundColor,
        }}
      >
        {_.map(tableColumns, (column, index) => {
          let viewStyles = {
            height: 50,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            ...column.viewStyles,
          };

          let textProps = {
            extraStyle: { paddingLeft: 20 },
            color: theme.paragraph,
            fontStyle: "400.bold",
            size: 13,
          };

          if (column.thType === "highlighted") {
            viewStyles = {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              ...column.viewStyles,
            };
            textProps = {
              extraStyle: { paddingLeft: 10 },
              color: theme.black,
              fontStyle: "400.bold",
              size: 16,
            };
          }

          if (column.title == "Amount") {
            viewStyles = {
              ...viewStyles,
              alignItems: "center",
            };
          }

          return (
            <View
              key={index}
              style={{
                ...viewStyles,
                width: column.width,
              }}
            >
              <PrestoText {...textProps}>{column.title}</PrestoText>
            </View>
          );
        })}
      </View>
    );
  };

  const renderItem = ({ item, index }) => {
    let reportType = item.report_type;
    let state = item.state;
    let status = item.status;
    let dateRange = `${moment(`${item.start_date}`).format(
      "DD/MM/YYYY"
    )} - ${moment(`${item.end_date}`).format("DD/MM/YYYY")}`;
    let requester = item.requester_hash?.name
      ? `${item.requester_hash.name} on
    ${moment(item.created_at).format("DD/MM/YYYY")}`
      : null;

    const isStateCompleted = item.state == "completed";

    const isActive = _.get(activeRow, "id") === item.id;
    const bgColor = isActive ? theme.primary : theme.white;
    const textColor1 = isActive ? theme.white : theme.primary;
    const textColor2 = isActive ? theme.white : theme.darkText;

    return (
      <Pressable
        key={`${item.id}-${index}`}
        onPress={() => onPressRow(item)}
        style={{
          width: "100%",
          height: 60,
          backgroundColor: bgColor,
          borderRadius: 15,
          paddingHorizontal: theme.primaryPadding,
          paddingVertical: 10,
          flexDirection: "row",
          marginBottom: 5,
        }}
      >
        <View
          style={{
            width: "20%",
            flexDirection: "column",
            borderRightWidth: 1,
            borderRightColor: "#DBDBDB",
            marginRight: 10,
            justifyContent: "center",
          }}
        >
          <PrestoText
            extraStyle={{}}
            color={textColor1}
            fontStyle={"600.semibold"}
          >
            {reportType}
          </PrestoText>

          {status ? (
            <PrestoText
              extraStyle={{}}
              color={textColor2}
              fontStyle={"400.normal"}
            >
              ({status})
            </PrestoText>
          ) : null}
        </View>

        <View
          style={{
            width: `25%`,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText
            extraStyle={{}}
            color={textColor2}
            fontStyle={"400.normal"}
          >
            {state}
          </PrestoText>
          {isStateCompleted ? (
            <Pressable onPress={() => onDownloadReport(item)}>
              <PrestoText
                size={14}
                fontStyle={"500.medium"}
                color={isActive ? theme.white : theme.primary}
                extraStyle={{ paddingTop: 5 }}
              >
                {I18n.t("screen_messages.reports.download_report")}
              </PrestoText>
            </Pressable>
          ) : null}
        </View>

        <View
          style={{
            width: `25%`,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText
            extraStyle={{}}
            color={textColor2}
            fontStyle={"400.normal"}
          >
            {dateRange}
          </PrestoText>
        </View>

        <View
          style={{
            width: `30%`,
            flexDirection: "column",
            marginRight: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PrestoText
            extraStyle={{
              textAlign: "center",
            }}
            color={textColor2}
            fontStyle={"400.normal"}
          >
            {requester}
          </PrestoText>
        </View>
      </Pressable>
    );
  };

  return (
    <View style={{ ...(isWeb) && { height: "70vh" } }}>
      <FlatList
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        stickyHeaderIndices={[0]}
        keyExtractor={(item, index) => `${item.id}-${index}`}
        data={items}
        renderItem={renderItem}
        ListHeaderComponent={() => {
          return <RenderItemHeader />;
        }}
        onEndReached={onEndReached}
      />
    </View>
  );
}
