import React from "react";
import { PurchaseItemCards } from "presto-react-components";

export default function PurchaseItemCard(props) {
  if (props.forPurchases) {
    return <PurchaseItemCards.PurchaseItemCard2 {...props} />;
  } else {
    return <PurchaseItemCards.PurchaseItemCard1 {...props} />;
  }
}
