import SessionManager from "@presto-services/features/session/SessionManager";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import AdminManager from "@presto-services/features/admin/AdminManager";
import OperatorsManager from "@presto-services/features/operators/OperatorsManager";
import config from "@presto-common/config";
import _ from "lodash";

function operatorLogin(params, successCallback, errorCallback) {
  SessionManager.operatorLogin(params, successCallback, errorCallback);
}

function agentLogin(params, successCallback, errorCallback) {
  SessionManager.agentLogin(params, successCallback, errorCallback);
}

function adminLogin(params, successCallback, errorCallback) {
  SessionManager.adminLogin(params, successCallback, errorCallback);
}

function loginBasedOnUserType(params, successCallback, errorCallback) {
  if (isUserRoleOperator()) {
    operatorLogin(params, successCallback, errorCallback);
  } else if (isUserRoleEmployee()) {
    agentLogin(params, successCallback, errorCallback);
  } else if (isUserRoleAdmin()) {
    adminLogin(params, successCallback, errorCallback);
  }
}

function autoTokenLoginBasedOnUserType(params, successCallback, errorCallback) {
  if (isUserRoleOperator()) {
    OperatorsManager.loginOperatorWithToken(
      params,
      successCallback,
      errorCallback
    );
  } else if (isUserRoleEmployee()) {
    EmployeeManager.loginEmployeeWithToken(
      params,
      successCallback,
      errorCallback
    );
  }
}

function getUserRole() {
  return config?.user_type;
}

function isUserRoleOperator() {
  return getUserRole() === "operator";
}

function isUserRoleEmployee() {
  return getUserRole() === "employee";
}

function isUserRoleAdmin() {
  return getUserRole() === "admin";
}

function getUserMerchantId(user) {
  let val =
    isUserRoleOperator() || isUserRoleEmployee()
      ? user?.merchant_id
      : isUserRoleAdmin()
        ? user?.id
        : null;

  if (_.has(val, "$oid")) {
    val = _.get(val, "$oid");
  }

  return val;
}

function generateAutoLoginDeepLinkForOperator(userId, token) {
  const deeplinkForAutologin = _.get(
    config,
    "deeplink_options.autologin_for_operator"
  );

  if (deeplinkForAutologin) {
    let baseLink = deeplinkForAutologin.baseLink;
    let autologinLink = deeplinkForAutologin.link;
    let apnBundleIdForAutoLogin = deeplinkForAutologin.apn;

    let linkPayload = encodeURIComponent(
      `operator_id=${userId}&path=app/autologin&token=${token}`
    );

    let deepLink = `${baseLink}/?link=${autologinLink}?${linkPayload}&apn=${apnBundleIdForAutoLogin}&ibi=${apnBundleIdForAutoLogin}`;
    return deepLink;
  }
  return null;
}

function getAutoLoginTokenForOperator(userId, onSuccess, onError) {
  const onSuccessCallback = (response) => {
    response.link = generateAutoLoginDeepLinkForOperator(
      userId,
      response.data?.token
    );
    onSuccess(response);
  };

  let params = { id: userId };
  return isUserRoleEmployee()
    ? EmployeeManager.generatorLoginTokenForOperator(
      params,
      onSuccessCallback,
      onError
    )
    : isUserRoleAdmin()
      ? AdminManager.generatorLoginTokenForOperator(
        params,
        onSuccessCallback,
        onError
      )
      : null;
}

function generateAutoLoginDeepLinkForEmployee(userId, token) {
  const deeplinkForAutologin = _.get(
    config,
    "deeplink_options.autologin_for_employee"
  );

  if (deeplinkForAutologin) {
    let baseLink = deeplinkForAutologin.baseLink;
    let autologinLink = deeplinkForAutologin.link;
    let apnBundleIdForAutoLogin = deeplinkForAutologin.apn;
    let linkPayload = encodeURIComponent(
      `employee_id=${userId}&path=app/autologin&token=${token}`
    );
    let deepLink = `${baseLink}/?link=${autologinLink}?${linkPayload}&apn=${apnBundleIdForAutoLogin}&ibi=${apnBundleIdForAutoLogin}`;
    return deepLink;
  }
  return null;
}

function getAutoLoginTokenForEmployee(userId, onSuccess, onError) {
  const onSuccessCallback = (response) => {
    console.log(` getAutoLoginTokenForEmployee response`, response);

    response.link = generateAutoLoginDeepLinkForEmployee(
      userId,
      response.data?.token
    );
    onSuccess(response);
  };

  let params = { id: userId };
  AdminManager.generatorLoginTokenForEmployee(
    params,
    onSuccessCallback,
    onError
  );
}

export default {
  loginBasedOnUserType: loginBasedOnUserType,
  operatorLogin: operatorLogin,
  agentLogin: agentLogin,
  getUserRole: getUserRole,
  isUserRoleOperator: isUserRoleOperator,
  isUserRoleEmployee: isUserRoleEmployee,
  isUserRoleAdmin: isUserRoleAdmin,
  getUserMerchantId: getUserMerchantId,
  adminLogin: adminLogin,
  getAutoLoginTokenForOperator: getAutoLoginTokenForOperator,
  autoTokenLoginBasedOnUserType: autoTokenLoginBasedOnUserType,
  getAutoLoginTokenForEmployee: getAutoLoginTokenForEmployee,
};
