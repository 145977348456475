import _ from "lodash";
import { PermissionsAndroid, Platform } from "react-native";
import Share from "@presto-common/Share"
import PrintHelper from "@presto-helpers/PrintHelper";
import PrestoSdk from "@presto-services/global/PrestoSdk";
import RNFetchBlob from "@presto-common/RNFetchBlob";
import OrdersHelper from "@presto-helpers/OrdersHelper";
import PurchaseOrdersManager from "@presto-services/features/purchase_orders/PurchaseOrdersManager";
import { AxiosError } from "axios";
import asyncify from "@presto-common/Asyncify";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";

const AsyncPurchaseOrdersManager = asyncify(PurchaseOrdersManager);
const AsyncEmployeeManager = asyncify(EmployeeManager);

function printOrDownload(po, { print = false }) {
  const fetchPrestoSession = () => {
    const sessionUrl = PrestoSdk.getHostName() + "/employee.json";
    return fetch(sessionUrl).then((response) => {
      let prestoSession = response.headers.map["x-presto-st"];
      return prestoSession;
    });
  };

  return new Promise((resolve) => {
    return OrdersHelper.askFileWritePermissions().then((permissionResult) => {
      if (_.isEmpty(permissionResult)) {
        resolve({
          success: false,
          filePath: null,
        });
      }

      fetchPrestoSession()
        .then((prestoSession) => {
          let dirs = null,
            configOptions = {};
          if (Platform.OS == "android") {
            dirs = RNFetchBlob.fs.dirs;
            configOptions = {
              trusty: true,
              credentials: true,
              addAndroidDownloads: {
                useDownloadManager: true,
                notification: true,
                mime: "application/pdf",
                description: "Invoice downloaded.",
                path: `${dirs.DownloadDir}/purchase_order_${po.id}.pdf`,
              },
            };
          } else if (Platform.OS === "ios") {
            configOptions = {
              fileCache: true,
              trusty: true,
              mime: "application/pdf",
            };
          }

          let filePath = null;

          const url =
            PrestoSdk.getHostName() +
            `/employee/v1/purchase_orders/${po.id}/download_purchase_order.json`;

          RNFetchBlob.config(configOptions)
            .fetch("GET", url, { "x-presto-st": prestoSession })
            .then((resp) => {
              filePath = resp.path();

              if (Platform.OS === "android") {
                filePath = `file://${filePath}`;
              }

              return resp.readFile("base64");
            })
            .then(async (base64Data) => {
              if (Platform.OS === "ios") {
                base64Data = `data:application/pdf;base64,` + base64Data;
                try {
                  if (print) {
                    return PrintHelper.print(base64Data)
                      .then((...args) => {
                        resolve({
                          success: true,
                          filePath,
                        });
                      })
                      .catch(async (error) => {
                        try {
                          await Share.open({ url: base64Data });
                          resolve({
                            success: true,
                            filePath,
                          });
                        } catch (error) {
                          resolve({
                            success: false,
                            filePath,
                          });
                        }
                      });
                  } else {
                    try {
                      await Share.open({ url: base64Data });
                      resolve({
                        success: true,
                        filePath,
                      });
                    } catch (error) {
                      resolve({
                        success: false,
                        filePath,
                      });
                    }
                  }
                } catch (error) {
                  resolve({
                    success: false,
                    filePath,
                  });
                }
              } else if (print) {
                return PrintHelper.print(filePath).then((...args) => {
                  resolve({
                    success: true,
                    filePath,
                  });
                });
              } else {
                resolve({
                  success: true,
                  filePath,
                });
              }
            })
            .catch((error) => {
              console.log(
                "PurchaseOrderHelper->downloadInvoiceByEmployee->RNFetchBlob error ",
                error
              );

              resolve({
                success: true,
                filePath: null,
              });
            });
        })
        .catch((error) => {
          console.log(
            "PurchaseOrderHelper->downloadInvoiceByEmployee->fetchPrestoSession error ",
            error
          );

          resolve({
            success: true,
            filePath: null,
          });
        });
    });
  });
}

function DraftPo(po) {
  this.po = po;

  const AsyncManager = AsyncPurchaseOrdersManager;

  this.initiatedPo = () => {
    const params = { status: "INITIATED" };
    return AsyncManager.listDraftPo(params)
      .then(([error, response]) => {
        if (error) {
          throw error;
        }
        const data = response.data;
        return _.first(data);
      })
      .catch((error) => {
        return error;
      });
  };

  this.create = () => {
    const params = {};
    return AsyncManager.createDraftPo(params)
      .then(([error, response]) => {
        if (error) {
          throw error;
        }
        const data = response.data;
        this.po = data;
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  this.markAsConfirmed = (success, items) => {
    const params = {
      id: this.po.id,
      status: "CONFIRMED",
    };
    return AsyncManager.markDraftPoAsConfirmed(params)
      .then(([error, response]) => {
        if (error) {
          throw error;
        }
        const data = response.data;
        if (success) {
          success(this.po.id, items)
        }
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  this.approveItemPurchases = (items) => {
    let promises = _.map(items, (item) => {
      console.log(item);
      let { itemPurchaseRequest, supplierPrice } = item;
      if (!itemPurchaseRequest.id) {
        return {};
      }
      let params = {
        approved_item_purchase: {
          quantity: itemPurchaseRequest.purchase_quantity,
          item_purchase_request_id: itemPurchaseRequest.id,
          draft_purchase_order_id: this.po.id,
          supplier_id: supplierPrice.supplier_id,
        },
      };

      return AsyncEmployeeManager.approveItemPurchaseRequest(params).then(
        ([error, response]) => {
          if (error) {
            console.log("------");
            console.log(params);
            console.log(response);
            console.log(error);
            console.log(error.response);
            console.log("---- ERR ----");
            if (error instanceof AxiosError) {
              error = _.get(error, "response.data");
            }
            return error;
          }
          return response.data;
        }
      );
    });

    return Promise.all(promises);
  };
}

/**
 * @usedIn: src/screens/PurchaseRequests/PurchaseRequests.ziphealthPos.js, src/screens/PurchaseOrders/PurchaseOrders.ziphealthPos.js
 * @description: This method will returns Purchase order like object with same structure
 * @param : items as Array
 * @return : Purchase order object
 */
const getPuchaseOrderObjectAsProxy = ({ items }) => {
  let tempItems = _.cloneDeep(items);
  let modifiedItems = _.map(
    tempItems,
    ({
      product,
      itemPurchaseRequest,
      supplierPrice,
      approvedItemPurchase = null,
      itemPurchaseConfiguration = null,
    }) => {
      let purchasePrice =
        Number(_.get(supplierPrice, "purchase_price", 0)) * 100;
      let purchaseQuantity = itemPurchaseRequest?.purchase_quantity;

      let purchaseQuantityConvertedToSaleQuantity = 0;

      if (purchaseQuantity > 0) {
        let ratio = _.get(itemPurchaseConfiguration, "sale_to_purchase_ratio");
        purchaseQuantityConvertedToSaleQuantity = purchaseQuantity * ratio;
      }

      let quantity =
        purchaseQuantity > 0
          ? purchaseQuantityConvertedToSaleQuantity
          : _.get(itemPurchaseRequest || product, "quantity", 1);
      let totalPrice = purchasePrice * quantity;
      let rate = purchasePrice;

      let companyName = _.get(supplierPrice, "name", "");

      return {
        ...product,
        other_data: {
          ...product.other_data,
          company_name: companyName,
        },
        undelivered_amount: 0,
        total_price: totalPrice,
        quantity,
        delivered_quantity: quantity,
        rate,
        itemPurchaseRequest,
        supplierPrice,
        approvedItemPurchase,
        itemPurchaseConfiguration,
      };
    }
  );

  let totalPrice = _.sumBy(modifiedItems, (e) => e.total_price);

  return {
    items: modifiedItems,
    total_price: totalPrice,
    undelivered_amount: 0,
  };
};

export default {
  printOrDownload,
  DraftPo,
  getPuchaseOrderObjectAsProxy,
};
