import React, { createContext, useEffect, useState } from "react";
import NetInfo from "@react-native-community/netinfo";
import AsyncStorage from "@react-native-community/async-storage";

const NetworkContext = createContext();

export const NetworkState = {
  connected: false,
};

export const UserNetworkState = {
  online: false,
};

const USER_NETWORK_KEY = "USER_NETWORK_STATE";

export function NetworkContextProvider(props) {
  const [networkInfo, setNetworkInfo] = useState({
    isConnected: false,
    connectionType: "none",
  });
  const [userNetworkState, setUserNetworkState] = useState(false);

  const setUserNetwork = (value) => {
    setUserNetworkState(value);
    UserNetworkState.online = value;
    AsyncStorage.setItem(USER_NETWORK_KEY, value ? "FALSE" : "TRUE");
  };

  useEffect(() => {
    AsyncStorage.getItem(USER_NETWORK_KEY, (err, result) => {
      if (err) {
        setUserNetwork(true);
      } else {
        setUserNetwork(result === "FALSE");
      }
    });
  }, []);

  useEffect(() => {
    const unsubscribe = init();
    return () => {
      unsubscribe();
    };
  }, []);

  const init = () => {
    return NetInfo.addEventListener(networkChanged);
  };

  const networkChanged = (networkState) => {
    setNetworkInfo((prev) => {
      return {
        ...prev,
        isConnected: networkState.isConnected,
        connectionType: networkState.type,
      };
    });
    NetworkState.connected = networkState.isConnected;
  };

  const isOnline = () => networkInfo.isConnected;
  const isAppOnline = () => networkInfo.isConnected && userNetworkState;

  const setUserNetworkToOnline = () => {
    setUserNetwork(true);
  };

  const setUserNetworkToOffline = () => {
    setUserNetwork(false);
  };

  const toggleUserNetworkConnection = () => {
    setUserNetwork(!userNetworkState);
  };

  return (
    <NetworkContext.Provider
      value={{
        userNetworkState,
        networkInfo,
        isAppOnline,
        isOnline,
        setUserNetworkToOnline,
        setUserNetworkToOffline,
        toggleUserNetworkConnection,
      }}
    >
      {props.children}
    </NetworkContext.Provider>
  );
}

export default NetworkContext;
