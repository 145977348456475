import React, { useContext, useEffect, useState } from "react";
import { View, ScrollView, Dimensions, FlatList, Pressable, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import I18n from "i18n-js";
import _ from "lodash";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import PrestoText from "@presto-components/PrestoText";
import KeyValueList from "@presto-components/KeyValueList/KeyValueList";
import utils from "../../utils/index";
import { useCatalog } from "@presto-stores/CatalogStore";
import ActionButtons from "@presto-screen-components/ActionButtons/ActionButtons";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import asyncify from "@presto-common/Asyncify";
import { alertBox } from "@presto-common/Alert";
import Utils from "@presto-services/common/Utils";
import moment from "moment";
import {
  useGlobalStateStore,
  GlobalStoreKeys,
} from "@presto-stores/GlobalStateStore";

const AsyncEmployeeManager = asyncify(EmployeeManager);

export default function ItemDetailScreen({ navigation, route }) {
  const isWeb = Platform.OS == 'web'
  const routeParams = route?.params || {};
  const {
    sideNavigationRef,
    selectedItem,
    onClickAdd,
    onAddOrderToRequest,
    onPressPurchaseDetailButton,
    onPressEdit,
    onPressAddToCart,
    dispatch,
  } = routeParams;
  const { theme } = useContext(ThemeContext);
  const catalog = useCatalog((state) => state.catalog);
  const windowWidth = Dimensions.get("window").width;
  const styles = getStyles(theme, windowWidth);
  const enabledPrintSummary = false;
  const enableAddToStore = routeParams.enableAddToStore ?? false;
  const enableAddToCart = routeParams.enableAddToCart ?? false;
  const enableAddOrderToRequest = routeParams.enableAddOrderToRequest ?? false;
  const enablePurchaseDetailButton =
    routeParams.enablePurchaseDetailButton ?? false;
  const enabledEditItem = routeParams.enabledEditItem ?? false;

  const [inventoryBatches, setInventoryBatches] = useState([]);
  const globalStateStore = useGlobalStateStore();
  let myTab = globalStateStore.getKey("MY_TAB");
  const isMobile = utils.isMobile();

  useEffect(() => {
    init();
  }, [selectedItem]);

  const init = () => {
    if (_.isEmpty(selectedItem)) {
      setInventoryBatches([]);
    } else {
      AsyncEmployeeManager.inventoryBatchList({
        item_id: selectedItem.id,
        records_per_page: 5,
      })
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          let data = _.flatMap(response.data, (e) => e);
          data = _.filter(data, (e) => e.remaining_quantity > 0);
          data = Utils.sortItemsByDate(data, "expiry_date");
          setInventoryBatches(data);
        })
        .catch((e) => {
          console.log("ItemDetailScreen->init->error", e);
          alertBox("", e.message);
        });
    }
  };

  const onClickAddToStore = () => {
    if (onClickAdd) {
      onClickAdd();
    }
  };

  const getCategories = () => {
    return _.concat(
      catalog?.categories,
      _.flatMap(catalog?.categories, (e) => e.categories)
    );
  };

  const getCategory = (categoryId) => {
    return catalog?.categories
      ? _.find(getCategories(), ["id", categoryId])
      : null;
  };

  const onClickPrintSummary = () => { };

  const getCategoryName = () => {
    let obj = getCategory(selectedItem.category_id);
    return _.get(obj, "name", "");
  };

  const getPurchasePrice = () => {
    let val = selectedItem ? selectedItem?.purchase_price / 100 : 0;

    if (isNaN(val)) {
      val = 0;
    }

    return val;
  };

  const getBasePrice = () => {
    let val = Number(selectedItem?.price?.base_price);
    if (isNaN(val)) {
      val = 0;
    }
    return val;
  };

  const getActionButtons = () => {
    let btns = [],
      commonBtnProps = {
        size: "large",
        buttonStyle: "secondary",
        titleColor: theme.white,
        titleFontStyle: "600.semibold",
        titleSize: 24,
        height: 60,
        borderRadius: 12,
      },
      hasVariations = selectedItem?.variations?.length > 0;

    if (
      enableAddToStore &&
      myTab != "Stock Audit" &&
      myTab != "Master Catalog"
    ) {
      btns.push({
        title: I18n.t("screen_messages.add_to_store"),
        onPress: onClickAddToStore,
        bgColor: "#5F98A4",
      });
    }

    if (enableAddToCart && !hasVariations) {
      btns.push({
        title: I18n.t("screen_messages.cart.add_to_cart"),
        onPress: onPressAddToCart,
        bgColor: "#5F98A4",
      });
    }

    if (enabledPrintSummary) {
      btns.push({
        title: I18n.t("screen_messages.print_summary"),
        onPress: onClickPrintSummary,
        bgColor: theme.primary,
      });
    }

    if (
      enabledEditItem &&
      myTab != "Stock Audit" &&
      myTab != "Master Catalog"
    ) {
      btns.push({
        title: I18n.t("screen_messages.common.edit_text"),
        onPress: onPressEdit,
        bgColor: theme.primary,
      });
    }

    if (enableAddOrderToRequest) {
      btns.push({
        title: I18n.t("screen_messages.products.add_to_order_request"),
        onPress: () => onAddOrderToRequest(selectedItem),
        bgColor: "#F17B8D",
      });
    }

    if (enablePurchaseDetailButton) {
      btns.push({
        title: I18n.t("screen_messages.products.goto_purchase_details"),
        onPress: () => onPressPurchaseDetailButton(selectedItem),
        bgColor: "#5F98A4",
      });
    }
    return { btns, commonBtnProps };
  };

  let cartInfo = {
    [I18n.t("screen_messages.therapy_browse_label")]: _.get(
      selectedItem,
      "other_data.therapy_area_simple"
    ),
    [I18n.t("screen_messages.inventory.unit_price")]: utils.formattedCurrency(
      getBasePrice()
    ),
    [I18n.t("screen_messages.common.company_name")]: _.get(
      selectedItem,
      "other_data.company_name"
    ),
    [I18n.t("screen_messages.common.molecule")]: _.get(
      selectedItem,
      "other_data.molecule"
    ),
    [I18n.t("screen_messages.common.sale_price")]: _.get(
      selectedItem,
      "price.base_price"
    ),
    [I18n.t("screen_messages.common.stock_quantity")]:
      _.get(selectedItem, "stock_count") +
      " " +
      _.get(selectedItem, "other_data['sale unit']"),
    [I18n.t("screen_messages.common.sale_unit")]: _.get(
      selectedItem,
      "other_data['sale unit']"
    ),
    [I18n.t("screen_messages.common.purchase_unit")]:
      _.get(selectedItem, "other_data['sale to purchase ratio']") +
      " " +
      _.get(selectedItem, "other_data['purchase unit']"),
  };
  if (!_.isEmpty(selectedItem?.variations)) {
    selectedItem.variations.map((item) => {
      cartInfo = {
        ...cartInfo,
        [item?.name]: item?.price?.base_price,
      };
    });
  }

  const actionButtons = getActionButtons();
  console.log(inventoryBatches, "inventoryBatchesfsdjkasdifjsdifioj")
  return (
    <View
      style={{
        backgroundColor: theme.topHeaderInactiveColor,
        height: "100%",
        position: "relative",
      }}
    >
      {selectedItem ? (
        <>
          <ScrollView
            contentContainerStyle={{
              paddingTop: 30,
              paddingHorizontal: 25,
              paddingBottom: 150,
            }}
          >
            <PrestoText
              size={24}
              color={theme.white}
              fontStyle={"600.bold"}
              extraStyle={{ paddingBottom: 20 }}
            >
              {selectedItem?.name}
            </PrestoText>
            <KeyValueList
              dict={cartInfo}
              leftTextColor={theme.white}
              rightTextColor={theme.white}
            />

            {selectedItem?.description ? (
              <View>
                <PrestoText
                  size={14}
                  color={theme.white}
                  fontStyle={"600.bold"}
                  extraStyle={{ paddingTop: 20 }}
                >
                  {I18n.t("screen_messages.details_str")}
                </PrestoText>
                <PrestoText
                  size={14}
                  color={theme.lightText}
                  extraStyle={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  {selectedItem?.description}
                </PrestoText>
              </View>
            ) : null}

            {!_.isEmpty(inventoryBatches) ? (
              <View>
                <View style={{ paddingVertical: theme.primaryPadding }}>
                  <PrestoText
                    fontStyle={"600.bold"}
                    color={theme.white}
                    numberOfLines={1}
                    size={14}
                  >
                    {I18n.t("screen_messages.inventory.plural")}
                  </PrestoText>
                </View>
                <FlatList
                  data={inventoryBatches}
                  ItemSeparatorComponent={
                    !isWeb ? <View style={{ width: "100%", height: 10 }} /> : null
                  }
                  renderItem={({ item, index }) => {
                    return (
                      <Pressable
                        onPress={() => {
                          dispatch({
                            type: "SET_CURRENT_TAB",
                            payload: "Edit Request",
                          });
                          dispatch({
                            type: "SET_CURRENT_INVENTORY_PURCHASE",
                            payload: item,
                          });
                        }}
                        style={{ ...(isWeb) && { marginBottom: 20 } }}
                      >
                        <View
                          key={item.id}
                          style={{
                            padding: 10,
                            backgroundColor: theme.white,
                            borderRadius: 12,
                          }}
                        >
                          <View>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <PrestoText
                                fontStyle="600.semibold"
                                color="#333333"
                                numberOfLines={1}
                                size={14}
                              >
                                {item.batch_number}
                              </PrestoText>

                              <PrestoText
                                fontStyle="600.semibold"
                                color="#333333"
                                numberOfLines={1}
                                size={14}
                              >
                                {I18n.t("screen_messages.common.qty")}:{" "}
                                {item.remaining_quantity}
                              </PrestoText>
                            </View>

                            <View
                              style={{
                                paddingTop: 5,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <PrestoText
                                fontStyle="600.semibold"
                                color="#666666"
                                numberOfLines={1}
                                size={14}
                              >
                                {I18n.t("screen_messages.products.expiry")}:{" "}
                                {moment(item.expiry_date).format("DD-MM-YYYY")}
                              </PrestoText>
                              <PrestoText
                                fontStyle="600.semibold"
                                color="#333333"
                                numberOfLines={1}
                                size={14}
                              >
                                {item.purchase_price}
                              </PrestoText>
                            </View>
                          </View>
                        </View>
                      </Pressable>
                    );
                  }}
                />
              </View>
            ) : null}
          </ScrollView>
          {actionButtons.btns.length ? (
            <View
              style={{
                backgroundColor: theme.white,
                height: 120,
                borderTopLeftRadius: 18,
                borderTopRightRadius: 18,
                width: isMobile ? "100%" : 400,
                position: "absolute",
                bottom: 0,
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  paddingHorizontal: theme.primaryPadding,
                }}
              >
                <ActionButtons
                  btns={actionButtons.btns}
                  commonBtnProps={actionButtons.commonBtnProps}
                />
              </View>
            </View>
          ) : null}
        </>
      ) : (
        <View style={{ padding: 20 }}>
          <PrestoText size={22} fontStyle={"600.semibold"} color={theme.white}>
            {I18n.t("screen_messages.returns.please_select_an_item")}
          </PrestoText>
        </View>
      )}
    </View>
  );
}

function getStyles(theme) {
  return {
    footerContainer: {
      paddingHorizontal: 10,
      flexDirection: "row",
      bottom: 0,
      width: "100%",
      paddingBottom: 20,
    },
    footerInner: {
      width: "100%",
      padding: 5,
      flex: 1,
    },
  };
}
