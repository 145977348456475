import Utils from "../common/Utils";
import AddressObject from "./AddressObject";
import ErrorConstants from "../common/ErrorConstants";
import PrestoSdk from "../global/PrestoSdk";
import UserObject from "./UserObject";
import ServiceItemObject from "./ServiceItemObject";
import $ from "../global/util";

const ClientObject = function (
  Utils,
  AddressObject,
  ErrorConstants,
  PrestoSdk,
  UserObject,
  ServiceItemObject
) {
  function ClientObject() {
    this.merchant_id = PrestoSdk.getMerchantId();

    this.id = null;
    this.name = "";
    this.email = "";
    this.birthday = "";
    this.anniversary = "";
    this.profession = "";
    this.marital_status = "";
    this.national_id = "";
    this.membership_type = "";
    this.country_code = "";
    this.password = "";
    this.role = "";
    this.referrer_user_id = "";
    this.referral_code = "";
    this.referrer_user_type = "";
    this.phone_number = "";
    this.profile_pic = "";
    this.about = "";
    this.catalog_type = "";
    this.unread_notification_count = "";
    this.Current_cycle_count = "";
    this.service_ids = "";
    this.addresses = new AddressObject();
  }

  ClientObject.prototype = {
    validateObject: function () {
      var error = Utils.ErrorObject(ErrorConstants.ErrorCodes.SUCCESS, true);
      return error;
    },
    buildObject: function (rawEmployeeObj) {
      this.merchant_id = rawEmployeeObj.merchant_id;
      this.id = rawEmployeeObj.id;
      this.name = rawEmployeeObj.name;
      this.email = rawEmployeeObj.email;
      this.birthday = rawEmployeeObj.birthday;
      this.anniversary = rawEmployeeObj.anniversary;
      this.profession = rawEmployeeObj.profession;
      this.marital_status = rawEmployeeObj.marital_status;
      this.national_id = rawEmployeeObj.national_id;
      this.membership_type = rawEmployeeObj.membership_type;
      this.country_code = rawEmployeeObj.country_code;
      this.password = rawEmployeeObj.password;
      this.role = rawEmployeeObj.role;
      this.referrer_user_id = rawEmployeeObj.referrer_user_id;
      this.referral_code = rawEmployeeObj.referral_code;
      this.referrer_user_type = rawEmployeeObj.referrer_user_type;
      this.phone_number = rawEmployeeObj.phone_number;
      this.profile_pic = rawEmployeeObj.profile_pic;
      this.about = rawEmployeeObj.about;
      this.catalog_type = rawEmployeeObj.catalog_type;
      this.unread_notification_count = rawEmployeeObj.unread_notification_count;
      this.Current_cycle_count = rawEmployeeObj.Current_cycle_count;
      this.service_ids = rawEmployeeObj.service_ids;
      let addressObj = new AddressObject();
      addressObj.buildObject(rawEmployeeObj.address);
      this.addresses = addressObj;
    },
  };

  return ClientObject;
};

export default ClientObject(
  Utils,
  AddressObject,
  ErrorConstants,
  PrestoSdk,
  UserObject,
  ServiceItemObject
);
