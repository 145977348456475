import { View, Pressable, PermissionsAndroid } from "react-native";
import * as Print from "expo-print";
import RNFetchBlob from "@presto-common/RNFetchBlob";
import PrestoSdk from "@presto-services/global/PrestoSdk";

function print(filePath) {
  const options = {
    uri: filePath,
  };
  return Print.printAsync(options);
}

function printBase64(base64Data) {
  const options = {
    base64: base64Data,
  };
  return Print.printToFileAsync(options);
}

export default {
  print,
  printBase64,
};
