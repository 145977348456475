import PurchaseOrdersResource from "./PurchaseOrdersResource";
import ErrorConstants from "../../common/ErrorConstants";
import Utils from "../../common/Utils";
import PrestoSdk from "../../global/PrestoSdk";
import UserObject from "../../data_models/UserObject";
import $ from "../../global/util";

const PurchaseOrdersManager = function (
  PurchaseOrdersResource,
  ErrorConstants,
  Utils,
  PrestoSdk,
  UserObject
) {
  function create(params, successCallback, errorCallback) {
    PurchaseOrdersResource.create(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function show(params, successCallback, errorCallback) {
    PurchaseOrdersResource.show(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function show(params, successCallback, errorCallback) {
    PurchaseOrdersResource.show(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function addItem(params, successCallback, errorCallback) {
    PurchaseOrdersResource.addItem(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeItem(params, successCallback, errorCallback) {
    PurchaseOrdersResource.removeItem(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateItem(params, successCallback, errorCallback) {
    PurchaseOrdersResource.updateItem(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function markAsWaitingForApproval(params, successCallback, errorCallback) {
    PurchaseOrdersResource.markAsWaitingForApproval(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function approve(params, successCallback, errorCallback) {
    PurchaseOrdersResource.approve(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function download(params, successCallback, errorCallback) {
    PurchaseOrdersResource.download(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function reject(params, successCallback, errorCallback) {
    PurchaseOrdersResource.reject(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function list(params, successCallback, errorCallback) {
    PurchaseOrdersResource.list(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function updateDeliveredQuantity(params, successCallback, errorCallback) {
    PurchaseOrdersResource.updateDeliveredQuantity(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function markAsDelivered(params, successCallback, errorCallback) {
    PurchaseOrdersResource.markAsDelivered(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function close(params, successCallback, errorCallback) {
    PurchaseOrdersResource.close(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function order(params, successCallback, errorCallback) {
    PurchaseOrdersResource.order(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function listDraftPo(params, successCallback, errorCallback) {
    PurchaseOrdersResource.listDraftPo(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createDraftPo(params, successCallback, errorCallback) {
    PurchaseOrdersResource.createDraftPo(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function markDraftPoAsConfirmed(params, successCallback, errorCallback) {
    PurchaseOrdersResource.markDraftPoAsConfirmed(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );

  }

  function getBatchAndExpiry(params, successCallback, errorCallback) {
    PurchaseOrdersResource.getBatchAndExpiry(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );

  }

  return {
    create,
    show,
    addItem,
    removeItem,
    updateItem,
    markAsWaitingForApproval,
    approve,
    download,
    reject,
    list,
    updateDeliveredQuantity,
    markAsDelivered,
    close,
    order,
    listDraftPo,
    createDraftPo,
    markDraftPoAsConfirmed,
    getBatchAndExpiry,
  };
};

export default PurchaseOrdersManager(
  PurchaseOrdersResource,
  ErrorConstants,
  Utils,
  PrestoSdk,
  UserObject
);
