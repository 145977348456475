import React, { useEffect, useState, useContext, useRef, useReducer } from "react";
import { View, ScrollView } from "react-native";
import LoadingContainer from "@presto-components/LoadingContainer";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import I18n from "i18n-js";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import utils from "../../utils";
import UserContext from "@presto-contexts/UserContext";
import YearMonthPicker from "@presto-components/yearMonthPicker";

function reducer(state, { payload, type }) {
  switch (type) {
    case "setBatchNumber":
      return { ...state, batchNumber: payload };
    case "setExpiry":
      return { ...state, expiry: payload };
    case "setPrice":
      return { ...state, price: payload };
    case "setQuantity":
      return { ...state, quantity: payload };
    case "setAll":
      return { ...state, ...payload }
  }
}

const PurchaseRequestUpdate = ({ selectedItem, onUpdate }) => {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const isMobile = utils.isMobile();
  console.log(selectedItem, "ksdkjasdjlfjsdkljlk")

  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    price: selectedItem?.purchase_price,
    expiry: selectedItem?.expiry_date,
    batchNumber: selectedItem?.batch_number,
    quantity: selectedItem?.remaining_quantity?.toString(),
  })
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  const handleFocus = () => {
    setIsFocused(true);
    // Optionally, do something when the TextInput is focused
  };

  const handleBlur = () => {
    setIsFocused(false);
    // Optionally, do something when the TextInput is blurred
  };

  const { price, expiry, batchNumber, quantity } = state

  const renderLabel = (title) => {
    return (
      <PrestoText color={theme.white} fontStyle={"400.semibold"} size={14}>
        {title}
      </PrestoText>
    );
  };

  useEffect(() => {
    dispatch({
      type: "setAll", payload: {
        price: selectedItem?.purchase_price?.toString(),
        expiry: selectedItem?.expiry_date,
        batchNumber: selectedItem?.batch_number,
        quantity: selectedItem?.remaining_quantity.toString()
      }
    })
  }, [selectedItem])

  console.log(state, "kdsjfkjasdfkjklfjsdk")

  const renderTextInput = (
    label,
    placeholder = "",
    value,
    onChange,
    editable = true,
    keyboardType = "default",
  ) => {
    const onDateChange = (value) => {
      dispatch({ type: "setExpiry", payload: value.toISOString() })
    };
    return (
      <View style={{ width: "48%" }}>
        {label ? (
          <View>
            {renderLabel(label)}
            <VerticalSpacing size={10} />
          </View>
        ) : null}
        {editable ?
          <PrestoTextInput
            editable={editable}
            style={{}}
            width={"100%"}
            theme={theme}
            placeholder={placeholder}
            value={value}
            keyboardType={keyboardType}
            onChange={({ nativeEvent: { text } }) => dispatch({
              type: onChange,
              payload: text
            })}
            onFocus={handleFocus}
            onBlur={handleBlur}
            backgroundColor={theme.white}
            borderWidth={2}
          />
          : <View style={{
            width: "100%",
            backgroundColor: theme.white,
            borderWidth: 2,
            borderRadius: theme.textInputBorderRadius || 25,
            borderColor: theme.textInputBorderColor || "#CCCCCC",
          }}>
            <View
              style={{
                height: 50,
                flexDirection: "column",
                borderRightWidth: 1,
                borderRightColor: "#DBDBDB",
                marginRight: 0,
                alignItems: "center",
                justifyContent: "center",
                paddingRight: 20,
                marginRight: 0,
              }}
            >
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#fff",
                }}
              >
                <YearMonthPicker isSet={true} date={value} onDateChange={onDateChange}>
                </YearMonthPicker>
              </View>
            </View>
          </View>
        }
      </View>
    );
  };

  const onSubmit = async () => {
    const params = {
      id: selectedItem?.id,
      data: {
        inventory_batch: {
          remaining_quantity: Number(quantity),
          purchase_price: Number(price),
          expiry_date: expiry,
          batch_number: batchNumber,
        }
      }
    }
    await EmployeeManager.inventoryBatchUpdate(params, () => {
      onUpdate();
    }, (error) => alert(error?.message))
  }

  return (
    <LoadingContainer bgColor={theme.screenBackgroundColor} loading={loading}>
      <ScrollView
        contentContainerStyle={{
          backgroundColor: theme.createItemBgColor,
          borderRadius: 20,
          margin: 20,
          paddingVertical: 30,
          paddingHorizontal: 20,
        }}
      >
        <View style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", alignItems: 'center', width: "100%" }}>
          {/* <> */}
          {renderTextInput("BATCH NUMBER", "", batchNumber, "setBatchNumber")}
          {renderTextInput("Purchase Price", "", price, "setPrice", true, "numeric")}
          {renderTextInput("Quantity", "", quantity, "setQuantity", true, "numeric")}
          {renderTextInput("Expiry by", "", expiry, "setExpiry", false)}
          {/* </> */}
        </View>
        <View style={{ flexDirection: "row", justifyContent: "flex-end", marginTop: 19 }}>
          <PrestoSolidButton
            size="large"
            buttonStyle="primary"
            title={I18n.t("screen_messages.common.update")}
            titleColor={theme.white}
            titleFontStyle={"600.semibold"}
            titleSize={22}
            height={50}
            width={100}
            borderRadius={10}
            // disable={
            //   formSteps.isReferenceId
            //     ? !formSteps.purchaseConfiguration
            //     : true
            // }
            onPress={onSubmit}
            bgColor={theme.primary}
          />
        </View>
      </ScrollView>
    </LoadingContainer>
  )
}

export default PurchaseRequestUpdate
