import React, { useContext, useState, useEffect } from "react";
import { View, StyleSheet, TextInput, Pressable, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import { IconButton } from "@presto-components/PrestoButton";
import { useAssets } from "presto-react-components";

export default function TextInputDoubleButton(props) {
  const { svgs } = useAssets();
  const {
    placeholder,
    onNextPress,
    onPlusPress,
    onSearchText,
    showCamera,
    onPressCamera,
    disablePressCamera = false,
    disableNextPress = false,
    disablePlusPress = false,
    iconLeft,
    isEditable = true,
    hideArrow = false,
    hidePlus = false,
    autoCapitalize = "none",
    hideSearch = true,
    onSearchPress,
    extraStyle,
    iconBgColor,
    textInputStyle = {},
    arrowButtonContainerStyles = {},
    arrowButtonProps = {},
  } = props;
  const [searchText, setSearctText] = useState("");
  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme, extraStyle, textInputStyle);
  let isAndroid = Platform.OS === "android";

  useEffect(() => {
    setSearctText(props.searchText);
  }, [props.searchText]);

  const PlusButtonComponent = () => {
    return (
      <View
        style={{
          width: 75,
          backgroundColor: theme.primary,
          borderRightWidth: !hideArrow ? 1 : 0,
          borderRightColor: theme.tertiary,
          opacity: disablePlusPress ? 0.4 : 1,
          borderTopRightRadius: !hideArrow ? 0 : 7,
          borderBottomRightRadius: !hideArrow ? 0 : 7,
        }}
      >
        <IconButton
          width={isAndroid && disablePlusPress ? 75 : 70}
          height="100%"
          borderRadius={isAndroid && 0}
          bgColor={theme.primary}
          icon={iconLeft ?? <svgs.PlusIconBuposOperator color={theme.white} />}
          onPress={() => {
            if (!disablePlusPress) {
              onPlusPress(searchText);
            }
          }}
        />
      </View>
    );
  };

  const ArrowButtonComponent = () => {
    return (
      <View
        style={{
          width: 58,
          backgroundColor: iconBgColor ? iconBgColor : theme.secondary,
          borderTopRightRadius: 7,
          borderBottomRightRadius: 7,
          opacity: disableNextPress ? 0.4 : 1,
          ...arrowButtonContainerStyles,
        }}
      >
        <IconButton
          width={isAndroid && disableNextPress ? 58 : 57}
          height="100%"
          borderRadius={isAndroid && 0}
          borderTopRightRadius={7}
          borderBotRightRadius={7}
          bgColor={iconBgColor ? iconBgColor : theme.secondary}
          borderColor={iconBgColor ? iconBgColor : theme.secondary}
          icon={<svgs.RightArrowWithTail color={theme.white} />}
          onPress={() => {
            if (!disableNextPress) {
              onNextPress(searchText);
            }
          }}
          {...arrowButtonProps}
        />
      </View>
    );
  };

  const SearchButtonComponent = () => {
    return (
      <View
        style={{
          width: 58,
          backgroundColor: theme.secondary,
          borderTopRightRadius: 7,
          borderBottomRightRadius: 7,
          opacity: disableNextPress ? 0.4 : 1,
        }}
      >
        <IconButton
          width={57}
          height="100%"
          bgColor={theme.secondary}
          borderColor={theme.secondary}
          icon={<svgs.SearchIcon2 color={theme.white} />}
          onPress={() => {
            if (!disableNextPress) {
              onSearchPress(searchText);
            }
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        <View style={styles.textInputContainer}>
          <TextInput
            editable={isEditable}
            underlineColorAndroid="transparent"
            autoCorrect={true}
            keyboardType="default"
            value={searchText}
            placeholder={placeholder}
            placeholderTextColor={theme.placeHolderColor}
            onChangeText={(text) => {
              setSearctText(text);
              onSearchText && onSearchText(text);
            }}
            style={styles.textInputStyle}
            autoCapitalize={autoCapitalize}
          />
        </View>
        <View style={styles.buttonContainer}>
          {showCamera ? (
            <Pressable
              onPress={() => {
                if (!disablePressCamera) {
                  onPressCamera();
                }
              }}
              style={{
                width: 50,
                alignItems: "center",
                justifyContent: "center",
                opacity: disablePressCamera ? 0.4 : 1,
              }}
            >
              <svgs.CameraFilled color={theme.tertiary} />
            </Pressable>
          ) : null}

          {!hidePlus ? PlusButtonComponent() : null}
          {!hideArrow ? ArrowButtonComponent() : null}
          {!hideSearch ? SearchButtonComponent() : null}
        </View>
      </View>
    </View>
  );
}

function getStyles(theme, extraStyle, textInputStyle) {
  return {
    container: {
      flex: 1,
      width: "100%",
      borderColor: theme.tertiary,
      height: 63,
      backgroundColor: theme.white,
      borderRadius: 8,
      borderWidth: 1,
      ...extraStyle,
    },
    inner: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    textInputContainer: {
      flexGrow: 1,
      flex: 1,
    },
    textInputStyle: {
      paddingLeft: 10,
      fontSize: 12,
      height: "100%",
      ...textInputStyle,
    },
    buttonContainer: {
      flexDirection: "row",
    },
  };
}
