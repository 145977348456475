import React, {
  useContext,
  useState,
  useEffect,
  useReducer,
  useCallback,
  useRef,
} from "react";
import {
  View,
  ScrollView,
  FlatList,
  Pressable,
  Dimensions,
  Image,
  Platform,
} from "react-native";
import I18n from "i18n-js";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import QPItemCard from "@presto-screen-components/ItemCard/QPItemCard";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import {
  PrestoSolidButton,
  PrestoFramedButton,
} from "@presto-components/PrestoButton";
import LoadingContainer from "@presto-components/LoadingContainer";
import { alertBox, prestoAlert } from "@presto-common/Alert";
import _ from "lodash";
import utils from "../../utils/index";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useCatalog } from "@presto-stores/CatalogStore";
import SearchManager from "@presto-services/features/search/SearchManager";
import { usePagination } from "../../hooks/pagination";
import SearchBar from "@presto-screen-components/SearchBar/SearchBar";
import SideNavigation from "@presto-screen-components/SideNavigation/SideNavigation";
import HeaderWithTabs from "@presto-screen-components/Headers/HeaderWithTabs";
import ModalMobile from "@presto-components/Modals/Modal.bupos";
import moment from "moment";
import PlusMinusButton from "@presto-components/PlusMinusButtons/PlusMinusButton";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import { createNavigationContainerRef } from "@react-navigation/native";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import { VerticalSpacing } from "@presto-components/Spacing";
import TextInputLargeBox from "@presto-components/TextInputs/TextInputLargeBox";
import { useAssets } from "presto-react-components";
import { useActionSheet } from "@expo/react-native-action-sheet";
import PickerMobile from "@presto-components/PrestoPickers/PickerMobile.buadmin";
import UserContext from "@presto-contexts/UserContext";
import DatePickerComponent from "@presto-components/CalendarComponent/DatePickerComponent";
import asyncify from "@presto-common/Asyncify";
import ProductForm from "@presto-screen-components/AllStock/ProductForm.ziphealthPos";
import PurchaseRequestUpdate from "@presto-screen-components/AllStock/PurchaseRequestUpdate";
import ItemPurchaseRequestTable from "@presto-screen-components/PurchaseRequests/ItemPurchaseRequestTable";
import MyPurchaseRequests from "@presto-screen-components/PurchaseRequests/MyPurchaseRequests";
import PurchaseOrdersManager from "@presto-services/features/purchase_orders/PurchaseOrdersManager";
import PurchaseOrderHelper from "@presto-helpers/PurchaseOrderHelper";
import LoginHelper from "@presto-helpers/LoginHelper";
import { AxiosError } from "axios";
import DBCategoryItemManager from "@presto-db-managers/DBCategoryItemManager";
import PrestoIcon from "@presto-components/PrestoIcon";
import PrestoAmountInput from "@presto-components/AmountInputs/PrestoSmallAmountInput";
import PrestoAmountInputWithPlusMinus from "@presto-components/AmountInputs/PrestoAmountInputWithPlusMinus";
import TextInputDoubleButton from "@presto-components/TextInputButtons/TextInputDoubleButton";
import CheckBoxWithText from "@presto-components/CheckBoxes/CheckBoxWithText";
import PrestoSdk from "@presto-services/global/PrestoSdk";
import {
  useGlobalStateStore,
  GlobalStoreKeys,
} from "@presto-stores/GlobalStateStore";
import CategoryDataSources from "@presto-datasources/CategoryDataSources";
import AsyncStorage from "@react-native-community/async-storage";
import YearMonthPicker from "@presto-components/yearMonthPicker";
import { OfflineSync } from "@presto-helpers/OfflineSync";
import Config from "@presto-common/config";


const AsyncEmployeeManager = asyncify(EmployeeManager);
const AsyncSearchManager = asyncify(SearchManager);
const AsyncPurchaseOrdersManager = asyncify(PurchaseOrdersManager);

function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_TOP_LEVEL_CATEGORIES":
      return { ...state, topLevelCategories: payload };
    case "SET_CATEGORY_COUNT":
      return {
        ...state,
        categoryCount: {
          ...state.categoryCount,
          [payload.cat_id]: payload.count,
        },
      };
    case "SET_SEARCH_QUERY":
      return { ...state, searchQuery: payload };
    case "SET_CURRENT_TAB":
      return { ...state, currentTab: payload };
    case "SET_ITEM_NAME":
      return { ...state, itemName: payload };
    case "SET_ITEM_DESCRIPTION":
      return { ...state, itemDesc: payload };
    case "SET_ITEM_LISTING_ORDER":
      return { ...state, itemListingOrder: payload };
    case "SET_ITEM_PRICE":
      return { ...state, itemPrice: payload };
    case "SET_ITEM_STOCK":
      return { ...state, itemStock: payload };
    case "SET_ITEM_CATEGORY":
      return { ...state, itemCategory: payload };
    case "SET_CATEGORY_TYPES":
      return { ...state, categoryTypes: payload };
    case "RESET":
      return { ...state, ...payload };
    case "SET_CURRENT_INVENTORY_PURCHASE":
      return { ...state, currentInventoryPurchase: payload }
  }
}

export default function AllStock({ navigation, route }) {
  const { selectedFeature } = route?.params;
  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  const isWeb = Platform.OS == 'web'
  const [loading, setLoading] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { images, svgs } = useAssets();
  const styles = getStyles(theme);
  const insets = useSafeAreaInsets();
  const catalog = useCatalog((state) => state.catalog);
  const { Navigator } = useContext(NavigatorContext);
  const { user } = useContext(UserContext);
  const { showActionSheetWithOptions } = useActionSheet();
  const [searchedItems, setSearchItems] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [addInventoryModalVisible, setAddInventoryModalVisible] = useState(
    false
  );
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [itemQty, setItemQuantity] = useState(0);
  const [suppliers, setSuppliers] = useState([]);
  const [purchasePrice, setPurchasePrice] = useState("");
  const [itemRefernceId, setItemRefernceId] = useState("");
  const [itemCatalogId, setItemCatalogId] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [editedItemId, setEditedItemId] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [isIncomingScreen, setIsIncomingScreen] = useState("true");
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemPurchaseRequests, setItemPurchaseRequests] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [actionHandler, setActionHandler] = useState(null);
  const merchantId = LoginHelper.getUserMerchantId(user);
  const [myTab, setMyTab] = useState("Store Inventory");
  const globalStateStore = useGlobalStateStore();
  const [currentSortDirection, setCurrentSortDirection] = useState("ASC");
  const [totalResultCount, setTotalResultCount] = useState(0);
  const [sortField, setSortField] = useState("Name");
  let [selectExpiringSoon, setSelectExpiringSoon] = useState(false);
  const [isShowShortExpiry, setShowShortExpiry] = useState(false);
  const extraOptions = useRef({
    selectedItems: [],
    selectedItemPurchaseRequests: [],
    draftPo: null,
  });
  let isTabAndAbove = windowWidth > 500 ? true : false;

  const setParentLoading = (value) => {
    dispatch({ type: "SET_LOADING", payload: value });
  };
  const [itemForm, setItemForm] = useState({
    supplierPrices: [
      {
        supplierId: null,
        purchasePrice: "",
        purchaseConfigurationId: "",
      },
      {
        supplierId: null,
        purchasePrice: "",
        purchaseConfigurationId: "",
      },
    ],
  });
  const [stockForm, setStockForm] = useState({
    batchNumber: "",
    purchasePrice: "",
  });
  const [focusedItem, setFocusedItem] = useState(null);
  const [itemPurchaseConfiguration, setItemPurchaseConfiguration] = useState(
    null
  );
  const navigationRef = createNavigationContainerRef();
  const [tabs, setTabs] = useState(
    _.compact([
      {
        id: "master_catalog",
        value: "Master Catalog",
        text: I18n.t("screen_messages.inventory.master_catalog"),
        defaultStyles: {
          paddingLeft: 25,
          paddingRight: 25,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
        isActive: globalStateStore.getKey("MY_TAB") == "Master Catalog",
      },
      {
        id: "catalog",
        value: "Store Inventory",
        text: I18n.t("screen_messages.inventory.store_inventory"),
        defaultStyles: {
          paddingLeft: 25,
          paddingRight: 25,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
        isActive:
          globalStateStore.getKey("MY_TAB") == "Store Inventory" ||
          !globalStateStore.getKey("MY_TAB"),
      },
      {
        id: "quick_price",
        value: "Quick Price",
        text: I18n.t("screen_messages.inventory.quick_price"),
        defaultStyles: {
          paddingLeft: 25,
          paddingRight: 25,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
        isActive: globalStateStore.getKey("MY_TAB") == "Quick Price",
      },
      {
        id: "received_products",
        value: "Non Zip Purchases",
        text: I18n.t("screen_messages.inventory.non_zip_purchases"),
        defaultStyles: {
          paddingLeft: 25,
          paddingRight: 25,
          marginLeft: 1,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
        isActive: globalStateStore.getKey("MY_TAB") == "Non Zip Purchases",
      },
      {
        id: "zip_open_orders",
        value: "Open Zip Orders",
        text: I18n.t("screen_messages.inventory.open_zip_orders"),
        defaultStyles: {
          paddingLeft: 25,
          paddingRight: 25,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
        isActive: globalStateStore.getKey("MY_TAB") == "Open Zip Orders",
      },
      false && {
        id: "addcustom",
        value: "addcustom",
        text: I18n.t("screen_messages.inventory.add_custom"),
        defaultStyles: {
          backgroundColor: theme.primary,
          height: 48,
        },
        tabisActiveStyles: {
          backgroundColor: theme.subHeader,
          height: 48,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
      },
      {
        id: "stock_audit",
        value: "Stock Audit",
        text: I18n.t("screen_messages.inventory.stock_audit"),
        defaultStyles: {
          paddingLeft: 25,
          paddingRight: 25,
          marginLeft: 1,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
        isActive: globalStateStore.getKey("MY_TAB") == "Stock Audit",
      },
    ])
  );
  let myTab1 = globalStateStore.getKey("MY_TAB");
  console.log("MM- " + JSON.stringify(myTab1));
  const [state, dispatch] = useReducer(reducer, {
    topLevelCategories: [],
    categoryCount: {},
    searchQuery: "",
    currentTab: myTab1 || "Store Inventory",
    itemName: "",
    itemDesc: "",
    itemListingOrder: 0,
    itemPrice: 0,
    itemStock: "",
    itemCategory: "",
    categoryTypes: createCategories(),
    currentInventoryPurchase: {}
  });
  const isMobile = utils.isMobile();

  let searchQuery = state?.searchQuery;
  const currentTab = state?.currentTab || "Open Zip Orders";
  const categoryTypes = state?.categoryTypes || createCategories();
  console.log(state?.categoryTypes);
  console.log(categoryTypes);
  console.log("---cats----");

  const itemName = state?.itemName || "";
  const itemDesc = state?.itemDesc || "";
  const itemListingOrder = state?.itemListingOrder || "";
  const itemPrice = state?.itemPrice || "";
  const itemStock = state?.itemStock || "";
  const itemCategory = state?.itemCategory || "";
  let sortDirection = state?.currentSortDirection || "ASC";
  const reasons = [
    I18n.t("screen_messages.reasons.missing"),
    I18n.t("screen_messages.reasons.excess"),
    I18n.t("screen_messages.reasons.expiry"),
    I18n.t("screen_messages.reasons.damaged"),
    I18n.t("screen_messages.reasons.wrong_item"),
    I18n.t("screen_messages.reasons.others"),
    I18n.t("screen_messages.reasons.stock_transfer"),
    I18n.t("screen_messages.reasons.cancel"),
  ];

  useEffect(() => {
    if (user) {
      loadFirstPage();
    }
  }, [searchQuery, user]);

  useEffect(() => {
    EmployeeManager.suppliers(
      {},
      (res) => {
        setSuppliers(res.data);
      },
      (err) => {
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    if (selectedItem) {
      fetchAndSetItemPurchaseConfiguration();
      if (isMobile && currentTab === "Store Inventory") {
        Navigator.emit({
          event: "modal",
          params: {
            screenName: "ITEM_DETAILS_SCREEN",
            screenParams: {
              selectedItem: selectedItem,
              sideNavigationRef: navigationRef,
              enabledEditItem: true,
              dispatch: dispatch,
              onPressEdit: () => {
                setItemForForm(selectedItem);
                dispatch({
                  type: "SET_CURRENT_TAB",
                  payload: "Add Custom",
                });
                Navigator.emit({ event: "goBack" });
              },
            },
          },
        });
      } else {
        navigationRef.navigate("ItemDetailScreen", {
          selectedItem: selectedItem,
          sideNavigationRef: navigationRef,
          enabledEditItem: true,
          dispatch: dispatch,
          onPressEdit: () => {
            setItemForForm(selectedItem);
            dispatch({
              type: "SET_CURRENT_TAB",
              payload: "Add Custom",
            });
          },
        });
      }
    } else {
      setItemPurchaseConfiguration(null);
    }
  }, [selectedItem]);

  useEffect(() => {
    console.log("I have changed");
    loadFirstPage();
  }, [selectedItemIds, isShowShortExpiry]);

  useEffect(() => {
    if (actionHandler) {
      onActionHandle();
    }
  }, [actionHandler, purchaseOrders, purchaseOrder, activeRow]);

  const onActionHandle = () => {
    if (actionHandler.type === "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL") {
      handleSideNavigation();
      setActionHandler(null);
    }
  };

  const callbackAfterPoStatusUpdate = () => {
    navigationRef.navigate("InitialPurchaseRequestDetail", {
      initial: null,
    });
    loadPurchaseOrders();
    setPurchaseOrder(null);
  };

  const handleSideNavigation = async () => {
    let status = _.get(purchaseOrder, "status");
    navigationRef.navigate("InitialPurchaseRequestDetail", {
      //supplierOrder: supplierOrder,
      parentLoading: loading,
      setParentLoading: setParentLoading,
      enableApproveButton: status === "AWAITING_APPROVAL",
      enableRejectButton: status === "AWAITING_APPROVAL",
      enableEditDeliveryButton: false,
      enableDownloadPO: _.includes(["APPROVED", "DELIVERED", "CLOSED"], status),
      enableMarkAsDeliveredButton: status === "APPROVED",
      enableMarkAsClosedButton: status === "DELIVERED",
      initial: purchaseOrder,
      sideNavigationRef: navigationRef,
      onPressApproveButton: (po) => {
        setParentLoading(true);
        return purchaseOrderObject
          .markAsApproved(po)
          .then(({ success }) => {
            if (success) {
              callbackAfterPoStatusUpdate();
              alertBox("Purchase order moved to approved state");
            } else {
              alertBox("Failed to move Purchase order to approved state");
            }
            setParentLoading(false);
          })
          .catch((e) => setParentLoading(false));
      },
      onPressRejectButton: (po, rejectionReason) => {
        setParentLoading(true);
        return purchaseOrderObject
          .markAsRejected(po, rejectionReason)
          .then(({ success }) => {
            if (success) {
              callbackAfterPoStatusUpdate();
              alertBox("Purchase order moved to rejected state");
            } else {
              alertBox("Failed to move Purchase order to rejected state");
            }

            setParentLoading(false);
          })
          .catch((e) => setParentLoading(false));
      },
      onUpdateItem: (item, qty) => {
        if (purchaseOrder?.id) {
          return purchaseOrderObject.updateItemDeliveredQty(
            purchaseOrder,
            item,
            qty
          );
        } else {
          return onItemPurchaseRequestUpdate(item, qty);
        }
      },
      onRemoveItem: (item, qty) => {
        if (purchaseOrder?.id) {
          return purchaseOrderObject.updateItemDeliveredQty(
            purchaseOrder,
            item,
            qty
          );
        } else {
          return onItemPurchaseRequestUpdateRemove(item);
        }
      },
      onPressMarkAsDeliveredButton: (po) => {
        return purchaseOrderObject
          .markAsDelivered(po)
          .then(({ success }) => {
            console.log("I came here");
            if (success) {
              callbackAfterPoStatusUpdate();
              alertBox("Purchase order moved to delivered state");
            } else {
              alertBox("Failed to move Purchase order to delivered state");
            }

            setParentLoading(false);
          })
          .catch((e) => setParentLoading(false));
      },
      onPressmarkAsClosedButton: (po) => {
        return purchaseOrderObject
          .markAsClosed(po)
          .then((updatedPo) => {
            if (updatedPo.status === "CLOSED") {
              callbackAfterPoStatusUpdate();
              alertBox("Purchase order moved to closed state");
            } else {
              alertBox("Failed to move Purchase order to closed state");
            }
            setParentLoading(false);
          })
          .catch((e) => setParentLoading(false));
      },
      resetPurchaseRequest: () => { },
      reloadSideNav: () => {
        setActionHandler({
          type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
        });
      },
    });
  };

  const fetchAndSetItemPurchaseConfiguration = () => {
    let params = { id: selectedItem.id };
    AsyncEmployeeManager.itemPurchaseConfigurationShow(params).then(
      ([error, response]) => {
        if (error) {
          setItemPurchaseConfiguration(null);
          /*let message =
            error instanceof AxiosError
              ? _.get(error, "response.data.message")
              : error.message;
          return alertBox("", message);*/
          return;
        }

        setItemPurchaseConfiguration(response.data);
      }
    );
  };

  const setItemForForm = (item) => {
    dispatch({
      type: "SET_ITEM_NAME",
      payload: item.name,
    });

    dispatch({
      type: "SET_ITEM_DESCRIPTION",
      payload: item.description,
    });

    dispatch({
      type: "SET_ITEM_LISTING_ORDER",
      payload: item.listing_order?.toString(),
    });

    dispatch({
      type: "SET_ITEM_PRICE",
      payload: _.get(item, "price.base_price")?.toString(),
    });

    dispatch({
      type: "SET_ITEM_STOCK",
      payload: item.stock_count?.toString(),
    });

    dispatch({
      type: "SET_ITEM_CATEGORY",
      payload: {
        label: _.get(
          _.find(categoryTypes, ["value", item.category_id]),
          "label"
        ),
        value: item.category_id,
      },
    });

    let purchasePriceValue = Number(item.purchase_price) / 100;

    if (isNaN(purchasePriceValue)) {
      purchasePriceValue = 0;
    }

    setPurchasePrice(purchasePriceValue.toString());
    setItemCatalogId(item.catalog_id);
    setSelectedSupplier({
      label: _.get(_.find(suppliers, ["reference_id", item.vendor_id]), "name"),
      value: item.vendor_id,
      reference_id: item.vendor_id,
    });
    setItemRefernceId(item.reference_id);
    setEditedItemId(item.id);
  };

  const displaySearchedItems = (items) => {
    if (!_.isArray(items)) {
      items = [items];
    }
    setSearchItems(items);
  };

  function getCurrentTabText() {
    let myTab1 = globalStateStore.getKey("MY_TAB");
    return myTab1;
  }
  function createCategories() {
    console.log("Called");
    let array = [];
    _.map(catalog?.categories, (item) => {
      let obj = {};
      obj.label = item?.name;
      obj.value = item?.id;
      array.push(obj);
    });

    if (true) {
      if (merchantId == "65cb12bf179fb0028f46c6ab") {
        array = [{}];
      }
      array = [
        {
          label: "Clinical & Devices",
          value: "6583d9ad179fb0839e96f04c",
        },
        {
          label: "Baby & Child",
          value: "6583da09179fb0839e96f04f",
        },
        {
          label: "Health & Wellness",
          value: "6583d996179fb0839e96f04b",
        },
        {
          label: "Rx",
          value: "6583d9c0179fb0839e96f04d",
        },
        {
          label: "Pharma",
          value: "6583d988179fb0839e96f04a",
        },
        {
          label: "Beauty, Skincare & Personal Hygiene",
          value: "6583d9e9179fb0839e96f04e",
        },
        {
          label: "Herbals & Nutraceuticals",
          value: "6583d9fa179fb083a696f012",
        },
        {
          label: "Clinical & Surgicals",
          value: "6583da33179fb0839e96f051",
        },
        {
          label: "Sexual Wellness",
          value: "6583da1b179fb0839e96f050",
        },
        {
          label: "OTC",
          value: "6583d9d0179fb083a696f011",
        },
      ];
    }
    return array;
  }
  const getMasterMerchantId = function () {
    if (PrestoSdk.getHostName().indexOf("plus") >= 0) {
      return (
        user.other_data.permissions.base_merchant_id ||
        "65e52fc94d2d8dc92f6a7696"
      );
    } else {
      return (
        user.other_data.permissions.base_merchant_id ||
        "65d6e0c5179fb0cfa4eadafa"
      );
    }
  };
  const searchPage = useCallback(
    (pageNumber, success, failure) => {
      const params = {
        category_id: "",
        page: pageNumber,
        //catalog_id: catalog?.id,
        merchant_id:
          getCurrentTabText() == "Master Catalog"
            ? getMasterMerchantId()
            : catalog?.merchant_id,
        search_string: searchQuery,
        merchant_id:
          getCurrentTabText() == "Master Catalog"
            ? getMasterMerchantId()
            : user.merchant_id,
      };
      console.log(params);
      console.log("---SEARCH PARAMS---");
      if (getCurrentTabText() == "Master Catalog") {
        delete params.catalog_id;
      }
      sortDirection = globalStateStore.getKey("SORT_DIRECTION");
      let csortField = globalStateStore.getKey("SORT_FIELD");
      if (currentSortDirection == "DESC" || sortDirection == "DESC") {
        if (csortField == "Name") {
          params.name_desc = true;
        } else if (csortField == "Zip") {
          params.zip_desc = true;
        } else if (csortField == "Therapy") {
          params.therapy_desc = true;
        } else if (csortField == "Manufacturer") {
          params.company_name_desc = true;
        } else if (csortField == "Price") {
          params.price_high = true;
        } else if (csortField == "Expiry") {
          params.expiry_desc = true;
        } else if (csortField == "Avgpp") {
          params.avg_pp_desc = true;
        } else if (csortField == "Margin") {
          params.margin_desc = true;
        } else if (csortField == "LastAudit") {
          params.last_audit_desc = true;
        } else if (csortField == "" || csortField == "Stock") {
          console.log("came here");
          params.stock_count_desc = true;
        }
      } else {
        if (csortField == "Name") {
          params.name_asc = true;
        } else if (csortField == "Zip") {
          params.zip_asc = true;
        } else if (csortField == "Therapy") {
          params.therapy_asc = true;
        } else if (csortField == "Manufacturer") {
          params.company_name_asc = true;
        } else if (csortField == "Price") {
          params.price_low = true;
        } else if (csortField == "Expiry") {
          params.expiry_asc = true;
        } else if (csortField == "Avgpp") {
          params.avg_pp_asc = true;
        } else if (csortField == "Margin") {
          params.margin_asc = true;
        } else if (csortField == "LastAudit") {
          params.last_audit_asc = true;
        } else if (csortField == "" || csortField == "Stock") {
          params.stock_count_asc = true;
        }
      }
      // let expSoon = globalStateStore.getKey("EXPIRING_SOON");
      // if (expSoon) {
      //   params.show_near_expiry = true;
      //   globalStateStore.setKey("EXPIRING_SOON", undefined);
      // }
      if (isShowShortExpiry) {
        params.lastOrders = {
          lte: moment().add(6, "months").format("yyyy-MM-DD").toString(),
          gte: moment().format("yyyy-MM-DD").toString()
        }
      }
      console.log(params);
      if (getCurrentTabText() == "Quick Price") {
        params.size = 25;
      }
      console.log(params);
      console.log("---SEARCH--");

      const masterCatalogArray = (response, value) => {
        let array = _.map(response, (item) => item?._source);

        array.forEach((arrayItem) => {
          arrayItem.itemPurchaseConfiguration = {
            sale_unit_display_name: arrayItem.other_data["sale unit"],
            purchase_unit_display_name:
              arrayItem.other_data["purchase unit"],
            sale_to_purchase_ratio: parseInt(
              arrayItem.other_data["sale to purchase ratio"]
            ),
          };
          if (
            arrayItem.other_data.zip_supplied == "1" ||
            arrayItem.other_data.zip_supplied == 1
          ) {
            arrayItem.itemPurchaseConfiguration.supplier_prices = [
              {
                name: "Zip",
              },
            ];
          } else {
            arrayItem.itemPurchaseConfiguration.supplier_prices = [
              {
                name: "Others",
              },
            ];
          }
        });
        setTotalResultCount(
          value
        );
        return array
      }
      SearchManager.searchCategoryItem(
        params,
        (response) => {
          if (getCurrentTabText() == "Master Catalog") {
            let item_ids = [];
            response.hits.hits.forEach((item) => {
              item_ids.push(item._source.reference_id);
            });
            var nParams = {
              ref_ids: item_ids,
            };
            if (item_ids.length == 0) {
              success([]);
              return;
            }
            SearchManager.searchCategoryItem(
              nParams,
              (sResponse) => {
                if (sResponse.hits && sResponse.hits.hits) {
                  response.hits.hits.forEach((item) => {
                    item._source.is_available = false;
                    item.is_available = false;
                    sResponse.hits.hits.forEach((sItem) => {
                      if (
                        sItem._source.reference_id == item._source.reference_id
                      ) {
                        console.log("Found - " + sItem._source.name);
                        item.is_available = true;
                        item._source.is_available = true;
                      }
                    });
                  });
                }
                let array = masterCatalogArray(response.hits.hits, response?.hits?.total?.value || response?.hits?.total)
                console.log(array[0]);
                success(array);
              },
              (error) => {
                response.hits.hits.forEach((item) => {
                  item._source.is_available = false;
                  item.is_available = false;
                });

                let array = masterCatalogArray(response.hits.hits, response?.hits?.total?.value || response?.hits?.total)
                success(array)
              }
            );
          } else {
            try {
              setTotalResultCount(
                response?.hits?.total?.value == 0
                  ? 0
                  : response?.hits?.total?.value || response?.hits?.total
              );
            } catch (e) { }
            let array;
            if (response?.hits?.total?.value == 0) {
              array = [];
            } else {
              array = _.map(response.hits.hits, (item) => item?._source);
            }

            array.forEach((arrayItem) => {
              arrayItem.itemPurchaseConfiguration = {
                sale_unit_display_name: arrayItem.other_data["sale unit"],
                purchase_unit_display_name:
                  arrayItem.other_data["purchase unit"],
                sale_to_purchase_ratio: parseInt(
                  arrayItem.other_data["sale to purchase ratio"]
                ),
              };
              if (
                arrayItem.other_data.zip_supplied == "1" ||
                arrayItem.other_data.zip_supplied == 1
              ) {
                arrayItem.itemPurchaseConfiguration.supplier_prices = [
                  {
                    name: "Zip",
                  },
                ];
              } else {
                arrayItem.itemPurchaseConfiguration.supplier_prices = [
                  {
                    name: "Others",
                  },
                ];
              }
            });

            success(array);
          }
        },
        (error) => {
          failure([]);
        }
      );
    },
    [searchQuery, isShowShortExpiry]
  );
  const [
    results,
    loadNextPage,
    loadFirstPage,
    load,
    pageLoaded,
  ] = usePagination(searchPage);

  console.log(results, "kjdsfiojsdiofja")

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const getItemPurchaseConfiguration = (item, doNotShowAlert) => {
    return AsyncEmployeeManager.itemPurchaseConfigurationShow({
      id: item.id,
    }).then(([error, response]) => {
      if (error) {
        let message =
          error instanceof AxiosError
            ? _.get(error, "response.data.message")
            : error.message;
        if (!doNotShowAlert) {
          alertBox("", message);
        }
        return null;
      }
      return _.get(response, "data");
    });
  };

  const getItemsByLocalIds = (ids) => {
    let params = {
      merchant_id: merchantId,
      ids: ids,
      size: ids.length,
      enableOffline: !isWeb,
    };
    return CategoryDataSources.fuzzySearchCategoryItem(
      params,
      async (response) => {
        console.log("REEE");
        console.log(response);
        let items = _.get(response, "hits.hits", []);
        let itemPurchaseData = await AsyncStorage.getItem("ITEM_PURCHASE_LIST");
        if (!itemPurchaseData) {
          itemPurchaseData = "[]";
        }
        let itemPurchaseRequests = JSON.parse(itemPurchaseData);
        let tempItems = _.map(items, "_source");
        let prs = [];
        tempItems = _.map(tempItems, (tempItem) => {
          let itemPurchaseRequestItem = _.find(itemPurchaseRequests, [
            "category_item_id",
            tempItem.id,
          ]);
          let itemPurchaseConfiguration = {
            sale_unit_display_name: tempItem.other_data["sale unit"],
            purchase_unit_display_name: tempItem.other_data["purchase unit"],
            sale_to_purchase_ratio: parseInt(
              tempItem.other_data["sale to purchase ratio"]
            ),
          };
          if (
            tempItem.other_data.zip_supplied == "1" ||
            tempItem.other_data.zip_supplied == 1
          ) {
            itemPurchaseConfiguration.supplier_prices = [
              {
                name: "Zip",
              },
            ];
          } else {
            itemPurchaseConfiguration.supplier_prices = [
              {
                name: "Others",
              },
            ];
          }
          prs.push({
            itemPurchaseConfiguration: itemPurchaseConfiguration,
            itemPurchaseRequest: itemPurchaseRequestItem,
            product: tempItem,
          });
        });
        console.log(JSON.stringify(prs));
        console.log("----");
        setItemPurchaseRequests(prs);
        //setParentLoading(false);
      }
    );
  };

  const getItemsByIds = (ids) => {
    let params = {
      merchant_id: merchantId,
      ids: ids,
      size: ids.length,
    };
    return AsyncSearchManager.fuzzySearchCategoryItem(params).then(
      ([error, response]) => {
        let items = _.get(response, "hits.hits", []);
        return _.map(items, "_source");
      }
    );
  };

  const fetchPurchaseRequestItems = async () => {
    //setParentLoading(true);
    let itemPurchaseData = await AsyncStorage.getItem("ITEM_PURCHASE_LIST");
    if (!itemPurchaseData) {
      itemPurchaseData = "[]";
    }
    let itemPurchaseRequests = JSON.parse(itemPurchaseData);
    let ids = _.map(itemPurchaseRequests, "category_item_id");
    let tempItems = ids.length ? await getItemsByLocalIds(ids) : [];
    if (ids.length == 0) {
      setItemPurchaseRequests(tempItems);
    }
  };

  const fetchPurchaseRequestItemsNetwork = () => {
    //setParentLoading(true);
    console.log("I am refetching");
    return AsyncEmployeeManager.itemPurchaseRequests({})
      .then(([error, response]) => {
        return _.get(response, "data", []);
      })
      .then(async (itemPurchaseRequests) => {
        if (itemPurchaseRequests) {
          let ids = _.map(itemPurchaseRequests, "category_item_id");
          let tempItems = ids.length ? await getItemsByIds(ids) : [];

          if (tempItems) {
            tempItems = _.map(tempItems, (tempItem) => {
              let itemPurchaseRequestItem = _.find(itemPurchaseRequests, [
                "category_item_id",
                tempItem.id,
              ]);
              return {
                itemPurchaseRequest: itemPurchaseRequestItem,
                product: tempItem,
              };
            });
          }

          //setItemPurchaseRequests(tempItems);

          //setParentLoading(false);

          let completed = 0;
          if (completed == tempItems.length) {
            console.log("Setting latest ones");
            setItemPurchaseRequests(tempItems);
          }
          tempItems.forEach((tempItem) => {
            getItemPurchaseConfiguration(tempItem.product, true).then(
              (itemPurchaseConfiguration) => {
                completed = completed + 1;
                tempItem.itemPurchaseConfiguration = itemPurchaseConfiguration;
                if (completed == tempItems.length) {
                  console.log("Setting latest ones");
                  setItemPurchaseRequests(tempItems);
                }
              }
            );
          });
        }
      });
  };

  function PurchaseOrderObject() {
    const Manager = PurchaseOrdersManager;
    const AsyncManager = AsyncPurchaseOrdersManager;

    this.list = function (query) {
      const params = {
        purchase_orders: query,
      };
      return AsyncManager.list(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }

          const list = response.data.purchase_orders;
          return list;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->list->error", error);
          alertBox(error.message);
        });
    };

    this.listAwaitingApproval = () => {
      return this.list({ status: "AWAITING_APPROVAL" });
    };

    this.listApproved = () => {
      console.log("Listing Approved now");
      return this.list({ status: "APPROVED" }).then((rows) => {
        const draftPo = _.filter(rows, ["type", "DRAFT"]);
        let confirmedPo = _.filter(rows, ["type", "CONFIRMED"]);
        confirmedPo = _.map(confirmedPo, (item) => {
          let parentPo = _.find(draftPo, ["id", item.main_order_id]);
          return {
            ...item,
            ordered_by_hash: parentPo?.ordered_by_hash ?? { name: "" },
          };
        });
        console.log("Confirmed -- ");
        console.log(confirmedPo);
        return confirmedPo;
      });
    };

    this.listDelivered = () => {
      return this.list({ status: "DELIVERED" });
    };

    this.listClosed = () => {
      return this.list({ status: "CLOSED" });
    };

    this.listRejected = () => {
      return this.list({ status: "REJECTED" });
    };

    this.markAsApproved = (po) => {
      const params = {
        id: po.id,
      };
      return AsyncManager.approve(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const data = {
            success: response.data.success,
          };
          return data;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->markAsApproved->error", error);
          alertBox(error.message);
          return error;
        });
    };

    this.markAsRejected = (po, rejectionReason) => {
      const params = {
        id: po.id,
        purchase_order: {
          reject_reason: rejectionReason,
        },
      };
      return AsyncManager.reject(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const data = {
            success: response.data.success,
          };

          return data;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->markAsRejected->error", error);
          alertBox(error.message);
          return error;
        });
    };

    this.updateItemDeliveredQty = (po, item, qty) => {
      const params = {
        id: po.id,
        purchase_order: {
          line_item_id: item.id,
          delivered_quantity: qty,
          batch_number: item.batch_number,
          expiry_date: item.expiry_date,
        },
      };
      return AsyncManager.updateDeliveredQuantity(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const data = response.data;
          return data;
        })
        .catch((error) => {
          console.log(
            "PurchaseOrderObject->updateItemDeliveredQty->error",
            error
          );
          alertBox(error.message);
          return error;
        });
    };

    this.markAsDelivered = (po) => {
      const params = {
        id: po.id,
      };
      return AsyncManager.markAsDelivered(params)
        .then(([error, response]) => {
          console.log("Done Marking Delivered");

          if (error) {
            throw error;
          }
          const data = response.data;
          return data;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->markAsDelivered->error", error);
          //alertBox(error.message);
          return error;
        });
    };

    this.markAsClosed = (po) => {
      const params = {
        id: po.id,
      };
      return AsyncManager.close(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const data = response.data;
          return data;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->markAsClosed->error", error);
          alertBox(error.message);
          return error;
        });
    };

    this.getSupplierOrder = (po) => {
      const params = { id: po.id };
      return AsyncManager.order(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const data = response.data;
          return data;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->getSupplierOrder->error", error);
          return null;
        });
    };

    this.createDraftPo = () => {
      const params = {};
      return AsyncManager.createDraftPo(params)
        .then(([error, response]) => {
          if (error) {
            throw error;
          }
          const data = response.data;
          return data;
        })
        .catch((error) => {
          console.log("PurchaseOrderObject->getSupplierOrder->error", error);
          return error;
        });
    };

    this.approveItemPurchases = (items) => {
      let promises = _.map(items, (item) => {
        let { itemPurchaseRequest, supplierPrice } = item;
        console.log(
          itemPurchaseRequest,
          "fksladjkfjdksjfkjd^^^^^^^^^^^^^^^^^^^^^^^^^^^^"
        );
        let params = {
          approved_item_purchase: {
            quantity: itemPurchaseRequest.purchase_quantity,
            item_purchase_request_id: itemPurchaseRequest.id,
            draft_purchase_order_id: extraOptions.current.draftPo.id,
            supplier_id: supplierPrice.supplier_id,
          },
        };

        return AsyncEmployeeManager.approveItemPurchaseRequest(params).then(
          ([error, response]) => {
            if (error) {
              if (error instanceof AxiosError) {
                error = _.get(error, "response.data");
              }
              return error;
            }

            return response.data;
          }
        );
      });

      return Promise.all(promises);
    };
  }

  const purchaseOrderObject = new PurchaseOrderObject();
  console.log(JSON.stringify(user), "lksdfjaijfiojsilfjdilj")
  const loadPurchaseOrders = () => {
    let promiseItem = purchaseOrderObject.listApproved();
    console.log("-- Loading POs");
    setLoading(true);
    if (promiseItem) {
      console.log("Fetching POS again ");
      promiseItem
        .then((rows) => {
          console.log("Got final ones");
          console.log(rows);
          setLoading(false);
          //setPurchaseOrders(rows);
          let count = 0;
          let completedCount = 0;
          rows.forEach((pOrder) => {
            count += pOrder.items.length;
          });

          if (count == 0) {
            setPurchaseOrders(rows);
          }
          let promises = _.map(rows, (pOrder) => {
            return purchaseOrderObject.getSupplierOrder(pOrder);
          });
          const AsyncManager = AsyncPurchaseOrdersManager;
          Promise.all(promises)
            .then((responses) => {
              console.log("RESPPPP");
              console.log(responses);

              rows.forEach((pOrder) => {
                responses.forEach((resp) => {
                  if (resp && pOrder?.id == resp?.purchase_order_id) {
                    console.log("Matched -- ");
                    console.log(resp);
                    pOrder.zipOrder = resp;
                  }
                });
                _.map(pOrder?.zipOrder?.items, (ele, ind) => {
                  if (ele.inventory_batches_info?.length) {
                    const params = {
                      ids: ele.inventory_batches_info.map((ids) => ids.id).join(","),
                      inventory_batches_info: ele.inventory_batches_info,
                      merchant_id: user.other_data.permissions.order_merchant_id
                    }
                    AsyncManager.getBatchAndExpiry(params, (response) => {
                      if (response.data[ele.item_id]?.length > 1) {
                        response.data[ele.item_id].forEach((batch) => {
                          pOrder.items.push({
                            isOfBatch: true,
                            ...pOrder.items[ind],
                            batch_number: batch.batch_number,
                            expiry_date: batch.expiry_date,
                            purchase_quantity: batch.initial_quantity,
                            purchase_price_value: batch.purchase_price - pOrder.items[ind].total_tax,
                            purchase_tax_value: pOrder.items[ind].total_tax
                          });
                          pOrder.items = pOrder.items.filter((batchItems) => batchItems.isOfBatch)
                        });
                        console.log(JSON.stringify(pOrder), "ldsajfiojsdoijfijosd")
                      }
                      else {
                        pOrder.items[ind].batch_number = response.data[ele.item_id][0].batch_number;
                        pOrder.items[ind].expiry_date = response.data[ele.item_id][0].expiry_date
                      }
                    }, (error) => console.log(error, "errldsjlakjjsifjslf"));
                  }
                })

                pOrder.items.forEach((item) => {
                  const obj = new DBCategoryItemManager();
                  let newParams = {
                    id: item.item_id,
                  };
                  obj.filter(newParams).then((items) => {
                    completedCount += 1;
                    const response = obj.mockAsSearchServerAPI(items);

                    item.purchase_price_value = item.total_price / 100;
                    let tax = 0;
                    pOrder?.zipOrder?.items?.forEach((item1) => {
                      if (item1.reference_id == item.reference_id) {
                        tax = item1.total_tax;
                        item.purchase_price_value = item1.total_amount;
                        item.purchase_quantity = parseInt(
                          item1.delivered_quantity
                        );
                        if (pOrder.zipOrder.status == "REJECTED") {
                          item.purchase_quantity = 0;
                        }
                      }
                    });
                    item.purchase_tax_value = tax;
                    //item.purchase_quantity = parseInt(item.delivered_quantity);
                    //alert(item.purchase_price_value);
                    item.sourceItem = response?.data?.hits?.hits[0]?._source;
                    if (completedCount >= count) {
                      setPurchaseOrders(rows);
                    }
                  });
                });
              });
            })
            .catch((error) => { });
        })
        .catch((error) => {
          setLoading(false);
          console.log("PurchaseOrders->reloadLeftSideSection->error", error);
        });
    } else {
      setLoading(false);
    }
  };
  const onPressTab = (tab) => {
    //setMyTab(tab?.text);

    let tempTabs = [];
    setTabs((prev) => {
      tempTabs = _.cloneDeep(prev);
      return prev;
    });
    searchQuery = "";
    onSearchQueryChange("");
    tempTabs = _.map(tempTabs, (tabItem) => {
      let ifMainAndSelectedTab = _.includes(["stocks", tab.id], tabItem.id);
      let ifOnlyMainTabThanFallback =
        tab.id === "sales" && tabItem.id === "today";
      let isActive = ifMainAndSelectedTab || ifOnlyMainTabThanFallback;
      return {
        ...tabItem,
        isActive,
      };
    });

    setTabs(tempTabs);
    try {
      globalStateStore.setKey("SORT_DIRECTION", "asc");
      globalStateStore.setKey("SORT_FIELD", "name");
    } catch (e) { }

    globalStateStore.setKey("MY_TAB", tab?.value);
    if (tab?.value === "Add Custom") {
      setSelectedItem(null);
      onSearchQueryChange("");
      navigationRef.navigate("ItemDetailScreen", {
        selectedItem: null,
      });
    } else if (tab?.value === "Non Zip Purchases") {
      setSelectedItem(null);
      onSearchQueryChange("");
      navigationRef.navigate("ItemDetailScreen", {
        selectedItem: null,
      });
      //alert(isIncoming);
      fetchPurchaseRequestItems();
    } else if (tab?.id === "zip_open_orders") {
      setSelectedItem(null);
      onSearchQueryChange("");
      navigationRef.navigate("InitialPurchaseRequestDetail", {
        initial: null,
      });
      loadPurchaseOrders();
    } else {
      navigationRef.navigate("ItemDetailScreen", {
        selectedItem: null,
      });
      loadFirstPage();
    }
    dispatch({
      type: "SET_CURRENT_TAB",
      payload: tab?.value,
    });
  };

  console.log("sdsfsfc", state.currentTab);

  const onSearchQueryChange = (text) => {
    dispatch({
      type: "SET_SEARCH_QUERY",
      payload: text,
    });
  };

  const onNameChange = ({ nativeEvent: { text } }) => {
    dispatch({
      type: "SET_ITEM_NAME",
      payload: text,
    });
  };

  const onDescChange = (text) => {
    dispatch({
      type: "SET_ITEM_DESCRIPTION",
      payload: text,
    });
  };

  const onListingChange = ({ nativeEvent: { text } }) => {
    dispatch({
      type: "SET_ITEM_LISTING_ORDER",
      payload: text,
    });
  };

  const onPriceChange = ({ nativeEvent: { text } }) => {
    dispatch({
      type: "SET_ITEM_PRICE",
      payload: text,
    });
  };

  const onStockChange = ({ nativeEvent: { text } }) => {
    dispatch({
      type: "SET_ITEM_STOCK",
      payload: text,
    });
  };

  const onSearchItems = (items) => {
    setSearchItems(items);
  };

  const validateOnClickUpdate = () => {
    let isValid = true,
      error = null;

    if (itemQty <= 0) {
      error = I18n.t("error_messages.stocks.invalid_qty");
    }

    if (error) {
      isValid = false;
    }

    return { isValid, error };
  };

  const validateOnInventoryBatchCreate = () => {
    let isValid = true,
      error = null;

    if (itemQty <= 0) {
      error = I18n.t("error_messages.stocks.invalid_qty");
    }

    /*if (_.isEmpty(stockForm.batchNumber)) {
      error = I18n.t("error_messages.products.please_enter_batch_number");
    }*/

    if (_.isEmpty(stockForm.expiry)) {
      error = I18n.t("error_messages.products.please_enter_expiry_date");
    } else {
      const now = moment();
      const expiryDate = moment(stockForm.expiry);
      if (expiryDate < now) {
        error = I18n.t(
          "error_messages.products.expiry_date_should_be_future_date"
        );
      }
    }
    if (error) {
      isValid = false;
    }
    return { isValid, error };
  };

  const addToStoreAndCreateInventory = () => {
    setAddInventoryModalVisible(false);
    setLoading(true);
    var params = {
      merchant_id: user.merchant_id,
      filter: "products",
    };
    SearchManager.searchCatalog(
      params,
      (response) => {
        console.log("Search Catalog response");
        console.log(response);
        let catalogId = response.hits.hits[0]._source.id;

        var item = selectedItem;
        item.other_data.nearest_expiry = moment(stockForm.expiry).format(
          "YYYY-MM-DD"
        );
        item.other_data.last_purchase_price = stockForm.purchasePrice;
        item.other_data.avg_purchase_price = stockForm.purchasePrice;
        console.log("ITEM create");
        if (getCurrentTabText() == "Master Catalog") {
          let params = {
            //body: {
            category_item: {
              ...item,
              is_available: true,
              stock_count: stockForm.stockCount,
              category_id: "",
              catalog_id: catalogId,
              other_data: JSON.stringify(item.other_data),
            },
            price: {
              ...item?.price,
              base_price: (stockForm.salePrice * 100).toString(),
            },
            //},
          };
          delete params.category_item.id;
          console.log("Item Create params");
          console.log(params);

          EmployeeManager.createCategoryItem(
            params,
            async (res) => {
              console.log("Rsult");
              console.log(res);
              dispatch({
                type: "RESET",
                payload: {
                  selectedItem: null,
                  selectedVariation: null,
                },
              });
              setItemQuantity(parseInt(stockForm.stockCount));

              let params = {
                purchase_configuration: {
                  sale_unit_display_name: item.other_data["sale unit"],
                  purchase_unit_display_name: item.other_data["purchase unit"],
                  sale_to_purchase_ratio:
                    item.other_data["sale to purchase ratio"],
                  category_item_id: res.data.id,
                },
              };

              console.log("Master data");
              console.log(params);
              let method = null;
              method = AsyncEmployeeManager.purchaseConfigurationCreate;
              const [error1, response] = await method(params);
              let sparams = {
                supplier_price: {
                  purchase_price: 0,
                  purchase_configuration_id: response.data.id,
                  supplier_id: suppliers[0].id,
                },
              };
              let smethod = AsyncEmployeeManager.supplierPricesCreate;
              const [serror1, sresponse] = await smethod(sparams);
              setLoading(false);

              setTimeout(function () {
                const offlineSync = new OfflineSync(user);
                const force = true;
                offlineSync.syncCatalog(force);
                console.log("Sync in progress");
              }, 20000);
              //inventoryBatchCreate();
              alertBox("", I18n.t("screen_messages.products.desc1"));

              setSelectedItem(null);
              setLoading(false);
              loadFirstPage();
            },
            (err) => {
              console.log("Error");
              console.log(err);
              setLoading(false);
              alert(I18n.t("screen_messages.try_again_later"));
            }
          );
        } else {
          setSelectedVariation(null);
          if (item) {
            setSelectedItem(item);
          }
          //setModalVisible(true);
        }
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const inventoryBatchCreate = () => {
    const { isValid, error } = validateOnInventoryBatchCreate();
    if (!isValid) {
      return alertBox("", error);
    }
    const params = {
      inventory_batch: {
        item_id: selectedItem.id,
        batch_number: stockForm.batchNumber,
        expiry_date: stockForm.expiry,
        initial_quantity: itemQty,
        remaining_quantity: itemQty,
      },
    };
    AsyncEmployeeManager.inventoryBatchCreate(params)
      .then(([error, response]) => {
        if (error) {
          throw error;
        }
        setLoading(false);
        dispatch({
          type: "RESET",
          payload: {
            selectedItem: null,
            selectedVariation: null,
          },
        });
        setStockForm({
          batchNumber: "",
          purchasePrice: "",
          expiry: null,
        });
        setItemPurchaseConfiguration(null);
        setModalVisible(false);
        setItemQuantity(0);
        setLoading(false);
        loadFirstPage();
        alertBox("", I18n.t("success_messages.inventory_batch.created"));
      })
      .catch((error) => {
        console.log("AllStock->inventoryBatchCreate->error", error);
        setLoading(false);
        alertBox("", error.message);
      });
  };

  const onClickUpdate = () => {
    const { isValid, error } = validateOnClickUpdate();

    if (!isValid) {
      return alertBox("", error);
    }

    setModalVisible(false);
    setLoading(true);

    let extraItemCount = Number(itemQty);
    let params;

    if (isNaN(extraItemCount)) {
      extraItemCount = 0;
    }

    if (selectedVariation) {
      let filtered = _.filter(
        selectedItem?.variations,
        (item) => item?.id !== selectedVariation.id
      );

      let stockCount = Number(selectedVariation?.stock_count);
      if (isNaN(stockCount)) {
        stockCount = 0;
      }
      stockCount = stockCount + extraItemCount;

      params = {
        id: selectedItem?.id,
        body: {
          category_item: {
            ...selectedItem,
            category_id: selectedItem?.category_id,
            catalog_id: selectedItem?.catalog_id,
            sold_out: false,
            variations: [
              ...filtered,
              {
                ...selectedVariation,
                stock_count: stockCount,
                price: {
                  ...selectedVariation?.price,
                  base_price: (
                    selectedVariation?.price?.base_price * 100
                  ).toString(),
                },
              },
            ],
          },
          price: {
            ...selectedItem?.price,
            base_price: (selectedItem?.price?.base_price * 100).toString(),
          },
        },
      };
    } else {
      let stockCount = Number(selectedItem?.stock_count);
      if (isNaN(stockCount)) {
        stockCount = 0;
      }
      stockCount = stockCount + extraItemCount;

      params = {
        id: selectedItem?.id,
        body: {
          category_item: {
            ...selectedItem,
            category_id: selectedItem?.category_id,
            catalog_id: selectedItem?.catalog_id,
            stock_count: stockCount,
            sold_out: false,
          },
          price: {
            ...selectedItem?.price,
            base_price: (selectedItem?.price?.base_price * 100).toString(),
          },
        },
      };
    }
    EmployeeManager.updateCategoryItem(
      params,
      (res) => {
        dispatch({
          type: "RESET",
          payload: {
            selectedItem: null,
            selectedVariation: null,
          },
        });
        setItemQuantity(0);
        setLoading(false);
        loadFirstPage();

        alertBox("", I18n.t("screen_messages.products.desc1"));
      },
      (err) => {
        setLoading(false);
        alert(I18n.t("screen_messages.try_again_later"));
      }
    );
  };

  const validateSubmittedData = () => {
    if (!itemName) {
      alert(I18n.t("screen_messages.stock.please_enter_name"));
    } else if (!itemPrice) {
      alert(I18n.t("screen_messages.stock.please_enter_price"));
    } else if (!purchasePrice) {
      alert(I18n.t("screen_messages.inventory.purchase_price"));
    } else if (!itemListingOrder) {
      alert(I18n.t("screen_messages.stock.please_enter_listing"));
    } else if (!itemStock) {
      alert(I18n.t("screen_messages.stock.please_enter_stock"));
    } else if (!itemCategory) {
      alert(I18n.t("screen_messages.stock.please_select_category"));
    } else if (!itemDesc) {
      alert(I18n.t("screen_messages.stock.please_enter_description"));
    } else if (!selectedSupplier) {
      alert("Please select vendor");
    } else {
      onClickCreate();
    }
  };

  const onClickCreate = () => {
    setLoading(true);
    let price = (parseFloat(itemPrice) * 100).toString();
    let params1 = {
      category_item: {
        name: itemName,
        description: itemDesc,
        listing_order: parseInt(itemListingOrder),
        category_id: itemCategory?.value,
        catalog_id: itemCatalogId || catalog?.id,
        stock_count: itemStock,
        vendor_id: selectedSupplier.reference_id,
        reference_id: itemRefernceId,
        is_available: true,
      },
      price: {
        base_price: price,
      },
    };
    let params = {},
      method = null;

    if (editedItemId) {
      method = EmployeeManager.updateCategoryItem;
      params.id = editedItemId;
      params.body = params1;
    } else {
      method = EmployeeManager.createCategoryItem;
      params = params1;
    }

    method(
      params,
      (res) => {
        dispatch({
          type: "RESET",
          payload: {
            itemName: "",
            itemDesc: "",
            itemPrice: "",
            itemListingOrder: 0,
            itemCategory: "",
            itemStock: "",
          },
        });

        setItemCatalogId(null);
        setPurchasePrice("");
        setEditedItemId(null);
        setSelectedSupplier(null);
        setItemRefernceId(null);
        setLoading(false);
        alertBox("", I18n.t("screen_messages.products.desc1"));
        setItemPurchasePrice(res.data);
      },
      (err) => {
        setLoading(false);
        alert(I18n.t("screen_messages.try_again_later"));
      }
    );
  };

  const setItemPurchasePrice = (item) => {
    const onSuccess = (response) => {
      setLoading(false);
    };

    const onError = (error) => {
      setLoading(false);
      console.log("AllStock->setItemPurchasePrice->error", error);
    };

    setLoading(true);
    let price = (parseFloat(purchasePrice) * 100).toString();
    let params = {
      id: item.id,
      purchase_price: price,
    };
    EmployeeManager.setItemPurchasePrice(params, onSuccess, onError);
  };

  const onPressVariation = (item) => {
    setSelectedVariation(item);
  };

  const onPressHome = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "FEATURE_SELECTION",
      },
    });
  };

  const onSelectCategory = (val) => {
    dispatch({
      type: "SET_ITEM_CATEGORY",
      payload: val,
    });
  };

  const showAddToStore = (item) => {
    setAddInventoryModalVisible(true);
    setSelectedItem(item);
  };
  const onClickAddToStore = (item) => {
    showAddToStore(item);
    return;
    if (getCurrentTabText() == "Master Catalog") {
      let params = {
        id: item?.id,
        body: {
          category_item: {
            ...item,
            is_available: true,
          },
          price: {
            ...item?.price,
            base_price: (item?.price?.base_price * 100).toString(),
          },
        },
      };
      EmployeeManager.updateCategoryItem(
        params,
        (res) => {
          dispatch({
            type: "RESET",
            payload: {
              selectedItem: null,
              selectedVariation: null,
            },
          });
          setItemQuantity(0);
          setLoading(false);
          loadFirstPage();
          setTimeout(function () {
            const offlineSync = new OfflineSync(user);
            const force = true;
            offlineSync.syncCatalog(force);
            console.log("Sync in progress");
          }, 10000);
          alertBox("", I18n.t("screen_messages.products.desc1"));
        },
        (err) => {
          setLoading(false);
          alert(I18n.t("screen_messages.try_again_later"));
        }
      );
    } else {
      setSelectedVariation(null);
      if (item) {
        setSelectedItem(item);
      }
      setModalVisible(true);
    }
  };

  const onPressDelete = (item) => {
    setLoading(true);
    let params = { id: item?.id };
    EmployeeManager.deleteCategoryItem(
      params,
      (res) => {
        loadFirstPage();
        onSearchQueryChange("");
        setLoading(false);
        alert(I18n.t("screen_messages.item_deleted"));
        console.log("res :>> ", res);
      },
      (err) => {
        setLoading(false);
        alert(err?.message);
        console.log("err :>> ", err);
      }
    );
  };

  const showDeleteOptions = (item) => {
    showActionSheetWithOptions(
      {
        options: [
          I18n.t("screen_messages.delete_str"),
          I18n.t("screen_messages.cancel_appyapy_button"),
        ],
        cancelButtonIndex: 1,
      },
      (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            onPressDelete(item);
            break;
        }
      }
    );
  };

  const showAuditReasons = (item, callback) => {
    console.log(item);
    showActionSheetWithOptions(
      {
        options: [...reasons, I18n.t("screen_messages.common.cancel_text")],
        message: I18n.t("screen_messages.common.select_reason"),
        cancelButtonIndex: reasons.length,
      },
      (buttonIndex) => {
        let reason = _.get(reasons, buttonIndex, null);
        item.other_data.stock_audit_reason = reason;
        console.log(item);
        console.log("-----");
        if (reason) {
          callback(reason);
        }
      }
    );
  };

  const renderTextBox = ({
    label,
    placeholder,
    value,
    keyboardType = "default",
    setFunction,
    fieldName,
  }) => {
    return (
      <View>
        <PrestoText fontStyle={"400.semibold"} size={16}>
          {label}
        </PrestoText>
        <PrestoTextInput
          width={300}
          theme={theme}
          placeholder={placeholder}
          value={value}
          keyboardType={keyboardType}
          onChange={({ nativeEvent: { text } }) => setFunction(text)}
          backgroundColor={theme.white}
          borderWidth={2}
          onBlur={() => {
            setFocusedItem(null);
          }}
          onFocus={() => {
            setFocusedItem(fieldName);
          }}
          style={{
            borderWidth: 2,
            borderColor:
              focusedItem === fieldName ? theme.primary : theme.lightText,
            borderRadius: 6,
          }}
        />
      </View>
    );
  };

  const RenderItemHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          padding: 5,
          flexDirection: "row",
          backgroundColor: theme.screenBackgroundColor,
        }}
      >
        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "24%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Name");
            globalStateStore.setKey("SORT_FIELD", "Name");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <PrestoText
              extraStyle={{ paddingLeft: 20 }}
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.common.name_text")}
              {sortField == "Name"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "12%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={13}>
            {I18n.t("screen_messages.all_sales.last_ordered")}
          </PrestoText>
        </View>
        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "12%",
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Stock");
            globalStateStore.setKey("SORT_FIELD", "Stock");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoText
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.all_sales.in_stock")}
              {sortField == "Stock"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>
        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "15%",
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Expiry");
            globalStateStore.setKey("SORT_FIELD", "Expiry");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoText
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.inventory.nearest_expiry")}
              {sortField == "Expiry"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>
        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "15%",
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Avgpp");
            globalStateStore.setKey("SORT_FIELD", "Avgpp");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoText
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.inventory.average_cost")}
              {sortField == "Avgpp"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>
        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "15%",
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Price");
            globalStateStore.setKey("SORT_FIELD", "Price");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoText
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.inventory.current_sales_price")}
              {sortField == "Price"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>
      </View>
    );
  };

  const RenderMasterItemHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          padding: 5,
          flexDirection: "row",
          backgroundColor: theme.screenBackgroundColor,
        }}
      >
        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "24%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Name");
            globalStateStore.setKey("SORT_FIELD", "Name");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <PrestoText
              extraStyle={{ paddingLeft: 20 }}
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.common.name_text")}
              {sortField == "Name"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "12%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={13}>
            {I18n.t("screen_messages.inventory.category_str")}
          </PrestoText>
        </View>
        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "10%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Therapy");
            globalStateStore.setKey("SORT_FIELD", "Therapy");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoText
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.therapy_browse_label")}
              {sortField == "Therapy"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>
        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "12%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Manufacturer");
            globalStateStore.setKey("SORT_FIELD", "Manufacturer");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoText
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.inventory.manufacturer")}
              {sortField == "Manufacturer"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "12%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={13}>
            {I18n.t("screen_messages.orders.supply_util")}
          </PrestoText>
        </View>

        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "10%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={13}>
            {I18n.t("screen_messages.recommended_sales_unit")}
          </PrestoText>
        </View>
        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "10%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Zip");
            globalStateStore.setKey("SORT_FIELD", "Zip");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoText
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.orders.sold_by")}
              {sortField == "Zip"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>
      </View>
    );
  };

  const RenderQuickPriceHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          padding: 5,
          flexDirection: "row",
          backgroundColor: theme.screenBackgroundColor,
        }}
      >
        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "20%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Name");
            globalStateStore.setKey("SORT_FIELD", "Name");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <PrestoText
              extraStyle={{ paddingLeft: 20 }}
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.common.name_text")}
              {sortField == "Name"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>

        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "10%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Stock");
            globalStateStore.setKey("SORT_FIELD", "Stock");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoText
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.orders.in_stock")}
              {sortField == "Stock"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>
        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "10%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Expiry");
            globalStateStore.setKey("SORT_FIELD", "Expiry");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoText
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.inventory.nearest_expiry")}
              {sortField == "Expiry"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>

        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "10%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Avgpp");
            globalStateStore.setKey("SORT_FIELD", "Avgpp");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoText
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.inventory.average_cost")}
              {sortField == "Avgpp"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "10%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={13}>
            {I18n.t("screen_messages.inventory.most_recent_order_cost")}
          </PrestoText>
        </View>
        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "10%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Price");
            globalStateStore.setKey("SORT_FIELD", "Price");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoText
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.inventory.current_sales_price")}
              {sortField == "Price"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>
        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "10%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Margin");
            globalStateStore.setKey("SORT_FIELD", "Margin");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoText
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.inventory.current_margin")}
              {sortField == "Margin"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "10%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={13}>
            {I18n.t("screen_messages.inventory.new_sales_price")}
          </PrestoText>
        </View>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "10%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrestoText color={theme.paragraph} fontStyle={"400.bold"} size={13}>
            {I18n.t("screen_messages.inventory.new_margin")}
          </PrestoText>
        </View>
      </View>
    );
  };

  const RenderStockItemHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 60,
          padding: 5,
          flexDirection: "row",
          backgroundColor: theme.screenBackgroundColor,
        }}
      >
        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "24%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("Name");
            globalStateStore.setKey("SORT_FIELD", "Name");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <PrestoText
              extraStyle={{ paddingLeft: 20 }}
              color={theme.paragraph}
              fontStyle={"400.bold"}
              size={13}
            >
              {I18n.t("screen_messages.common.name_text")}
              {sortField == "Name"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>
        <Pressable
          style={{
            height: 50,
            flexDirection: "column",
            width: "11%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          onPress={() => {
            console.log("Got the request here = " + currentSortDirection);
            setSortField("LastAudit");
            globalStateStore.setKey("SORT_FIELD", "LastAudit");
            if (currentSortDirection == "ASC") {
              setCurrentSortDirection("DESC");
              globalStateStore.setKey("SORT_DIRECTION", "DESC");
            } else {
              setCurrentSortDirection("ASC");
              globalStateStore.setKey("SORT_DIRECTION", "ASC");
            }
            loadFirstPage();
          }}
        >
          <View
            style={{
              height: 50,
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoText color={theme.black} fontStyle={"400.bold"} size={10}>
              {I18n.t("screen_messages.inventory.last_audited")}
              {sortField == "LastAudit"
                ? currentSortDirection == "ASC"
                  ? " ^"
                  : " v"
                : ""}
            </PrestoText>
          </View>
        </Pressable>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "11%",
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 10,
          }}
        >
          <PrestoText color={theme.black} fontStyle={"400.bold"} size={10}>
            {I18n.t("screen_messages.inventory.expected")}
          </PrestoText>
        </View>
        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "15%",
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 10,
          }}
        >
          <PrestoText color={theme.black} fontStyle={"400.bold"} size={10}>
            {I18n.t("screen_messages.stock_audit.current_stock")}
          </PrestoText>
        </View>

        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "10%",
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 10,
          }}
        >
          <PrestoText color={theme.black} fontStyle={"400.bold"} size={10}>
            {I18n.t("screen_messages.stock_audit.diff")}
          </PrestoText>
        </View>

        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "16%",
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 10,
          }}
        >
          <PrestoText color={theme.black} fontStyle={"400.bold"} size={10}>
            {I18n.t("screen_messages.stock_audit.reason")}
          </PrestoText>
        </View>

        <View
          style={{
            height: 50,
            flexDirection: "column",
            width: "14%",
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 10,
          }}
        >
          <PrestoSolidButton
            size="small"
            buttonStyle="secondary"
            titleColor={theme.white}
            title={I18n.t("screen_messages.submit_audit")}
            padding="20"
            width={100}
            titleExtraStyle={{ textTransform: "capitalize" }}
            onPress={() => {
              updateStockAudit();
            }}
          />
        </View>
      </View>
    );
  };

  const updateStockAudit = function () {
    let count = selectedItemIds.length;
    let completedCount = 0;
    let error = false;
    _.map(selectedItems, (selectedItem) => {
      if (
        !selectedItem.current_stock_count &&
        selectedItem.current_stock_count != 0
      ) {
        error = true;
      }
    });
    if (error) {
      alert(
        I18n.t("screen_messages.please_enter_stock_count_of_selected_items")
      );
      return;
    }
    _.map(selectedItems, (selectedItem) => {
      var other_data = selectedItem.other_data;
      other_data.last_audited_at = moment().toISOString();
      other_data.stock_audit_reason =
        selectedItem.other_data.stock_audit_reason;

      let params = {
        id: selectedItem?.id,
        body: {
          category_item: {
            ...selectedItem,
            is_available: true,
            stock_count: selectedItem.current_stock_count,
            other_data: JSON.stringify(other_data),
          },
          price: {
            ...selectedItem?.price,
            base_price: (selectedItem?.price?.base_price * 100).toString(),
          },
        },
      };
      console.log("params -- ");
      console.log(JSON.stringify(params));

      EmployeeManager.updateCategoryItem(
        params,
        (res) => {
          setLoading(false);
          completedCount = completedCount + 1;
          if (completedCount >= count) {
            setSelectedItemIds([]);
            setSelectedItems([]);
            setSelectedItem(null);
            loadFirstPage();
            alert(I18n.t("screen_messages.selected_items_have_been_updated"));
            setTimeout(function () {
              const offlineSync = new OfflineSync(user);
              const force = true;
              offlineSync.syncCatalog(force);
              console.log("Sync in progress");
            }, 20000);
          }
        },
        (err) => {
          setLoading(false);
          alert(I18n.t("screen_messages.try_again_later"));
        }
      );
    });
  };
  const renderItem = ({ item, index }) => {
    let selected = selectedItem?.id === item?.id;
    return (
      <ItemCard
        item={item}
        selected={selected}
        onPress={() => setSelectedItem(item)}
        onPressButton={() => onClickAddToStore(item)}
        onLongPress={showDeleteOptions}
        tabText={getCurrentTabText()}
        forInventory={isMobile}
        zipImage={images.ZipIcon}
      />
    );
  };

  const renderQuickPriceItem = ({ item, index }) => {
    if (stockForm.finalMarkupPercentage) {
      item.markedUpPrice =
        item.price.base_price +
        (item.price.base_price * stockForm.finalMarkupPercentage) / 100.0;
      item.markedUpTax =
        item.price.tax +
        (item.price.tax * stockForm.finalMarkupPercentage) / 100.0;
    }
    if (stockForm.finalMarkupAmount) {
      item.markedUpPrice =
        item.price.base_price + parseFloat(stockForm.finalMarkupAmount);
      item.markedUpTax =
        item.price.tax +
        (item.price.tax_percentage / 100) *
        parseFloat(stockForm.finalMarkupAmount);
    }
    if (stockForm.finalMargin) {
      if (item.other_data.avg_purchase_price) {
        item.markedUpTax =
          item.price.tax +
          (item.price.tax_percentage / 100) * parseFloat(stockForm.finalMargin);
        item.markedUpPrice =
          parseFloat(item.other_data.avg_purchase_price) -
          item.markedUpTax +
          (parseFloat(item.other_data.avg_purchase_price) *
            stockForm.finalMargin) /
          100.0;
      }
    }
    let selected = selectedItemIds.indexOf(item.id) != -1;

    const onPressItem = () => {
      console.log("Adding to selected");
      if (selectedItemIds.indexOf(item.id) == -1) {
        let itemIds = selectedItemIds;
        let sItems = selectedItems;
        if (stockForm.finalMargin) {
          if (item.other_data.avg_purchase_price) {
            itemIds.push(item.id);
            sItems.push(item);
          } else {
            alert(I18n.t("screen_messages.you_can_select_item"));
          }
        } else {
          itemIds.push(item.id);
          sItems.push(item);
        }

        setSelectedItemIds(itemIds);
        setSelectedItems(sItems);
        setSelectedItem(item);
      } else {
        console.log("I am setting again and again");
        var itemIndex = selectedItemIds.indexOf(item.id);
        let itemIds = selectedItemIds;
        itemIds.splice(itemIndex, 1);
        let sItems = selectedItems;
        sItems.splice(itemIndex, 1);
        setSelectedItemIds(itemIds);
        setSelectedItems(sItems);
        setSelectedItem(null);
        //setSelectedItem(item);
      }
    };

    if (isMobile) {
      return (
        <ItemCard
          item={item}
          selected={selected}
          onPress={() => onPressItem(item)}
          onPressButton={() => onClickAddToStore(item)}
          tabText={getCurrentTabText()}
          onLongPress={showDeleteOptions}
          forMasterCatalog={true}
          forQuickPrice={true}
          zipImage={images.ZipIcon}
          nearestExpiryFormat={"MM-YY"}
        />
      );
    }

    return (
      <QPItemCard
        item={item}
        selected={selected}
        onPress={() => onPressItem(item)}
        onPressButton={() => onClickAddToStore(item)}
        onLongPress={showDeleteOptions}
        tabText={getCurrentTabText()}
      />
    );
  };
  const renderMasterItem = ({ item, index }) => {
    let selected = selectedItem?.id === item?.id;
    return (
      <ItemCard
        item={item}
        selected={selected}
        onPress={() => setSelectedItem(item)}
        onPressButton={() => onClickAddToStore(item)}
        onLongPress={showDeleteOptions}
        tabText={getCurrentTabText()}
        forMasterCatalog={isMobile}
        zipImage={images.ZipIcon}
      />
    );
  };

  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };

  const navigateToSort = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "SORT_OPTIONS",
        screenParams: {
          selectedSort: currentSortDirection,
          selectedValue: sortField,
          onSelectSortOptions,
          goBack,
          currentTab: currentTab,
        },
      },
    });
  };

  const onSelectSortOptions = (value, direction) => {
    setSortField(value);
    setCurrentSortDirection(direction);
    globalStateStore.setKey("SORT_FIELD", value);
    globalStateStore.setKey("SORT_DIRECTION", direction);
    setTimeout(() => {
      loadFirstPage();
    }, 200);
  };

  const renderStockItem = ({ item, index }) => {
    let selected = selectedItem?.id === item?.id;
    let isActive = selectedItemIds.indexOf(item.id) >= 0 ? true : false;
    let auditDate = item?.other_data.last_audited_at
      ? moment(item?.other_data.last_audited_at).format("DD-MM-YYYY")
      : "";
    let diff = item.current_stock_count
      ? item?.current_stock_count - item.stock_count
      : "";
    let reason = item.other_data.stock_audit_reason
      ? item.other_data.stock_audit_reason
      : I18n.t("screen_messages.common.choose");

    const onPressStockItem = (item) => {
      let array = [
        {
          value: item.current_stock_count?.toString(),
          function: (qty) => {
            onAmountChange(qty);
          },
          title: I18n.t("screen_messages.stock_audit.current_stock"),
        },
        {
          value: diff.toString() || "0",
          function: () => { },
          title: I18n.t("screen_messages.stock_audit.diff"),
          editable: false,
        },
      ];
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "NON_ZIP_PURCHASES",
          screenParams: {
            goBack: goBack,
            item: item,
            incomingArray: array,
            reason: reason,
            reasons: reasons,
            showDropDown: true,
            onPressDropDown: onPressDropDown,
            buttonTitle: I18n.t("screen_messages.submit_audit"),
            onPressButton: () => {
              goBack();
              updateStockAudit();
            },
          },
        },
      });
    };

    const onAmountChange = (quantity) => {
      item.current_stock_count = quantity;
      setItemsAndIds();
    };

    const setItemsAndIds = (reason) => {
      if (reason) {
        item.other_data.stock_audit_reason = reason;
        item.is_selected = true;
      }
      if (selectedItemIds.indexOf(item.id) == -1) {
        let itemIds = selectedItemIds;
        itemIds.push(item.id);
        let sItems = selectedItems;
        sItems.push(item);
        setSelectedItemIds(itemIds);
        setSelectedItems(sItems);
        setSelectedItem(item);
      } else {
        console.log("I am setting again and again");
        var itemIndex = selectedItemIds.indexOf(item.id);
        let itemIds = selectedItemIds;
        itemIds.splice(itemIndex, 1);
        let sItems = selectedItems;
        sItems.splice(itemIndex, 1);
        setSelectedItemIds(itemIds);
        setSelectedItems(sItems);
        setSelectedItem(null);
        setTimeout(function () {
          itemIds.push(item.id);
          sItems.push(item);
          setSelectedItemIds(itemIds);
          setSelectedItems(sItems);
          setSelectedItem(item);
        }, 10);
        //setSelectedItem(item);
      }
    };

    const onPressDropDown = (propsReason) => {
      if (isMobile) {
        item.other_data.stock_audit_reason = propsReason;
        setItemsAndIds(propsReason);
      } else {
        showAuditReasons(item, (reason) => {
          setItemsAndIds(reason);
        });
      }
    };

    if (isMobile) {
      return (
        <ItemCard
          item={item}
          selected={selected}
          onPress={() => {
            setSelectedItem(item);
            onPressStockItem(item);
          }}
          onPressButton={() => { }}
          onLongPress={showDeleteOptions}
          tabText={getCurrentTabText()}
          forMasterCatalog={isMobile}
          forStockAudit={isMobile}
          zipImage={images.ZipIcon}
          auditDate={auditDate}
        />
      );
    }
    return (
      <Pressable
        onLongPress={() => { }}
        onPress={() => setSelectedItem && setSelectedItem(item)}
        style={{
          width: "100%",
          // height: 60,
          backgroundColor: selected
            ? theme.topHeaderInactiveColor
            : isActive
              ? theme.primary
              : theme.white,
          borderRadius: 15,
          padding: 5,
          flexDirection: "row",
          marginBottom: 5,
        }}
      >
        <View
          style={{
            // height: 50,
            flexDirection: "column",
            width: "24%",
            justifyContent: "center",
            alignItems: "flex-start",
            borderRightColor: theme.lightText,
            borderRightWidth: 1,
          }}
        >
          <PrestoText
            size={12}
            numberOfLines={2}
            extraStyle={{ paddingLeft: 10 }}
            color={selected || isActive ? theme.white : theme.dark}
            fontStyle={"400.bold"}
          >
            {item?.name}
          </PrestoText>
          {item?.other_data?.molecule ? (
            <PrestoText
              extraStyle={{ paddingLeft: 10 }}
              size={12}
              color={selected || isActive ? theme.white : theme.dark}
            >
              {item.other_data.molecule}
            </PrestoText>
          ) : null}
        </View>
        <View
          style={{
            // height: 50,
            flexDirection: "column",
            width: "12%",
            justifyContent: "center",
            alignItems: "center",
            borderRightColor: theme.lightText,
            borderRightWidth: 1,
          }}
        >
          <PrestoText
            size={12}
            color={selected || isActive ? theme.white : theme.dark}
          >
            {auditDate}
          </PrestoText>
        </View>
        <View
          style={{
            // height: 50,
            flexDirection: "column",
            width: "9%",
            justifyContent: "center",
            alignItems: "center",
            borderRightColor: theme.lightText,
            borderRightWidth: 1,
            paddingHorizontal: 10,
          }}
        >
          <PrestoText
            fontStyle={"400.semibold"}
            size={12}
            color={selected || isActive ? theme.white : theme.dark}
            extraStyle={{ textAlign: "center" }}
          >
            {item?.stock_count}
          </PrestoText>
          <PrestoText
            extraStyle={{ textAlign: "center" }}
            size={8}
            color={selected || isActive ? theme.white : theme.dark}
          >
            {item.other_data["sale unit"]}
          </PrestoText>
        </View>
        <View
          style={{
            // height: 50,
            flexDirection: "row",
            width: "17%",
            justifyContent: "center",
            alignItems: "center",
            borderRightColor: theme.lightText,
            borderRightWidth: 1,
            paddingHorizontal: 5,
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 0,
          }}
        >
          <PrestoAmountInputWithPlusMinus
            value={item.current_stock_count}
            clearTextOnFocus={false}
            onAmountChange={(quantity) => onAmountChange(quantity)}
            placeholder=""
            keyboardType="numeric"
            selected={isActive}
          ></PrestoAmountInputWithPlusMinus>
        </View>

        <View
          style={{
            // height: 50,
            flexDirection: "column",
            width: "9%",
            justifyContent: "center",
            alignItems: "center",
            borderRightColor: theme.lightText,
            borderRightWidth: 1,
            paddingHorizontal: 10,
          }}
        >
          <PrestoText
            fontStyle={"400.semibold"}
            size={12}
            color={selected || isActive ? theme.white : theme.dark}
            extraStyle={{ textAlign: "center" }}
          >
            {diff}
          </PrestoText>
        </View>

        <View
          style={{
            // height: 40,
            flexDirection: "column",
            width: "16%",
            justifyContent: "center",
            alignItems: "center",
            borderRightColor: theme.lightText,
            borderRightWidth: 1,
            paddingHorizontal: 10,
            paddingTop: 10,
          }}
        >
          {item.current_stock_count - item.stock_count != 0 ? (
            <Pressable
              style={{
                height: 40,
                width: "100%",
              }}
              onPress={() => onPressDropDown()}
            >
              <TextInputDoubleButton
                placeholder={reason}
                isEditable={false}
                searchText={
                  item.other_data.stock_audit_reason
                    ? item.other_data.stock_audit_reason
                    : ""
                }
                onSearchText={(text) => { }}
                hidePlus={true}
                hideArrow={true}
                extraStyle={{
                  borderWidth: 0,
                  borderRadius: 12,
                }}
                arrowButtonContainerStyles={{
                  borderTopRightRadius: 12,
                  borderBottomRightRadius: 12,
                }}
                arrowButtonProps={{
                  borderTopRightRadius: 12,
                  borderBottomRightRadius: 12,
                }}
                textInputStyle={{
                  backgroundColor: "#D8D8D8",
                  borderRadius: 12,
                  fontSize: 12,
                  color: "#999999",
                }}
                iconBgColor={theme.primary}
              />
            </Pressable>
          ) : (
            <PrestoText
              fontStyle={"400.semibold"}
              size={12}
              color={selected || isActive ? theme.white : theme.dark}
              extraStyle={{ textAlign: "center" }}
            >
              {I18n.t("screen_messages.match")}
            </PrestoText>
          )}
        </View>
        <View
          style={{
            // height: 50,
            width: "12%",
            flexDirection: "row",
            paddingHorizontal: 10,
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <PrestoSolidButton
            title={I18n.t("screen_messages.common.submit")}
            height={30}
            titleSize={10}
            width={60}
            titleColor={selected || isActive ? theme.white : theme.primary}
            borderColor={selected || isActive ? theme.white : theme.primary}
            borderWidth={1}
            borderRadius={7}
            onPress={() => {
              item.is_selected = true;
              if (selectedItemIds.indexOf(item.id) == -1) {
                let itemIds = selectedItemIds;
                itemIds.push(item.id);
                let sItems = selectedItems;
                sItems.push(item);
                setSelectedItemIds(itemIds);
                setSelectedItems(sItems);
                setSelectedItem(item);
              } else {
                console.log("I am setting again and again");
                var itemIndex = selectedItemIds.indexOf(item.id);
                let itemIds = selectedItemIds;
                itemIds.splice(itemIndex, 1);
                let sItems = selectedItems;
                sItems.splice(itemIndex, 1);
                setSelectedItemIds(itemIds);
                setSelectedItems(sItems);
                setSelectedItem(null);

                itemIds.push(item.id);
                sItems.push(item);
                setSelectedItemIds(itemIds);
                setSelectedItems(sItems);
                setSelectedItem(item);
                //setSelectedItem(item);
              }
            }}
          />
        </View>
      </Pressable>
    );
  };

  const ItemPictures = () => {
    return (
      <View
        style={{
          paddingRight: 30,
          paddingTop: 30,
          flexDirection: "row",
        }}
      >
        <Image
          source={{ uri: "http://unsplash.it/120/200" }}
          alt={"image"}
          style={{
            height: 180,
            width: 120,
            borderRadius: 5,
          }}
        />
        <Image
          source={{ uri: "http://unsplash.it/120/200" }}
          alt={"image"}
          style={{
            marginLeft: 5,
            height: 180,
            width: 120,
            borderRadius: 5,
          }}
        />
      </View>
    );
  };

  const ItemVaritaions = () => {
    return (
      <ScrollView
        horizontal
        contentContainerStyle={{
          flexDirection: "row",
          bottom: 10,
          position: "absolute",
        }}
      >
        {selectedItem?.variations?.map((item, index) => {
          let isEven = index % 2 === 0;
          return (
            <Pressable
              onPress={() => onPressVariation(item)}
              style={{
                height: 80,
                width: 180,
                backgroundColor: isEven ? theme.primary : theme.secondary,
                borderRadius: 20,
                marginRight: 20,
                padding: 10,
              }}
            >
              <PrestoText size={16} color={theme.white} fontStyle={"600.bold"}>
                {item?.name}
              </PrestoText>
              <PrestoText
                size={14}
                color={theme.white}
                fontStyle={"600.normal"}
                extraStyle={{ paddingTop: 5 }}
              >
                {item?.price?.base_price}
              </PrestoText>
            </Pressable>
          );
        })}
      </ScrollView>
    );
  };

  const ItemAddComponent = () => {
    return (
      <View
        style={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <PrestoText fontStyle={"400.semibold"} size={16}>
          {I18n.t("screen_messages.purchase_requests.sale_unit")}{" "}
          {itemPurchaseConfiguration &&
            !_.isEmpty(itemPurchaseConfiguration.sale_unit_display_name)
            ? `(${itemPurchaseConfiguration.sale_unit_display_name})`
            : ""}
        </PrestoText>

        <PlusMinusButton
          count={itemQty}
          theme={theme}
          onUpdate={(qty) => {
            if (qty >= 0) {
              setItemQuantity(qty);
            }
          }}
          onPressMinus={() => {
            setItemQuantity((prev) => {
              return prev > 1 ? prev - 1 : 0;
            });
          }}
          onPressPlus={() => {
            setItemQuantity((prev) => {
              return prev + 1;
            });
          }}
          extraStyle={{
            height: 50,
            borderRadius: 6,
            width: 200,
            marginTop: 8,
          }}
        />
      </View>
    );
  };

  const updateAllItemPrices = () => {
    let isValid = true;
    selectedItems.forEach((item, index) => {
      let price = (parseFloat(item.markedUpPrice) * 100).toString();
      if (isNaN(price) || price == 0) {
        isValid = false;
      }
    });

    if (!isValid) {
      alert(I18n.t("screen_messages.please_select_an_option_to_apply"));
      return;
    }
    var count = selectedItems.length;
    console.log("I am going to updatehhhhh for -- " + count);
    let completedCount = 0;
    let margin = stockForm.finalMargin;
    stockForm.finalMarkupAmount = null;
    stockForm.finalMarkupPercentage = null;
    stockForm.finalMargin = null;
    if (count > 0) {
      setLoading(true);
    }
    selectedItems.forEach((item, index) => {
      let price = (parseFloat(item.markedUpPrice) * 100).toString();
      let other_data = item.other_data;
      if (margin) {
        other_data.margin = margin;
      } else if (other_data.avg_purchase_price) {
        var percentageDiff =
          ((item.markedUpPrice +
            item.markedUpTax -
            item.other_data.avg_purchase_price) /
            item.other_data.avg_purchase_price) *
          100.0;
        other_data.margin = percentageDiff.toFixed(2);
      }
      let params1 = {
        category_item: {
          is_available: true,
          name: item.name,
          other_data: JSON.stringify(other_data),
        },
        price: {
          base_price: price,
        },
      };
      let params = {};
      params.id = item.id;
      params.body = params1;
      console.log(params);
      EmployeeManager.updateCategoryItem(
        params,
        (res) => {
          completedCount = completedCount + 1;
          if (completedCount >= count) {
            setTimeout(function () {
              setLoading(false);
              setSelectedItemIds([]);
              alert(I18n.t("screen_messages.all_selected_item_price_updated"));
              setTimeout(function () {
                const offlineSync = new OfflineSync(user);
                const force = true;
                offlineSync.syncCatalog(force);
                console.log("Sync in progress");
              }, 20000);
            }, 10000);
          }
        },
        (err) => {
          console.log(err);
          console.log("Error");
          completedCount = completedCount + 1;
          if (completedCount >= count) {
            setLoading(false);
            setSelectedItemIds([]);
            alert(I18n.t("screen_messages.all_selected_item_price_updated"));
          }
        }
      );
    });
  };

  const createItemComponent = () => {
    return (
      <View style={{ backgroundColor: theme.screenBackgroundColor }}>
        <View
          style={{
            width: windowWidth - 440,
            height: "90%",
            backgroundColor: theme.createItemBgColor,
            borderRadius: 20,
            margin: 20,
            paddingHorizontal: 10,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View style={{ flex: 1 }}>
            <VerticalSpacing size={20} />
            <View>
              <PrestoText
                color={theme.white}
                fontStyle={"400.semibold"}
                size={16}
              >
                {I18n.t("screen_messages.stock.name")}
              </PrestoText>
              <PrestoTextInput
                style={{}}
                width={"100%"}
                theme={theme}
                placeholder={I18n.t("screen_messages.add_name")}
                value={itemName}
                keyboardType={"default"}
                onChange={(val) => onNameChange(val)}
                backgroundColor={theme.white}
                borderWidth={2}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                paddingTop: 20,
              }}
            >
              <View style={{ width: "50%" }}>
                <PrestoText
                  color={theme.white}
                  fontStyle={"400.semibold"}
                  size={16}
                >
                  {I18n.t("screen_messages.stock.listing_order")}
                </PrestoText>
                <PrestoTextInput
                  width={190}
                  theme={theme}
                  placeholder={I18n.t("screen_messages.enter_listing_order")}
                  value={itemListingOrder}
                  keyboardType={"numeric"}
                  onChange={(val) => onListingChange(val)}
                  backgroundColor={theme.white}
                  borderWidth={2}
                />
              </View>
              <View style={{ paddingLeft: 20, width: "50%" }}>
                <PrestoText
                  color={theme.white}
                  fontStyle={"400.semibold"}
                  size={16}
                >
                  {I18n.t("screen_messages.stock_count")}
                </PrestoText>
                <PrestoTextInput
                  width={190}
                  theme={theme}
                  placeholder={I18n.t("screen_messages.stock.enter_stock")}
                  value={itemStock}
                  keyboardType={"numeric"}
                  onChange={(val) => onStockChange(val)}
                  backgroundColor={theme.white}
                  borderWidth={2}
                />
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                paddingTop: 20,
              }}
            >
              <View style={{ width: "50%" }}>
                <PrestoText
                  color={theme.white}
                  fontStyle={"400.semibold"}
                  size={16}
                >
                  {I18n.t("screen_messages.merchants.reference_id")}
                </PrestoText>
                <PrestoTextInput
                  width={190}
                  theme={theme}
                  placeholder={I18n.t(
                    "error_messages.merchants.please_enter_reference_id"
                  )}
                  value={itemRefernceId}
                  keyboardType={"numeric"}
                  onChange={({ nativeEvent: { text } }) =>
                    setItemRefernceId(text)
                  }
                  backgroundColor={theme.white}
                  borderWidth={2}
                />
              </View>
            </View>

            <VerticalSpacing size={20} />
            <View style={{ paddingHorizontal: 5 }}>
              <PrestoText
                color={theme.white}
                fontStyle={"400.semibold"}
                size={18}
                extraStyle={{ paddingBottom: 5 }}
              >
                {I18n.t("screen_messages.description_str")}
              </PrestoText>
              <TextInputLargeBox
                placeholder={I18n.t("screen_messages.enter_the_desc")}
                multiline={true}
                textInputHeight={100}
                textInputfontSize={14}
                numberOfLines={10}
                autoCorrect={false}
                theme={theme}
                onFocusActive={true}
                backgroundColor={theme.white}
                textInputBorderRadius={10}
                placeholderTextColor={theme.subHeader}
                onChange={({ nativeEvent: { text } }) => onDescChange(text)}
                value={itemDesc}
              />
            </View>
          </View>

          <View style={{ flex: 1, paddingLeft: 20 }}>
            <VerticalSpacing size={20} />
            <View>
              <PrestoText
                color={theme.white}
                fontStyle={"400.semibold"}
                size={16}
              >
                {I18n.t("screen_messages.orders.unit_price")}
              </PrestoText>
              <PrestoTextInput
                width={300}
                theme={theme}
                placeholder={I18n.t("screen_messages.orders.enter_unite_price")}
                value={itemPrice}
                keyboardType={"numeric"}
                onChange={(val) => onPriceChange(val)}
                backgroundColor={theme.white}
                borderWidth={2}
              />
            </View>
            <VerticalSpacing size={20} />
            <View>
              <PrestoText
                color={theme.white}
                fontStyle={"400.semibold"}
                size={16}
              >
                {I18n.t("screen_messages.inventory.purchase_price")}
              </PrestoText>
              <PrestoTextInput
                width={300}
                theme={theme}
                placeholder={I18n.t(
                  "screen_messages.inventory.please_enter_purchase_price"
                )}
                value={purchasePrice}
                keyboardType={"numeric"}
                onChange={({ nativeEvent: { text } }) => setPurchasePrice(text)}
                backgroundColor={theme.white}
                borderWidth={2}
              />
            </View>
            <VerticalSpacing size={20} />
            <View style={{ position: "relative" }}>
              <PrestoText
                color={theme.white}
                fontStyle={"400.semibold"}
                size={16}
              >
                {I18n.t("screen_messages.category_str")}
              </PrestoText>
              <VerticalSpacing size={5} />
              <PickerMobile
                dropdownIconStyles={{ color: theme.white }}
                textContainerStyles={{ borderColor: theme.subHeader }}
                items={categoryTypes}
                labelFieldKey={"label"}
                valueFieldKey={"value"}
                textColor={theme.white}
                selectedItem={itemCategory}
                onSelect={(val) => onSelectCategory(val)}
              />

              <PrestoText
                color={theme.white}
                fontStyle={"400.semibold"}
                size={16}
              >
                {I18n.t("screen_messages.suppliers.title")}
              </PrestoText>
              <VerticalSpacing size={5} />
              <PickerMobile
                dropdownIconStyles={{ color: theme.white }}
                textContainerStyles={{ borderColor: theme.subHeader }}
                items={
                  suppliers
                    ? _.map(suppliers, (e) => {
                      return {
                        value: e.id,
                        label: e.name,
                        reference_id: e.reference_id,
                      };
                    })
                    : []
                }
                labelFieldKey={"label"}
                valueFieldKey={"value"}
                textColor={theme.white}
                selectedItem={selectedSupplier}
                onSelect={(val) => {
                  setSelectedSupplier(val);
                }}
              />
            </View>
          </View>

          <View style={{ width: "100%" }}>
            <VerticalSpacing size={20} />
            {renderSupplierPurchasePriceTable()}

            <VerticalSpacing size={20} />
            <View>
              <PrestoSolidButton
                bgColor={theme.primary}
                title={I18n.t("screen_messages.create_str")}
                height={50}
                titleSize={16}
                width={100}
                extraStyle={{
                  marginTop: theme.primaryPadding,
                  alignSelf: "flex-start",
                }}
                titleColor={theme.white}
                borderColor={theme.primary}
                borderWidth={1}
                borderRadius={7}
                onPress={() => validateSubmittedData()}
              />
            </View>
          </View>
        </View>
      </View>
    );
  };

  const renderSupplierPurchasePriceTable = () => {
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flex: 1 }}>
            <PrestoText size={16} color={theme.white} fontStyle={"500.medium"}>
              {I18n.t("screen_messages.suppliers.title2")}
            </PrestoText>
          </View>

          <View style={{ flex: 1 }}>
            <PrestoText size={16} color={theme.white} fontStyle={"500.medium"}>
              {I18n.t("screen_messages.common.price")}
            </PrestoText>
          </View>

          <View
            style={{
              flex: 0.4,
              justifyContent: "flex-end",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <PrestoText size={16} color={theme.white} fontStyle={"500.medium"}>
              {I18n.t("screen_messages.actions")}
            </PrestoText>
          </View>
        </View>

        {itemForm.supplierPrices
          ? _.map(itemForm.supplierPrices, (item, index) => {
            return (
              <View
                key={index}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  rowGap: 15,
                }}
              >
                <View style={{ flex: 1 }}>
                  <View style={{ width: "90%" }}>
                    <PickerMobile
                      dropdownIconStyles={{ color: theme.white }}
                      textContainerStyles={{ borderColor: theme.subHeader }}
                      items={
                        suppliers
                          ? _.map(suppliers, (e) => {
                            return {
                              value: e.id,
                              label: e.name,
                              reference_id: e.reference_id,
                            };
                          })
                          : []
                      }
                      labelFieldKey={"label"}
                      valueFieldKey={"value"}
                      textColor={theme.white}
                      selectedItem={null}
                      // selectedItem={item.supplierId}
                      onSelect={(val) => { }}
                    />
                  </View>
                </View>

                <View style={{ flex: 1 }}>
                  <PrestoTextInput
                    width={300}
                    theme={theme}
                    placeholder={I18n.t(
                      "screen_messages.orders.enter_unite_price"
                    )}
                    value={itemPrice}
                    keyboardType={"numeric"}
                    onChange={(val) => onPriceChange(val)}
                    backgroundColor={theme.white}
                    borderWidth={2}
                  />
                </View>

                <View
                  style={{
                    flex: 0.4,
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <PrestoSolidButton
                    size="medium"
                    buttonStyle="primary"
                    title={"Delete"}
                    titleColor={theme.white}
                    titleFontStyle={"600.semibold"}
                    titleSize={22}
                    height={50}
                    width={100}
                    extraStyle={{ marginTop: 10 }}
                    borderRadius={10}
                    onPress={() => { }}
                    bgColor={theme.primary}
                  />
                </View>
              </View>
            );
          })
          : []}
      </View>
    );
  };

  const renderModal = () => {
    const hasVariation = selectedVariation || !selectedItem?.variations?.length;

    return (
      <ModalMobile
        ModelHeight={"70%"}
        ModelWidth={"65%"}
        visible={modalVisible}
        onDismiss={() => setModalVisible(false)}
        modalBackgroundColor="#FFF"
        modalContainerStyles={styles.modalContainer}
        content={
          <>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <View>
                <PrestoText
                  size={28}
                  color={theme.black}
                  fontStyle={"600.bold"}
                >
                  {selectedItem?.name}
                </PrestoText>
                <PrestoText
                  size={16}
                  color={theme.black}
                  fontStyle={"600.regular"}
                >
                  {selectedItem?.other_data?.company_name}
                </PrestoText>
                <PrestoText
                  size={16}
                  color={theme.subHeader}
                  fontStyle={"600.normal"}
                >
                  {selectedItem?.other_data?.molecule}
                </PrestoText>
              </View>
              {!_.isEmpty(selectedItem?.pictures) ? <ItemPictures /> : null}
            </View>

            {hasVariation ? (
              <>
                <View
                  style={{
                    flexDirection: "row",
                    paddingTop: theme.primaryPadding,
                  }}
                >
                  <View style={{ flex: 1, paddingRight: 10 }}>
                    {renderTextBox({
                      label: I18n.t("screen_messages.products.batch_number"),
                      placeholder: I18n.t(
                        "error_messages.products.please_enter_batch_number"
                      ),
                      value: stockForm.batchNumber,
                      keyboardType: "numeric",
                      setFunction: (text) => {
                        setStockForm({ ...stockForm, batchNumber: text });
                      },
                      fieldName: "batchNumber",
                    })}
                  </View>

                  <View style={{ flex: 1, paddingLeft: 10 }}>
                    <PrestoText
                      fontStyle={"400.semibold"}
                      size={16}
                      extraStyle={{ marginBottom: 5 }}
                    >
                      {I18n.t("screen_messages.products.expiry")}
                    </PrestoText>
                    <DatePickerComponent
                      userDate={
                        stockForm.expiry ? new Date(stockForm.expiry) : null
                      }
                      placeholder={I18n.t(
                        "error_messages.products.please_enter_expiry_date"
                      )}
                      onDateChange={(value) => {
                        setStockForm({
                          ...stockForm,
                          expiry: value.toISOString(),
                        });
                      }}
                      onFocus={() => setFocusedItem("expiry")}
                      onBlur={() => setFocusedItem(null)}
                      textInputStyles={{
                        borderWidth: 2,
                        borderColor:
                          focusedItem === "expiry"
                            ? theme.primary
                            : theme.lightText,
                        borderRadius: 6,
                      }}
                      minimumDate={new Date()}
                      showTimeInput={false}
                      iconSize={35}
                      showYearsDropdown={true}
                      defaultToToday={false}
                      textInputHeight={48}
                    />
                  </View>
                </View>

                <View style={{ flexDirection: "row" }}>
                  <View style={{ width: "50%", paddingRight: 10 }}>
                    {renderTextBox({
                      label: I18n.t("screen_messages.inventory.purchase_price"),
                      placeholder: I18n.t(
                        "screen_messages.inventory.please_enter_purchase_price"
                      ),
                      value: stockForm.purchasePrice,
                      keyboardType: "numeric",
                      setFunction: (text) => {
                        setStockForm({ ...stockForm, purchasePrice: text });
                      },
                      fieldName: "purchasePrice",
                    })}
                  </View>

                  <View style={{ width: "50%", paddingLeft: 10 }}>
                    {hasVariation ? <ItemAddComponent /> : <ItemVaritaions />}
                  </View>
                </View>

                <View style={{ flexDirection: "row" }}>
                  <View style={{ width: "50%", paddingRight: 10 }}>
                    <PrestoSolidButton
                      size="large"
                      buttonStyle="primary"
                      title={I18n.t("screen_messages.common.update")}
                      titleColor={theme.white}
                      titleFontStyle={"600.semibold"}
                      titleSize={22}
                      height={50}
                      width={100}
                      extraStyle={{ marginTop: 10 }}
                      borderRadius={10}
                      onPress={inventoryBatchCreate}
                      bgColor={theme.primary}
                    />
                  </View>
                </View>
              </>
            ) : null}
          </>
        }
      />
    );
  };

  const SortIcon = <svgs.SortIcon color={theme.paragraph} />;
  const renderAddInventoryModal = () => {
    console.log("I am rendering now");
    return (
      <ModalMobile
        ModelHeight={"80%"}
        ModelWidth={isMobile ? "90%" : "85%"}
        visible={addInventoryModalVisible}
        onDismiss={() => setAddInventoryModalVisible(false)}
        modalBackgroundColor="#FFF"
        modalContainerStyles={styles.modalContainer}
        content={
          <>
            <View
              style={{
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%"
              }}
            >
              <View>
                <PrestoText size={28} color={theme.black} fontStyle={"600.bold"}>
                  {I18n.t("screen_messages.add_to_store")}
                </PrestoText>
                <PrestoText
                  size={28}
                  color={theme.black}
                  fontStyle={"600.bold"}
                ></PrestoText>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <PrestoText
                      size={18}
                      color={theme.black}
                      fontStyle={"600.bold"}
                    >
                      {selectedItem?.name}
                    </PrestoText>
                    <PrestoText
                      size={16}
                      color={theme.black}
                      fontStyle={"600.regular"}
                    >
                      {selectedItem?.other_data?.company_name}
                    </PrestoText>
                    <PrestoText
                      size={14}
                      color={theme.subHeader}
                      fontStyle={"600.normal"}
                    >
                      {selectedItem?.other_data?.molecule}
                    </PrestoText>
                  </View>
                  {!_.isEmpty(selectedItem?.pictures) ? <ItemPictures /> : null}
                </View>

                <View
                  style={{
                    flexDirection: isMobile ? "column" : "row",
                    paddingTop: theme.primaryPadding,
                  }}
                >
                  <View style={{ flex: 1, paddingRight: isMobile ? 0 : 10 }}>
                    {renderTextBox({
                      label: I18n.t("screen_messages.products.batch_number"),
                      placeholder: I18n.t(
                        "error_messages.products.please_enter_batch_number"
                      ),
                      value: stockForm.batchNumber,
                      keyboardType: "numeric",
                      setFunction: (text) => {
                        setStockForm({ ...stockForm, batchNumber: text });
                      },
                      fieldName: "batchNumber",
                    })}
                  </View>

                  <View style={{ flex: 1, paddingLeft: isMobile ? 0 : 10 }}>
                    <PrestoText
                      fontStyle={"400.semibold"}
                      size={16}
                      extraStyle={{ marginBottom: 5 }}
                    >
                      {I18n.t("screen_messages.products.expiry")}
                    </PrestoText>
                    <View
                      style={{
                        borderColor: theme.lightText,
                        borderWidth: 3,
                        borderRadius: 6,
                        height: 50,
                        padding: 10,
                        paddingTop: 15,
                        margin: 2,
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                    >
                      <YearMonthPicker
                        onDateChange={(value) => {
                          setStockForm({
                            ...stockForm,
                            expiry: value.toISOString(),
                          });
                        }}
                      ></YearMonthPicker>
                    </View>
                  </View>
                  <View style={{ flex: 1, paddingLeft: isMobile ? 0 : 10 }}>
                    {renderTextBox({
                      label:
                        I18n.t("screen_messages.inventory.purchase_price") + "*",
                      placeholder: I18n.t(
                        "screen_messages.inventory.please_enter_purchase_price"
                      ),
                      value: stockForm.purchasePrice,
                      keyboardType: "numeric",
                      setFunction: (text) => {
                        setStockForm({ ...stockForm, purchasePrice: text });
                      },
                      fieldName: "purchasePrice",
                    })}
                  </View>
                  <View style={{ flex: 1, paddingLeft: isMobile ? 0 : 10 }}>
                    {renderTextBox({
                      label: `${I18n.t("screen_messages.stocks.stock_count")}*`,
                      placeholder: I18n.t("screen_messages.stocks.stock_count"),
                      value: stockForm.stockCount,
                      keyboardType: "numeric",
                      setFunction: (text) => {
                        setStockForm({ ...stockForm, stockCount: text });
                      },
                      fieldName: "stockCount",
                    })}
                  </View>
                  <View style={{ flex: 1, paddingLeft: isMobile ? 0 : 10 }}>
                    {renderTextBox({
                      label: I18n.t("screen_messages.sale_price") + "*",
                      placeholder: I18n.t("screen_messages.enter_sale_price"),
                      value: stockForm.salePrice,
                      keyboardType: "numeric",
                      setFunction: (text) => {
                        setStockForm({ ...stockForm, salePrice: text });
                      },
                      fieldName: "salePrice",
                    })}
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignContent: "flex-end",
                  alignItems: "flex-end",
                  marginTop: 10,
                }}
              >
                <View
                  style={{
                    width: "100%",
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <PrestoSolidButton
                    size="large"
                    buttonStyle="primary"
                    title={I18n.t("screen_messages.add_to_store")}
                    titleColor={theme.white}
                    titleFontStyle={"600.semibold"}
                    titleSize={22}
                    height={50}
                    width={200}
                    extraStyle={{ marginTop: 10 }}
                    borderRadius={10}
                    onPress={addToStoreAndCreateInventory}
                    bgColor={theme.primary}
                  />
                </View>
              </View>
            </View>
          </>
        }
      />
    );
  };

  const LoadMoreBtn = () => {
    return (
      <View
        style={{
          paddingTop: 0,
          paddingLeft: 30,
          flexDirection: "row",
          alignItems: "flex-end",
          alignContent: "flex-end",
        }}
      >
        {results.length > 0 && results.length < totalResultCount ? (
          <PrestoSolidButton
            size="small"
            buttonStyle="secondary"
            titleColor={theme.white}
            title={I18n.t("screen_messages.common.load_more")}
            padding="20"
            width={150}
            titleExtraStyle={{ textTransform: "capitalize" }}
            style={{ alignItems: "flex-end" }}
            onPress={() => {
              loadNextPage();
            }}
          />
        ) : null}
      </View>
    );
  };

  return (
    <LoadingContainer
      bgColor={"transparent"}
      loading={loading || load}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        marginTop: insets.top,
      }}
    >
      <HeaderWithTabs
        isActiveStyles={{
          backgroundColor: "#D8D8D8",
        }}
        tabs={tabs}
        onPressTab={onPressTab}
      />
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          height: "100%",
          backgroundColor: "#d8d8d8",
        }}
      >
        {modalVisible && renderModal()}
        {addInventoryModalVisible && renderAddInventoryModal()}
        {/* <View style={{ height: "100%" }}> */}
        {currentTab == "Store Inventory" && (
          <View
            style={{
              backgroundColor: theme.screenBackgroundColor,
              flex: 1,
            }}
          >
            <View style={{ padding: 10 }}>
              <SearchBar
                placeholder={I18n.t("screen_messages.common.search_by3")}
                onPressItem={displaySearchedItems}
                hideAutoComplete={true}
                onSearchComplete={onSearchItems}
                onSearchQryChange={onSearchQueryChange}
                onSearchResults={({ searchQuery, autoCompleteItems }) => { }}
                sortIcon={isTabAndAbove ? null : SortIcon}
                onPressSortIcon={navigateToSort}
              />
            </View>

            <View
              style={{
                padding: 10,
                zIndex: -1,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <PrestoText
                  extraStyle={{ paddingLeft: 20 }}
                  color={theme.black}
                  fontStyle={"400.bold"}
                  size={16}
                >
                  {I18n.t("screen_messages.common.showing")} {results.length}{" "}
                  {I18n.t("screen_messages.common.of")} {totalResultCount}
                </PrestoText>
                <PrestoFramedButton
                  size="small"
                  buttonStyle="primary"
                  title={I18n.t("screen_messages.inventory.short_expiry")}
                  titleColor={isShowShortExpiry ? theme.white : theme.primary}
                  titleFontStyle={"400.normal"}
                  titleSize={11}
                  height={29}
                  width={100}
                  borderRadius={6}
                  borderColor={isShowShortExpiry ? theme.white : theme.primary}
                  onPress={() => setShowShortExpiry(!isShowShortExpiry)}
                />
              </View>
              <View style={{ ...(isWeb && results.length) && { height: "90vh" } }}>
                <FlatList
                  contentContainerStyle={{
                    paddingBottom: results.length ? 150 : 0,
                  }}
                  stickyHeaderIndices={[0]}
                  data={results}
                  renderItem={renderItem}
                  keyExtractor={(item, index) => `${index}`}
                  ListHeaderComponent={() => {
                    if (isMobile) return;
                    return <RenderItemHeader />;
                  }}
                  onEndReachedThreshold={0.2}
                  onEndReached={
                    results.length > 0
                      ? () => {
                        loadNextPage();
                      }
                      : undefined
                  }
                />
              </View>
            </View>

            {results.length === 0 && !loading ? (
              <View style={{ paddingTop: theme.primaryPadding, zIndex: -1, }}>
                <View
                  style={{
                    flexDirection: isTabAndAbove ? "row" : "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PrestoText
                    extraStyle={{ paddingLeft: 20, textAlign: "center" }}
                    color={theme.black}
                    fontStyle={"400.bold"}
                    size={16}
                  >
                    {I18n.t("screen_messages.products.no_products_found")} "
                    {searchQuery}".
                  </PrestoText>

                  <Pressable
                    onPress={() => {
                      setSelectedItem(null);
                      onSearchQueryChange("");
                      dispatch({
                        type: "SET_CURRENT_TAB",
                        payload: "Add Custom",
                      });

                      dispatch({
                        type: "SET_ITEM_NAME",
                        payload: searchQuery,
                      });

                      dispatch({
                        type: "RESET",
                        payload: {
                          itemDesc: "",
                          itemPrice: "",
                          itemListingOrder: 0,
                          itemCategory: "",
                          itemStock: "",
                        },
                      });

                      setPurchasePrice("");
                      setItemRefernceId("");
                      setSelectedSupplier("");
                    }}
                  >
                    <PrestoText
                      extraStyle={{ paddingLeft: 20 }}
                      color={theme.primary}
                      fontStyle={"400.bold"}
                      size={16}
                    >
                      ({I18n.t("screen_messages.common.click_to_create")})
                    </PrestoText>
                  </Pressable>
                </View>
              </View>
            ) : null}
          </View>
        )}

        {currentTab == "Master Catalog" && (
          <View
            style={{
              backgroundColor: theme.screenBackgroundColor,
              width: "100%",
            }}
          >
            <View style={{ padding: 10 }}>
              <SearchBar
                placeholder={I18n.t("screen_messages.common.search_by3")}
                onPressItem={displaySearchedItems}
                hideAutoComplete={true}
                onSearchComplete={onSearchItems}
                onSearchQryChange={onSearchQueryChange}
                onSearchResults={({ searchQuery, autoCompleteItems }) => { }}
                sortIcon={isTabAndAbove ? null : SortIcon}
                onPressSortIcon={navigateToSort}
              />
            </View>

            <View
              style={{
                padding: 10,
                zIndex: -1,
                width: "100%",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PrestoText
                  extraStyle={{ paddingLeft: 20 }}
                  color={theme.black}
                  fontStyle={"400.bold"}
                  size={16}
                >
                  {I18n.t("screen_messages.showing")} {results.length}{" "}
                  {I18n.t("screen_messages.of")} {totalResultCount}
                </PrestoText>
              </View>
              <View style={{ ...(isWeb && results.length) && { height: "90vh" } }}>
                <FlatList
                  contentContainerStyle={{
                    paddingBottom: results.length ? 150 : 0,
                  }}
                  stickyHeaderIndices={[0]}
                  data={results}
                  renderItem={renderMasterItem}
                  ListHeaderComponent={() => {
                    if (isMobile) return;
                    return <RenderMasterItemHeader />;
                  }}
                  onEndReachedThreshold={0.2}
                  onEndReached={
                    results.length > 0
                      ? () => {
                        loadNextPage();
                      }
                      : undefined
                  }
                />
              </View>
            </View>

            {results.length === 0 && !loading ? (
              <View style={{ paddingTop: theme.primaryPadding }}>
                <View
                  style={{
                    flexDirection: isTabAndAbove ? "row" : "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PrestoText
                    extraStyle={{ paddingLeft: 20, textAlign: "center" }}
                    color={theme.black}
                    fontStyle={"400.bold"}
                    size={16}
                  >
                    {I18n.t("screen_messages.products.no_products_found")} "
                    {searchQuery}".
                  </PrestoText>

                  <Pressable
                    onPress={() => {
                      setSelectedItem(null);
                      onSearchQueryChange("");
                      dispatch({
                        type: "SET_CURRENT_TAB",
                        payload: "Add Custom",
                      });

                      dispatch({
                        type: "SET_ITEM_NAME",
                        payload: searchQuery,
                      });

                      dispatch({
                        type: "RESET",
                        payload: {
                          itemDesc: "",
                          itemPrice: "",
                          itemListingOrder: 0,
                          itemCategory: "",
                          itemStock: "",
                        },
                      });

                      setPurchasePrice("");
                      setItemRefernceId("");
                      setSelectedSupplier("");
                    }}
                  >
                    <PrestoText
                      extraStyle={{ paddingLeft: 20 }}
                      color={theme.primary}
                      fontStyle={"400.bold"}
                      size={16}
                    >
                      ({I18n.t("screen_messages.common.click_to_create")})
                    </PrestoText>
                  </Pressable>
                </View>
              </View>
            ) : null}
          </View>
        )}

        {currentTab == "Quick Price" && (
          <View
            style={{
              backgroundColor: theme.screenBackgroundColor,
              width: "100%",
            }}
          >
            <ScrollView>
              <View style={{ padding: 10 }}>
                <SearchBar
                  placeholder={I18n.t("screen_messages.common.search_by3")}
                  onPressItem={displaySearchedItems}
                  hideAutoComplete={true}
                  onSearchComplete={onSearchItems}
                  onSearchQryChange={onSearchQueryChange}
                  onSearchResults={({ searchQuery, autoCompleteItems }) => { }}
                  sortIcon={isTabAndAbove ? null : SortIcon}
                  onPressSortIcon={navigateToSort}
                />
              </View>

              <View
                style={{
                  padding: 10,
                }}
              >
                <PrestoText
                  extraStyle={{ paddingLeft: isMobile ? 10 : 20 }}
                  color={theme.black}
                  fontStyle={"400.bold"}
                  size={16}
                >
                  {I18n.t(
                    "screen_messages.inventory.selected_product_quick_pricing_options"
                  )}
                  :
                </PrestoText>

                <View
                  style={{
                    paddingTop: 30,
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <View
                    style={{
                      width: isMobile ? "35%" : 200,
                    }}
                  >
                    <PrestoText
                      extraStyle={{
                        paddingLeft: isMobile ? 10 : 20,
                        paddingRight: isMobile ? 0 : 20,
                      }}
                      color={theme.black}
                      fontStyle={"400.bold"}
                      size={16}
                    >
                      {I18n.t("screen_messages.inventory.increase_price_by")}
                    </PrestoText>
                  </View>
                  <View
                    style={{
                      width: isMobile ? "20%" : 80,
                      marginTop: -22,
                    }}
                  >
                    <PrestoTextInput
                      width={30}
                      height={40}
                      theme={theme}
                      keyboardType={"numeric"}
                      onChange={({ nativeEvent: { text } }) => {
                        console.log(text);
                        stockForm.percentageIncrease = text;
                      }}
                      backgroundColor={theme.white}
                      borderWidth={2}
                      onBlur={() => {
                        setFocusedItem(null);
                      }}
                      onFocus={() => {
                        setFocusedItem("percentageIncrease");
                      }}
                      style={{
                        borderWidth: 2,
                        borderColor:
                          focusedItem === "percentageIncrease"
                            ? theme.primary
                            : theme.lightText,
                        borderRadius: 6,
                        minWidth: 30,
                        height: 40,
                        padding: 10,
                      }}
                    />
                  </View>
                  <View style={{}}>
                    <PrestoText
                      extraStyle={{
                        paddingLeft: isMobile ? 10 : 20,
                        paddingRight: isMobile ? 10 : 20,
                        width: isMobile ? 85 : 100,
                      }}
                      color={theme.black}
                      fontStyle={"400.bold"}
                      size={16}
                    >
                      %
                    </PrestoText>
                  </View>
                  <View
                    style={{
                      height: "auto",
                      flexDirection: "column",
                      width: "14%",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingHorizontal: isMobile ? 0 : 10,
                    }}
                  >
                    <PrestoSolidButton
                      size="small"
                      buttonStyle="secondary"
                      titleColor={theme.white}
                      title={I18n.t("screen_messages.apply_str")}
                      padding="20"
                      width={100}
                      titleExtraStyle={{ textTransform: "capitalize" }}
                      onPress={() => {
                        stockForm.finalMarkupAmount = null;
                        stockForm.amountIncrease = 0;
                        stockForm.finalMarkupPercentage =
                          stockForm.percentageIncrease;
                        setSelectedItemIds([]);
                      }}
                    />
                  </View>
                </View>

                <View
                  style={{
                    paddingTop: 30,
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <View style={{ width: isMobile ? "35%" : 200 }}>
                    <PrestoText
                      extraStyle={{
                        paddingLeft: isMobile ? 10 : 20,
                        paddingRight: isMobile ? 0 : 20,
                      }}
                      color={theme.black}
                      fontStyle={"400.bold"}
                      size={16}
                    >
                      {I18n.t("screen_messages.inventory.increase_price_by")}
                    </PrestoText>
                  </View>
                  <View
                    style={{
                      width: isMobile ? "20%" : 80,
                      marginTop: -22,
                    }}
                  >
                    <PrestoTextInput
                      width={30}
                      height={40}
                      theme={theme}
                      keyboardType={"numeric"}
                      onChange={({ nativeEvent: { text } }) => {
                        console.log(text);
                        stockForm.amountIncrease = text;
                      }}
                      backgroundColor={theme.white}
                      borderWidth={2}
                      onBlur={() => {
                        setFocusedItem(null);
                      }}
                      onFocus={() => {
                        setFocusedItem("amountIncrease");
                      }}
                      style={{
                        borderWidth: 2,
                        borderColor:
                          focusedItem === "amountIncrease"
                            ? theme.primary
                            : theme.lightText,
                        borderRadius: 6,
                        minWidth: 30,
                        height: 40,
                        padding: 10,
                      }}
                    />
                  </View>
                  <View style={{}}>
                    <PrestoText
                      extraStyle={{
                        paddingLeft: isMobile ? 10 : 20,
                        paddingRight: isMobile ? 10 : 20,
                        width: isMobile ? 85 : 100,
                      }}
                      color={theme.black}
                      fontStyle={"400.bold"}
                      size={16}
                    >
                      {Config.merchant.currency || "ZMW"}
                    </PrestoText>
                  </View>
                  <View
                    style={{
                      height: "auto",
                      flexDirection: "column",
                      width: "14%",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingHorizontal: 10,
                    }}
                  >
                    <PrestoSolidButton
                      size="small"
                      buttonStyle="secondary"
                      titleColor={theme.white}
                      title={I18n.t("screen_messages.apply_str")}
                      padding="20"
                      width={100}
                      titleExtraStyle={{ textTransform: "capitalize" }}
                      onPress={() => {
                        stockForm.finalMarkupPercentage = null;
                        stockForm.percentageIncrease = 0;
                        stockForm.finalMarkupAmount = stockForm.amountIncrease;
                        setSelectedItemIds([]);
                      }}
                    />
                  </View>
                </View>

                <View
                  style={{
                    paddingTop: 30,
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <View
                    style={{
                      width: isMobile ? "35%" : 200,
                    }}
                  >
                    <PrestoText
                      extraStyle={{
                        paddingLeft: isMobile ? 10 : 20,
                        paddingRight: isMobile ? 0 : 20,
                      }}
                      color={theme.black}
                      fontStyle={"400.bold"}
                      size={16}
                    >
                      {I18n.t("screen_messages.inventory.set_margin")}
                    </PrestoText>
                  </View>
                  <View
                    style={{
                      width: isMobile ? "20%" : 80,
                      marginTop: -22,
                    }}
                  >
                    <PrestoTextInput
                      width={30}
                      height={40}
                      theme={theme}
                      keyboardType={"numeric"}
                      onChange={({ nativeEvent: { text } }) => {
                        console.log(text);
                        stockForm.margin = text;
                      }}
                      backgroundColor={theme.white}
                      borderWidth={2}
                      onBlur={() => {
                        setFocusedItem(null);
                      }}
                      onFocus={() => {
                        setFocusedItem("margin");
                      }}
                      style={{
                        borderWidth: 2,
                        borderColor:
                          focusedItem === "margin"
                            ? theme.primary
                            : theme.lightText,
                        borderRadius: 6,
                        minWidth: 30,
                        height: 40,
                        padding: 10,
                      }}
                    />
                  </View>
                  <View style={{}}>
                    <PrestoText
                      extraStyle={{
                        paddingLeft: isMobile ? 10 : 20,
                        paddingRight: isMobile ? 10 : 20,
                        width: isMobile ? 85 : 100,
                      }}
                      color={theme.black}
                      fontStyle={"400.bold"}
                      size={16}
                    >
                      %
                    </PrestoText>
                  </View>
                  <View
                    style={{
                      height: "auto",
                      flexDirection: "column",
                      width: "14%",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingHorizontal: 10,
                    }}
                  >
                    <PrestoSolidButton
                      size="small"
                      buttonStyle="secondary"
                      titleColor={theme.white}
                      title={I18n.t("screen_messages.apply_str")}
                      padding="20"
                      width={100}
                      titleExtraStyle={{ textTransform: "capitalize" }}
                      onPress={() => {
                        stockForm.finalMarkupPercentage = null;
                        stockForm.percentageIncrease = 0;
                        stockForm.finalMarkupAmount = null;
                        stockForm.amountIncrease = 0;
                        stockForm.finalMargin = stockForm.margin;
                        setSelectedItemIds([]);
                      }}
                    />
                  </View>
                </View>
              </View>

              <View
                style={{
                  paddingTop: 30,
                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: isMobile ? "space-around" : undefined,
                  width: "100%",
                }}
              >
                <View
                  style={{
                    paddingTop: 0,
                    paddingLeft: isMobile ? 10 : 30,
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <PrestoSolidButton
                    size="small"
                    buttonStyle="primary"
                    titleColor={theme.white}
                    title={I18n.t("screen_messages.common.select_all")}
                    padding="20"
                    width={isMobile ? 80 : 100}
                    titleExtraStyle={{ textTransform: "capitalize" }}
                    onPress={() => {
                      var ids = [];
                      var items = [];
                      results.forEach(function (res, i) {
                        if (stockForm.finalMargin) {
                          if (res.other_data.avg_purchase_price) {
                            ids.push(res.id);
                            items.push(res);
                          }
                        } else {
                          ids.push(res.id);
                          items.push(res);
                        }
                      });
                      setSelectedItemIds(ids);
                      setSelectedItems(items);
                    }}
                  />
                </View>
                <View
                  style={{
                    paddingTop: 0,
                    paddingLeft: 30,
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <PrestoSolidButton
                    size="small"
                    buttonStyle="secondary"
                    titleColor={theme.white}
                    title={I18n.t("screen_messages.common.clear_all")}
                    padding="20"
                    width={isMobile ? 80 : 100}
                    titleExtraStyle={{ textTransform: "capitalize" }}
                    onPress={() => {
                      setSelectedItemIds([]);
                      setSelectedItems([]);
                    }}
                  />
                </View>
                <View
                  style={{
                    paddingTop: 0,
                    paddingLeft: 30,
                    flexDirection: "row",
                    alignItems: "flex-end",
                    alignContent: "flex-end",
                  }}
                >
                  <PrestoSolidButton
                    size="small"
                    buttonStyle="secondary"
                    titleColor={theme.white}
                    title={I18n.t(
                      "screen_messages.inventory.save_selected_prices"
                    )}
                    padding="20"
                    width={isMobile ? 160 : 200}
                    titleExtraStyle={{ textTransform: "capitalize" }}
                    style={{ alignItems: "flex-end" }}
                    onPress={() => {
                      updateAllItemPrices();
                    }}
                  />
                </View>
              </View>
              <View
                style={{
                  padding: 10,
                  marginTop: 10,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "space-between",
                    width: "100%",
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <PrestoText
                      extraStyle={{ paddingLeft: 20 }}
                      color={theme.black}
                      fontStyle={"400.bold"}
                      size={16}
                    >
                      {I18n.t("screen_messages.showing")} {results.length}{" "}
                      {I18n.t("screen_messages.of")} {totalResultCount},{" "}
                      {I18n.t(
                        "screen_messages.price_rule_creation.selected_items"
                      )}{" "}
                      {I18n.t("screen_messages.count")}: {selectedItems.length}
                    </PrestoText>
                  </View>
                  <LoadMoreBtn />
                </View>
                <View style={{ paddingBottom: results.length ? 200 : 0 }}>
                  <FlatList
                    contentContainerStyle={
                      {
                        // paddingBottom: results.length ? 200 : 0,
                      }
                    }
                    data={results}
                    renderItem={renderQuickPriceItem}
                    ListHeaderComponent={() => {
                      if (isMobile) return;
                      return <RenderQuickPriceHeader />;
                    }}
                  />
                  <View
                    style={{ flexDirection: "row", justifyContent: "flex-end" }}
                  >
                    <LoadMoreBtn />
                  </View>
                </View>
              </View>

              {results.length === 0 && !loading ? (
                <View style={{ paddingTop: theme.primaryPadding }}>
                  <View
                    style={{
                      flexDirection: isTabAndAbove ? "row" : "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PrestoText
                      extraStyle={{ paddingLeft: 20, textAlign: "center" }}
                      color={theme.black}
                      fontStyle={"400.bold"}
                      size={16}
                    >
                      {I18n.t("screen_messages.products.no_products_found")} "
                      {searchQuery}".
                    </PrestoText>

                    <Pressable
                      onPress={() => {
                        setSelectedItem(null);
                        onSearchQueryChange("");
                        dispatch({
                          type: "SET_CURRENT_TAB",
                          payload: "Add Custom",
                        });

                        dispatch({
                          type: "SET_ITEM_NAME",
                          payload: searchQuery,
                        });

                        dispatch({
                          type: "RESET",
                          payload: {
                            itemDesc: "",
                            itemPrice: "",
                            itemListingOrder: 0,
                            itemCategory: "",
                            itemStock: "",
                          },
                        });

                        setPurchasePrice("");
                        setItemRefernceId("");
                        setSelectedSupplier("");
                      }}
                    >
                      <PrestoText
                        extraStyle={{ paddingLeft: 20 }}
                        color={theme.primary}
                        fontStyle={"400.bold"}
                        size={16}
                      >
                        ({I18n.t("screen_messages.common.click_to_create")})
                      </PrestoText>
                    </Pressable>
                  </View>
                </View>
              ) : null}
            </ScrollView>
          </View>
        )}
        {currentTab == "Non Zip Purchases" ? (
          <ItemPurchaseRequestTable
            activeRow={activeRow}
            //setParentLoading={setParentLoading}
            selectedItemIds={selectedItemIds}
            setActiveRow={setActiveRow}
            onPressDownload={(selectedItemPurchaseRequests) =>
              onPressDownload(selectedItemPurchaseRequests)
            }
            sendSelectedToZip={(selectedItemPurchaseRequests) =>
              sendSelectedToZip(selectedItemPurchaseRequests)
            }
            onPressRow={(product) => {
              let tempItem = null;
              if (product.id !== activeRow?.id) {
                tempItem = product;
              }
              setActiveRow(tempItem);
            }}
            items={itemPurchaseRequests}
            fetchPurchaseRequestItems={fetchPurchaseRequestItems}
            selectedItemPurchaseRequests={extraOptions.current.selectedItems}
            setSelectedItemPurchaseRequests={(val, itemObj) => {
              extraOptions.current.selectedItems = val;
              console.log(itemObj);
              console.log("----");
              let item = itemObj.item;
              var itemIndex = selectedItemIds.indexOf(item.id);
              let itemIds = selectedItemIds;
              itemIds.splice(itemIndex, 1);
              let sItems = selectedItems;
              sItems.splice(itemIndex, 1);
              setSelectedItemIds(itemIds);
              setSelectedItems(sItems);
              setSelectedItem(null);
              setTimeout(function () {
                itemIds.push(item.id);
                sItems.push(item);
                setSelectedItemIds(itemIds);
                setSelectedItems(sItems);
                setSelectedItem(item);
              }, 10);
            }}
            navigateToInitialPurchaseRequestDetail={() => {
              setActionHandler({
                type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
              });
            }}
            isIncomingScreen={isIncomingScreen}
            setParentLoading={setLoading}
          />
        ) : null}

        {currentTab == "Open Zip Orders" ? (
          <View
            style={{
              width: "100%",
              backgroundColor: theme.screenBackgroundColor,
            }}
          >
            <MyPurchaseRequests
              activeRow={activeRow}
              suppliers={suppliers}
              column2Title={
                _.some(tabs, ["id", "awaiting_approval"])
                  ? I18n.t("screen_messages.orders.items")
                  : I18n.t("screen_messages.suppliers.title2")
              }
              onPressActionButton={() => { }}
              onPressRow={(tempPurchaseOrder) => {
                /*let tempItem = null;
                if (tempPurchaseOrder.id !== activeRow?.id) {
                  tempItem = tempPurchaseOrder;
                }
                setActiveRow(tempItem);
                setPurchaseOrder(tempPurchaseOrder);

                setActionHandler({
                  type: "NAVIGATE_TO_INTIAL_PURCHASE_REQUEST_DETAIL",
                });*/
              }}
              items={purchaseOrders}
              onEndReached={() => { }}
              isIncomingScreen={isIncomingScreen}
              purchaseOrderObject={purchaseOrderObject}
              loadPurchaseOrders={loadPurchaseOrders}
            />
          </View>
        ) : null}
        {currentTab == "Add Custom" ? (
          <ProductForm
            categoryTypes={categoryTypes}
            suppliers={suppliers}
            catalog={catalog}
            product={selectedItem}
            searchQuery={itemName}
            dispatch={dispatch}
          />
        ) : null}
        {
          currentTab == "Edit Request" && state?.currentInventoryPurchase ? (
            <PurchaseRequestUpdate
              onUpdate={() => {
                fetchAndSetItemPurchaseConfiguration();
                if (isMobile && currentTab === "Store Inventory") {
                  Navigator.emit({
                    event: "modal",
                    params: {
                      screenName: "ITEM_DETAILS_SCREEN",
                      screenParams: {
                        selectedItem: null,
                        sideNavigationRef: navigationRef,
                        enabledEditItem: true,
                        dispatch: dispatch,
                        onPressEdit: () => {
                          setItemForForm(null);
                          dispatch({
                            type: "SET_CURRENT_TAB",
                            payload: "Add Custom",
                          });
                          Navigator.emit({ event: "goBack" });
                        },
                      },
                    },
                  });
                } else {
                  navigationRef.navigate("ItemDetailScreen", {
                    selectedItem: null,
                    sideNavigationRef: navigationRef,
                    enabledEditItem: true,
                    dispatch: dispatch,
                    onPressEdit: () => {
                      setItemForForm(null);
                      dispatch({
                        type: "SET_CURRENT_TAB",
                        payload: "Add Custom",
                      });
                    },
                  });
                }
                loadFirstPage();
                setSelectedItem(null);
                dispatch({
                  type: "SET_CURRENT_TAB",
                  payload: "Store Inventory",
                })
              }}
              selectedItem={state?.currentInventoryPurchase}
            />
          ) : <></>
        }
        {currentTab == "Stock Audit" && (
          <View
            style={{
              backgroundColor: theme.screenBackgroundColor,
              flex: 1,
            }}
          >
            <View style={{ padding: 10 }}>
              <SearchBar
                placeholder={I18n.t("screen_messages.common.search_by3")}
                onPressItem={displaySearchedItems}
                hideAutoComplete={true}
                onSearchComplete={onSearchItems}
                onSearchQryChange={onSearchQueryChange}
                onSearchResults={({ searchQuery, autoCompleteItems }) => { }}
                sortIcon={isTabAndAbove ? null : SortIcon}
                onPressSortIcon={navigateToSort}
              />
            </View>

            <View
              style={{
                padding: 10,
                zIndex: -1,
              }}
            >
              <View style={{ ...(isWeb && results.length) && { height: "90vh" } }}>
                <FlatList
                  contentContainerStyle={{
                    paddingBottom: results.length ? 150 : 0,
                  }}
                  stickyHeaderIndices={[0]}
                  data={results}
                  renderItem={renderStockItem}
                  ListHeaderComponent={() => {
                    if (isMobile) return;
                    return <RenderStockItemHeader />;
                  }}
                  onEndReachedThreshold={0.2}
                  onEndReached={
                    results.length > 0
                      ? () => {
                        loadNextPage();
                      }
                      : undefined
                  }
                />
              </View>
            </View>

            {results.length === 0 && !loading ? (
              <View style={{ paddingTop: theme.primaryPadding }}>
                <View
                  style={{
                    flexDirection: isTabAndAbove ? "row" : "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PrestoText
                    extraStyle={{ paddingLeft: 20, textAlign: "center" }}
                    color={theme.black}
                    fontStyle={"400.bold"}
                    size={16}
                  >
                    {I18n.t("screen_messages.products.no_products_found")} "
                    {searchQuery}".
                  </PrestoText>

                  <Pressable
                    onPress={() => {
                      setSelectedItem(null);
                      onSearchQueryChange("");
                      dispatch({
                        type: "SET_CURRENT_TAB",
                        payload: "Add Custom",
                      });

                      dispatch({
                        type: "SET_ITEM_NAME",
                        payload: searchQuery,
                      });

                      dispatch({
                        type: "RESET",
                        payload: {
                          itemDesc: "",
                          itemPrice: "",
                          itemListingOrder: 0,
                          itemCategory: "",
                          itemStock: "",
                        },
                      });

                      setPurchasePrice("");
                      setItemRefernceId("");
                      setSelectedSupplier("");
                    }}
                  >
                    <PrestoText
                      extraStyle={{ paddingLeft: 20 }}
                      color={theme.primary}
                      fontStyle={"400.bold"}
                      size={16}
                    >
                      ({I18n.t("screen_messages.common.click_to_create")})
                    </PrestoText>
                  </Pressable>
                </View>
              </View>
            ) : null}
          </View>
        )}
        {/* </View> */}
        {isMobile ? null : (
          <View
            style={{
              width: 350,
              height: "100%",
              paddingBottom: 35,
              backgroundColor: theme.screenBackgroundColor,
            }}
          >
            <SideNavigation
              navRef={navigationRef}
              selectedFeature={selectedFeature}
              forwardProps={{
                onClickAdd: onClickAddToStore,
                selectedItem: selectedItem,
              }}
            />
          </View>
        )}
      </View>
    </LoadingContainer>
  );
}

function getStyles(theme) {
  return {
    container: {},
    modalContainer: {
      alignSelf: "center",
      padding: 20,
      justifyContent: "center",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      borderBottomLeftRadius: 20,
    },
  };
}
