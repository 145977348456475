import React, { useContext, useState, useEffect, useCallback } from "react";
import { View, FlatList, Pressable } from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import LoadingContainer from "@presto-components/LoadingContainer";
import { prestoAlert } from "../../common/Alert";
import moment from "moment";
import _ from "lodash";
import { usePagination } from "@presto-hooks/appointmentPagination";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import PrestoSdk from "../../services/global/PrestoSdk";
import RNFetchBlob from "@presto-common/RNFetchBlob";
import Share from "@presto-common/Share"
import SearchBar from "@presto-screen-components/SearchBar/SearchBar";
import SalesTable from "@presto-screen-components/SalesReports/SalesTable";
import SalesTablePagination from "@presto-screen-components/SalesReports/SalesTablePagination";
import AdvanceSearchBar from "@presto-screen-components/SalesReports/AdvanceSearchBar";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PosManager from "@presto-services/features/pos/PosManager";
import CustomersTable from "@presto-screen-components/CustomerList/CustomersTable";
import Utils from "@presto-services/common/Utils";
import utils from "../../utils";
import PrestoIcon from "@presto-components/PrestoIcon";
import { useAssets } from "presto-react-components";
import FlexRowSpaceAligned from "@presto-components/FlexRowSpaceAligned";

export default function CustomerList({
  sideNavigationRef,
  parentRef,
  tabs,
  setTabs,
  parentLoading,
  setParentLoading,
  componentProps,
}) {
  const { Navigator } = useContext(NavigatorContext);
  const [loading, setLoading] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { images, svgs } = useAssets();
  const [totalCount, setTotalCount] = useState(0);
  const [activeCustomer, setActiveCustomer] = useState(null);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [actionHandler, setActionHandler] = useState(null);
  const [currentSortDirection, setCurrentSortDirection] = useState("ASC");
  const [sortField, setSortField] = useState("Name");
  const [filterOptions, setFilterOptions] = useState({
    searchValue: "",
    fromDate: null,
    toDate: null,
  });
  let direction = "ASC"
  const isMobile = utils.isMobile();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (actionHandler) {
      onActionHandle();
    }
  }, [actionHandler, activeCustomer, filterOptions]);

  const init = () => {
    parentRef.current.onPressTab = (tab) => {
      setActionHandler({ type: "ON_PRESS_TAB", tab });
    };
    const tempTabs = [
      {
        id: "customers",
        text: I18n.t("screen_messages.customers.customers"),
        isActive: true,
        defaultStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
      },
      {
        id: "add_customer",
        text: I18n.t("screen_messages.customers.add_customer"),
        tabisActiveTextExtraStyles: {
          color: "#212123",
        },
      },
    ];

    setTabs(tempTabs);

    loadFirstPage();
  };

  const onActionHandle = () => {
    if (actionHandler.type === "ON_PRESS_TAB") {
      onPressTab(actionHandler.tab);
      setActionHandler(null);
    }
  };

  const onPressTab = (tab) => {
    let tempTabs = [];
    setTabs((prev) => {
      tempTabs = _.cloneDeep(prev);
      return prev;
    });

    tempTabs = _.map(tempTabs, (tabItem) => {
      let isActive = tabItem.id === tab.id;
      return {
        ...tabItem,
        isActive,
      };
    });

    setTabs(tempTabs);
    afterPressTab(tab);
  };

  const openSideNavigation = () => {
    if (!isMobile) return;
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "SIDE_NAVIGATION",
        screenParams: {
          forwardProps: componentProps,
        },
      },
    });
  };

  const afterPressTab = (tab) => {
    if (tab.id === "add_customer") {
      openSideNavigation();
      setTimeout(
        () => {
          sideNavigationRef.navigate("AddCustomer", {
            title: "",
            screen: "",
            parentLoading,
            setParentLoading,
          });
        },
        isMobile ? 300 : 300
      );
    }
    if (tab.id === "customers") {
      sideNavigationRef.navigate("CustomerDetails", {
        initialCustomer: null,
        parentLoading,
        setParentLoading,
        sideNavigationRef,
        onSaveCustomerInfoCallback: () => {
          loadFirstPage();
        },
      });
    }
  };

  const initPage = (lastItem, success, failure) => {
    let params = {};
    const text = filterOptions.searchValue;
    params.parameter = Utils.getSearchType(text);
    if (params.parameter == "name") {
      params.value = _.lowerCase(text);
    } else {
      params.value = text;
    }
    if (direction && sortField) {
      params.sortField = sortField;
      params.sortDirection = direction;
    }
    PosManager.searchUser(
      params,
      (response) => {
        const users = response.data.users;
        success(users);
      },
      (error) => {
        failure(error);
      }
    );
  };

  const onPressPage = (page) => {
    loadNextPage();
  };

  const doAdvanceSearch = () => {
    loadFirstPage();
  };

  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };

  const navigateToSort = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "SORT_OPTIONS",
        screenParams: {
          selectedSort: currentSortDirection,
          selectedValue: sortField,
          onSelectSortOptions: onSelectSortOptions,
          goBack,
          currentTab: "Customers",
        },
      },
    });
  };

  const onSelectSortOptions = (value, dir) => {
    setSortField(value);
    setCurrentSortDirection(dir);
    direction = dir;
    // globalStateStore.setKey("SORT_FIELD", value);
    // globalStateStore.setKey("SORT_DIRECTION", direction);
    setTimeout(() => {
      loadFirstPage();
    }, 200);
  };

  const SortIcon = <svgs.SortIcon color={theme.paragraph} />;
  const [
    customers,
    loadNextPage,
    loadFirstPage,
    load,
    pageLoaded,
    done,
  ] = usePagination(initPage, undefined);
  console.log(sideNavigationRef, "iosdufiojadslfjlj")
  return (
    <View
      style={{
        backgroundColor: theme.screenBackgroundColor,
        flex: 1,
      }}
    >
      <View style={{ padding: 10 }}>
        <FlexRowSpaceAligned styleSet={{ paddingBottom: 10 }}>
          <PrestoText
            fontStyle="400.regular"
            size={13}
          >
            {I18n.t("screen_messages.customers.search_by2")}
          </PrestoText>
          <Pressable
            style={{ width: "10%", height: 36, paddingLeft: 5 }}
            onPress={navigateToSort}
          >
            <PrestoIcon icon={SortIcon} />
          </Pressable>
        </FlexRowSpaceAligned>
        <AdvanceSearchBar
          searchValue={filterOptions.searchValue}
          onChange={(text) => {
            setFilterOptions({
              ...filterOptions,
              searchValue: text,
            });
          }}
          onSubmitEditing={doAdvanceSearch}
          onPressIcon={doAdvanceSearch}
          placeHolder={isMobile ? I18n.t("screen_messages.search") : I18n.t("screen_messages.customers.search_by2")}
        />
      </View>

      <View
        style={{
          paddingHorizontal: 10,
          paddingBottom: 120,
          height: "100%",
        }}
      >
        <CustomersTable
          activeRow={activeCustomer}
          onPressRow={(customer) => {
            console.log(customer, "running 3");
            let tempCustomer =
              customer.id !== activeCustomer?.id ? customer : null;
            openSideNavigation();
            setTimeout(
              () => {
                sideNavigationRef.navigate("CustomerDetails", {
                  initialCustomer: tempCustomer,
                  parentLoading,
                  setParentLoading,
                  sideNavigationRef,
                  onSaveCustomerInfoCallback: () => {
                    loadFirstPage();
                  },
                });
              },
              isMobile ? 300 : 0
            );
            setActiveCustomer(tempCustomer);
          }}
          items={customers}
          onEndReached={() => { }}
        />
      </View>
    </View>
  );
}
