import React, { useContext, useState, useEffect, useCallback } from "react";
import {
  View,
  SectionList,
  Pressable,
  Platform,
  PermissionsAndroid,
  RefreshControl,
} from "react-native";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import LoadingContainer from "@presto-components/LoadingContainer";
import { prestoAlert } from "../../common/Alert";
import moment from "moment";
import _ from "lodash";
import { usePagination } from "../../hooks/pagination";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import PrestoSdk from "../../services/global/PrestoSdk";
import RNFetchBlob from "@presto-common/RNFetchBlob";
import Share from "@presto-common/Share"
import AdvanceSearchBar from "@presto-screen-components/SalesReports/AdvanceSearchBar";
import AdvanceSearchFields from "@presto-screen-components/SalesReports/AdvanceSearchFields";
import { VerticalSpacing } from "@presto-components/Spacing";
import AllReportsTable from "@presto-screen-components/SalesReports/AllReportsTable";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import SessionManager from "@presto-services/features/session/SessionManager";

export default function AllReports({
  parentRef,
  tabs,
  setTabs,
  parentLoading,
  setParentLoading,
  sideNavigationRef,
}) {
  const { Navigator } = useContext(NavigatorContext);
  const { theme } = useContext(ThemeContext);
  const [allReports, setAllReports] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const isWeb = Platform.OS == 'web'
  const [filterOptions, setFilterOptions] = useState({
    searchValue: "",
    fromDate: null,
    toDate: null,
  });
  const [refreshing, setRefreshing] = useState(false);
  useEffect(() => {
    init();
    loadFirstPage();
  }, []);

  const init = () => {
    parentRef.current.onPressTab = onPressTab;
    const tabStyles = {
      tabisActiveTextExtraStyles: {
        color: "#212123",
      },
    };

    const tempTabs = [
      {
        id: "sales",
        text: I18n.t("screen_messages.sales.sales"),
        isActive: true,
        defaultStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveStyles: {
          backgroundColor: theme.primary,
        },
        tabisActiveTextExtraStyles: {
          color: theme.white,
        },
      },
      ...[
        {
          id: "custom",
          text: I18n.t("screen_messages.common.custom"),
          isActive: true,
        },
      ].map((item) => {
        return {
          ...item,
          ...tabStyles,
        };
      }),
    ];

    setTabs(tempTabs);
  };

  const afterPressTab = (tab) => {
    const id = tab.id,
      fromDate = new Date(),
      toDate = new Date();

    fromDate.setHours("00", "00", "01");
    toDate.setHours("23", "59", "59");

    if (id === "sales") {
      navigateToSalesReports();
    }
  };

  const navigateToSalesReports = () => {
    Navigator.emit({
      event: "replace",
      params: {
        screenName: "HOME",
        screenParams: {
          selectedFeature: {
            state: "sales_reports_management",
          },
        },
      },
    });
  };

  const onPressTab = (tab) => {
    let tempTabs = [];
    setTabs((prev) => {
      tempTabs = _.cloneDeep(prev);
      return prev;
    });

    tempTabs = _.map(tempTabs, (tabItem) => {
      let ifMainAndSelectedTab = _.includes(["sales", tab.id], tabItem.id);
      let ifOnlyMainTabThanFallback =
        tab.id === "sales" && tabItem.id === "today";
      let isActive = ifMainAndSelectedTab || ifOnlyMainTabThanFallback;
      return {
        ...tabItem,
        isActive,
      };
    });

    setTabs(tempTabs);
    afterPressTab(tab);
  };

  const getReports = (pageNumber, success, failure) => {
    setParentLoading(true);
    var params = {
      page: pageNumber,
    };

    EmployeeManager.getReport(
      params,
      (response) => {
        if (pageNumber !== undefined && pageNumber == 1) {
          setAllReports([{ title: "All Reports", data: response.data }]);
        } else {
          setAllReports((prevReports) => {
            if (prevReports) {
              const newData = prevReports[0].data.concat(response.data);
              return [
                {
                  title: "All Reports",
                  data: newData,
                },
              ];
            }
          });
        }
        if (success) {
          success(response.data);
        }
        setParentLoading(false);
        return;
      },
      (error) => {
        console.log("Error here", error);
        failure(error);
        setParentLoading(false);
      }
    );
  };

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const alertBox = (title, message) =>
    prestoAlert(
      title,
      message,
      [
        {
          text: "Ok",
          onPress: () => { },
          style: "cancel",
        },
      ],
      false
    );

  const wait = (timeout) => {
    return new Promise((resolve) => setTimeout(resolve, timeout));
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    loadFirstPage();

    wait(2000).then(() => setRefreshing(false));
  }, []);

  const fetchPrestoSession = async (report) => {
    setParentLoading(true);
    return await SessionManager.getEmployee((sessionHeader) => {
      const url =
        PrestoSdk.getHostName() +
        `/employee/v0/reports_tasks/${report.id}/download_report`
      window.open(url, "_self");
      setParentLoading(false);
      return url;
    })
  };

  const onDownloadReport = async (report) => {
    if (isWeb) {
      fetchPrestoSession(report)
    } else {
      setParentLoading(true);
      const sessionUrl = PrestoSdk.getHostName() + "/employee.json";
      const sessionResponse = await fetch(sessionUrl);
      const prestoToken = sessionResponse.headers.map["x-presto-st"];
      const reportUrl =
        PrestoSdk.getHostName() +
        `/employee/v0/reports_tasks/${report.id}/download_report`;
      if (Platform.OS == "android") {
        downloadReportAndroid(prestoToken, reportUrl);
      } else if (Platform.OS === "ios") {
        downloadReportIOS(prestoToken, reportUrl);
      }
    }
  };

  const askFileWritePermissions = async () => {
    const writePermissionKey =
      PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE;
    const readPermissionKey =
      PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE;
    const result = await PermissionsAndroid.requestMultiple([
      writePermissionKey,
      readPermissionKey,
    ]);

    if (
      result[readPermissionKey] === "denied" ||
      result[writePermissionKey] === "denied"
    ) {
      alertBox(I18n.t("screen_messages.permissions.enabled_write"));
      return null;
    } else {
      return result;
    }
  };

  const downloadReportAndroid = async (prestoToken, reportUrl) => {
    const permissionResult = await askFileWritePermissions();

    if (!permissionResult) {
      return;
    } else {
      setParentLoading(true);
      const dirs = RNFetchBlob.fs.dirs;
      const configOptions = {
        trusty: true,
        credentials: true,
        addAndroidDownloads: {
          useDownloadManager: true,
          notification: true,
          mime: "text/csv",
          description: "Report downloaded",
          path: `${dirs.DownloadDir}/Report.csv`,
        },
      };

      RNFetchBlob.config(configOptions)
        .fetch("GET", reportUrl, { "x-presto-st": prestoToken })
        .then((resp) => {
          setParentLoading(false);
          alertBox(I18n.t("screen_messages.reports.report_downloaded"));
          return resp;
        })
        .catch((err) => {
          setParentLoading(false);
          alert(err);
        });
    }
  };

  const downloadReportIOS = async (prestoToken, reportUrl) => {
    setParentLoading(true);
    let filePath = null;
    const configOptions = { fileCache: true, trusty: true };
    RNFetchBlob.config(configOptions)
      .fetch("GET", reportUrl, { "x-presto-st": prestoToken })
      .then((resp) => {
        filePath = resp.path();
        return resp.readFile("base64");
      })
      .then(async (base64Data) => {
        setParentLoading(false);
        try {
          base64Data = `data:text/csv;base64,` + base64Data;
          await Share.open({
            url: base64Data,
          });
        } catch (error) {
          console.log(`downloadReportIOS Share error`, error);
        }
      });
  };

  const [
    reports,
    loadNextPage,
    loadFirstPage,
    load,
    pageLoaded,
    done,
  ] = usePagination(getReports);

  return (
    <View
      style={{
        backgroundColor: theme.screenBackgroundColor,
      }}
    >
      <View style={{ padding: 10 }}>
        <View>
          <AdvanceSearchFields
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            parentLoading={parentLoading}
            setParentLoading={setParentLoading}
            callBackAfterSubmit={() => {
              loadFirstPage();
            }}
          />
        </View>
      </View>

      <View
        style={{
          paddingHorizontal: 10,
          paddingBottom: 520,
          height: "100%",
          zIndex: 1,
        }}
      >
        <AllReportsTable
          activeRow={activeRow}
          onPressRow={(report) => {
            let reportItem = report;
            if (report.id === activeRow?.id) {
              setActiveRow(null);
              reportItem = null;
            } else {
              setActiveRow(report);
            }

            sideNavigationRef.navigate("ReportDetails", {
              initialReport: reportItem,
              parentLoading,
              setParentLoading,
            });
          }}
          items={reports}
          onEndReached={() => {
            loadNextPage();
          }}
          onDownloadReport={onDownloadReport}
          refresh={loadFirstPage}
        />
      </View>
    </View>
  );
}
