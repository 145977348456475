import * as FileSystem from "expo-file-system";
import * as SQLite from "expo-sqlite";
import config from "@presto-common/config";
import _ from "lodash";
import AsyncStorage from "@react-native-community/async-storage";
import { Platform } from "react-native";
import Dexie from 'dexie';

export default class DatabaseHelper {
  constructor() {
    this.databaseConnection = null;
    if (Platform.OS === 'web') {
      this.setupDexie();
    }
  }

  getFilePath = () => {
    const app = config.app;
    const dbName = `${app}.db`;
    const folderPath = `${FileSystem.documentDirectory}SQLite`;
    const filePath = `${folderPath}/${dbName}`;
    //TODO: Neccessary to open and view the db
    console.log("DatabaseHelper filePath\n", filePath);
    return { folderPath, filePath, dbName };
  };

  getOrCreateDB = async () => {
    const { folderPath } = this.getFilePath();
    const fileInfo = await FileSystem.getInfoAsync(folderPath);
    const fileExists = fileInfo.exists;
    if (!fileExists) {
      try {
        await FileSystem.makeDirectoryAsync(folderPath, {
          intermediates: true,
        });
      } catch (error) {
        console.log("DatabaseHelper error while creating dir", error);
        throw error;
      }
    }
  };

  setupDexie = () => {
    this.dexieDB = new Dexie(config.app);
    console.log(this.dexieDB, "sjdfaisjfajsdlfjlkasdf")
    this.dexieDB.version(1).stores({
      categories: "++_id, id, name, description, pic_url, reference_id, other_data, listing_order, hidden, catalog_id, merchant_id, category_id, updated_at, created_at, pictures",
      category_items: "++_id, id, keywords, description, pic_url, name, reference_id, other_data, listing_order, stock_count, sold_out, pre_orderable, catalog_id, merchant_id, category_id, updated_at, created_at, price, variations, on_offer, pictures, zip_supplied",
      meta_data: "++_id, key, value",
      carts: "++_id, id, user_id, merchant_id, outlet_id, total_price, original_gross_amount, sub_total_price, total_taxes, discounts, total_discounts, coupon_code, discount_response, aggregate_discounts, _sync_status, _user",
      cart_items: "++_id, _cart_id, _variation_id, id, reference_id, name, quantity, pic_url, other_data, item_id, rate, total_price, total_amount, _sync_status",
      orders: "++_id, id, outlet_id, merchant_id, items, instructions, payment_type, source, tax_amount, total_amount, aggregate_discount, address, user_hash, coupon_code, app_id, ordering_employee_id, _composite_payment, _create_payment_params, _sync_status, _error",
      users: "++_id, id, name, email, phone_number, created_at, birthday, gender, address, merchant_id, _sync_status, _error",
      coupons: "++_id, id, active, code, description, end_time, max_discount, max_redemption_count, merchant_id, min_bill_amount, other_data, start_time, target_type, title, type, value, value_type",
      employees: "++_id, id, name, email, phone_number, role"
    });
  };

  connect = () => {
    if (Platform.OS !== 'web') {
      this.getOrCreateDB();

      const { dbName } = this.getFilePath();
      const version = "1.0",
        description = "",
        size = 1;
      const callback = async (db) => {
        this.databaseConnection = db;
        let skipDropTables = false;
        let migration_needed = await AsyncStorage.getItem("migration_needed");
        let migration = await AsyncStorage.getItem("migration");
        if (migration_needed == "no") {
          skipDropTables = true;
        }

        const createCategories = `
        CREATE TABLE IF NOT EXISTS categories(
          _id INTEGER PRIMARY KEY NOT NULL,
          id TEXT,
          name TEXT,
          description TEXT,
          pic_url TEXT,
          reference_id TEXT,
          other_data TEXT,
          listing_order TEXT,
          hidden TEXT,
          catalog_id TEXT,
          merchant_id TEXT,
          category_id TEXT,
          updated_at TEXT,
          created_at TEXT,
          pictures TEXT
      );`;

        const createCategoryItems = `
          CREATE TABLE IF NOT EXISTS category_items(
            _id INTEGER PRIMARY KEY NOT NULL,
            id TEXT,
            keywords TEXT,
            description TEXT,
            pic_url TEXT,
            name TEXT,
            reference_id TEXT,
            other_data TEXT,
            listing_order TEXT,
            stock_count TEXT,
            sold_out TEXT,
            pre_orderable TEXT,
            catalog_id TEXT,
            merchant_id TEXT,
            category_id TEXT,
            updated_at TEXT,
            created_at TEXT,
            price TEXT,
            variations TEXT,
            on_offer TEXT,
            pictures TEXT,
            zip_supplied TEXT
        );`;

        console.log("db skipDropTables", [skipDropTables, migration]);
        console.log(createCategoryItems);

        let execQueries = [
          {
            query: "PRAGMA journal_mode=DELETE;",
          },
          {
            query: "DROP TABLE IF EXISTS categories;",
            skip: skipDropTables,
          },
          {
            query: "DROP TABLE IF EXISTS category_items;",
            skip: skipDropTables,
          },
          {
            query: createCategories,
          },
          {
            query: createCategoryItems,
          },
          {
            query: "CREATE INDEX IF NOT EXISTS index_name ON categories (name);",
          },
          {
            query:
              "CREATE INDEX IF NOT EXISTS index_name ON category_items (name);",
          },
          {
            query:
              "CREATE INDEX IF NOT EXISTS index_catalog_id ON category_items (catalog_id);",
          },
          {
            query:
              "CREATE INDEX IF NOT EXISTS index_category_id ON category_items (category_id);",
          },
          {
            query:
              "CREATE INDEX IF NOT EXISTS index_reference_id ON category_items (reference_id);",
          },
          {
            query: `
          CREATE TABLE IF NOT EXISTS meta_data(
            _id INTEGER PRIMARY KEY NOT NULL,
            key TEXT,
            value TEXT
          );`,
          },
          {
            query: `
          CREATE TABLE IF NOT EXISTS carts(
            _id INTEGER PRIMARY KEY NOT NULL,
            id TEXT,
            user_id TEXT,
            merchant_id TEXT,
            outlet_id TEXT,
            total_price REAL,
            original_gross_amount REAL,
            sub_total_price REAL,
            total_taxes REAL,
            discounts TEXT,
            total_discounts REAL,
            coupon_code TEXT,
            discount_response TEXT,
            aggregate_discounts REAL,
            _sync_status TEXT,
            _user TEXT
          );`,
          },
          {
            query: `
          CREATE TABLE IF NOT EXISTS cart_items(
            _id INTEGER PRIMARY KEY NOT NULL,
            _cart_id TEXT,
            _variation_id TEXT,
            id TEXT,
            reference_id TEXT,
            name TEXT,
            quantity INTEGER,
            pic_url TEXT,
            other_data TEXT,
            item_id TEXT,
            rate REAL,
            total_price REAL,
            total_amount REAL,
            _sync_status TEXT
          );`,
          },
          {
            query: `
          CREATE TABLE IF NOT EXISTS orders(
            _id INTEGER PRIMARY KEY NOT NULL,
            id TEXT,
            outlet_id TEXT,
            merchant_id TEXT,
            items TEXT,
            instructions TEXT,
            payment_type TEXT,
            source TEXT,
            tax_amount REAL,
            total_amount REAL,
            aggregate_discount REAL,
            address TEXT,
            user_hash TEXT,
            coupon_code TEXT,
            app_id TEXT,
            ordering_employee_id TEXT,
            _composite_payment TEXT,
            _create_payment_params TEXT,
            _sync_status TEXT,
            _error TEXT
          );`,
          },
          {
            query: `
          CREATE TABLE IF NOT EXISTS users(
            _id INTEGER PRIMARY KEY NOT NULL,
            id TEXT,
            name TEXT,
            email TEXT,
            phone_number TEXT,
            created_at TEXT,
            birthday TEXT,
            gender TEXT,
            address TEXT,
            merchant_id TEXT,
            _sync_status TEXT,
            _error TEXT
          );`,
          },
          {
            query: "CREATE INDEX IF NOT EXISTS index_name ON users (name);",
          },
          {
            query: "CREATE INDEX IF NOT EXISTS index_email ON users (email);",
          },
          {
            query:
              "CREATE INDEX IF NOT EXISTS index_phone_number ON users (phone_number);",
          },
          {
            query: `
          CREATE TABLE IF NOT EXISTS coupons(
            _id INTEGER PRIMARY KEY NOT NULL,
            id TEXT,
            active TEXT,
            code TEXT,
            description TEXT,
            end_time TEXT,
            max_discount REAL NULL,
            max_redemption_count INTEGER NULL,
            merchant_id TEXT,
            min_bill_amount REAL NULL,
            other_data TEXT,
            start_time TEXT,
            target_type TEXT,
            title TEXT,
            type TEXT,
            value INTEGER,
            value_type TEXT
          );`,
          },
          {
            query: `
          CREATE TABLE IF NOT EXISTS employees(
            _id INTEGER PRIMARY KEY NOT NULL,
            id TEXT,
            name TEXT,
            email TEXT,
            phone_number TEXT,
            role TEXT
          );`,
          },
          {
            query: "CREATE INDEX IF NOT EXISTS index_code ON coupons (code);",
          },
          {
            query: "CREATE INDEX IF NOT EXISTS index_title ON coupons (title);",
          },
          {
            query: "CREATE INDEX IF NOT EXISTS index_name ON employees (name);",
          },
          {
            query: "ALTER TABLE category_items ADD COLUMN stock_count TEXT",
          },
          {
            query: "ALTER TABLE users ADD COLUMN gender TEXT",
          },
        ];

        let consideredQueries = _.filter(execQueries, (e) => !e.skip);
        let allQueries = _.map(consideredQueries, (queryItem) => {
          return {
            sql: queryItem.query,
            args: [],
          };
        });
        if (allQueries) {
          db.exec(allQueries, false, async (error, results) => {
            if (error) {
              console.log("error while db connection", error);
            } else {
              AsyncStorage.setItem("migration", "done");
              let migration_needed = await AsyncStorage.getItem(
                "migration_needed"
              );
              if (migration_needed === "yes") {
                AsyncStorage.setItem("migration_needed", "no");
              }
            }
          });
        }
      };
      return SQLite.openDatabase(dbName, version, description, size, callback);
    } else {
      return { databaseConnection: { exec: () => null } }
    }
  };

  migrateDexie = async () => {
    const migration_needed = await AsyncStorage.getItem("migration_needed");
    if (migration_needed === "yes") {
      await this.dexieDB.transaction('rw', this.dexieDB.tables, async () => {
        await this.dexieDB.categories.clear();
        await this.dexieDB.category_items.clear();
        // ...clear other tables as needed
      });
      await AsyncStorage.setItem("migration_needed", "no");
      await AsyncStorage.setItem("migration", "done");
    }
  };
}