import React, { useContext } from "react";
import { View } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import _ from "lodash";
import moment from "moment";
import PrestoText from "@presto-components/PrestoText";

export default function ItemPromotion({ route }) {
  const { selectedItem, state } = route?.params;
  const { theme } = useContext(ThemeContext);

  const DetailComponent = ({
    label,
    children,
    extraStyle,
    labelExtraStyle,
  }) => {
    return (
      <View
        style={{ paddingHorizontal: 20, flexDirection: "row", ...extraStyle }}
      >
        <PrestoText
          fontStyle={"400.semibold"}
          color={theme.white}
          size={16}
          extraStyle={labelExtraStyle}
        >
          {label}
        </PrestoText>
        <View>{children}</View>
      </View>
    );
  };

  return (
    <View
      style={{
        backgroundColor: theme.topHeaderInactiveColor,
        height: "100%",
        position: "relative",
      }}
    >
      <View style={{ padding: 20 }}>
        <PrestoText size={22} fontStyle={"600.semibold"} color={theme.white}>
          {I18n.t("screen_messages.price_rules.item_promotion_details")}
        </PrestoText>
      </View>
      {state?.itemName ? (
        <DetailComponent
          label={I18n.t("screen_messages.stock.name")}
          extraStyle={{ justifyContent: "space-between" }}
        >
          <PrestoText fontStyle={"400.normal"} color={theme.white} size={18}>
            {state?.itemName}
          </PrestoText>
        </DetailComponent>
      ) : null}
      {state?.itemDescription ? (
        <DetailComponent
          label={I18n.t("screen_messages.description_str")}
          extraStyle={{ justifyContent: "space-between", paddingTop: 20 }}
        >
          <PrestoText fontStyle={"400.normal"} color={theme.white} size={18}>
            {state?.itemDescription}
          </PrestoText>
        </DetailComponent>
      ) : null}
      {state?.timeObject?.startTime ? (
        <DetailComponent
          label={I18n.t("screen_messages.description_str")}
          extraStyle={{ justifyContent: "space-between", paddingTop: 20 }}
        >
          <PrestoText fontStyle={"400.normal"} color={theme.white} size={18}>
            {moment(state?.timeObject?.startTime).format("DD-MM-YYYY")}
          </PrestoText>
        </DetailComponent>
      ) : null}
      {state?.timeObject?.endTime ? (
        <DetailComponent
          label={I18n.t("screen_messages.description_str")}
          extraStyle={{ justifyContent: "space-between", paddingTop: 20 }}
        >
          <PrestoText fontStyle={"400.normal"} color={theme.white} size={18}>
            {moment(state?.timeObject?.endTime).format("DD-MM-YYYY")}
          </PrestoText>
        </DetailComponent>
      ) : null}
      {state?.selectedPriceRule ? (
        <DetailComponent
          label={I18n.t("screen_messages.price_rules.selected_price_rule")}
          extraStyle={{ justifyContent: "space-between", paddingTop: 20 }}
        >
          <PrestoText fontStyle={"400.normal"} color={theme.white} size={18}>
            {state?.selectedPriceRule?.title}
          </PrestoText>
        </DetailComponent>
      ) : null}
    </View>
  );
}
