import React, { useContext } from "react";
import { View, TouchableWithoutFeedback } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import PrestoIcon from "@presto-components/PrestoIcon";
import SelectedCheckbox from "@presto-components/SelectedCheckBox";
import UnSelectedCheckbox from "@presto-components/UnSelectedCheckBox";

export default function CheckboxRowButton(props) {
  const { theme } = useContext(ThemeContext);
  const {
    title,
    showDisclosure,
    lightText,
    uppercase,
    rightTitle,
    onPress,
    rightColor,
    selected,
    description,
    icon,
    titleColor = theme.rowButtonLeftColor,
    descriptionColor = theme.rowButtonLeftColor,
    paddingLeft = theme.primaryPadding,
    paddingHorizontal = theme.primaryPadding,
    height = 56,
  } = props;
  return (
    <TouchableWithoutFeedback onPress={onPress} style={{}}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingVertical: 5,
          height: height,
          backgroundColor: "transparent",
          paddingHorizontal: paddingHorizontal,
          paddingLeft: paddingLeft,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            flex: 1,
          }}
        >
          {icon ? (
            <View style={{ paddingRight: 10 }}>
              <PrestoIcon icon={icon} width={25} height={25} />
            </View>
          ) : null}
          <View style={{ flex: 1 }}>
            {lightText ? (
              <PrestoText
                fontStyle={"semibold"}
                size={14}
                color={titleColor}
                extraStyle={{
                  textTransform: uppercase ? "uppercase" : undefined,
                }}
              >
                {title}
              </PrestoText>
            ) : (
              <PrestoText
                fontStyle={"medium"}
                size={14}
                color={titleColor}
                extraStyle={{
                  textTransform: uppercase ? "uppercase" : undefined,
                }}
              >
                {title}
              </PrestoText>
            )}
            {description ? (
              <PrestoText
                fontStyle={"400.medium"}
                size={12}
                color={descriptionColor}
                extraStyle={{
                  textTransform: uppercase ? "uppercase" : undefined,
                  paddingTop: 5,
                }}
              >
                {description}
              </PrestoText>
            ) : null}
          </View>
        </View>
        {rightTitle && (
          <View style={{ alignContent: "flex-end" }}>
            <PrestoText
              fontStyle={"medium"}
              size={16}
              color={rightColor || theme.primaryColor}
              extraStyle={{
                textTransform: uppercase ? "uppercase" : undefined,
                marginRight: 10,
              }}
            >
              {rightTitle}
            </PrestoText>
          </View>
        )}
        {showDisclosure ? (
          selected ? (
            <View
              style={{
                alignContent: "flex-end",
                alignItems: "center",
                justifyContent: "center",
                width: 50,
                height: 40,
              }}
            >
              <SelectedCheckbox
                borderWidth={props?.borderWidth}
                borderColor={props?.borderColor}
                iconColor={props?.selectedIconColor}
              />
            </View>
          ) : (
            <View
              style={{
                alignContent: "flex-end",
                alignItems: "center",
                justifyContent: "center",
                width: 50,
                height: 40,
              }}
            >
              <UnSelectedCheckbox
                borderWidth={props?.borderWidth}
                borderColor={props?.borderColor}
              />
            </View>
          )
        ) : null}
      </View>
    </TouchableWithoutFeedback>
  );
}
