import React, { useState, useCallback, useEffect, forwardRef } from "react";
import { View, SafeAreaView, StyleSheet, Text, TouchableOpacity, Modal, Picker } from "react-native";
import moment from "moment";
import I18n from "i18n-js";
import DatePicker, { CalendarContainer } from "react-datepicker";
import RNPickerSelect from 'react-native-picker-select';
import { Platform } from 'react-native';

const generateYears = (startYear, endYear) => {
  const years = [];
  for (let i = startYear; i <= endYear; i++) {
    years.push({ label: `${i}`, value: i });
  }
  return years;
};

const generateMonths = () => {
  const months = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ];
  return months;
};

const YearMonthSelectView = ({ startYear = 2024, endYear = 2050, onValueChange }) => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const years = generateYears(startYear, endYear);
  const months = generateMonths();

  const handleConfirm = () => {
    if (selectedYear && selectedMonth) {
      const formattedDate = `${selectedMonth.toString().padStart(2, '0')}/${selectedYear}`;
      onValueChange(moment(formattedDate, "MM/YYYY"));
      setModalVisible(false);
    }
  };

  useEffect(() => {
    handleConfirm();
  }, [selectedMonth, selectedYear])

  return (
    <View>
      <TouchableOpacity style={styles.input} onPress={() => setModalVisible(!modalVisible)}>
        <Text>
          {selectedYear && selectedMonth
            ? `${selectedMonth.toString().padStart(2, '0')}/${selectedYear}`
            : 'Select Date'}
        </Text>
      </TouchableOpacity>
      {Platform.OS === 'web' ? (
        modalVisible && (
          <View style={styles.pickerContainer}>
            <View style={{ paddingBottom: 20, width: 100 }}>
              <RNPickerSelect
                onValueChange={(value) => setSelectedYear(value)}
                items={years}
                placeholder={{ label: 'Select Year', value: null }}
              />
            </View>
            <View>
              <RNPickerSelect
                onValueChange={(value) => { setSelectedMonth(value) }}
                items={months}
                placeholder={{ label: 'Select Month', value: null }}
              />
            </View>
          </View>
        )
      ) : (
        <Modal visible={modalVisible} transparent={true} animationType="slide">
          <View style={styles.modalContainer}>
            <View style={styles.pickerContainer}>
              <Picker
                selectedValue={selectedYear}
                onValueChange={(itemValue) => setSelectedYear(itemValue)}>
                <Picker.Item label="Select Year" value={null} />
                {years.map((year) => (
                  <Picker.Item key={year.value} label={year.label} value={year.value} />
                ))}
              </Picker>
              <Picker
                selectedValue={selectedMonth}
                onValueChange={(itemValue) => setSelectedMonth(itemValue)}>
                <Picker.Item label="Select Month" value={null} />
                {months.map((month) => (
                  <Picker.Item key={month.value} label={month.label} value={month.value} />
                ))}
              </Picker>
              <TouchableOpacity onPress={handleConfirm} style={styles.confirmButton}>
                <Text style={styles.confirmButtonText}>Confirm</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
    margin: 5,
    borderRadius: 5,
    width: 100
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pickerContainer: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    width: '80%',
    maxHeight: '50%',
    alignItems: 'center',
  },
  confirmButton: {
    marginTop: 20,
    backgroundColor: '#007bff',
    padding: 10,
    borderRadius: 5,
  },
  confirmButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
});
export default function YearMonthSelect(props) {
  const [date, setDate] = useState(props.date ? new Date(props.date) : new Date());
  const [show, setShow] = useState(props.show || false);
  const [isSet, setIsSet] = useState(props.isSet ? props.isSet : false);
  const showPicker = useCallback((value) => setShow(value), []);

  const onValueChange = useCallback(
    (event, newDate) => {
      if (event !== "dismissedAction") {
        const selectedDate = newDate || date;

        showPicker(false);
        setDate(selectedDate);
        props.onDateChange(selectedDate);
        setIsSet(true);
      } else {
        setIsSet(false);
        showPicker(false);
      }
    },
    [date, showPicker]
  );

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Text className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </Text>
  ));

  const MyContainer = ({ className, children }) => {
    return (
      <View style={{
      }}>
        <CalendarContainer>
          <View style={{}}>{children}</View>
        </CalendarContainer>
      </View>
    );
  };
  console.log(date, "dsoifjoaijsdfijljsfl")
  return (
    <View>
      <YearMonthSelectView onValueChange={(date) => { setDate(date); props.onDateChange(date) }} />
      {/* {date.year && date.month && (
        <Text>
          Selected: {date.year} - {date.month}
        </Text>
      )} */}
    </View>
  );
}
