import React, { useState, useContext, useRef } from "react";
import { View, Dimensions, ScrollView } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import LoadingContainer from "@presto-components/LoadingContainer";
import _ from "lodash";
import CheckboxRowButton from "@presto-components/CheckBoxes/CheckboxRowButton";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { SafeAreaView } from "react-native-safe-area-context";
import UserContext from "@presto-contexts/UserContext";
import PrestoText from "@presto-components/PrestoText";
import DefaultModal from "@presto-components/Modals/Modal.bookmychef";
import { PrestoSolidButton } from "@presto-components/PrestoButton";

let VALUE_OPTIONS = [
  {
    key: "Name",
    label: "Name",
    toShow: ["Master Catalog", "Store Inventory", "Stock Audit", "Customers"]
  },
  {
    key: "Purchased On",
    label: "Purchased On",
    toShow: ["Sales"]
  },
  {
    key: "Therapy",
    label: "Therapy",
    toShow: ["Master Catalog", "Store Inventory", "Stock Audit"]
  },
  {
    key: "Manufacturer",
    label: "Manufacturer",
    toShow: ["Master Catalog", "Store Inventory", "Stock Audit"]
  },
  {
    key: "Margin",
    label: "Margin",
    toShow: ["Quick Price"]
  },
  {
    key: "Expiry",
    label: "Expiry",
    toShow: ["Quick Price"]
  },
  {
    key: "Molecule",
    label: "Molecule",
    toShow: ["Master Catalog", "Stock Audit", "Quick Price"]
  },
  {
    key: "Zip",
    label: "Sold by ZIP",
    toShow: ["Store Inventory", "Stock Audit"]
  },
  {
    key: "Stock",
    label: "Stock",
    toShow: ["Quick Price", "Store Inventory", "Stock Audit"]
  },
  {
    key: "Avgpp",
    label: "Average Cost",
    toShow: ["Store Inventory", "Stock Audit"]
  },
];

const SORT_OPTIONS = [
  {
    key: "ASC",
    label: "screen_messages.sort.sort_ascending",
  },
  {
    key: "DESC",
    label: "screen_messages.sort.sort_descending",
  },
];

export default function SortOptionsScreen({ route, navigation }) {
  const {
    goBack,
    selectedSort,
    selectedValue,
    onSelectSortOptions,
    currentTab,
  } = route.params;
  let VALUE_OPTIONS_FILTER = VALUE_OPTIONS.filter((ele) => { console.log(ele?.toShow?.includes(currentTab)); return (ele?.toShow?.includes(currentTab)) })
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const [sort, setSort] = useState(selectedSort);
  const [value, setValue] = useState(selectedValue);
  const modalRef = useRef();
  const windowHeight = Dimensions.get("window").height;

  const onPressApply = () => {
    if (sort && value) {
      navigation.goBack();
      onSelectSortOptions(value, sort);
    } else {
      alert("Please Select the Value and Sort Direction");
    }
  };

  const onCheckboxClickedSort = (key) => {
    setSort(key);
  };

  const onCheckboxClickedValue = (key) => {
    setValue(key);
  };

  return (
    <DefaultModal
      goBack={goBack}
      modalRef={modalRef}
      keyboardAvoidView={false}
      bgColor={theme.appBackgroundColor}
      height={(75 / 100) * windowHeight}
    >
      <LoadingContainer
        bgColor={theme.white}
        loading={false}
        style={{
          borderTopRightRadius: 18,
          borderTopLeftRadius: 18,
        }}
      >
        <View
          style={{
            backgroundColor: theme.white,
            borderTopRightRadius: 18,
            borderTopLeftRadius: 18,
            flex: 1,
            paddingTop: 30,
          }}
        >
          <ScrollView>
            <PrestoText
              extraStyle={{ paddingLeft: 15, marginBottom: 15 }}
              fontStyle={"600.semibold"}
              size={16}
            >
              {"Value"}
            </PrestoText>
            {_.map(VALUE_OPTIONS_FILTER, (option) => {
              return (
                <>
                  <CheckboxRowButton
                    title={option.label}
                    showDisclosure={true}
                    selected={option.key === value}
                    onPress={() => onCheckboxClickedValue(option.key)}
                  />
                  <LineDivider
                    dividerColor="#DADADA"
                    width="100%"
                    theme={theme}
                  />
                </>
              );
            })}
            <PrestoText
              extraStyle={{ paddingLeft: 15, marginVertical: 15 }}
              fontStyle={"600.semibold"}
              size={16}
            >
              {"Sort By"}
            </PrestoText>
            {_.map(SORT_OPTIONS, (option) => {
              return (
                <>
                  <CheckboxRowButton
                    title={I18n.t(option.label)}
                    showDisclosure={true}
                    selected={option.key === sort}
                    onPress={() => onCheckboxClickedSort(option.key)}
                  />
                  <LineDivider
                    dividerColor="#DADADA"
                    width="100%"
                    theme={theme}
                  />
                </>
              );
            })}
          </ScrollView>
        </View>
        <View
          style={{
            padding: 10,
            // position: "absolute",
            // bottom: 20,
            width: "100%",
          }}
        >
          <PrestoSolidButton
            size="small"
            buttonStyle="primary"
            titleColor={theme.white}
            title={I18n.t("screen_messages.common.apply")}
            width={"100%"}
            height={50}
            titleSize={16}
            borderRadius={8}
            titleExtraStyle={{ textTransform: "capitalize" }}
            onPress={() => {
              onPressApply();
            }}
          />
        </View>
      </LoadingContainer>
    </DefaultModal>
  );
}
