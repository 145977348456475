import React, { useState, useContext, useCallback } from "react";
import { View, Dimensions, ScrollView } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import useFocusEffect from "@presto-common/useFocusEffect";
import moment from "moment";

export default function ReportDetails({ route, navigation }) {
  const params = route.params || {};
  const { initialReport, setParentLoading } = params || {};
  const { theme } = useContext(ThemeContext);
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  const styles = getStyles(theme, windowWidth);
  const [report, setReport] = useState(null);

  useFocusEffect(
    useCallback(() => {
      setReport(initialReport);
    }, [initialReport])
  );

  const startDate = report?.start_date ? moment(report?.start_date) : null;
  const endDate = report?.end_date ? moment(report?.end_date) : null;

  return (
    <View style={{ backgroundColor: theme.screenBackgroundColor }}>
      <View
        style={{
          backgroundColor: "#737682",
          borderTopLeftRadius: 18,
          borderTopRightRadius: 18,
          height: windowHeight,
        }}
      >
        <ScrollView
          style={{ width: "100%", flex: 1, height: windowHeight }}
          contentContainerStyle={{
            paddingTop: 10,
            paddingBottom: 100,
          }}
        >
          <View>
            {report ? (
              <View style={{ paddingHorizontal: 30, paddingTop: 30 }}>
                <View style={styles.row}>
                  <PrestoText
                    color={theme.white}
                    fontStyle={"400.normal"}
                    size={18}
                  >
                    {I18n.t("screen_messages.reports.report_type")} {": "}
                  </PrestoText>

                  <PrestoText
                    color={theme.white}
                    fontStyle={"600.semibold"}
                    size={18}
                  >
                    {report.report_type}
                  </PrestoText>
                </View>

                <View
                  style={[styles.row, { paddingTop: theme.primaryPadding }]}
                >
                  <PrestoText
                    color={theme.white}
                    fontStyle={"400.normal"}
                    size={18}
                  >
                    {I18n.t("screen_messages.reports.status")} {": "}
                  </PrestoText>

                  <PrestoText
                    color={theme.white}
                    fontStyle={"600.semibold"}
                    size={18}
                  >
                    {_.capitalize(report.status)}
                  </PrestoText>
                </View>

                <View
                  style={[styles.row, { paddingTop: theme.primaryPadding }]}
                >
                  <PrestoText
                    color={theme.white}
                    fontStyle={"400.normal"}
                    size={18}
                  >
                    {I18n.t("screen_messages.location.state")} {": "}
                  </PrestoText>

                  <PrestoText
                    color={theme.white}
                    fontStyle={"600.semibold"}
                    size={18}
                  >
                    {_.capitalize(report.state)}
                  </PrestoText>
                </View>

                <View
                  style={[styles.row, { paddingTop: theme.primaryPadding }]}
                >
                  <PrestoText
                    color={theme.white}
                    fontStyle={"400.normal"}
                    size={18}
                  >
                    {I18n.t("screen_messages.reports.date_range")} {": "}
                  </PrestoText>

                  <PrestoText
                    color={theme.white}
                    fontStyle={"600.semibold"}
                    size={18}
                  >
                    {startDate.format("DD/MM/YYYY")}-
                    {endDate.format("DD/MM/YYYY")}
                  </PrestoText>
                </View>

                <View
                  style={[styles.row, { paddingTop: theme.primaryPadding }]}
                >
                  <PrestoText
                    color={theme.white}
                    fontStyle={"400.normal"}
                    size={18}
                  >
                    {I18n.t("screen_messages.reports.notify_email")} {": "}
                  </PrestoText>

                  <PrestoText
                    color={theme.white}
                    fontStyle={"600.semibold"}
                    size={18}
                  >
                    {_.get(report, "notification_email_id", "")}
                  </PrestoText>
                </View>
              </View>
            ) : (
              <PrestoText
                extraStyle={{ paddingHorizontal: 30, paddingTop: 30 }}
                color={theme.white}
                fontStyle={"400.normal"}
                size={12}
              >
                {I18n.t("screen_messages.reports.please_select_an_report")}
              </PrestoText>
            )}
          </View>
        </ScrollView>
      </View>
    </View>
  );
}

function getStyles(theme) {
  return {
    footerContainer: {
      paddingHorizontal: theme.primaryPadding,
      bottom: 0,
      width: "100%",
    },
    footerInner: {
      flexDirection: "row",
    },
    row: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignContent: "center",
      alignItems: "center",
    },
  };
}
