import WalletResource from "./WalletResource";
import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import WalletObject from "../../data_models/WalletObject";
import $ from "../../global/util";

const WalletManager = function (
  WalletResource,
  Utils,
  ErrorConstants,
  WalletObject
) {
  function getWalletInfo(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.walletId)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.WALLET_ID_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else {
      WalletResource.getWalletInfo(params).then(
        function (walletInfo) {
          console.log("walletInfo -", walletInfo);
          var walletObject = new WalletObject();
          walletObject.buildObject(walletInfo.data);
          walletInfo.data = walletObject;
          successCallback(walletInfo);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function makePayment(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.id)) {
      var error;
      if (params.id_type == "appointment_id") {
        error = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.APPOINTMENT_ID_EMPTY,
          false
        );
      } else {
        error = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.ORDER_ID_EMPTY,
          false
        );
      }
      errorCallback(error);
    } else {
      WalletResource.makePayment(params).then(
        function (paymentResponse) {
          successCallback(paymentResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getTransactionHistory(params, successCallback, errorCallback) {
    WalletResource.getTransactionHistory(params).then(
      function (getTransactionHistoryResponse) {
        successCallback(getTransactionHistoryResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function makePaymentWithPin(params, successCallback, errorCallback) {
    WalletResource.makePaymentWithPin(params).then(
      function (paymentResponse) {
        successCallback(paymentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function onSetUserPin(params, successCallback, errorCallback) {
    WalletResource.onSetUserPin(params).then(
      function (onSetUserPinResponse) {
        successCallback(onSetUserPinResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function onSetUserPinOtp(params, successCallback, errorCallback) {
    WalletResource.onSetUserPinOtp(params).then(
      function (onSetUserPinOtpResponse) {
        successCallback(onSetUserPinOtpResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function onVerifyUserPinForWalletRecharge(
    params,
    successCallback,
    errorCallback
  ) {
    WalletResource.onVerifyUserPinForWalletRecharge(params).then(
      function (onVerifyUserPinResponse) {
        successCallback(onVerifyUserPinResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function onWalletRechargeWithoutPin(params, successCallback, errorCallback) {
    WalletResource.onWalletRechargeWithoutPin(params).then(
      function (onVerifyUserPinResponse) {
        successCallback(onVerifyUserPinResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function requestUnBlock(params, successCallback, errorCallback) {
    WalletResource.requestUnBlock(params).then(
      function (requestUnBlockResponse) {
        successCallback(requestUnBlockResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function activate(params, successCallback, errorCallback) {
    WalletResource.activate(params).then(
      function (activateResponse) {
        successCallback(activateResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getRistaLoyaltyInfo(successCallback, errorCallback) {
    WalletResource.getRistaLoyaltyInfo().then(
      function (getRistaLoyaltyInfo) {
        successCallback(getRistaLoyaltyInfo);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function payViaRistaLoyalty(params, succcessCallback, errorCallback) {
    WalletResource.payViaRistaLoyalty(params).then(
      function (paymentResponse) {
        succcessCallback(paymentResponse);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function getWalletPromotions(successCallback, errorCallback) {
    WalletResource.getWalletPromotions().then(
      function (getWalletPromotionsResponse) {
        successCallback(getWalletPromotionsResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getWalletRedeemedPromotions(successCallback, errorCallback) {
    WalletResource.getWalletRedeemedPromotions().then(
      function (getWalletRedeemedPromotionsResponse) {
        successCallback(getWalletRedeemedPromotionsResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function getEmployeeWalletInfo(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.id)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.USER_ID_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else {
      WalletResource.getEmployeeWalletInfo(params).then(
        function (walletInfo) {
          var walletObject = new WalletObject();
          walletObject.buildObject(walletInfo.data);
          walletInfo.data = walletObject;
          successCallback(walletInfo);
        },
        function (error) {
          console.log("error", error);
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }
  function makeUserPayment(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.id)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.USER_ID_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else {
      WalletResource.makeUserPayment(params).then(
        function (paymentResponse) {
          successCallback(paymentResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function walletDebit(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.id)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.USER_ID_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else {
      WalletResource.walletDebit(params).then(
        function (paymentResponse) {
          successCallback(paymentResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function initiateWalletRedemption(params, successCallback, errorCallback) {
    WalletResource.initiateWalletRedemption(params).then(
      function (paymentResponse) {
        successCallback(paymentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function confirmWalletRedemption(params, successCallback, errorCallback) {
    WalletResource.confirmWalletRedemption(params).then(
      function (paymentResponse) {
        successCallback(paymentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    getWalletInfo: getWalletInfo,
    walletDebit: walletDebit,
    makeUserPayment: makeUserPayment,
    getEmployeeWalletInfo: getEmployeeWalletInfo,
    makePayment: makePayment,
    getTransactionHistory: getTransactionHistory,
    makePaymentWithPin: makePaymentWithPin,
    onSetUserPin: onSetUserPin,
    onSetUserPinOtp: onSetUserPinOtp,
    onVerifyUserPinForWalletRecharge: onVerifyUserPinForWalletRecharge,
    onWalletRechargeWithoutPin: onWalletRechargeWithoutPin,
    requestUnBlock: requestUnBlock,
    activate: activate,
    getRistaLoyaltyInfo: getRistaLoyaltyInfo,
    payViaRistaLoyalty: payViaRistaLoyalty,
    getWalletPromotions: getWalletPromotions,
    getWalletRedeemedPromotions: getWalletRedeemedPromotions,
    initiateWalletRedemption: initiateWalletRedemption,
    confirmWalletRedemption: confirmWalletRedemption,
  };
};

export default WalletManager(
  WalletResource,
  Utils,
  ErrorConstants,
  WalletObject
);
