import React, { useContext, useState } from "react";
import { View, ScrollView } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import _ from "lodash";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import HeaderWithBackAndLogoAndRightTitle from "@presto-components/Headers/HeaderWithBackAndLogoAndRightTitle";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import DropDownPicker from "react-native-dropdown-picker";
import DatePickerComponent from "@presto-components/CalendarComponent/DatePickerComponent";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import LoadingContainer from "@presto-components/LoadingContainer";
import { prestoAlert } from "../../common/Alert";

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_CATEGORY_ITEM":
      return { ...state, items: { ...state.items, [payload.id]: payload } };
  }
  return state;
}

export default function CreateReport({ navigation, route }) {
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openDateType, setOpenDateType] = useState(false);

  const [reportTypeValue, setReportTypeValue] = useState(null);
  const [statusTypeValue, setStatusTypeValue] = useState(null);
  const [dateTypeValue, setDateTypeValue] = useState(null);
  const [email, setEmail] = useState("");
  const [focusedItem, setFocusedItem] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  let maxDate = new Date();
  maxDate.setHours("23", "59", "59");

  const [reportType, setReportType] = useState([
    { label: "Appointments", value: "Appointment" },
    { label: "Orders", value: "Order" },
  ]);

  const [statusType, setStatusType] = useState([
    { label: "ALL", value: "ALL" },
    { label: "RECEIVED", value: "RECEIVED" },
    { label: "ACCEPTED", value: "ACCEPTED" },
    { label: "DISPATCHED", value: "DISPATCHED" },
    { label: "DELIVERED", value: "DELIVERED" },
    { label: "REJECTED", value: "REJECTED" },
  ]);

  const [dateType, setDateType] = useState([
    { label: "Created At", value: "created_at" },
    { label: "Received At", value: "received_at" },
    { label: "Accepted At", value: "accepted_at" },
    { label: "Dispatched At", value: "dispatched_at" },
    { label: "Delivered At", value: "delivered_at" },
    { label: "Rejected At", value: "rejected_at" },
    { label: "Cancelled At", value: "cancelled_at" },
    { label: "Failed At", value: "failed_at" },
  ]);

  const onEmailChange = ({ nativeEvent: { text } }) => {
    setEmail(text);
  };

  const onCreateReport = () => {
    let isValid = true,
      error = null;
    let allFieldsFilled = null;

    allFieldsFilled = _.some(
      [
        reportTypeValue,
        statusTypeValue,
        dateTypeValue,
        startDate,
        endDate,
        email,
      ],
      (field) => _.isEmpty(field)
    );

    if (allFieldsFilled) {
      isValid = false;
      error = {
        title: I18n.t("error_messages.common.missing_information_title"),
        message: I18n.t("error_messages.common.missing_information_desc"),
      };
    }

    if (!isValid) {
      return alertBox(error.title, error.message);
    }

    setLoading(true);
    let params = {
      reports_task: {
        report_type: reportTypeValue,
        status: statusTypeValue == "ALL" ? "" : statusTypeValue,
        date_type: dateTypeValue,
        start_date: startDate,
        end_date: endDate,
        notification_email_id: email,
      },
    };
    EmployeeManager.createReport(
      params,
      (response) => {
        alertBox(
          "Information",
          I18n.t("screen_messages.reports.report_created_success")
        );
        clearUserInputs();
      },
      (error) => {
        console.log("Error here", error);
        alertBox(error.title, error.message);
        setLoading(false);
      }
    );
  };

  const clearUserInputs = () => {
    setEmail("");
    setReportTypeValue(null);
    setStatusTypeValue(null);
    setDateTypeValue(null);
    setStartDate(null);
    setEndDate(null);
    setFocusedItem(null);
    setLoading(false);
  };

  const alertBox = (title, message) =>
    prestoAlert(
      title,
      message,
      [
        {
          text: "Ok",
          onPress: () => { },
          style: "cancel",
        },
      ],
      false
    );

  return (
    <>
      <HeaderWithBackAndLogoAndRightTitle enableBack={false} />

      <View style={{ backgroundColor: theme.white, height: "100%" }}>
        <LoadingContainer loading={loading} bgColor={theme.white}>
          <ScrollView
            style={{ width: "100%", flex: 1 }}
            contentContainerStyle={{
              paddingTop: 10,
              paddingBottom: 100,
            }}
          >
            <View style={{ padding: theme.containerPadding }}>
              <PrestoText size={20} fontStyle={"600.semibold"}>
                {I18n.t("screen_messages.reports.new_report")}
              </PrestoText>

              <View style={{ paddingBottom: open ? 100 : 30 }}>
                <PrestoText
                  fontStyle={"500.medium"}
                  color={theme.title}
                  size={16}
                  extraStyle={{ paddingTop: 30, paddingBottom: 20 }}
                >
                  {I18n.t("screen_messages.reports.report_type")}
                </PrestoText>

                <DropDownPicker
                  dropDownDirection="BOTTOM"
                  placeholder={I18n.t(
                    "screen_messages.reports.select_report_type"
                  )}
                  open={open}
                  value={reportTypeValue}
                  items={reportType}
                  setOpen={setOpen}
                  setValue={setReportTypeValue}
                  setItems={setReportType}
                  style={{
                    borderColor: theme.header,
                    borderRadius: 8,
                  }}
                  textStyle={{
                    fontSize: 16,
                    color: theme.subHeader,
                  }}
                />
              </View>

              <View style={{ paddingBottom: openStatus ? 230 : 30 }}>
                <PrestoText
                  fontStyle={"500.medium"}
                  color={theme.title}
                  size={16}
                  extraStyle={{ paddingBottom: 20 }}
                >
                  {I18n.t("screen_messages.reports.status")}
                </PrestoText>

                <DropDownPicker
                  dropDownDirection="BOTTOM"
                  placeholder={I18n.t("screen_messages.reports.select_status")}
                  open={openStatus}
                  value={statusTypeValue}
                  items={statusType}
                  setOpen={setOpenStatus}
                  setValue={setStatusTypeValue}
                  setItems={setStatusType}
                  style={{
                    backgroundColor: theme.white,
                    borderColor: theme.header,
                    borderRadius: 8,
                  }}
                  textStyle={{
                    fontSize: 16,
                    color: theme.subHeader,
                  }}
                />
              </View>

              <View style={{ paddingBottom: openDateType ? 230 : 30 }}>
                <PrestoText
                  fontStyle={"500.medium"}
                  color={theme.title}
                  size={16}
                  extraStyle={{
                    paddingBottom: 20,
                  }}
                >
                  {I18n.t("screen_messages.reports.date_type")}
                </PrestoText>

                <DropDownPicker
                  dropDownDirection="BOTTOM"
                  placeholder={I18n.t(
                    "screen_messages.reports.select_date_type"
                  )}
                  open={openDateType}
                  value={dateTypeValue}
                  items={dateType}
                  setOpen={setOpenDateType}
                  setValue={setDateTypeValue}
                  setItems={setDateType}
                  style={{
                    backgroundColor: theme.white,
                    borderColor: theme.header,
                    borderRadius: 8,
                  }}
                  textStyle={{
                    fontSize: 16,
                    color: theme.subHeader,
                  }}
                />
              </View>

              <View>
                <PrestoText
                  fontStyle={"500.medium"}
                  color={theme.title}
                  size={16}
                  extraStyle={{
                    paddingBottom: 20,
                  }}
                >
                  {I18n.t("screen_messages.reports.date_range")}
                </PrestoText>

                <View>
                  <DatePickerComponent
                    userDate={
                      !_.isEmpty(startDate) ? new Date(startDate) : null
                    }
                    placeholder={I18n.t("screen_messages.reports.start_date")}
                    onDateChange={(value) => {
                      setStartDate(value.toISOString());
                    }}
                    onFocus={() => setFocusedItem("startDate")}
                    onBlur={() => setFocusedItem(null)}
                    textInputStyles={{
                      borderWidth: 2,
                      borderColor:
                        focusedItem === "startDate"
                          ? theme.primary
                          : theme.lightText,
                      borderRadius: 6,
                    }}
                    // minimumDate={null}
                    maximumDate={maxDate}
                    showTimeInput={true}
                    iconSize={35}
                    showYearsDropdown={true}
                    defaultToToday={false}
                  />
                </View>

                <View style={{ paddingTop: 30 }}>
                  <DatePickerComponent
                    userDate={!_.isEmpty(endDate) ? new Date(endDate) : null}
                    placeholder={I18n.t("screen_messages.reports.end_date")}
                    onDateChange={(value) => {
                      setEndDate(value.toISOString());
                    }}
                    onFocus={() => setFocusedItem("endDate")}
                    onBlur={() => setFocusedItem(null)}
                    textInputStyles={{
                      borderWidth: 2,
                      borderColor:
                        focusedItem === "endDate"
                          ? theme.primary
                          : theme.lightText,
                      borderRadius: 6,
                    }}
                    minimumDate={null}
                    maximumDate={maxDate}
                    showTimeInput={true}
                    iconSize={35}
                    showYearsDropdown={true}
                    defaultToToday={false}
                  />
                </View>
              </View>

              <View>
                <PrestoText
                  fontStyle={"500.medium"}
                  color={theme.title}
                  size={16}
                  extraStyle={{
                    paddingTop: 30,
                    paddingBottom: 20,
                  }}
                >
                  {I18n.t("screen_messages.reports.notify_email")}
                </PrestoText>
                <PrestoTextInput
                  theme={theme}
                  placeholder={I18n.t("screen_messages.reports.enter_email")}
                  value={email}
                  onChange={onEmailChange}
                  backgroundColor={theme.white}
                  textContentType={"email"}
                  autoCompleteType={"email"}
                  importantForAutofill={"yes"}
                  style={{
                    textInputHeight: 50,
                    borderColor: theme.header,
                    borderRadius: 8,
                  }}
                />
              </View>
              <View style={{ width: "100%", marginTop: 30 }}>
                <PrestoSolidButton
                  size="large"
                  buttonStyle="primary"
                  title={I18n.t("screen_messages.common.continue")}
                  titleColor={theme.white}
                  titleFontStyle={"600.semibold"}
                  titleSize={24}
                  height={60}
                  borderRadius={12}
                  onPress={() => onCreateReport()}
                />
              </View>
            </View>
          </ScrollView>
        </LoadingContainer>
      </View>
    </>
  );
}
