import _ from "lodash";
import { PermissionsAndroid, Platform } from "react-native";
import Share from "@presto-common/Share";
import PrintHelper from "@presto-helpers/PrintHelper";
import PrestoSdk from "@presto-services/global/PrestoSdk";
import RNFetchBlob from "@presto-common/RNFetchBlob";

function upComingOrdersFilter(orders) {
  return _.filter(orders, (o) =>
    ["RECEIVED", "DISPATCHED", "ACCEPTED"].includes(o.status)
  );
}

function sortOrderOnLatest(orders) {
  return orders.sort((a, b) => {
    let date1 = new Date(a.created_at);
    let date2 = new Date(b.created_at);
    if (date1 >= date2) {
      return -1;
    } else {
      return 0;
    }
  });
}

const askFileWritePermissions = async () => {
  if (Platform.OS === "ios") {
    return {
      "android.permission.READ_EXTERNAL_STORAGE": "granted",
      "android.permission.WRITE_EXTERNAL_STORAGE": "granted",
    };
  }

  const writePermissionKey =
    PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE;
  const readPermissionKey =
    PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE;
  const result = await PermissionsAndroid.requestMultiple([
    writePermissionKey,
    readPermissionKey,
  ]);

  if (
    result[readPermissionKey] === "denied" ||
    result[writePermissionKey] === "denied"
  ) {
    alertBox(I18n.t("screen_messages.permissions.enabled_write"));
    return null;
  } else {
    return result;
  }
};

function downloadInvoiceByEmployee(order, { print = false }) {
  const fetchPrestoSession = () => {
    const sessionUrl = PrestoSdk.getHostName() + "/employee.json";
    return fetch(sessionUrl).then((response) => {
      let prestoSession = response.headers.map["x-presto-st"];
      return prestoSession;
    });
  };

  return new Promise((resolve) => {
    return askFileWritePermissions().then((permissionResult) => {
      if (_.isEmpty(permissionResult)) {
        resolve({
          success: false,
          filePath: null,
        });
      }

      fetchPrestoSession()
        .then((prestoSession) => {
          let dirs = null,
            configOptions = {};
          if (Platform.OS == "android") {
            dirs = RNFetchBlob.fs.dirs;
            configOptions = {
              trusty: true,
              credentials: true,
              addAndroidDownloads: {
                useDownloadManager: true,
                notification: true,
                mime: "application/pdf",
                description: "Invoice downloaded.",
                path: `${dirs.DownloadDir}/Invoice_${order.id}.pdf`,
              },
            };
          } else if (Platform.OS === "ios") {
            configOptions = {
              fileCache: true,
              trusty: true,
              mime: "application/pdf",
            };
          }

          let filePath = null;

          const url =
            PrestoSdk.getHostName() +
            `/employee/v0/orders/${order.id}/download_invoice.json`;

          RNFetchBlob.config(configOptions)
            .fetch("GET", url, { "x-presto-st": prestoSession })
            .then((resp) => {
              filePath = resp.path();

              if (Platform.OS === "android") {
                filePath = `file://${filePath}`;
              }

              return resp.readFile("base64");
            })
            .then(async (base64Data) => {
              if (Platform.OS === "ios") {
                base64Data = `data:application/pdf;base64,` + base64Data;
                try {
                  if (print) {
                    return PrintHelper.print(base64Data)
                      .then((...args) => {
                        resolve({
                          success: true,
                          filePath,
                        });
                      })
                      .catch(async (error) => {
                        try {
                          await Share.open({ url: base64Data });
                          resolve({
                            success: true,
                            filePath,
                          });
                        } catch (error) {
                          resolve({
                            success: false,
                            filePath,
                          });
                        }
                      });
                  } else {
                    try {
                      await Share.open({ url: base64Data });
                      resolve({
                        success: true,
                        filePath,
                      });
                    } catch (error) {
                      resolve({
                        success: false,
                        filePath,
                      });
                    }
                  }
                } catch (error) {
                  resolve({
                    success: false,
                    filePath,
                  });
                }
              } else if (print) {
                return PrintHelper.print(filePath).then((...args) => {
                  resolve({
                    success: true,
                    filePath,
                  });
                });
              } else {
                resolve({
                  success: true,
                  filePath,
                });
              }
            })
            .catch((error) => {
              console.log(
                "orderHelper->downloadInvoiceByEmployee->RNFetchBlob error ",
                error
              );

              resolve({
                success: true,
                filePath: null,
              });
            });
        })
        .catch((error) => {
          console.log(
            "orderHelper->downloadInvoiceByEmployee->fetchPrestoSession error ",
            error
          );

          resolve({
            success: true,
            filePath: null,
          });
        });
    });
  });
}

export default {
  upComingOrdersFilter: upComingOrdersFilter,
  sortOrderOnLatest: sortOrderOnLatest,
  downloadInvoiceByEmployee,
  askFileWritePermissions,
};
