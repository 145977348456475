import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import ClientConstants from "./ClientConstants";
import $ from "../../global/util";

const ClientResource = function ($http, PrestoSdk, ClientConstants) {
  function getAllClients(params) {
    var url = PrestoSdk.getHostName() + ClientConstants.GET_ALL_CLIENTS.PATH;
    return $http.get(url);
  }

  function createClient(params) {
    var url = PrestoSdk.getHostName() + "/cc/users.json";
    return $http.post(url, params);
  }

  function updateClient(params) {
    var url = PrestoSdk.getHostName() + `/cc/users/${params.user.id}.json`;
    console.log("updateEmployee url, params:", url, params.user.id);
    return $http.put(url, params);
  }

  function getClient(params) {
    var url =
      PrestoSdk.getHostName() +
      `/cc/${params.merchant_id}/users/${params.user_id}.json`;
    console.log("showEmployee url, params:", url, params);
    return $http.get(url, params);
  }

  function updateClientAddress(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/employees/${params.client_id}/address.json`;
    return $http.put(url, params);
  }

  return {
    getAllClients: getAllClients,
    createClient: createClient,
    updateClient: updateClient,
    getClient: getClient,
    updateClientAddress: updateClientAddress,
  };
};

export default ClientResource($http, PrestoSdk, ClientConstants);
