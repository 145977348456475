import React, { useState, useContext, useCallback } from "react";
import { View, Dimensions, ScrollView } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import useFocusEffect from "@presto-common/useFocusEffect";
import moment from "moment";
import ActionButtons from "@presto-screen-components/ActionButtons/ActionButtons";
import EmployeeManager from "@presto-services/features/employee/EmployeeManager";
import { alertBox, prestoConfirm } from "../../common/Alert";
import utils from "../../utils";

export default function CouponDetails({ route, navigation }) {
  const params = route.params || {};
  const { initial, setParentLoading, refresh } = params || {};
  const { theme } = useContext(ThemeContext);
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  const styles = getStyles(theme, windowWidth);
  const [coupon, setCoupon] = useState(null);
  const isMobile = utils.isMobile();

  useFocusEffect(
    useCallback(() => {
      setCoupon(initial);
    }, [initial])
  );

  const getDateRange = () => {
    let startTime = moment(coupon.start_time);
    let endTime = moment(coupon.end_time);

    let dateRange = `${startTime.format("DD-MM-YYYY")} - ${endTime.format(
      "DD-MM-YYYY"
    )}`;

    return dateRange;
  };

  const getValue = () => {
    return coupon.value_type === "fixed_discount"
      ? coupon.value / 100
      : coupon.value;
  };

  const getActionButtons = () => {
    let btns = [],
      commonBtnProps = {
        size: "large",
        buttonStyle: "secondary",
        titleColor: theme.white,
        titleFontStyle: "600.semibold",
        titleSize: 24,
        height: 60,
        borderRadius: 12,
      };

    btns.push({
      title: I18n.t("screen_messages.common.delete"),
      onPress: () => {
        const isCancelable = false;
        prestoConfirm(
          I18n.t("screen_messages.common.confirm_text"),
          I18n.t("error_messages.coupons.confirm_delete_1"),
          [
            {
              text: I18n.t("screen_messages.common.yes_text"),
              onPress: () => {
                const onSuccess = (response) => {
                  setParentLoading(false);
                  setCoupon(null);
                  if (_.isFunction(refresh)) {
                    refresh();
                  }
                  alertBox("", I18n.t("success_messages.coupons.removed"));
                };
                const onError = (error) => {
                  setParentLoading(false);
                  alertBox("", error.message);
                  console.log("getActionButtons->delete->error", error);
                };
                setParentLoading(true);
                EmployeeManager.couponRemove(
                  { id: coupon.id },
                  onSuccess,
                  onError
                );
              },
            },
            {
              text: I18n.t("screen_messages.common.no_text"),
            },
          ],
          isCancelable
        );
      },
      bgColor: "#F17B8D",
    });

    return { btns, commonBtnProps };
  };

  const actionButtons = getActionButtons();
  
  return (
    <View style={{ backgroundColor: theme.screenBackgroundColor }}>
      <View
        style={{
          backgroundColor: "#737682",
          borderTopLeftRadius: isMobile ? 0 : 18,
          borderTopRightRadius: isMobile ? 0 : 18,
          height: "100%",
        }}
      >
        <ScrollView
          style={{ width: "100%", flex: 1, height: windowHeight }}
          contentContainerStyle={{
            paddingTop: 10,
            paddingBottom: 100,
          }}
        >
          {coupon ? (
            <View
              style={{
                paddingHorizontal: theme.primaryPadding,
                paddingTop: theme.primaryPadding,
              }}
            >
              <View style={styles.row}>
                <PrestoText
                  color={theme.white}
                  fontStyle={"400.normal"}
                  size={18}
                >
                  {I18n.t("screen_messages.coupons.code_title")} {": "}
                </PrestoText>

                <PrestoText
                  color={theme.white}
                  fontStyle={"600.semibold"}
                  size={18}
                >
                  {coupon.code}
                </PrestoText>
              </View>

              <View style={[styles.row, { paddingTop: theme.primaryPadding }]}>
                <PrestoText
                  color={theme.white}
                  fontStyle={"400.normal"}
                  size={18}
                >
                  {I18n.t("screen_messages.coupons.coupon_title")} {": "}
                </PrestoText>

                <PrestoText
                  color={theme.white}
                  fontStyle={"600.semibold"}
                  size={18}
                >
                  {_.capitalize(coupon.title)}
                </PrestoText>
              </View>

              <View style={[styles.row, { paddingTop: theme.primaryPadding }]}>
                <PrestoText
                  color={theme.white}
                  fontStyle={"400.normal"}
                  size={18}
                >
                  {I18n.t("screen_messages.purchase_requests.type")} {": "}
                </PrestoText>

                <PrestoText
                  color={theme.white}
                  fontStyle={"600.semibold"}
                  size={18}
                >
                  {_.capitalize(coupon.type)}
                </PrestoText>
              </View>

              <View style={[styles.row, { paddingTop: theme.primaryPadding }]}>
                <PrestoText
                  color={theme.white}
                  fontStyle={"400.normal"}
                  size={18}
                >
                  {I18n.t("screen_messages.coupons.value_type_title")} {": "}
                </PrestoText>

                <PrestoText
                  color={theme.white}
                  fontStyle={"600.semibold"}
                  size={18}
                >
                  {_.capitalize(_.replace(coupon.value_type, "_", " "))}
                </PrestoText>
              </View>

              <View style={[styles.row, { paddingTop: theme.primaryPadding }]}>
                <PrestoText
                  color={theme.white}
                  fontStyle={"400.normal"}
                  size={18}
                >
                  {I18n.t("screen_messages.common.value")} {": "}
                </PrestoText>

                <PrestoText
                  color={theme.white}
                  fontStyle={"600.semibold"}
                  size={18}
                >
                  {getValue()}
                </PrestoText>
              </View>

              <View style={[styles.row, { paddingTop: theme.primaryPadding }]}>
                <PrestoText
                  color={theme.white}
                  fontStyle={"400.normal"}
                  size={18}
                >
                  {I18n.t("screen_messages.reports.date_range")} {": "}
                </PrestoText>

                <PrestoText
                  color={theme.white}
                  fontStyle={"600.semibold"}
                  size={18}
                >
                  {getDateRange()}
                </PrestoText>
              </View>
            </View>
          ) : (
            <View style={{ paddingHorizontal: theme.primaryPadding }}>
              <PrestoText
                color={theme.white}
                fontStyle={"400.normal"}
                size={14}
              >
                {I18n.t("screen_messages.coupons.choose_coupon")}
              </PrestoText>
            </View>
          )}
        </ScrollView>

        {coupon ? (
          <View
            style={{
              backgroundColor: theme.white,
              minHeight: isMobile ? 150 : 240,
              borderTopLeftRadius: 18,
              borderTopRightRadius: 18,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              width: isMobile ? "100%" : 400,
              position: "absolute",
              bottom: 0,
            }}
          >
            <View
              style={{
                paddingHorizontal: theme.primaryPadding,
                height: "100%",
                flexDirection: "column",
                justifyContent: "flex-end",
                paddingBottom: theme.containerPadding + 10,
              }}
            >
              <ActionButtons
                btns={actionButtons.btns}
                commonBtnProps={actionButtons.commonBtnProps}
              />
            </View>
          </View>
        ) : null}
      </View>
    </View>
  );
}

function getStyles(theme) {
  return {
    footerContainer: {
      paddingHorizontal: theme.primaryPadding,
      bottom: 0,
      width: "100%",
    },
    footerInner: {
      flexDirection: "row",
    },
    row: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignContent: "center",
      alignItems: "center",
    },
  };
}
