import Utils from "../../common/Utils";
import PrestoSdk from "../../global/PrestoSdk";
import $http from "../../global/http";
import CatalogConstants from "./CatalogConstants";
import $ from "../../global/util";
import config from "@presto-common/config";

const CatalogResource = function (Utils, PrestoSdk, $http, CatalogConstants) {
  function getGenericCatalogStructure(params) {
    let mid = params.merchant_id
      ? params.merchant_id
      : PrestoSdk.getMerchantId();

    var url =
      PrestoSdk.getHostName() +
      "/catalog/structure.json?merchant_id=" +
      mid +
      "&type=" +
      params.type;

    return $http.get(url);
  }

  function getGenericCatalog(params) {
    let mid = params.merchant_id
      ? params.merchant_id
      : PrestoSdk.getMerchantId();
    var url =
      PrestoSdk.getHostName() + "/catalog/structure.json?merchant_id=" + mid;
    return $http.get(url);
  }

  function getOutletBasedCatalog(params) {
    var url =
      PrestoSdk.getHostName() +
      "/catalog/structure.json?top_level_categories=true&merchant_id=" +
      PrestoSdk.getMerchantId() +
      "&outlet_id=" +
      params.outletId;
    return $http.get(url);
  }

  function getFilterBasedCatalog(params) {
    let mid;
    let top_level_categories;
    if (!params.merchant_id) {
      mid = PrestoSdk.getMerchantId();
    } else {
      mid = params.merchant_id;
    }
    if (!params.top_level_categories) {
      top_level_categories = true;
    }
    var url =
      PrestoSdk.getHostName() +
      "/catalog/structure.json?top_level_categories=" +
      top_level_categories +
      "&merchant_id=" +
      mid +
      "&filter=" +
      params.filter;
    return $http.get(url);
  }

  function searchProduct(params) {
    var url =
      PrestoSdk.getHostName() +
      "/search_catalog.json?catalog_type=products" +
      "&merchant_id=" +
      PrestoSdk.getMerchantId() +
      "&q=" +
      params.search_string;
    return $http.get(url);
  }

  function getFullCatalog(params) {
    var url =
      PrestoSdk.getHostName() +
      "/catalog.json?merchant_id=" +
      PrestoSdk.getMerchantId();
    if (params.filter) {
      url = url + "&filter=" + params.filter;
    }
    return $http.get(url);
  }

  function getOutletBasedFullCatalog(params) {
    var url =
      PrestoSdk.getHostName() +
      "/catalog.json?merchant_id=" +
      PrestoSdk.getMerchantId() +
      "&outlet_id=" +
      params.outletId;
    return $http.get(url);
  }

  function getMasterCatalog() {
    var url = config.master_catalog;
    if (url) {
      return $http.get(url);
    } else {
      return null;
    }
  }

  function createCatalog(params) {
    var url = PrestoSdk.getHostName() + "/catalog.json";
    return $http.post(url, params);
  }

  function getMasterCatalogForProducts() {
    var url = config.master_catalog_for_products;
    return $http.get(url);
  }

  return {
    getGenericCatalog: getGenericCatalog,
    getOutletBasedCatalog: getOutletBasedCatalog,
    getFilterBasedCatalog: getFilterBasedCatalog,
    searchProduct: searchProduct,
    getGenericCatalogStructure: getGenericCatalogStructure,
    getFullCatalog: getFullCatalog,
    getOutletBasedFullCatalog: getOutletBasedFullCatalog,
    getMasterCatalog: getMasterCatalog,
    createCatalog: createCatalog,
    getMasterCatalogForProducts: getMasterCatalogForProducts,
  };
};

export default CatalogResource(Utils, PrestoSdk, $http, CatalogConstants);
